import React, { Component } from "react";
import Functions from "../FieldsDropdown/Functions";
import { companyDropdowns } from "../PeopleMarket/inputsPeople";
import { adwords_budget } from "../../../../tags/adwords_budget";
import { role_groups } from "../../../../tags/role_groups";
import { industry_groups } from "../../../../tags/industry_groups";
import { hiring_groups } from "../../../../tags/hiring_groups";
import { employee_size } from "../../../../tags/employee_size";
import { news_groups } from "../../../../tags/news_groups";
import CompanyFoundedYear from "../../../RightPanel/CompanyFoundedYear";
import { technology_groups } from "../../../../tags/technology_groups";

class CompanyMarket extends Component {
  getParticularGroupsProp = (name) => {
    if (name === "role") {
      const role = this.props.marketBased.role;
      if (role && role.groups) {
        const selectedGroups = [];
        role_groups.map((item) => {
          const group = role.groups[item.group];
          if (group) {
            selectedGroups.push({
              ...item,
              open: group.open,
              tags: group.include_keywords ? group.include_keywords : [],
              excludeTags: group.exclude_keywords ? group.exclude_keywords : [],
            });
          }
        });
        return selectedGroups;
      }
    } else if (name === "adwords") {
      const adwords = this.props.marketBased.adwords_budget;
      if (adwords && adwords.length) {
        const adwordGroups = adwords_budget.filter((item) => {
          const index = adwords.indexOf(item.group);
          return index > -1;
        });
        return adwordGroups;
      }
    } else if (name === "technologies") {
      const technologies = this.props.marketBased.technology;
      if (technologies && technologies.length) {
        const withTechs = technologies.map((tech) => {
          const foundTechGroup = technology_groups.find(
            (item) => item.category === tech.category
          );
          return {
            ...tech,
            technologies: foundTechGroup
              ? foundTechGroup.technologies
              : tech.technologies,
          };
        });
        return withTechs;
      }
    } else if (name === "industry") {
      const industries = this.props.marketBased.industries;
      if (industries && industries.length) {
        const industryGroups = industry_groups.filter((item) => {
          const index = industries.indexOf(item.group);
          return index > -1;
        });
        return industryGroups;
      }
    } else if (name === "hiring") {
      const hiring = this.props.marketBased.hiring;
      if (hiring && hiring.groups && hiring.groups.length) {
        const hiringGroups = hiring_groups.filter((item) => {
          const index = hiring.groups.indexOf(item.group);
          return index > -1;
        });
        return hiringGroups;
      }
    } else if (name === "employee") {
      const employees = this.props.marketBased.employees;
      if (employees) {
        const selectedGroups = [];
        employee_size.map((item) => {
          if (employees[item.group]) {
            selectedGroups.push({
              ...item,
              tags: employees[item.group],
              include_tags: employees[item.group]
                ? employees[item.group]
                : item.include_tags,
              open: employees.openOrClose
                ? employees.openOrClose[item.group]
                : false,
              excludeTags: [],
            });
          }
        });
        return selectedGroups;
      }
    } else if (name === "news") {
      const news = this.props.marketBased.news;
      if (news && news.length) {
        const newsGroups = news_groups.filter((item) => {
          const index = news.indexOf(item.group);
          return index > -1;
        });
        return newsGroups;
      }
    } else if (name === "yearsOfExperience") {
      const yearsOfExperience = this.props.marketBased.yearsOfExperience;
      if (yearsOfExperience && yearsOfExperience.length) {
        const yearsOfExperienceGroups = yearsOfExperience.filter((item) => {
          const found = yearsOfExperience.find(
            (yearsOfExp) => yearsOfExp.group === item.group
          );
          return !!found;
        });
        return yearsOfExperienceGroups;
      }
    } else if (name === "estimatedSalary") {
      const estimatedSalary = this.props.marketBased.estimatedSalary;
      if (estimatedSalary && estimatedSalary.length) {
        const estimatedSalaryGroups = estimatedSalary.filter((item) => {
          const found = estimatedSalary.find(
            (estSal) => estSal.group === item.group
          );
          return !!found;
        });
        return estimatedSalaryGroups;
      }
    } else if (name === "location") {
      const companyLocation = this.props.marketBased.companyLocation;
      if (companyLocation && companyLocation.length) {
        return companyLocation;
      }
    }
    return [];
  };

  getIncludeKeywordsProp = (name) => {
    if (name === "role") {
      const role = this.props.marketBased.role;
      if (role) {
        return role.include_keywords ? role.include_keywords : [];
      }
    }
    if (name === "hiring") {
      const hiring = this.props.marketBased.hiring;
      if (hiring && hiring.roles) {
        return hiring.roles;
      }
    }
    if (name === "skills") {
      const skills = this.props.marketBased.skills;
      if (skills) {
        return skills.include_keywords ? skills.include_keywords : [];
      }
    }
    if (name === "industries2") {
      const industries2 = this.props.marketBased.industries2;
      if (industries2) {
        return industries2.include_keywords ? industries2.include_keywords : [];
      }
    }
    if (name === "department") {
      const departments = this.props.marketBased.departments;
      if (departments) {
        return departments.include_keywords ? departments.include_keywords : [];
      }
    }
    return [];
  };

  getExcludeKeywordsProp = (name) => {
    if (name === "role") {
      const role = this.props.marketBased.role;
      if (role) {
        return role.exclude_keywords ? role.exclude_keywords : [];
      }
    }
    if (name === "skills") {
      const skills = this.props.marketBased.skills;
      if (skills) {
        return skills.exclude_keywords ? skills.exclude_keywords : [];
      }
    }
    if (name === "industries2") {
      const industries2 = this.props.marketBased.industries2;
      if (industries2) {
        return industries2.exclude_keywords ? industries2.exclude_keywords : [];
      }
    }
    if (name === "department") {
      const departments = this.props.marketBased.departments;
      if (departments) {
        return departments.exclude_keywords ? departments.exclude_keywords : [];
      }
    }
    return [];
  };

  render() {
    const {
      active,
      setGroups,
      setIncludeTags,
      setExcludeTags,
      clearFields,
      updateClearFields,
      searchResultFilters,
      setCompanyFoundedYearFrom,
      setCompanyFoundedYearTo,
    } = this.props;

    return (
      <div className={`people-market ${active}`}>
        {companyDropdowns.map((item, index) => (
          <div className="people-market__col" key={index}>
            <p className="people-market__label">{item.label}</p>
            <Functions
              marketBased={this.props.marketBased}
              label={item.label}
              includeKeywords={this.getIncludeKeywordsProp(item.name)}
              excludeKeywords={this.getExcludeKeywordsProp(item.name)}
              selectedGroups={this.getParticularGroupsProp(item.name)}
              basicHeading1={item.basicHeading1}
              placeholder={item.placeholder}
              inputType={item.inputType}
              type={item.type}
              name={item.name}
              groups={item.group}
              customInput={item.customInput}
              showExclude={item.showExclude}
              haveTags={item.have_tags}
              technologies={item.technologies}
              setIncludeTags={setIncludeTags}
              setExcludeTags={setExcludeTags}
              setGroups={setGroups}
              clearFields={clearFields}
              updateClearFields={updateClearFields}
            />
          </div>
        ))}
        {/* <div className="people-market__col" key="founded-year">
          <CompanyFoundedYear
            setCompanyFoundedYearFrom={setCompanyFoundedYearFrom}
            setCompanyFoundedYearTo={setCompanyFoundedYearTo}
            searchResultFilters={searchResultFilters}
          />
        </div> */}
      </div>
    );
  }
}

export default CompanyMarket;
