import React, { Component } from 'react'
import { Link } from 'react-router-dom'

const items = [
  // {
  //   name: 'Hot Leads',
  //   slug: '/lead-search/hot-leads',
  //   icon: 'acc-2.svg',
  //   active: 'acc-2-hover.svg',
  // },
  {
    name: 'Market Based',
    slug: '/lead-search/market-based',
    icon: 'acc-2.svg',
    active: 'acc-2-hover.svg',
  },
  {
    name: 'Account Based',
    slug: '/lead-search/account-based',
    icon: 'acc-3.svg',
    active: 'acc-3-hover.svg',
  },
  {
    name: 'Saved Searches',
    slug: '/lead-search/saved-searches',
    icon: 'acc-4.svg',
    active: 'acc-4-hover.svg',
  },
  // {
  //   name: 'Ignore List',
  //   slug: '/ignore-list',
  //   icon: 'Ignore_List.svg',
  //   active: 'Ignore_List_hover.svg',
  // }
]


class LeadSearch extends Component {
  state = {
    path: window.location.pathname
  }

  componentDidMount = () => {
    let pathArray = window.location.pathname.split('/');
    let secondLevelLocation = pathArray[1];
    let thirdLevelLocation = pathArray[2];
    items.map(item => {
      if (item.slug === "/" + secondLevelLocation + "/" + thirdLevelLocation) {
        this.setState({ path: item.slug })
      }
    })
  }

  changePath = (slug) => {
    this.setState({ path: slug })
  }
  stateMaintain = () => {

  }
  render() {
    const { path } = this.state
    const { current } = this.props
    return (
      <ul className={`leads-search ${current ? "animation-slideDown" : ""}`} >
        {
          items.map((item, index) =>
            <Link to={item.slug} key={index}>
              <li className={`leads-search__item ${path === item.slug ? 'active' : ''}`} onClick={() => this.changePath(item.slug)}>
                <img src={`/assest/accountBased/${path === item.slug ? item.active : item.icon}`} alt="" />
                {item.name}
              </li>
            </Link>
          )
        }
      </ul>
    )
  }
}

export default LeadSearch