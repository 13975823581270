import React from 'react'
import OwnersContent from '../LeadListFilter/OwnersContent'

export const OwnerFilter = ({
  searchValue,
  searchOwnerFilter,
  ownersLists,
  changeCheck
}) => {
  return (
    <div className="other_option">
      <div className="coupon-search__inp sm_coupon-search__inp">
        <img src="/assest/accountBased/search.svg" alt="" />
        <input type="text"
          placeholder="Search Owners"
          value={searchValue}
          onInput={searchOwnerFilter} />
      </div>
      {ownersLists &&
        <div className="scroll_view">
          <OwnersContent ownersLists={ownersLists}
            changeCheck={changeCheck} />
        </div>
      }
    </div>
  )
}
