import React, {Fragment, Component} from 'react';
import Functions from '../../../MarkedBasedBox/MarketSearch/FieldsDropdown/Functions';
import inputsPeople from '../../../MarkedBasedBox/MarketSearch/PeopleMarket/inputsPeople'
import UploadBox from '../UploadBox';

class MergedSearch extends Component {

  state = {
    collapsed: false,
    uploadPopup: false,
    uploadLinkedinPopup: false,
    showAll: false,
  }
  toggle = () => {
    this.setState({collapsed: !this.state.collapsed});
  }

  onPenClick = () => {
    this.setState({uploadPopup: !this.state.uploadPopup});
  }

  onPenBulkLinkedinClick = () => {
    this.setState({uploadLinkedinPopup: !this.state.uploadLinkedinPopup});
  }

  closeUploadPopup = () => {
    this.setState({uploadPopup: !this.state.uploadPopup});
  }

  closeLinkedinUploadPopup = () => {
    this.setState({uploadLinkedinPopup: !this.state.uploadLinkedinPopup});
  }

  render() {
  const { firstName, lastName, disableName, linkedinUrlCompany } = this.props;
  return(
    <Fragment>
      {/* <div className="based-header__top" style={{borderTop: 'none'}}> */}
        {/* <h2 className={`adv-lead__title ${this.state.collapsed? "expend" : "collapse"}`}
        onClick={this.toggle}
        >Criteria</h2> */}
      {/* </div> */}

      {!this.state.collapsed && 
      <div className="companyDiv">
        <div className="people-market peoplePro">
            <div className="people-market__col">
                <p className="people-market__label">First name</p>
                <div>
                <input type="text" name="firstName" placeholder="Search by first name" value={firstName} disabled={disableName} onChange={this.props.onFirstNameChange} autoComplete="false"/>
                </div>
            </div>
            <div className="people-market__col">
                <p className="people-market__label">Last name</p>
                <div>
                <input type="text" name="lastName" placeholder="Search by last name" value={lastName}  disabled={disableName} onChange={this.props.onLastNameChange} autoComplete="false"/>
                </div>
            </div>
        
            <div className="people-market__col">
            <p className="people-market__label">Company or Domain Name</p>
            <Functions
                placeholder="Company or domain"
                inputType="text"
                name="company"
                groups={[]}
                includedTags={this.props.excludedCompanies}
                includeKeywords={this.props.excludedCompanies}
                customInput={true}
                showExclude={false}
                haveTags={false}
                setIncludeTags={(tags, name) => this.props.setIncludeTags(tags)}
                setExcludeTags={this.props.setExcludeTags}
                setGroups={this.props.setGroups}
                clearFields={this.props.clearFields}
                updateClearFields={this.props.updateClearFields}
                showPen={true}
                onPenClick={this.onPenClick}
            />
            </div>

            <div className="people-market__col">
                <p className="people-market__label">{inputsPeople[0].label}</p>
                <Functions
                label={inputsPeople[0].label}
                
                includeKeywords={this.props.includeKeywords}
                excludeKeywords={this.props.excludeKeywords}
                selectedGroups={this.props.selectedGroups}

                placeholder={inputsPeople[0].placeholder}
                inputType={inputsPeople[0].inputType}
                name={inputsPeople[0].name}
                customInput={inputsPeople[0].customInput}
                showExclude={inputsPeople[0].showExclude}
                groups={inputsPeople[0].group}
                haveTags={inputsPeople[0].have_tags}
                technologies={inputsPeople[0].technologies}
                setIncludeTags={this.props.setIncludeTags}
                setExcludeTags={this.props.setExcludeTags}
                setGroups={this.props.setGroups}
                clearFields={this.props.clearFields}
                updateClearFields={this.props.updateClearFields}
                />
            </div>

{/*             
            <div className="people-market__col">
                <p className="people-market__label">Linked URL</p>
                <div>
                <input type="text" name="linkedinUrl" placeholder="Search by Linked URL" value={linkedinUrl} onChange={this.props.onLinkedinURLChange} autoComplete="false"/>
                </div>
            </div> */}

            <div className="people-market__col">
                <p className="people-market__label">Linkedin URL</p>
                <div>
                   <input type="text" placeholder="Insert a personal or company LinkedIn URL" onChange={this.props.onLinkedinURLCompanyChange} autoComplete="false"/>
                   <i
                    className="fa fa-paperclip"
                    aria-hidden="true"
                    onClick={this.onPenBulkLinkedinClick}
                    style={{
                      position: "absolute",
                      top: "35px",
                      right: "42px",
                      cursor: "pointer",
                      color: "#bdbdbd",
                    }}
                  ></i>           
                </div>
                <div>
                <div className="people-market-field">
                  <div className="people-market-field__box">
                    {linkedinUrlCompany && linkedinUrlCompany.map((item, index) => {
                    
                    if (this.state.showAll) {
                      return (<Fragment key={index}>
                        <p
                          className="people-market-field__group"
                          onClick={() =>{}}
                        >
                          {item}
                          <img
                            src="/assest/accountBased/del-tag.svg"
                            alt=""
                            onClick={(e) => this.props.delLinkedInRow(item)}
                          />
                        </p>
                      </Fragment>)
                    } else {
                      if (index < 10) {
                        return (<Fragment key={index}>
                        <p
                          className="people-market-field__group"
                          onClick={() =>{}}
                        >
                          {item}
                          <img
                            src="/assest/accountBased/del-tag.svg"
                            alt=""
                            onClick={(e) => this.props.delLinkedInRow(item)}
                          />
                        </p>
                      </Fragment>)
                      }                      
                      if (index == 10) {
                       return (<Fragment key={index}>
                        <p
                          className="people-market-field__group"
                          onClick={() =>{this.setState({showAll:true})}}
                        >
                          {`Show more +`}                         
                        </p>
                      </Fragment>)                          
                      }
                    }             
                    })}
                  </div>
                </div>
                </div>
            </div>
        </div>

      </div>
    }

    <UploadBox 
      setCompanyTags={this.props.setCompanyTags} 
      uploadPopup={this.state.uploadPopup} 
      setLinkedinUrlCompany={this.props.setLinkedinUrlCompany} 
      uploadLinkedinPopup={this.state.uploadLinkedinPopup} 
      onCloseUploadPopup={this.closeUploadPopup}  
      onCloseLinkedinUploadPopup={this.closeLinkedinUploadPopup} 
    />

    </Fragment>
  )
}
}

export default MergedSearch;