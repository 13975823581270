// export const estimatedSalary = [
//   {
//     category: 150000,
//     label: "$150,000-250,000",
//     group: "150,000-250,000",
//     include_tags: [],
//     exclude_tags: []
//   },
//   {
//     category: 35000,
//     label: "$35,000-45,000",
//     group: "35,000-45,000",
//     include_tags: [],
//     exclude_tags: []
//   },
//   {
//     category: 20000,
//     label: "$20,000-25,000",
//     group: "20,000-25,000",
//     include_tags: ["Customer Service"],
//     exclude_tags: []
//   },
//   {
//     category: 100000,
//     label: "$100,000-150,000",
//     group: "100,000-150,000",
//     include_tags: [],
//     exclude_tags: []
//   },
//   {
//     category: 55000,
//     label: "$55,000-70,000",
//     group: "55,000-70,000",
//     include_tags: [],
//     exclude_tags: []
//   },
//   {
//     category: 25000,
//     label: "$25,000-35,000",
//     group: "25,000-35,000",
//     include_tags: [],
//     exclude_tags: []
//   },
//   {
//     category: 250000,
//     label: ">$250,000",
//     group: ">250,000",
//     include_tags: [],
//     exclude_tags: []
//   },
//   {
//     category: 85000,
//     label: "$85,000-100,000",
//     group: "85,000-100,000",
//     include_tags: [],
//     exclude_tags: []
//   },
//   {
//     category: 45000,
//     label: "$45,000-55,000",
//     group: "45,000-55,000",
//     include_tags: [],
//     exclude_tags: []
//   },
//   {
//     category: 70000,
//     label: "$70,000-85,000",
//     group: "70,000-85,000",
//     include_tags: [],
//     exclude_tags: []
//   },
//   {
//     category: 19000,
//     label: "<$20,000",
//     group: "<20,000",
//     include_tags: [],
//     exclude_tags: []
//   },
//   {
//     category: "Unknown",
//     label: "$Unknown",
//     group: "Unknown",
//     include_tags: [],
//     exclude_tags: []
//   },
// ];

export const estimatedSalary = [
  {
    category: 19000,
    label: "Less than $20,000",
    group: "Less than $20,000",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 20000,
    label: "$20,000 to $44,999",
    group: "$20,000 to $44,999",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 45000,
    label: "$45,000 to $59,999",
    group: "$45,000 to $59,999",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 60000,
    label: "$60,000 to $74,999",
    group: "$60,000 to $74,999",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 75000,
    label: "$75,000 to $99,999",
    group: "$75,000 to $99,999",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 100000,
    label: "$100,000 to $149,999",
    group: "$100,000 to $149,999",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 150000,
    label: "$150,000 to $199,999",
    group: "$150,000 to $199,999",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 200000,
    label: "$200,000 to $249,999",
    group: "$200,000 to $249,999",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 250000,
    label: "$250,000+",
    group: "$250,000+",
    include_tags: [],
    exclude_tags: []
  }
];
