import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {BreadCrumb, IntegrationsContent, IntegrationsRight, Loader, RightPanelBanner} from '../../components'
import './style.scss'
import {startLoader, stopLoader} from '../../actions/loader'
import {deleteIntegrations, getUsersIntegrations} from '../../services/integrations'
import {setErrorMessage, setSuccessMessage} from '../../actions/error_msg'
import {toast} from 'react-toastify';
import {checkIntegrations, integrations} from '../../integrations'
import {initSpreedSheet} from '../../services/leads';
import {LOCAL_STORAGE_KEYS} from '../../constants/constant'
import {EmitterService} from "../../services/emitterService";
import {showErrorToast, showSuccessToast} from '../../helpers/toast';

class Integrations extends Component {
    clonedIntegrations = JSON.parse(JSON.stringify(integrations));
    clonedCheckIntegrations = JSON.parse(JSON.stringify(checkIntegrations));

    state = {
        integrationsData: this.clonedIntegrations,
        activeIntegrations: [],
        popup: false,
        nameForPopup: '',
        popup2: false,
        settingPopup: false,
        integID: '',
        popupDuxSoup: false,
        popupFreshSales: false,
        checks: this.clonedCheckIntegrations,
        filterFlag: false,
        count: 0,
        searchBar: "",
        toggelIndex: '',
        activeIntegCheck: false,
        totalIntegrations: this.clonedIntegrations.length,
        apiLocation: "",
        integrationLabel: "",
        image: ''
    }

    componentDidMount = () => {
        this.getIntegrations()
        this.redirectedByAuth()
    }

    componentWillMount() {
        EmitterService.emit("loadLeadsRemaining");
    }


    redirectedByAuth = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('status');
        if (status === "OK") {
            toast.success("Integration turned on successfully", {
                className: "toast-success-div",
                autoClose: 4000,
            })
        } else if (status === "error") {
            toast.error("Unable to turn on integration", {
                className: "toast-error-div",
                autoClose: 4000
            })
        }
    }

    getIntegrations = async () => {
        const {startLoader, stopLoader} = this.props
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        startLoader(true)
        try {
            const res = await getUsersIntegrations(userId.id);
            this.setState({activeIntegrations: res.data.data});
            this.settingIntegrationToFalse();
            this.ActiveIntegration();
        } catch (error) {
            toast.error("Unable to load integrations", {
                className: "toast-error-div",
                autoClose: 4000
            })
        }
        stopLoader(false)
    }

    settingIntegrationToFalse = () => {
        const {integrationsData} = this.state
        for (let i = 0; i < integrationsData.length; i++) {
            integrationsData[i].active = false
        }
        this.setState({integrationsData: integrationsData})
    }

    ActiveIntegration = () => {
        const {integrationsData, activeIntegrations} = this.state
        let activeCheck = false
        activeIntegrations.forEach((item) => {
            for (let i = 0; i < integrationsData.length; i++) {
                if (integrationsData[i].name === item.name) {
                    integrationsData[i].active = true
                    activeCheck = true
                    integrationsData[i].activeID = item._id
                }
            }
            this.setState({integrationsData: integrationsData, activeIntegCheck: activeCheck})
        });
    }

    addActiveIntegration = (integration) => {
        this.setState(ps => ({
            ...ps,
            activeIntegrations: [...ps.activeIntegrations, integration],
            integrationsData: ps.integrationsData.map(itg => {
                return itg.name === integration.name?
                    {
                        ...itg,
                        activeCheck: true,
                        active: true,
                        activeID: integration._id
                    }: itg;
            })
        }));
    }

    integrationSearchHandler = (e) => {
        const {integrationsData} = this.state
        let searchBar = e.target.value
        if (searchBar === "") {
            this.setState({integrationsData: this.clonedIntegrations})
        } else {
            let items = this.clonedIntegrations.filter(item => {
                return item.name.includes(searchBar);
            });
            // integrationsData.forEach(item => {
            //     if (item.name.includes(searchBar)) {
            //         items = items.concat(item)
            //     }
            // })
            this.setState({integrationsData: items})
        }
    }
    changeCheck = (i) => {
        const {checks, integrationsData, count, totalIntegrations} = this.state
        var numOfIntegrations = 0
        checks[i].checked = !checks[i].checked
        //all integrations will be deselected
        integrationsData.forEach((i) => {
            i.checked = false
        })
        checks.forEach((c) => {
            if (c.checked) {
                integrationsData.forEach(data => {
                    var matched = false
                    for (let n = 0; n < data.tags.length; n++) {
                        if (data.tags[n] === c.name && !data.checked) {
                            data.checked = true
                            matched = true
                        }
                    }
                    if (matched) {
                        numOfIntegrations++
                    }
                })
            }

        })
        if (numOfIntegrations == 0) {
            integrationsData.forEach((i) => {
                i.checked = true
            })
            numOfIntegrations = integrationsData.length
        }
        this.setState({
            checks: checks,
            integrationsData: integrationsData,
            filterFlag: true,
            count: (count + 1),
            totalIntegrations: numOfIntegrations
        })
    }

    unactiveChecks = () => {
        const {checks, count, integrationsData} = this.state
        integrationsData.forEach((i) => {
            i.checked = true
        })
        checks.forEach((c) => {
            c.checked = false
        })

        this.setState({
            checks: checks,
            integrationsData: integrationsData,
            totalIntegrations: integrationsData.length,
            count: 0
        })

    }


    openSheets = async (toastId) => {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        try {
            const res = await initSpreedSheet(user, "", "/integrations", "googlesheets", false, false,
                false, false, false)
            window.location.href = res.data.data
        } catch (error) {
            showErrorToast(toastId, "Unable to turn on integration");
        }
    }
    openIntegrationPopup = (name, setting, integID, index, label, image) => {
        const {integrationsData} = this.state
        if (index !== "" && !setting && name !== "close") {
            integrationsData[index].active = !integrationsData[index].active
            this.setState({integrationsData: integrationsData})
            if (name == "googlesheets") {
                let toastId = null;
                toastId = toast('Redirecting to integration', {
                    autoClose: 12000,
                });
                setTimeout(() => {
                    this.openSheets(toastId)
                    setTimeout(() => {
                        integrationsData[index].active = !integrationsData[index].active
                        this.setState({integrationsData: integrationsData})
                    }, 3000)
                }, 500)
            } else if (name === "zap") {
                if (integrationsData[index].active) {
                    setTimeout(() => {
                        window.open('https://zapier.com/apps/leadfuze/integrations', '_blank');
                        integrationsData[index].active = !integrationsData[index].active
                        this.setState({integrationsData: integrationsData})
                    }, 500)
                }
            } else if (name === "appypie") {
                if (integrationsData[index].active) {
                    setTimeout(() => {
                        window.open('https://www.appypie.com/connect/apps/leadfuze/integrations', '_blank');
                        integrationsData[index].active = !integrationsData[index].active
                        this.setState({integrationsData: integrationsData})
                    }, 500)
                }
            } else {
                setTimeout(() => {
                    this.setState({
                        toggelIndex: index,
                        popup: !this.state.popup,
                        nameForPopup: name,
                        settingPopup: setting,
                        integID: integID,
                        integrationLabel: label,
                        image: image
                    })
                }, 0)
            }
        } else if (name === "close") {
            if (index !== '') {
                integrationsData[index].active = !integrationsData[index].active
            }
            this.setState({integrationsData: integrationsData})
            setTimeout(() => {
                this.setState({popup: !this.state.popup})
            }, 0)
        } else {
            this.setState({
                popup: !this.state.popup,
                nameForPopup: name,
                integrationLabel: label,
                settingPopup: setting,
                integID: integID,
                toggelIndex: '',
                image: image
            })
        }
    }

    openIntegrationPopup2 = (name, setting, integID, index, apiLocation, label, image) => {
        const {integrationsData} = this.state
        if (index !== '' && !setting && name !== "close") {
            integrationsData[index].active = !integrationsData[index].active
            this.setState({integrationsData: integrationsData})
            setTimeout(() => {
                this.setState({
                    toggelIndex: index,
                    popup2: (name == "googlesheets") ? false : !this.state.popup2,
                    nameForPopup: name,
                    settingPopup: setting,
                    integID: integID,
                    apiLocation: apiLocation,
                    integrationLabel: label,
                    image: image
                })
            }, 0)
        } else if (name === "close") {
            if (index !== '') {
                integrationsData[index].active = !integrationsData[index].active
            }
            this.setState({integrationsData: integrationsData})
            setTimeout(() => {
                this.setState({popup2: !this.state.popup2})
            }, 0)
        } else {
            this.setState({
                popup2: !this.state.popup2,
                nameForPopup: name,
                settingPopup: setting,
                integID: integID,
                toggelIndex: '',
                apiLocation: apiLocation,
                integrationLabel: label,
                image: image
            })
        }
    }

    openDuxSoupPopup = (name, setting, integID, index, image) => {
        const {integrationsData} = this.state
        if (index !== '' && !setting && name !== "close") {
            integrationsData[index].active = !integrationsData[index].active
            this.setState({integrationsData: integrationsData})
            setTimeout(() => {
                this.setState({
                    toggelIndex: index,
                    popupDuxSoup: !this.state.popupDuxSoup,
                    nameForPopup: name,
                    settingPopup: setting,
                    integID: integID,
                    image: image
                })
            }, 0)
        } else if (name === "close") {
            if (index !== '') {
                integrationsData[index].active = !integrationsData[index].active
            }
            this.setState({integrationsData: integrationsData})
            setTimeout(() => {
                this.setState({popupDuxSoup: !this.state.popupDuxSoup})
            }, 0)
        } else {
            this.setState({
                popupDuxSoup: !this.state.popupDuxSoup,
                nameForPopup: name,
                settingPopup: setting,
                integID: integID,
                toggelIndex: '',
                image: image
            })
        }
    }
    openFreshSalesPopup = (name, setting, integID, index, image) => {
        const {integrationsData} = this.state
        if (index !== '' && !setting && name !== "close") {
            integrationsData[index].active = !integrationsData[index].active
            this.setState({integrationsData: integrationsData})
            setTimeout(() => {
                this.setState({
                    toggelIndex: index,
                    popupFreshSales: !this.state.popupFreshSales,
                    nameForPopup: name,
                    settingPopup: setting,
                    integID: integID,
                    image: image
                })
            }, 0)
        } else if (name === "close") {
            if (index !== '') {
                integrationsData[index].active = !integrationsData[index].active
            }
            this.setState({integrationsData: integrationsData})
            setTimeout(() => {
                this.setState({popupFreshSales: !this.state.popupFreshSales})
            }, 0)
        } else {
            this.setState({
                popupFreshSales: !this.state.popupFreshSales,
                nameForPopup: name,
                settingPopup: setting,
                integID: integID,
                toggelIndex: '',
                image: image
            })
        }
    }

    turningOffIntegrationSwitch = async (index, activeID) => {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        const {integrationsData} = this.state

        let toastId = null;
        toastId = toast(`Turning off integration`, {
            autoClose: 10000,
        });

        try {
            await deleteIntegrations(user.id, activeID)
            this.getIntegrations()
            this.setState(ps => ({ ...ps, integrationsData: ps.integrationsData.map((itg, itgIndex) => {
                return index === itgIndex?
                    {
                        ...itg,
                        active: itg.active
                    }: itg
            })}));    
            showSuccessToast(toastId, "Integration turned off successfully");
        } catch {
            showErrorToast(toastId, "Unable to turn off integration");
        }
    }

    render() {
        const {totalIntegrations, activeIntegrations, activeIntegCheck, toggelIndex, image, searchBar, filterFlag, checks, popupFreshSales, popupDuxSoup, integID, integrationsData, popup, nameForPopup, popup2, settingPopup, integrationLabel, apiLocation} = this.state
        const {loader} = this.props
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT));

        const links = [
            {
                name: 'Settings',
                slug: '/api-keys',
            },
            {
                name: 'Integrations',
                slug: null,
            },
        ]

        return (
            <Fragment>
                <BreadCrumb links={links}></BreadCrumb>

                <div className="integration-panel">
                    {userId === null && this.props.history.push('/signin?path=/integrations')}

                    {userAccount.status === "trial_expired" && this.props.history.push('/billing-and-users')}

                    <div className="integration-panel__left">

                        {loader.loading && <Loader/>}

                        <IntegrationsContent
                            activeIntegrations={activeIntegrations}
                            totalIntegrations={totalIntegrations}
                            activeIntegCheck={activeIntegCheck}
                            getIntegrations={this.getIntegrations}
                            integrationsData={integrationsData}
                            turningOffIntegrationSwitch={this.turningOffIntegrationSwitch}
                            popup={popup}
                            popup2={popup2}
                            openIntegrationPopup2={this.openIntegrationPopup2}
                            openIntegrationPopup={this.openIntegrationPopup}
                            nameForPopup={nameForPopup}
                            settingPopup={settingPopup}
                            integID={integID}
                            popupDuxSoup={popupDuxSoup}
                            openDuxSoupPopup={this.openDuxSoupPopup}
                            openFreshSalesPopup={this.openFreshSalesPopup}
                            popupFreshSales={popupFreshSales}
                            filterFlag={filterFlag}
                            integrationSearchHandler={this.integrationSearchHandler}
                            searchBar={searchBar}
                            toggelIndex={toggelIndex}
                            image={image}
                            apiLocation={apiLocation}
                            integrationLabel={integrationLabel}
                            addActiveIntegration={this.addActiveIntegration}
                        />
                    </div>

                    <div className="integration-panel__right">
                        <RightPanelBanner title={'Integrations Demo'} page="integrations" type="integrations"
                                          video_id="n9s6vf1v1l"/>

                        <IntegrationsRight
                            changeCheck={this.changeCheck}
                            unactiveChecks={this.unactiveChecks}
                            integrationSearchHandler={this.integrationSearchHandler}
                            checks={checks}/>
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        loader: state.loader,
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        startLoader,
        stopLoader,
        setSuccessMessage,
        setErrorMessage
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Integrations)
