import axios from '../axios';
import { SearchType } from "../constants/constant";
import { convertLocationFromPropsToStats, convertLocationFromStateToProps, getOnlyMarketPlaceObjectFromProps } from '../constants/helper';
const queryString = require('query-string');
const REACT_APP_POOL_SERVICE_URL = process.env.REACT_APP_POOL_SERVICE_URL;
const REACT_APP_ROOT_URL = process.env.REACT_APP_ROOT_URL;
const REACT_APP_PROCESS_SERVICE_URL = process.env.REACT_APP_PROCESS_SERVICE_URL;

export function getSearchResults(marketBased, accountBased, filterOptions, queryParams) {
  const queryStr = queryString.stringify(queryParams);
  let marketBasedObj = getOnlyMarketPlaceObjectFromProps(marketBased, filterOptions);
  let object = {};
  let url = '';
  
  if (filterOptions.searchType === SearchType.MarketBased) {
    object = {
      ...marketBasedObj,
    }
    url = `/process/person/market-search?${queryStr}`;
  } else {
    object = accountBased;
    url = `/process/person/account-search?${queryStr}`;
  }

  const data = {
    ...filterOptions,
    ...object,
  };

  sessionStorage.setItem("searchResultQuery", JSON.stringify(data));
  return axios({
    method: "post",
    data: data,
    url,
  });
}

export function getLeadLists(pageNumber, recordsPerPage, search) {
  const user = JSON.parse(localStorage.getItem("user"));
  const searchParam = (search && search != '') ? "&search=" + search : "";

  return axios({
    method: "get",
    headers: {
      userID: user.id,
      accountID: user.account_id
    },
    url: `/core/lists/names?pageNum=${pageNumber}&recordsPerPage=${recordsPerPage}${searchParam}`
  });
}

export function createLeadList(listName) {
  const user = JSON.parse(localStorage.getItem("user"));

  return axios({
    method: "post",
    headers: {
      userID: user.id,
      accountID: user.account_id
    },
    data: {
      Name: listName
    },
    url: `/core/lists`
  });
}

export function getLeadDetail(leadId, fullData) {
  const user = JSON.parse(localStorage.getItem("user"));
  const fullQueryParam = fullData ? "?full=yes" : "";
  
  return axios({
    method: "get",
    headers: {
      userID: user.id,
      accountID: user.account_id
    },
    url: `/process/person/${leadId}${fullQueryParam}`
  });
}

export function getCompanyEmployees(companyId, queryParams) {
  const user = JSON.parse(localStorage.getItem("user"));
  const queryStr = queryString.stringify(queryParams);
  
  return axios({
    method: "get",
    headers: {
      userID: user.id,
      accountID: user.account_id
    },
    url: `/pool/person/company/${companyId}?${queryStr}`
  });
}

//New company APIs after seprating the company idex on ES > start
export function getCompany(companyId, queryParams) {
 const user = JSON.parse(localStorage.getItem("user"));
 const queryStr = queryString.stringify(queryParams);
 
 return axios({
   method: "get",
   headers: {
     userID: user.id,
     accountID: user.account_id
   },
   url: `/pool/company/${companyId}?${queryStr}`
  });
}

export function getCompanyNews(companyId, queryParams) {
  const user = JSON.parse(localStorage.getItem("user"));
  const queryStr = queryString.stringify(queryParams);

  return axios({
    method: "get",
    headers: {
      userID: user.id,
      accountID: user.account_id
    },
    url: `/pool/company/${companyId}/news?${queryStr}`
  });
}

 
export function getCompanyTechnologies(companyId, queryParams) {
  const user = JSON.parse(localStorage.getItem("user"));
  const queryStr = queryString.stringify(queryParams);
  return axios({
    method: "get",
    headers: {
      userID: user.id,
      accountID: user.account_id
    },
    url: `/pool/company/${companyId}/technologies?${queryStr}`
  });
}

export function getCompanyHiring(companyId, queryParams) {
  const user = JSON.parse(localStorage.getItem("user"));
  const queryStr = queryString.stringify(queryParams);
  return axios({
    method: "get",
    headers: {
      userID: user.id,
      accountID: user.account_id
    },
    url: `/pool/company/${companyId}/hiring?${queryStr}`
  });
}
//New company APIs after seprating the company idex on ES > end

export function skipLead(leadIds) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios({
    method: "post",
    headers: {
      userID: user.id,
      accountID: user.account_id
    },
    data: {
      ids: leadIds
    },
    url: `/pool/account-growth/skip`
  });
}

export function unSkipSingleLead(leadId) {
  const user = JSON.parse(localStorage.getItem("user"));

  return axios({
    method: "delete",
    headers: {
      userID: user.id,
      accountID: user.account_id
    },
    url: `/pool/account-growth/skip/${leadId}`
  });
}

export function getActivities(leadId) {
  const user = JSON.parse(localStorage.getItem("user"));

  return axios({
    method: "get",
    headers: {
      userID: user.id,
      accountID: user.account_id
    },
    url: `/core/lead-activity/lead/${leadId}`
  });
}

export function getUnseenActivitiesCount(leadId) {
  const user = JSON.parse(localStorage.getItem("user"));

  return axios({
    method: "get",
    headers: {
      userID: user.id,
      accountID: user.account_id
    },
    url: `/core/lead-activity/lead/${leadId}/count`
  });
}

export function addLeads(listId, leadIds, addMax, people_prospector, people_lookup, type, attach_fuzebot = false) {
  const user = JSON.parse(localStorage.getItem("user"));
  let query = {};
  if (addMax) {
    query = JSON.parse(sessionStorage.getItem("searchResultQuery"));
    leadIds = [];
  }

  query = {
    people_prospector: people_prospector,
    people_lookup: people_lookup,
    type: type,
    attach_fuzebot:attach_fuzebot
  }
  return axios({
    method: "post",
    headers: {
      userID: user.id,
      accountID: user.account_id
    },
    data: {
      list_id: listId,
      selected_ids: leadIds,
      add_max: addMax,
      query: query
    },
    url: `/process/person`
  });
}

export function getPersonDetailForAddedLead(leadId) {
  const user = JSON.parse(localStorage.getItem("user"));
  
  return axios({
    method: "get",
    headers: {
      userID: user.id,
      accountID: user.account_id
    },
    url: `/core/pLeads/${leadId}`
  });
}