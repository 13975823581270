import React, { Component } from 'react';
class SearchPopup extends Component {
    render() {
        const { active, columnValue, sheetNames, loading } = this.props;
        return (
            <div className={`add-list-name ${active ? 'active' : ''}`}>
                <div className="add-list-name__search">
                    <img src="/assest/accountBased/search.svg" alt="" />
                    <input placeholder="Search your existing sheets" type="text" />
                </div>
                <div className="add-list-name__row">
                    { loading && <p className="add-list-name__text loader">Loading...</p>}
                    { !loading && sheetNames.length > 0 &&
                        sheetNames.map((item, index) =>
                            <div className="add-list-name__item" key={index} onClick={() => { columnValue(item.name, item.id, item.export_columns) }}>
                                <p className="add-list-name__edit">{item.name} </p>
                            </div>
                        )
                    }
                    { !loading && sheetNames.length == 0 &&
                        <p className="add-list-name__text loader">No matching results</p>
                    }
                </div>
            </div>
        )
    }
}

export default SearchPopup;
