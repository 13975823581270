import React, {Component} from 'react';

class PhoneCheck extends Component {

    render() {
        const {
            setRequirePhoneNumber,
            setRequireCompanyPhoneNumber,
            setRequirePersonalPhoneNumber,
            searchResultFilters
        } = this.props;

        return (
            <div className="right-filters__social">
                <div className="right-filters__switch" onClick={setRequirePhoneNumber}>
                    <div className={`switch ${searchResultFilters.require_phone_number ? 'active' : ''}`}>
                        <span></span></div>
                    <p className="switch__name">Require Phone Number?</p>
                </div>
                {searchResultFilters.require_phone_number &&
                <div className="right-filters__socChecks actCheck">
                    <div className="right-filters__check" onClick={setRequireCompanyPhoneNumber}>
                        <div
                            className={`check ${searchResultFilters.require_company_phone_number ? 'active' : ''}`}></div>
                        <p className={`${searchResultFilters.require_company_phone_number ? 'right-filters__checked' : ''}`}>Require
                            Company Phone Number</p>
                    </div>

                    <div className="right-filters__check" onClick={setRequirePersonalPhoneNumber}>
                        <div
                            className={`check ${searchResultFilters.require_personal_phone_number ? 'active' : ''}`}></div>
                        <p className={`${searchResultFilters.require_personal_phone_number ? 'right-filters__checked' : ''}`}>Require
                            Mobile Number</p>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default PhoneCheck