import React from 'react'
import items from './items'

const CouponsHeader = props =>{
  const { changeAllCheck, allCheck } = props
  return(
    <div className="coupon-header">
      <div className="coupon-header__row">
        <div className="coupon-header__check">
          <div className={`check ${allCheck ? 'active' : ''}`} onClick={changeAllCheck}></div>
          <img src="/assest/arr.svg" alt=""/>
        </div>
        <div className="coupon-header__items">
          {
            items.map((item, index)=>
              <div className={`coupon-header__col ${item.clname}`} key={index}>
                <p>{item.name}</p>
                {
                  item.icon ? <img src="/assest/accountBased/sort.svg" alt=""/> : ''
                }
              </div>
            )
          }
        </div>
        <div className="coupon-header__close"></div>
      </div>
    </div>
  )
}

export default CouponsHeader