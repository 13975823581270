import React from 'react'
import './style.scss'
import Activated from './Activated'
import AllIntegrations from './AllIntegrations'
import IntegrationPopups from '../IntegrationPopups'
import PopupForTokenIntegrations from '../IntegrationPopupsTwo'
import DuxSoupPopup from '../DuxSoupPopup'
import FreshSalesPopup from '../FreshSalesPopup'

const IntegrationsContent = (props) => {
    const {
        activeIntegrations,
        totalIntegrations,
        turningOffIntegrationSwitch,
        toggelIndex,
        image,
        integrationSearchHandler,
        filterFlag,
        openFreshSalesPopup,
        popupFreshSales,
        openDuxSoupPopup,
        popupDuxSoup,
        integID,
        settingPopup,
        getIntegrations,
        integrationsData,
        openIntegrationPopup,
        popup,
        popup2,
        openIntegrationPopup2,
        nameForPopup,
        apiLocation,
        integrationLabel,
        addActiveIntegration,
    } = props
    return (
        <section className="integrations">
            <div className="integrations-search">
                {/* <input className="integrations-search__inp"
                       type="text"
                       placeholder="Search Integration"
                       onChange={integrationSearchHandler}/> */}
                <p className="integrations-search__text">All Available Integrations : {totalIntegrations}</p>
            </div>
            <div className="integrations__table">
                <Activated
                    activeIntegrations={activeIntegrations}
                    integrationsData={integrationsData}
                    turningOffIntegrationSwitch={turningOffIntegrationSwitch}
                    getIntegrations={getIntegrations}
                    openIntegrationPopup={openIntegrationPopup}
                    popup={popup}
                    popup2={popup2}
                    openIntegrationPopup2={openIntegrationPopup2}
                    nameForPopup={nameForPopup}
                    settingPopup={settingPopup}
                    integID={integID}
                    image={image}
                    popupDuxSoup={popupDuxSoup}
                    openDuxSoupPopup={openDuxSoupPopup}
                    popupFreshSales={popupFreshSales}
                    openFreshSalesPopup={openFreshSalesPopup}
                    filterFlag={filterFlag}
                    toggelIndex={toggelIndex}
                    apiLocation={apiLocation}
                    integrationLabel={integrationLabel}
                />

                <AllIntegrations
                    activeIntegrations={activeIntegrations}
                    integrationsData={integrationsData}
                    turningOffIntegrationSwitch={turningOffIntegrationSwitch}
                    getIntegrations={getIntegrations}
                    openIntegrationPopup={openIntegrationPopup}
                    popup2={popup2}
                    openIntegrationPopup2={openIntegrationPopup2}
                    popup={popup}
                    nameForPopup={nameForPopup}
                    settingPopup={settingPopup}
                    integID={integID}
                    image={image}
                    popupDuxSoup={popupDuxSoup}
                    openDuxSoupPopup={openDuxSoupPopup}
                    popupFreshSales={popupFreshSales}
                    openFreshSalesPopup={openFreshSalesPopup}
                    filterFlag={filterFlag}
                    toggelIndex={toggelIndex}
                    apiLocation={apiLocation}
                    integrationLabel={integrationLabel}
                />
            </div>
            
            {popup &&
            <IntegrationPopups activeIntegrations={activeIntegrations} toggelIndex={toggelIndex} integID={integID}
                                image={image}
                                settingPopup={settingPopup} getIntegrations={getIntegrations}
                                openIntegrationPopup={openIntegrationPopup} popup={popup}
                                nameForPopup={nameForPopup} integrationLabel={integrationLabel}/>
            }
            {popup2 &&
            <PopupForTokenIntegrations activeIntegrations={activeIntegrations} toggelIndex={toggelIndex} image={image}
                                        integID={integID} settingPopup={settingPopup}
                                        getIntegrations={getIntegrations} popup2={popup2} nameForPopup={nameForPopup}
                                        openIntegrationPopup2={openIntegrationPopup2} apiLocation={apiLocation} integrationLabel={integrationLabel}/>
            }
            {popupDuxSoup &&
            <DuxSoupPopup activeIntegrations={activeIntegrations} toggelIndex={toggelIndex} integID={integID} image={image}
                            settingPopup={settingPopup} getIntegrations={getIntegrations} popupDuxSoup={popupDuxSoup}
                            nameForPopup={nameForPopup} openDuxSoupPopup={openDuxSoupPopup}
                            addActiveIntegration={addActiveIntegration}
                            connection_message="" followup_message=""/>
            }
            {popupFreshSales &&
            <FreshSalesPopup activeIntegrations={activeIntegrations} toggelIndex={toggelIndex} integID={integID} image={image}
                                settingPopup={settingPopup} getIntegrations={getIntegrations}
                                popupFreshSales={popupFreshSales} nameForPopup={nameForPopup}
                                openFreshSalesPopup={openFreshSalesPopup}/>
            }
        </section>
    )
}
export default IntegrationsContent
