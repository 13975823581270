// const OnePlus = "1 to 10 Employees";
// const TenPlus = "11 to 50 Employees";
// const FiftyPlus = "51 to 200 Employees";
// const TwoHundredPlus = "201 to 500 Employees";
// const FiveHundredPlus = "501 to 1,000 Employees";
// const OneThousandPlus = "1,001 to 5,000 Employees";
// const FiveThousandPlus = "5,001 to 10,000 Employees";
// const TenThousandPlus = "10,000+ Employees";

// export const employee_size = [
//   {
//     category: "Small",
//     label: "Small Business",
//     group: "Small Business",
//     include_tags: [OnePlus, TenPlus, FiftyPlus, TwoHundredPlus],
//     exclude_tags: []
//   },
//   {
//     category: "MidSize",
//     label: "Mid Size Business",
//     group: "Mid Size Business",
//     include_tags: [FiveHundredPlus],
//     exclude_tags: []
//   },
//   {
//     category: "Enterprise",
//     label: "Enterprise",
//     group: "Enterprise",
//     include_tags: [OneThousandPlus, FiveThousandPlus, TenThousandPlus],
//     exclude_tags: []
//   }
// ];

const OnePlus = "1 to 10";
const TenPlus = "11 to 25";
const TwentyFivePlus = "26 to 50";
const FiftyPlus = "51 to 100";
const OneHundredPlus = "101 to 250";
const TwoHundredPlus = "251 to 500";
const FiveHundredPlus = "501 to 1000";
const OneThousandPlus = "1001 to 5000";
const FiveThousandPlus = "5001 to 10000";
const TenThousandPlus = "10000+";

export const employee_size = [
  {
    category: "Small",
    label: "Small Business",
    group: "Small Business",
    include_tags: [OnePlus, TenPlus, TwentyFivePlus, FiftyPlus, OneHundredPlus],
    exclude_tags: []
  },
  {
    category: "MidSize",
    label: "Mid Size Business",
    group: "Mid Size Business",
    include_tags: [TwoHundredPlus, FiveHundredPlus],
    exclude_tags: []
  },
  {
    category: "Enterprise",
    label: "Enterprise",
    group: "Enterprise",
    include_tags: [OneThousandPlus, FiveThousandPlus, TenThousandPlus],
    exclude_tags: []
  }
];
