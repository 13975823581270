import React, { Component } from 'react'
import FieldCheck from './FieldCheck'
import '../style.scss'

const checks = [
  { name: 'Annual', check: false },
  { name: 'Monthly', check: false },
  { name: 'Commitment', check: false },
]

class CouponCreate extends Component {
  state = {
    switchI: false,
    checks: checks,
    monthlyAllotment: false,
    expire: false,
    oneTimeAllotment: false
  }

  changeSwitch = () => {
    this.setState({ switchI: !this.state.switchI })
  }

  changeCheck = (i) => {
    const { checks } = this.state
    checks[i].check = !checks[i].check
    this.setState({ checks: checks })
  }

  changeFieldCheck = (name) => {
    name === 'monthlyAllotment' ? this.setState({ monthlyAllotment: !this.state.monthlyAllotment }) :
      name === 'expire' ? this.setState({ expire: !this.state.expire }) :
        this.setState({ oneTimeAllotment: !this.state.oneTimeAllotment })
  }

  render() {
    const { switchI, checks, monthlyAllotment, oneTimeAllotment, expire } = this.state
    const { addCoupon, closeAddCoupon } = this.props
    return (
      <section className={`coupon create ${addCoupon ? 'active' : ''}`}>
        <div className="coupon__title">
          <h3>Add New Coupon</h3>
        </div>
        <div className="coupon-cont">
          <div className="coupon-cont__row">
            <label className="coupon-cont__label coupon__col">
              <p>Generate new code</p>
              <input type="text" placeholder="AMVG9KdQ" />
            </label>
            <div className="coupon-cont__switch coupon__col">
              <div className={`switch ${switchI ? 'active' : ''}`} onClick={this.changeSwitch}><span></span></div>
              <p className={`switch__name ${switchI ? 'active' : ''}`}>Stripe</p>
            </div>
          </div>
          <div className="coupon-cont__row">
            <label className="coupon-cont__label coupon__col">
              <p>Description</p>
              <input type="text" placeholder="Gets 25% more leads/mo and the Kickstart package for free" />
            </label>
            <div className="coupon-cont__checks coupon__col">
              {
                checks.map((item, index) =>
                  <div key={index} className="coupon-cont__check">
                    <div className={`check ${item.check ? 'active' : ''}`} onClick={() => this.changeCheck(index)}></div>
                    <p className={`${item.check ? 'active' : ''}`}>{item.name}</p>
                  </div>
                )
              }
            </div>
          </div>
          <div className="coupon-cont__row">
            <label className="coupon-cont__label coupon__col">
              <p>Monthly Allotment</p>
              <input type="text" placeholder="0" />
              <FieldCheck check={monthlyAllotment} name='monthlyAllotment' descr='Percent' changeFieldCheck={this.changeFieldCheck} />
            </label>
            <label className="coupon-cont__label coupon__col">
              <p>One-Time Allotment</p>
              <input type="text" placeholder="0" />
              <FieldCheck check={oneTimeAllotment} name='oneTimeAllotment' descr='Percent' changeFieldCheck={this.changeFieldCheck} />
            </label>
          </div>
          <div className="coupon-cont__row">
            <label className="coupon-cont__label coupon__col">
              <p>Max Usage</p>
              <input type="text" placeholder="Number of usage: 0 for infinite" />
            </label>
            <label className="coupon-cont__label coupon__col">
              <p>Expire On</p>
              <input type="text" placeholder="03.14.2019" />
              <FieldCheck check={expire} name='expire' descr='Infinite' changeFieldCheck={this.changeFieldCheck} />
            </label>
          </div>
          <div className="coupon__buttons">
            <div className="coupon__btn orange">Add to Coupons</div>
            <div className="coupon__btn orange-light" onClick={closeAddCoupon}>Cancel</div>
          </div>
        </div>
      </section>
    )
  }
}

export default CouponCreate