import React, { Fragment, Component } from 'react'
import {
  ProfileContent,
  ProfileRight,
  RightPanelBanner,
  BreadCrumb,
  GreenBanner
} from '../../components'
import './style.scss'
import { Grid } from '@material-ui/core'
import { LOCAL_STORAGE_KEYS } from '../../constants/constant'
import { EmitterService } from "../../services/emitterService";

class Profile extends Component {
  componentWillMount() {
    EmitterService.emit("loadLeadsRemaining");
  }
  
  render() {
    const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
    const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT))

    const links = [
      {
        name: 'Settings',
        slug: '/api-keys',
      },
      {
        name: 'Profile',
        slug: null,
      },
    ]

    return (
      <Fragment>
        <BreadCrumb links={links}></BreadCrumb>

        <div className="profile-panel">
          {userId === null && this.props.history.push('/signin?path=/profile')}

          {userAccount.status === "trial_expired" && this.props.history.push('/billing-and-users')}

          <div className="profile-panel__left">
            <ProfileContent />
          </div>

          <div className="profile-panel__right">
            {/* <RightPanelBanner title={'Profile Demo'}  page="profile" type="profile"/> */}
            <ProfileRight />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Profile
