import { START_LOADER,STOP_LOADER, OPEN_POPUP, CLOSE_POPUP, OPEN_EMPLOYEE_POPUP, CLOSE_EMPLOYEE_POPUP } from '../constants/constant';

export function startLoader(status) {
  return {
    type: START_LOADER,
    payload: status
  };
}

export function stopLoader(status) {
  return {
    type: STOP_LOADER,
    payload: status
  };
}

export function openPopup(status) {
  return {
    type: OPEN_POPUP,
    payload: status
  };
}

export function closePopup(status) {
  return {
    type: CLOSE_POPUP,
    payload: status
  };
}
export function openEmployeePopup(status) {
  return {
    type: OPEN_EMPLOYEE_POPUP,
    payload: status
  };
}

export function closeEmployeePopup(status) {
  return {
    type: CLOSE_EMPLOYEE_POPUP,
    payload: status
  };
}
