import axios from '../axios';

export function getLeadLists(userID, params) {
    return axios({
        method: 'get',
        url: `/core/lists`,
        params: params,
    });
}

export function getLeadListById(id) {

    return axios({
        method: 'get',
        url: `/core/lists/${id}`,
    });
}

export function getHotLeads(hlcId,params, recordsPerPage, pageNum, sort, sortOrder) {

    return axios({
        method: 'get',
        url: `/core/hot-leads/${hlcId}?${params}&recordsPerPage=${recordsPerPage}&pageNum=${pageNum}&sort=${sort}&sortOrder=${sortOrder}`,
        params
    });
}

export function getOwnersLists(userId) {
    return axios({
        method: 'get',
        url: `/core/user-data`,
    });
}

export function getListNames(userId) {
    return axios({
        method: 'get',
        url: `/core/lists/names`,
    });
}

export function postLeads(userID, id, val) {
    return axios({
        method: 'patch',
        url: `/core/lists/${id}/fuzebot/daily`,
        data: {
            "daily_leads": val
        }
    })
}

export function updateTraits(id, traits) {
    return axios({
        method: 'patch',
        url: `/process/lists/${id}/fuzebot/traits`,
        data: {
            type: 'people_prospector',
            people_prospector: traits
        }
    })
}

export function createList(userID, accountID, name) {
    return axios({
        method: 'post',
        headers: {
            'userID': userID,
            'accountID': accountID
        },
        url: `/core/lists`,
        data: {
            "Name": name
        }
    })
}

export function deleteList(userID, id) {
    return axios({
        method: 'delete',
        url: `/core/lists/${id}`,
    })
}

export function getUsersSheet(userId) {
    return axios({
        method: 'get',
        headers: {
            'userID': userId.id,
        },
        url: `/core/google-sheets`,
    });
}

export function exportListCSV(list_id, search_query, query_filters, userID, email, listID, columns) {
    return axios({
        method: 'post',
        url: `/core/export-lists-csv`,
        data: {
            "list_id": listID,
            "search_query": search_query,
            "query_filters": query_filters,
            "email": email,
            "columns": columns
        }
    });
}

export function getIntegrations(userID) {
    return axios({
        method: 'get',
        url: `/core/integrations`,
    });
}

export function initSpreedSheet(user, listID, path, name, auto_sync, require_email, require_company_emails, require_personal_emails, require_double_verified) {
    return axios({
        method: 'post',
        headers: {
            'userID': user.id,
            'accountID': user.account_id,
            'url': path
        },
        url: `/core/init-spreadsheet?listID=${listID}`,
        data: {
            "name": name,
            "auto_sync": auto_sync,
            "require_email": require_email,
            "require_company_emails": require_company_emails,
            "require_personal_emails": require_personal_emails,
            "require_double_verified": require_double_verified

        }
    });
}

export function editListName(userID, id, name) {
    return axios({
        method: 'patch',
        url: `/core/lists/${id}/name`,
        data: {
            "Name": name
        }
    });
}

export function pauseResume(userID, id, status) {

    return axios({
        method: 'patch',
        url: `/core/lists/${id}/fuzebot/status`,
        params: {
            "action": status
        },
        data: {
            "status": status,
        }

    });
}

export function getWoodpecker(userId) {
    return axios({
        method: 'get',
        url: `/core/woodpecker/campaigns`,
        params: {
            userId: userId
        },
    });
}

export function getLemlist(userId) {
    return axios({
        method: 'get',
        url: `/core/lemlist/campaigns`,
        params: {
            userId: userId
        },
    });
}

export function getIntegrationCampaigns(integrationName) {
    return axios({
        method: 'get',
        url: `/core/${integrationName}/campaigns`,
    });
}

export function getJazzhrJobs() {
    return axios({
        method: 'get',
        url: `/core/jazzhr/jobs`,
    });
}

export function getMailShake(userId) {

    return axios({
        method: 'get',
        url: `/core/mailshake/campaigns`,
    });
}

export function createMailShakeCampaign(campaignName) {
    return axios({
        method: 'post',
        url: `/core/mailshake/campaign?name=${campaignName}`,
    });
}

export function exportListGoogleSheet(email, listID, columns, automate, sheetID, sheetName) {
    return axios({
        method: 'post',
        url: `/core/export-lists-sheets`,
        data: {
            "email": email,
            "list_id": listID,
            "columns": columns,
            'automate': automate,
            "sheet_id": sheetID,
            "sheet_name": sheetName
        }
    });
}

export function integrateListIdWithSelectedIntegration(listID, integrationName) {
    return axios({
        method: 'post',
        url: `/core/${integrationName}/leads`,
        data: {
            "list_id": listID,
        }
    });
}

export function updateLemListCampaigns(userId, listID, campID) {
    return axios({
        method: 'patch',
        url: `/core/lists/${listID}/integrations`,
        data: {
            "lemlist_info": {
                "campaign_id": campID,
            }
        }
    });
}

export function updateIntegrations(listID, integrations_info) {
    return axios({
        method: 'patch',
        url: `/core/lists/${listID}/integrations`,
        data: integrations_info
    });
}

export function integrateListWitDuxSoup(listID, connectionMessage, followupMessage, insideObj) {
    return axios({
        method: 'post',
        url: `/core/duxsoup/leads`,
        data: {
            ...insideObj,
            "dx_message": connectionMessage,
            "dx_process": followupMessage,
            "list_id": listID,
        }
    });
}

export function addIntegrationLeads(integrationName, listID, jobID, campID, search_query, dx_message, dx_process, make_default, insideObj, type) {

    return axios({
        method: 'post',
        url: `/core/${integrationName}/leads`,
        data: {
            ...insideObj,
            "job_id": '' + jobID,
            "campaign_id": '' + campID,
            "type": '' + type,
            "dx_message": dx_message,
            "dx_process": dx_process,
            "list_id": listID,
            "search_query": search_query,
            "make_default": make_default,
        }
    });
}
