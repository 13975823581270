export const integrations = [
    {
        name: "zapier",
        label: "Zapier",
        activeID: '',
        id: 14,
        image: 'zapier.png',
        text: 'Connect LeadFuze to all your apps. Send data to third party tools to trigger multi-channel outreach campaigns with direct mail, dialers, email outreach, etc.',
        tags: [
            'API'
        ],
        url: "https://zapier.com/developer/invite/101593/c874ecfa1ddb6ca21c5fe73d40c50d58/",
        active: false,
        featured: true,
        checked: true
    },
    {
        name: "highlevel",
        label: "HighLevel",
        activeID: '',
        id: 38,
        image: 'highlevel.png',
        text: 'Send your LeadFuze leads into HighLevel and trigger cold email and calling campaigns automatically. Once configured, the process is completely hands off.',
        tags: [
            'Cold email',
            'CRM'
        ],
        url: "https://www.gohighlevel.com/",
        active: false,
        featured: true,
        checked: true,
        apiLocation: "Login to Gohighlevel.com. Your api key will be available under Settings > APIeys"
    },
    {
        name: "salesforce",
        label: "Salesforce",
        activeID: '',
        id: 25,
        image: 'salesforce.png',
        text: 'Automatically sync leads into your Salesforce account to be worked through your sales process. Ensures no duplicate leads end up in your CRM.',
        tags: [
            'CRM'
        ],
        url: "https://www.salesforce.com",
        active: false,
        featured: true,
        checked: true
    },
    {
        name: "appypie",
        label: "Appypie Connect",
        activeID: '',
        id: 100,
        image: 'appypie.png',
        text: 'Connect LeadFuze to all your apps using Appypie Connect. Send data to third party tools to trigger multi-channel outreach campaigns, to your CRM, or choose from hundreds of other tools.',
        tags: [
            'API'
        ],
        url: "https://www.appypie.com/connect/",
        active: false,
        featured: false,
        checked: true

    },
    {
        name: "bidsketch",
        label: "Bidsketch",
        activeID: '',
        id: 15,
        image: 'bidsketch.png',
        text: 'Automatically create contacts into your Bidsketch account to send proposals to.',
        tags: [
            'Proposals'
        ],
        url: "https://bidsketch.com",
        active: false,
        featured: false,
        checked: true,
        apiLocation: "Login to Bidsketch. Your api key will be available under Account > API & Integrations > Unique Token."
    },
    {
        name: "close-io",
        label: "Close-io",
        activeID: '',
        id: 11,
        image: 'close-io.png',
        text: 'Automatically sync leads into your Closeio account to be worked through your sales process. Ensures no duplicate leads end up in your CRM.',
        tags: [
            'CRM'
        ],
        url: "https://close.com/",
        active: false,
        featured: false,
        checked: true,
        apiLocation: "Login to Closeio. Your api key will be available under Settings > API."
    },
    {
        name: "duxsoup",
        label: "Dux-Soup",
        activeID: '',
        id: 19,
        image: 'DuxSoup.svg',
        text: 'Connect with your LeadFuze leads via LinkedIn and do so with personalized connection messages. Then, automatically schedule InMail messages for follow-ups.',
        tags: [
            'Social Automation'
        ],
        url: "https://www.dux-soup.com/",
        active: false,
        featured: false,
        checked: true
    },
    {
        name: "freshsales",
        label: "Freshsales",
        activeID: '',
        id: 20,
        image: 'freshsales.png',
        text: 'Automatically sync leads into your FreshSales account to be worked through your sales process. Ensures no duplicate leads end up in your CRM.',
        tags: [
            'CRM'
        ],
        url: "https://www.freshworks.com/",
        active: false,
        featured: false,
        checked: true,
        apiLocation: "Login to Freshsales. Your api key will be available under Personal Settings > API Settings."
    },
    {
        name: "googlesheets",
        label: "Google Sheets",
        activeID: '',
        id: 36,
        image: 'googlesheets.png',
        text: "Export leads into Google Sheets and have it continuously updated as you add more leads. A more dynamic export option compared to CSV.",
        tags: [
            'Spreadsheet'
        ],
        url: "https://gsuite.google.com/products/sheets/",
        active: false,
        featured: false,
        checked: true
    },
    {
        name: "hubspot",
        label: "HubSpot",
        activeID: '',
        id: 12,
        image: 'hubspot.png',
        text: 'Automatically sync leads into your Hubspot account to be worked through your sales process. Ensures no duplicate leads end up in your CRM.',
        tags: [
            'CRM'
        ],
        url: "https://hubspot.com",
        active: false,
        featured: false,
        checked: true,
        apiLocation: "Login to Hubspot. Your api key will be available under Profile & Preferences > Integrations > API Key."
    },
    {
        name: "lemlist",
        label: "lemlist",
        activeID: '',
        id: 21,
        image: 'lemlist.png',
        text: 'Send your LeadFuze leads into lemlist and trigger cold email campaigns automatically. Once configured, the process is completely hands off.',
        tags: [
            'Cold email'
        ],
        url: "https://lemlist.com",
        active: false,
        featured: false,
        checked: true,
        apiLocation: "Login to lemlist. Your api key will be available under Settings > Integrations > API Key."
    },
    {
        name: "mailshake",
        label: "Mailshake",
        activeID: '',
        id: 22,
        image: 'mailshake.png',
        text: 'Send your LeadFuze leads into Mailshake and trigger cold email campaigns automatically. Once configured, the process is completely hands off.',
        tags: [
            'Cold email'
        ],
        url: "https://www.mailshake.com",
        active: false,
        featured: false,
        checked: true
    },
    {
        name: "pipedrive",
        label: "Pipedrive",
        activeID: '',
        id: 23,
        image: 'pipedrive.png',
        text: 'Automatically sync leads into your Pipedrive account to be worked through your sales process. Ensures no duplicate leads end up in your CRM.',
        tags: [
            'CRM'
        ],
        url: "https://www.pipedrive.com",
        active: false,
        featured: false,
        checked: true
    },
    {
        name: "reply",
        label: "Reply",
        activeID: '',
        id: 37,
        image: 'reply.png',
        text: 'Send your LeadFuze leads into Reply and trigger cold email and calling campaigns automatically. Once configured, the process is completely hands off.',
        tags: [
            'Cold email',
            'Dialing'
        ],
        url: "https://reply.io/",
        active: false,
        featured: false,
        checked: true,
        apiLocation: "Login to Reply.io. Your api key will be available under Settings > APIeys"
    },

	
	{
        name: "jazzhr",
        label: "JazzHR",
        activeID: '',
        id: 26,
        image: 'jazzhr.png',
        text: 'JazzHR’s best-in-class software replaces time-consuming and manual hiring tasks with intuitive software designed to help recruiters and hiring managers recruit, and hire the right talent, fast.',
        tags: [
            'ATS',
            'Recruiting'
        ],
        url: "https://www.jazzhr.com/",
        active: false,
        featured: false,
        checked: true,
        apiLocation: "Login to JazzHR. Your api key will be available under Settings > Integration."
    },
    {
        name: "salesflare",
        label: "Salesflare",
        activeID: '',
        id: 24,
        image: 'salesflare.png',
        text: 'Automatically sync leads into your SalesFlare account to be worked through your sales process. Ensures no duplicate leads end up in your CRM.',
        tags: [
            'CRM'
        ],
        url: "https://salesflare.com",
        active: false,
        featured: false,
        checked: true,
        apiLocation: "Login to Salesflare. Your api key will be available under Settings > API."
    },
    {
        name: "woodpecker",
        label: "Woodpecker",
        activeID: '',
        id: 35,
        image: 'woodpecker.png',
        text: 'Send your LeadFuze leads into Woodpecker and trigger cold email campaigns automatically. Once configured, the process is completely hands off.',
        tags: [
            'Cold email'
        ],
        url: "https://woodpecker.co/",
        active: false,
        featured: false,
        checked: true,
        apiLocation: "Login to Woodpecker. Your api key will be available under Settings > Integrations > API."

    }

]

export const checkIntegrations = [
    {
        title: "API",
        name: 'API',
        checked: false
    },
    {
        title: "ATS",
        name: 'ATS',
        checked: false
    },
    {
        title: "Cold email",
        name: 'Cold email',
        checked: false
    },
    {
        title: "CRM",
        name: 'CRM',
        checked: false
    },
    {
        title: "Dialing",
        name: 'Dialing',
        checked: false
    },
    {
        title: "Proposals",
        name: 'Proposals',
        checked: false
    },

    {
        title: "Recruiting",
        name: 'Recruiting',
        checked: false
    },
    {
        title: "Social Automation",
        name: 'Social Automation',
        checked: false
    },
    {
        title: "Spreadsheet",
        name: 'Spreadsheet',
        checked: false
    }
]

