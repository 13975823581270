import { toast } from 'react-toastify';
import $ from 'jquery';

export function showSuccessToast(toastId, message) {
    toast.done(toastId);
    $(".Toastify").addClass("mes-done");
    setTimeout(() => {
        toast.update(toastId, {
            className: "toast-success-div",
            render: message,
            type: toast.TYPE.SUCCESS
        });
    }, 1300);
    setTimeout(function () {
        toast.dismiss(toastId)
        $(".Toastify").removeClass("mes-done");
    }, 4000)
}

export function showErrorToast(toastId, errorMessage) {
    toast.done(toastId)
    $(".Toastify").addClass("mes-done");
    setTimeout(() => {
        toast.update(toastId, {
            className: "toast-error-div",
            render: errorMessage,
            type: toast.TYPE.ERROR
        });
    }, 1300)
    setTimeout(function () {
        toast.dismiss(toastId)
        $(".Toastify").removeClass("mes-done");
    }, 4000)
}