import axios from '../axios';
const ROOT_URL = process.env.REACT_APP_ROOT_URL;

export function updateProfilePic(userID, file) {
    return axios({
        method: 'patch',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        url: `/core/update-profile-pic`,
        data: file
    })
}
export function getUserActivity(userID) {
    return axios({
        method: 'get',
        headers: {
        },
        url: `/core/user-activities`,
    })
}
export function updateProfile(userID, fName, lName, compName, role, email, password, isLeadToManyLists) {
    return axios({
        method: 'patch',
        headers: {
            'userID': userID
        },
        url: `/core/update-pro`,
        data: {
            "first_name": fName,
            "last_name": lName,
            "company_name": compName,
            "role": role,
            "email": email,
            "password": password,
            "is_lead_to_many_lists": isLeadToManyLists
        }
    })
}
export function getBucketUrl() {
    return axios({
        method: "get",
        url: `/core/accounts/resource-paths`,
    })
}
export function updateSocialInfo(userID, phone, linkedin, facebook, twitter, instagram, youtube) {
    return axios({
        method: 'patch',
        headers: {
            'userID': userID
        },
        url: `/core/update-contact-info`,
        data: {
            "phone": phone,
            "linkedin": linkedin,
            "facebook": facebook,
            "twitter": twitter,
            "instagram": instagram,
            "youtube": youtube
        }
    })
}

