import React, { Fragment } from 'react';
import items from "./items";

const SavedSearchesHeader = props => {
  const headers = items.map((item, index) => {
    let image = "/assest/accountBased/sort.svg";
    if (item.sortName === props.sortingColumn) {
      image = props.sortOrder === 'asc'? "/assest/accountBased/sort_asc.svg" : "/assest/accountBased/sort_desc.svg";
    }

    return (
      <Fragment key={index}>
        {item.sortable &&
          <div className={`saved_searches-header__col ${item.clname}`} onClick={() => props.switchSort(item.sortName)}>
            <p>{item.name}</p>
            <img src={`${image}`} alt="" />
          </div>
        }

        {!item.sortable &&
          <div className={`saved_searches-header__col no-sort ${item.clname}`}>
            <p>{item.name}</p>
          </div>
        }
      </Fragment>
    );
  });
    return (
      <div className="saved_searches-header">
        <h2 className="saved_searches__title">Saved Searches</h2>
        <div className="saved_searches-header__row">
          <div className="saved_searches-header__items">{headers}</div>
          <div className="saved_searches-header__close"></div>
        </div>
      </div>
    );
};

export default SavedSearchesHeader;
