import { GET_INTEGRATION } from "../constants/constant";

const initialState = { integration: [] }

const integration = (state = initialState, action) => {
    switch (action.type) {
        case GET_INTEGRATION:

            return {
                ...state,
                integration: action.payload,
            }
        default:
            return state
    }
}
export default integration
