import axios from '../axios';
const queryString = require('query-string');

const POOL_SERVICE_URL = process.env.REACT_APP_POOL_SERVICE_URL;

export function getCompanyLocations(search) {
  
  const queryStr = queryString.stringify({search});
  return axios({
    method: "get",
    url: `/pool/location/company?${queryStr}`
  });
}

export function getPersonLocations(search) {
  
  const queryStr = queryString.stringify({search});
  return axios({
    method: "get",
    url: `/pool/location/person?${queryStr}`
  });
}
