export const role_groups = [
  {
    category: "Admin",
    group: "Admin / Assistant",
    label: "Admin / Assistant",
    include_tags: ["secretary", "executive", "assistant", "administration"],
    exclude_tags: []
  },
  {
    category: "CustomerService",
    label: "Customer Service",
    group: "Customer Service",
    include_tags: [
      "customer service manager",
      "customer support manager",
      "customer success manager",
      "office manager",
      "head of customer support",
      "head of customer service",
      "head of customer success",
      "customer service director",
      "customer support director",
      "customer success director",
      "vp customer success",
      "vp of customer support",
      "vp customer service",
      "vice president of customer success",
      "vice president of customer support",
      "vice president of customer service",
      "client service manager",
      "customer",
      "client service",
      "client management",
      "client support"
    ],
    exclude_tags: ["assistant", "intern"]
  },
  {
    category: "Finanace",
    label: "Finance",
    group: "Finance",
    include_tags: [
      "finance manager",
      "chief financial officer",
      "cfo",
      "finance director",
      "director of finance",
      "vp finance",
      "vp of finance",
      "vice president finance",
      "vice president of finance",
      "controller",
      "treasurer",
      "financial manager",
      "financial director",
      "accounting manager",
      "accounting director",
      "finance",
      "financial",
      "accounts receivable",
      "accounts payable",
      "accounting",
      "payroll",
      "revenue"
    ],
    exclude_tags: ["assistant", "intern"]
  },
  {
    category: "HumanResources",
    label: "Human Resources",
    group: "Human Resources",
    include_tags: [
      "hr manager",
      "hr officer",
      "hr coordinator",
      "hr director",
      "vp hr",
      "vp human resources",
      "president human resources",
      "vice president of hr",
      "vice president hr",
      "head of hr",
      "head of human resources",
      "people officer",
      "talent acquisition",
      "talent manager",
      "talent officer",
      "talent coordinator",
      "talent director",
      "direct of talent",
      "vp recruiting",
      "recruiting manager",
      "recruiting director",
      "recruiting coordinator",
      "staffing manager",
      "staffing director",
      "staffing coordinator",
      "hiring manager",
      "hiring director",
      "hiring coordinator",
      "hiring officer",
      "human resources officer",
      "comptroller",
      "human resources",
      "hr",
      "benefits",
      "talent",
      "people",
      "hiring",
      "staffing",
      "personnel"
    ],
    exclude_tags: ["assistant", "intern"]
  },
  {
    category: "Lawayer",
    label: "Lawyer",
    group: "Lawyer",
    include_tags: ["lawyer", "attorney", "law", "legal", "counsel"],
    exclude_tags: ["assistant", "intern", "secretary", "paralegal"]
  },
  {
    category: "Marketing",
    label: "Marketing",
    group: "Marketing",
    include_tags: [
      "marketing manager",
      "director of marketing",
      "cmo",
      "chief marketing officer",
      "vp marketing",
      "vp of marketing",
      "vice president of marketing",
      "head of marketing",
      "head of growth",
      "growth marketer",
      "brand manager",
      "marketing director",
      "creative director",
      "marketing coordinator",
      "pr manager",
      "pr director",
      "pr coordinator",
      "public relations manager",
      "public relations director",
      "public relations coordinator",
      "marketing",
      "seo",
      "social",
      "search",
      "pr",
      "public relations",
      "content",
      "marketer",
      "advertising",
      "media",
      "ppc",
      "sem",
      "paid ads",
      "growth"
    ],
    exclude_tags: ["assistant", "intern"]
  },
  {
    category: "Operations",
    label: "Operations",
    group: "Operations",
    include_tags: [
      "operations manager",
      "coo",
      "chief operations officer",
      "director of operations",
      "vp operations",
      "vp of operations",
      "vice president of operations",
      "operations director",
      "operational manager",
      "general manager",
      "operations",
      "project manager",
      "project management"
    ],
    exclude_tags: ["assistant", "intern"]
  },
  {
    category: "Owner",
    label: "Owner / CEO",
    group: "Owner / CEO",
    include_tags: [
      "founder",
      "owner",
      "ceo",
      "chief executive officer",
      "co-founder",
      "founder & ceo",
      "partner",
      "president",
      "managing director",
      "managing member",
      "executive director",
      "chairman"
    ],
    exclude_tags: ["assistant", "intern", "secretary", "vice"]
  },
  {
    category: "Realtor",
    label: "Realtor",
    group: "Realtor",
    include_tags: [
      "realtor",
      "real estate agent",
      "real estate",
      "house agent",
      "land agent"
    ],
    exclude_tags: ["assistant", "intern"]
  },
  {
    category: "Sales",
    label: "Sales",
    group: "Sales",
    include_tags: [
      "sales manager",
      "business development manager",
      "sales director",
      "director of sales",
      "head of sales",
      "vp sales",
      "vp of sales",
      "vice president sales",
      "vice president of sales",
      "territory manager",
      "territory sales manager",
      "director of inside sales",
      "director of national sales",
      "national sales manager",
      "account Manager",
      "account executive",
      "ae",
      "account exec",
      "sales",
      "salesperson",
      "bdm",
      "bdr",
      "sdr",
      "business development",
      "territory"
    ],
    exclude_tags: ["assistant", "intern"]
  },
  {
    category: "Technology",
    label: "Technology",
    group: "Technology",
    include_tags: [
      "it manager",
      "cio",
      "chief information officer",
      "director of it",
      "director of information technology",
      "vp of it",
      "vp information technology",
      "vp of information technology",
      "vice president of information technology",
      "vice president information technology",
      "it director",
      "chief technology officer",
      "cto",
      "directory of technology",
      "vp technology",
      "vice president of technology",
      "vice president technology",
      "information technology director",
      "technology",
      "information",
      "programmer",
      "web developer",
      "webmaster",
      "web administrator",
      "front-end developer",
      "back-end developer",
      "software developer",
      "software",
      "server admin",
      "sys admin",
      "cloud",
      "computer",
      "network",
      "it support",
      "systems administrator",
      "technical"
    ],
    exclude_tags: ["assistant", "intern"]
  }
];
