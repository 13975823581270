import React, { Component } from 'react';
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip';
import '../style.scss';
import './styles.scss';
import Popup from 'reactjs-popup';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { addIntegrationLeads, integrateListWitDuxSoup, updateIntegrations } from '../../../../services/leads';
import { showSuccessToast, showErrorToast } from '../../../../helpers/toast';

class DuxSoup extends Component {
    state = {
        value: "",
        value1: "",
        connectionMessage: this.props.connection_message,
        followUpMessage: this.props.followup_message,
        check: false,
        check1: false
    }
    handleConnectionMessage = (e) => {
        this.props.updateDefaultIntegration("duxsoup_info", {connection_message: e.target.value, followup_message: ""});
        this.setState({ connectionMessage: e.target.value })
    }
    handleFollowupMessage = (e) => {
        this.setState({ followUpMessage: e.target.value })
    }
    onUpdateClick = async () => {
        if(this.props.inside) {
            let toastId = null;
            toastId = toast(`Sending leads to ${this.props.type}`, {
                autoClose: 10000,
            });
            try{
                const response = await addIntegrationLeads("duxsoup", this.props.listId, '', '',this.props.search_query, this.state.connectionMessage, "connection_request", this.state.make_default, this.props.inside)
                if (response.data.data) {
                    let added = response.data.data.added;
                    let skipped = response.data.data.skipped;
                    if (skipped == -1 && added == -1) {
                        this.showSuccessToast(toastId, "Leads are being sent to the integration. Please check back in a couple of minutes.");
                    } else if(skipped == 0) {
                        showSuccessToast(toastId, "Leads sent successfully to integration");
                    }
                    else if(skipped > 0) {
                        showSuccessToast(toastId, `${added} leads sent successfully to integration. ${skipped} leads were skipped`);
                    }
                    const { openWoodpeckerPopup } = this.props
                    openWoodpeckerPopup()
                  } else {
                    showErrorToast(toastId, 'Unable to send leads to integration');
                  }
            }
            catch(e) {
                showErrorToast(toastId, 'Unable to send leads to integration');
            }
        }
        else {
            let toastId = null;
            try {
                toastId = toast(`Updating Integrations Settings`, {
                    autoClose: 10000,
                });
                let integrations_info = {
                    duxsoup_info: {
                        connection_message: this.state.connectionMessage,
                        followup_message: this.state.followUpMessage
                    }
                };

                const response = await updateIntegrations(this.props.listId, integrations_info)
                //const response = await integrateListWitDuxSoup(this.props.listId, this.state.connectionMessage, this.state.followUpMessage, this.props.inside);
                if (response.data.data) {
                    showSuccessToast(toastId, 'Integration settings updated successfully');
                    const { openDuxSoupPopup } = this.props
                    openDuxSoupPopup(response.data.data);
                } else {
                    showErrorToast(toastId, 'Unable to update integration settings');
                }
            }
            catch (e) {
                showErrorToast(toastId, 'Unable to update integration settings');
            }
        }
    }
    onChangeCheck = () => {
        this.setState({ check: !this.state.check })
    }
    onChangeCheck1 = () => {
        this.setState({ check1: !this.state.check1 })
    }

    firstName = (oneOr2) => {
        if (oneOr2 === 1) {
            this.setState({ connectionMessage: this.state.connectionMessage + ' {{.firstName}} ' })
        } else {
            this.setState({ followUpMessage: this.state.followUpMessage + ' {{.firstName}} ' })
        }
    }

    lastName = (oneOr2) => {
        if (oneOr2 === 1) {
            this.setState({ connectionMessage: this.state.connectionMessage + ' {{.lastName}} ' })
        } else {
            this.setState({ followUpMessage: this.state.followUpMessage + ' {{.lastName}} ' })
        }
    }

    companyName = (oneOr2) => {
        if (oneOr2 === 1) {
            this.setState({ connectionMessage: this.state.connectionMessage + ' {{.companyName}} ' })
        } else {
            this.setState({ followUpMessage: this.state.followUpMessage + ' {{.companyName}} ' })
        }
    }

    getConnectionMessageFormatted = () => {
        return this.state.connectionMessage.replace('{{.firstName}}', '&lt;span&gt;First Name&lt;span/&gt;')
    }

    render() {
        const { connectionMessage, followUpMessage, check, value1, check1 } = this.state
        const { popupDuxSoup, openDuxSoupPopup, inside } = this.props;
        return (
            <div className={`add-list ${popupDuxSoup ? 'open' : ''}`}>
                <div className="add-list__content duxsoup">
                    <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={openDuxSoupPopup} />
                    <p className="add-list__title">{inside ? 'Send To Dux-Soup' : 'Dux-Soup Settings'} </p>
                    <div className="heading-div">
                        <p className="connection-message"><span>Connection Message &nbsp;</span>
                            <img data-tip data-for="leadRemainingToolTip" data-place="bottom" src="/assest/icon-help.svg" alt="" width="10" /></p>
                        <Popup trigger={<p className="add"
                        >
                            <img className="plus-icon" src="/assest/accountBased/plus-orange.svg" alt="" />
                        </p>}
                            position="bottom right"
                            arrow={false}
                            on="hover"
                            closeOnDocumentClick={true}
                            closeOnEscape={true}
                            contentStyle={{ border: 'default', boxShadow: 'none' }}
                        >
                            <div className="inside-select open">
                                <div className={`menu-item `} onClick={e => this.firstName(1)} >First Name</div>
                                <div className={`menu-item `} onClick={e => this.lastName(1)}>Last Name</div>
                                <div className={`menu-item `} onClick={e => this.companyName(1)}>Company Name</div>
                            </div>
                        </Popup>
                    </div>
                    <ReactTooltip id="leadRemainingToolTip">
                        <p className="lead-remaining-tooltip">We send this message along with the connection request. Pleae keep this field empty
                if you only want to send a connection request</p>
                    </ReactTooltip>
                    <div style={{ position: 'relative' }}>
                        <textarea className="bg pos-relative" style={{ position: 'absolute;' }}
                            placeholder="Please connection message"
                            value={connectionMessage}
                            onChange={this.handleConnectionMessage}
                        ></textarea>
                    </div>
                    {!inside && <div className="heading-div follow-up ">
                        <p className="connection-message"><span>Follow-up Message &nbsp;</span>
                            <img data-tip data-for="leadRemainingToolTip" data-place="bottom" src="/assest/icon-help.svg" alt="" width="10" /></p>
                        <Popup trigger={<p className="add"
                        >
                            <img className="plus-icon" src="/assest/accountBased/plus-orange.svg" alt="" />
                        </p>}
                            position="bottom right"
                            arrow={false}
                            on="hover"
                            closeOnDocumentClick={true}
                            closeOnEscape={true}
                            contentStyle={{ border: 'default', boxShadow: 'none' }}
                        >
                            <div className="inside-select open">
                                <div className={`menu-item `} onClick={e => this.firstName(2)} >First Name</div>
                                <div className={`menu-item `} onClick={e => this.lastName(2)}>Last Name</div>
                                <div className={`menu-item `} onClick={e => this.companyName(2)}>Company Name</div>
                            </div>
                        </Popup>

                    </div>}
                    <ReactTooltip id="leadRemainingToolTip">
                        <p className="lead-remaining-tooltip">We send this message along with the connection request. Pleae keep this field empty
                if you only want to send a connection request</p>
                    </ReactTooltip>
                    {!inside && <textarea className="bg"
                        placeholder="Enter follow-up message"
                        value={followUpMessage}
                        onChange={this.handleFollowupMessage}
                    ></textarea>}

                    <div className="add-list__buttons">
                        <div className="add-list__btn orange" onClick={() => { this.onUpdateClick() }} >{inside ? 'Send' : 'Save'}</div>
                        <div className="add-list__btn orange-light" onClick={openDuxSoupPopup}>Cancel</div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        integration: state.integration,
        user: state.user
    };
}

export default connect(mapStateToProps, null)(DuxSoup);