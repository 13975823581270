const inputs = [
  {
    label: 'First Name',
    name: 'fname',
    type: 'text',
    placeholder: 'Please type your first name here',
    value: ''
  },
  {
    label: 'Last Name',
    name: 'lname',
    type: 'text',
    placeholder: 'Please type your last name here',
    value: ''
  },
  {
    label: 'Password',
    name: 'pass',
    type: 'password',
    placeholder: 'Please type your password',
    value: ''
  }
]

export default inputs