import React from 'react';
import '../style.scss';
import { isNotEmpty } from '../../../helpers/validate';

const OwnerContent = props => {
  const { ownersLists, changeCheck } = props;
  return (
    ownersLists.map((item, index) =>
      <div className="rm_padding" key={index}>
        <div className="coupon-list__row rm_padding"
              onClick={() => changeCheck(index, item.user_id)}>
          <div className="coupon-header__check">
            <div className={`check ${item.check ? 'active' : ''}`}></div>
          </div>
          <div className="coupon-list__items owner-name">
            <p className="switch__name">{isNotEmpty(item.owner_name) ? item.owner_name : item.email} </p>
          </div>
        </div>
      </div>
    )
  )
}

export default OwnerContent;
