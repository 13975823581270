import React, { Fragment, Component } from 'react'
import Popup from 'reactjs-popup'
import noop from 'lodash/noop';
import classNames from 'classnames';

const duxsoup = "duxsoup"
const woodpecker = "woodpecker"
const lemlist = "lemlist"
const reply = "reply"
const highlevel = "highlevel"
const jazzhr = "jazzhr"
const salesforce = "salesforce"
const hubspot = "hubspot"
const freshsales = "freshsales"
const closeio = "close-io"
const bidsketch = "bidsketch"
const pipedrive = "pipedrive"
const salesflare = "salesflare"
const mailshake = "mailshake"
const zapier = "zapier"

class InsideSelectMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      windowWidth: window.innerWidth,
      isMobile : window.innerWidth < 900,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
  }

  handleResize = (e) => {
      this.setState({ windowWidth: window.innerWidth, isMobile : window.innerWidth < 900 });
  };

  doesIntegrationExist = integrationName => {
    const foundIntegration = this.props.integrations.find(
      integration => integration.name === integrationName
    );
    return !!foundIntegration;
  };



  render() {

  return(
    <Fragment>

      <Popup 
            trigger={
          <div className="add" style={{padding: '10px'}}  // onClick={(e) => this.changeCheckDropdown(e)}
          >
            <div className="">
              <ul className='lead-cont-item__dots' style={{marginLeft: 0}} 
                // onClick={(e) => e.stopPropagation()}
                onClick={this.props.openMenu}
                > 
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            </div>}
            
                      position="bottom center"
                      arrow={false}
                      closeOnDocumentClick={true}
                      closeOnEscape={true}
                      onClose={this.props.closeMenu}
                      contentStyle={{ 'background': 'none', border: 'default', 'boxShadow': 'none', 'zIndex': '10' }}
                      >
                      {close => (
                      <div className="inside-select open">
                        <div className={`menu-item`} onClick={() => { close(); this.props.showMoveToList(this.props.leadId)} } ><div><i className="fa fa-download" style={{marginRight: '5px'}}></i>Move To</div></div>
                        
          {(
                        <Popup trigger={ 
                          <div className={classNames("menu-item", {
                            "disable-btn": !this.props.integrations || this.props.integrations.length == 0
                        })}><div><i className="fa fa-paper-plane" style={{marginRight: '5px'}}></i>Send To</div><img className="menu-arrow revert" src="/assest/accountBased/arrow.svg" alt="" /></div>}
                          position={this.state.isMobile ? "left top" : "left top"}
                          arrow={false}
                          on="hover"
                          contentStyle={{top:'10px', border: 'default', 'boxShadow': 'none', padding: 0 }}
                          >
                            {this.doesIntegrationExist(duxsoup)? 
                              (
                                <Popup trigger={ 
                                  <div className="menu-item" >
                                    <div style={{display: 'flex'}}>
                                      <img src="/assest/integrations/duxsoup-logo.png" style={{width: 20, paddingLeft: 3, paddingRight: 3, marginRight: 10}} alt="" />Dux-Soup
                                    </div>
                                    <img className="menu-arrow revert" src="/assest/accountBased/arrow.svg" alt="" /></div>}
                                  position={"left top"}
                                  arrow={false}
                                  on="hover"
                                  contentStyle={{top:'10px', border: 'default', 'boxShadow': 'none', padding: 0 }}
                                  >
                                    <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(duxsoup)} }>
                                      <i class="fa fa-user-plus" style={{width: 20, paddingLeft: 3, paddingRight: 3, marginRight: 5}}  />Send Connection Request
                                    </div>
                                    <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration('duxsoup-no-popup')} }>
                                      <i class="fa fa-eye" style={{width: 20, paddingLeft: 3, paddingRight: 3, marginRight: 5}}  />Visit Profile
                                    </div>
                                  </Popup>
                              )
                              : null
                            }
                          {this.doesIntegrationExist(woodpecker)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(woodpecker)} }><img className="woodpecker" src="/assest/integrations/woodpecker-logo.ico" alt="" />Woodpecker</div> : null}
                          {this.doesIntegrationExist(mailshake)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(mailshake)} }><img src="/assest/integrations/mailshake-logo.png" alt="" />Mailshake</div> : null}
                          {this.doesIntegrationExist(lemlist)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(lemlist)} }><img src="/assest/integrations/lemlist-logo.png" alt="" />lemlist</div> : null}
                          {this.doesIntegrationExist(reply)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(reply)} }><img src="/assest/integrations/reply-logo.png" alt="" />Reply</div> : null}
                          {this.doesIntegrationExist(highlevel)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(highlevel)} }><img src="/assest/integrations/highlevel-logo.png" alt="" />Highlevel</div> : null}
                          {this.doesIntegrationExist(jazzhr)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(jazzhr)} }><img src="/assest/integrations/jazzhr-logo.png" alt="" />Jazz Hr</div> : null}
                          {this.doesIntegrationExist(salesforce)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(salesforce)} }><img src="/assest/integrations/salesforce-logo.ico" alt="" />Salesforce</div> : null}
                          {this.doesIntegrationExist(hubspot)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(hubspot)} }><img src="/assest/integrations/hubspot-logo.png" alt="" />Hubspot</div> : null}
                          {this.doesIntegrationExist(freshsales)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(freshsales)} }><img src="/assest/integrations/freshsales-logo.png" alt="" />Freshsales</div> : null}
                          {this.doesIntegrationExist(closeio)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(closeio)} }><img src="/assest/integrations/closeio-logo.ico" alt="" />CloseIo</div> : null}
                          {this.doesIntegrationExist(bidsketch)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(bidsketch)} }><img src="/assest/integrations/bidsketch-logo.ico" alt="" />Bidsketch</div> : null}
                          {this.doesIntegrationExist(pipedrive)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(pipedrive)} }><img src="/assest/integrations/pipedrive-logo.png" alt="" />Pipedrive</div> : null}
                          {this.doesIntegrationExist(salesflare)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(salesflare)} }><img src="/assest/integrations/salesflare-logo.png" alt="" />Salesflare</div> : null}
                          {this.doesIntegrationExist(zapier)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateOneLeadWithIntegration(zapier)} }><img src="/assest/integrations/zapier-logo.ico" alt="" />Zapier</div> : null}
                        </Popup>
          ) }
                        <div className={`menu-item `} onClick={e => { close(); this.props.deleteSelectedLead(this.props.leadId)} }><div><i className="fa fa-trash" style={{marginRight: '5px'}}></i>Delete</div></div>
                        </div>)}
        </Popup>

      {/* <div className="inside-select__back"></div>
      <div className={`inside-select ${props.insideSelectDropdown? 'open' : ''}`}>
        <div className="inside-select__item" onClick={(e) => props.showMoveToList(props.leadId)}>
          <img src="/assest/inside/back.svg" alt=""/>
          <p>Move to</p>
        </div>
        <div className="inside-select__item" onClick={(e) => props.showSendToList(props.leadId)}>
          <img src="/assest/inside/send.svg" alt=""/>
          <p>Send to</p>
        </div>
        <div className="inside-select__item" onClick={(e) => props.deleteSelectedLead(props.leadId)}>
          <img src="/assest/inside/del.svg" alt="" />
          <p>Delete</p>
        </div>
      </div> */}
    </Fragment>
  )
}
}

export default InsideSelectMenu