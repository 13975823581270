import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  props: {
  },
  typography: {
  },
  overrides: {
    MuiTooltip: {
      popper: {
        marginBottom: -8,
      },
      tooltip: {
        padding: '6px 8px',
        borderRadius: 3,
        backgroundColor: 'var(--orange)',
      },
      arrow: {
        color: 'var(--orange)'
      }
    }
  },
});
