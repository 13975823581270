export const HospitalsAndHealthCare = "Hospitals And Health Care",
  Retail = "Retail",
  HigherEducation = "Higher Education",
  FinancialServices = "Financial Services",
  ItServicesAndItConsulting = "It Services And It Consulting",
  SoftwareDevelopment = "Software Development",
  GovernmentAdministration = "Government Administration",
  EducationAdministrationPrograms = "Education Administration Programs",
  BuildingConstruction = "Building Construction",
  LeasingRealEstate = "Leasing Real Estate",
  Insurance = "Insurance",
  FoodAndBeverageServices = "Food And Beverage Services",
  PrimaryAndSecondaryEducation = "Primary And Secondary Education",
  NonProfitOrganizations = "Non-Profit Organizations",
  AdvertisingServices = "Advertising Services",
  MotorVehicleManufacturing = "Motor Vehicle Manufacturing",
  Restaurants = "Restaurants",
  BusinessConsultingAndServices = "Business Consulting And Services",
  Hospitality = "Hospitality",
  ArmedForces = "Armed Forces",
  TruckTransportation = "Truck Transportation",
  Banking = "Banking",
  HumanResourcesServices = "Human Resources Services",
  Telecommunications = "Telecommunications",
  LawPractice = "Law Practice",
  OilAndGas = "Oil And Gas",
  Accounting = "Accounting",
  WellnessAndFitnessServices = "Wellness And Fitness Services",
  MedicalPractices = "Medical Practices",
  EntertainmentProviders = "Entertainment Providers",
  Manufacturing = "Manufacturing",
  AppliancesElectricalAndElectronicsManufacturing = "Appliances, Electrical, And Electronics Manufacturing",
  ConsumerServices = "Consumer Services",
  MedicalEquipmentManufacturing = "Medical Equipment Manufacturing",
  PharmaceuticalManufacturing = "Pharmaceutical Manufacturing",
  BiotechnologyResearch = "Biotechnology Research",
  MachineryManufacturing = "Machinery Manufacturing",
  DefenseAndSpaceManufacturing = "Defense And Space Manufacturing",
  Utilities = "Utilities",
  AirlinesAndAviation = "Airlines And Aviation",
  ChemicalManufacturing = "Chemical Manufacturing",
  EnvironmentalServices = "Environmental Services",
  IndustrialMachineryManufacturing = "Industrial Machinery Manufacturing",
  IndividualAndFamilyServices = "Individual And Family Services",
  TechnologyInformationAndInternet = "Technology, Information And Internet",
  CivilEngineering = "Civil Engineering",
  Wholesale = "Wholesale",
  MentalHealthCare = "Mental Health Care",
  AviationAndAerospaceComponentManufacturing = "Aviation And Aerospace Component Manufacturing",
  WholesaleBuildingMaterials = "Wholesale Building Materials",
  BroadcastMediaProductionAndDistribution = "Broadcast Media Production And Distribution",
  RetailApparelAndFashion = "Retail Apparel And Fashion",
  ArchitectureAndPlanning = "Architecture And Planning",
  ResearchServices = "Research Services",
  FacilitiesServices = "Facilities Services",
  ReligiousInstitutions = "Religious Institutions",
  CivicAndSocialOrganizations = "Civic And Social Organizations",
  SpectatorSports = "Spectator Sports",
  DesignServices = "Design Services",
  SecurityAndInvestigations = "Security And Investigations",
  ComputersAndElectronicsManufacturing = "Computers And Electronics Manufacturing",
  TransportationLogisticsSupplyChainAndStorage = "Transportation, Logistics, Supply Chain And Storage",
  Mining = "Mining",
  LawEnforcement = "Law Enforcement",
  PackagingAndContainersManufacturing = "Packaging And Containers Manufacturing",
  BookAndPeriodicalPublishing = "Book And Periodical Publishing",
  TravelArrangements = "Travel Arrangements",
  LegalServices = "Legal Services",
  EventsServices = "Events Services",
  InvestmentManagement = "Investment Management",
  SemiconductorManufacturing = "Semiconductor Manufacturing",
  AutomationMachineryManufacturing = "Automation Machinery Manufacturing",
  FreightAndPackageTransportation = "Freight And Package Transportation",
  PrintingServices = "Printing Services",
  RenewableEnergySemiconductorManufacturing = "Renewable Energy Semiconductor Manufacturing",
  ComputerAndNetworkSecurity = "Computer And Network Security",
  FurnitureAndHomeFurnishingsManufacturing = "Furniture And Home Furnishings Manufacturing",
  PublicRelationsAndCommunicationsServices = "Public Relations And Communications Services",
  PublicSafety = "Public Safety",
  ProfessionalTrainingAndCoaching = "Professional Training And Coaching",
  ArtistsAndWriters = "Artists And Writers",
  PersonalCareProductManufacturing = "Personal Care Product Manufacturing",
  ELearningProviders = "E-Learning Providers",
  RetailOfficeEquipment = "Retail Office Equipment",
  Musicians = "Musicians",
  Farming = "Farming",
  MuseumsHistoricalSitesAndZoos = "Museums, Historical Sites, And Zoos",
  BeverageManufacturing = "Beverage Manufacturing",
  PlasticsManufacturing = "Plastics Manufacturing",
  VentureCapitalAndPrivateEquityPrincipals = "Venture Capital And Private Equity Principals",
  GamblingFacilitiesAndCasinos = "Gambling Facilities And Casinos",
  InternationalTradeAndDevelopment = "International Trade And Development",
  OutsourcingAndOffshoringConsulting = "Outsourcing And Offshoring Consulting",
  GovernmentRelationsServices = "Government Relations Services",
  ComputerHardwareManufacturing = "Computer Hardware Manufacturing",
  RecreationalFacilities = "Recreational Facilities",
  TextileManufacturing = "Textile Manufacturing",
  NewspaperPublishing = "Newspaper Publishing",
  InternationalAffairs = "International Affairs",
  InvestmentBanking = "Investment Banking",
  RetailGroceries = "Retail Groceries",
  PaperAndForestProductManufacturing = "Paper And Forest Product Manufacturing",
  VeterinaryServices = "Veterinary Services",
  WarehousingAndStorage = "Warehousing And Storage",
  ComputerGames = "Computer Games",
  ExecutiveOffices = "Executive Offices",
  OnlineAudioAndVideoMedia = "Online Audio And Video Media",
  Libraries = "Libraries",
  ComputerNetworking = "Computer Networking",
  RetailLuxuryGoodsAndJewelry = "Retail Luxury Goods And Jewelry",
  MarketResearch = "Market Research",
  Photography = "Photography",
  AdministrationOfJustice = "Administration Of Justice",
  MaritimeTransportation = "Maritime Transportation",
  WritingAndEditing = "Writing And Editing",
  SportingGoodsManufacturing = "Sporting Goods Manufacturing",
  Fundraising = "Fundraising",
  RailroadEquipmentManufacturing = "Railroad Equipment Manufacturing",
  TranslationAndLocalization = "Translation And Localization",
  AlternativeMedicine = "Alternative Medicine",
  PhilanthropicFundraisingServices = "Philanthropic Fundraising Services",
  GlassCeramicsAndConcreteManufacturing = "Glass, Ceramics And Concrete Manufacturing",
  MoviesVideosAndSound = "Movies, Videos, And Sound",
  RetailArtSupplies = "Retail Art Supplies",
  AnimationAndPostProduction = "Animation And Post-Production",
  PoliticalOrganizations = "Political Organizations",
  ThinkTanks = "Think Tanks",
  WirelessServices = "Wireless Services",
  CapitalMarkets = "Capital Markets",
  PublicPolicyOffices = "Public Policy Offices",
  WholesaleImportAndExport = "Wholesale Import And Export",
  Shipbuilding = "Shipbuilding",
  StrategicManagementServices = "Strategic Management Services",
  DairyProductManufacturing = "Dairy Product Manufacturing",
  AlternativeDisputeResolution = "Alternative Dispute Resolution",
  LegislativeOffices = "Legislative Offices",
  Education = "Education",
  Ranching = "Ranching",
  NanotechnologyResearch = "Nanotechnology Research",
  TobaccoManufacturing = "Tobacco Manufacturing",
  Fisheries = "Fisheries",
  InformationTechnologyAndServices = "Information Technology & Services",
  OnlineAndMailOrderRetail = "Online And Mail Order Retail",
  Internet = "Internet",
  MarketingServices = "Marketing Services",
  HealthWellnessAndFitness = "Health, Wellness & Fitness",
  ConsumerGoods = "Consumer Goods",
  Automotive = "Automotive",
  EngineeringServices = "Engineering Services",
  DataInfrastructureAndAnalytics = "Data Infrastructure And Analytics",
  Research = "Research",
  RailTransportation = "Rail Transportation",
  HomeHealthCareServices = "Home Health Care Services",
  LeisureTravelAndTourism = "Leisure, Travel And Tourism",
  MedicalDevice = "Medical Device"




// export const Accounting = "Accounting",
//   AirlinesAviation = "Airlines/Aviation",
//   AlternativeDisputeResolution = "Alternative Dispute Resolution",
//   AlternativeMedicine = "Alternative Medicine",
//   Animation = "Animation",
//   ApparelAndFashion = "Apparel & Fashion",
//   ArchitectureAndPlanning = "Architecture & Planning",
//   ArtsAndCrafts = "Arts and Crafts",
//   Automotive = "Automotive",
//   AviationAndAerospace = "Aviation & Aerospace",
//   Banking = "Banking",
//   Biotechnology = "Biotechnology",
//   BroadcastMedia = "Broadcast Media",
//   BuildingMaterials = "Building Materials",
//   BusinessSuppliesAndEquipment = "Business Supplies and Equipment",
//   CapitalMarkets = "Capital Markets",
//   Chemicals = "Chemicals",
//   CivicAndSocialOrganization = "Civic & Social Organization",
//   CivilEngineering = "Civil Engineering",
//   CommercialRealEstate = "Commercial Real Estate",
//   ComputerAndNetworkSecurity = "Computer & Network Security",
//   ComputerGames = "Computer Games",
//   ComputerHardware = "Computer Hardware",
//   ComputerNetworking = "Computer Networking",
//   ComputerSoftware = "Computer Software",
//   Construction = "Construction",
//   ConsumerElectronics = "Consumer Electronics",
//   ConsumerGoods = "Consumer Goods",
//   ConsumerServices = "Consumer Services",
//   Cosmetics = "Cosmetics",
//   Dairy = "Dairy",
//   DefenseAndSpace = "Defense & Space",
//   Design = "Design",
//   EducationManagement = "Education Management",
//   ELearning = "E-Learning",
//   ElectricalElectronicManufacturing = "Electrical/Electronic Manufacturing",
//   Entertainment = "Entertainment",
//   EnvironmentalServices = "Environmental Services",
//   EventsServices = "Events Services",
//   ExecutiveOffice = "Executive Office",
//   FacilitiesServices = "Facilities Services",
//   Farming = "Farming",
//   FinancialServices = "Financial Services",
//   FineArt = "Fine Art",
//   Fishery = "Fishery",
//   FoodAndBeverages = "Food & Beverages",
//   FoodProduction = "Food Production",
//   FundRaising = "Fund-Raising",
//   Furniture = "Furniture",
//   GamblingAndCasinos = "Gambling & Casinos",
//   GlassCeramicsAndConcrete = "Glass, Ceramics & Concrete",
//   GovernmentAdministration = "Government Administration",
//   GovernmentRelations = "Government Relations",
//   GraphicDesign = "Graphic Design",
//   HealthWellnessAndFitness = "Health, Wellness and Fitness",
//   HigherEducation = "Higher Education",
//   HospitalAndHealthCare = "Hospital & Health Care",
//   Hospitality = "Hospitality",
//   HumanResources = "Human Resources",
//   ImportAndExport = "Import and Export",
//   IndividualAndFamilyServices = "Individual & Family Services",
//   IndustrialAutomation = "Industrial Automation",
//   InformationServices = "Information Services",
//   InformationTechnologyAndServices = "Information Technology and Services",
//   Insurance = "Insurance",
//   InternationalAffairs = "International Affairs",
//   InternationalTradeAndDevelopment = "International Trade and Development",
//   Internet = "Internet",
//   InvestmentBanking = "Investment Banking",
//   InvestmentManagement = "Investment Management",
//   Judiciary = "Judiciary",
//   LawEnforcement = "Law Enforcement",
//   LawPractice = "Law Practice",
//   LegalServices = "Legal Services",
//   LegislativeOffice = "Legislative Office",
//   LeisureTravelAndTourism = "Leisure, Travel & Tourism",
//   Libraries = "Libraries",
//   LogisticsAndSupplyChain = "Logistics and Supply Chain",
//   LuxuryGoodsAndJewelry = "Luxury Goods & Jewelry",
//   Machinery = "Machinery",
//   ManagementConsulting = "Management Consulting",
//   Maritime = "Maritime",
//   MarketingAndAdvertising = "Marketing and Advertising",
//   MarketResearch = "Market Research",
//   MechanicalOrIndustrialEngineering = "Mechanical or Industrial Engineering",
//   MediaProduction = "Media Production",
//   MedicalDevices = "Medical Devices",
//   MedicalPractice = "Medical Practice",
//   MentalHealthCare = "Mental Health Care",
//   Military = "Military",
//   MiningAndMetals = "Mining & Metals",
//   MotionPicturesAndFilm = "Motion Pictures and Film",
//   MuseumsAndInstitutions = "Museums and Institutions",
//   Music = "Music",
//   Nanotechnology = "Nanotechnology",
//   Newspapers = "Newspapers",
//   NonProfitOrganizationManagement = "Non-Profit Organization Management",
//   OilAndEnergy = "Oil & Energy",
//   OnlineMedia = "Online Media",
//   OutsourcingOffshoring = "Outsourcing/Offshoring",
//   PackageFreightDelivery = "Package/Freight Delivery",
//   PackagingAndContainers = "Packaging and Containers",
//   PaperAndForestProducts = "Paper & Forest Products",
//   PerformingArts = "Performing Arts",
//   Pharmaceuticals = "Pharmaceuticals",
//   Philanthropy = "Philanthropy",
//   Photography = "Photography",
//   Plastics = "Plastics",
//   PoliticalOrganization = "Political Organization",
//   PrimarySecondaryEducation = "Primary/Secondary Education",
//   Printing = "Printing",
//   ProfessionalTrainingAndCoaching = "Professional Training & Coaching",
//   ProgramDevelopment = "Program Development",
//   PublicPolicy = "Public Policy",
//   PublicRelationsAndCommunications = "Public Relations and Communications",
//   PublicSafety = "Public Safety",
//   Publishing = "Publishing",
//   RailroadManufacture = "Railroad Manufacture",
//   Ranching = "Ranching",
//   RealEstate = "Real Estate",
//   RecreationalFacilitiesAndServices = "Recreational Facilities and Services",
//   ReligiousInstitutions = "Religious Institutions",
//   RenewablesAndEnvironment = "Renewables & Environment",
//   Research = "Research",
//   Restaurants = "Restaurants",
//   Retail = "Retail",
//   SecurityAndInvestigations = "Security and Investigations",
//   Semiconductors = "Semiconductors",
//   Shipbuilding = "Shipbuilding",
//   SportingGoods = "Sporting Goods",
//   Sports = "Sports",
//   StaffingAndRecruiting = "Staffing and Recruiting",
//   Supermarkets = "Supermarkets",
//   Telecommunications = "Telecommunications",
//   Textiles = "Textiles",
//   ThinkTanks = "Think Tanks",
//   Tobacco = "Tobacco",
//   TranslationAndLocalization = "Translation and Localization",
//   TransportationTruckingRailroad = "Transportation/Trucking/Railroad",
//   Utilities = "Utilities",
//   VentureCapitalAndPrivateEquity = "Venture Capital & Private Equity",
//   Veterinary = "Veterinary",
//   Warehousing = "Warehousing",
//   Wholesale = "Wholesale",
//   WineAndSpirits = "Wine and Spirits",
//   Wireless = "Wireless",
//   WritingAndEditing = "Writing and Editing";
