import axios from '../axios';

const ROOT_URL = process.env.REACT_APP_ROOT_URL;

export function getLeadsByListId(params, recordsPerPage, pageNum, sort, sortOrder) {

    return axios({
        method: 'get',
        url: `/core/people-leads?${params}&recordsPerPage=${recordsPerPage}&pageNum=${pageNum}&sort=${sort}&sortOrder=${sortOrder}`,
        // params: params,
    });
}

export function searchLeadsByKeyword(keyword, recordsPerPage, pageNum, sort, order) {

    return axios({
        method: 'get',
        url: `/core/search-people-leads?keyword=${keyword}&recordsPerPage=${recordsPerPage}&pageNum=${pageNum}`
        // &sort=${sort}&order=${order}`,
        // params: params,
    });
}

export function moveLead(leadId, moveToListId) {

    return axios({
        method: 'patch',
        url: `/core/move-lead/${leadId}?listID=${moveToListId}`,
    });
}

export function moveBulkLeads(list_id, search_query, query_filters, selectAll, selectNew, selectInView, selectedIds, deSelectedIds, moveToListId) {

    return axios({
        method: 'patch',
        data: {
            list_id: list_id,
            search_query: search_query,
            query_filters: query_filters,
            select_all: selectAll,
            select_new: selectNew,
            select_in_view: selectInView,
            selected_ids: selectedIds,
            deselected_ids: deSelectedIds,
            move_to_list_id: moveToListId,
        },
        url: `/core/move-leads`,
    });
}

export function deleteBulkLeads(search_query, query_filters, selectAll, selectNew, selectInView, selectedIds, deSelectedIds, list_id) {

    return axios({
        method: 'delete',
        data: {
            search_query: search_query,
            query_filters: query_filters,
            select_all: selectAll,
            select_new: selectNew,
            select_in_view: selectInView,
            selected_ids: selectedIds,
            deselected_ids: deSelectedIds,
            list_id: list_id
        },
        url: `/core/people-leads`,
    });
}

export function deleteLead(leadId) {

    return axios({
        method: 'delete',
        url: `/core/people-leads/${leadId}`,
    });
}

export function exportLeadsToCSV(list_id, search_query, query_filters, email, selectAll, selectNew, selectInView, selectedIds, deSelectedIds, columns) {

    return axios({
        method: 'post',
        url: `/core/export-leads-csv`,
        data: {
            "filter_data": {
                "list_id": list_id,
                "search_query": search_query,
                "query_filters": query_filters,
                "select_all": selectAll,
                "select_new": selectNew,
                "select_in_view": selectInView,
                "selected_ids": selectedIds,
                "deselected_ids": deSelectedIds
            },
            "email": email,
            "columns": columns
        }
    });
}

export function integrateLeadsWithSelectedIntegrations(selectAll, listId, searchQuery, selectedIds, deSelectedIds, selectedIntegrationName) {

    return axios({
        method: 'post',
        url: `/core/${selectedIntegrationName}/leads`,
        data: {
            "filter_data": {
                "select_all": selectAll,
                "selected_ids": selectedIds,
                "search_query": searchQuery,
                "deselected_ids": deSelectedIds
            },
            "list_id": listId,
        }
    });
}

export function exportLeadsToGoogleSheet(automate, listid, email, searchQuery, query_filters, selectAll, selectedIds, deSelectedIds, sheetName, sheetId, columns) {

    return axios({
        method: 'post',
        url: `/core/export-leads-sheets`,
        data: {
            "filter_data": {
                "list_id": listid,
                "select_all": selectAll,
                "selected_ids": selectedIds,
                "search_query": searchQuery,
                "deselected_ids": deSelectedIds,
                "query_filters": query_filters,
            },
            "email": email,
            "sheet_name": sheetName,
            "sheet_id": sheetId,
            "columns": columns,
            "automate": automate,
        }
    });
}