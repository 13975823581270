import React, { Component, Fragment } from "react";
import SavedSearchesItemExpanded from "./SavedSearchesItemExpanded";
import moment from "moment";
import Loader from '../../Loader';
import SavedSearchesKeywordsWithCount from "./SavedSearchesKeywordsWithCount";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openPopup, closePopup } from '../../../actions/loader';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openPopup,
    closePopup,
  }, dispatch);
}

class SavedSearchesContent extends Component {
  state = {
    activeSearchId: ""
  };

  setActive = searchId => {
    if(searchId) {
      this.props.openPopup();
    }
    else {
      this.props.closePopup();
    }
    this.setState({ activeSearchId: searchId });
  };

  componentDidMount() {
  }

  formatDate(savedSearchDate) {
    return moment(savedSearchDate).format("MM-DD-YYYY");
  }

  differenceInTime(savedSearchDate) {
    const searchDate = moment(savedSearchDate);
    const diffInDays = moment().diff(searchDate, "days");
    if (diffInDays === 0) {
      const diffInHours = moment().diff(searchDate, "hours");
      if (diffInHours === 0) {
        const diffInMinutes = moment().diff(searchDate, "minutes");
        if (diffInMinutes < 1) {
          return 'Just now';
        }
        return `${diffInMinutes} minutes ago`;
      }
      return `${diffInHours} hours ago`;
    }
    return `${diffInDays} days ago`;
  }

  skipSelected = () => {
    this.setActive(null);
  }

  render() {
    const savedSearchItems = this.props.savedSearches.map((item, index) => {
      return (
        <Fragment key={index}>
          <div key={item.id} className={`result-item ${this.state.activeSearchId === item.id ? "active" : "" }`} onClick={() => this.setActive(item.id)}>
            <div className="result-item__row">
              <div className="result-item__col">
                <div className="result-item__data">
                  <p className="result-item__name">{item.name}</p>
                  <p className="result-item__education">{item.owner_info && item.owner_info.length ? ((item.owner_info[0].first_name + ' ' + item.owner_info[0].last_name ) != ' ' ? (item.owner_info[0].first_name + ' ' + item.owner_info[0].last_name ) : item.owner_info[0].email) : ''}</p>
                </div>
              </div>

              <div className="result-item__col">
                <div className="result-item__data">
                  <p className="result-item__name fw-normal">
                    {this.formatDate(item.creation_date)}
                  </p>
                  <p className="result-item__education">
                    {this.differenceInTime(item.creation_date)}
                  </p>
                </div>
              </div>

              <div className="result-item__col keywords_column saved-searches-selectedKeywords">
                <div className="result-item__data">
                  <SavedSearchesKeywordsWithCount query={item.query} showCount={true} />
                </div>
              </div>

              <div className="result-item__col link_column">
                <div className="result-item__show-results-link">
                  <p onClick={() => this.props.showSearchResults(item)} >Show Results</p>
                </div>
              </div>

              <div className="result-item__col-skip">
                <div className="result-item__skip" onClick={(e) => { e.stopPropagation(); this.props.showDeleteConfirmationPopup(item.id); }} >
                  <img src="/assest/billing/del.svg" alt="" />
                </div>
              </div>
            </div>

            {this.state.activeSearchId === item.id &&
              <SavedSearchesItemExpanded savedSearchQuery={item.query} showCriteriaFields={true}  showEditTraits={true} editSearchTraits={() => this.props.editSearchTraits(item)} />
            }
          </div>

          <div className={`result-item__hide ${this.state.activeSearchId === item.id ? "active" : "" }`} onClick={this.skipSelected}></div>
        </Fragment>
      );
    });

    return (
      <div className="saved_searches__content">
        {this.props.loader.loading && <Loader />}
        {savedSearchItems}
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(SavedSearchesContent);