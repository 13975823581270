const BillingPlanAndTerm = {
  plans: [
    {
      name: "Starter",
      description: "500 Lead Credits Per Month"
    },
  
    {
      name: "Scaling",
      description: "2,500 Lead Credits Per Month"
    }
  ],

  terms: {
    Starter: [
      {
        id: "starterMonthly",
        name: "$132.30 (monthly commitment)",
        description: "Billed monthly, 12 month commitment + 20% more lead credits for free"
      },
  
      {
        id: "starterMTM",
        name: "$147 (month to month)",
        description: "Billed monthly, cancel any time"
      },
  
      {
        id: "starterAnnual",
        name: "$1,411.20 (annual)",
        description: "Billed annually, 2+ month free + 50% more lead credits for free"
      },
    ],
  
    Scaling: [
      {
        id: "scalingMonthly",
        name: "$437.50 (monthly commitment)",
        description: "Billed monthly, 12 month commitment + 20% more lead credits for free"
      },
  
      {
        id: "scalingMTM",
        name: "$497 (month to month)",
        description: "Billed monthly, cancel any time"
      },
  
      {
        id: "scalingAnnual",
        name: "$4,771.20 (annual)",
        description: "Billed annually, 2+ month free + 50% more lead credits for free"
      },
    ]
  }
};

export function getBillingPlans() {
  return JSON.parse(JSON.stringify(BillingPlanAndTerm.plans));
}


export function getBillingTerms() {
  return JSON.parse(JSON.stringify(BillingPlanAndTerm.terms));
}
