import React, { Component, Fragment } from 'react';
import Details from './Details';
import HiringRole from './HiringRole';
import CompanyNews from './CompanyNews';
import './style.scss';
import CompanyTechnologies from "./CompanyTechnologies";
import {
  getCompany,
  getCompanyNews,
  getCompanyHiring,
  getCompanyTechnologies
} from '../../../../services/searchResult';

const async = require('async');


class Company extends Component{
  state = {
    detail: null,
    news: null,
    hiring: null,
    technologies:null
  };

  getCurrentSearchQueryParams = (cursorId) => {
    let queryParam = {
        personId: this.props.leadDetail.id,
    };

    if (cursorId != null) {
      queryParam.cursor = cursorId;
    }

    return queryParam;
}

generateQueryString = (isNext, page, next_cursor, last_cursor) => {
   
  let pageCount = page;
  let queryParam = {};

  if (isNext) {
    pageCount = pageCount + 1;
    queryParam = this.getCurrentSearchQueryParams(next_cursor);
  } else {
    if (pageCount > 1) {
      pageCount = pageCount - 1;
    }

    let lastCur = last_cursor;
    if (pageCount == 1) {
      lastCur = "";
    } 
    queryParam = this.getCurrentSearchQueryParams(lastCur);  
  }
  
  return queryParam;
}
  
async componentWillMount() {
  
  const { leadDetail } = this.props;
  const companyId = leadDetail.job.company.id;
  const queryParam = this.getCurrentSearchQueryParams()  
  const [companyDetail, companyNews, companyHiring, companyTechnologies] = await Promise.all([getCompany(companyId, queryParam), getCompanyNews(companyId, queryParam), getCompanyHiring(companyId, queryParam), getCompanyTechnologies(companyId, queryParam)]);  

  const addedToList = leadDetail.addedToList;
  const detail = companyDetail.data.data;

  const companyTechData = companyTechnologies.data.data;
  const technology = {
    technology: companyTechData,
    addedToList: addedToList,
    next_cursor: companyTechData.paging.next_cursor,
    last_cursor: "",
    page:1,
  }

  const hiringData = companyHiring.data.data;
  const hiring = {
    hiring: hiringData,
    addedToList: addedToList,
    next_cursor: hiringData.paging.next_cursor,
    last_cursor: "",
    page:1,
  }

  const newData = companyNews.data.data;
  const news = {
    news: newData,
    addedToList: addedToList,
    next_cursor: newData.paging.next_cursor,
    last_cursor: "",
    page:1,
  }
  
  this.setState({ detail, news, hiring, technology });
}

 handleTechPagination = async (isNext) => {  

  const {leadDetail} = this.props;
  const {page, next_cursor, last_cursor} = this.state.technology;
  
  let pageCount = page;
  let queryParam = {};

  if (isNext) {
    pageCount = pageCount + 1;
    queryParam = this.getCurrentSearchQueryParams(next_cursor);
  } else {
    if (pageCount > 1) {
      pageCount = pageCount - 1;
    }

    let lastCur = last_cursor;
    if (pageCount == 1) {
      lastCur = "";
    } 
    queryParam = this.getCurrentSearchQueryParams(lastCur);  
  }

  const [response] = await Promise.all([getCompanyTechnologies(leadDetail.job.company.id, queryParam)]);  

  const data = response.data.data;  
  let objCont = {
    technology: data,
    addedToList: leadDetail.addedToList,
    next_cursor: data.paging.next_cursor,
    last_cursor: next_cursor,
    page: pageCount,
  }

  if (data.paging.next_cursor == "" || next_cursor == "") {
    objCont.last_cursor = last_cursor;
  }

  if (pageCount == 1) {
    objCont.last_cursor = "";
  } 

  this.setState({ technology: objCont });
}


handleHiringPagination = async (isNext) => {
  const {leadDetail} = this.props;
  const {page, next_cursor, last_cursor} = this.state.hiring;

  
  let pageCount = page;
  let queryParam = {};

  if (isNext) {
    pageCount = pageCount + 1;
    queryParam = this.getCurrentSearchQueryParams(next_cursor);
  } else {
    if (pageCount > 1) {
      pageCount = pageCount - 1;
    }

    let lastCur = last_cursor;
    if (pageCount == 1) {
      lastCur = "";
    } 
    queryParam = this.getCurrentSearchQueryParams(lastCur);  
  }

  const [response] = await Promise.all([getCompanyHiring(leadDetail.job.company.id, queryParam)]);  

  const data = response.data.data;  
  let objCont = {
    hiring: data,
    addedToList: leadDetail.addedToList,
    next_cursor: data.paging.next_cursor,
    last_cursor: next_cursor,
    page: pageCount,
  }

  if (data.paging.next_cursor == "" || next_cursor == "") {
    objCont.last_cursor = last_cursor;
  }

  if (pageCount == 1) {
    objCont.last_cursor = "";
  } 

  this.setState({ hiring: objCont });
}


handleNewsPagination = async (isNext) => {
  const {leadDetail} = this.props;
  const {page, next_cursor, last_cursor} = this.state.news;

  
  let pageCount = page;
  let queryParam = {};

  if (isNext) {
    pageCount = pageCount + 1;
    queryParam = this.getCurrentSearchQueryParams(next_cursor);
  } else {
    if (pageCount > 1) {
      pageCount = pageCount - 1;
    }

    let lastCur = last_cursor;
    if (pageCount == 1) {
      lastCur = "";
    } 
    queryParam = this.getCurrentSearchQueryParams(lastCur);  
  }

  const [response] = await Promise.all([getCompanyNews(leadDetail.job.company.id, queryParam)]);  

  const data = response.data.data;  
  let objCont = {
    news: data,
    addedToList: leadDetail.addedToList,
    next_cursor: data.paging.next_cursor,
    last_cursor: next_cursor,
    page: pageCount,
  }

  if (data.paging.next_cursor == "" || next_cursor == "") {
    objCont.last_cursor = last_cursor;
  }

  if (pageCount == 1) {
    objCont.last_cursor = "";
  } 

  this.setState({ news: objCont });
}

  render(){
    const {detail, news, hiring, technology } = this.state;
  
    if (detail ===  null) {
      return(
        <div className="company-dt"></div>
      )
    }
    
    return(
      <div className="company-dt">
        {this.props.leadDetail && detail.company?.name != "" &&
          <Fragment>
            <Details leadDetail={detail} openAddLeadToListPopup={this.props.openAddLeadToListPopup} />
            {/* <CompanyTechnologies leadDetail={technology} openAddLeadToListPopup={this.props.openAddLeadToListPopup} handlePagination={this.handleTechPagination}/>
            <HiringRole leadDetail={hiring} openAddLeadToListPopup={this.props.openAddLeadToListPopup} handlePagination={this.handleHiringPagination}/>
            <CompanyNews leadDetail={news} openAddLeadToListPopup={this.props.openAddLeadToListPopup} handlePagination={this.handleNewsPagination}/> */}
          </Fragment>
        }
      </div>
    )
  }
}

export default Company