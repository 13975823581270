import React, { Component, Fragment } from "react";
import SavedSearchesKeywordsWithCount from "../../SavedSearchesKeywordsWithCount";

class SavedSearchKeywords extends Component {

  render() {

    const includeKeywords = [];
    const excludeKeywords = [];

    let queryObject = {};
    
    if (this.props.query.type === "people_prospector") {
      queryObject = this.props.query.people_prospector;

    } else if (this.props.query.type === "people_lookup") {
      queryObject = this.props.query.people_lookup;
    }

    if(queryObject.include_keywords && queryObject.include_keywords.length > 0) {
      includeKeywords.push(...queryObject.include_keywords);
    }

    if(queryObject.exclude_keywords && queryObject.exclude_keywords.length > 0) {
      excludeKeywords.push(...queryObject.exclude_keywords);
    }

    return (
      <Fragment>

        {(includeKeywords.length > 0 || excludeKeywords.length > 0) &&
          <ul className="saved-search-expanded__keywords">
            {includeKeywords.map((item, index) => 
              <li key={index}>{item}</li>
            )}

            {excludeKeywords.map((item, index) => 
              <li key={index} className="grey">{item}</li>
            )}
          </ul>
        }
        
        <SavedSearchesKeywordsWithCount query={this.props.query} showCount={false} />
      </Fragment>
    );
  }
}
export default SavedSearchKeywords;
