import React, { Component } from 'react'
import './style.scss'
import { withRouter } from 'react-router-dom';
import Popup from "reactjs-popup";
import items from '../Sidebar/items';
import { getBucketUrl } from '../../services/profile';
import { pictureUpdate, saveUserInfo } from '../../actions/profile_update';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import {getLoggedInUserInfo} from '../../services/users';
import {EmitterService} from "../../services/emitterService";
import ReactTooltip from 'react-tooltip';
import { LOCAL_STORAGE_KEYS } from '../../constants/constant';
import { startLoader, stopLoader, closePopup } from '../../actions/loader'
const queryString = require('query-string');

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    pictureUpdate,
    saveUserInfo,
    startLoader,
    stopLoader,
    closePopup
  }, dispatch)
}
function mapStateToProps(state) {
  return {
    profileImage: state.userProfileInfo,
    loader: state.loader
  }
}
class NavSearch extends Component {
  interval = null;

  state = {
    profilePic: '',
    logOut: false,
    status: '',
    active: items,
    searchValue: '',
    loggedInUser: null,
    updateForMoreLeads: false,
    leadsRemainingText: "",
    totalRemainingLeads: "0",
    remainingLeadsPercentage: 0,
    leadsConsumedColor: 'redColor'
  };

  componentDidMount = async () => {
    const { pictureUpdate } = this.props
    let user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
    let userStatus = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT));

    if (user) {
      const res = await getBucketUrl()
      let picture = "https://s3.amazonaws.com/" + res.data.data.s3_base_path + user.profile_pic_url
      await pictureUpdate(picture)
    }

    if (userStatus) {
      this.setState({ status: userStatus.status })
    }

    const qParams = queryString.parse(this.props.history.location.search);
    if (qParams.search) {
      this.setState({searchValue: qParams.search});
    }

    this.loadLeadsRemaining();

    EmitterService.subscribe("loadLeadsRemaining", () => {
      this.loadLeadsRemaining();
    });

    //this.interval = setInterval(() => { this.loadLeadsRemaining(); }, 10000);
  }

  componentWillUnmount = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  onSearchValueChange = (value) => {
    this.setState({searchValue: value});
  }

  onSearchValueSubmit = () => {
    const qParams = queryString.parse(this.props.history.location.search);

    this.props.history.push({
      pathname: '/lead-list/inside-list',
      search: `?search=${this.state.searchValue}`
    });
    // if(this.state.searchValue != qParams.search)
    //   this.props.closePopup();
  }

  menu = (action) => {
    const { active } = this.state
    const { history } = this.props
    if (action === "logout") {
      for (let key in active) {
        active[key].current = false
        active[key].subMenu = false
      }
      localStorage.clear();
      window.logout()
      this.setState({ logOut: true, active: active })
    } else if (action === "update") {
      history.push("/billing-and-users")
    } else if (action === "edit") {
      history.push("/profile")
    }
  }

  loadLeadsRemaining = async () => {
    // if(this.props.history.location.pathname == "/billing-and-users") {
    //   this.props.startLoader(true);
    // }
    const response = await getLoggedInUserInfo();
    const user = response.data.data;
    const isFraud = user.accounts[0].is_fraud;
    if (isFraud) {
      localStorage.clear();
      this.setState({ logOut: true});
      return;
    }
    this.props.saveUserInfo(user);

    if (user) {
      this.setState({loggedInUser: user});
      const account = user.accounts[0];

      if (account.is_unlimited) {
        this.setState({updateForMoreLeads: false, leadsRemainingText: "UNLIMITED", remainingLeadsPercentage: 100, totalRemainingLeads: "UNLIMITED", leadsConsumedColor: 'greenColor'});
        return;
      }

      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('userAccount', JSON.stringify(account))

      let remainingLeadsPercentage = 0;
      let leadsConsumedColor = 'redColor';

      if (user.available_lead_credits !== 0 && account.monthly_lead_credits !== 0) {

        const consumedLeads = (account.monthly_lead_credits - user.available_lead_credits) > 0 ? account.monthly_lead_credits - user.available_lead_credits : 0;

        
        const consumedLeadsPercentage = ((consumedLeads * 100 ) / account.monthly_lead_credits);
        remainingLeadsPercentage = 100 - consumedLeadsPercentage;

        if (remainingLeadsPercentage > 100) {
          remainingLeadsPercentage = 100;
        }

        if (remainingLeadsPercentage  >= 30 && remainingLeadsPercentage < 70) {
          leadsConsumedColor = 'orangeColor';
        } else if (remainingLeadsPercentage >= 70) {
          leadsConsumedColor = 'greenColor';
        }
      }

      const updateForMoreLeads = account.status !== "paid" || user.available_lead_credits < 0.1 * account.monthly_lead_credits;

      this.setState({
        updateForMoreLeads: updateForMoreLeads,
        leadsConsumedColor: leadsConsumedColor,
        remainingLeadsPercentage: remainingLeadsPercentage,
        totalRemainingLeads: user.available_lead_credits,
        leadsRemainingText: user.available_lead_credits + " / " + account.monthly_lead_credits
      });

      // if(this.props.history.location.pathname == "/billing-and-users") {
      //   this.props.stopLoader();
      // }
      //const queryParams = queryString.parse(this.props.history.location.search);
        
      // let session_id = queryParams.session_id;
      // if(session_id && localStorage.getItem('session_id')) {
      //   this.props.stopLoader();
      // }
    }
  }

  render() {
    const { logOut, status } = this.state
    const { profileImage, sideMenuToggel, history, loader } = this.props
    return (
      <div className="nav-search" 
        style={{background: (loader.isPopupOpen ? (loader.isEmployeePopupOpen ? '#cecece' : '#e5e5e5') : ''), 
          borderBottomColor: (loader.isEmployeePopupOpen ? '#d5d5d5' : '#eeeeee') }}>
        {logOut && history.push("/signin?status=logout")}

        <div className="nav-search__box">
          <div className="menu-btn" onClick={() => sideMenuToggel("open")}><img src="/assest/accountBased/acc-1.svg" alt="" /></div>
          <div className="nav-search__inp">
            <input placeholder="Search" 
              style={{background: (loader.isPopupOpen ? (loader.isEmployeePopupOpen ? '#cecece' : '#e5e5e5') : ''), 
                borderBottomColor: (loader.isEmployeePopupOpen ? '#d5d5d5' : '#eeeeee') }} 
              value={this.state.searchValue} name="search" type="text" onChange={(e => {
              this.onSearchValueChange(e.target.value);
            })}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                this.onSearchValueSubmit();
              }
            }} />
          </div>

          {this.state.loggedInUser &&
            <div className="nav-search-lead">
              <p
                className="nav-search-lead__text"
                // NOTE:there are multiple tooltips with the same id as here,
                // so this data should be commented because otherwise other tooltip
                // will be rendered
                // data-for="leadRemainingToolTip"
                // data-tip
                // data-place="bottom"
              >
                Leads Remaining: <span className={`${this.state.leadsRemainingText === 'UNLIMITED' ? 'bold' : ''}`}>{this.state.leadsRemainingText}</span>
              </p>
              <div className="nav-search-lead__line">
                <div className={`nav-search-lead__line-color ${this.state.leadsConsumedColor}`} style={{width: `${this.state.remainingLeadsPercentage}%`}}></div>
              </div>
              {this.state.updateForMoreLeads && <p className="nav-search-lead__link" onClick={() => history.push("/billing-and-users")}>Upgrade for more leads</p>}
              {/* <ReactTooltip id="leadRemainingToolTip">
                <p className="lead-remaining-tooltip">You have {this.state.totalRemainingLeads} leads remaining</p>
              </ReactTooltip> */}
            </div>
          }

        </div>
        <div className="nav-search-person">
          <Popup trigger={<div className="nav-search-person__photo">
            <span className="navBarImageIcon">
              <img src={`${profileImage.image}`} alt="" />
            </span>
            <div className="nav-menu" ></div>
          </div>}
            /* position="bottom" */
            arrow={false}
            closeOnDocumentClick
            contentStyle={{ 'border': 'none', 'boxShadow': 'none', 'background': 'transparent' }}>
            {close => (<div className="menu">
              <div className="menu-item" onClick={() => { close(); this.menu("update")} }><div><img src="/assest/accountBased/billing.svg" style={{marginRight: '5px'}} alt=""/>{`${status === "paid" ? "Billing" : "Upgrade"}`}</div></div>
              <div className="menu-item" onClick={() => { close(); this.menu("edit")} }><div><i className="fa fa-pencil-square-o" style={{marginRight: '5px'}}></i>Edit</div></div>
              <div className="menu-item" style={{ color: "#E01817" }} onClick={() => { close(); this.menu("logout")} }><div><i className="fa fa-power-off" style={{marginRight: '5px'}}></i>Log Out</div></div>
            </div>)}
          </Popup>
        </div>
      </div>
    )
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavSearch))
