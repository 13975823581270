import React, { Component, Fragment } from 'react';
import { Loader } from '../../../components';
import SearchResultItem from "./SearchResultItem";
import AddToList from './AddToList';
import './style.scss';
import NoList from "../../ApiKeysBox/NoApiKeys";

class ContentResult extends Component {

  state = {
    selectedList: null,
  };

  selectList = (list) => {
    this.setState({selectedList: list});
  }

  

  render(){
    const { items, selectItem, skipLead, unSkipSingleLead, popup, openAddLeadToListPopup, closeAddToListPopup, showSearchResultItemDetail, leadDetail, addLeadsToList } = this.props;

    return(
      <Fragment>
        <div className="result search-results">
          {this.props.showLoader && <Loader />}

          <div>
            {
              this.props.errorDiv && this.props.searchResultError ?
                <NoList
                  msg={"Sorry, we were unable to retrieve your lists. Please try again later or contact help@leadfuze.co for further assistance."}
                  submsg={``} />
                :
                (
                  (!this.props.showLoader && (!items || !items.length)) &&
                <NoList
                  msg={"No results found matching this search criteria."}
                  submsg={`Please try modifying your search criteria.`} />
                )
            }
          </div>

          {items && items.map((item, index)=>
              <Fragment key={index}>
                <SearchResultItem
                  item={item}
                  checked={item.check}
                  skipped={item.skipped}
                  selectItem={selectItem}
                  skipLead={skipLead}
                  unSkipSingleLead={unSkipSingleLead}
                  popup={popup}
                  openAddLeadToListPopup={openAddLeadToListPopup}
                  showSearchResultItemDetail={showSearchResultItemDetail}
                  leadDetail={leadDetail}
                  showLeadDetailLoader={this.props.showLeadDetailLoader}
                  showTabs={true} />
              </Fragment>
            )
          }

          {popup &&
            <AddToList closePopup={closeAddToListPopup} addLeadsToList={addLeadsToList} selectedList={this.state.selectedList} selectList={this.selectList} popup={popup} />
          }
        </div>
      </Fragment>
    )
  }
}

export default ContentResult;
