import {
  SEARCH_RESULT_ACTIONS
} from "../constants/constant";

const initialState = {
  searchResultList: []
};

const searchResults = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_RESULT_ACTIONS.SET_RESULTS_LIST:
      return {
        ...state,
        searchResultList: action.payload
      };

    default:
      return state
  }
}

export default searchResults;
