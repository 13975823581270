const images = [
  {
    image: 'text.png',
    name: 'image big',
  },
  {
    image: 'board-1.png',
    name: 'image',
  },
  {
    image: 'board-2.png',
    name: 'image',
  },
  {
    image: 'board-3.png',
    name: 'image',
  }
]

export default images