import React, {Component, Fragment} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {LeadDetail} from '../../../../components';

function mapStateToProps(state) {
    return {
        marketBased: state.marketBased,
    };
}

class SearchResultItem extends Component {

    state = {
        copy: false
    }

    getEmails = (emailObject) => {
        const emails = [];
        if (emailObject) {
            if (emailObject.personal) {
                emails.push(...emailObject.personal.deliverable);
                emails.push(...emailObject.personal.catchall);
            }

            if (emailObject.company) {
                emails.push(...emailObject.company.deliverable);
                emails.push(...emailObject.company.catchall);
            }
        }
        return emails.join(',');
    }

    getPhoneNumbers(phone) {
        const phoneNumbers = [];
        if (phone) {
            phone.forEach(item => {
                if (item.number) {
                    phoneNumbers.push(item.number);
                }
            });
        }
        return phoneNumbers.join(',');
    }

    getMailingAddress(item) {
    }

    onCopy = ($event) => {
        $event.stopPropagation();
        this.setState({copy: true});

        setTimeout(() => {
            this.setState({copy: false});
        }, 300);
    }

    render() {
        const {item, selectItem, skipLead, unSkipSingleLead, openAddLeadToListPopup, showSearchResultItemDetail, leadDetail, showTabs} = this.props;

        return (
            <Fragment>
                <div
                    className={`result-item ${item.active ? 'active' : ''} ${item.check ? 'checked' : ''} ${item.skipped ? 'leadSkipped' : ''} ${item.addedToList ? 'leadAdded' : ''}`}
                    onClick={() => showSearchResultItemDetail(item.poolId)}>
                    <img className="result-item__close" src="/assest/accountBased/close.svg" alt=""
                         onClick={($event) => {
                             $event.stopPropagation();
                             showSearchResultItemDetail()
                         }}/>

                    <div className="result-item__row">

                        <div className="result-item__col result-item__check">
                            <div
                                className={`check ${item.check ? 'active' : ''} ${(item.skipped || item.addedToList) ? 'disabled' : ''}`}
                                onClick={($event) => selectItem($event, item.poolId)}></div>
                        </div>

                        <div className="result-item__col">
                            <img src={item.profilePicture ? item.profilePicture : "/assest/accountBased/nothink.png"} className="profile-img" alt=""/>
                            <div className="result-item__data">
                                <p className="result-item__name">{item.fullname}</p>
                                <p className="result-item__education">{item.title}</p>
                            </div>
                        </div>

                        <div className="result-item__col result-item__company-info">
                            <p className="result-item__company"><a onClick={($event) => $event.stopPropagation()}
                                                                   href={`http://${item.website}`}
                                                                   target="_blank">{item.company}</a></p>
                            <p className="result-item__position">{item.industry.length < 40 ? item.industry : item.industry.slice(0, 37) + '...'}</p>
                        </div>

                        <div className="result-item__col">
                            {item.addedToList ?
                                <div className={`result-item__social addedToList`}>
                                    {item.hasEmail ? <CopyToClipboard text={this.getEmails(item.person.emails)}><a
                                        className={`copy ${this.state.copy ? 'copy_text' : null}`}
                                        onClick={(event) => this.onCopy(event)}><img
                                        src={item.verified ? '/assest/accountBased/social-icons/email-icon-green.svg' : '/assest/accountBased/social-icons/email-icon.svg'}
                                        alt=""/></a></CopyToClipboard> : ''}
                                    {item.hasPhone ? <CopyToClipboard text={this.getPhoneNumbers(item.phone)}><a
                                        className={`copy ${this.state.copy ? 'copy_text' : null}`}
                                        onClick={(event) => this.onCopy(event)}><img src="/assest/phone-icon.svg"
                                                                                     alt=""/></a></CopyToClipboard> : ''}
                                    {item.hasMailingAddress ? <CopyToClipboard text={item.mailing_address}><a
                                        className={`copy ${this.state.copy ? 'copy_text' : null}`}
                                        onClick={(event) => this.onCopy(event)}><i className="fa fa-building-o active"
                                                                                   aria-hidden="true"/></a></CopyToClipboard> : ''}
                                    {item.social_profiles && item.hasLinkedinUrl ?
                                        <a href={'http://' + item.social_profiles.linkedin} target="_blank"><img
                                            src="/assest/accountBased/social-icons/ln-icon.svg" alt=""/></a> : ''}
                                    {item.social_profiles && item.hasFbUrl ?
                                        <a href={'http://' + item.social_profiles.facebook} target="_blank"><img
                                            src="/assest/accountBased/social-icons/fb-icon.svg" alt=""/></a> : ''}
                                    {item.social_profiles && item.hasTwitterUrl ?
                                        <a href={'http://' + item.social_profiles.twitter} target="_blank"><img
                                            src="/assest/accountBased/social-icons/tw-icon.svg" alt=""/></a> : ''}
                                </div>
                                :
                                <div className={`result-item__social`}>
                                    {item.hasEmail ? <a><img src="/assest/accountBased/social-icons/email-icon.svg"
                                                             alt=""/></a> : ''}
                                    {item.hasPhone ? <a><img src="/assest/phone-icon.svg" alt=""/></a> : ''}
                                    {item.hasMailingAddress ?
                                        <a><i className="fa fa-building-o" aria-hidden="true"></i></a> : ''}
                                    {item.hasLinkedinUrl ?
                                        <a><img src="/assest/accountBased/social-icons/ln-icon.svg" alt=""/></a> : ''}
                                    {item.hasFbUrl ?
                                        <a><img src="/assest/accountBased/social-icons/fb-icon.svg" alt=""/></a> : ''}
                                    {item.hasTwitterUrl ?
                                        <a><img src="/assest/accountBased/social-icons/tw-icon.svg" alt=""/></a> : ''}
                                </div>
                            }

                            <p className="result-item__location">
                                <img src="/assest/pin.svg" alt=""/>
                                {
                                    ((!this.props.marketBased.personLocation || (this.props.marketBased.personLocation && this.props.marketBased.personLocation.length == 0)) && this.props.marketBased.companyLocation && this.props.marketBased.companyLocation.length > 0)
                                        ? item.companyLocation : item.personLocation
                                }

                            </p>
                        </div>

                        <div className="result-item__col">
                            {item.skipped ?
                                <div className={`result-item__link`}>
                                    <p onClick={($event) => unSkipSingleLead($event, item.poolId)}>Return Lead</p>
                                </div>
                                :
                                <div className={`result-item__link ${item.addedToList ? 'active' : ''} `}>
                                    {item.addedToList ?
                                        <p>
                                            Added to&nbsp;
                                            <Link
                                                to={`/lead-list/inside-list?list_id=${item.listId}&list_name=${item.listName}`}
                                                target="_blank">
                                                <span>{item.listName}</span>
                                            </Link>
                                        </p>
                                        :
                                        <Fragment>
                                            {item.addingLeadToList ?
                                                <div className="add-lead-progress">
                                                    <p>{item.leadProgressText}</p>
                                                    <p className="progress-bar"></p>
                                                </div>
                                                : item.warningMsg ?
                                                    <p className="warning" style={{textTransform: 'initial'}}>
                                                        <i className="fa fa-exclamation-triangle"></i>{item.warningMsg}
                                                    </p> : item.errorMsg ? <p className="error"
                                                                              style={{textTransform: 'initial'}}>{item.errorMsg}</p> :
                                                        <p onClick={($event) => openAddLeadToListPopup($event, item.poolId)}>Add
                                                            to list</p>
                                            }
                                        </Fragment>
                                    }
                                </div>
                            }

                        </div>

                        <div className="result-item__col-skip">
                            {(!item.skipped && !item.addedToList) &&
                            <div className="result-item__skip"
                                 onClick={($event) => skipLead($event, item.poolId, false)} data-tip="Skip">
                                <img src="/assest/accountBased/result-arr.svg" alt=""/>
                                <ReactTooltip/>
                            </div>
                            }
                        </div>

                    </div>

                    {item.active &&
                    <LeadDetail
                        leadDetail={leadDetail}
                        showLeadDetailLoader={this.props.showLeadDetailLoader}
                        showTabs={showTabs}
                        openAddLeadToListPopup={openAddLeadToListPopup}/>
                    }
                </div>

                <div className={`result-item__hide ${item.active ? 'active' : ''}`}
                     onClick={() => showSearchResultItemDetail()}></div>
            </Fragment>
        )
    }
}

export default connect(mapStateToProps, null)(SearchResultItem);
