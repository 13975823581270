import React, { Fragment, Component } from "react";
import Popup from "reactjs-popup";
const duxsoup = "duxsoup"
const woodpecker = "woodpecker"
const lemlist = "lemlist"
const salesforce = "salesforce"
const hubspot = "hubspot"
const freshsales = "freshsales"
const closeio = "closeio"
const bidsketch = "bidsketch"
const pipedrive = "pipedrive"
const salesflare = "salesflare"
const mailshake = "mailshake"

class InsideSelectIntegration extends Component {
  
  doesIntegrationExist = integrationName => {
    const foundIntegration = this.props.integrations.find(
      integration => integration.name === integrationName
    );
    return !!foundIntegration;
  };

  render() {
    return (
      <Fragment>
        <div className="inside-select__back"></div>
        <div className={`inside-integration-select open}`}>
          {/* {this.doesIntegrationExist(salesforce) ? (
            <div
              className="inside-integration-select__item menu-item icon-set"
              onClick={() => this.props.integrateSelectedLeadsWithIntegration(salesforce)}
            >
              <img src="/assest/integrations/salesforce-logo.ico" alt="" />
              SalesForce
            </div>
          ) : null} */}
          {this.doesIntegrationExist(duxsoup)? 
                (
                  <Popup trigger={ 
                    <div className="menu-item" >
                      <div style={{display: 'flex'}}>
                        <img src="/assest/integrations/duxsoup-logo.png" style={{width: 20, paddingLeft: 3, paddingRight: 3, marginRight: 10}} alt="" />Dux-Soup
                      </div>
                      <img className="menu-arrow revert" src="/assest/accountBased/arrow.svg" alt="" /></div>}
                    position={"left top"}
                    arrow={false}
                    on="hover"
                    contentStyle={{top:'10px', border: 'default', 'boxShadow': 'none', padding: 0 }}
                    >
                      <div className="menu-item icon-set" onClick={() => { this.props.integrateSelectedLeadsWithIntegration(duxsoup)} }><i class="fa fa-user-plus" style={{width: 20, paddingLeft: 3, paddingRight: 3, marginRight: 5}}  />Send Connection Request</div>
                      <div className="menu-item icon-set" onClick={() => { this.props.integrateSelectedLeadsWithIntegration(duxsoup)} }><i class="fa fa-eye" style={{width: 20, paddingLeft: 3, paddingRight: 3, marginRight: 5}}  />Visit Profile</div>
                    </Popup>
                )
                : null
              }
          {this.doesIntegrationExist(woodpecker)? <div className="menu-item icon-set" onClick={() => this.props.integrateSelectedLeadsWithIntegration(woodpecker)}><img className="woodpecker" src="/assest/integrations/woodpecker-logo.ico" alt="" />Woodpecker</div> : null}
          {this.doesIntegrationExist(mailshake)? <div className="menu-item icon-set" onClick={() => this.props.integrateSelectedLeadsWithIntegration(mailshake)}><img src="/assest/integrations/mailshake-logo.png" alt="" />Mailshake</div> : null}
          {this.doesIntegrationExist(lemlist)? <div className="menu-item icon-set" onClick={() => this.props.integrateSelectedLeadsWithIntegration(lemlist)}><img src="/assest/integrations/lemlist-logo.png" alt="" />lemlist</div> : null}
          {this.doesIntegrationExist(salesforce)? <div className="menu-item icon-set" onClick={() => this.props.integrateSelectedLeadsWithIntegration(salesforce)}><img src="/assest/integrations/salesforce-logo.ico" alt="" />Salesforce</div> : null}
          {this.doesIntegrationExist(hubspot)? <div className="menu-item icon-set" onClick={() => this.props.integrateSelectedLeadsWithIntegration(hubspot)}><img src="/assest/integrations/hubspot-logo.png" alt="" />Hubspot</div> : null}
          {this.doesIntegrationExist(freshsales)? <div className="menu-item icon-set" onClick={() => this.props.integrateSelectedLeadsWithIntegration(freshsales)}><img src="/assest/integrations/freshsales-logo.png" alt="" />Freshsales</div> : null}
          {this.doesIntegrationExist(closeio)? <div className="menu-item icon-set" onClick={() => this.props.integrateSelectedLeadsWithIntegration(closeio)}><img src="/assest/integrations/closeio-logo.ico" alt="" />CloseIo</div> : null}
          {this.doesIntegrationExist(bidsketch)? <div className="menu-item icon-set" onClick={() => this.props.integrateSelectedLeadsWithIntegration(bidsketch)}><img src="/assest/integrations/bidsketch-logo.ico" alt="" />Bidsketch</div> : null}
          {this.doesIntegrationExist(pipedrive)? <div className="menu-item icon-set" onClick={() => this.props.integrateSelectedLeadsWithIntegration(pipedrive)}><img src="/assest/integrations/pipedrive-logo.png" alt="" />Pipedrive</div> : null}
          {this.doesIntegrationExist(salesflare)? <div className="menu-item icon-set" onClick={() => this.props.integrateSelectedLeadsWithIntegration(salesflare)}><img src="/assest/integrations/salesflare-logo.png" alt="" />Salesflare</div> : null}



        </div>
      </Fragment>
    );
  }
}

export default InsideSelectIntegration;
