import React, { Component, Fragment } from "react";
import { Tooltip } from '@material-ui/core'
import { Loader } from "../../../components";
import { CreateConfigurations } from "./CreateConfigurations";
import { createHotLeadConfiguration, updateHotLeadConfiguration } from "../../../services/hotLeads";
import { isNotEmpty } from "../../../helpers/validate";
import { showErrorToast, showSuccessToast } from "../../../helpers/toast";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import ConfigureMenu from "./ConfigureMenu";

class ConfigurationList extends Component {
    constructor(props) {
        super(props)
        this.state = { newConfig: {}, isOpen: false }
    }

    onAddFunction = async () => {
        const { newConfig } = this.state
        let isValid = true, error = {}
        if (!isNotEmpty(newConfig.name)) {
            error = { name: 'Configuration name can not be empty' }
            isValid = false
        }
        if (!isNotEmpty(newConfig.website)) {
            error = { ...error, website: 'Website can not be empty' }
            isValid = false
        }
        if (!isValid) {
            this.setState({ errorMessage: error })
            return
        }
        if (this.state.isOpen === 'edit') {
            const toastId = toast('Updating config', {
                autoClose: 10000,
            });
            try {
                const res = await updateHotLeadConfiguration({
                    ...this.state.newConfig
                })
                showSuccessToast(toastId, "Config updated successfully")
                this.setState({ isOpen: false })
                this.props.getConfigurations()
                this.setState({ newConfig: {}, errorMessage: {} })
            } catch (error) {
                showErrorToast(toastId, "Error")
                this.setState({ value: {}, errorMessage: {} })
            }
            return
        }
        const toastId = toast('Creating new config', {
            autoClose: 10000,
        });
        try {
            const res = await createHotLeadConfiguration({
                ...this.state.newConfig,
                "is_slack_enabled": true
            })
            showSuccessToast(toastId, "Config created successfully")
            this.setState({ isOpen: false })
            this.props.getConfigurations()
            this.setState({ newConfig: {}, errorMessage: {} })
        } catch (error) {
            showErrorToast(toastId, "Error")
            this.setState({ value: {}, errorMessage: {} })
        }
    }

    getLink = () => {
        return `https://slack.com/oauth/v2/authorize?client_id=4155268034.8133964878371&scope=incoming-webhook,team:read&redirect_uri=${process.env.REACT_APP_API_GATEWAY_URL}/p/slack/oauth/callback&state={"auth":${localStorage.getItem("token")},"hlc_id":${this.props.config.hlc_id}}`
    }

    getWebsite = (url) => {
        let str = url?.replace('https://', "")?.replace('http://', "")
        str = str?.split('/')?.[0]
        return str
    }

    render() {
        const { name, website, hlc_id, script, is_slack_enabled, slack_webhook_url } = this.props.config
        return (
            <div className="markbased__table config-list">
                <section>
                    <div className="inside-list__title">
                        <h3>
                            Configurations
                        </h3>
                    </div>
                    <div className="inside-list-header">
                        <div className="inside-list-header__row">
                            <div className="inside-list-header__items">
                                <div className="inside-list-header__col">

                                    <p className={`inside-list-header__link`}>Name</p>
                                </div>
                                <div className="inside-list-header__col">

                                    <p className={`inside-list-header__link`}>Website</p>
                                </div>
                                <div className="inside-list-header__col">

                                    <p className={`inside-list-header__link`}>Slack Enabled</p>
                                </div>
                                <div className="inside-list-header__col">
                                </div>
                            </div>
                            <ul className="inside-list-list__nav"></ul>
                        </div>
                    </div>
                </section>
                <div className="inside-list-loader">
                    {this.props.loader.loading && <Loader />}
                </div>
                {(!this.props.loader.loading && (isEmpty(this.props.config))) &&
                    <>
                        <div className="dont-list">
                            <h2 className="dont-list__title">
                                No configuration found
                            </h2>
                            <p className="dont-list__descr">Please create new configuration
                            </p>
                            <div className="dont-list__btn" onClick={(e => this.setState({ isOpen: 'create' }))}>Create configuration</div>
                        </div>
                    </>
                }
                {!isEmpty(this.props.config) &&
                    <div className="lead-cont">
                        <Fragment>
                            <div className={`result-item leadAdded`}
                                onClick={() => { this.setState({ isOpen: 'view' }) }}>
                                <div className="result-item__row">
                                    <div className="result-item__col">
                                        <div className="result-item__data">
                                            {name}
                                        </div>
                                    </div>
                                    <div className="result-item__col">
                                        <div className="result-item__data" onClick={(e) => e.stopPropagation()}>
                                            <Tooltip
                                                placement="top"
                                                arrow title={website}
                                            >
                                                <a href={website} target="_blank">{this.getWebsite(website)}</a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="result-item__col">
                                        <div className={`result-item__data fw-500 ${is_slack_enabled ? 'green__text' : 'red-font'}`}>
                                            {is_slack_enabled ? "Active" : 'In Active'}
                                        </div>
                                    </div>
                                    <div className="result-item__col">
                                        <div className="result-item__data" >
                                            <p className="lead-cont-item__show" onClick={(e) => {
                                                e.stopPropagation();
                                                this.props.showLeads(hlc_id);
                                            }}>Show Hot Leads</p>
                                        </div>
                                    </div>
                                    <div className="result-item__col-skip">
                                        <div onClick={(e) => {
                                            e.stopPropagation();
                                        }}>
                                            <ConfigureMenu
                                                onEdit={() => this.setState({ isOpen: 'edit', newConfig: this.props.config })}
                                                slack_webhook_url={slack_webhook_url}
                                                slack_url={this.getLink()}
                                                onOpen={() => { this.setState({ isOpen: 'view' }) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    </div>
                }
                <CreateConfigurations
                    isOpen={this.state.isOpen}
                    errorMessage={this.state.errorMessage}
                    newConfig={this.state.newConfig}
                    script={script || ""}
                    onAddFunction={this.onAddFunction}
                    onClosePopup={() => this.setState({ isOpen: false })}
                    handleChange={(name, value) => this.setState({ newConfig: { ...this.state.newConfig, [name]: value } })}
                />
            </div>
        );
    }
}
export default ConfigurationList;
