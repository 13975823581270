import React, { Component } from 'react';
import OwnersContent from '../../LeadListFilter/OwnersContent';
import { OwnerFilter } from '../../OwnerFilter/OwnerFilter';

class SaveSearchesRightFilter extends Component{

  constructor(props) {
    super(props);

    this.state = {
      ownerState: false,
    };
  }

  componentDidMount() {
  }

  bannerHidden = () =>{
    // let demoVideoStatus = JSON.parse(localStorage.getItem('demoVideoStatus'));

    // if (this.props.page && demoVideoStatus && demoVideoStatus[this.props.page]) {
    //   demoVideoStatus[this.props.page].videoClosed = true;
    //   localStorage.setItem('demoVideoStatus', JSON.stringify(demoVideoStatus));
    // }

    // this.setState({active: 'hidden'});
  }

  toggleOwnerState = () => {
    let val = this.state.ownerState;
    this.setState({ownerState: !val});
    if(val == true) {
      this.props.ownersLists.map((item, index) => {
        if(item.check)
          this.props.changeCheck(index, item.user_id);
      });
    }
  }

  render(){
    const { isUserAdmin, searchOwnerFilter, searchValue, ownersLists, changeCheck, applyFilter, clearAllFilter, nameFilter, toggleNameFilter, name, changeName} = this.props;

    return(
        <section className="right">
        <div className="right-filters right-filters__checks actCheck">
          <h4 className="right-filters__title">Filters</h4>
          <h5 className="right-filters__title rm_margin" style={{marginBottom: 10, fontSize: 11}}>Filter BY:</h5>

          {isUserAdmin &&
          <div className="right-filters__check" onClick={this.toggleOwnerState}>
            <div className={`check ${this.state.ownerState ? 'active' : ''}`} ><span></span></div>
            <p>Owner</p>
          </div>
          }
          {
            (isUserAdmin && this.state.ownerState) &&
            <OwnerFilter
              searchValue={searchValue}
              searchOwnerFilter={searchOwnerFilter}
              ownersLists={ownersLists}
              changeCheck={changeCheck}
            />
          }
          <div className="right-filters__check" onClick={toggleNameFilter}>
            <div className={`check ${nameFilter ? 'active' : ''}`}><span></span></div>
            <p>Name</p>
          </div>
          {nameFilter &&
            <div className="other_option b_pedding">
              <div className="coupon-search__inp sm_coupon-search__inp">
                {/* <img src="/assest/accountBased/search.svg" alt="" /> */}
                <input type="text" style={{ padding: '9px 15px 9px 10px; !important' }}
                  placeholder="Enter name"
                  className="name-search"
                  value={name}
                  onInput={changeName} />
              </div>
              {/* listNames &&
                <div className="scroll_view">
                  <ListNames listNames={listNames}
                    changeCheckForName={changeCheckForName} />
                </div> */
              }
            </div>
          }
          <div className="btn-align top-mar">
            <div className="sidebarText__link " onClick={applyFilter}>Apply</div>
            <div className="adv-lead__link" onClick={clearAllFilter}><p>Clear all</p></div>
          </div>
        </div>
      </section>
    )
  }
}

export default SaveSearchesRightFilter
