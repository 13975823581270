import * as tech from "./technologies";
import tech_groups from '../files/tech_groups.json';
import { Telecommunications } from "./industry_name";

export const technology_groups = tech_groups.map(item => {
  return {
    category: item.group,
    label: item.group,
    group: item.group,
    include_tags: [],
    exclude_tags: [],
    technologies: item.names
  }
})
// export const technology_groups = [
//   {
//     category: "Advertising",
//     label: "Advertising",
//     group: "Advertising",
//     include_tags: ["retargeting", "ads", "ad_optimization", "advertising"],
//     exclude_tags: [],
//     technologies: tech.Advertising
//   },
//   {
//     category: "AdvertisingNetworks",
//     label: "Advertising Networks",
//     group: "Advertising Networks",
//     include_tags: ["advertising_networks"],
//     exclude_tags: [],
//     technologies: tech.AdvertisingNetworks
//   },
//   {
//     category: "Analytics",
//     label: "Analytics",
//     group: "Analytics",
//     include_tags: [
//       "audience_&_market_data",
//       "analytics",
//       "sales_analytics",
//       "marketing_performance_measurement"
//     ],
//     exclude_tags: [],
//     technologies: tech.Analytics
//   },
//   {
//     category: "AnalyticsSoftware",
//     label: "Analytics Software",
//     group: "Analytics Software",
//     include_tags: ["reporting_software"],
//     exclude_tags: [],
//     technologies: tech.AnalyticsSoftware
//   },
//   {
//     category: "ArchitectureDesign",
//     label: "Architecture Design",
//     group: "Architecture Design",
//     include_tags: ["architecture_design"],
//     exclude_tags: [],
//     technologies: tech.ArchitectureDesign
//   },
//   {
//     category: "Aviation",
//     label: "Aviation",
//     group: "Aviation",
//     include_tags: ["aviation"],
//     exclude_tags: [],
//     technologies: tech.Aviation
//   },
//   {
//     category: "Blogs",
//     label: "Blogs",
//     group: "Blogs",
//     include_tags: ["blogs"],
//     exclude_tags: [],
//     technologies: tech.Blogs
//   },
//   {
//     category: "Business",
//     label: "Business",
//     group: "Business",
//     include_tags: ["business"],
//     exclude_tags: [],
//     technologies: tech.Business
//   },
//   {
//     category: "BusinessIntelligence",
//     label: "Business Intelligence",
//     group: "Business Intelligence",
//     include_tags: [
//       "bi",
//       "business_service_management",
//       "business_analysis",
//       "bpm",
//       "automated_process/workflow_systems",
//       "epm",
//       "workforce_management_software",
//       "enterprise_performance_management",
//       "performance",
//       "performance_management",
//       "business_process_management"
//     ],
//     exclude_tags: [],
//     technologies: tech.BusinessIntelligence
//   },
//   {
//     category: "CAD",
//     label: "CAD / CAM",
//     group: "CAD / CAM",
//     include_tags: ["cad/cam"],
//     exclude_tags: [],
//     technologies: tech.CAD
//   },
//   {
//     category: "CDN",
//     label: "CDN",
//     group: "CDN",
//     include_tags: ["cdn", "cd_n", "content_delivery_network"],
//     exclude_tags: [],
//     technologies: tech.CDN
//   },
//   {
//     category: "CMSPlatforms",
//     label: "CMS Platforms",
//     group: "CMS Platforms",
//     include_tags: [
//       "cms",
//       "c_ms",
//       "web_content_management_systems",
//       "web_content_management_system",
//       "wcms",
//       "ecm"
//     ],
//     exclude_tags: [],
//     technologies: tech.CMSPlatforms
//   },
//   {
//     category: "CRM",
//     label: "CRM",
//     group: "CRM",
//     include_tags: ["crm"],
//     exclude_tags: [],
//     technologies: tech.CRM
//   },
//   {
//     category: "CallCenter",
//     label: "Call Center",
//     group: "Call Center",
//     include_tags: ["call_center"],
//     exclude_tags: [],
//     technologies: tech.CallCenter
//   },
//   {
//     category: "Captchas",
//     label: "Captchas",
//     group: "Captchas",
//     include_tags: ["captchas"],
//     exclude_tags: [],
//     technologies: tech.Captchas
//   },
//   {
//     category: "ChangeManagement",
//     label: "Change Management",
//     group: "Change Management",
//     include_tags: ["change_management"],
//     exclude_tags: [],
//     technologies: tech.ChangeManagement
//   },
//   {
//     category: "ChatSoftware",
//     label: "Chat Software",
//     group: "Chat Software",
//     include_tags: [
//       "chat_software",
//       "live_chat",
//       "chat",
//       "customer_messaging_platform",
//       "communications_technology"
//     ],
//     exclude_tags: [],
//     technologies: tech.ChatSoftware
//   },
//   {
//     category: "Collaboration",
//     label: "Collaboration",
//     group: "Collaboration",
//     include_tags: ["collaboration"],
//     exclude_tags: [],
//     technologies: tech.Collaboration
//   },
//   {
//     category: "CommentingSystems",
//     label: "Commenting Systems",
//     group: "Commenting Systems",
//     include_tags: ["comment_systems"],
//     exclude_tags: [],
//     technologies: tech.CommentingSystems
//   },
//   {
//     category: "Commerce",
//     label: "Commerce",
//     group: "Commerce",
//     include_tags: ["commerce"],
//     exclude_tags: [],
//     technologies: tech.Commerce
//   },
//   {
//     category: "Construction",
//     label: "Construction",
//     group: "Construction",
//     include_tags: ["construction_design"],
//     exclude_tags: [],
//     technologies: tech.Construction
//   },
//   {
//     category: "ContentMarketing",
//     label: "Content Marketing",
//     group: "Content Marketing",
//     include_tags: ["content_marketing"],
//     exclude_tags: [],
//     technologies: tech.ContentMarketing
//   },
//   {
//     category: "ControlSystems",
//     label: "Control Systems",
//     group: "Control Systems",
//     include_tags: ["control_systems"],
//     exclude_tags: [],
//     technologies: tech.ControlSystems
//   },
//   {
//     category: "Databases",
//     label: "Databases",
//     group: "Databases",
//     include_tags: ["data_storage", "database", "databases"],
//     exclude_tags: [],
//     technologies: tech.Databases
//   },
//   {
//     category: "DatabaseArchitecture",
//     label: "Database Architecture",
//     group: "Database Architecture",
//     include_tags: [
//       "visualization_software",
//       "file_system",
//       "gis",
//       "virtualisation_software",
//       "gis_software",
//       "virtualisation_software_virtualisation:_platform_management",
//       "virtualization:_application_&_desktop",
//       "geographis_information_system"
//     ],
//     exclude_tags: [],
//     technologies: tech.DatabaseArchitecture
//   },
//   {
//     category: "DatabaseManagement",
//     label: "Database Management",
//     group: "Database Management",
//     include_tags: [
//       "rdbms",
//       "database_managers",
//       "cloud_infrastructure_computing",
//       "data_archiving",
//       "data_quality",
//       "storage",
//       "database_management",
//       "database_management_software",
//       "database_management_system",
//       "cloud_computing_management",
//       "it_infrastructure_&_operations_management",
//       "ebs",
//       "data_management_&_storage_(hardware)",
//       "data_management_platform",
//       "dbms",
//       "data_warehouse"
//     ],
//     exclude_tags: [],
//     technologies: tech.DatabaseManagement
//   },
//   {
//     category: "Design",
//     label: "Design",
//     group: "Design",
//     include_tags: ["design"],
//     exclude_tags: [],
//     technologies: tech.Design
//   },
//   {
//     category: "DesignPublishing",
//     label: "Design & Publishing",
//     group: "Design & Publishing",
//     include_tags: ["design_&_publishing"],
//     exclude_tags: [],
//     technologies: tech.DesignAndPublishing
//   },
//   {
//     category: "DigitalMarketing",
//     label: "Digital Marketing",
//     group: "Digital Marketing",
//     include_tags: ["inbound"],
//     exclude_tags: [],
//     technologies: tech.DigitalMarketing
//   },
//   {
//     category: "DocumentTools",
//     label: "Document Tools",
//     group: "Document Tools",
//     include_tags: ["document_management"],
//     exclude_tags: [],
//     technologies: tech.DocumentTools
//   },
//   {
//     category: "DocumentionTools",
//     label: "Documention Tools",
//     group: "Documention Tools",
//     include_tags: [
//       "document_management_systems",
//       "documentation_tools",
//       "documents_software",
//       "documents"
//     ],
//     exclude_tags: [],
//     technologies: tech.DocumentationTools
//   },
//   {
//     category: "ESignature",
//     label: "E-Signature",
//     group: "E-Signature",
//     include_tags: ["esignature"],
//     exclude_tags: [],
//     technologies: tech.ESignature
//   },
//   {
//     category: "EcommercePlatforms",
//     label: "E-commerce Platforms",
//     group: "E-commerce Platforms",
//     include_tags: ["ecommerce"],
//     exclude_tags: [],
//     technologies: tech.EcommercePlatforms
//   },
//   {
//     category: "ERP",
//     label: "ERP",
//     group: "ERP",
//     include_tags: ["erp"],
//     exclude_tags: [],
//     technologies: tech.ERP
//   },
//   {
//     category: "Editors",
//     label: "Editors",
//     group: "Editors",
//     include_tags: ["editors"],
//     exclude_tags: [],
//     technologies: tech.Editors
//   },
//   {
//     category: "Education",
//     label: "Education",
//     group: "Education",
//     include_tags: ["education", "academic_&_education_management_software"],
//     exclude_tags: [],
//     technologies: tech.Education
//   },
//   {
//     category: "EducationSoftware",
//     label: "Education Software",
//     group: "Education Software",
//     include_tags: ["education_software"],
//     exclude_tags: [],
//     technologies: tech.EducationSoftware
//   },
//   {
//     category: "Email",
//     label: "Email",
//     group: "Email",
//     include_tags: ["mail", "email"],
//     exclude_tags: [],
//     technologies: tech.Email
//   },
//   {
//     category: "EmailMarketing",
//     label: "Email Marketing",
//     group: "Email Marketing",
//     include_tags: ["email_marketing"],
//     exclude_tags: [],
//     technologies: tech.EmailMarketing
//   },
//   {
//     category: "EngineeringDesign",
//     label: "Engineering Design",
//     group: "Engineering Design",
//     include_tags: ["engineering_design"],
//     exclude_tags: [],
//     technologies: tech.EngineeringDesign
//   },
//   {
//     category: "Enterprise",
//     label: "Enterprise",
//     group: "Enterprise",
//     include_tags: ["enteprise", "enterprise_business_solutions", "enterprise"],
//     exclude_tags: [],
//     technologies: tech.Enterprise
//   },
//   {
//     category: "EnterpriseSoftware",
//     label: "Enterprise Software",
//     group: "Enterprise Software",
//     include_tags: ["enterprise_software"],
//     exclude_tags: [],
//     technologies: tech.EnterpriseSoftware
//   },
//   {
//     category: "FeedReaders",
//     label: "Feed Readers",
//     group: "Feed Readers",
//     include_tags: ["feed_readers"],
//     exclude_tags: [],
//     technologies: tech.FeedReaders
//   },
//   {
//     category: "Finance",
//     label: "Finance",
//     group: "Finance",
//     include_tags: ["finance"],
//     exclude_tags: [],
//     technologies: tech.Finance
//   },
//   {
//     category: "Financial",
//     label: "Financial",
//     group: "Financial",
//     include_tags: ["financial", "budgeting_applicaiton"],
//     exclude_tags: [],
//     technologies: tech.Financial
//   },
//   {
//     category: "FinancialAnalytics",
//     label: "Financial Analytics",
//     group: "Financial Analytics",
//     include_tags: ["financial_analytical_applications"],
//     exclude_tags: [],
//     technologies: tech.FinancialAnalytics
//   },
//   {
//     category: "FontScripts",
//     label: "Font Scripts",
//     group: "Font Scripts",
//     include_tags: ["font_scripts"],
//     exclude_tags: [],
//     technologies: tech.FontScripts
//   },
//   {
//     category: "Fundraising",
//     label: "Fundraising",
//     group: "Fundraising",
//     include_tags: ["fundraising"],
//     exclude_tags: [],
//     technologies: tech.Fundraising
//   },
//   {
//     category: "HR",
//     label: "HR",
//     group: "HR",
//     include_tags: [
//       "hris",
//       "hcm",
//       "hr_management_systems",
//       "human_capital_management_system",
//       "human_capital_management",
//       "human_resource_management",
//       "human_resource_management_system",
//       "hr",
//       "human_capital_management_services",
//       "applicant_tracking_systems",
//       "hris_management",
//       "hcm_software",
//       "hrms",
//       "ehr"
//     ],
//     exclude_tags: [],
//     technologies: tech.HR
//   },
//   {
//     category: "Hardware",
//     label: "Hardware",
//     group: "Hardware",
//     include_tags: ["hardware"],
//     exclude_tags: [],
//     technologies: tech.Hardware
//   },
//   {
//     category: "Health",
//     label: "Health",
//     group: "Health",
//     include_tags: ["health", "medical", "electronic_health_record", "emr"],
//     exclude_tags: [],
//     technologies: tech.Health
//   },
//   {
//     category: "Hosting",
//     label: "Hosting",
//     group: "Hosting",
//     include_tags: [
//       "cloud_storage",
//       "cloud_servers",
//       "cloud_hosting",
//       "servers",
//       "cloudstorage",
//       "web_hosting",
//       "vps",
//       "back-up_&_recovery",
//       "cloud_services",
//       "hosting"
//     ],
//     exclude_tags: [],
//     technologies: tech.Hosting
//   },
//   {
//     category: "HostingPanels",
//     label: "Hosting Panels",
//     group: "Hosting Panels",
//     include_tags: ["hosting_panels"],
//     exclude_tags: [],
//     technologies: tech.HostingPanels
//   },
//   {
//     category: "IT",
//     label: "IT",
//     group: "IT",
//     include_tags: ["it"],
//     exclude_tags: [],
//     technologies: tech.IT
//   },
//   {
//     category: "ITManagement",
//     label: "IT Management",
//     group: "IT Management",
//     include_tags: ["it_management"],
//     exclude_tags: [],
//     technologies: tech.ITManagement
//   },
//   {
//     category: "ITServices",
//     label: "IT Services",
//     group: "IT Services",
//     include_tags: ["iaas"],
//     exclude_tags: [],
//     technologies: tech.ITServices
//   },
//   {
//     category: "Identity",
//     label: "Identity",
//     group: "Identity",
//     include_tags: [
//       "identity_governance",
//       "authentication",
//       "access_management_services"
//     ],
//     exclude_tags: [],
//     technologies: tech.Identity
//   },
//   {
//     category: "Java",
//     label: "Java",
//     group: "Java",
//     include_tags: ["java"],
//     exclude_tags: [],
//     technologies: tech.Java
//   },
//   {
//     category: "JavascriptFrameworks",
//     label: "Javascript Frameworks",
//     group: "Javascript Frameworks",
//     include_tags: ["javascript_frameworks", "java_script_frameworks"],
//     exclude_tags: [],
//     technologies: tech.JavascriptFrameworks
//   },
//   {
//     category: "JavascriptServices",
//     label: "Javascript Services",
//     group: "Javascript Services",
//     include_tags: [
//       "javascript_graphics",
//       "java_server",
//       "java_script_graphics",
//       "java_application_server"
//     ],
//     exclude_tags: [],
//     technologies: tech.JavascriptServices
//   },
//   {
//     category: "LandingPageBuilders",
//     label: "Landing Page Builders",
//     group: "Landing Page Builders",
//     include_tags: ["landing_page_builders"],
//     exclude_tags: [],
//     technologies: tech.LandingPageBuilders
//   },
//   {
//     category: "LearningManagementSystems",
//     label: "Learning Management Systems",
//     group: "Learning Management Systems",
//     include_tags: [
//       "learning_management_system",
//       "lms",
//       "elm",
//       "enterprise_learning"
//     ],
//     exclude_tags: [],
//     technologies: tech.LearningManagementSystems
//   },
//   {
//     category: "Legal",
//     label: "Legal",
//     group: "Legal",
//     include_tags: ["law", "legal_and_professional_services_management"],
//     exclude_tags: [],
//     technologies: tech.Legal
//   },
//   {
//     category: "ManagedServices",
//     label: "Managed Services",
//     group: "Managed Services",
//     include_tags: ["managed_service_providers"],
//     exclude_tags: [],
//     technologies: tech.ManagedServices
//   },
//   {
//     category: "ManufacturingEngineering",
//     label: "Manufacturing / Engineering",
//     group: "Manufacturing / Engineering",
//     include_tags: ["manufacturing/engineering"],
//     exclude_tags: [],
//     technologies: tech.ManufacturingOrEngineering
//   },
//   {
//     category: "Maps",
//     label: "Maps",
//     group: "Maps",
//     include_tags: ["maps"],
//     exclude_tags: [],
//     technologies: tech.Maps
//   },
//   {
//     category: "Marketing",
//     label: "Marketing",
//     group: "Marketing",
//     include_tags: [
//       "lead_generation",
//       "marketing",
//       "marketing_performance_management"
//     ],
//     exclude_tags: [],
//     technologies: tech.Marketing
//   },
//   {
//     category: "MarketingAnalytics",
//     label: "Marketing Analytics",
//     group: "Marketing Analytics",
//     include_tags: ["marketing_analytics"],
//     exclude_tags: [],
//     technologies: tech.MarketingAnalytics
//   },
//   {
//     category: "MarketingAutomation",
//     label: "Marketing Automation",
//     group: "Marketing Automation",
//     include_tags: ["marketing_automation"],
//     exclude_tags: [],
//     technologies: tech.MarketingAutomation
//   },
//   {
//     category: "Media",
//     label: "Media",
//     group: "Media",
//     include_tags: ["media"],
//     exclude_tags: [],
//     technologies: tech.Media
//   },
//   {
//     category: "MessageBoards",
//     label: "Message Boards",
//     group: "Message Boards",
//     include_tags: ["message_boards"],
//     exclude_tags: [],
//     technologies: tech.MessageBoards
//   },
//   {
//     category: "Middleware",
//     label: "Middleware",
//     group: "Middleware",
//     include_tags: ["middleware"],
//     exclude_tags: [],
//     technologies: tech.Middleware
//   },
//   {
//     category: "MiddlewareSoftware",
//     label: "Middleware Software",
//     group: "Middleware Software",
//     include_tags: ["middleware_software"],
//     exclude_tags: [],
//     technologies: tech.MiddlewareSoftware
//   },
//   {
//     category: "Miscellaneous",
//     label: "Miscellaneous",
//     group: "Miscellaneous",
//     include_tags: ["miscellaneous"],
//     exclude_tags: [],
//     technologies: tech.Miscellaneous
//   },
//   {
//     category: "MobileFrameworks",
//     label: "Mobile Frameworks",
//     group: "Mobile Frameworks",
//     include_tags: ["mobile_frameworks"],
//     exclude_tags: [],
//     technologies: tech.MobileFrameworks
//   },
//   {
//     category: "MobileMarketing",
//     label: "Mobile Marketing",
//     group: "Mobile Marketing",
//     include_tags: ["mobile_marketing"],
//     exclude_tags: [],
//     technologies: tech.MobileMarketing
//   },
//   {
//     category: "NetworkDevices",
//     label: "Network Devices",
//     group: "Network Devices",
//     include_tags: ["network_devices", "network_monitoring", "network"],
//     exclude_tags: [],
//     technologies: tech.NetworkDevices
//   },
//   {
//     category: "NetworkManagement",
//     label: "Network Management",
//     group: "Network Management",
//     include_tags: ["network_management_(hardware)"],
//     exclude_tags: [],
//     technologies: tech.NetworkManagement
//   },
//   {
//     category: "NetworkManagementSoftware",
//     label: "Network Management Software",
//     group: "Network Management Software",
//     include_tags: ["dns_service", "network_management_(software)"],
//     exclude_tags: [],
//     technologies: tech.NetworkManagementSoftware
//   },
//   {
//     category: "NetworkStorage",
//     label: "Network Storage",
//     group: "Network Storage",
//     include_tags: ["network_storage"],
//     exclude_tags: [],
//     technologies: tech.NetworkStorage
//   },
//   {
//     category: "NonProfits",
//     label: "Non Profits",
//     group: "Non Profits",
//     include_tags: ["nonprofits", "philanthropic"],
//     exclude_tags: [],
//     technologies: tech.NonProfits
//   },
//   {
//     category: "OperatingSystems",
//     label: "Operating Systems",
//     group: "Operating Systems",
//     include_tags: [
//       "operating_system",
//       "operating_systems",
//       "operating_systems_&_computing_languages"
//     ],
//     exclude_tags: [],
//     technologies: tech.OperatingSystems
//   },
//   {
//     category: "Optimization",
//     label: "Optimization",
//     group: "Optimization",
//     include_tags: ["optimisation"],
//     exclude_tags: [],
//     technologies: tech.Optimization
//   },
//   {
//     category: "PRSoftware",
//     label: "PR Software",
//     group: "PR Software",
//     include_tags: ["pr_software"],
//     exclude_tags: [],
//     technologies: tech.PRSoftware
//   },
//   {
//     category: "PaymentProcessors",
//     label: "Payment Processors",
//     group: "Payment Processors",
//     include_tags: ["payment_processors"],
//     exclude_tags: [],
//     technologies: tech.PaymentProcessors
//   },
//   {
//     category: "PaymentSystem",
//     label: "Payment System",
//     group: "Payment System",
//     include_tags: ["paywalls", "electronic_payment_system", "payments"],
//     exclude_tags: [],
//     technologies: tech.PaymentSystem
//   },
//   {
//     category: "Payroll",
//     label: "Payroll",
//     group: "Payroll",
//     include_tags: [
//       "payroll_processing",
//       "payroll",
//       "accounting_payroll",
//       "accounting"
//     ],
//     exclude_tags: [],
//     technologies: tech.Payroll
//   },
//   {
//     category: "Personalization",
//     label: "Personalization",
//     group: "Personalization",
//     include_tags: ["personalization"],
//     exclude_tags: [],
//     technologies: tech.Personalization
//   },
//   {
//     category: "PhotoGalleries",
//     label: "Photo Galleries",
//     group: "Photo Galleries",
//     include_tags: ["photo_galleries"],
//     exclude_tags: [],
//     technologies: tech.PhotoGalleries
//   },
//   {
//     category: "Platform",
//     label: "Platform",
//     group: "Platform",
//     include_tags: ["platform"],
//     exclude_tags: [],
//     technologies: tech.Platform
//   },
//   {
//     category: "Predictive",
//     label: "Predictive",
//     group: "Predictive",
//     include_tags: ["forecasting_application", "predictive"],
//     exclude_tags: [],
//     technologies: tech.Predictive
//   },
//   {
//     category: "Presentation",
//     label: "Presentation",
//     group: "Presentation",
//     include_tags: ["presentation"],
//     exclude_tags: [],
//     technologies: tech.Presentation
//   },
//   {
//     category: "Printers",
//     label: "Printers",
//     group: "Printers",
//     include_tags: ["printers"],
//     exclude_tags: [],
//     technologies: tech.Printers
//   },
//   {
//     category: "ProductDesign",
//     label: "Product Design",
//     group: "Product Design",
//     include_tags: ["product_design"],
//     exclude_tags: [],
//     technologies: tech.ProductDesign
//   },

//   {
//     category: "Productivity",
//     label: "Productivity",
//     group: "Productivity",
//     include_tags: ["productivity_solutions"],
//     exclude_tags: [],
//     technologies: tech.Productivity
//   },
//   {
//     category: "ProgrammingLanguages",
//     label: "Programming Languages",
//     group: "Programming Languages",
//     include_tags: [
//       "programming_languages",
//       "programming_language",
//       "customer_information_control_system"
//     ],
//     exclude_tags: [],
//     technologies: tech.ProgrammingLanguages
//   },
//   {
//     category: "ProjectManagement",
//     label: "Project Management",
//     group: "Project Management",
//     include_tags: ["project_management"],
//     exclude_tags: [],
//     technologies: tech.ProjectManagement
//   },
//   {
//     category: "PropertyManagement",
//     label: "Property Management",
//     group: "Property Management",
//     include_tags: ["property_management"],
//     exclude_tags: [],
//     technologies: tech.PropertyManagement
//   },
//   {
//     category: "Publishing",
//     label: "Publishing",
//     group: "Publishing",
//     include_tags: ["publishing"],
//     exclude_tags: [],
//     technologies: tech.Publishing
//   },
//   {
//     category: "QualityManagementSystems",
//     label: "Quality Management Systems",
//     group: "Quality Management Systems",
//     include_tags: ["quality_management_systems"],
//     exclude_tags: [],
//     technologies: tech.QualityManagementSystems
//   },
//   {
//     category: "RealEstate",
//     label: "Real Estate",
//     group: "Real Estate",
//     include_tags: ["realty", "real_estate"],
//     exclude_tags: [],
//     technologies: tech.RealEstate
//   },
//   {
//     category: "RemoteAccess",
//     label: "Remote Access",
//     group: "Remote Access",
//     include_tags: ["remote_access"],
//     exclude_tags: [],
//     technologies: tech.RemoteAccess
//   },
//   {
//     category: "RichTextEditors",
//     label: "Rich Text Editors",
//     group: "Rich Text Editors",
//     include_tags: ["rich_text_editors"],
//     exclude_tags: [],
//     technologies: tech.RichTextEditors
//   },
//   {
//     category: "SAP",
//     label: "SAP",
//     group: "SAP",
//     include_tags: ["sap"],
//     exclude_tags: [],
//     technologies: tech.SAP
//   },
//   {
//     category: "SSL",
//     label: "SSL",
//     group: "SSL",
//     include_tags: ["ssl_certificate"],
//     exclude_tags: [],
//     technologies: tech.SSL
//   },
//   {
//     category: "SaaS",
//     label: "SaaS",
//     group: "SaaS",
//     include_tags: ["saas"],
//     exclude_tags: [],
//     technologies: tech.SaaS
//   },
//   {
//     category: "Sales",
//     label: "Sales",
//     group: "Sales",
//     include_tags: ["sales"],
//     exclude_tags: [],
//     technologies: tech.Sales
//   },
//   {
//     category: "SearchEngines",
//     label: "Search Engines",
//     group: "Search Engines",
//     include_tags: ["search_engines"],
//     exclude_tags: [],
//     technologies: tech.SearchEngines
//   },
//   {
//     category: "Server",
//     label: "Server",
//     group: "Server",
//     include_tags: ["server", "media_servers"],
//     exclude_tags: [],
//     technologies: tech.Server
//   },
//   {
//     category: "ServerTechnologies",
//     label: "Server Technologies",
//     group: "Server Technologies",
//     include_tags: ["server_technologies_(hardware)"],
//     exclude_tags: [],
//     technologies: tech.ServerTechnologies
//   },
//   {
//     category: "ServiceandFieldSupportManagement",
//     label: "Service and Field Support Management",
//     group: "Service and Field Support Management",
//     include_tags: ["service_&_field_support_management"],
//     exclude_tags: [],
//     technologies: tech.ServiceandFieldSupportManagement
//   },
//   {
//     category: "ShoppingCart",
//     label: "Shopping Cart",
//     group: "Shopping Cart",
//     include_tags: ["shopping_cart"],
//     exclude_tags: [],
//     technologies: tech.ShoppingCart
//   },
//   {
//     category: "SmallBusiness",
//     label: "Small Business",
//     group: "Small Business",
//     include_tags: ["small_business"],
//     exclude_tags: [],
//     technologies: []
//   },
//   {
//     category: "SocialMediaMarketing",
//     label: "Social Media Marketing",
//     group: "Social Media Marketing",
//     include_tags: ["social_marketing", "social_media_marketing"],
//     exclude_tags: [],
//     technologies: tech.SocialMediaMarketing
//   },
//   {
//     category: "Software",
//     label: "Software",
//     group: "Software",
//     include_tags: ["software", "software_-_other", "open_source"],
//     exclude_tags: [],
//     technologies: tech.Software
//   },
//   {
//     category: "SoftwareDevelopmentTools",
//     label: "Software Development Tools",
//     group: "Software Development Tools",
//     include_tags: [
//       "cache_tools",
//       "web_server_extensions",
//       "dev_tools",
//       "build_ci_systems",
//       "issue_trackers",
//       "visualisation_software",
//       "information_integration",
//       "application_development_&_management",
//       "web_driver",
//       "software_development",
//       "web_development",
//       "application_development"
//     ],
//     exclude_tags: [],
//     technologies: tech.SoftwareDevelopmentTools
//   },
//   {
//     category: "SoftwareTools",
//     label: "Software Tools",
//     group: "Software Tools",
//     include_tags: ["tools"],
//     exclude_tags: [],
//     technologies: tech.SoftwareTools
//   },
//   {
//     category: "SupplyChainManagement",
//     label: "Supply Chain Management",
//     group: "Supply Chain Management",
//     include_tags: ["supply_chain_management"],
//     exclude_tags: [],
//     technologies: tech.SupplyChainManagement
//   },
//   {
//     category: "Support",
//     label: "Support",
//     group: "Support",
//     include_tags: [
//       "customer_support",
//       "help_desk_management",
//       "help_desk_software"
//     ],
//     exclude_tags: [],
//     technologies: tech.Support
//   },
//   {
//     category: "SurveySoftware",
//     label: "Survey Software",
//     group: "Survey Software",
//     include_tags: ["survey_software"],
//     exclude_tags: [],
//     technologies: tech.SurveySoftware
//   },
//   {
//     category: "SystemAnalyticsMonitoring",
//     label: "System Analytics Monitoring",
//     group: "System Analytics Monitoring",
//     include_tags: ["monitoring", "system_analytics_&_monitoring"],
//     exclude_tags: [],
//     technologies: tech.SystemAnalyticsAndMonitoring
//   },
//   {
//     category: "SystemSecurityServices",
//     label: "System Security Services",
//     group: "System Security Services",
//     include_tags: [
//       "system_security_services",
//       "endpoint_protection",
//       "security"
//     ],
//     exclude_tags: [],
//     technologies: tech.SystemSecurityServices
//   },
//   {
//     category: "TagManagers",
//     label: "Tag Managers",
//     group: "Tag Managers",
//     include_tags: ["tag_managers"],
//     exclude_tags: [],
//     technologies: tech.TagManagers
//   },
//   {
//     category: "TechnologyCat",
//     label: "Technology",
//     group: "Technology",
//     include_tags: ["technology"],
//     exclude_tags: [],
//     technologies: tech.Technology
//   },
//   {
//     category: "Telecommunications",
//     label: "Telecommunications",
//     group: "Telecommunications",
//     include_tags: ["telecomunications"],
//     exclude_tags: [],
//     technologies: tech.Telecommunications
//   },
//   {
//     category: "Testing",
//     label: "Testing",
//     group: "Testing",
//     include_tags: ["testing"],
//     exclude_tags: [],
//     technologies: tech.Testing
//   },
//   {
//     category: "Ticket",
//     label: "Ticket",
//     group: "Ticket",
//     include_tags: ["ticketing"],
//     exclude_tags: [],
//     technologies: tech.Ticket
//   },
//   {
//     category: "Travel",
//     label: "Travel",
//     group: "Travel",
//     include_tags: ["travel_management", "travel_and_expense_management"],
//     exclude_tags: [],
//     technologies: tech.Travel
//   },
//   {
//     category: "VOIP",
//     label: "VOIP",
//     group: "VOIP",
//     include_tags: ["voip"],
//     exclude_tags: [],
//     technologies: tech.VOIP
//   },
//   {
//     category: "Video",
//     label: "Video",
//     group: "Video",
//     include_tags: ["video", "video_conferencing"],
//     exclude_tags: [],
//     technologies: tech.Video
//   },
//   {
//     category: "VideoPlayers",
//     label: "Video Players",
//     group: "Video Players",
//     include_tags: ["video_players"],
//     exclude_tags: [],
//     technologies: tech.VideoPlayers
//   },
//   {
//     category: "WebPortalTechnology",
//     label: "Web & Portal Technology",
//     group: "Web & Portal Technology",
//     include_tags: ["web_&_portal_technology"],
//     exclude_tags: [],
//     technologies: tech.WebAndPortalTechnology
//   },
//   {
//     category: "WebCams",
//     label: "Web Cams",
//     group: "Web Cams",
//     include_tags: ["webcams"],
//     exclude_tags: [],
//     technologies: tech.WebCams
//   },
//   {
//     category: "WebFrameworks",
//     label: "Web Frameworks",
//     group: "Web Frameworks",
//     include_tags: ["widgets", "web_frameworks"],
//     exclude_tags: [],
//     technologies: tech.WebFrameworks
//   },
//   {
//     category: "WebMail",
//     label: "Web Mail",
//     group: "Web Mail",
//     include_tags: ["web_mail"],
//     exclude_tags: [],
//     technologies: tech.WebMail
//   },
//   {
//     category: "WebServer",
//     label: "Web Server",
//     group: "Web Server",
//     include_tags: ["web_servers"],
//     exclude_tags: [],
//     technologies: tech.WebServer
//   },
//   {
//     category: "WebsiteDesign",
//     label: "Website Design",
//     group: "Website Design",
//     include_tags: ["website_design"],
//     exclude_tags: [],
//     technologies: tech.WebsiteDesign
//   },
//   {
//     category: "Wikis",
//     label: "Wikis",
//     group: "Wikis",
//     include_tags: ["wikis"],
//     exclude_tags: [],
//     technologies: tech.Wikis
//   },
//   {
//     category: "Wordpress",
//     label: "Wordpress",
//     group: "Wordpress",
//     include_tags: ["wordpress"],
//     exclude_tags: [],
//     technologies: tech.Wordpress
//   }
// ];
