import React, {Component} from 'react'
import './style.scss'
import Functions from '../../MarkedBasedBox/MarketSearch/FieldsDropdown/Functions'

class LeadSearch extends Component {
  state = {
    collapse: false
  };

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const {
      setIncludeTags,
      setExcludeTags,
      setGroups,
      clearFields,
      // saveSearch,
      searchError,
      updateClearFields,
      togglePastEmployment,
      accountBased
    } = this.props;

    let errorDiv = null;
      if(searchError) {
        errorDiv = (<div className="adv-lead__error_div">
        <p>Please enter company/domain before searching.</p>
      </div>);
      }
    return (
      <section className="lead-search">
        <h2 className={`lead-search__title ${this.state.collapse ? "expend" : "collapse"}`} onClick={this.toggle}>Advanced Search Criteria</h2>
        
        <div className="lead-search__container">

          {!this.state.collapse &&
            <div className="lead-search-filters">
              <div className="lead-search-key">
                <div className="people-market__col lead-search__keywords w-100">
                  <p className="people-market__label">Keywords</p>
                  <Functions
                    placeholder="Keywords"
                    type="text"
                    name="keywords"
                    excludeKeywords={this.props.accountBased.exclude_keywords}
                    includeKeywords={this.props.accountBased.include_keywords}
                    groups={[]}
                    customInput={true}
                    haveTags={false}
                    basicHeading1="All Include & Exclude Tags"
                    setIncludeTags={setIncludeTags}
                    setExcludeTags={setExcludeTags}
                    setGroups={setGroups}
                    clearFields={clearFields}
                    updateClearFields={updateClearFields}
                    showExclude={true}
                  />
                </div>
              </div>

              <div className="w-50">
                <div className="lead-search__past-history" onClick={togglePastEmployment}>
                  <p className="switch__name">Search Past Employment History</p>
                  <div className={`switch ${accountBased.search_past_employment ? 'active' : ''}`}><span></span></div>
                </div>
              </div>

            </div>
          }

          {errorDiv}

          <div className="lead-search__box">
            <div className="lead-search__buttons">
              <div className="lead-search-key__btn orange" onClick={this.props.applyAccountBasedForm}>Start searching</div>
              {/* <div className="lead-search-key__btn orange-light" onClick={saveSearch}>Save search</div> */}
            </div>

            <div className="lead-search__link"  onClick={this.props.resetFilters}>
              <p>Clear all fields</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default LeadSearch;