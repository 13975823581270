import React, {Component} from 'react';
import {Loader, Pagination} from '../../../../components';
import {deleteBillingUser, getBillingUsers} from '../../../../services/billing';
import './style.scss';
import {toast} from 'react-toastify';
import UserDetailsContent from './UserDetailsContent';
import ConfirmationPopup from "../../../ConfirmationPopup";
import AddUserPopup from "./AddUserPopup";
import {EmitterService} from "../../../../services/emitterService";

const tableHeader = [
    {name: 'User & Email', className: 'user-email'},
    {name: 'Leads added', className: 'leads-added'},
    {name: 'Max leads', className: 'max-leads'},
    {name: 'Leads remaining', className: 'leads-remaining'},
    {name: 'Number of lists', className: 'number-of-lists'},
    {name: 'Last login date', className: 'last-login-date'},
]

class UserDetails extends Component {
    container = React.createRef();

    state = {
        items: [],
        billingUsersLoading: false,

        searchQuery: "",
        currentPage: 1,
        recordsPerPage: 25,
        showDropDown: false,
        totalUsers: 0,

        showAddUser: false,
        showConfirmation: false,
        selectedItem: null
    };

    componentDidMount() {
        let recordsPerPage = localStorage.getItem('recordsPerPage');
        if (recordsPerPage === null) {
            localStorage.setItem('recordsPerPage', '25');
            recordsPerPage = localStorage.getItem('recordsPerPage');
        }

        this.setState({recordsPerPage: recordsPerPage}, this.loadBillingUsers);
    }

    loadBillingUsers = async () => {
        this.setState({billingUsersLoading: true});

        try {
            const res = await getBillingUsers(this.state.currentPage, this.state.recordsPerPage, this.state.searchQuery);
            const users = res.data.data.users
            this.props.onReceiveUserContent(users.reduce((total, u) => {
                return total + u.list_count
            }, 0), users.reduce((total, u) => {
                return total + u.used_lead_credits
            }, 0), users.reduce((total, u) => {
                return total + u.max_lead_credits
            }, 0), users.filter((u) => {return u.max_lead_credits < 0}).length > 0)
            this.setState({items: res.data.data.users, totalUsers: res.data.data.total_number_of_users});
        }
        catch(err) {
            
        }
        this.setState({billingUsersLoading: false});
    }

    _getSelectedItem = (id) => {
        let selectedItem = null;
        this.state.items.forEach((item) => {
            if (item.id === id) {
                selectedItem = item;
                return;
            }
        });
        return selectedItem;
    }

    toggleUserDetail = (id, toggleValue) => {
        const item = this._getSelectedItem(id);

        if (item.active && toggleValue) {
            return;
        }

        item.active = toggleValue;
        item.editMode = false;
        this.setState({items: this.state.items});
    }

    toggleMenuDropDown = (id, toggleValue) => {
        const item = this._getSelectedItem(id);
        item.menuDropDownActive = toggleValue;

        this.setState({items: this.state.items});
    }

    onEnterPressed = (e) => {
        let code = e.keyCode || e.which;
        if (code === 13) {
            this.setState({currentPage: 1}, this.loadBillingUsers);
        }
    }

    onKeywordChange = (e) => {
        this.setState({searchQuery: e.target.value});
    }

    paginate = async (num) => {
        window.scrollTo(0, 0);
        this.setState({currentPage: num}, this.loadBillingUsers);
    }

    incOrDecNum = (sign, indexOfLastPage) => {
        let currentPage = this.state.currentPage;

        if (sign === "-" && currentPage > 1) {
            currentPage = currentPage - 1
            this.paginate(currentPage)
        } else if (sign === "+" && currentPage < indexOfLastPage) {
            currentPage = currentPage + 1
            this.paginate(currentPage)
        }
    }

    paginationPopup = async () => {
        this.setState({showDropDown: !this.state.showDropDown})
    }

    updatePerPage = async (sign, num) => {
        let integer = parseInt(num);

        if (sign === "+" && integer < 100) {
            this.setState({recordsPerPage: integer + 25}, this.loadBillingUsers);
            localStorage.setItem('recordsPerPage', integer + 25);
        } else if (sign === "-" && integer > 25) {
            this.setState({recordsPerPage: integer - 25}, this.loadBillingUsers);
            localStorage.setItem('recordsPerPage', integer - 25);
        } else if (sign === '') {
            this.setState({showDropDown: !this.state.showDropDown, recordsPerPage: integer}, this.loadBillingUsers);
            localStorage.setItem('recordsPerPage', integer);
        }
    }

    showAddUserPopup = (item) => {
        if (item) {
            item.menuDropDownActive = false;
        }
        this.setState({showAddUser: true, items: this.state.items, selectedItem: item});
    }

    closeAddUserPopup = (reloadData) => {
        this.setState({showAddUser: false, selectedItem: null});

        if (reloadData) {
            EmitterService.emit("loadBillingOverview", null);
            this.loadBillingUsers();
        }
    }

    deleteUser = async () => {
        const toastId = this._showToaster("Deleting user");

        try {
            await deleteBillingUser(this.state.selectedItem.id);
            this.closeConfirmationPopup();
            EmitterService.emit("loadBillingOverview", null);
            this.loadBillingUsers();
            this._updateToaster(toastId, "User has been deleted successfully");

        } catch (error) {
            this.closeConfirmationPopup();

            toast.update(toastId, {
                className: "toast-error-div",
                render: "Unable to delete user",
                type: toast.TYPE.ERROR,
                autoClose: 3000
            });
        }
    }

    showConfirmationPopup = (item) => {
        item.menuDropDownActive = false;
        this.setState({items: this.state.items, showConfirmation: true, selectedItem: item});
    }

    closeConfirmationPopup = () => {
        this.setState({showConfirmation: false, selectedItem: null});
    }

    _showToaster(message) {
        return toast(message, {hideProgressBar: true, autoClose: 10000})
    }

    _updateToaster(toastId, message) {
        toast.update(toastId, {
            render: message,
            className: "toast-success-div",
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
            autoClose: 2000,
        });
    }

    showActionButton = () => {
        const {billingOverview} = this.props;
        return billingOverview && billingOverview.plan_name && billingOverview.plan_name.trim() !== "";
    }

    render() {
        return (
            <div className="billing-user">
                <div className="billing-user-search">
                    <img src="/assest/accountBased/search.svg" alt=""/>
                    <input type="text" placeholder="Search Users" onChange={this.onKeywordChange}
                           onKeyPress={this.onEnterPressed}/>

                    <p>Press <strong> Enter </strong> to search</p>
                </div>

                <div className="result-header__top">
                    <h2 className="result-header__title">USERS LIST</h2>

                    {this.showActionButton() &&
                    <div className="result-header__selected">
                        <div className="result-header__add" onClick={() => this.showAddUserPopup(null)}>Add new user
                        </div>
                    </div>
                    }
                </div>

                {this.state.showAddUser &&
                <AddUserPopup
                    user={this.state.selectedItem}
                    closePopup={this.closeAddUserPopup}
                />
                }

                <div className="billing-user-head">
                    {tableHeader.map((item, index) =>
                        <p className={`billing-user-head__name ${item.className}`} key={index}>
                            <span>{item.name}</span>
                        </p>
                    )}
                    <p className="add" style={{padding: '10px'}} ></p>
                </div>

                <div className="billing-user-table">
                    {this.state.billingUsersLoading && <Loader/>}

                    <UserDetailsContent
                        items={this.state.items}
                        billingUsersLoading={this.state.billingUsersLoading}
                        toggleMenuDropDown={this.toggleMenuDropDown}
                        showAddUserPopup={this.showAddUserPopup}
                        showConfirmationPopup={this.showConfirmationPopup}
                        showActionButton={this.showActionButton}
                    />

                    {this.state.showConfirmation &&
                    <ConfirmationPopup
                        title="Delete User"
                        message={`Are you sure you want to delete ${this.state.selectedItem.first_name} ${this.state.selectedItem.last_name} (${this.state.selectedItem.email}) from your account?`}
                        onYesClick={this.deleteUser}
                        onNoClick={this.closeConfirmationPopup}
                        onCloseClick={this.closeConfirmationPopup}/>
                    }

                </div>

                {// && this.state.totalUsers > this.state.recordsPerPage
                this.state.items && this.state.items.length > 0 &&
                <Pagination
                    totalLists={this.state.totalUsers}
                    recordsPerPage={this.state.recordsPerPage}
                    currentPageNum={this.state.currentPage}
                    updatePerPage={this.updatePerPage}
                    paginationPopup={this.paginationPopup}
                    paginate={this.paginate}
                    incOrDecNum={this.incOrDecNum}
                    showDropDown={this.state.showDropDown}
                    container={this.container}/>
                }
            </div>
        )
    }
}

export default UserDetails;