import React, { Component } from 'react'

class IntegrationsBanner extends Component{
  state = {
    active: '',
  }
  bannerHidden = () =>{
    this.setState({active: 'hidden'})
  }

  render(){
    const { active } = this.state
    return(
      <div className={`right-banner ${active}`}>
        <div className="right-banner__close" onClick={this.bannerHidden}></div>
        <div className="right-banner__content">
          <h4 className="right-banner__title">Integrations Demo</h4>
          <p className="right-banner__video">Watch Video</p>
        </div>
        <div className="right-banner__img">
          <img src="/assest/accountBased/igni.svg" alt="" />
        </div>
      </div>
    )
  }
}

export default IntegrationsBanner