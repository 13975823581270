import React, { Component } from 'react'
import '../style.scss'
import Calendar from 'react-calendar';
import items from './items'
var moment = require('moment');

class InsideListFilter extends Component {
  insideCalendar;
  calendarInput;

  state = {
    showCalendar: false,
    formattedDate: '',
    selectedDate: '',
  };

  componentDidMount() {
    this.insideCalendar = React.createRef();
    this.calendarInput = React.createRef();
  }

  clickOutsideCalendar = (e) => {
    if (this.insideCalendar.current && !this.insideCalendar.current.contains(e.target)) {
      this.closeCalendar();
    }
  }

  closeCalendar = () => {
    setTimeout(() => {
      this.setState({ showCalendar: false });
      //this.calendarInput.current.blur();
      document.body.removeEventListener('click', this.clickOutsideCalendar);
    }, 300);
  }

  componentWillUnmount = () => {
  }

  openCalendar = () => {
    this.setState({ showCalendar: true });
    document.body.addEventListener('click', this.clickOutsideCalendar);
  }

  formatDate(date) {
    return moment(date).format("MMMM D, YYYY");
  }

  onDateChange = (selectedDate) => {
    const fromDateFormat = moment(selectedDate[0]).format("MM-DD-YYYY");
    const toDateFormat = moment(selectedDate[1]).format("MM-DD-YYYY");
    this.setState({ formattedDate: `${fromDateFormat} - ${toDateFormat}`, selectedDate });
    this.closeCalendar();
    this.props.setInsideListFromTo(moment(selectedDate[0]).format("YYYY-MM-DD"), moment(selectedDate[1]).format("YYYY-MM-DD"));
  }

  render() {
    return (
      <div className="inside-filter">
        <p className="inside-filter__title">Filters</p>
        <div className="inside-filter__box">

          {!this.props.hotlead && <><div className="right-filters__check" onClick={() => this.props.toggleHasEmail()}>
            <div className={`check ${this.props.filters.hasEmail ? 'active' : ''}`}></div>
            <p>Has Email</p>
          </div>
            {this.props.filters.hasEmail &&
              <div className="right-filters__checks actCheck">
                <div className="label">Filter By Email Status</div>
                <div className="right-filters__check" onClick={() => this.props.togglePersonalEmail()}>
                  <div className={`check ${this.props.filters.personalEmail ? 'active' : ''}`}></div>
                  <p>Personal Email</p>
                </div>
                <div className="right-filters__check" onClick={() => this.props.toggleCompanyEmail()}>
                  <div className={`check ${this.props.filters.companyEmail ? 'active' : ''}`}></div>
                  <p>Company Email</p>
                </div>
                <div className="label">Filter By Email Type</div>
                <div className="right-filters__check" onClick={() => this.props.toggleSingleVerifiedEmail()}>
                  <div className={`check ${this.props.filters.singleVerifiedEmail ? 'active' : ''}`}></div>
                  <p>Single Verified Email</p>
                </div>
                <div className="right-filters__check" onClick={() => this.props.toggleDoubleVerifiedEmail()}>
                  <div className={`check ${this.props.filters.doubleVerifiedEmail ? 'active' : ''}`}></div>
                  <p>Double Verified Email</p>
                </div>
              </div>
            }

            {/* // items.map((item, index)=> */}
            <div className="right-filters__check" onClick={() => this.props.toggleAddedByFuzebot()}>
              <div className={`check ${this.props.filters.addedByFuzebot ? 'active' : ''}`}></div>
              <p>Added by Fuzebot</p>
            </div>
            <div className="right-filters__check" onClick={() => this.props.toggleHasLinkedIn()}>
              <div className={`check ${this.props.filters.hasLinkedIn ? 'active' : ''}`}></div>
              <p>Has LinkedIn</p>
            </div>
            <div className="right-filters__check" onClick={() => this.props.toggleHasPhone()}>
              <div className={`check ${this.props.filters.hasPhone ? 'active' : ''}`}></div>
              <p>Has Phone</p>
            </div>
            <div className="right-filters__check" onClick={() => this.props.toggleHasMailingAddress()}>
              <div className={`check ${this.props.filters.hasMailingAddress ? 'active' : ''}`}></div>
              <p>Has Mailing Address</p>
            </div></>}
          <p className="inside-filter__calendar_heading">Date Range</p>
          <label className="inside-filter__calendar"
            ref={this.insideCalendar}>
            <input
              ref={this.calendarInput}
              type="text"
              id="calendarClose"
              value={this.state.formattedDate}
              placeholder="MM-DD-YYYY - MM-DD-YYYY"
              className="date-field"
              onClick={() => {
                // this.openCalendar();
              }
              }
              onFocus={(e) => {
                e.target.blur();
                this.openCalendar();
              }}
            />
            <img src="/assest/cal.svg" alt="" />
            {this.state.showCalendar &&
              <div className="cal-body">
                {/* <div className="side-c">
                      <div class="date-range">
                          <div class="days-range">
                              <ul class="list">
                                  <li><h3>DATE RANGE</h3></li>
                                  <li>Last 3 days</li>
                                  <li>Last Week</li>
                                  <li>Last Month</li>
                                  <li>Custom Range</li>
                              </ul>
                              <div class="dates">
                                  <div class="input-field">
                                      <label>From</label>
                                      <input type="text" name="" placeholder="01-03-2019" />
                                  </div>
                                  <div class="input-field">
                                      <label>To</label>
                                      <input type="text" name="" placeholder="01-26-2019" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div> */}
                <Calendar
                  onChange={this.onDateChange}
                  selectRange={true}
                  showNeighboringMonth={true}
                  value={this.state.selectedDate}
                />
              </div>
            }
          </label>
          {/* // ) */}

        </div>
        <div className="inside-filter__btn" onClick={() => {
          this.setState({ selectedDate: '', formattedDate: '' });
          this.props.clearAllFilters()
        }
        }>Clear all</div>
      </div>
    )
  }
}
export default InsideListFilter