import React from 'react'
import '../style.scss'

const FindLeads3left = (props) =>{
  const { prevTab, solution, getValue, step3 } = props
  const nextTab = () =>{
    if(solution){
      step3()
    }else{
      alert('not fields')
    }
  }

     
  return(
    <div className="find-leads">
      <img className="find-leads__logo" src="/assest/accountBased/logo.svg" alt=""/>
      <h2 className="find-leads__title">What solution do you provide?</h2>
      <div className="find-leads__box">
        <label className="find-leads__label">
          <p>Solution</p>
          <input  type="text" 
                  name="solution"
                  value={solution}
                  placeholder="Describe your offering here in 140 characters or less" 
                  onChange={getValue}/>
        </label>
      </div>
      <p className="find-leads__text">This way we can share some lead gen ideas with you.</p>
      <div className="find-leads__buttons">
        <div className="find-leads__btn" onClick={nextTab}>Next Step</div>
        <div className="find-leads__link" onClick={()=>prevTab(2)}>Previous Step</div>
      </div>
    </div>
  )
}

export default FindLeads3left