import { GET_API_KEYS, API_KEY_PAGINATION } from "../constants/constant";


const initialState = { apiKeysLists: [], recordsPerPage: 10, pageNum: 1 }

export default function getApiKeysLists(state = initialState, action) {

    switch (action.type) {
        case GET_API_KEYS:
            return {
                ...state,
                apiKeysLists: action.payload || state.apiKeysLists,
                isLoading: !action.payload
            }
        case API_KEY_PAGINATION:
            return {
                ...state,
                pageNum: action.payload
            }
        default:
            return state
    }
}
