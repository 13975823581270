import { INSIDE_LIST_ACTIONS } from "../constants/constant";

export function setInsideListAddedByFuzebote(status) {
  return {
    type: INSIDE_LIST_ACTIONS.ADDED_BY_FUZEBOT,
    payload: status
  };
}

export function setInsideListHasEmail(status) {
  return {
    type: INSIDE_LIST_ACTIONS.HAS_EMAIL,
    payload: status
  };
}

export function setInsideListPersonalEmail(status) {
  return {
    type: INSIDE_LIST_ACTIONS.PERSONAL_EMAIL,
    payload: status
  };
}

export function setInsideListCompanyEmail(status) {
  return {
    type: INSIDE_LIST_ACTIONS.COMPANY_EMAIL,
    payload: status
  };
}

export function setInsideListSingleVerifiedEmail(status) {
  return {
    type: INSIDE_LIST_ACTIONS.SINGLE_VERIFIED_EMAIL,
    payload: status
  };
}

export function setInsideListDoubleVerifiedEmail(status) {
  return {
    type: INSIDE_LIST_ACTIONS.DOUBLE_VERIFIED_EMAIL,
    payload: status
  };
}

export function setInsideListHasLinkedIn(status) {
  return {
    type: INSIDE_LIST_ACTIONS.HAS_LINKED_IN,
    payload: status
  };
}

export function setInsideListHasPhone(status) {
  return {
    type: INSIDE_LIST_ACTIONS.HAS_PHONE,
    payload: status
  };
}

export function setInsideListFromTo(from, to) {
  return {
    type: INSIDE_LIST_ACTIONS.FROM_TO,
    payload: {
      from,
      to
    }
  };
}

export function setInsideListHasMailingAddress(status) {
  return {
    type: INSIDE_LIST_ACTIONS.HAS_MAILING_EMAIL,
    payload: status
  };
}

export function setInsidasdeListHasMailingAddress(status) {
  return {
    type: INSIDE_LIST_ACTIONS.HAS_MAILING_EMAIL,
    payload: status
  };
}

export function clearAllInsideFilters() {
  return {
    type: INSIDE_LIST_ACTIONS.CLEAR_ALL
  };
}
