const items = [
  {
    name: 'User & Email',
    cssClass: ''
  },
  {
    name: 'Account status',
    cssClass: ''
  },
  {
    name: 'Available Leads',
    cssClass: ''
  },
  {
    name: 'Sign up date',
    cssClass: 'users_created_date'
  },
  {
    name: 'Last login date',
    cssClass: 'users_last_login'
  }
]

export default items