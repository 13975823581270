import React, {Component} from "react";
import { connect } from 'react-redux';
import { numberFormatter } from '../../constants/helper';
import "./style.scss";

function mapStateToProps(state) {
	return {
	  searchResultFilters: state.searchResultFilters,
	};
  } 

class Pagination extends Component {

	paginate(skipPagination, indexOfFirstPage) {
		if (skipPagination) {
			return;
		}
		this.props.paginate(indexOfFirstPage);
	}

	render() {
		const { max_results_per_company } = this.props.searchResultFilters;

		const {
			totalLists,
			paginate,
			recordsPerPage,
			noPageNumbers,
			incOrDecNum,
			updatePerPage,
			paginationPopup,
			currentPageNum,
			showDropDown,
			restrictJumping,
			container
		} = this.props;

		const item = [];
		const withoutJump = [];
		let pageNumber = currentPageNum;
		const indexOfLastPage = Math.ceil(totalLists / recordsPerPage);
		const indexOfFirstPage = 1;

		for (let i = 1; i <= Math.ceil(totalLists / recordsPerPage); i++) {
			if(i >= currentPageNum - 2 && i <= currentPageNum + 2)
			item.push(i);
		}

		if (pageNumber === 1) {
			for (let i = 1; i <= Math.ceil(totalLists / recordsPerPage); i++) {
				if (i <= 2) {
					withoutJump.push(i);
				} else {
					break;
				}
			}
		} else {
			if (currentPageNum <= indexOfLastPage) {
				let prevPage = currentPageNum - 1;
				let nextPage = currentPageNum + 1;
				if (nextPage > indexOfLastPage) {
					withoutJump.push(prevPage, currentPageNum);
				} else {
					withoutJump.push(prevPage, currentPageNum, nextPage);
				}
			}
		}

		let pageNumbersList = null;
		if (!noPageNumbers) {
			pageNumbersList = (
				<ul className="pagination__list">
					{restrictJumping &&
						withoutJump.map(num => (
							<li
								key={`pag-${num}`}
								className={currentPageNum === num ? "active" : ""}>
								<p onClick={() => paginate(num)}>{num}</p>
							</li>
						))}
					{!restrictJumping &&
						item.map(num => (
							<li
								key={`pag-${num}`}
								className={currentPageNum === num ? "active" : ""}>
								<p onClick={() => paginate(num)}>{num}</p>
							</li>
						))}
				</ul>
			);
		}

		return (
			<div className="pagination" style={{marginBottom: this.props.paginationMarginBottom}}>
				{!restrictJumping &&
					<div className="pagination__arr prev" onClick={() => this.paginate(currentPageNum === 1, indexOfFirstPage)}>
						<img
							src="/assest/pagination/arr--.svg"
							alt=""
						/>
					</div>
				}
				<div className="pagination__arr prev" onClick={() => incOrDecNum("-")}>
					<img
						src="/assest/pagination/arr.svg"
						alt=""
					/>
				</div>
				{pageNumbersList}
				<div className="pagination__arr next" onClick={() => incOrDecNum("+", indexOfLastPage)}>
					<img
						src="/assest/pagination/arr.svg"
						alt=""
					/>
				</div>
				{!restrictJumping && (
					<div className="pagination__arr next" onClick={() => this.paginate(indexOfLastPage === currentPageNum, indexOfLastPage)}>
						<img
							src="/assest/pagination/arr--.svg"
							alt=""
						/>
					</div>
				)}
				<div className="pagination-box" ref={container}>
					<span onClick={paginationPopup}>{recordsPerPage}</span>
					{showDropDown && (
						<div className="pagination-items">
							<div className="dropdown-in pagination-block">
								<div className="dropdown-in__groups">
									<div className="dropdown-in-group" onClick={() => updatePerPage("", 25)}>
										<p className="dropdown-in-group__name pagination-item">25</p>
									</div>
									<div className="dropdown-in-group" onClick={() => updatePerPage("", 50)}>
										<p className="dropdown-in-group__name pagination-item">50</p>
									</div>
									<div className="dropdown-in-group" onClick={() => updatePerPage("", 75)}>
										<p className="dropdown-in-group__name pagination-item">75</p>
									</div>
									<div className="dropdown-in-group" onClick={() => updatePerPage("", 100)}>
										<p className="dropdown-in-group__name pagination-item">100</p>
									</div>
								</div>
							</div>
						</div>
					)}
					<div className="pagination-items__arr">
						<div
						 	className={`pagination-items__link next ${recordsPerPage == 100 ? 'disabled' : ''}`}
							onClick={() =>
								updatePerPage("+", recordsPerPage)
							}></div>
						<div
							className={`pagination-items__link prev ${recordsPerPage == 25 ? 'disabled' : ''}`}
							onClick={() =>
								updatePerPage("-", recordsPerPage)
							}></div>
					</div>
				</div>
				<div className="pagination__count">
					<p>
						{(currentPageNum - 1) * recordsPerPage + 1} - {currentPageNum !== indexOfLastPage ? currentPageNum * recordsPerPage : totalLists} of {window.location.href.includes('/lead-search/market-based') && max_results_per_company && max_results_per_company != 'No Limit' && '~ '}{numberFormatter.format(totalLists)}
					</p>
				</div>
			</div>
		)
	}
}

export default connect(mapStateToProps)(Pagination);
