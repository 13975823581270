import React from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import AuxContainer from "../../../../../containers/AuxContainer/aux-container";

const EditGroups = props => {
  const {
    finishRoleGroupsSelected,
    openEditGroupsTags,
    delGroupSelect,
    delTagInSelect,
    excludeTag,
    dellExcludeTag,
    includeTag,
    haveTags,
    name,
    basicHeading1
  } = props;
  let allIncludeAndExcludeTagHeading = null;
  if (basicHeading1) {
    allIncludeAndExcludeTagHeading = (
      <div className="field__include">{basicHeading1}</div>
    );
  }
  /* if ((haveTags && name !== "employee") || name === "keywords") {
    allIncludeAndExcludeTagHeading = (
      <div className="field__include">All Include & Exclude Tags</div>
    );
  }
  const hiringHeading = name === "hiring" ? (<div className="field__include">All Include Tags</div>) : null; */

  let technologies = null;
  if (name === "technologies") {
    const filteredTechnologies = finishRoleGroupsSelected.filter(
      item => item.isTechName
    );
    const filteredTechnologyGroups = finishRoleGroupsSelected.filter(
      item => !item.isTechName
    );
    const justTechnologies = filteredTechnologies.map((item, index) => (
      <div>
        <p
          className={`${
            item.excludeGroup ? "field__exclude_tag exclude_tag" : "field__exclude_ex"}
            tech-base ${item.label.toLowerCase()}
          `}
          key={"tech" + index}
        >
          {item.label}
          <img
            src={`${
              item.excludeGroup
                ? "/assest/apiKeys/Close_red.svg"
                : "/assest/accountBased/del-tag.svg"
            }`}
            alt=""
            onClick={e => delGroupSelect(e, item.category, item.excluded)}
          />
        </p>
      </div>
    ));
    const technologyGroups = filteredTechnologyGroups.map((item, index) => (
      <div
          className={`field-role ${item.open ? "active" : ""} ${item.excludeGroup? 'tech-group-excluded' : ''}`}
          key={index}
        >
          <p
            className="field-role__group"
            onClick={() => openEditGroupsTags(index)}
          >
          Group: {item.label}
          {/* <img
            src={`${
              item.excludeGroup
                ? "/assest/accountBased/exclude-del.svg"
                : "/assest/accountBased/del-tag.svg"
            }`}
            alt=""
            onClick={e => delGroupSelect(e, item.category, item.excluded)}
          /> */}
        </p>
          <div className="field-role__tags">
            {item.technologies.map((tag, num) => (
              <div>
                <p className="tags-group__name" key={tag + num}>
                  {tag}
                </p>
              </div>
            ))}
            <div className="field-role__delgroup">
              <p onClick={e => delGroupSelect(e, item.category)}>
                delete group
              </p>
            </div>
          </div>
      </div>
    ));
    let justTechnologiesDiv = (<div className="field__exclude">{justTechnologies}</div>); 
    let technologyGroupsHeading = (<div className="field__include">All Technology Groups</div>); 
    let technologyGroupsDiv = (<div className="field__exclude">{technologyGroups}</div>); 
    if(!justTechnologies.length) {
      allIncludeAndExcludeTagHeading = null;
      justTechnologiesDiv = null;
    }
    if(!technologyGroups.length) {
      technologyGroupsHeading = null;
      technologyGroupsDiv = null;
    }
    technologies = (
      <AuxContainer>
        {justTechnologiesDiv}
        {/* {technologyGroupsHeading} */}
        {technologyGroups}
      </AuxContainer>
    );
  }

  let groupsWithTags = null;
  if(haveTags) {
    groupsWithTags = finishRoleGroupsSelected.map((item, index) => {
      return (
        <div
          className={`field-role ${item.open ? "active" : ""}`}
          key={index}
        >
          <p
            className="field-role__group"
            onClick={() => openEditGroupsTags(index + 1)}
          >
            Group: {item.label}
          </p>
          <div className="field-role__tags">
            {item.tags.map((tag, num) => (
              <div>
                <p className="tags-group__name" key={tag + num}>
                  {tag}
                  <img
                    src="/assest/accountBased/del-tag.svg"
                    alt=""
                    onClick={e => delTagInSelect(e, tag, item.category)}
                  />
                </p>
              </div>
            ))}
            {item.excludeTags.map((tag, num) => (
              <div>
                <p className="field__exclude_tag exclude_tag" key={"ex-" + num}>
                  {tag}
                  <img 
                    src="/assest/apiKeys/Close_red.svg"
                    alt=""
                    onClick={e => delTagInSelect(e, tag, item.category)}
                  />
                </p>
              </div>
            ))}
            <div className="field-role__delgroup">
              <p onClick={e => delGroupSelect(e, item.category)}>
                delete group
              </p>
            </div>
          </div>
        </div>
      );
            });
  }

  let customIncludeExcludeTags = null;
  if ((includeTag.length || excludeTag.length) && ((haveTags && name !== "employee") ||
    name === "keywords" ||
    name === "skills" ||
    name === "hiring")) {
      customIncludeExcludeTags = 
  ((includeTag.length || excludeTag.length) &&
    ((haveTags && name !== "employee") ||
      name === "keywords" ||
      name === "skills" ||
      name === "hiring") && (
      <div className="field__exclude">
        {includeTag.map((item, index) => (
          <CSSTransitionGroup
            transitionName="example"
            transitionEnter={false}
          >
            <p className="field__exclude_ex" key={"keywords" + index}>
              {item}
              <img
                src="/assest/accountBased/del-tag.svg"
                alt=""
                onClick={e => dellExcludeTag(item, e)}
              />
            </p>
          </CSSTransitionGroup>
        ))}
        {excludeTag.map((item, index) => (
          <div>
            <p className="field__exclude_tag exclude_tag" key={index}>
              {item}
              <img
                src="/assest/apiKeys/Close_red.svg"
                alt=""
                onClick={e => dellExcludeTag(item, e, "exclude")}
              />
            </p>
          </div>
        ))}
        {/* excludeTag.length === 0 && includeTag.length === 0 ? (
          <p className="field__exclude_notag">No tag selected</p>
        ) : (
          ""
        ) */}
      </div>
    ));
    }

    let groupsWithoutTagsHeading = '';
    if (name === 'hiring' && finishRoleGroupsSelected.length) {
      groupsWithoutTagsHeading = <div className="field__include">Hiring Groups</div>;
    }
    if ((name === 'hiring' || name === 'role') && !includeTag.length && !excludeTag.length) {
      allIncludeAndExcludeTagHeading = null;
    }

    let groupsWithoutTags = null;
    if(!haveTags && name !== "keywords" && name !== "technologies" &&  name !== "skills" && finishRoleGroupsSelected.length) {
      groupsWithoutTags = (
        <div className="field__exclude">
          {finishRoleGroupsSelected.map((item, index) => (
            <div>
              <p className="field__exclude_ex" key={"selected-groups-" + index}>
                {name === "hiring" ? `Group:  ${item.label}` : item.label}
                <img
                  src="/assest/accountBased/del-tag.svg"
                  alt=""
                  onClick={e => delGroupSelect(e, item.category)}
                />
              </p>
            </div>
          ))}
        </div>
      )
    }

  return (
    <div className="dropdown-in animation-slideDown scroll-dropdown">
      {allIncludeAndExcludeTagHeading}
      {/* {hiringHeading} */}
      {/* {!haveTags && name !== "hiring" && name !== "keywords" && (
        <div className="field__include">All Selected Tags</div>
      )} */}
      {customIncludeExcludeTags}
      {groupsWithoutTagsHeading}
      {groupsWithTags}
      {groupsWithoutTags}
      {technologies}
    </div>
  );
};

export default EditGroups;
