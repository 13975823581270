import { Tooltip } from '@material-ui/core'
import React, { Component, Fragment } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import RevokePopup from './RevokePopup'

var moment = require('moment');
class ApiKeysContent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            popupRevoke: false,
            listId: "",
            revokedAPi: "",
            copiedIcon: false,
            keyForIcon: '',
            date: new Date()
        }
    }

    openRevokePopup = (id, revokedID) => {
        this.setState({ listId: id, revokedAPi: revokedID })
        this.setState({ popupRevoke: !this.state.popupRevoke })

    }

    ImageIcon = (key) => {
        this.setState({ copiedIcon: true, keyForIcon: key })
    }

    render() {
        const { items, ApiKeys } = this.props
        const { popupRevoke, listId, copiedIcon, keyForIcon, revokedAPi, date } = this.state
        return (
            <Fragment>
                {items &&
                    items.map((item, index) =>
                        <div className="coupon-list coupon-header" key={index}>
                            <div style={{ display: 'none' }}>
                                {item.id === revokedAPi ? item.revoke_date = moment(date).format("MM-DD-YYYY") : ""}
                            </div>
                            <div className="coupon-list__row">
                                <div className="coupon-list__items name">
                                    {/* TODO: make sure that name of the field is correct and case is also correct */}
                                    <p className="coupon-header__col ">{item.name}</p>
                                </div>
                                <div className="coupon-list__items key">
                                    <div className="coupon-header__col key-inner"><p>{item.Key}</p></div>
                                    <CopyToClipboard text={item.Key} onClick={() => this.ImageIcon('')}>
                                        <Tooltip
                                            placement="top"
                                            open={keyForIcon === item.Key}
                                            arrow title="copied!"
                                            enterTouchDelay={0}
                                            leaveTouchDelay={0}
                                            enterDelay={0}
                                        >
                                            <span
                                                className="icon-bg"
                                                onMouseDown={() => this.ImageIcon(item.Key)}
                                                onMouseUp={() => this.ImageIcon('')}
                                                onMouseOut={() => this.ImageIcon('')}
                                            >
                                                <img
                                                    src={
                                                        (copiedIcon && keyForIcon === item.Key)
                                                            ? "/assest/apiKeys/copiedIcon.svg"
                                                            : "/assest/apiKeys/Copy.svg"
                                                    }
                                                    alt=""
                                                />
                                            </span>
                                        </Tooltip>
                                    </CopyToClipboard>
                                </div>
                                <div className="coupon-list__items status">
                                    {item.revoke_date !== null &&
                                        <p className="coupon__deactive ">Revoked</p>
                                    }
                                    {item.revoke_date === null &&
                                        <p className="coupon__status ">Active</p>

                                    }
                                </div>
                                <div className="coupon-list__items date apiKey_created_date">
                                    <p className="coupon-header__col ">{moment(item.creation_date).format("MM-DD-YYYY")}</p>
                                </div>
                                {item.revoke_date !== null &&
                                    <div className="coupon-list__items date apiKey_revoked_date">
                                        <p className="coupon-header__col ">{moment(item.revoke_date).format("MM-DD-YYYY")}</p>
                                    </div>
                                }
                                {item.revoke_date === null &&
                                    <div className="coupon-list__items date apiKey_revoked_date">
                                        <p className="coupon-header__col ">N/A</p>
                                    </div>
                                }

                                <div className="coupon-list__items del apiKey_revoke">
                                    {item.revoke_date === null &&
                                        <div className="coupon-list__close" onClick={() => this.openRevokePopup(item.id, '')}>
                                            <img src="/assest/apiKeys/Deactivate.svg" alt="" />
                                        </div>
                                    }
                                </div>

                            </div>

                        </div>

                    )

                }
                <RevokePopup popupRevoke={popupRevoke} openRevokePopup={this.openRevokePopup} listId={listId} ApiKeys={ApiKeys} />
            </Fragment>
        )
    }
}
export default ApiKeysContent
