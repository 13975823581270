import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import Columns from '../ExportToGoogle/columns'
import '../style.scss';
import {exportListCSV} from '../../../../services/leads';
import {columnsData} from './columnsDataCSV';
import {toast} from 'react-toastify';
import {LOCAL_STORAGE_KEYS} from '../../../../constants/constant';
import {showErrorToast, showSuccessToast} from '../../../../helpers/toast';

const queryString = require('query-string');


class ExportToCSV extends Component {
    state = {
        columns: columnsData,
        selectedColumns: ["first_name", "last_name", "job_role", "company_email", "company_email_status", "personal_email", "interests", "birthday", "location", "college", "facebook", "linkedin", "company_name", "company_website", "company_industry", "company_adwords_budget", "company_employees", "phone", "company_linkedin", "company_facebook", "company_mailing_address","mobile_number","company_phone_number","other_phone_numbers"],
        email: "",
        newColumns: columnsData,
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        this.setState({email: user.email, isSuperAdmin: user.is_lead_fuze_admin})
    }

    searchColumnFilter = (e) => {
        const {newColumns} = this.state
        const val = e.target.value;
        const columnsFilter = newColumns.filter(item => {
            return item.name.toLowerCase().includes(val.toLowerCase())
        })
        this.setState({columns: columnsFilter})
    }

    changeCheckForColumn = (i, value) => {
        const {selectedColumns, columns} = this.state
        columns[i].check = !columns[i].check;
        this.setState({columns: columns})
        let newCol
        if (columns[i].check) {
            this.setState({selectedColumns: selectedColumns.concat(value)})
        } else {
            newCol = selectedColumns.filter((el) => el !== value)
            this.setState({selectedColumns: newCol})

        }

    }
    changeValue = (e) => {
        const newVal = e.target.value
        this.setState({email: newVal})
    }

    selectAll() {
        const columns = this.state.columns.map(col => {
            return {
                ...col,
                check: true,
            }
        });
        const newColumns = [...columns];
        this.setState({selectedColumns: columns.map(item => item.value), columns, newColumns})
    }

    clearAll() {
        const columns = this.state.columns.map(col => {
            return {
                ...col,
                check: false,
            }
        });
        const newColumns = [...columns];
        this.setState({selectedColumns: [], columns, newColumns})
    }

    openListName = () => {
        this.setState({active: !this.state.active});
    }
    exportCsv = async (email, id, selectedColumns) => {
        const {openCsvPopup, onExportFromPopup} = this.props
        if (onExportFromPopup) {
            onExportFromPopup(email, selectedColumns);
            return;
        }
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        let toastId = null;
        let errormsg
        toastId = toast('Exporting csv', {
            autoClose: 10000,
        });

        try {
            const queryParams = queryString.parse(window.location.search);
            let list_id = queryParams.list_id;
            let search_query = queryParams.search;
            let query_filters = {
                ...this.props.insideListFilters
            };
            await exportListCSV(list_id, search_query, query_filters, userId.id, email, id, selectedColumns)
            openCsvPopup()
            showSuccessToast(toastId, `Export started. Check your email in a few minutes.`);
        } catch (error) {
            if (!error.response) {
                errormsg = 'Unable to export list'
            } else if (error.response.data.errCode === 30120) {
                errormsg = 'Permission denied'
            } else {
                errormsg = 'Unable to export list'
            }
            showErrorToast(toastId, errormsg);
            openCsvPopup()
        }
    }
    onClosePopup = () => {
        const {openCsvPopup} = this.props
        openCsvPopup()
        this.setState({email: ""})
    }
    render() {
        const {columns, email, selectedColumns, isSuperAdmin} = this.state
        const {popupCSV, id} = this.props;
        const primaryLogin = JSON.parse(localStorage.getItem('primaryLogin')) == null ? false : JSON.parse(localStorage.getItem('primaryLogin'));
        const isSAdmin = isSuperAdmin == null ? false : isSuperAdmin
        return (
            <Fragment>
                <div className={`add-list ${popupCSV ? 'open' : ''}`}>
                    <div className="add-list__content">
                        <img className="add-list__close" src="/assest/accountBased/close.svg" alt=""
                             onClick={this.onClosePopup}/>
                        <p className="add-list__title">Export To CSV</p>
                        <p className="add-list__label">Email</p>
                        <div className="add-list__search-box">
                            <input className="add-list__search" placeholder="Enter Email" type="text" value={email}
                                     disabled={!primaryLogin && !isSAdmin} onInput={this.changeValue}/>
                        </div>
                        <p className="add-list__label field-margin mt-2" style={{'margin-top': '10px'}}>Columns</p>
                        <div className="coupon-search__inp sm_coupon-search__inp" style={{'marginBottom': '15px'}}>
                            <img src="/assest/accountBased/search.svg" alt=""/>
                            <input type="text" placeholder="Search Columns" onInput={this.searchColumnFilter}/>
                        </div>
                        <div className="scroll_view">
                            <Columns columns={columns} changeCheckForColumn={this.changeCheckForColumn}/>
                        </div>
                        <p className="add-list__title add-list__clear field-margin">
                            <span onClick={() => this.selectAll()}>Select All</span>
                            <span onClick={() => this.clearAll()}>Clear All</span>
                        </p>
                        <p className="add-list__create">
                        </p>
                        <div className="add-list__buttons">
                            <div className="add-list__btn orange" onClick={() => {
                                this.exportCsv(email, id, selectedColumns)
                            }}>Export
                            </div>
                            <div className="add-list__btn orange-light" onClick={this.onClosePopup}>Cancel</div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        insideListFilters: state.insideListFilters,
    };
}


export default connect(mapStateToProps, null)(ExportToCSV);

