import axios from '../axios'

const ROOT_URL = process.env.REACT_APP_ROOT_URL

export function getUsersIntegrations(userID) {
    return axios({
        method: 'get',
        url: `/core/integrations`,
    });
}

export function authIntegrations(userID, name, auto_sync, require_email, require_company_emails, require_personal_emails, require_double_verified) {
    return axios({
        method: 'post',
        url: `/core/${name}/auth`,
        data: {
            "name": name,
            "auto_sync": auto_sync,
            "require_email": require_email,
            "include_company_emails": require_company_emails,
            "include_personal_emails": require_personal_emails,
            "include_double_verified_emails": require_double_verified

        }
    });
}

export function updateIntegrations(userID, token, integId, name, auto_sync, require_email, require_personal_emails, require_company_emails, require_double_verified) {
    let data = {}
    data[name] = {"token": token}
    data["name"] = name
    data["auto_sync"] = auto_sync
    data["require_email"] = require_email
    data["include_company_emails"] = require_company_emails
    data["include_personal_emails"] = require_personal_emails
    data["include_double_verified_emails"] = require_double_verified
    return axios({
        method: 'patch',
        url: `/core/integrations/${integId}`,
        data: data
    });
}

export function createIntegrations(userID, token, name, auto_sync, require_email, require_personal_emails, require_company_emails, require_double_verified, accID) {
    let data = {}
    if (name === "close-io") {
        data["close_io"] = {"token": token}
    } else {
        data[name] = {"token": token}
    }
    data["name"] = name
    data["auto_sync"] = auto_sync
    data["require_email"] = require_email
    data["include_company_emails"] = require_company_emails
    data["include_personal_emails"] = require_personal_emails
    data["include_double_verified_emails"] = require_double_verified
    return axios({

        method: 'post',
        headers: {
            'userID': userID,
            "accountID": accID
        },
        url: `/core/integrations`,
        data: data
    });
}

export function createDuxSoupIntegration(userID, duxsoupId, apiKey, name, auto_sync, require_email, require_company_emails,
                                         require_personal_emails, require_double_verified, accID) {
    let data = {}
    data[name] = {"user_id": duxsoupId, "api_key": apiKey}
    data["name"] = name
    data["auto_sync"] = auto_sync
    data["require_email"] = require_email
    data["include_company_emails"] = require_company_emails
    data["include_personal_emails"] = require_personal_emails
    data["include_double_verified_emails"] = require_double_verified
    return axios({
        method: 'post',
        headers: {
            'userID': userID,
            "accountID": accID
        },
        url: `/core/integrations`,
        data: data
    });
}

export function updateDuxSoupIntegrations(userID, duxsoupId, apiKey, integId, name, auto_sync, require_email, require_company_emails, require_personal_emails, require_double_verified) {
    let data = {}
    data[name] = {"user_id": duxsoupId, "api_key": apiKey}
    data["name"] = name
    data["auto_sync"] = auto_sync
    data["require_email"] = require_email
    data["include_company_emails"] = require_company_emails
    data["include_personal_emails"] = require_personal_emails
    data["include_double_verified_emails"] = require_double_verified
    return axios({
        method: 'patch',
        url: `/core/integrations/${integId}`,
        data: data
    });
}

export function createFreshsalesIntegration(userID, crmDomain, apiKey, user, name, auto_sync, require_email, require_company_emails, require_personal_emails, require_double_verified, accID) {
    let data = {}
    data[name] = {"user_id": user, "domain": crmDomain, "api_key": apiKey}
    data["name"] = name
    data["auto_sync"] = auto_sync
    data["require_email"] = require_email
    data["include_company_emails"] = require_company_emails
    data["include_personal_emails"] = require_personal_emails
    data["include_double_verified_emails"] = require_double_verified
    return axios({
        method: 'post',
        headers: {
            'userID': userID,
            "accountID": accID
        },
        url: `/core/integrations`,
        data: data
    });
}

export function updateFreshsalesIntegration(userID, crmDomain, apiKey, integId, user, name, auto_sync, require_email, require_company_emails, require_personal_emails, require_double_verified) {
    let data = {}
    data[name] = {"user_id": user, "domain": crmDomain, "api_key": apiKey}
    data["name"] = name
    data["auto_sync"] = auto_sync
    data["require_email"] = require_email
    data["include_company_emails"] = require_company_emails
    data["include_personal_emails"] = require_personal_emails
    data["include_double_verified_emails"] = require_double_verified
    return axios({
        method: 'patch',
        url: `/core/integrations/${integId}`,
        data: data
    });
}

export function freshSalesGetUsers(crmDomain, apiKey) {
    return axios({
        method: 'get',
        url: `/core/freshsales/users`,
        params: {
            "domain": crmDomain,
            "apiKey": apiKey
        }
    });
}

export function deleteIntegrations(userID, integId) {
    return axios({
        method: 'delete',
        url: `/core/integrations/${integId}`,
    });
}

