import React, { Component } from 'react'
import { Link } from 'react-router-dom'

const items = [
  {
    name: 'People Lists',
    slug: '/people-lists',
    active: 'acc-3-hover.svg',
    icon: 'acc-3.svg',
  },
]

class LeadList extends Component {
  state = {
    path: window.location.pathname
  }
  componentDidMount = () => {
    let pathArray = window.location.pathname.split('/');
    let secondLevelLocation = pathArray[1];
    let thirdLevelLocation = pathArray[2];
    items.map(item => {
      if (item.slug === "/" + secondLevelLocation + "/" + thirdLevelLocation) {
        this.setState({ path: item.slug })
      }
    })
  }
  changePath = (slug) => {
    this.setState({ path: slug })
  }
  render() {
    const { path } = this.state
    const { current } = this.props
    return (
      <ul className={`leads-search ${current ? "animation-slideDown" : ""}`}>
        {
          items.map((item, index) =>
            <Link to={item.slug} key={index}>
              <li className={`leads-search__item ${path === item.slug ? 'active' : ''}`} onClick={() => this.changePath(item.slug)}>
                <img src={`/assest/accountBased/${path === item.slug ? item.active : item.icon}`} alt="" />
                {item.name}
              </li>
            </Link>
          )
        }
      </ul>
    )
  }
}

export default LeadList