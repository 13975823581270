import React, { Component, Fragment } from 'react';
import '../style.scss';
import { editListName } from '../../../../services/leads';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { isNotEmpty } from '../../../../helpers/validate';
import { showSuccessToast, showErrorToast } from '../../../../helpers/toast';
import { DEFAULT_TOAST_DURATION } from '../../../../constants/constant';
class EditListName extends Component {
    state = {
        value: "",
    }
    componentDidMount() {
        this.setState({value: this.props.existingName});
    }
    handleChange = (e) => {
        this.setState({ value: e.target.value, errorMessage: '' })
    }
    onUpdateClick = async (val) => {
        if (!isNotEmpty(val)) {
            this.setState({errorMessage: 'List name can not be empty'});
            return;
        }
        const { userID, id, openEditListPopup, leadList } = this.props
        let errormsg
        let toastId = null;
        toastId = toast('Updating list name', {
            autoClose: 10000,
        });
        try {
            await editListName(userID, id, val)
            openEditListPopup();
            showSuccessToast(toastId, "List name updated successfully")
        } catch (error) {
            if(!error.response) {
                errormsg = 'Unable to edit list name'
            }
            else if (error.response.data.errCode === 30120) {
                errormsg = 'Permission denied'
            } else {
                errormsg = "Unable to edit list name"
            }
            showErrorToast(toastId, errormsg)
        }
        this.setState({ value: "", errorMessage: '' })
    }
    onClosePopup = () => {
        const { openEditListPopup } = this.props
        openEditListPopup()
        this.setState({ value: "", errorMessage: '' })
    }

    render() {
        const { value, errorMessage } = this.state
        const { popupEditList } = this.props;
        return (
            <Fragment>
                <div className={`add-list ${popupEditList ? 'open' : ''}`}>
                    <div className="add-list__content">
                        <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={this.onClosePopup} />
                        <p className="add-list__title">Edit List Name</p>
                        <p className="add-list__label">List Name</p>
                        <div className="add-list__search-box">
                            <input className="add-list__search"
                                placeholder="No List Selected"
                                type="text"
                                value={value}
                                onChange={this.handleChange}
                                required />

                            {
                                errorMessage &&
                                <div className="lead-header__model_error"><p>{errorMessage}</p></div>
                            }
                        </div>
                        <p className="add-list__create">
                        </p>
                        <div className="add-list__buttons">
                            <div className="add-list__btn orange" onClick={() => { this.onUpdateClick(value) }} >Update</div>
                            <div className="add-list__btn orange-light" onClick={this.onClosePopup}>Cancel</div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default EditListName;
