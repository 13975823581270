import React, { Component, Fragment } from 'react';
import '../style.scss';
import { pauseResume } from '../../../../services/leads';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { showSuccessToast, showErrorToast } from '../../../../helpers/toast';

class PauseResume extends Component {

    onUpdateClick = async () => {
        const { id, openPauseResumePopup, leadList, userID, isActive } = this.props
        let toastId = null;
        let successMsg
        let errormsg
        if (isActive === 0) {
            successMsg = "Fuzebot paused successfully"
            errormsg = 'Unable to pause fuzebot'
            toastId = toast('Pausing FuzeBot', {
                autoClose: 5000,
            });
        } else {
            successMsg = "Fuzebot resumed successfully"
            errormsg = 'Unable to resume fuzebot'
            toastId = toast('Resuming FuzeBot', {
                autoClose: 5000,
            });
        }
        try {
            await pauseResume(userID, id, isActive ===1 ? 0 : 1)
            showSuccessToast(toastId, successMsg)
            openPauseResumePopup()
        }
        catch (error) {
            if(!error.response) {
                errormsg = 'Server error'
            }
            else if (error.response.data.errCode === 30120) {
                errormsg = 'Permission denied'
            }
            showErrorToast(toastId, errormsg);
        }
    }

    onClosePopup = () => {
        const { openPauseResumePopup } = this.props
        openPauseResumePopup()
        this.setState({ error: 0 })
    }

    render() {
        const { popupPauseResume, name, isActive} = this.props;
        return (
            <Fragment>
                <div className={`add-list ${popupPauseResume ? 'open' : ''}`}>
                    <div className="add-list__content">
                        <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={this.onClosePopup} />
                        <p className="add-list__title">{isActive ? 'Resume' : 'Pause'} Fuzebot</p>
                        <p className="lead-cont__delete-list__desc">Are you sure you want to {isActive ? 'resume' : 'pause'} Fuzebot on <strong>{name}</strong>?</p>
                        <p className="add-list__create">
                        </p>
                        <div className="add-list__buttons">
                            <div className="add-list__btn orange" onClick={() => { this.onUpdateClick() }} >Confirm</div>
                            <div className="add-list__btn orange-light" onClick={this.onClosePopup}>Cancel</div>
                        </div>
                    </div>
                </div>
            </Fragment>

        )
    }
}
export default PauseResume;
