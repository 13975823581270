import axios from '../axios';
// const ROOT_URL = process.env.REACT_APP_ROOT_URL;

export function doSignIn(formValues) {
  return axios({
    method: 'post',
    url: `/p/sign-in`,
    data: {
      Email: formValues.email,
      Password: formValues.pass
    }
  });
}

export function forgotPassword(formValues) {
  return axios({
    method: 'post',
    url: `/p/forgot-pass`,
    data: {
      Email: formValues.email,
    }
  });
}

export function resetPassword(formValues) {
  return axios({
    method: 'post',
    url: `/p/reset-pass`,
    data: {
      Password: formValues.pass,
      Token: formValues.token
    }
  });
}
export function getUsers(currentPage, recordsPerPage, searchQuery) {

  return axios({
    method: 'get',
    url: `/core/users?pageNum=${currentPage}&recordsPerPage=${recordsPerPage}&search=${searchQuery}`,
  });
}

export function deleteUser(userId) {

  return axios({
    method: 'delete',
    url: `/core/users/${userId}`,
  });
}

export function deleteAccount(accountId) {

  return axios({
    method: 'delete',
    url: `/core/accounts/${accountId}`,
  });
}

export function updateUser(userId, data) {
  return axios({
    method: 'patch',
    data: data,
    url: `/core/user/${userId}/update-user-admin-info`
  });
}

export function loginAs(userId) {
  return axios({
    method: 'get',
    url: `/core/users/${userId}/loginas`
  });
}

export function getLoggedInUserInfo() {
  const user = JSON.parse(localStorage.getItem("user"));

  return axios({
    method: 'get',
    /* headers: {
      userID: user.id,
      accountID: user.account_id
    }, */
    url: `/core/info`
  });
}

export function updateUserSettings(data) {
  return axios({
    method: 'patch',
    data: {
      settings: data
    },
    url: `/core/update-user-settings`
  });
}