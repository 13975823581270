import React, { Component } from 'react';

class CampaignDropDown extends Component {
    render() {
        const { active, columnValue, lemlistCampaigns } = this.props;
        return (
            <div className={`add-list-name ${active ? 'active' : ''}`}>
                <div className="add-list-name__search">
                    <img src="/assest/accountBased/search.svg" alt="" />
                    <input placeholder="Search List" type="text" />
                </div>
                <div className="add-list-name__row">
                    {
                        lemlistCampaigns.map((item, index) =>
                            <div className="add-list-name__item" key={index} onClick={() => { columnValue(item.name, item._id) }}>
                                <p className="add-list-name__text">{item.name}</p>
                                <p className="add-list-name__edit">{item._id}</p>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default CampaignDropDown;
