import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {bindActionCreators} from 'redux';
import {startLoader, stopLoader} from '../../actions/loader';
import {BreadCrumb, ConfirmationPopup, Pagination, RightPanelBanner, UsersTable} from '../../components';
import {deleteAccount, deleteUser, getUsers, loginAs, updateUser} from '../../services/users';
import './style.scss';
import {LOCAL_STORAGE_KEYS} from '../../constants/constant';
import {EmitterService} from "../../services/emitterService";

function mapStateToProps(state) {
    return {
        loader: state.loader
    }
}

function mapDispatchToProps(dipatch) {
    return bindActionCreators({
        startLoader,
        stopLoader
    }, dipatch)
}

class Users extends Component {
    container = React.createRef();

    state = {
        searchQuery: "",
        currentPage: 1,
        recordsPerPage: 25,
        showDropDown: false,
        totalUsers: 0,

        items: [],
        userListingLoading: false,

        showConfirmation: false,
        selectedItemId: null,
        deleteMethod: null,
        deleteEntityName: null
    }

    componentWillMount() {
        EmitterService.emit("loadLeadsRemaining");
    }

    componentDidMount() {
        let recordsPerPage = localStorage.getItem('recordsPerPage');
        if (recordsPerPage === null) {
            localStorage.setItem('recordsPerPage', '25');
            recordsPerPage = localStorage.getItem('recordsPerPage');
        }

        this.setState({recordsPerPage: recordsPerPage}, this.adminUserList);
    }

    adminUserList = async () => {
        this.startLoader({userListingLoading: true});

        const res = await getUsers(this.state.currentPage, this.state.recordsPerPage, this.state.searchQuery);
        this.setState({items: res.data.data.users, totalUsers: res.data.data.total_number_of_users});
        this.stopLoader({userListingLoading: false});
    }

    _getSelectedItem(id) {
        let selectedItem = null;
        this.state.items.forEach((item) => {
            if (item.id === id) {
                selectedItem = item;
                return;
            }
        });
        return selectedItem;
    }

    toggleUserDetail = (id, toggleValue) => {
        const item = this._getSelectedItem(id);

        if (item.active && toggleValue) {
            return;
        }

        item.active = toggleValue;
        item.editMode = false;

        this.setState({items: this.state.items});
    }

    toggleMenuDropDown = (id, toggleValue) => {
        const item = this._getSelectedItem(id);
        item.menuDropDownActive = toggleValue;

        this.setState({items: this.state.items});
    }

    onEnterPressed = (e) => {
        let code = e.keyCode || e.which;
        if (code === 13) {
            this.setState({currentPage: 1}, this.adminUserList);
        }
    }

    onKeywordChange = (e) => {
        this.setState({searchQuery: e.target.value});
    }

    startLoader(loaderState) {
        this.props.startLoader(true);
        this.setState(loaderState);
    }

    stopLoader(loaderState) {
        this.props.stopLoader(false);
        this.setState(loaderState);
    }

    paginate = async (num) => {
        window.scrollTo(0, 0);
        this.setState({currentPage: num}, this.adminUserList);
    }

    incOrDecNum = (sign, indexOfLastPage) => {
        let currentPage = this.state.currentPage;

        if (sign === "-" && currentPage > 1) {
            currentPage = currentPage - 1
            this.paginate(currentPage)
        } else if (sign === "+" && currentPage < indexOfLastPage) {
            currentPage = currentPage + 1
            this.paginate(currentPage)
        }
    }

    paginationPopup = async () => {
        this.setState({showDropDown: !this.state.showDropDown})
    }

    updatePerPage = async (sign, num) => {
        let integer = parseInt(num);
        if (sign === "+" && integer < 100) {
            this.setState({recordsPerPage: integer + 25}, this.adminUserList);
            localStorage.setItem('recordsPerPage', integer  + 25);
        } else if (sign === "-" && integer > 25) {
            this.setState({recordsPerPage: integer - 25}, this.adminUserList);
            localStorage.setItem('recordsPerPage', (integer - 25));
        } else if (sign === '') {
            this.setState({showDropDown: !this.state.showDropDown, recordsPerPage: integer}, this.adminUserList);
            localStorage.setItem('recordsPerPage', integer);
        }
    }

    loginAsUser = async (id) => {
        const item = this._getSelectedItem(id);
        item.menuDropDownActive = false;
        this.setState({items: this.state.items});

        try {
            const response = await loginAs(id);

            const user = response.data.data.info;
            const account = response.data.data.info.accounts[0];
            const token = response.data.data.token;

            const primaryLogin = {
                user: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER)),
                userAccount: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT)),
                token: localStorage.getItem("token")
            };

            localStorage.setItem("primaryLogin", JSON.stringify(primaryLogin));

            localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(user))
            localStorage.setItem('userAccount', JSON.stringify(account));
            localStorage.setItem('token', token);
            window.location.href = window.location.origin + "/lead-search/market-based";

        } catch (error) {
            toast.error("Unable to login you as " + item.first_name + " " + item.last_name, {
                className: "toast-error-div",
                type: toast.TYPE.ERROR,
                autoClose: 3000
            });
        }
    }

    enableUserEditMode = (id, enable) => {
        const item = this._getSelectedItem(id);
        item.editMode = enable;
        item.active = enable;
        item.menuDropDownActive = false;

        this.setState({items: this.state.items});
    }

    deleteUser = async () => {
        const toastId = this._showToaster("Deleting user");
        try {
            await deleteUser(this.state.selectedItemId);
            this.closeConfirmationPopup();
            this.adminUserList();
            this._updateToaster(toastId, "User deleted successfully");
        } catch (error) {
            this.closeConfirmationPopup();

            toast.update(toastId, {
                className: "toast-error-div",
                render: "Unable to delete user",
                type: toast.TYPE.ERROR,
                autoClose: 3000
            });
        }
    }

    deleteAccount = async () => {
        const toastId = this._showToaster("Deleting account");

        try {
            await deleteAccount(this.state.selectedItemId);
            this.closeConfirmationPopup();
            this.adminUserList();
            this._updateToaster(toastId, "Account deleted successfully");
        } catch (error) {
            this.closeConfirmationPopup();

            toast.update(toastId, {
                className: "toast-error-div",
                render: "Unable to delete account",
                type: toast.TYPE.ERROR,
                autoClose: 3000
            });
        }
    }

    showConfirmationPopup = (item, entityName) => {
        this.toggleMenuDropDown(item.id, false);
        let deleteMethod = this.deleteUser;
        let itemId = item.id;

        if (entityName === "account") {
            deleteMethod = this.deleteAccount;
            itemId = item.account_id;
        }

        this.setState({
            showConfirmation: true,
            selectedItemId: itemId,
            deleteMethod: deleteMethod,
            deleteEntityName: entityName
        });
    }

    closeConfirmationPopup = () => {
        this.setState({showConfirmation: false, selectedItemId: null, deleteMethod: null, deleteEntityName: null});
    }

    _showToaster(message) {
        return toast(message, {hideProgressBar: true, autoClose: 10000})
    }

    _updateToaster(toastId, message) {
        toast.update(toastId, {
            render: message,
            className: "toast-success-div",
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
            autoClose: 2000,
        });
    }

    updateUserData = async (userId, data) => {
        const toastId = this._showToaster("updating user");

        try {
            
            await updateUser(userId, data);
            this.adminUserList();

            const selectedUser = this._getSelectedItem(userId);
            selectedUser.editMode = false;
            selectedUser.active = false;
            this._updateToaster(toastId, "User updated successfully");
            this.setState({items: this.state.items});
        } catch (error) {
            toast.update(toastId, {
                className: "toast-error-div",
                render: "Unable to update user",
                type: toast.TYPE.ERROR,
                autoClose: 3000
            });
        }
    }

    render() {
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
        const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT));

        const links = [
            {
                name: 'Admin',
                slug: '/admin/users',
            },
            {
                name: 'Users',
                slug: null,
            },
        ]

        return (
            <Fragment>

                <BreadCrumb links={links}></BreadCrumb>

                <div className="users-panel">

                    {userId === null && this.props.history.push('/signin?path=/admin/users')}

                    {userAccount.status === "trial_expired" && this.props.history.push('/billing-and-users')}

                    <div className="users-panel__left">
                        <div className="markbased__table users__table">
                            <UsersTable
                                items={this.state.items}
                                totalUsers={this.state.totalUsers}
                                userListingLoading={this.state.userListingLoading}
                                onEnterPressed={this.onEnterPressed}
                                onKeywordChange={this.onKeywordChange}
                                toggleMenuDropDown={this.toggleMenuDropDown}
                                toggleUserDetail={this.toggleUserDetail}
                                loginAsUser={this.loginAsUser}
                                enableUserEditMode={this.enableUserEditMode}
                                showConfirmationPopup={this.showConfirmationPopup}
                                updateUserData={this.updateUserData}/>

                            {this.state.showConfirmation &&
                            <ConfirmationPopup
                                title={`Delete ${this.state.deleteEntityName === "user" ? "User" : "Account"}`}
                                message={`Are you sure you want to delete this ${this.state.deleteEntityName}?`}
                                onYesClick={this.state.deleteMethod}
                                onNoClick={this.closeConfirmationPopup}
                                onCloseClick={this.closeConfirmationPopup}/>
                            }

                            {// && this.state.totalUsers > this.state.recordsPerPage
                                this.state.items && this.state.items.length > 0 &&
                                <Pagination
                                    totalLists={this.state.totalUsers}
                                    recordsPerPage={this.state.recordsPerPage}
                                    currentPageNum={this.state.currentPage}
                                    updatePerPage={this.updatePerPage}
                                    paginationPopup={this.paginationPopup}
                                    paginate={this.paginate}
                                    incOrDecNum={this.incOrDecNum}
                                    showDropDown={this.state.showDropDown}
                                    container={this.container}/>
                            }
                        </div>
                    </div>
                    <div className="users-panel__right">
                        <RightPanelBanner title={'Admin Panel Demo'} page="users" type="users" video_id="af4g9b66jr"/>
                    </div>

                </div>
            </Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
