import { ACCOUNT_BASED_ACTIONS, GLOBAL_ACTIONS } from "../constants/constant";

export function accountBasedFormSubmit(formData) {
  return {
    type: ACCOUNT_BASED_ACTIONS.FORM_SUBMIT,
    payload: formData,
  };
}

export function accountBasedUserListUpload(company_domain_tags) {
  return {
    type: ACCOUNT_BASED_ACTIONS.USER_LIST,
    payload: {company_domain_tags},
  };
}

export function accountBasedRole(roleGroupsData, name) {
  return {
    type: ACCOUNT_BASED_ACTIONS.ROLE,
    payload: roleGroupsData,
    name: name
  };
}

export function accountBasedIncludeKeywords(incKeywordsData) {
  return {
    type: ACCOUNT_BASED_ACTIONS.INCLUDE_KEYWORDS,
    payload: incKeywordsData
  };
}
export function accountBasedExcludeKeywords(excKeywordsData) {
  return {
    type: ACCOUNT_BASED_ACTIONS.EXCLUDE_KEYWORDS,
    payload: excKeywordsData
  };
}
export function accountBasedSearchPastEmployment(excKeywordsData) {
  return {
    type: ACCOUNT_BASED_ACTIONS.SEARCH_PAST_EMPLOYMENT,
    payload: excKeywordsData
  };
}

export function accountBasedAction(accountBased) {
  return {
    type: ACCOUNT_BASED_ACTIONS.ALL,
    payload: accountBased,
  };
}


export function resetState() {
  return {
    type: GLOBAL_ACTIONS.RESET_STATE
  };
}

export function clearAccountBasedChanged() {
  return {
    type: "CLEAR_ACCOUNTBASED_CHANGED"
  };
}
