import React, { Component, Fragment } from 'react';
import moment from "moment";
import AccountDetails from './AccountDetails';
import OtherInfo from './OtherInfo';
import UsageDetails from './UsageDetails';

const titles = [
  {
    title: 'User details',
    rightTitle: '',
    active: true
  },
  {
    title: 'Account details',
    rightTitle: '',
    active: true
  },
  {
    title: 'Other Info',
    rightTitle: '',
    active: true
  }
]

class GeneralInfo extends Component {
  state = {
    available_lead_credits: 0,
    account_available_lead_credits:0,
    automation_limit: 0,
    used_lead_credits: 0,
    list_capacity: 0,
    list_count: 0,
    max_lead_credits: 0,
    is_fraud: false,
    is_admin: false,
    is_lead_fuze_admin: false,
    trailExpirationDate: null,
    trailExpirationDateStr: null,

    contract_start_date: null,

    is_unlimited:false,
    is_life_time: false,
    extended_trial: false,
    double_lead_credits_offer: false,

    monthly_lead_credits: null,
    plan_name: null,
    stripe_id: null,
    subscription_id: null,

  }

  componentWillMount() {
    const {user} = this.props;
    
    const date = new Date(user.accounts[0].trial_expiration_date);
    const dateStr = moment(date).format("MM-DD-YYYY");


    this.setState({
      available_lead_credits: user.available_lead_credits,
      account_available_lead_credits: user.accounts[0].available_lead_credits,       used_lead_credits: user.used_lead_credits,
      automation_limit: user.accounts[0].automation_limit,
      list_capacity: user.accounts[0].list_capacity,
      max_lead_credits: user.max_lead_credits,
      list_count: user.list_count,
      trailExpirationDateStr: dateStr, 
      trailExpirationDate: date,
      is_admin: user.is_admin,
      is_lead_fuze_admin: user.is_lead_fuze_admin || user.is_lead_fuze_admin === false ? user.is_lead_fuze_admin  : false,


      contract_start_date: user.accounts[0].contract_start_date,
      
      is_fraud: user.accounts[0].is_fraud,
      is_unlimited: user.accounts[0].is_unlimited,
      is_life_time: user.accounts[0].is_life_time,
      extended_trial: user.accounts[0].extended_trial,
      double_lead_credits_offer: user.accounts[0].double_lead_credits_offer,

      monthly_lead_credits: user.accounts[0].monthly_lead_credits,
      plan_name: user.accounts[0].plan_name,
      stripe_id: user.accounts[0].stripe_id,
      subscription_id: user.accounts[0].subscription_id,


    })
  }
  updateSubscriptionId = (val) => {
    this.setState({subscription_id: val});
  }
  updateListsCreated = (val) => {
    this.setState({list_count: Number(val)});
  }
  updateUsedLeadCredits = (val) => {
    this.setState({used_lead_credits: Number(val)});
  }
  updateStripeId = (val) => {
    this.setState({stripe_id: val});
  }
  updatePlanName = (val) => {
    this.setState({plan_name: val});
  }
  updateMonthlyLeadCredits = (val) => {
    this.setState({monthly_lead_credits: Number(val)});
  }

  updateAccountAvailableLeadCredits = (val) => {
    this.setState({account_available_lead_credits: Number(val)});
  }
  updateAvailableLeadCredits = (val) => {
    this.setState({account_available_lead_credits: Number(val)});
  }

  updateAutomationLimit = (val) => {

    this.setState({automation_limit: Number(val)});
  }

  updateListCapacity = (val) => {
    this.setState({list_capacity: Number(val)});
  }

  updateMaxLeadCredits = (val) => {
    this.setState({max_lead_credits: Number(val)});
  }

  onDateChange = (date) => {
    //console.log(this.state);
    const dateStr = moment(date).format("MM-DD-YYYY");
    //const trial_expiration = moment(date).format("YYYY-MM-DDTHH:mm:ss.msZ");
    this.setState({trailExpirationDateStr: dateStr,  trailExpirationDate:  date });
    //console.log(this.state);

  }

  onContractStartDateChange = (date) => {
    const contract_start_date = new Date(date).getTime()/1000;
    this.setState({  contract_start_date:  contract_start_date });
  }

  updateIsUnlimited = () => {
    if (this.props.user.editMode) {
      this.setState({is_unlimited: !this.state.is_unlimited});
    }
  }
  updateIsExtendedTrial = () => {
    if (this.props.user.editMode) {
      this.setState({extended_trial: !this.state.extended_trial});
    }
  }
  updateIsLifeTime = () => {
    if (this.props.user.editMode) {
      this.setState({is_life_time: !this.state.is_life_time});
    }
  }
  updateIsDoubleLeadCreditsOffer = () => {
    if (this.props.user.editMode) {
      this.setState({double_lead_credits_offer: !this.state.double_lead_credits_offer});
    }
  }


  updateIsFraud = () => {
    if (this.props.user.editMode) {
      this.setState({is_fraud: !this.state.is_fraud});
    }
  }
  
  updateIsAdmin = () => {
    if (this.props.user.editMode) {
      this.setState({is_admin: !this.state.is_admin});
    }
  }

  updateIsLeadFuzeAdmin = () => {
    if (this.props.user.editMode) {
      this.setState({is_lead_fuze_admin: !this.state.is_lead_fuze_admin});
    }
  }


  onUpdateUserData = () => {
    const user = {
      available_lead_credits: this.state.account_available_lead_credits,      
      used_lead_credits: this.state.used_lead_credits,
      //account_available_lead_credits: this.state.account_available_lead_credits,
      automation_limit: this.state.automation_limit,
      list_capacity: this.state.list_capacity,
      max_lead_credits: this.state.max_lead_credits,
//      list_count: this.state.list_count,
      trial_expiration: this.state.trailExpirationDate,
      is_fraud: this.state.is_fraud,
      is_admin: this.state.is_admin,
      is_lead_fuze_admin: this.state.is_lead_fuze_admin,

      contract_start_date: this.state.contract_start_date,

      is_unlimited: this.state.is_unlimited,
      is_life_time: this.state.is_life_time,
      extended_trial: this.state.extended_trial,
      double_lead_credits_offer: this.state.double_lead_credits_offer,

      monthly_lead_credits: this.state.monthly_lead_credits,
      plan_name: this.state.plan_name,
      stripe_id: this.state.stripe_id,
      subscription_id: this.state.subscription_id,



    };
    //console.log(user);
    this.props.updateUserData(this.props.user.id, user);
  }


  
  render() {
    return(
      <div className="user-general">
        {
          titles.map((item, index) =>
            <Fragment key={index}>
              
              <div className="users-tab-list-tabs__subtitle">
                <p className={item.rightTitle ? 'col-50' : ''}>{item.title}</p>
                {item.rightTitle ? <p className="col-50">{item.rightTitle}</p> : ''}
              </div>

              {
                index === 0 ? 
                <UsageDetails user={this.props.user} 
                  available_lead_credits={this.state.available_lead_credits}
                  used_lead_credits={this.state.used_lead_credits}
                  is_admin={this.state.is_admin}
                  is_lead_fuze_admin={this.state.is_lead_fuze_admin}
                  list_count={this.state.list_count} 
                  max_lead_credits={this.state.max_lead_credits}


                  updateUserAvailableLeadCredits={this.updateUserAvailableLeadCredits}
                  updateListsCreated={this.updateListsCreated}
                  updateUsedLeadCredits={this.updateUsedLeadCredits}
                  updateListCapacity={this.updateListCapacity}
                  updateMaxLeadCredits={this.updateMaxLeadCredits}
                  updateIsFraud={this.updateIsFraud}
                  updateIsAdmin={this.updateIsAdmin}
                  updateIsLeadFuzeAdmin={this.updateIsLeadFuzeAdmin}

                 /> : 
                index === 1 ? <AccountDetails user={this.props.user} 
                trailExpirationDateStr={this.state.trailExpirationDateStr}
                trailExpirationDate={this.state.trailExpirationDate}
                contract_start_date={this.state.contract_start_date}
                automation_limit={this.state.automation_limit}
                available_lead_credits={this.state.account_available_lead_credits}
                monthly_lead_credits={this.state.monthly_lead_credits}
                plan_name={this.state.plan_name}
                stripe_id={this.state.stripe_id}
                subscription_id={this.state.subscription_id}
                double_lead_credits_offer={this.state.double_lead_credits_offer}
                extended_trial={this.state.extended_trial}
                is_life_time={this.state.is_life_time}
                is_unlimited={this.state.is_unlimited}
                is_fraud={this.state.is_fraud}
                list_capacity={this.state.list_capacity}

               // account_available_lead_credits={this.state.account_available_lead_credits}
               updateAccountAvailableLeadCredits={this.updateAccountAvailableLeadCredits}                updateListCapacity={this.updateListCapacity}
                onContractStartDateChange={this.onContractStartDateChange}
                updateIsExtendedTrial={this.updateIsExtendedTrial}
                updateIsDoubleLeadCreditsOffer={this.updateIsDoubleLeadCreditsOffer}
                updateIsUnlimited={this.updateIsUnlimited}
                updateIsLifeTime={this.updateIsLifeTime}
                updateAutomationLimit={this.updateAutomationLimit}
                updateIsFraud={this.updateIsFraud}
                updateLeadToManyLists={this.updateLeadToManyLists}

                updateMonthlyLeadCredits={this.updateMonthlyLeadCredits}
                updatePlanName={this.updatePlanName}
                updateStripeId={this.updateStripeId}
                updateSubscriptionId={this.updateSubscriptionId}

                onDateChange={this.onDateChange}
                /> : 
                index === 2 ? <OtherInfo user={this.props.user} /> : ''
              }
            </Fragment>
          )
        }

        {this.props.user.editMode &&
          <div className="users-tab__buttons">
            <div className="users-tab__btn orange" onClick={(event) => {event.stopPropagation(); this.onUpdateUserData();}}>Save Changes</div>

            <div className="users-tab__btn" onClick={(event) => {event.stopPropagation(); this.props.enableUserEditMode(this.props.user.id, false)}}>Cancel</div>
          </div>
        }

      </div>
    )
  }
}

export default GeneralInfo;