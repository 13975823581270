const items = [
  {
    check: false,
    active: false,
    code: 'ZMVG9KdQ',
    descr: 'Gets 25% more leads/mo and the Kickstart package for free',
    time: '0',
    monthly: '0',
    usage: '~',
    redeemers: '100',
    expire: '03.14.2019'
  },
  {
    check: false,
    active: false,
    code: 'DDFF9KdQ',
    descr: 'Gets 25% more leads/mo and the Kickstart package for free',
    time: '0',
    monthly: '0',
    usage: '~',
    redeemers: '100',
    expire: '03.14.2019'
  },
  {
    check: false,
    active: false,
    code: 'VDCCV9KdQ',
    descr: 'Gets 25% more leads/mo and the Kickstart package for free',
    time: '0',
    monthly: '0',
    usage: '~',
    redeemers: '100',
    expire: '03.14.2019'
  },
  {
    check: false,
    active: false,
    code: 'KLJJKM9KdQ',
    descr: 'Gets 25% more leads/mo and the Kickstart package for free',
    time: '0',
    monthly: '0',
    usage: '~',
    redeemers: '100',
    expire: '03.14.2019'
  },
  {
    check: false,
    active: false,
    code: 'YRERI9KdQ',
    descr: 'Gets 25% more leads/mo and the Kickstart package for free',
    time: '0',
    monthly: '0',
    usage: '~',
    redeemers: '100',
    expire: '03.14.2019'
  }
]

export default items