import React, { Component } from "react";
import PeopleMarket from "./PeopleMarket";
import "./style.scss";
import CompanyMarket from "./CompanyMarket";
import { technology_groups } from "../../../tags/technology_groups";

class MarketSearch extends Component {
  state = {
    active: "peoplePro",
    companyCollapse: false,
    peopleCollapse: true,
  };
  changePeople = () => {
    this.setState({ active: "peoplePro" });
  };
  changeCompany = () => {
    this.setState({ active: "companyPro" });
  };

  togglePeopleView = () => {
    this.setState({ peopleCollapse: !this.state.peopleCollapse });
  };

  toggleCompanyView = () => {
    this.setState({ companyCollapse: !this.state.companyCollapse });
  };

  render() {
    const { active } = this.state;
    const {
      setGroups,
      setIncludeTags,
      setExcludeTags,
      clearFields,
      updateClearFields,
      marketBased,
      expendSearchCriteria,

      searchResultFilters,
      setCompanyFoundedYearFrom,
      setCompanyFoundedYearTo,
      toggleIncludePastRole,


    } = this.props;
    return (
      <section className={`market ${!expendSearchCriteria ? 'collapsed' : 'expended'}`}>
        <div className="market-header__top">
          <h2 className="market__title">
            Search Criteria
            {
              this.props.title &&
              <span>&nbsp;({this.props.title})</span>
            }
          </h2>
          {/* ${expendSearchCriteria ? 'collapse' : 'expend'} */}

          {!expendSearchCriteria && !this.props.hideChangeSearch &&
            <div className="market-header__change" onClick={this.props.changeSearch}>Change Search</div>
          }
        </div>


        {expendSearchCriteria &&
        // <div className={`based-header__top ${this.state.companyCollapse ? "expend" : "collapse"}`}
        // style={{borderTop: 'none'}} >
          <h2 className={`adv-lead__title ${this.state.companyCollapse ? "expend" : "collapse"}`}
          onClick={this.toggleCompanyView}
          >Company Criteria</h2>
        // </div>
        }

        {expendSearchCriteria && !this.state.companyCollapse &&
          <div className="market__container">
            {/* <div className="market-tabs">
              <div className={`market-tabs__title market-tabs__people ${active}`} onClick={this.changePeople}>
                <p>People Prospector</p>
              </div>
            </div> */}

            <div className="market__box">
              <CompanyMarket
                marketBased={marketBased}
                active={active}
                setGroups={setGroups}
                setIncludeTags={setIncludeTags}
                setExcludeTags={setExcludeTags}
                clearFields={clearFields}
                updateClearFields={updateClearFields}
                searchResultFilters={searchResultFilters}
                setCompanyFoundedYearFrom={setCompanyFoundedYearFrom}
                setCompanyFoundedYearTo={setCompanyFoundedYearTo}
                technology_groups={technology_groups}
              />
            </div>
            </div>}

            {expendSearchCriteria &&
            // <div className="based-header__top">
              <h2 className={`adv-lead__title  ${this.props.peopleCollapse ? "expend" : "collapse"}`}
              onClick={this.props.togglePeopleView}>People Criteria</h2>
            // </div>
            }
        {expendSearchCriteria && !this.props.peopleCollapse &&
          <div className="market__container">


            <div className="market__box">
              <PeopleMarket
                marketBased={marketBased}
                active={active}
                setGroups={setGroups}
                setIncludeTags={setIncludeTags}
                setExcludeTags={setExcludeTags}
                clearFields={clearFields}
                updateClearFields={updateClearFields}
                toggleIncludePastRole={toggleIncludePastRole}
                isAnyCompanyCriteriaApplied={this.props.isAnyCompanyCriteriaApplied}
              />
            </div>
          </div>
        }
      </section>
    );
  }
}

export default MarketSearch;
