export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNIN_USER_FAILURE = "SIGNIN_USER_FAILURE";
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";
export const OPEN_EMPLOYEE_POPUP = "OPEN_EMPLOYEE_POPUP";
export const CLOSE_EMPLOYEE_POPUP = "CLOSE_EMPLOYEE_POPUP";
export const RESET_PASS_TOKEN_EXPIRE = "RESET_PASS_TOKEN_EXPIRE";
export const RESET_PASS_TOKEN_SUCCESS = "RESET_PASS_TOKEN_SUCCESS";
export const LEAD_FILTER_FUZZBOT_STATUS = "LEAD_FILTER_FUZZBOT_STATUS";
export const LEAD_FILTER_FUZZBOT_ACTIVE = "LEAD_FILTER_FUZZBOT_ACTIVE";
export const LEAD_FILTER_FUZZBOT_PAUSE = "LEAD_FILTER_FUZZBOT_PAUSE";
export const LEAD_FILTER_FUZZBOT_NONE = "LEAD_FILTER_FUZZBOT_NONE";
export const LEAD_FILTER_FILTER_NAME = "LEAD_FILTER_FILTER_NAME";
export const LEAD_FILTER_LIST_AT_MAX_CAPACITY =
    "LEAD_FILTER_LIST_AT_MAX_CAPACITY";
export const LEAD_FILTER_LIST_WITH_ZERO_LEADS =
    "LEAD_FILTER_LIST_WITH_ZERO_LEADS";
export const LEAD_FILTER_OWNER = "LEAD_FILTER_OWNER";
export const LEAD_FILTER_OWNERS = "LEAD_FILTER_OWNERS";
export const LEAD_LIST_NAMES = "LEAD_LIST_NAMES";
export const LEAD_NAME_FILTER_ACTION = "LEAD_NAME_FILTER_ACTION";
export const GET_ADMIN_USERS = "GET_ADMIN_USERS";
export const GET_LEAD_LIST = "GET_LEAD_LIST";
export const CLEAR_LEAD_LIST = "CLEAR_LEAD_LIST";
export const UPDATE_LIST_ITEM = "UPDATE_LIST_ITEM";
export const GET_INTEGRATION = "GET_INTEGRATION";
export const REDIRECT_USER_TO_LIST = "REDIRECT_USER_TO_LIST";
export const LEAD_LIST_PAGINATION = "LEAD_LIST_PAGINATION";
export const SUCCESS_DIV_REDIRECT = "SUCCESS_DIV_REDIRECT";
export const GET_API_KEYS = "GET_API_KEYS";
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const API_KEY_PAGINATION = "API_KEY_PAGINATION";
export const CREATE_DATE_VAL = "CREATE_DATE_VAL";
export const REVOKE_DATE_VAL = "REVOKE_DATE_VAL";
export const CREATE_AND_REVOKE = "CREATE_AND_REVOKE";
export const SORT_BY = "SORT_BY";
export const RECORDS_PER_PAGE = "RECORDS_PER_PAGE";
export const LEADS_RECORDS_PER_PAGE = "LEADS_RECORDS_PER_PAGE";
export const LEADS_CLEAR_PAGE_NUMBER = "LEADS_CLEAR_PAGE_NUMBER";
export const LEADS_SORT_BY = "LEADS_SORT_BY";
export const SET_ALL_CHECK_TO_FALSE = "SET_ALL_CHECK_TO_FALSE";
export const IGNORE_LIST_PAGINATION = "IGNORE_LIST_PAGINATION";
export const IGNORE_LIST_RECORDS_PER_PAGE = "IGNORE_LIST_RECORDS_PER_PAGE";
export const LOGIN_ATTEMPTS = "LOGIN_ATTEMPTS";
export const ROLE = "ROLE";

export const ACCOUNT_BASED_ACTIONS = {
    FORM_SUBMIT: "ACTION_ACCOUNT_BASED_FORM",
    USER_LIST: "ACTION_ACCOUNT_BASED_USER_LIST",
    ROLE: "ACTION_ACCOUNT_BASED_ROLE",
    INCLUDE_KEYWORDS: "ACTION_ACCOUNT_BASED_INCLUDE_KEYWORDS",
    EXCLUDE_KEYWORDS: "ACTION_ACCOUNT_BASED_EXCLUDE_KEYWORDS",
    SEARCH_PAST_EMPLOYMENT: "ACTION_ACCOUNT_BASED_SEARCH_PAST_EMPLOYMENT",
    ALL: "ACTION_ALL",
};


export const GLOBAL_ACTIONS = {
    RESET_STATE: "RESET_STATE"
}

export const SEARCH_RESULT_ACTIONS = {
    REQUIRE_EMAIL: "ACTION_SEARCH_RESULT_REQUIRE_EMAIL",
    REQUIRE_DOUBLE_VERIFIED_EMAILS: "ACTION_SEARCH_RESULT_REQUIRE_DOUBLE_VERIFIED_EMAILS",
    REQUIRE_COMPANY_EMAILS: "ACTION_SEARCH_RESULT_REQUIRE_COMPANY_EMAILS",
    REQUIRE_PERSONAL_EMAILS: "ACTION_SEARCH_RESULT_REQUIRE_PERSONAL_EMAILS",

    REQUIRE_PHONE_NUMBER: "ACTION_SEARCH_RESULT_REQUIRE_PHONE_NUMBER",
    REQUIRE_COMPANY_PHONE_NUMBER: "ACTION_SEARCH_RESULT_REQUIRE_COMPANY_PHONE_NUMBER",
    REQUIRE_PERSONAL_PHONE_NUMBER: "ACTION_SEARCH_RESULT_REQUIRE_PERSONAL_PHONE_NUMBER",

    REQUIRE_SOCIAL: "ACTION_SEARCH_RESULT_REQUIRE_SOCIAL",
    REQUIRE_LINKEDIN: "ACTION_SEARCH_RESULT_REQUIRE_LINKEDIN",
    REQUIRE_FACEBOOK: "ACTION_SEARCH_RESULT_REQUIRE_FACEBOOK",
    REQUIRE_TWITTER: "ACTION_SEARCH_RESULT_REQUIRE_TWITTER",

    REQUIRE_JOB_STATUS: "ACTION_SEARCH_RESULT_REQUIRE_JOB_STATUS",
    REQUIRE_EMPLOYMENT: "ACTION_SEARCH_RESULT_REQUIRE_EMPLOYMENT",
    REQUIRE_UNEMPLOYMENT: "ACTION_SEARCH_RESULT_REQUIRE_UNEMPLOYMENT",

    REQUIRE_MAILING_ADDRESS: "ACTION_SEARCH_RESULT_REQUIRE_MAILING_ADDRESS",
    EXCLUDE_PREVIOUS_EXPERIENCE: "ACTION_SEARCH_RESULT_EXCLUDE_PREVIOUS_EXPERIENCE",

    MAX_PEOPLE_PER_COMPANY: "ACTION_SEARCH_RESULT_MAX_PEOPLE_PER_COMPANY",
    MAX_RESULTS_PER_COMPANY: "ACTION_SEARCH_RESULT_MAX_RESULTS_PER_COMPANY",

    COMPANY_FOUNDED_DATE_FROM: "ACTION_SEARCH_RESULT_FOUNDED_DATE_FROM",
    COMPANY_FOUNDED_DATE_TO: "ACTION_SEARCH_RESULT_FOUNDED_DATE_TO",
    SEARCH_TYPE: "ACTION_SEARCH_TYPE",
    SET_ALL: "ACTION_SET_ALL",

    CLEAR_ALL: "ACTION_SEARCH_RESULT_CLEAR_ALL",

    SET_RESULTS_LIST: "ACTION_SEARCH_RESULT_SET_RESULTS_LIST",
    CLEAR_SEARCHRESULTFILTER_CHANGED: "CLEAR_SEARCHRESULTFILTER_CHANGED",
}

export const INSIDE_LIST_ACTIONS = {
    ADDED_BY_FUZEBOT: "INSIDE_LIST_ADDED_BY_FUZEBOT",
    HAS_EMAIL: "INSIDE_LIST_HAS_EMAIL",
    PERSONAL_EMAIL: "INSIDE_LIST_PERSONAL_EMAIL",
    COMPANY_EMAIL: "INSIDE_LIST_COMPANY_EMAIL",
    SINGLE_VERIFIED_EMAIL: "INSIDE_LIST_SINGLE_VERIFIED_EMAIL",
    DOUBLE_VERIFIED_EMAIL: "INSIDE_LIST_DOUBLE_VERIFIED_EMAIL",
    HAS_LINKED_IN: "INSIDE_LIST_HAS_LINKED_IN",
    HAS_PHONE: "INSIDE_LIST_HAS_PHONE",
    HAS_MAILING_EMAIL: "INSIDE_LIST_HAS_MAILING_EMAIL",
    FROM_TO: "INSIDE_LIST_FROM_TO",
    CLEAR_ALL: "INSIDE_LIST_CLEAR_ALL",
}

export const BILLING_ACTIONS = {
    SET_BILLING_OVERVIEW: "SET_BILLING_OVERVIEW",
    // SET_BILLING_OVERVIEW: "SET_BILLING_OVERVIEW",
    CLEAR_ALL: "BILLING_ACTIONS_CLEAR_ALL",
}

export const LOADED_DATA_ACTIONS = {
    UPDATE_COMPANY_LOCATION_OPTIONS: "ACTION_UPDATE_COMPANY_LOCATION_OPTIONS",
    CLEAR_ALL: "ACTION_LOADED_DATA_CLEAR_ALL",
}


export const SearchType = {
    AccountBased: 'accountBased',
    MarketBased: 'marketBased',
}

export const LocationDropdownType = {
    Company: 'company',
    People: 'people',
}

export const LocationType = {
    City: 0,
    State: 1,
    Metro: 2,
    Country: 3,
    Group: 4,
    Continent: 5,
    Keyword: 6,
}
export const FUZEBOT_STATUSES = {
    NOT_AVAILABLE: 'N/A',
    RUNNING: "Running",
    PAUSED: "Paused"
}

export const DEFAULT_TOAST_DURATION = 4000

export const INTEGRATION_ID = {
    CLOSE_IO: 11,
    DUX_SOUP: 19,
    JAZZ_HR: 26,
    SALESFORCE: 25,
    ZAPIER: 14,
    BIDSKETCH: 15,
    FRESHSALES: 20,
    GOOGLE_SHEETS: 36,
    HUBSPOT: 12,
    LEMLIST: 21,
    MAILSHAKE: 22,
    PIPEDRIVE: 23,
    REPLY: 37,
    HIGHLEVEL: 38,
    SALESFLARE: 24,
    WOODPECKER: 35,
    APPYPIE: 100
}

export const LOCAL_STORAGE_KEYS = {
    USER: 'user',
    TOKEN: 'token',
    USER_ACCOUNT: 'userAccount',
    RECORDS_PER_PAGE: 'recordsPerPage',
    PRIMARY_LOGIN: 'primaryLogin'
}

export const MARKET_BASED_QUERY_PARAM_ACTIONS = {
    PREVIEW_SAVE_SEARCH: "preview_save_search",
    PREVIEW_LEADS: 'preview_leads',
    EDIT_FUZEBOT: 'edit_fuzebot'
}
