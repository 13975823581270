const items = [
  {
    name: 'Category',
    clname: 'category',
    active: false,
    order: ''
  },
  {
    name: 'Title',
    clname: 'title',
    active: false,
    order: ''
  },
  {
    name: 'Upload',
    clname: 'upload',
    active: false,
    order: ''
  },
  {
    name: 'Date added',
    clname: 'added',
    active: false,
    order: ''
  }
]

export default items