import React, { Component } from 'react'
import { Uploads } from '../../../components'
import '../style.scss'
import { Fab } from '@material-ui/core'
import { ConfirmationPopup } from '../..'

class IgnoreListAdd extends Component {

  state = {
    droped: false,
    showBulkUploadConfirmation: false,
  }
  buttonMessage = (uploading) => {
    this.setState({ droped: uploading })
  }

  onHandleAdd = () => {
    const { company, domain, email } = this.props;
    if (!company && !domain && !email) {
      this.props.validateFail();
    }
    else {
      this.setState({
        showBulkUploadConfirmation: true,
      })
    }
  }

  

  closeBulkUploadConfirmationPopup = () => {
    this.setState({
      showBulkUploadConfirmation: false
    })
  }

  onEnterPressed = (e) => {
    let code = e.keyCode || e.which;
    if (code === 13) {
      this.onHandleAdd();
    }
  }

  render() {
    const {
      validate,
      company,
      domain,
      email,
      getValue,
      addIgnoreList,
      showAddIgnoreList,
      uploadIgnoreList,
      hasAllInputsEmpty
    } = this.props
    const { droped } = this.state
    return (
      /* ${addIgnoreListForm ? 'active' : ''} */
      <section className={`coupon create active`}>
        <div className="ignore">
          <h3 className="integrations-cont__title">ADD TO IGNORE LIST</h3>
          <div className="ignore-add">
            <div className="ignore-add__row">
              <div className="ignore-add__col">
                <label className="ignore-add__label">
                  <p>Company name</p>
                  <input type="text"
                    name="company"
                    value={company}
                    onChange={getValue}
                    onKeyPress={this.onEnterPressed}
                    placeholder="Type Company Name"
                  />
                  {validate.inputError && !validate.domain && !validate.email &&
                    <span className="errorInput-red">Please fill out at least one keyword.</span>
                  }
                </label>
                <label className="ignore-add__label">
                  <p>Domain name</p>
                  <input type="text"
                    name="domain"
                    value={domain}
                    onChange={getValue}
                    onKeyPress={this.onEnterPressed}
                    placeholder="Type Domain Name" />
                  {validate.inputError && !validate.domain && !validate.email &&
                    <span className="errorInput-red">Please fill out at least one keyword.</span>
                  }
                  {validate.domain &&
                    <span className="errorInput-red">Please enter valid domain</span>
                  }
                </label>
                <label className="ignore-add__label">
                  <p>Email address</p>
                  <input type="email"
                    name="email"
                    value={email}
                    onChange={getValue}
                    onKeyPress={this.onEnterPressed}
                    placeholder="Type Email Address" />
                  {validate.inputError && !validate.email && !validate.domain &&
                    <span className="errorInput-red">Please fill out at least one keyword.</span>
                  }
                  {validate.email &&
                    <span className="errorInput-red">Please enter valid email</span>
                  }
                </label>
                {
                  hasAllInputsEmpty &&
                  <div className="ignore-add__error">
                    <p>Please fill out at least one field</p>
                  </div>
                }
              </div>
              <Uploads buttonMessage={this.buttonMessage} uploadIgnoreList={uploadIgnoreList} showAddIgnoreList={showAddIgnoreList}  page="IgnoreList" />
            </div>

            <div className={`ignore__buttons ${droped ? "disable-btn" : ''}`}>
              <Fab
                onClick={this.onHandleAdd}
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add"
              >{droped ? "Uploading..." : "Add to Ignore List"}</Fab>
              {/* <Fab
                onClick={showAddIgnoreList}
                variant="extended"
                size="medium"
                color="secondary"
                aria-label="add"
              >Cancel</Fab> */}
            </div>
          </div>
        </div>
        {
          this.state.showBulkUploadConfirmation &&
          <ConfirmationPopup
            title="Delete Existing Leads"
            message="Do you want to delete existing leads that match these new ignore list keywords?"
            onYesClick={() => {
              addIgnoreList(true)
              this.closeBulkUploadConfirmationPopup()
            }}
            onNoClick={() => {
              addIgnoreList(false)
              this.closeBulkUploadConfirmationPopup()
            }}
            onCloseClick={() => {
              this.closeBulkUploadConfirmationPopup()
            }}
          />
        }
      </section>
    )
  }
}

export default IgnoreListAdd
