import React, { Component } from 'react';

class UsageDetails extends Component {
  render() {
    const user = this.props.user;
    const account = user.accounts[0];
    return( 
      <div className="details-cont">
        <div className="details-cont__row">
          
          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>Available Lead Credits</p>
              <input placeholder="Number here" type="number"   disabled  
              value={this.props.available_lead_credits}               
              />
            </label>
          </div>

          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>Used Lead credits</p>
              <input placeholder="Number here" type="number"  disabled={!user.editMode}  
              value={this.props.used_lead_credits}
              onChange={(e) => this.props.updateUsedLeadCredits(e.target.value)}
              />
            </label>
          </div>


          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>Max Lead Credits</p>
              <input placeholder="Number here" type="number" disabled={!user.editMode} 
              value={this.props.max_lead_credits}
              onChange={(e) => this.props.updateMaxLeadCredits(e.target.value)}
              />
            </label>
          </div>

          
          <div className="details-cont__col">
            <label className="details-cont__label">
              <p># of lists added</p>
              <input placeholder="Text here" type="text" disabled={!user.editMode}  
              value={user.list_count}
              onChange={(e) => this.props.updateListsCreated(e.target.value)}

              />
            </label>
          </div>

    

        

          <div className="details-cont__col d-flex">
            <div className="check-panel">
              <div className={`check ${user.editMode ? '' : 'disabled'} ${this.props.is_lead_fuze_admin ? 'active' : ''}`} onClick={() => this.props.updateIsLeadFuzeAdmin()}></div>
              <div className="check-label">Is LeadFuze Admin?</div>
            </div>
          </div>


          <div className="details-cont__col d-flex">
            <div className="check-panel">
              <div className={`check ${user.editMode ? '' : 'disabled'} ${this.props.is_admin ? 'active' : ''}`} onClick={() => this.props.updateIsAdmin()}></div>
              <div className="check-label">Is Admin?</div>
            </div>
          </div>


          
          
        </div>

      {/*   <div className="details-cont__row">

          { this.props.user.integrations.length > 0 &&
            <div className="details-cont__col">
              <p>Integrations Used</p>

              <ul className="user-integration-list">
                {this.props.user.integrations.map((item) => 
                  <li key={item._id}>
                    <img src={`/assest/integrations/${item.name}.png`} alt="" />
                    <span>{item.name}</span>
                  </li>
                )}
              </ul>

            </div>
          }

        </div>
      */}
     
      </div>
    ) 
  }
}

export default UsageDetails;