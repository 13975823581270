import axios from '../axios';

export function getHotLeadConfiguration() {
    return axios({
        method: 'get',
        url: `/core/hot-leads-config`,
    });
}

export function createHotLeadConfiguration(data) {
    return axios({
        method: 'put',
        url: `/core/hot-leads-config`,
        data
    });
}

export function updateHotLeadConfiguration(data) {
    return axios({
        method: 'put',
        url: `/core/hot-leads-config`,
        data,
        params: {
            hlcId: data.hlc_id,
        },
    });
}