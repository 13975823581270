import axios from '../axios'

const POOL_SERVICE_URL = process.env.REACT_APP_POOL_SERVICE_URL
const PROCESS_SERVICE_URL = process.env.REACT_APP_PROCESS_SERVICE_URL


export function getIgnoreLists(
    accID,
    numberOfrecordsToShow,
    keyword,
    sortOrder,
    source,
    nextCursor
) {

    let cursor = "";
    if (nextCursor && nextCursor.length > 0) {
        cursor = nextCursor.toString();
    }

    return axios({
        method: "get",
        url: `/pool/ignorelists`,
        params: {
            sort: source,
            recordsPerPage: numberOfrecordsToShow,
            keyword: keyword,
            order: sortOrder,
            cursor: cursor
        }
    })
}

export function addIgnoreList(accID, items, deleteLeads) {
    return axios({
        method: "post",
        url: `/process/ignorelists`,
        data: {
            "ignore_list_items": items,
            "deleteLeads": deleteLeads
        }
    })
}

export function addIgnoreListViaFile(accID, file, deleteLeads, userID) {
    return axios({
        method: "post",
        url: `/process/ignorelists-file-upload?deleteLeads=` + deleteLeads,
        data: file
    })
}

export function deleteIgnoreList(
    accID,
    selectedIDs,
    deSelectedIDs,
    keyword,
    selectall
) {
    return axios({
        method: "delete",
        url: `/pool/ignorelists`,
        data: {
            deselected_ids: deSelectedIDs,
            select_all: selectall,
            selected_ids: selectedIDs,
            search_query: {
                keyword: keyword
            }
        }
    })
}
