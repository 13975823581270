import React, { Fragment, Component } from 'react'
import './style.scss';
import ApiKeysTable from '../../components/ApiKeysBox/ApiKeysHeader';
import { connect } from 'react-redux'
import { Pagination, RightPanelBanner, BreadCrumb } from '../../components';
import { getApiKeys } from '../../services/apiKeys';
import { getApiKeysList } from '../../actions/api_keys';
import { bindActionCreators } from 'redux';
import ApiKeysFilter from '../../components/ApiKeysBox/ApikeysFilter';
import { startLoader, stopLoader } from '../../actions/loader';
import { setErrorMessage, setSuccessMessage } from '../../actions/error_msg';
import { currentApiKeyPageNum, recordsPerPage } from '../../actions/apikeysfilters';
import NoList from '../../components/ApiKeysBox/NoApiKeys';
import Grid from '@material-ui/core/Grid';
import { LOCAL_STORAGE_KEYS } from '../../constants/constant';
import { EmitterService } from "../../services/emitterService";

class ApiKeys extends Component {

    container = React.createRef();
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            active1: false,
            apiKeyError: false,
            sorted: false,
            totalLists: 0,
            val: "",
            showDropDown: false
        }
    }
    componentWillMount() {
        EmitterService.emit("loadLeadsRemaining");
        this.ApiKeys()
        document.addEventListener("mousedown", this.clickEventHandle)
    }
    componentWillUnmount() {
        const { setErrorMessage } = this.props
        setErrorMessage(false)
        this.setState({ apiKeyError: false })
        document.removeEventListener("mousedown", this.clickEventHandle)
    }
    
    clickEventHandle = (event) => {
        const { showDropDown, active1, active } = this.state
        if (this.container.current && !this.container.current.contains(event.target)) {
            if (showDropDown) {
                this.paginationPopup()
            }
        } if ((active || active1) && !event.target.closest('.react-calendar')) {
            this.setState({ active: false, active1: false })
        }
    }
    paginationPopup = async () => {
        this.setState({ showDropDown: !this.state.showDropDown })
    }
    ApiKeys = async (newKey, sorted) => {
        const { apiKeysLists, startLoader, stopLoader, setErrorMessage, apiKeysFilter, getApiKeysList } = this.props
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        if (newKey !== undefined && newKey !== "") {
            this.props.getApiKeysList([newKey, ...apiKeysLists.apiKeysLists])
            this.setState({ totalLists: apiKeysLists.apiKeysLists.length + 1 })

        } else {
            this.setState({ sorted })
            try {
                startLoader(true)
                const res = await getApiKeys(userId.id, apiKeysFilter)
                await getApiKeysList(res.data.data.apikeys)
                this.setState({ totalLists: res.data.data.total_number_of_records })
            }
            catch (error) {
                await setErrorMessage(true)
                this.setState({ apiKeyError: true })
            }
            stopLoader(false)
        }
    }
    paginate = async (num) => {
        window.scrollTo(0, 0);
        const { currentApiKeyPageNum, getApiKeysList } = this.props
        await currentApiKeyPageNum(num)
        await getApiKeysList('')
        this.ApiKeys()
    }
    incOrDecNum = (sign, indexOfLastPage) => {
        const { apiKeysFilter } = this.props
        let currentPage = apiKeysFilter.pageNum
        if (sign === "-" && currentPage > 1) {
            currentPage = currentPage - 1
            this.paginate(currentPage)
        } else if (sign === "+" && currentPage < indexOfLastPage) {
            currentPage = currentPage + 1
            this.paginate(currentPage)
        }
    }
    updatePerPage = async (sign, num) => {
        let integer = parseInt(num);
        const { recordsPerPage } = this.props
        if (sign === "+" && integer < 100) {
            await recordsPerPage(integer + 25)
            this.ApiKeys()
        } else if (sign === "-" && integer > 25) {
            await recordsPerPage(integer - 25)
            this.ApiKeys()
        } else if (sign === '') {
            this.setState({ showDropDown: !this.state.showDropDown })
            await recordsPerPage(integer)
            this.ApiKeys()
        }
    }


    calendarOpen = (open) => {
        this.setState({
            active1: open === "active1",
            active: open === "active"
        })
    }

    closeDateCreatedCalendar = () => {
        this.setState({
            active1: false
        })
    }

    closeDateDeactivatedCalendar = () => {
        this.setState({
            active: false
        })
    }

    renderTable = () => {

    }


    render() {
        const { apiKeysLists, loader, user, apiKeysFilter, history } = this.props
        const { sorted, apiKeyError, totalLists, showDropDown, active, active1 } = this.state
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT));

        const links = [
            {
              name: 'Settings',
              slug: '/api-keys',
            },
            {
              name: 'API',
              slug: null,
            },
        ]

        return (
            <Fragment>
                <BreadCrumb links={links}></BreadCrumb>
                <div className="apiKey-panel">
                    {userId === null && this.props.history.push('/signin?path=/api-keys')}

                    {userAccount.status === "trial_expired" && history.push('/billing-and-users')}

                    {(user.errorDiv && apiKeyError) &&
                        <div className="error-div alert-div">
                            <img className="add-list__close" src="/assest/apiKeys/Close_red.svg" alt="" onClick={() => this.setState({ apiKeyError: !apiKeyError })} />
                            <p>Sorry, we were unable to retrieve your API KEYS. Please try again later or contact help@leadfuze.co for further assistance.</p>
                        </div>
                    }

                    <div className="apiKey-panel__left">
                        
                        <ApiKeysTable apiKeysLists={apiKeysLists} ApiKeys={this.ApiKeys} />

                        {!loader.loading && !sorted && apiKeysLists.apiKeysLists && apiKeysLists.apiKeysLists.length === 0 &&
                            <NoList msg={"You haven't generated any api keys yet."} submsg={`Click on the "+Add New Key" to generate your first key.`} />
                        }

                        {sorted && apiKeysLists.apiKeysLists && apiKeysLists.apiKeysLists.length === 0 &&
                            <NoList msg={"We found 0 keys for your search."} submsg={`Please try modifying your search criteria.`} />
                        }

                        {// && totalLists > apiKeysFilter.recordsPerPage 
                        apiKeysLists.apiKeysLists && apiKeysLists.apiKeysLists.length > 0 &&
                            <Pagination totalLists={totalLists}
                                recordsPerPage={apiKeysFilter.recordsPerPage}
                                currentPageNum={apiKeysFilter.pageNum}
                                paginate={this.paginate}
                                incOrDecNum={this.incOrDecNum}
                                updatePerPage={this.updatePerPage}
                                paginationPopup={this.paginationPopup}
                                showDropDown={showDropDown}
                                container={this.container} />
                        }
                    </div>

                    <div className="apiKey-panel__right">
                        <RightPanelBanner title={'API Keys Demo'} page="apiKeys" type="api_keys" video_id="uoi5mp9r8n"/>
                        
                        {/* <div class="wistia_responsive_padding" style={{padding:'56.25% 0 0 0', position: 'relative'}}>
                            <div class="wistia_responsive_wrapper" style={{height: '100%', left:0, position: 'absolute', top: 0, width:'100%'}}>
                                <span class="wistia_embed wistia_async_uoi5mp9r8n popover=true popoverAnimateThumbnail=true videoFoam=true" 
                                style={{display:'inline-block', height:'100%', position: 'relative', width: '100%'}}>&nbsp;</span>
                                </div>
                        </div> */}
                        <ApiKeysFilter
                            calendarOpen={this.calendarOpen}
                            ApiKeys={this.ApiKeys}
                            active={active}
                            active1={active1}
                            closeDateCreatedCalendar={this.closeDateCreatedCalendar}
                            closeDateDeactivatedCalendar={this.closeDateDeactivatedCalendar}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getApiKeysList,
        startLoader,
        stopLoader,
        setErrorMessage,
        setSuccessMessage,
        currentApiKeyPageNum,
        recordsPerPage
    }, dispatch);
}
function mapStateToProps(state) {
    return {
        apiKeysLists: state.apiKeysLists,
        loader: state.loader,
        user: state.user,
        apiKeysFilter: state.apiKeysFilter
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ApiKeys)
