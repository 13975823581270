import { LocationType } from "./constant";
import { industry_groups } from "../tags/industry_groups";
import { department_groups } from "../tags";



export function convertTechnologiesFromPropToStats(technologies) {
  const totalTechs = [];
  if (
    technologies.include &&
    technologies.include.names &&
    technologies.include.names.length
  ) {
    const includedTechs = technologies.include.names.map(tech => {
      return {
        category: tech,
        technologies: [],
        label: tech,
        excludeGroup: false,
        isGroup: false,
        isTechName: true
      };
    });
    totalTechs.push(...includedTechs);
  }
  if (
    technologies.include &&
    technologies.include.groups &&
    technologies.include.groups.length
  ) {
    const includedTechs = technologies.include.groups.map(tech => {
      return {
        category: tech,
        technologies: [],
        label: tech,
        excludeGroup: false,
        isGroup: true,
        isTechName: false
      };
    });
    totalTechs.push(...includedTechs);
  }
  if (
    technologies.exclude &&
    technologies.exclude.names &&
    technologies.exclude.names.length
  ) {
    const excludedTechs = technologies.exclude.names.map(tech => {
      return {
        category: tech,
        technologies: [],
        label: tech,
        excludeGroup: true,
        isGroup: false,
        isTechName: true
      };
    });
    totalTechs.push(...excludedTechs);
  }
  if (
    technologies.exclude &&
    technologies.exclude.groups &&
    technologies.exclude.groups.length
  ) {
    const excludedTechs = technologies.exclude.groups.map(tech => {
      return {
        category: tech,
        technologies: [],
        label: tech,
        excludeGroup: true,
        isGroup: true,
        isTechName: false
      };
    });
    totalTechs.push(...excludedTechs);
  }

  return totalTechs;
}

export function getIndustriesLable(category){
  
  const industry = industry_groups.find(item => item.category === category);
  return industry ? industry.label : category;
};

export function getDepartmentLable(category){
  const department = department_groups.find(item => item.category === category);
  return department ? department.label : category;
};

export function convertTechnologiesFromStateToProps(marketTechnologies) {
  const technology = {
    include: {
      names: [],
      groups: [],
    },
    exclude: {
      names: [],
      groups: [],
    }
  };
  marketTechnologies && marketTechnologies.map(tech => {
    // tech.group and tech.category have the same value
    if(tech.isTechName && tech.excludeGroup) {
      technology.exclude.names.push(tech.category);
    } else if(tech.isTechName) {
      technology.include.names.push(tech.category);
    }
    if(!tech.isTechName && tech.excludeGroup) {
      technology.exclude.groups.push(tech.category);
    } else if(!tech.isTechName) {
      technology.include.groups.push(tech.category);
    }
  });

  return technology;

}

export function convertLocationFromStateToProps(marketLocations, excludeGroup = false) {
  const location = {
    cities: [],
    states: [],
    metros: [],
    countries: [],
    group: [],
    continents: [],
    keywords: [],
  };
  marketLocations && marketLocations.map(tech => {
    if(tech.excludeGroup !== excludeGroup) return;
    if(tech.type === LocationType.City) {
      location.cities.push({
        city: tech.keyword.split("|")[0],
        state: tech.keyword.split("|")[1],
      })
    } else if(tech.type === LocationType.State) {
      location.states.push({
        state: tech.keyword.split("|")[0],
        country: tech.keyword.split("|")[1],
      })
    } else if(tech.type === LocationType.Metro) {
      location.metros.push({
        metro: tech.keyword.split("|")[0],
        state: tech.keyword.split("|")[1],
      })
    } else if(tech.type === LocationType.Country) {
      location.countries.push(tech.keyword);
    } else if(tech.type === LocationType.Group) {
      location.group.push(tech.keyword);
    } else if(tech.type === LocationType.Continent) {
      location.continents.push(tech.keyword);
    } else if(tech.type === LocationType.Keyword) {
      location.keywords.push(tech.keyword);
    }
  });
  return location;
}
export function parseResponseLocation(include_locations, extends_locations){
  const location = {
    cities: [],
    states: [],
    metros: [],
    countries: [],
    group: [],
    continents: [],
    keywords: [],
  };

  //excludeGroup
  include_locations.cities && include_locations.cities.map(tech => {
    location.cities.push({...tech, excludeGroup: false});
  });
  include_locations.states && include_locations.states.map(tech => {
    location.states.push({...tech, excludeGroup: false});
  });
  include_locations.metros && include_locations.metros.map(tech => {
    location.metros.push({...tech, excludeGroup: false});
  });

  include_locations.countries && include_locations.countries.map(tech => {
    location.countries.push({countryName : tech, excludeGroup: false});
  });
  include_locations.group && include_locations.group.map(tech => {
    location.group.push({groupName: tech, excludeGroup: false});
  });
  include_locations.continents && include_locations.continents.map(tech => {
    location.continents.push({continentName: tech, excludeGroup: false});
  });
  include_locations.keywords && include_locations.keywords.map(tech => {
    location.keywords.push({keywordName: tech, excludeGroup: false});
  });



  extends_locations.cities && extends_locations.cities.map(tech => {
    location.cities.push({...tech, excludeGroup: true});
  });
  extends_locations.states && extends_locations.states.map(tech => {
    location.states.push({...tech, excludeGroup: true});
  });
  extends_locations.metros && extends_locations.metros.map(tech => {
    location.metros.push({...tech, excludeGroup: true});
  });
  extends_locations.countries && extends_locations.countries.map(tech => {
    location.countries.push({countryName : tech, excludeGroup: true});
  });
  extends_locations.group && extends_locations.group.map(tech => {
    location.group.push({groupName: tech, excludeGroup: true});
  });
  extends_locations.continents && extends_locations.continents.map(tech => {
    location.continents.push({continentName: tech, excludeGroup: true});
  });
  extends_locations.keywords && extends_locations.keywords.map(tech => {
    location.keywords.push({keywordName: tech, excludeGroup: true});
  });
  


  
  return location;
}

export function parseResponseIndestries(include_Indestries, extends_Indestries){
  const indestries = {include_keywords : [] , exclude_keywords : []};
  indestries.include_keywords = include_Indestries ? include_Indestries : [];
  indestries.exclude_keywords = extends_Indestries ? extends_Indestries : [];

  return indestries;
}

export function parseResponseDepartments(include_Indestries, extends_Indestries){
  const indestries = {include_keywords : [] , exclude_keywords : []};
  indestries.include_keywords = include_Indestries ? include_Indestries : [];
  indestries.exclude_keywords = extends_Indestries ? extends_Indestries : [];

  return indestries;
}

/* export function convertExcludeLocationFromStateToProps(marketLocations) {
  const location = {
    cities: [],
    states: [],
    metros: [],
    countries: [],
    group: [],
    continents: [],
    keywords: [],
  };
  marketLocations && marketLocations.map(tech => {
    if(!tech.excludeGroup) return;
    if(tech.type === LocationType.City) {
      location.cities.push({
        city: tech.keyword.split("|")[0],
        state: tech.keyword.split("|")[1],
      })
    } else if(tech.type === LocationType.State) {
      location.states.push({
        state: tech.keyword.split("|")[0],
        country: tech.keyword.split("|")[1],
      })
    } else if(tech.type === LocationType.Metro) {
      location.metros.push({
        metro: tech.keyword.split("|")[0],
        state: tech.keyword.split("|")[1],
      })
    } else if(tech.type === LocationType.Country) {
      location.countries.push(tech.keyword);
    } else if(tech.type === LocationType.Group) {
      location.group.push(tech.keyword);
    } else if(tech.type === LocationType.Continent) {
      location.continents.push(tech.keyword);
    } else if(tech.type === LocationType.Keyword) {
      location.keywords.push(tech.keyword);
    }
  });
  return location;
}
 */
export function convertLocationFromPropsToStats(marketLocations) {
  const location = [];
    marketLocations.cities && marketLocations.cities.map(city => {
      location.push({excludeGroup: city.excludeGroup, type: LocationType.City, keyword: `${city.city}|${city.state}`});
    });
    marketLocations.states && marketLocations.states.map(state => {
      location.push({excludeGroup: state.excludeGroup, type: LocationType.State, keyword: `${state.state}|${state.country}`});
    });
    marketLocations.metros && marketLocations.metros.map(metro => {
      location.push({excludeGroup: metro.excludeGroup, type: LocationType.Metro, keyword: `${metro.metro}|${metro.state}`});
    });
    marketLocations.countries && marketLocations.countries.map(country => {
      location.push({excludeGroup: country.excludeGroup, type: LocationType.Country, keyword: country.countryName});
    });
    marketLocations.group && marketLocations.group.map(group => {
      location.push({excludeGroup: group.excludeGroup, type: LocationType.Group, keyword: group.groupName});
    });
    marketLocations.continents && marketLocations.continents.map(continent => {
      location.push({excludeGroup: continent.excludeGroup, type: LocationType.Continent, keyword: continent.continentName});
    });
    marketLocations.keywords && marketLocations.keywords.map(keyword => {
      location.push({excludeGroup: keyword.excludeGroup, type: LocationType.Keyword, keyword: keyword.keywordName});
    });
  return location;
}



export function getPeopleLookupObjectFromProps(marketBased, filterOptions) {
  const marketBasedObj = getOnlyMarketPlaceObjectFromProps(marketBased, filterOptions);
  const object = {
    ...filterOptions,
    ...marketBasedObj
  };
  return object;
}

export function getOnlyMarketPlaceObjectFromProps(marketBased, filterOptions) {
 

  const technology = marketBased.technology && marketBased.technology.include && marketBased.technology.exclude ? marketBased.technology : convertTechnologiesFromStateToProps(
    marketBased.technology
  );
  const people_location = convertLocationFromStateToProps(marketBased.personLocation);
  const exclude_people_location = convertLocationFromStateToProps(marketBased.personLocation, true);

  const company_location = convertLocationFromStateToProps(marketBased.companyLocation);
  const exclude_company_location = convertLocationFromStateToProps(marketBased.companyLocation, true);
  
  const industries = marketBased.industries2 && marketBased.industries2.include_keywords ? 
    marketBased.industries2.include_keywords : [];
  const exclude_industries = marketBased.industries2 && marketBased.industries2.exclude_keywords ? 
    marketBased.industries2.exclude_keywords : []; 
    
  const department = marketBased.departments && marketBased.departments.include_keywords ? 
    marketBased.departments.include_keywords : [];
  const exclude_department = marketBased.departments && marketBased.departments.exclude_keywords ? 
    marketBased.departments.exclude_keywords : []; 

  const estimated_salary = marketBased.estimatedSalary ? marketBased.estimatedSalary.map(salary => salary.group) : [];
  let employees = {};
  if (marketBased && marketBased.employees) {
    employees = marketBased.employees;
    delete employees.openOrClose;
  }

  let marketBasedObj = {
    ...marketBased,
    industries,
    exclude_industries,
    department,
    exclude_department,
    technology,
    employees,
    company_location,
    exclude_people_location,
    people_location,
    exclude_company_location,
    estimated_salary,
    time_latest_job: marketBased.timeInLatestJob ? marketBased.timeInLatestJob.map(tlj => tlj.group) : [],
    years_of_experience: marketBased.yearsOfExperience ? marketBased.yearsOfExperience.map(yof => yof.group) : [],
    founded_date_from: (filterOptions && filterOptions.founded_date_from) ? parseInt(filterOptions.founded_date_from) : 0,
    founded_date_to: (filterOptions && filterOptions.founded_date_to) ? parseInt(filterOptions.founded_date_to) : 0
  };
  delete  marketBasedObj.industries2;
  delete marketBasedObj.departments;
  return marketBasedObj;
}


function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 6)}`;
  }

  return clearValue;
}


export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }
  const clearValue = clearNumber(value);
  let nextValue;
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8,
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
  return nextValue.trim();
}


export function formatCVC(value) {
  const clearValue = clearNumber(value);
  let maxLength = 4;
  return clearValue.slice(0, maxLength);
}

export const numberFormatter = new Intl.NumberFormat()
export const billingFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })