import React, { Component } from 'react';
import './style.scss';

class CancelFeedbackPopup extends Component {

  state = {
    tellUsMore: '',
    selectedFeedbackOption: "Didn’t have my leads",
    feedbackOptions: ['Didn’t have my leads', 'Too complicated', 'Too expensive', 'Didn’t have the features I need', 'I don’t need the app anymore', 'Data quality', 'Other'],
  }

  onChangeTellUsMore = (e) => {
    this.setState({tellUsMore: e.target.value});
  }

  onCancelAccount = () => {
    this.props.showCancelFeedbackPopup(false);
  }

  onCancelSubscription = () => {
    this.props.onCancelSubscription(this.state.selectedFeedbackOption +'. ' + this.state.tellUsMore);
  }

  onChangeFeedbackOption = (option) => {
    this.setState({selectedFeedbackOption: option});
  }
  
  render() {
    return(
      <div className="popup-pause popup-feed">
        <div className="popup-feed__container">
          <img className="popup-pause__close" src="/assest/accountBased/close.svg" alt=""  onClick={() => this.props.showCancelFeedbackPopup(false)}/>
          <p className="popup-pause__title">Your feedback helps us improve the product and is greatly appreciated</p>
          
          <div className="popup-feed__row">
            <div className="popup-feed__col">
              <p className="popup-feed__subtitle">Why are you canceling? </p>
              <ul className="popup-feed__list">
                {this.state.feedbackOptions.map((item, index)=>
                    <li className="popup-feed__item" key={index} onClick={() => this.onChangeFeedbackOption(item)}>
                      <div className={`popup-feed__check ${item === this.state.selectedFeedbackOption ? 'active' : ''}`}><span></span></div>
                      <p>{item}</p>
                    </li>
                  )
                }
              </ul>
            </div>

            <div className="popup-feed__col right">
              <p className="popup-feed__subtitle">Tell us more...</p>
              <textarea value={this.state.tellUsMore} placeholder="Please write your feedback" onChange={(e) => this.onChangeTellUsMore(e)}></textarea>
            </div>
          </div>
          
          <div className="popup-pause__btn " onClick={this.onCancelSubscription}>Cancel My Account</div>
        </div>
      </div>
    )
  }
}

export default CancelFeedbackPopup;