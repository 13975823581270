import React, { Component, Fragment } from 'react';
import { Uploads } from '../../../../components';
import FileDrop from 'react-file-drop'
import './style.scss'
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import Loader from '../../../Loader';
import { freshSalesGetUsers } from '../../../../services/integrations';

class UploadBox extends Component {

    static allowedFileExtensions = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/plain', 'text/csv']
    constructor(props) {
        super(props)
        this.state = {
            selectedFiles: null,
            droped: false,
            uploadSuccess: false,
            uploadError: false,
            errormsg: null,
            accountErrorMsg: false
        }
    }

    onDrop(files) { 
        if(files && files[0]) {
            this.handleFile(files[0]);
        } 
    };
    
    onFileChoose = (e) => {
        const files = e.target.files;
        if(files && files[0]) {
            const { uploadLinkedinPopup } = this.props;
            if (uploadLinkedinPopup) {
                this.handleLinkedinUploadFile(files[0]);
            } else {
                this.handleFile(files[0]);
            }
        }
    };

    clearUploadError = () => {
        this.setState({uploadError: '', droped: false});
    }
    
    handleFile = (file/*:File*/) => {
        const validFileTypeIndex = Uploads.allowedFileExtensions.findIndex(allowedExt => allowedExt === '' + file.type);
        if (validFileTypeIndex < 0) {
            this.setState({uploadError: 'Invalid file extension', droped: false, uploadSuccess: false,accountErrorMsg : true, errormsg: 'File format not supported.'});
            return;
        } else {
            this.setState({droped: true});
            this.file = file;
                /* Boilerplate to set up FileReader */
                const reader = new FileReader();
                const rABS = !!reader.readAsBinaryString;
                reader.onload = (e) => {
                    /* Parse data */
                    const bstr = e.target.result;
                    // const wb = XLSX.read(bstr, {type:rABS ? 'binary' : 'array'});
                    const wb = XLSX.read(bstr, {type:validFileTypeIndex === 0 ? 'binary' : 'string'});
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    /* Convert array of arrays */
                    const data = XLSX.utils.sheet_to_json(ws, {header:1});
                    const allCompanies = [];
                    data.map(rows => {
                        allCompanies.push(...rows);
                    });

                    if(allCompanies.length > 2000) {
                        this.setState({uploadError: 'Bulk Size Limit Exceed', droped: false, uploadSuccess: false, accountErrorMsg: true, errormsg: 'You are trying to upload more than 2000 companies.'});
                        return;
                    }
                    const requiredCompanies = allCompanies.slice(0,2000);
                    this.props.setCompanyTags(requiredCompanies);
            
                // this.props.saveData(data);
                // const cols = makeCols(data[0]);
                // this.fileHeadersSelectedSet = [];
                    /* Update state */
                    // this.setState({ data: data, cols, datasourceName: this.file.name });
                };
            
            if(rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
            setTimeout(() => {
                if(this.state.accountErrorMsg)
                    return;
                toast.success('File uploaded successfully', {
                    className: "toast-success-div",
                    autoClose: 2000
                })
                this.setState({uploadError: '', droped: false, uploadSuccess: true});
                this.closePopup();
            }, 2000)
        }
        
    };

    handleLinkedinUploadFile = (file/*:File*/) => {
        const validFileTypeIndex = Uploads.allowedFileExtensions.findIndex(allowedExt => allowedExt === '' + file.type);
        if (validFileTypeIndex < 0) {
            this.setState({uploadError: 'Invalid file extension', droped: false, uploadSuccess: false,accountErrorMsg : true, errormsg: 'File format not supported.'});
            return;
        } else {
            this.setState({droped: true});
            this.file = file;
                /* Boilerplate to set up FileReader */
                const reader = new FileReader();
                const rABS = !!reader.readAsBinaryString;
                reader.onload = (e) => {
                    /* Parse data */
                    const bstr = e.target.result;
                    // const wb = XLSX.read(bstr, {type:rABS ? 'binary' : 'array'});
                    const wb = XLSX.read(bstr, {type:validFileTypeIndex === 0 ? 'binary' : 'string'});
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    /* Convert array of arrays */
                    const data = XLSX.utils.sheet_to_json(ws, {header:1});
                    const allCompanies = [];
                    data.map(rows => {
                        allCompanies.push(...rows);
                    });

                    if(allCompanies.length > 2000) {
                        this.setState({uploadError: 'Bulk Size Limit Exceed', droped: false, uploadSuccess: false, accountErrorMsg: true, errormsg: 'You are trying to upload more than 2000 rows.'});
                        return;
                    }
                    const requiredCompanies = allCompanies.slice(0,2000);
                    this.props.setLinkedinUrlCompany(requiredCompanies);
            
                // this.props.saveData(data);
                // const cols = makeCols(data[0]);
                // this.fileHeadersSelectedSet = [];
                    /* Update state */
                    // this.setState({ data: data, cols, datasourceName: this.file.name });
                };
            
            if(rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
            setTimeout(() => {
                if(this.state.accountErrorMsg)
                    return;
                toast.success('File uploaded successfully', {
                    className: "toast-success-div",
                    autoClose: 2000
                })
                this.setState({uploadError: '', droped: false, uploadSuccess: true});
                this.closePopup();
            }, 2000)
        }
        
    };

    closePopup = () => {
        this.setState({
            selectedFiles: null,
            droped: false,
            uploadSuccess: false,
            uploadError: false,
            accountErrorMsg: false
        });
        const { uploadLinkedinPopup } = this.props;
        if (uploadLinkedinPopup) {            
            this.props.onCloseLinkedinUploadPopup();
        } else {
            this.props.onCloseUploadPopup();
        }

    
    }
    
    render() {
        const { uploadPopup, uploadLinkedinPopup } = this.props;
        const { droped, uploadSuccess, uploadError, accountErrorMsg, errormsg } = this.state;

        return (
            <Fragment>
                <div className={`add-list ${(uploadPopup || uploadLinkedinPopup) ? 'open' : ''}`}>
                    <div className="add-list__content">
                        <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={this.closePopup} />
                        {uploadPopup && (<p className="add-list__title">Upload Companies</p>)}
                        {uploadLinkedinPopup && (<p className="add-list__title">Upload Bulk Linkedin</p>)}
                        {/* <div className="uploads">
                            <h3 className="uploads__title">Bulk Uploads</h3> */}
                            {!uploadSuccess && !uploadError &&
                                < FileDrop onDrop={(file) => this.onDrop(file)}>
                                <div className="uploads__box">
                                    {droped &&
                                    <Loader uploading={droped} />
                                    }
                                    <p className="uploads__descr">Drag & Drop file here</p>
                                    <p className="uploads__text">or</p>
                                    <label htmlFor="forFileUpload" className="uploads__btn">Add File</label>
                                    <input type="file" id="forFileUpload" accept=".csv,text/plain,.xlsx" onChange={(file) => this.onFileChoose(file)} />
                                    <p className="uploads__types">Upload a .txt,.csv, or .xls file</p>
                                    {uploadPopup && (<p className="uploads__types">Can't upload more than 2000 companies</p>)}
                                    {uploadLinkedinPopup && (<p className="uploads__types">Can't upload more than 2000 linkedin urls</p>)}
                                </div>
                                </FileDrop>
                            }
                            {uploadSuccess &&
                                <div className="uploads__success-box">
                                <img src="/assest/apiKeys/check-symbol.svg" alt="" />
                                <p>File Uploaded Successfully</p>
                                </div>
                            }
                            {uploadError && (accountErrorMsg ?
                                <div className="uploads__error-box" >
                                <img src="/assest/apiKeys/Close_red.svg" alt="" />
                                <p>{errormsg} <span className="underline" onClick={() => this.clearUploadError()}>Try Again</span></p>
                                </div> :
                                <div className="uploads__error-box" onClick={() => this.clearUploadError()}>
                                <img src="/assest/apiKeys/Close_red.svg" alt="" />
                                <p>{errormsg} Try Again</p>
                                </div>)
                            }
                            <div className="download-icon">

                                <p>
                                    {uploadPopup && (<a href="/assest/sample_file.csv" download="sample_file.csv"><img src="/assest/Download.svg" alt="" />Download Sample</a>)}
                                    {uploadLinkedinPopup && (<a href="/assest/sample_bulk_linkedin.csv" download="sample_bulk_linkedin.csv"><img src="/assest/Download.svg" alt="" />Download Linkedin Sample</a>)}
                                </p>
                            </div>
                        {/* </div> */}
                        {/* <p className="upload-description">Can't upload more than 2000 companies</p> */}
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default UploadBox;
