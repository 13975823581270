import React, { Fragment, Component } from 'react';
import { Loader, SearchResultItem } from '../../../../../components';
import AddToList from './../../../ContentResult/AddToList';

class EmployeesList extends Component {
  
  state = {
    selectedList: null,
  };

  selectList = (list) => {
    this.setState({selectedList: list});
  }

  render() {
    const { items, selectItem, skipLead, unSkipSingleLead, popup, openAddLeadToListPopup, closeAddToListPopup, showSearchResultItemDetail, leadDetail, addLeadsToList } = this.props;

    return(
      <Fragment>
        {this.props.showLoader && <Loader />}

        { 
          (items && items.length ? items.map((item, index)=> 
            <Fragment key={index}>
              <SearchResultItem 
                item={item} 
                checked={item.check} 
                added={item.addedToList}
                selectItem={selectItem} 
                skipLead={skipLead}
                unSkipSingleLead={unSkipSingleLead}
                popup={popup}
                openAddLeadToListPopup={openAddLeadToListPopup}
                showSearchResultItemDetail={showSearchResultItemDetail}
                leadDetail={leadDetail}
                showLeadDetailLoader={this.props.showLeadDetailLoader}
                showTabs={false} />
            </Fragment>
          ) : '')
        }

        { (!this.props.showLoader && (!items || items.length == 0)) && <h2 className="no-found">No results found matching this search criteria.</h2>}

        {popup && 
          <AddToList closePopup={closeAddToListPopup} addLeadsToList={addLeadsToList} selectedList={this.state.selectedList} selectList={this.selectList} popup={popup} paddingTop={90}/> 
        }

      </Fragment>
    )
  }
}

export default EmployeesList;