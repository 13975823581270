import React, { Fragment } from "react";

const CollegeField = props => {
  const {
    openPanel,
    count,
    colleges,
    delGroupSelect,
  } = props;
  return (
    <div className="people-market-field">
      <div className="people-market-field__box">
        {colleges.map(
            (item, index) =>
                <Fragment key={index}>
                  <p
                    className="people-market-field__group"
                    onClick={() => openPanel("edit")}
                  >
                    {item.trim()}
                    <img
                  src="/assest/accountBased/del-tag.svg"
                  alt=""
                  onClick={e => delGroupSelect(e, item)}
                />
                  </p>
                </Fragment>
          )
          }

      </div>

    </div>
  );
};

export default CollegeField;
