import React, { Component } from "react";
import "../style.scss";
import CheckDropdown from "./CheckDropdown";
import InsideSelect from "./InsideSelect";
import items from "./items";
import AddToList from "../../SearchResultBox/ContentResult/AddToList";
import { numberFormatter } from "../../../constants/helper";

class InsideListHeader extends Component {
  insideSelectMenu;
  state = {
    insideSelectDropdown: false,
    checkDropdown: false,
    movePopup: false,
    sendPopup: false,
    selectedList: null,
  }

  componentDidMount() {
    this.insideSelectMenu = React.createRef();
    var insideCheckDropdown = document.getElementsByClassName('inside-list-header__check')[0];
    document.body.addEventListener('click', this.closeInsideSelectDropdown);
    /* insideCheckDropdown.addEventListener('click', (e) => {
      e.stopPropagation();
      this.setState({checkDropdown: true});
    }); */
  }

  closeInsideSelectDropdown = (e) => {
    if (this.insideSelectMenu.current && !this.insideSelectMenu.current.contains(e.target)) {
      this.setState({ insideSelectDropdown: false, checkDropdown: false, });
    }
  }

  componentWillUnmount = () => {
    document.body.removeEventListener('click', this.closeInsideSelectDropdown);
  }

  selectList = (list) => {
    this.setState({ selectedList: list });
  }

  changeCheck = (e) => {
    e.stopPropagation();
    this.setState({ checkDropdown: !this.state.checkDropdown })
  }

  changeCheckDropdown = (e) => {
    e.stopPropagation();
    this.setState({ insideSelectDropdown: !this.state.insideSelectDropdown });
  }

  closeAddToListPopup = () => {
    this.setState({ movePopup: false, sendPopup: false });
  }

  showMoveToList = () => {
    this.setState({ movePopup: true, sendPopup: false });
  }

  showSendToList = () => {
    this.setState({ movePopup: false, sendPopup: true });
  }

  addLeadsToList = async (list) => {
    this.closeAddToListPopup();
    await this.props.moveBulkSelectedToList(list._id);
  }

  deleteBulkSelectedLeads = (list) => {
    // await this.props.deleteBulkSelected(list.id);
  }

  switchSort = (name) => {
    if (name) {
      this.props.switchSort(name);
    }
  }



  render() {
    return (
      <section>
        <div className="inside-list__title">
          <h3>
            {this.props.hotlead ? 'Hot Leads' : 'Leads'}
          </h3>
          {this.props.totalLeads > 0 && <span className="item-count">{numberFormatter.format(this.props.totalLeads)} Leads matching filter</span>}
          {((this.props.selectedLeads.length || this.props.allLeads) && !this.props.hotlead) ? (
            <div className="inside-list__links" id="insideListDropdown"
              ref={this.insideSelectMenu}>
              <InsideSelect
                insideSelectDropdown={this.state.insideSelectDropdown}
                {...this.props}
                deleteBulkSelectedLeads={this.deleteBulkSelectedLeads}
                showMoveToList={this.showMoveToList}
                showSendToList={this.showSendToList}
              />
            </div>) : null
          }
        </div>
        <div className="inside-list-header">
          <div className="inside-list-header__row">
            {!this.props.hotlead && <div id="insideCheckDropdown" className="inside-list-header__check" onClick={(e) => this.changeCheck(e)}>
              <div className={`check ${this.props.allLeads || this.props.leadsInView || this.props.newLeads ? 'active' : ''}`}></div>
              <img
                className="inside-list-header__arr"
                src="/assest/arr.svg"
                alt=""
              />
              <CheckDropdown checkDropdown={this.state.checkDropdown} {...this.props} />
            </div>}
            <div className="inside-list-header__items">
              {items.map((item, index) => (
                <div
                  className={`inside-list-header__col ${item.clname}`}
                  key={index} onClick={() => this.switchSort(item.sortName)}
                >
                  <p className={`inside-list-header__link ${item.sort ? 'sortable' : ''}`}>{item.name}</p>
                  {item.sort ?
                    (this.props.sortingColumn === item.sortName ? (this.props.sortOrder === 'asc' ? (<img src="/assest/accountBased/sort_asc.svg" alt="" />) : (<img src="/assest/accountBased/sort_desc.svg" alt="" />))
                      : <img src="/assest/accountBased/sort.svg" alt="" />)
                    : ''}
                </div>
              ))}
            </div>
            <ul className="inside-list-list__nav"></ul>
          </div>
        </div>

        {(this.state.movePopup || this.state.sendPopup) &&
          <AddToList heading={this.state.movePopup ? 'Move to List' : 'Send to List'} closePopup={this.closeAddToListPopup} addLeadsToList={this.addLeadsToList} selectedList={this.state.selectedList} selectList={this.selectList} popup={this.state.movePopup || this.state.sendPopup} />
        }
      </section>
    );
  }
}
export default InsideListHeader;
