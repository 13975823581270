import { combineReducers } from "redux";
import user from "../reducers/reducer_user";
import loader from "../reducers/loader";
import leadFilter from "../reducers/leadfilters";
import { reducer as formReducer } from "redux-form";
import adminUsers from "../reducers/admin_users";
import leadList from "../reducers/lead_users";
import searchResults from "../reducers/search_results";
import integration from "../reducers/integration";
import getApiKeysLists from "../reducers/api_keys_lists";
import apiKeysFilter from "../reducers/api_keys_filters";
import ignoreListFilter from "../reducers/ignore_list_filters";
import marketBased from "../reducers/market_based";
import billing from "../reducers/billing";
import userProfileInfo from "../reducers/profile_pic_update";
import accountBased from "../reducers/account_based";
import searchResultFilters from "../reducers/search_result_filters";
import insideListFilters from "../reducers/inside_list_filters";
import loadedData from "../reducers/loaded_data";
export const reducers = combineReducers({
  user: user,
  form: formReducer,
  loader: loader,
  leadFilter: leadFilter,
  adminUsers: adminUsers,
  leadList: leadList,
  integration: integration,
  apiKeysLists: getApiKeysLists,
  apiKeysFilter: apiKeysFilter,
  ignoreListFilter: ignoreListFilter,
  marketBased: marketBased,
  loadedData: loadedData,
  accountBased: accountBased,
  insideListFilters: insideListFilters,
  userProfileInfo: userProfileInfo,
  searchResultFilters: searchResultFilters,
  searchResults: searchResults,
  billing: billing,
});
