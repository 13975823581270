import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { validate } from '../../helpers/validate';
import { startLoader, stopLoader } from '../../actions/loader';
import { forgotPassword } from '../../services/users';
import {
  ForgotPassContent,
} from '../../components';
import './style.scss';
import { loginAttempts } from '../../actions/users';
import { LOCAL_STORAGE_KEYS } from '../../constants/constant';
import { EmitterService } from "../../services/emitterService";

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    startLoader,
    stopLoader,
    loginAttempts
  }, dispatch);
}

function mapStateToProps(state, ownProps) {
  return {
    loader: state.loader,
    user: state.user
  };
}

class ForgotPass extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isPasswordReset: false,
      responseError: false,
      wrongEmail: false,
      validationError: ""
    }
  }

  componentWillMount() {
    EmitterService.emit("loadLeadsRemaining");
  }

  onSubmit = (values) => {
    let error = validate(values)
    this.setState({ validationError: error })
    var that = this;
    const { loginAttempts, startLoader, stopLoader } = this.props;
    if (!error.email) {
      startLoader(true);
      forgotPassword(values).then(function (response) {
        if (response.data.errCode === 10700) {
          that.setState({ wrongEmail: true });
          stopLoader("signinFalse");
        } else {
          that.setState({ wrongEmail: false });
          loginAttempts(0)
          stopLoader("signinFalse");
          that.setState({ isPasswordReset: true });
        }
      }).catch(error => {
        stopLoader(false);
        that.setState({ responseError: true });
      });
    }
  }

  render() {
    const { history, loader, user } = this.props;
    const { wrongEmail, validationError, isPasswordReset, responseError } = this.state;
    const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))

    return (
      <section className="log">
        {!(userId === null) &&
          history.push("/lead-search/market-based")
        }
        <Grid
          container
          direction="row">
          <Grid item xs={12} sm={4} >
            <ForgotPassContent onSubmit={this.onSubmit}
              wrongEmail={wrongEmail}
              visible={loader.loading}
              isPasswordReset={isPasswordReset}
              responseError={responseError}
              isValidToken={user.validToken}
              validationError={validationError}
            />
          </Grid>
          <Grid item xs={12} sm={8} className="log-right">
            <img src="/assest/laptop.png" alt="" />
          </Grid>
        </Grid>
      </section>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPass);
