import React, { Component, Fragment } from 'react';
import '../style.scss';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { postLeads } from '../../../../services/leads';
import { LOCAL_STORAGE_KEYS } from '../../../../constants/constant';
import { showSuccessToast, showErrorToast } from '../../../../helpers/toast';

class AddLeadsToList extends Component {
  state = {
    value: "",
  }
  componentWillMount() {
    this.setState({
      value: this.props.addedLeadsPerDay
    });
  }
  handleChange = async (e) => {
    this.setState({ value: e.target.value, errorMessage: '' })
  }
  onUpdateClick = async (val) => {
    if (!val || val < 1) {
      this.setState({errorMessage: 'Number of leads should be greater than 0'});
      return;
  }
    const { id, leadList, openListPopup } = this.props
    var number = parseInt(val);
    let toastId = null;
    let errormsg
    toastId = toast('Updating fuzebot', {
      autoClose: 10000,
    });
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
    try {
      await postLeads(user.id, id, number)
      showSuccessToast(toastId, "Fuzebot updated successfully");
      openListPopup()
      let item = this.props.list;
      leadList();
      item.fuzebot.daily_leads = number;
      this.props.updateListItem(item);
    } catch (error) {
      if(!error.response) {
        errormsg = 'Unable to update Fuzebot'
      } else if (error.response.data.errCode === 30130) {
        errormsg = 'Sorry, we were unable to update your list. You are allowed to automate up to  leads daily'
      } else if (error.response.data.errCode === 30120) {
        errormsg = 'Permission denied'
      } else {
        errormsg = 'Unable to update Fuzebot'
      }
      showErrorToast(toastId, errormsg);
    }
  }
  onClosePopup = () => {
    const { openListPopup } = this.props
    openListPopup()
    this.setState({ value: "" })
  }

  render() {
    const { value, errorMessage } = this.state
    const { popup } = this.props;
    return (
      <Fragment>
        <div className={`add-list ${popup ? 'open' : ''}`}>
          <div className="add-list__content">
            <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={this.onClosePopup} />
            <p className="add-list__title">Edit Leads Added Per Day</p>
            <p className="add-list__label">Number of Daily Leads</p>
            <input className="add-list__search"
              placeholder="Enter number of daily Leads"
              type="number"
              value={value}
              onChange={this.handleChange}
              required />

              {errorMessage? (
                            <div className="lead-header__model_error"><p>{errorMessage}</p></div>) : null}
            <p className="add-list__create">
            </p>
            <div className="add-list__buttons">
              <div className="add-list__btn orange" onClick={() => { this.onUpdateClick(value) }} >Update</div>
              <div className="add-list__btn orange-light" onClick={this.onClosePopup}>Cancel</div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default AddLeadsToList;
