import React, {Component, Fragment} from 'react';
import moment from "moment";

class CompanyNews extends Component {

  state = {
    isShowAll: false,
  }

  formatDate(date) {
    return moment(date).format("MMMM D, YYYY");
  }


  showNext = () => {
    this.props.handlePagination(true);
  }

  showLast = () => {
    this.props.handlePagination(false);
  }


  render() {
    const news = this.props.leadDetail.news;
    if (news === null || news.entries === null || news.entries.length === 0) {
      return (<div></div>);
    }

    let hasPrevious = false;
    let hasMore = false;
    const newsEntries = [];

    if (news.entries && news.entries.length > 0) {
      newsEntries.push(...news.entries);
    }


    if (this.props.leadDetail.next_cursor.length > 0) {
      hasMore = true;
    }
    
    if (this.props.leadDetail.last_cursor.length > 0) {
      hasPrevious = true;
    }


    let sortedNewsEntries = newsEntries.sort(function(a, b) {
      return moment(b.date) - moment(a.date);
    });

    return(
      <div className="details company-news">
        <h5 className="company-title">Company News</h5>
        
        <div className="company-tab__row">
          {sortedNewsEntries.map((item, index) => 
            <Fragment key={`news-${index}`}>
              {
                <div className="company-tab__col">
                  <p className="company-tab__date">{this.formatDate(item.date)}</p>
                  <p className="company-tab__name">{item.headline}</p>
                  <a className="company-tab__link" href={item.url} target="_blank">Read More</a>
                </div>
              }
            </Fragment>
          )}
        </div>

        {/* { hasMore && 
          <div className="company-tab__btn" onClick={($event)=> this.props.openAddLeadToListPopup($event, this.props.leadDetail.id)}>Show more</div>
        } */}
        { 
          <div className={`company-tab__btn ${!hasPrevious ? 'company-disable' : ''}`} onClick={hasPrevious && this.showLast}>&lt;</div>
        }
        { 
          <div className={`company-tab__btn ${!hasMore ? 'company-disable' : ''}`} onClick={hasMore && this.showNext}>&gt;</div>
        }
      </div>
    )
  }
}

export default CompanyNews