import React, { Component } from 'react'
import tabs from './tabs'
import images from './images'
import {
  FindLeads1left,
  FindLeads2left,
  FindLeads3left,
  FindLeads4left,
} from '../../components'
import './style.scss'
import { EmitterService } from "../../services/emitterService";

class FindLeads extends Component{
  state = {
    tabs: tabs,
    fname: '',
    lname: '',
    pass: '',
    market: '',
    solution: ''
  }

  componentWillMount() {
    EmitterService.emit("loadLeadsRemaining");
  }

  getValue = (e) =>{
    const target = e.target
    const name = target.name
    const value = target.value
    this.setState({[name]: value})
  }

  prevTab = (key) =>{
    tabs[key].active = false
    tabs[key - 1].active = true
    tabs[key - 1].success = false
    this.setState({tabs: tabs})
  }

  step1 = () => {
    tabs[0].active = false
    tabs[1].active = true
    tabs[0].success = true
    this.setState({tabs: tabs})
  }
  step2 = () => {
    tabs[1].active = false
    tabs[2].active = true
    tabs[1].success = true
    this.setState({tabs: tabs})
  }
  step3 = () => {
    tabs[2].active = false
    tabs[3].active = true
    tabs[2].success = true
    this.setState({tabs: tabs})
  }

  render(){
    const { tabs, fname, lname, pass, market, solution } = this.state
    return(
      <section className="log">
        <div className="log-content">
          {
            tabs[0].active ? <FindLeads1left fname={fname} lname={lname} pass={pass} getValue={this.getValue} step1={this.step1}/> :
            tabs[1].active ? <FindLeads2left market={market} step2={this.step2} prevTab={this.prevTab} getValue={this.getValue}/> :
            tabs[2].active ? <FindLeads3left solution={solution} step3={this.step3} prevTab={this.prevTab} getValue={this.getValue}/> :
            tabs[3].active ? <FindLeads4left /> : ''
          }
          <ul className="find-leads-tabs">
            {
              tabs.map((item, index)=>
                <li className={`find-leads-tabs__item ${item.active ? 'active' : ''} ${item.success ? 'success' : ''}`} key={index}>
                  {item.tab}
                </li>
              )
            }
          </ul>
        </div>
        <div className="log-right">
          {
            images.map((item, index)=>
              <img className={`find-leads__img ${tabs[index].active ? item.name : ''}`} 
              src={`/assest/board/${tabs[index].active ? item.image : ''}`}
              alt=""/>
            )
          }
        </div>
      </section>
    )
  }
}

export default FindLeads