const items = [
  {
    name: 'Searches Name',
    sortName: 'name',
    clname: 'category',
    sortable: true
  },
  {
    name: 'Date Saved',
    sortName: 'creation_date',
    clname: 'title',
    sortable: true
  },
  {
    name: 'Search Criteria',
    sortName: 'selected_keywords',
    clname: 'upload saved-searches-selectedKeywords',
    sortable: false
  },
]

export default items