import * as ind from "./industry_name.js";
import * as exd from "./industry_name.js";

export const industry_groups = [
  {
    category: "Hospitals And Health Care",
    label: "Hospitals And Health Care",
    group: "Hospitals And Health Care",
    include_tags: [ind.HospitalsAndHealthCare],
    exclude_tags: [exd.HospitalsAndHealthCare],
  },
  {
    category: "Retail",
    label: "Retail",
    group: "Retail",
    include_tags: [ind.Retail],
    exclude_tags: [exd.Retail],
  },
  {
    category: "Higher Education",
    label: "Higher Education",
    group: "Higher Education",
    include_tags: [ind.HigherEducation],
    exclude_tags: [exd.HigherEducation],
  },
  {
    category: "Financial Services",
    label: "Financial Services",
    group: "Financial Services",
    include_tags: [ind.FinancialServices],
    exclude_tags: [exd.FinancialServices],
  },
  {
    category: "It Services And It Consulting",
    label: "It Services And It Consulting",
    group: "It Services And It Consulting",
    include_tags: [ind.ItServicesAndItConsulting],
    exclude_tags: [exd.ItServicesAndItConsulting],
  },
  {
    category: "Software Development",
    label: "Software Development",
    group: "Software Development",
    include_tags: [ind.SoftwareDevelopment],
    exclude_tags: [exd.SoftwareDevelopment],
  },
  {
    category: "Government Administration",
    label: "Government Administration",
    group: "Government Administration",
    include_tags: [ind.GovernmentAdministration],
    exclude_tags: [exd.GovernmentAdministration],
  },
  {
    category: "Education Administration Programs",
    label: "Education Administration Programs",
    group: "Education Administration Programs",
    include_tags: [ind.EducationAdministrationPrograms],
    exclude_tags: [exd.EducationAdministrationPrograms],
  },
  {
    category: "Building Construction",
    label: "Building Construction",
    group: "Building Construction",
    include_tags: [ind.BuildingConstruction],
    exclude_tags: [exd.BuildingConstruction],
  },
  {
    category: "Leasing Real Estate",
    label: "Leasing Real Estate",
    group: "Leasing Real Estate",
    include_tags: [ind.LeasingRealEstate],
    exclude_tags: [exd.LeasingRealEstate],
  },
  {
    category: "Insurance",
    label: "Insurance",
    group: "Insurance",
    include_tags: [ind.Insurance],
    exclude_tags: [exd.Insurance],
  },
  {
    category: "Food And Beverage Services",
    label: "Food And Beverage Services",
    group: "Food And Beverage Services",
    include_tags: [ind.FoodAndBeverageServices],
    exclude_tags: [exd.FoodAndBeverageServices],
  },
  {
    category: "Primary And Secondary Education",
    label: "Primary And Secondary Education",
    group: "Primary And Secondary Education",
    include_tags: [ind.PrimaryAndSecondaryEducation],
    exclude_tags: [exd.PrimaryAndSecondaryEducation],
  },
  {
    category: "Non-Profit Organizations",
    label: "Non-Profit Organizations",
    group: "Non-Profit Organizations",
    include_tags: [ind.NonProfitOrganizations],
    exclude_tags: [exd.NonProfitOrganizations],
  },
  {
    category: "Advertising Services",
    label: "Advertising Services",
    group: "Advertising Services",
    include_tags: [ind.AdvertisingServices],
    exclude_tags: [exd.AdvertisingServices],
  },
  {
    category: "Motor Vehicle Manufacturing",
    label: "Motor Vehicle Manufacturing",
    group: "Motor Vehicle Manufacturing",
    include_tags: [ind.MotorVehicleManufacturing],
    exclude_tags: [exd.MotorVehicleManufacturing],
  },
  {
    category: "Restaurants",
    label: "Restaurants",
    group: "Restaurants",
    include_tags: [ind.Restaurants],
    exclude_tags: [exd.Restaurants],
  },
  {
    category: "Business Consulting And Services",
    label: "Business Consulting And Services",
    group: "Business Consulting And Services",
    include_tags: [ind.BusinessConsultingAndServices],
    exclude_tags: [exd.BusinessConsultingAndServices],
  },
  {
    category: "Hospitality",
    label: "Hospitality",
    group: "Hospitality",
    include_tags: [ind.Hospitality],
    exclude_tags: [exd.Hospitality],
  },
  {
    category: "Armed Forces",
    label: "Armed Forces",
    group: "Armed Forces",
    include_tags: [ind.ArmedForces],
    exclude_tags: [exd.ArmedForces],
  },
  {
    category: "Truck Transportation",
    label: "Truck Transportation",
    group: "Truck Transportation",
    include_tags: [ind.TruckTransportation],
    exclude_tags: [exd.TruckTransportation],
  },
  {
    category: "Banking",
    label: "Banking",
    group: "Banking",
    include_tags: [ind.Banking],
    exclude_tags: [exd.Banking],
  },
  {
    category: "Human Resources Services",
    label: "Human Resources Services",
    group: "Human Resources Services",
    include_tags: [ind.HumanResourcesServices],
    exclude_tags: [exd.HumanResourcesServices],
  },
  {
    category: "Telecommunications",
    label: "Telecommunications",
    group: "Telecommunications",
    include_tags: [ind.Telecommunications],
    exclude_tags: [exd.Telecommunications],
  },
  {
    category: "Law Practice",
    label: "Law Practice",
    group: "Law Practice",
    include_tags: [ind.LawPractice],
    exclude_tags: [exd.LawPractice],
  },
  {
    category: "Oil And Gas",
    label: "Oil And Gas",
    group: "Oil And Gas",
    include_tags: [ind.OilAndGas],
    exclude_tags: [exd.OilAndGas],
  },
  {
    category: "Accounting",
    label: "Accounting",
    group: "Accounting",
    include_tags: [ind.Accounting],
    exclude_tags: [exd.Accounting],
  },
  {
    category: "Wellness And Fitness Services",
    label: "Wellness And Fitness Services",
    group: "Wellness And Fitness Services",
    include_tags: [ind.WellnessAndFitnessServices],
    exclude_tags: [exd.WellnessAndFitnessServices],
  },
  {
    category: "Medical Practices",
    label: "Medical Practices",
    group: "Medical Practices",
    include_tags: [ind.MedicalPractices],
    exclude_tags: [exd.MedicalPractices],
  },
  {
    category: "Entertainment Providers",
    label: "Entertainment Providers",
    group: "Entertainment Providers",
    include_tags: [ind.EntertainmentProviders],
    exclude_tags: [exd.EntertainmentProviders],
  },
  {
    category: "Manufacturing",
    label: "Manufacturing",
    group: "Manufacturing",
    include_tags: [ind.Manufacturing],
    exclude_tags: [exd.Manufacturing],
  },
  {
    category: "Appliances, Electrical, And Electronics Manufacturing",
    label: "Appliances, Electrical, And Electronics Manufacturing",
    group: "Appliances, Electrical, And Electronics Manufacturing",
    include_tags: [ind.AppliancesElectricalAndElectronicsManufacturing],
    exclude_tags: [exd.AppliancesElectricalAndElectronicsManufacturing],
  },
  {
    category: "Consumer Services",
    label: "Consumer Services",
    group: "Consumer Services",
    include_tags: [ind.ConsumerServices],
    exclude_tags: [exd.ConsumerServices],
  },
  {
    category: "Medical Equipment Manufacturing",
    label: "Medical Equipment Manufacturing",
    group: "Medical Equipment Manufacturing",
    include_tags: [ind.MedicalEquipmentManufacturing],
    exclude_tags: [exd.MedicalEquipmentManufacturing],
  },
  {
    category: "Pharmaceutical Manufacturing",
    label: "Pharmaceutical Manufacturing",
    group: "Pharmaceutical Manufacturing",
    include_tags: [ind.PharmaceuticalManufacturing],
    exclude_tags: [exd.PharmaceuticalManufacturing],
  },
  {
    category: "Biotechnology Research",
    label: "Biotechnology Research",
    group: "Biotechnology Research",
    include_tags: [ind.BiotechnologyResearch],
    exclude_tags: [exd.BiotechnologyResearch],
  },
  {
    category: "Machinery Manufacturing",
    label: "Machinery Manufacturing",
    group: "Machinery Manufacturing",
    include_tags: [ind.MachineryManufacturing],
    exclude_tags: [exd.MachineryManufacturing],
  },
  {
    category: "Defense And Space Manufacturing",
    label: "Defense And Space Manufacturing",
    group: "Defense And Space Manufacturing",
    include_tags: [ind.DefenseAndSpaceManufacturing],
    exclude_tags: [exd.DefenseAndSpaceManufacturing],
  },
  {
    category: "Utilities",
    label: "Utilities",
    group: "Utilities",
    include_tags: [ind.Utilities],
    exclude_tags: [exd.Utilities],
  },
  {
    category: "Airlines And Aviation",
    label: "Airlines And Aviation",
    group: "Airlines And Aviation",
    include_tags: [ind.AirlinesAndAviation],
    exclude_tags: [exd.AirlinesAndAviation],
  },
  {
    category: "Chemical Manufacturing",
    label: "Chemical Manufacturing",
    group: "Chemical Manufacturing",
    include_tags: [ind.ChemicalManufacturing],
    exclude_tags: [exd.ChemicalManufacturing],
  },
  {
    category: "Environmental Services",
    label: "Environmental Services",
    group: "Environmental Services",
    include_tags: [ind.EnvironmentalServices],
    exclude_tags: [exd.EnvironmentalServices],
  },
  {
    category: "Industrial Machinery Manufacturing",
    label: "Industrial Machinery Manufacturing",
    group: "Industrial Machinery Manufacturing",
    include_tags: [ind.IndustrialMachineryManufacturing],
    exclude_tags: [exd.IndustrialMachineryManufacturing],
  },
  {
    category: "Individual And Family Services",
    label: "Individual And Family Services",
    group: "Individual And Family Services",
    include_tags: [ind.IndividualAndFamilyServices],
    exclude_tags: [exd.IndividualAndFamilyServices],
  },
  {
    category: "Technology, Information And Internet",
    label: "Technology, Information And Internet",
    group: "Technology, Information And Internet",
    include_tags: [ind.TechnologyInformationAndInternet],
    exclude_tags: [exd.TechnologyInformationAndInternet],
  },
  {
    category: "Civil Engineering",
    label: "Civil Engineering",
    group: "Civil Engineering",
    include_tags: [ind.CivilEngineering],
    exclude_tags: [exd.CivilEngineering],
  },
  {
    category: "Wholesale",
    label: "Wholesale",
    group: "Wholesale",
    include_tags: [ind.Wholesale],
    exclude_tags: [exd.Wholesale],
  },
  {
    category: "Mental Health Care",
    label: "Mental Health Care",
    group: "Mental Health Care",
    include_tags: [ind.MentalHealthCare],
    exclude_tags: [exd.MentalHealthCare],
  },
  {
    category: "Aviation And Aerospace Component Manufacturing",
    label: "Aviation And Aerospace Component Manufacturing",
    group: "Aviation And Aerospace Component Manufacturing",
    include_tags: [ind.AviationAndAerospaceComponentManufacturing],
    exclude_tags: [exd.AviationAndAerospaceComponentManufacturing],
  },
  {
    category: "Wholesale Building Materials",
    label: "Wholesale Building Materials",
    group: "Wholesale Building Materials",
    include_tags: [ind.WholesaleBuildingMaterials],
    exclude_tags: [exd.WholesaleBuildingMaterials],
  },
  {
    category: "Broadcast Media Production And Distribution",
    label: "Broadcast Media Production And Distribution",
    group: "Broadcast Media Production And Distribution",
    include_tags: [ind.BroadcastMediaProductionAndDistribution],
    exclude_tags: [exd.BroadcastMediaProductionAndDistribution],
  },
  {
    category: "Retail Apparel And Fashion",
    label: "Retail Apparel And Fashion",
    group: "Retail Apparel And Fashion",
    include_tags: [ind.RetailApparelAndFashion],
    exclude_tags: [exd.RetailApparelAndFashion],
  },
  {
    category: "Architecture And Planning",
    label: "Architecture And Planning",
    group: "Architecture And Planning",
    include_tags: [ind.ArchitectureAndPlanning],
    exclude_tags: [exd.ArchitectureAndPlanning],
  },
  {
    category: "Research Services",
    label: "Research Services",
    group: "Research Services",
    include_tags: [ind.ResearchServices],
    exclude_tags: [exd.ResearchServices],
  },
  {
    category: "Facilities Services",
    label: "Facilities Services",
    group: "Facilities Services",
    include_tags: [ind.FacilitiesServices],
    exclude_tags: [exd.FacilitiesServices],
  },
  {
    category: "Religious Institutions",
    label: "Religious Institutions",
    group: "Religious Institutions",
    include_tags: [ind.ReligiousInstitutions],
    exclude_tags: [exd.ReligiousInstitutions],
  },
  {
    category: "Civic And Social Organizations",
    label: "Civic And Social Organizations",
    group: "Civic And Social Organizations",
    include_tags: [ind.CivicAndSocialOrganizations],
    exclude_tags: [exd.CivicAndSocialOrganizations],
  },
  {
    category: "Spectator Sports",
    label: "Spectator Sports",
    group: "Spectator Sports",
    include_tags: [ind.SpectatorSports],
    exclude_tags: [exd.SpectatorSports],
  },
  {
    category: "Design Services",
    label: "Design Services",
    group: "Design Services",
    include_tags: [ind.DesignServices],
    exclude_tags: [exd.DesignServices],
  },
  {
    category: "Security And Investigations",
    label: "Security And Investigations",
    group: "Security And Investigations",
    include_tags: [ind.SecurityAndInvestigations],
    exclude_tags: [exd.SecurityAndInvestigations],
  },
  {
    category: "Computers And Electronics Manufacturing",
    label: "Computers And Electronics Manufacturing",
    group: "Computers And Electronics Manufacturing",
    include_tags: [ind.ComputersAndElectronicsManufacturing],
    exclude_tags: [exd.ComputersAndElectronicsManufacturing],
  },
  {
    category: "Transportation, Logistics, Supply Chain And Storage",
    label: "Transportation, Logistics, Supply Chain And Storage",
    group: "Transportation, Logistics, Supply Chain And Storage",
    include_tags: [ind.TransportationLogisticsSupplyChainAndStorage],
    exclude_tags: [exd.TransportationLogisticsSupplyChainAndStorage],
  },
  {
    category: "Mining",
    label: "Mining",
    group: "Mining",
    include_tags: [ind.Mining],
    exclude_tags: [exd.Mining],
  },
  {
    category: "Law Enforcement",
    label: "Law Enforcement",
    group: "Law Enforcement",
    include_tags: [ind.LawEnforcement],
    exclude_tags: [exd.LawEnforcement],
  },
  {
    category: "Packaging And Containers Manufacturing",
    label: "Packaging And Containers Manufacturing",
    group: "Packaging And Containers Manufacturing",
    include_tags: [ind.PackagingAndContainersManufacturing],
    exclude_tags: [exd.PackagingAndContainersManufacturing],
  },
  {
    category: "Book And Periodical Publishing",
    label: "Book And Periodical Publishing",
    group: "Book And Periodical Publishing",
    include_tags: [ind.BookAndPeriodicalPublishing],
    exclude_tags: [exd.BookAndPeriodicalPublishing],
  },
  {
    category: "Travel Arrangements",
    label: "Travel Arrangements",
    group: "Travel Arrangements",
    include_tags: [ind.TravelArrangements],
    exclude_tags: [exd.TravelArrangements],
  },
  {
    category: "Legal Services",
    label: "Legal Services",
    group: "Legal Services",
    include_tags: [ind.LegalServices],
    exclude_tags: [exd.LegalServices],
  },
  {
    category: "Events Services",
    label: "Events Services",
    group: "Events Services",
    include_tags: [ind.EventsServices],
    exclude_tags: [exd.EventsServices],
  },
  {
    category: "Investment Management",
    label: "Investment Management",
    group: "Investment Management",
    include_tags: [ind.InvestmentManagement],
    exclude_tags: [exd.InvestmentManagement],
  },
  {
    category: "Semiconductor Manufacturing",
    label: "Semiconductor Manufacturing",
    group: "Semiconductor Manufacturing",
    include_tags: [ind.SemiconductorManufacturing],
    exclude_tags: [exd.SemiconductorManufacturing],
  },
  {
    category: "Automation Machinery Manufacturing",
    label: "Automation Machinery Manufacturing",
    group: "Automation Machinery Manufacturing",
    include_tags: [ind.AutomationMachineryManufacturing],
    exclude_tags: [exd.AutomationMachineryManufacturing],
  },
  {
    category: "Freight And Package Transportation",
    label: "Freight And Package Transportation",
    group: "Freight And Package Transportation",
    include_tags: [ind.FreightAndPackageTransportation],
    exclude_tags: [exd.FreightAndPackageTransportation],
  },
  {
    category: "Printing Services",
    label: "Printing Services",
    group: "Printing Services",
    include_tags: [ind.PrintingServices],
    exclude_tags: [exd.PrintingServices],
  },
  {
    category: "Renewable Energy Semiconductor Manufacturing",
    label: "Renewable Energy Semiconductor Manufacturing",
    group: "Renewable Energy Semiconductor Manufacturing",
    include_tags: [ind.RenewableEnergySemiconductorManufacturing],
    exclude_tags: [exd.RenewableEnergySemiconductorManufacturing],
  },
  {
    category: "Computer And Network Security",
    label: "Computer And Network Security",
    group: "Computer And Network Security",
    include_tags: [ind.ComputerAndNetworkSecurity],
    exclude_tags: [exd.ComputerAndNetworkSecurity],
  },
  {
    category: "Furniture And Home Furnishings Manufacturing",
    label: "Furniture And Home Furnishings Manufacturing",
    group: "Furniture And Home Furnishings Manufacturing",
    include_tags: [ind.FurnitureAndHomeFurnishingsManufacturing],
    exclude_tags: [exd.FurnitureAndHomeFurnishingsManufacturing],
  },
  {
    category: "Public Relations And Communications Services",
    label: "Public Relations And Communications Services",
    group: "Public Relations And Communications Services",
    include_tags: [ind.PublicRelationsAndCommunicationsServices],
    exclude_tags: [exd.PublicRelationsAndCommunicationsServices],
  },
  {
    category: "Public Safety",
    label: "Public Safety",
    group: "Public Safety",
    include_tags: [ind.PublicSafety],
    exclude_tags: [exd.PublicSafety],
  },
  {
    category: "Professional Training And Coaching",
    label: "Professional Training And Coaching",
    group: "Professional Training And Coaching",
    include_tags: [ind.ProfessionalTrainingAndCoaching],
    exclude_tags: [exd.ProfessionalTrainingAndCoaching],
  },
  {
    category: "Artists And Writers",
    label: "Artists And Writers",
    group: "Artists And Writers",
    include_tags: [ind.ArtistsAndWriters],
    exclude_tags: [exd.ArtistsAndWriters],
  },
  {
    category: "Personal Care Product Manufacturing",
    label: "Personal Care Product Manufacturing",
    group: "Personal Care Product Manufacturing",
    include_tags: [ind.PersonalCareProductManufacturing],
    exclude_tags: [exd.PersonalCareProductManufacturing],
  },
  {
    category: "E-Learning Providers",
    label: "E-Learning Providers",
    group: "E-Learning Providers",
    include_tags: [ind.ELearningProviders],
    exclude_tags: [exd.ELearningProviders],
  },
  {
    category: "Retail Office Equipment",
    label: "Retail Office Equipment",
    group: "Retail Office Equipment",
    include_tags: [ind.RetailOfficeEquipment],
    exclude_tags: [exd.RetailOfficeEquipment],
  },
  {
    category: "Musicians",
    label: "Musicians",
    group: "Musicians",
    include_tags: [ind.Musicians],
    exclude_tags: [exd.Musicians],
  },
  {
    category: "Farming",
    label: "Farming",
    group: "Farming",
    include_tags: [ind.Farming],
    exclude_tags: [exd.Farming],
  },
  {
    category: "Museums, Historical Sites, And Zoos",
    label: "Museums, Historical Sites, And Zoos",
    group: "Museums, Historical Sites, And Zoos",
    include_tags: [ind.MuseumsHistoricalSitesAndZoos],
    exclude_tags: [exd.MuseumsHistoricalSitesAndZoos],
  },
  {
    category: "Beverage Manufacturing",
    label: "Beverage Manufacturing",
    group: "Beverage Manufacturing",
    include_tags: [ind.BeverageManufacturing],
    exclude_tags: [exd.BeverageManufacturing],
  },
  {
    category: "Plastics Manufacturing",
    label: "Plastics Manufacturing",
    group: "Plastics Manufacturing",
    include_tags: [ind.PlasticsManufacturing],
    exclude_tags: [exd.PlasticsManufacturing],
  },
  {
    category: "Venture Capital And Private Equity Principals",
    label: "Venture Capital And Private Equity Principals",
    group: "Venture Capital And Private Equity Principals",
    include_tags: [ind.VentureCapitalAndPrivateEquityPrincipals],
    exclude_tags: [exd.VentureCapitalAndPrivateEquityPrincipals],
  },
  {
    category: "Gambling Facilities And Casinos",
    label: "Gambling Facilities And Casinos",
    group: "Gambling Facilities And Casinos",
    include_tags: [ind.GamblingFacilitiesAndCasinos],
    exclude_tags: [exd.GamblingFacilitiesAndCasinos],
  },
  {
    category: "International Trade And Development",
    label: "International Trade And Development",
    group: "International Trade And Development",
    include_tags: [ind.InternationalTradeAndDevelopment],
    exclude_tags: [exd.InternationalTradeAndDevelopment],
  },
  {
    category: "Outsourcing And Offshoring Consulting",
    label: "Outsourcing And Offshoring Consulting",
    group: "Outsourcing And Offshoring Consulting",
    include_tags: [ind.OutsourcingAndOffshoringConsulting],
    exclude_tags: [exd.OutsourcingAndOffshoringConsulting],
  },
  {
    category: "Government Relations Services",
    label: "Government Relations Services",
    group: "Government Relations Services",
    include_tags: [ind.GovernmentRelationsServices],
    exclude_tags: [exd.GovernmentRelationsServices],
  },
  {
    category: "Computer Hardware Manufacturing",
    label: "Computer Hardware Manufacturing",
    group: "Computer Hardware Manufacturing",
    include_tags: [ind.ComputerHardwareManufacturing],
    exclude_tags: [exd.ComputerHardwareManufacturing],
  },
  {
    category: "Recreational Facilities",
    label: "Recreational Facilities",
    group: "Recreational Facilities",
    include_tags: [ind.RecreationalFacilities],
    exclude_tags: [exd.RecreationalFacilities],
  },
  {
    category: "Textile Manufacturing",
    label: "Textile Manufacturing",
    group: "Textile Manufacturing",
    include_tags: [ind.TextileManufacturing],
    exclude_tags: [exd.TextileManufacturing],
  },
  {
    category: "Newspaper Publishing",
    label: "Newspaper Publishing",
    group: "Newspaper Publishing",
    include_tags: [ind.NewspaperPublishing],
    exclude_tags: [exd.NewspaperPublishing],
  },
  {
    category: "International Affairs",
    label: "International Affairs",
    group: "International Affairs",
    include_tags: [ind.InternationalAffairs],
    exclude_tags: [exd.InternationalAffairs],
  },
  {
    category: "Investment Banking",
    label: "Investment Banking",
    group: "Investment Banking",
    include_tags: [ind.InvestmentBanking],
    exclude_tags: [exd.InvestmentBanking],
  },
  {
    category: "Retail Groceries",
    label: "Retail Groceries",
    group: "Retail Groceries",
    include_tags: [ind.RetailGroceries],
    exclude_tags: [exd.RetailGroceries],
  },
  {
    category: "Paper And Forest Product Manufacturing",
    label: "Paper And Forest Product Manufacturing",
    group: "Paper And Forest Product Manufacturing",
    include_tags: [ind.PaperAndForestProductManufacturing],
    exclude_tags: [exd.PaperAndForestProductManufacturing],
  },
  {
    category: "Veterinary Services",
    label: "Veterinary Services",
    group: "Veterinary Services",
    include_tags: [ind.VeterinaryServices],
    exclude_tags: [exd.VeterinaryServices],
  },
  {
    category: "Warehousing And Storage",
    label: "Warehousing And Storage",
    group: "Warehousing And Storage",
    include_tags: [ind.WarehousingAndStorage],
    exclude_tags: [exd.WarehousingAndStorage],
  },
  {
    category: "Computer Games",
    label: "Computer Games",
    group: "Computer Games",
    include_tags: [ind.ComputerGames],
    exclude_tags: [exd.ComputerGames],
  },
  {
    category: "Executive Offices",
    label: "Executive Offices",
    group: "Executive Offices",
    include_tags: [ind.ExecutiveOffices],
    exclude_tags: [exd.ExecutiveOffices],
  },
  {
    category: "Online Audio And Video Media",
    label: "Online Audio And Video Media",
    group: "Online Audio And Video Media",
    include_tags: [ind.OnlineAudioAndVideoMedia],
    exclude_tags: [exd.OnlineAudioAndVideoMedia],
  },
  {
    category: "Libraries",
    label: "Libraries",
    group: "Libraries",
    include_tags: [ind.Libraries],
    exclude_tags: [exd.Libraries],
  },
  {
    category: "Computer Networking",
    label: "Computer Networking",
    group: "Computer Networking",
    include_tags: [ind.ComputerNetworking],
    exclude_tags: [exd.ComputerNetworking],
  },
  {
    category: "Retail Luxury Goods And Jewelry",
    label: "Retail Luxury Goods And Jewelry",
    group: "Retail Luxury Goods And Jewelry",
    include_tags: [ind.RetailLuxuryGoodsAndJewelry],
    exclude_tags: [exd.RetailLuxuryGoodsAndJewelry],
  },
  {
    category: "Market Research",
    label: "Market Research",
    group: "Market Research",
    include_tags: [ind.MarketResearch],
    exclude_tags: [exd.MarketResearch],
  },
  {
    category: "Photography",
    label: "Photography",
    group: "Photography",
    include_tags: [ind.Photography],
    exclude_tags: [exd.Photography],
  },
  {
    category: "Administration Of Justice",
    label: "Administration Of Justice",
    group: "Administration Of Justice",
    include_tags: [ind.AdministrationOfJustice],
    exclude_tags: [exd.AdministrationOfJustice],
  },
  {
    category: "Maritime Transportation",
    label: "Maritime Transportation",
    group: "Maritime Transportation",
    include_tags: [ind.MaritimeTransportation],
    exclude_tags: [exd.MaritimeTransportation],
  },
  {
    category: "Writing And Editing",
    label: "Writing And Editing",
    group: "Writing And Editing",
    include_tags: [ind.WritingAndEditing],
    exclude_tags: [exd.WritingAndEditing],
  },
  {
    category: "Sporting Goods Manufacturing",
    label: "Sporting Goods Manufacturing",
    group: "Sporting Goods Manufacturing",
    include_tags: [ind.SportingGoodsManufacturing],
    exclude_tags: [exd.SportingGoodsManufacturing],
  },
  {
    category: "Fundraising",
    label: "Fundraising",
    group: "Fundraising",
    include_tags: [ind.Fundraising],
    exclude_tags: [exd.Fundraising],
  },
  {
    category: "Railroad Equipment Manufacturing",
    label: "Railroad Equipment Manufacturing",
    group: "Railroad Equipment Manufacturing",
    include_tags: [ind.RailroadEquipmentManufacturing],
    exclude_tags: [exd.RailroadEquipmentManufacturing],
  },
  {
    category: "Translation And Localization",
    label: "Translation And Localization",
    group: "Translation And Localization",
    include_tags: [ind.TranslationAndLocalization],
    exclude_tags: [exd.TranslationAndLocalization],
  },
  {
    category: "Alternative Medicine",
    label: "Alternative Medicine",
    group: "Alternative Medicine",
    include_tags: [ind.AlternativeMedicine],
    exclude_tags: [exd.AlternativeMedicine],
  },
  {
    category: "Philanthropic Fundraising Services",
    label: "Philanthropic Fundraising Services",
    group: "Philanthropic Fundraising Services",
    include_tags: [ind.PhilanthropicFundraisingServices],
    exclude_tags: [exd.PhilanthropicFundraisingServices],
  },
  {
    category: "Glass, Ceramics And Concrete Manufacturing",
    label: "Glass, Ceramics And Concrete Manufacturing",
    group: "Glass, Ceramics And Concrete Manufacturing",
    include_tags: [ind.GlassCeramicsAndConcreteManufacturing],
    exclude_tags: [exd.GlassCeramicsAndConcreteManufacturing],
  },
  {
    category: "Movies, Videos, And Sound",
    label: "Movies, Videos, And Sound",
    group: "Movies, Videos, And Sound",
    include_tags: [ind.MoviesVideosAndSound],
    exclude_tags: [exd.MoviesVideosAndSound],
  },
  {
    category: "Retail Art Supplies",
    label: "Retail Art Supplies",
    group: "Retail Art Supplies",
    include_tags: [ind.RetailArtSupplies],
    exclude_tags: [exd.RetailArtSupplies],
  },
  {
    category: "Animation And Post-Production",
    label: "Animation And Post-Production",
    group: "Animation And Post-Production",
    include_tags: [ind.AnimationAndPostProduction],
    exclude_tags: [exd.AnimationAndPostProduction],
  },
  {
    category: "Political Organizations",
    label: "Political Organizations",
    group: "Political Organizations",
    include_tags: [ind.PoliticalOrganizations],
    exclude_tags: [exd.PoliticalOrganizations],
  },
  {
    category: "Think Tanks",
    label: "Think Tanks",
    group: "Think Tanks",
    include_tags: [ind.ThinkTanks],
    exclude_tags: [exd.ThinkTanks],
  },
  {
    category: "Wireless Services",
    label: "Wireless Services",
    group: "Wireless Services",
    include_tags: [ind.WirelessServices],
    exclude_tags: [exd.WirelessServices],
  },
  {
    category: "Capital Markets",
    label: "Capital Markets",
    group: "Capital Markets",
    include_tags: [ind.CapitalMarkets],
    exclude_tags: [exd.CapitalMarkets],
  },
  {
    category: "Public Policy Offices",
    label: "Public Policy Offices",
    group: "Public Policy Offices",
    include_tags: [ind.PublicPolicyOffices],
    exclude_tags: [exd.PublicPolicyOffices],
  },
  {
    category: "Wholesale Import And Export",
    label: "Wholesale Import And Export",
    group: "Wholesale Import And Export",
    include_tags: [ind.WholesaleImportAndExport],
    exclude_tags: [exd.WholesaleImportAndExport],
  },
  {
    category: "Shipbuilding",
    label: "Shipbuilding",
    group: "Shipbuilding",
    include_tags: [ind.Shipbuilding],
    exclude_tags: [exd.Shipbuilding],
  },
  {
    category: "Strategic Management Services",
    label: "Strategic Management Services",
    group: "Strategic Management Services",
    include_tags: [ind.StrategicManagementServices],
    exclude_tags: [exd.StrategicManagementServices],
  },
  {
    category: "Dairy Product Manufacturing",
    label: "Dairy Product Manufacturing",
    group: "Dairy Product Manufacturing",
    include_tags: [ind.DairyProductManufacturing],
    exclude_tags: [exd.DairyProductManufacturing],
  },
  {
    category: "Alternative Dispute Resolution",
    label: "Alternative Dispute Resolution",
    group: "Alternative Dispute Resolution",
    include_tags: [ind.AlternativeDisputeResolution],
    exclude_tags: [exd.AlternativeDisputeResolution],
  },
  {
    category: "Legislative Offices",
    label: "Legislative Offices",
    group: "Legislative Offices",
    include_tags: [ind.LegislativeOffices],
    exclude_tags: [exd.LegislativeOffices],
  },
  {
    category: "Education",
    label: "Education",
    group: "Education",
    include_tags: [ind.Education],
    exclude_tags: [exd.Education],
  },
  {
    category: "Ranching",
    label: "Ranching",
    group: "Ranching",
    include_tags: [ind.Ranching],
    exclude_tags: [exd.Ranching],
  },
  {
    category: "Nanotechnology Research",
    label: "Nanotechnology Research",
    group: "Nanotechnology Research",
    include_tags: [ind.NanotechnologyResearch],
    exclude_tags: [exd.NanotechnologyResearch],
  },
  {
    category: "Tobacco Manufacturing",
    label: "Tobacco Manufacturing",
    group: "Tobacco Manufacturing",
    include_tags: [ind.TobaccoManufacturing],
    exclude_tags: [exd.TobaccoManufacturing],
  },
  {
    category: "Fisheries",
    label: "Fisheries",
    group: "Fisheries",
    include_tags: [ind.Fisheries],
    exclude_tags: [exd.Fisheries],
  },
  {
    category: "Information Technology & Services",
    label: "Information Technology & Services",
    group: "Information Technology & Services",
    include_tags: [ind.InformationTechnologyAndServices],
    exclude_tags: [exd.InformationTechnologyAndServices],
  },
  {
    category: "Online And Mail Order Retail",
    label: "Online And Mail Order Retail",
    group: "Online And Mail Order Retail",
    include_tags: [ind.OnlineAndMailOrderRetail],
    exclude_tags: [exd.OnlineAndMailOrderRetail],
  },
  {
    category: "Internet",
    label: "Internet",
    group: "Internet",
    include_tags: [ind.Internet],
    exclude_tags: [exd.Internet],
  },
  {
    category: "Marketing Services",
    label: "Marketing Services",
    group: "Marketing Services",
    include_tags: [ind.MarketingServices],
    exclude_tags: [exd.MarketingServices],
  },
  {
    category: "Health, Wellness & Fitness",
    label: "Health, Wellness & Fitness",
    group: "Health, Wellness & Fitness",
    include_tags: [ind.HealthWellnessAndFitness],
    exclude_tags: [exd.HealthWellnessAndFitness],
  },
  {
    category: "Consumer Goods",
    label: "Consumer Goods",
    group: "Consumer Goods",
    include_tags: [ind.ConsumerGoods],
    exclude_tags: [exd.ConsumerGoods],
  },
  {
    category: "Automotive",
    label: "Automotive",
    group: "Automotive",
    include_tags: [ind.Automotive],
    exclude_tags: [exd.Automotive],
  },
  {
    category: "Engineering Services",
    label: "Engineering Services",
    group: "Engineering Services",
    include_tags: [ind.EngineeringServices],
    exclude_tags: [exd.EngineeringServices],
  },
  {
    category: "Data Infrastructure And Analytics",
    label: "Data Infrastructure And Analytics",
    group: "Data Infrastructure And Analytics",
    include_tags: [ind.DataInfrastructureAndAnalytics],
    exclude_tags: [exd.DataInfrastructureAndAnalytics],
  },
  {
    category: "Research",
    label: "Research",
    group: "Research",
    include_tags: [ind.Research],
    exclude_tags: [exd.Research],
  },
  {
    category: "Rail Transportation",
    label: "Rail Transportation",
    group: "Rail Transportation",
    include_tags: [ind.RailTransportation],
    exclude_tags: [exd.RailTransportation],
  },
  {
    category: "Home Health Care Services",
    label: "Home Health Care Services",
    group: "Home Health Care Services",
    include_tags: [ind.HomeHealthCareServices],
    exclude_tags: [exd.HomeHealthCareServices],
  },
  {
    category: "Leisure, Travel And Tourism",
    label: "Leisure, Travel And Tourism",
    group: "Leisure, Travel And Tourism",
    include_tags: [ind.LeisureTravelAndTourism],
    exclude_tags: [exd.LeisureTravelAndTourism],
  },
  {
    category: "Medical Device",
    label: "Medical Device",
    group: "Medical Device",
    include_tags: [ind.MedicalDevice],
    exclude_tags: [exd.MedicalDevice],
  },
];

// export const industry_groups = [
//   {
//     category: "Accounting",
//     label: "Accounting",
//     group: "Accounting",
//     include_tags: [ind.Accounting],
//     exclude_tags: [exd.Accounting],
//   },
//   {
//     category: "Agriculture, Fish & Forestry",
//     label: "Agriculture, Fish & Forestry",
//     group: "Agriculture, Fish & Forestry",
//     include_tags: [ind.Farming, ind.Fishery, ind.Maritime, ind.Ranching],
//     exclude_tags: [exd.Farming, exd.Fishery, exd.Maritime, exd.Ranching]
//   },
//   {
//     category: "Architecture & Civil Engineering",
//     label: "Architecture & Civil Engineering",
//     group: "Architecture & Civil Engineering",
//     include_tags: [ind.ArchitectureAndPlanning, ind.CivilEngineering],
//     exclude_tags: [exd.ArchitectureAndPlanning, exd.CivilEngineering]
//   },
//   {
//     category: "Arts & Crafts",
//     label: "Arts & Crafts",
//     group: "Arts & Crafts",
//     include_tags: [ind.ArtsAndCrafts, ind.FineArt],
//     exclude_tags: [exd.ArtsAndCrafts, exd.FineArt]
//   },
//   {
//     category: "Automotive",
//     label: "Automotive",
//     group: "Automotive",
//     include_tags: [ind.Automotive],
//     exclude_tags: [exd.Automotive]
//   },
//   {
//     category: "Aviation",
//     label: "Aviation",
//     group: "Aviation",
//     include_tags: [ind.AirlinesAviation, ind.AviationAndAerospace],
//     exclude_tags: [exd.AirlinesAviation, exd.AviationAndAerospace]
//   },
//   {
//     category: "Banking",
//     label: "Banking",
//     group: "Banking",
//     include_tags: [ind.Banking, ind.InvestmentBanking],
//     exclude_tags: [exd.Banking, exd.InvestmentBanking]
//   },
//   {
//     category: "Biotechnology",
//     label: "Biotechnology",
//     group: "Biotechnology",
//     include_tags: [ind.Biotechnology],
//     exclude_tags: [exd.Biotechnology]
//   },
//   {
//     category: "Business Services (Training, Coaching, and more)",
//     label: "Business Services (Training, Coaching, and more)",
//     group: "Business Services (Training, Coaching, and more)",
//     include_tags: [
//       ind.ManagementConsulting,
//       ind.ProfessionalTrainingAndCoaching,
//       ind.ProgramDevelopment
//     ],
//     exclude_tags: [
//       exd.ManagementConsulting,
//       exd.ProfessionalTrainingAndCoaching,
//       exd.ProgramDevelopment

//     ]
//   },
//   {
//     category: "Business Supplies & Equipment",
//     label: "Business Supplies & Equipment",
//     group: "Business Supplies & Equipment",
//     include_tags: [ind.BusinessSuppliesAndEquipment],
//     exclude_tags: [exd.BusinessSuppliesAndEquipment]
//   },
//   {
//     category: "Charity, Civic & Social Organization",
//     label: "Charity, Civic & Social Organization",
//     group: "Charity, Civic & Social Organization",
//     include_tags: [
//       ind.CivicAndSocialOrganization,
//       ind.FundRaising,
//       ind.NonProfitOrganizationManagement,
//       ind.Philanthropy
//     ],
//     exclude_tags: [
//       exd.CivicAndSocialOrganization,
//       exd.FundRaising,
//       exd.NonProfitOrganizationManagement,
//       exd.Philanthropy

//     ]
//   },
//   {
//     category: "Chemicals",
//     label: "Chemicals",
//     group: "Chemicals",
//     include_tags: [ind.Chemicals],
//     exclude_tags: [exd.Chemicals]
//   },
//   {
//     category: "Clothing & Accessories",
//     label: "Clothing & Accessories",
//     group: "Clothing & Accessories",
//     include_tags: [ind.ApparelAndFashion],
//     exclude_tags: [exd.ApparelAndFashion]
//   },
//   {
//     category: "Computer Hardware & Networking",
//     label: "Computer Hardware & Networking",
//     group: "Computer Hardware & Networking",
//     include_tags: [
//       ind.ComputerAndNetworkSecurity,
//       ind.ComputerHardware,
//       ind.ComputerNetworking
//     ],
//     exclude_tags: [
//       exd.ComputerAndNetworkSecurity,
//       exd.ComputerHardware,
//       exd.ComputerNetworking

//     ]
//   },
//   {
//     category: "Computer Software & Internet Technology",
//     label: "Computer Software & Internet Technology",
//     group: "Computer Software & Internet Technology",
//     include_tags: [ind.ComputerGames, ind.ComputerSoftware, ind.Internet],
//     exclude_tags: [exd.ComputerGames, exd.ComputerSoftware, exd.Internet]
//   },
//   {
//     category: "Construction",
//     label: "Construction",
//     group: "Construction",
//     include_tags: [ind.BuildingMaterials, ind.Construction],
//     exclude_tags: [exd.BuildingMaterials, exd.Construction]
//   },
//   {
//     category: "Consumer Goods",
//     label: "Consumer Goods",
//     group: "Consumer Goods",
//     include_tags: [ind.ConsumerGoods],
//     exclude_tags: [exd.ConsumerGoods]
//   },
//   {
//     category: "Consumer Services",
//     label: "Consumer Services",
//     group: "Consumer Services",
//     include_tags: [ind.ConsumerServices],
//     exclude_tags: [exd.ConsumerServices]
//   },
//   {
//     category: "Cosmetics",
//     label: "Cosmetics",
//     group: "Cosmetics",
//     include_tags: [ind.Cosmetics],
//     exclude_tags: [exd.Cosmetics]
//   },
//   {
//     category: "Design",
//     label: "Design",
//     group: "Design",
//     include_tags: [ind.Design],
//     exclude_tags: [exd.Design]
//   },
//   {
//     category: "Education",
//     label: "Education",
//     group: "Education",
//     include_tags: [
//       ind.EducationManagement,
//       ind.ELearning,
//       ind.HigherEducation,
//       ind.PrimarySecondaryEducation
//     ],
//     exclude_tags: [
//       exd.EducationManagement,
//       exd.ELearning,
//       exd.HigherEducation,
//       exd.PrimarySecondaryEducation

//     ]
//   },
//   {
//     category: "Electrical / Electronic Manufacturing",
//     label: "Electrical / Electronic Manufacturing",
//     group: "Electrical / Electronic Manufacturing",
//     include_tags: [ind.ElectricalElectronicManufacturing],
//     exclude_tags: [exd.ElectricalElectronicManufacturing]
//   },
//   {
//     category: "Electronics",
//     label: "Electronics",
//     group: "Electronics",
//     include_tags: [ind.ConsumerElectronics, ind.Semiconductors],
//     exclude_tags: [exd.ConsumerElectronics, exd.Semiconductors]
//   },
//   {
//     category: "Entertainment",
//     label: "Entertainment",
//     group: "Entertainment",
//     include_tags: [ind.Entertainment, ind.RecreationalFacilitiesAndServices],
//     exclude_tags: [exd.Entertainment, exd.RecreationalFacilitiesAndServices]
//   },
//   {
//     category: "Environmental Services & Renewables",
//     label: "Environmental Services & Renewables",
//     group: "Environmental Services & Renewables",
//     include_tags: [ind.EnvironmentalServices, ind.RenewablesAndEnvironment],
//     exclude_tags: [exd.EnvironmentalServices, exd.RenewablesAndEnvironment]
//   },
//   {
//     category: "Event Services",
//     label: "Event Services",
//     group: "Event Services",
//     include_tags: [ind.EventsServices],
//     exclude_tags: [exd.EventsServices]
//   },
//   {
//     category: "Executive Office",
//     label: "Executive Office",
//     group: "Executive Office",
//     include_tags: [ind.ExecutiveOffice],
//     exclude_tags: [exd.ExecutiveOffice]
//   },
//   {
//     category: "Facility, Landscaping, Cleaning Services",
//     label: "Facility, Landscaping, Cleaning Services",
//     group: "Facility, Landscaping, Cleaning Services",
//     include_tags: [ind.FacilitiesServices],
//     exclude_tags: [exd.FacilitiesServices]
//   },
//   {
//     category: "Financial Services",
//     label: "Financial Services",
//     group: "Financial Services",
//     include_tags: [
//       ind.CapitalMarkets,
//       ind.FinancialServices,
//       ind.InvestmentManagement,
//       ind.VentureCapitalAndPrivateEquity
//     ],
//     exclude_tags: [
//       exd.CapitalMarkets,
//       exd.FinancialServices,
//       exd.InvestmentManagement,
//       exd.VentureCapitalAndPrivateEquity
//     ]
//   },
//   {
//     category: "Food & Beverage",
//     label: "Food & Beverage",
//     group: "Food & Beverage",
//     include_tags: [
//       ind.Dairy,
//       ind.FoodAndBeverages,
//       ind.FoodProduction,
//       ind.WineAndSpirits
//     ],
//     exclude_tags: [
//       exd.Dairy,
//       exd.FoodAndBeverages,
//       exd.FoodProduction,
//       exd.WineAndSpirits
//     ]
//   },
//   {
//     category: "Freight Shipping / Delivery",
//     label: "Freight Shipping / Delivery",
//     group: "Freight Shipping / Delivery",
//     include_tags: [ind.PackageFreightDelivery],
//     exclude_tags: [exd.PackageFreightDelivery]
//   },
//   {
//     category: "Furniture",
//     label: "Furniture",
//     group: "Furniture",
//     include_tags: [ind.Furniture],
//     exclude_tags: [exd.Furniture]
//   },
//   {
//     category: "Gambling & Casinos",
//     label: "Gambling & Casinos",
//     group: "Gambling & Casinos",
//     include_tags: [ind.GamblingAndCasinos],
//     exclude_tags: [exd.GamblingAndCasinos]
//   },
//   {
//     category: "Glass, Ceramics & Concrete",
//     label: "Glass, Ceramics & Concrete",
//     group: "Glass, Ceramics & Concrete",
//     include_tags: [ind.GlassCeramicsAndConcrete],
//     exclude_tags: [exd.GlassCeramicsAndConcrete]
//   },
//   {
//     category: "Government",
//     label: "Government",
//     group: "Government",
//     include_tags: [
//       ind.GovernmentAdministration,
//       ind.GovernmentRelations,
//       ind.InternationalAffairs
//     ],
//     exclude_tags: [
//       exd.GovernmentAdministration,
//       exd.GovernmentRelations,
//       exd.InternationalAffairs
//     ]
//   },
//   {
//     category: "Graphic Design",
//     label: "Graphic Design",
//     group: "Graphic Design",
//     include_tags: [ind.GraphicDesign, ind.Animation],
//     exclude_tags: [exd.GraphicDesign, exd.Animation]
//   },
//   {
//     category: "Health, Wellness & Fitness",
//     label: "Health, Wellness & Fitness",
//     group: "Health, Wellness & Fitness",
//     include_tags: [ind.HealthWellnessAndFitness],
//     exclude_tags: [exd.HealthWellnessAndFitness]
//   },
//   {
//     category: "Hospital & Health Care",
//     label: "Hospital & Health Care",
//     group: "Hospital & Health Care",
//     include_tags: [ind.HospitalAndHealthCare, ind.MentalHealthCare],
//     exclude_tags: [exd.HospitalAndHealthCare, exd.MentalHealthCare]
//   },
//   {
//     category: "Hospitality",
//     label: "Hospitality",
//     group: "Hospitality",
//     include_tags: [ind.Hospitality],
//     exclude_tags: [exd.Hospitality]
//   },
//   {
//     category: "HR, Staffing & Recruiting",
//     label: "HR, Staffing & Recruiting",
//     group: "HR, Staffing & Recruiting",
//     include_tags: [ind.HumanResources, ind.StaffingAndRecruiting],
//     exclude_tags: [exd.HumanResources, exd.StaffingAndRecruiting]
//   },
//   {
//     category: "Import & Export",
//     label: "Import & Export",
//     group: "Import & Export",
//     include_tags: [ind.ImportAndExport, ind.InternationalTradeAndDevelopment],
//     exclude_tags: [exd.ImportAndExport, exd.InternationalTradeAndDevelopment]
//   },
//   {
//     category: "Individual & Family Services",
//     label: "Individual & Family Services",
//     group: "Individual & Family Services",
//     include_tags: [
//       ind.AlternativeDisputeResolution,
//       ind.IndividualAndFamilyServices
//     ],
//     exclude_tags: [
//       exd.AlternativeDisputeResolution,
//       exd.IndividualAndFamilyServices
//     ]
//   },
//   {
//     category: "Information Technology & Services",
//     label: "Information Technology & Services",
//     group: "Information Technology & Services",
//     include_tags: [
//       ind.InformationServices,
//       ind.InformationTechnologyAndServices
//     ],
//     exclude_tags: [
//       exd.InformationServices,
//       exd.InformationTechnologyAndServices

//     ]
//   },
//   {
//     category: "Insurance",
//     label: "Insurance",
//     group: "Insurance",
//     include_tags: [ind.Insurance],
//     exclude_tags: [exd.Insurance]
//   },
//   {
//     category: "Law Enforcement & Security",
//     label: "Law Enforcement & Security",
//     group: "Law Enforcement & Security",
//     include_tags: [ind.LawEnforcement, ind.SecurityAndInvestigations],
//     exclude_tags: [exd.LawEnforcement, exd.SecurityAndInvestigations]
//   },
//   {
//     category: "Legal",
//     label: "Legal",
//     group: "Legal",
//     include_tags: [ind.Judiciary, ind.LawPractice, ind.LegalServices],
//     exclude_tags: [exd.Judiciary, exd.LawPractice, exd.LegalServices]
//   },
//   {
//     category: "Luxury Goods & Jewelry",
//     label: "Luxury Goods & Jewelry",
//     group: "Luxury Goods & Jewelry",
//     include_tags: [ind.LuxuryGoodsAndJewelry],
//     exclude_tags: [exd.LuxuryGoodsAndJewelry]
//   },
//   {
//     category: "Machinery",
//     label: "Machinery",
//     group: "Machinery",
//     include_tags: [ind.Machinery],
//     exclude_tags: [exd.Machinery]
//   },
//   {
//     category: "Marketing & Advertising",
//     label: "Marketing & Advertising",
//     group: "Marketing & Advertising",
//     include_tags: [ind.MarketingAndAdvertising],
//     exclude_tags: [exd.MarketingAndAdvertising]
//   },
//   {
//     category: "Mechanical or Industrial Engineering",
//     label: "Mechanical or Industrial Engineering",
//     group: "Mechanical or Industrial Engineering",
//     include_tags: [
//       ind.IndustrialAutomation,
//       ind.MechanicalOrIndustrialEngineering
//     ],
//     exclude_tags: [
//       exd.IndustrialAutomation,
//       exd.MechanicalOrIndustrialEngineering

//     ]
//   },
//   {
//     category: "Media",
//     label: "Media",
//     group: "Media",
//     include_tags: [
//       ind.BroadcastMedia,
//       ind.MediaProduction,
//       ind.MotionPicturesAndFilm,
//       ind.Newspapers,
//       ind.OnlineMedia
//     ],
//     exclude_tags: [
//       exd.BroadcastMedia,
//       exd.MediaProduction,
//       exd.MotionPicturesAndFilm,
//       exd.Newspapers,
//       exd.OnlineMedia

//     ]
//   },
//   {
//     category: "Medical Devices",
//     label: "Medical Devices",
//     group: "Medical Devices",
//     include_tags: [ind.MedicalDevices],
//     exclude_tags: [exd.MedicalDevices]
//   },
//   {
//     category: "Medical Practice",
//     label: "Medical Practice",
//     group: "Medical Practice",
//     include_tags: [ind.MedicalPractice],
//     exclude_tags: [exd.MedicalPractice]
//   },
//   {
//     category: "Medicine / Pharmaceuticals",
//     label: "Medicine / Pharmaceuticals",
//     group: "Medicine / Pharmaceuticals",
//     include_tags: [ind.AlternativeMedicine, ind.Pharmaceuticals],
//     exclude_tags: [exd.AlternativeMedicine, exd.Pharmaceuticals]
//   },
//   {
//     category: "Military",
//     label: "Military",
//     group: "Military",
//     include_tags: [ind.DefenseAndSpace, ind.Military],
//     exclude_tags: [exd.DefenseAndSpace,exd.Military]
//   },
//   {
//     category: "Mining & Metals",
//     label: "Mining & Metals",
//     group: "Mining & Metals",
//     include_tags: [ind.MiningAndMetals],
//     exclude_tags: [exd.MiningAndMetals]
//   },
//   {
//     category: "Museums & Libraries",
//     label: "Museums & Libraries",
//     group: "Museums & Libraries",
//     include_tags: [ind.Libraries, ind.MuseumsAndInstitutions],
//     exclude_tags: [exd.Libraries, exd.MuseumsAndInstitutions]
//   },
//   {
//     category: "Music & Sound",
//     label: "Music & Sound",
//     group: "Music & Sound",
//     include_tags: [ind.Music],
//     exclude_tags: [exd.Music]
//   },
//   {
//     category: "Nanotechnology",
//     label: "Nanotechnology",
//     group: "Nanotechnology",
//     include_tags: [ind.Nanotechnology],
//     exclude_tags: [exd.Nanotechnology]
//   },
//   {
//     category: "Oil, Gas, & Energy",
//     label: "Oil, Gas, & Energy",
//     group: "Oil, Gas, & Energy",
//     include_tags: [ind.OilAndEnergy],
//     exclude_tags: [exd.OilAndEnergy]
//   },
//   {
//     category: "Outsourcing & Offshoring",
//     label: "Outsourcing & Offshoring",
//     group: "Outsourcing & Offshoring",
//     include_tags: [ind.OutsourcingOffshoring],
//     exclude_tags: [exd.OutsourcingOffshoring]
//   },
//   {
//     category: "Packaging & Containers",
//     label: "Packaging & Containers",
//     group: "Packaging & Containers",
//     include_tags: [ind.PackagingAndContainers],
//     exclude_tags: [exd.PackagingAndContainers]
//   },
//   {
//     category: "Paper Products",
//     label: "Paper Products",
//     group: "Paper Products",
//     include_tags: [ind.PaperAndForestProducts],
//     exclude_tags: [exd.PaperAndForestProducts]
//   },
//   {
//     category: "Performing Arts",
//     label: "Performing Arts",
//     group: "Performing Arts",
//     include_tags: [ind.PerformingArts],
//     exclude_tags: [exd.PerformingArts]
//   },
//   {
//     category: "Photography",
//     label: "Photography",
//     group: "Photography",
//     include_tags: [ind.Photography],
//     exclude_tags: [exd.Photography]
//   },
//   {
//     category: "Plastics & Rubber",
//     label: "Plastics & Rubber",
//     group: "Plastics & Rubber",
//     include_tags: [ind.Plastics],
//     exclude_tags: [exd.Plastics]
//   },
//   {
//     category: "Printing",
//     label: "Printing",
//     group: "Printing",
//     include_tags: [ind.Printing],
//     exclude_tags: [exd.Printing]
//   },
//   {
//     category: "Public Service",
//     label: "Public Service",
//     group: "Public Service",
//     include_tags: [
//       ind.LegislativeOffice,
//       ind.PoliticalOrganization,
//       ind.PublicPolicy,
//       ind.PublicRelationsAndCommunications,
//       ind.PublicSafety,
//       ind.ThinkTanks
//     ],
//     exclude_tags: [
//       exd.LegislativeOffice,
//       exd.PoliticalOrganization,
//       exd.PublicPolicy,
//       exd.PublicRelationsAndCommunications,
//       exd.PublicSafety,
//       exd.ThinkTanks
//     ]
//   },
//   {
//     category: "Publishing",
//     label: "Publishing",
//     group: "Publishing",
//     include_tags: [ind.Publishing],
//     exclude_tags: [exd.Publishing]
//   },
//   {
//     category: "Real Estate",
//     label: "Real Estate",
//     group: "Real Estate",
//     include_tags: [ind.CommercialRealEstate, ind.RealEstate],
//     exclude_tags: [exd.CommercialRealEstate, exd.RealEstate]
//   },
//   {
//     category: "Religious",
//     label: "Religious",
//     group: "Religious",
//     include_tags: [ind.ReligiousInstitutions],
//     exclude_tags: [exd.ReligiousInstitutions]
//   },
//   {
//     category: "Research",
//     label: "Research",
//     group: "Research",
//     include_tags: [ind.MarketResearch, ind.Research],
//     exclude_tags: [exd.MarketResearch, exd.Research]
//   },
//   {
//     category: "Restaurants & Bars",
//     label: "Restaurants & Bars",
//     group: "Restaurants & Bars",
//     include_tags: [ind.Restaurants],
//     exclude_tags: [exd.Restaurants]
//   },
//   {
//     category: "Retail",
//     label: "Retail",
//     group: "Retail",
//     include_tags: [ind.Retail, ind.SportingGoods, ind.Supermarkets],
//     exclude_tags: [exd.Retail, exd.SportingGoods, exd.Supermarkets]
//   },
//   {
//     category: "Sports",
//     label: "Sports",
//     group: "Sports",
//     include_tags: [ind.Sports],
//     exclude_tags: [exd.Sports]
//   },
//   {
//     category: "Telecommunications",
//     label: "Telecommunications",
//     group: "Telecommunications",
//     include_tags: [ind.Telecommunications, ind.Wireless],
//     exclude_tags: [exd.Telecommunications, exd.Wireless]
//   },
//   {
//     category: "Textiles",
//     label: "Textiles",
//     group: "Textiles",
//     include_tags: [ind.Textiles],
//     exclude_tags: [exd.Textiles]
//   },
//   {
//     category: "Tobacco",
//     label: "Tobacco",
//     group: "Tobacco",
//     include_tags: [ind.Tobacco],
//     exclude_tags: [exd.Tobacco]
//   },
//   {
//     category: "Transportation",
//     label: "Transportation",
//     group: "Transportation",
//     include_tags: [
//       ind.LogisticsAndSupplyChain,
//       ind.RailroadManufacture,
//       ind.Shipbuilding,
//       ind.TransportationTruckingRailroad
//     ],
//     exclude_tags: [
//       exd.LogisticsAndSupplyChain,
//       exd.RailroadManufacture,
//       exd.Shipbuilding,
//       exd.TransportationTruckingRailroad

//     ]
//   },
//   {
//     category: "Travel & Tourism",
//     label: "Travel & Tourism",
//     group: "Travel & Tourism",
//     include_tags: [ind.LeisureTravelAndTourism],
//     exclude_tags: [exd.LeisureTravelAndTourism]
//   },
//   {
//     category: "Utilities",
//     label: "Utilities",
//     group: "Utilities",
//     include_tags: [ind.Utilities],
//     exclude_tags: [exd.Utilities]
//   },
//   {
//     category: "Veterinary",
//     label: "Veterinary",
//     group: "Veterinary",
//     include_tags: [ind.Veterinary],
//     exclude_tags: [exd.Veterinary]
//   },
//   {
//     category: "Warehousing",
//     label: "Warehousing",
//     group: "Warehousing",
//     include_tags: [ind.Warehousing],
//     exclude_tags: [exd.Warehousing]
//   },
//   {
//     category: "Wholesale",
//     label: "Wholesale",
//     group: "Wholesale",
//     include_tags: [ind.Wholesale],
//     exclude_tags: [exd.Wholesale]
//   },
//   {
//     category: "Writing, Editing, Translation",
//     label: "Writing, Editing, Translation",
//     group: "Writing, Editing, Translation",
//     include_tags: [ind.TranslationAndLocalization, ind.WritingAndEditing],
//     exclude_tags: [exd.TranslationAndLocalization, exd.WritingAndEditing]
//   }
// ];
