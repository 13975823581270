import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../helpers/renderfield';
import Loader from '../../Loader';
import '../style.scss';
import { Fab } from '@material-ui/core';
const ResetPassContent = (props) => {
  const { validationError, visible, handleSubmit, responseError } = props;
  return (
    <div className="pass-cont">
      <div className="pass-cont__logo">
        <img src="/assest/accountBased/logo.svg" alt="" />
      </div>
      <div className="height-notification">
        {responseError &&
          <div className="error-div">
            <p>Sorry, we were unable to reset your password. Please try again later or contact help@leadfuze.co for further assistance.</p>
          </div>
        }
      </div>
      <div className="pass-cont__box">
        <h2 className={`pass-cont__title ${responseError ? 'mt-0' : ''}`}>Reset Password</h2>
        <form method="post" onSubmit={handleSubmit}>
          <div className="sign-in__cont input-field lists_table_loader">
            {visible && <Loader />}
            <label className="sign-in__label">
              <img src="/assest/sign-in/pass.svg" alt="" />
              <p>New Password</p>
              <Field name="pass" component={renderField} type="password" label="Please type your new password" validationError={validationError.pass} />
              {validationError &&
                <span className="error-red">{validationError.pass}</span>
              }
            </label>
            <div className="sign-in__cont input-field">
              <label className="sign-in__label">
                <img src="/assest/sign-in/pass.svg" alt="" />
                <p>Confirm Password</p>
                <Field name="confirm_pass" component={renderField} type="password" label="Please confirm your password" validationError={validationError.confirm_pass} />
                {validationError &&
                  <span className="error-red">{validationError.confirm_pass}</span>
                }
              </label>
            </div>
            <Fab
              type="submit"
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
            >Update Password</Fab>
          </div>
        </form>
      </div>
      <p className="pass-cont__text">Already have an account? <a className="pass-cont__sign" href="http://ec2-44-229-0-194.us-west-2.compute.amazonaws.com/signin">Sign in</a> to your LeadFuze account.</p>
    </div>
  )
}

export default reduxForm({
  form: 'reset-pass',
})(ResetPassContent)
