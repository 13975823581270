import React, { Component } from 'react'
import { Link } from 'react-router-dom'

const items = [
    {
        name: 'Integrations',
        slug: '/integrations',
        active: 'Integration_hover.svg',
        icon: 'Integration.svg',
    },
    {
        name: 'Profile',
        slug: '/profile',
        active: 'Profile_hover.svg',
        icon: 'Profile.svg',
    },
    {
        name: 'API',
        slug: '/api-keys',
        active: 'API_keys_hover.svg',
        icon: 'API_keys.svg',
    }
]

class Settings extends Component {
    state = {
        path: window.location.pathname
    }
    componentDidMount = () => {
        let pathArray = window.location.pathname.split('/');
        let secondLevelLocation = pathArray[1];
        let thirdLevelLocation = pathArray[2];
        items.map(item => {
            if (item.slug === "/" + secondLevelLocation + "/" + thirdLevelLocation) {
                this.setState({ path: item.slug })
            }
        })
    }
    changePath = (slug) => {
        this.setState({ path: slug })
    }
    render() {
        const { path } = this.state
        const { current } = this.props
        return (
            <ul className={`leads-search ${current ? "animation-slideDown" : ""}`}>
                {
                    items.map((item, index) =>
                        <Link to={item.slug} key={index}>
                            <li className={`leads-search__item ${path === item.slug ? 'active' : ''}`} onClick={() => this.changePath(item.slug)}>
                                <img src={`/assest/apiKeys/${path === item.slug ? item.active : item.icon}`} alt="" />
                                {item.name}
                            </li>
                        </Link>
                    )
                }
            </ul>
        )
    }
}

export default Settings