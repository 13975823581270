import React from 'react'

const FieldCheck = props =>{
  const { check, changeFieldCheck, descr, name } = props
  return(
    <div className="coupon-cont__check">
      <div className={`check ${check ? 'active' : ''}`} onClick={()=>changeFieldCheck(name)}></div>
      <p className={`${check ? 'active' : ''}`}>{descr}</p>
    </div>
  )
}

export default FieldCheck