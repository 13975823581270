import React, { Component, Fragment } from 'react';
// import './style.scss';
import { AddGroups } from '../../../MarkedBasedBox/MarketSearch/FieldsDropdown';
import "./style.scss";
var moment = require('moment');

class MaxCompanyResults extends Component {
  container = React.createRef();
  state = {
    maxResultOptions: [
        {category: 'No Limit', label: 'No Limit' },
        {category: 'Only 1', label: 'Only 1' },
        {category: 'Up to 2', label: 'Up to 2' },
        {category: 'Up to 3', label: 'Up to 3' },
        {category: 'Up to 5', label: 'Up to 5' },
        {category: 'Up to 10', label: 'Up to 10' },
        {category: 'Up to 25', label: 'Up to 25' },
        {category: 'Up to 100', label: 'Up to 100' },
        
    ],
    dropdownOpen: false,
  }
  
  componentDidMount() {
    document.addEventListener("click", this.clickEventHandle)
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.clickEventHandle)
  } 

  clickEventHandle = (event) => {
    if (this.container.current && !this.container.current.contains(event.target)) {
      this.setState({
        dropdownOpen: false,
      })
    }
  }

  addGroupSelectFrom = (maxResults) => {
    this.setState({dropdownOpen: false});
    this.props.setMaxResultsPerCompany(maxResults);
  }

  render() {
    const {
      searchResultFilters
    } = this.props;

    return (
      <div className="max-results-input-parent" ref={this.container} >
        {/* <h4 className="right-filters-company__title">Max Results per Company</h4> */}
        {/* <div className="right-filters-company__list animation-slideDown" > */}
          {<input type="text"
                placeholder="Max Results Per Company" 
                disabled={searchResultFilters.require_unemployment}
                onFocus={() => this.setState({dropdownOpen: true})}
                value={searchResultFilters.max_results_per_company}
                // onChange={setCompanyFoundedYearFrom}
                />}
{/* <div className="dropdown-in animation-slideDown"> */}
        {this.state.dropdownOpen?
          <AddGroups
            addGroupSelect={this.addGroupSelectFrom}
            roleGroups={this.state.maxResultOptions}
            finishRoleGroups={this.state.maxResultOptions}
            addExcludeTag={this.addExcludeTag}
            addTagInSelect={this.addExcludeTag}
            value={searchResultFilters.max_results_per_company}
            customInput={false}
            // showMessage={showMessage}
            name={'maxResultsPerCompany'}
            haveTags={false}
            label={'Max Results Per Company'}
            showExclude={false}
            excludeOption={false}
            // toggleExcludeOption={this.toggleExcludeOption}
          /> : null}

          {/* <div className="founded-date__line"></div> */}

        </div>
    //   </div>
    )
  }
}

export default MaxCompanyResults;