import React, {Component, Fragment} from 'react';
import SearchPopup from './SearchPopup';
import CreateNewSheet from './createNewSheet'
import {exportListGoogleSheet, getLeadListById, getUsersSheet} from '../../../../services/leads'
import '../style.scss';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {Loader} from '../../..';
import {toast} from 'react-toastify';
import $ from 'jquery';
import {showErrorToast, showSuccessToast} from '../../../../helpers/toast';
import ColumnsReadOnly from './columns-read-only';
import {exportLeadsToGoogleSheet} from '../../../../services/insideList';
import {startLoader, stopLoader} from '../../../../actions/loader'
import {LOCAL_STORAGE_KEYS} from '../../../../constants/constant';

class ExportToGoogleSheet extends Component {
    state = {
        active: false,
        value: "",
        popupCreateNewSheet: false,
        sheetNames: [],
        isAuth: false,
        email: '',
        sheetID: '',
        columns: [],
        leadListDetail: undefined,
        integratedSheetId: undefined,
        integratedSheetName: '',
        automate: false,
        loading: true,

    }

    componentDidMount() {
        var that = this;
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        this.setState({email: user.email})
        const {integration, id} = that.props
        if (id) {
            try {
                getLeadListById(id).then(response => {
                    if (response.data.data && response.data.data.integrations_info.google_sheets_info) {
                        const googleSheetId = response.data.data.integrations_info.google_sheets_info.sheet_id;
                        const automate = response.data.data.integrations_info.google_sheets_info.automate;
                        that.setSelectedGoogleSheetName(googleSheetId, that.state.sheetNames);
                        that.setState({leadListDetail: response.data.data, integratedSheetId: googleSheetId, automate});
                    }
                });
            } catch (e) {
                console.error(e);
            }
        }
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        if (integration.integration.data) {
            let integrations = integration.integration.data.data
            let sheetIntegration = integrations.filter(function (el) {
                return el.name === "googlesheets"
            })
            if (sheetIntegration.length > 0) {
                //that.setSelectedGoogleSheetName(that.state.integratedSheetId, this.props.sheetNames);
                that.setState({isAuth: true, sheetNames: this.props.sheetNames, active: false})
                // let toastId = null;
                // let errormsg
                // toastId = toast('Fetching User Google Sheets', {
                //     autoClose: 10000,
                // });
                that.setState({isAuth: true})
                getUsersSheet(userId).then((response) => {
                    //toast.done(toastId);
                    // $(".Toastify").addClass("mes-done");
                    //     toast.update(toastId, {
                    //         className: "toast-success-div",
                    //         render: "Google Sheets Fetched",
                    //         type: toast.TYPE.SUCCESS,
                    //         autoClose: 1000,
                    //     });

                    if (response.data.data && response.data.data.length) {
                        that.setSelectedGoogleSheetName(that.state.integratedSheetId, response.data.data);
                        that.setState({loading: false, sheetNames: response.data.data, active: true})
                    }
                }).catch((error) => {
                    const toastId = toast('Unable to load Google Sheets', {
                        className: "toast-error-div",
                        type: toast.TYPE.ERROR
                    });
                    toast.done(toastId)
                    $(".Toastify").addClass("mes-done");
                    setTimeout(function () {
                        toast.dismiss(toastId)
                        $(".Toastify").removeClass("mes-done");
                    }, 3000)
                    this.setState({loading: false})
                });
            } else {
                this.setState({loading: false})
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sheetNames.length === this.props.sheetNames.length) {
            return;
        }
        var that = this;
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        this.setState({email: user.email})
        const {integration, id} = that.props
        if (id) {
            try {
                getLeadListById(id).then(response => {
                    if (response.data.data && response.data.data.integrations_info.google_sheets_info) {
                        const googleSheetId = response.data.data.integrations_info.google_sheets_info.sheet_id;
                        const automate = response.data.data.integrations_info.google_sheets_info.automate;
                        that.setSelectedGoogleSheetName(googleSheetId, that.state.sheetNames);
                        that.setState({leadListDetail: response.data.data, integratedSheetId: googleSheetId, automate});
                    }
                });
            } catch (e) {
                console.error(e);
            }
        }
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        if (integration.integration.data) {
            let integrations = integration.integration.data.data
            let sheetIntegration = integrations.filter(function (el) {
                return el.name === "googlesheets"
            })
            if (sheetIntegration.length > 0) {
                that.setSelectedGoogleSheetName(that.state.integratedSheetId, this.props.sheetNames);
                that.setState({isAuth: true, sheetNames: this.props.sheetNames, active: true})
            }
        }
    }

    setSelectedGoogleSheetName = (sheetId, googleSheets) => {
        if (sheetId) {
            const foundSheet = googleSheets.find(sheet => sheet.sheet_id === sheetId);
            if (foundSheet) {
                this.setState({integratedSheetName: foundSheet.name, value: foundSheet.name, sheetID: sheetId});
            }
        }
    }
    openNewSheetPopup = () => {
        this.setState({popupCreateNewSheet: !this.state.popupCreateNewSheet})
    }
    openListName = async () => {
        this.setState({active: !this.state.active});
    }
    handleChange = (e) => {
        this.setState({value: e.target.value})
    }
    columnValue = (val, sheetID, exportColumns) => {
        this.setState({value: val, sheetID: sheetID, columns: exportColumns, active: false})
    }


    changeEmail = (e) => {
        this.setState({email: e.target.value})
    }

    changeAutomate = () => {
        this.setState({automate: !this.state.automate})
    }
    onUpdateClick = async () => {

        const {email, sheetID, value} = this.state
        const {openExpGooglePopup, id, type} = this.props
        const {searchQuery, selectAll, selectedIds, deSelectedIds, sheetName, insideListFilters} = this.props
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        let toastId = null;
        let errormsg
        toastId = toast(`Exporting to Google Sheets`, {
            autoClose: 10000,
        });
        try {
            if (!this.props.insideListFilters) {
                await exportListGoogleSheet(email, id, this.state.columns, this.state.automate, sheetID, value)
            } else {
                let query_filters = {
                    added_by_fuzebot: this.props.insideListFilters.addedByFuzebot,
                    has_email: this.props.insideListFilters.hasEmail,
                    personal_email: this.props.insideListFilters.personalEmail,
                    company_email: this.props.insideListFilters.companyEmail,
                    single_verified_email: this.props.insideListFilters.singleVerifiedEmail,
                    double_verified_email: this.props.insideListFilters.doubleVerifiedEmail,
                    has_linkedin: this.props.insideListFilters.hasLinkedIn,
                    has_phone: this.props.insideListFilters.hasPhone,
                    has_mailing_address: this.props.insideListFilters.hasMailingAddress,
                    date_created_from: this.props.insideListFilters.from,
                    date_created_to: this.props.insideListFilters.to

                }
                await exportLeadsToGoogleSheet(this.state.automate, id, email, searchQuery, query_filters, selectAll, selectedIds, deSelectedIds, value, sheetID, this.state.columns)
            }
            showSuccessToast(toastId, `Export started. Check your email in a few minutes.`);
            openExpGooglePopup();
        } catch (error) {
            if (!error.response) {
                errormsg = `Unable to export to Google Sheets`
            } else if (error.response && error.response.data.errCode === 30120) {
                errormsg = 'Permission denied'
            } else {
                errormsg = `Unable to export to Google Sheets`
            }
            showErrorToast(toastId, errormsg);
            openExpGooglePopup();
        }
    }

    render() {
        const {active, value, popupCreateNewSheet, sheetNames, isAuth, sheetID, email, loading} = this.state
        const {popupExpGoogle, openExpGooglePopup, id, loader} = this.props;
        return (
            <Fragment>
                {loader.loading && <Loader/>}
                <div className={`add-list ${popupExpGoogle ? 'open' : ''}`}>
                    <div className={`add-list__content wide ${loader.loading ? "disable-btn" : ''} `}>
                        <img className="add-list__close" src="/assest/accountBased/close.svg" alt=""
                             onClick={openExpGooglePopup}/>
                        <p className="add-list__title">Export To Google Sheet</p>
                        <div className="sheet-block">
                            <div className="inner-sheet sheet-left">
                                <p className="add-list__label field-margin">Email</p>
                                <input className="add-list__search" placeholder="Enter Email" type="email" disabled
                                       value={email} onChange={this.changeEmail}/>

                                <p className="add-list__label">ADD TO EXISTING SHEET?</p>
                                <div className={`add-list__search-box ${active ? 'active' : ''}`}
                                     onClick={this.openListName}>
                                    <input className="add-list__search"
                                           placeholder="No Sheet Selected"
                                           type="text"
                                           value={value}
                                           disabled={true}
                                           onChange={this.handleChange}
                                           required/>
                                    <img src="/assest/accountBased/role-arr.svg" alt=""/>
                                </div>
                                {(
                                    <SearchPopup loading={loading} active={active} columnValue={this.columnValue}
                                                 sheetNames={sheetNames}/>
                                )
                                }

                                {sheetID ?
                                    (
                                        <ColumnsReadOnly columns={this.state.columns}></ColumnsReadOnly>
                                    ) : null}

                                {
                                    (this.state.integratedSheetId && this.state.integratedSheetName) ? (
                                        <div>
                                            <p className="add-list__label field-margin">Connected Sheet</p>
                                            <div className="orange-text sheet-name">
                                                <p className="add mv-20">
                                                    <a href={`https://docs.google.com/spreadsheets/d/${this.state.integratedSheetId}`}
                                                       rel="noopener noreferrer" target="_blank">
                                                        {this.state.integratedSheetName}
                                                        <img src="/assest/inside/export.svg" alt="newWindoIcon"
                                                             style={{marginLeft: '10px'}}/></a></p>
                                            </div>
                                        </div>
                                    ) : null
                                }

                                {this.props.id ? (<div className="right-filters__check check-margin"
                                                       onClick={() => this.changeAutomate()}>
                                    <div className={`check ${this.state.automate ? 'active' : ''}`}>
                                    </div>
                                    <p>Automatically add new leads to this sheet</p>
                                </div>) : null}
                                <div className="coupon__links">
                                    <p className="add mv-20" onClick={this.openNewSheetPopup}>
                                        <img src="/assest/accountBased/plus-orange.svg" alt=""/>Or Create New Sheet</p>
                                </div>
                                <div className="add-list__buttons">
                                    <div className={`add-list__btn ${!isAuth || !sheetID ? "disable-btn" : ''}`}
                                         onClick={() => {
                                             this.onUpdateClick()
                                         }}>Add To Sheet
                                    </div>
                                    <div className="add-list__btn" onClick={openExpGooglePopup}>Cancel</div>
                                </div>
                            </div>
                            <div className="inner-sheet sheet-right">
                                {/* <p className="add-list__label">SHEET NAME</p>
                                <p className="orange-text">{value}</p> */}
                            </div>
                        </div>
                    </div>
                    <CreateNewSheet sheetID={sheetID} openExpGooglePopup={this.props.openExpGooglePopup}
                                    openNewSheetPopup={this.openNewSheetPopup} popupCreateNewSheet={popupCreateNewSheet}
                                    isAuth={isAuth} listId={id}
                                    {...this.props}
                    />
                </div>
            </Fragment>

        )
    }
}

function mapStateToProps(state) {
    return {
        loader: state.loader,
        integration: state.integration,
        user: state.user
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        startLoader,
        stopLoader,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportToGoogleSheet);
