import React, {Component, Fragment} from 'react';
import BillingPlanTerm from "./BillingPlanTerm";
import BillingPriceList from "./BillingPriceList";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router";
import {setBillingOverview,} from '../../actions/billing';
import './style.scss';
import {createSubscription, getBillingOverview, validateCoupon} from '../../services/billing';
import Loader from '../Loader';
import {toast} from 'react-toastify';
import moment from "moment";
import {loadStripe} from '@stripe/stripe-js';

class BillingTrial extends Component {

    state = {
        selectedPlan: '',
        selectedTerm: 'starterMTM',
        consent: false,
        coupon: '',
        couponApplied: undefined,
        isLoading: false,
        trialPlans: undefined,
        isUserOnActiveTrial: false,
        isShowCoupon: false,
    };

    componentDidMount() {
        this.loadBillingOverview();
        this.isUserOnActiveTrial();
    }

    loadBillingOverview = async () => {
        this.setState({isLoading: true});
        const billingData = await getBillingOverview();
        this.setState({isLoading: false, selectedPlan: 'Starter',});
        this.props.setBillingOverview(billingData.data.data);
        this.filterTrialPlans(billingData.data.data.plans)
    }

    filterTrialPlans = (plans) => {
        const trialPlans = {
            commitment500: plans.commitment.find(commitPlan => commitPlan.id === 'commitment_1019_500'),
            commitment2500: plans.commitment.find(commitPlan => commitPlan.id === 'commitment_1019_2500'),
            monthly500: plans.monthly.find(monthlyPlan => monthlyPlan.id === 'monthly_1019_500'),
            monthly2500: plans.monthly.find(monthlyPlan => monthlyPlan.id === 'monthly_1019_2500'),
            annual500: plans.annual.find(annualPlan => annualPlan.id === 'annual_1019_500'),
            annual2500: plans.annual.find(annualPlan => annualPlan.id === 'annual_1019_2500'),
        };

        const trialBillingTerms = {
            Starter: [
                {
                    id: "starterMTM",
                    amount: trialPlans.monthly500.amount / 100,
                    name: `(billed monthly, cancel any time)`,
                    plan: trialPlans.monthly500,
                    type: 'is_monthly',
                    leads: 500
                },
                {
                    id: "starterMonthly",
                    amount: trialPlans.commitment500.amount / 100,
                    name: `(billed monthly, 12 month commitment)`,
                    plan: trialPlans.commitment500,
                    type: 'is_committed',
                    leads: 500
                },
                {
                    id: "starterAnnual",
                    amount: trialPlans.annual500.amount / 100,
                    name: `(billed annually, get all lead credits upfront)`,
                    plan: trialPlans.annual500,
                    type: 'is_annual',
                    leads: 500
                },
            ],

            Scaling: [
                {
                    id: "scalingMonthly",
                    amount: trialPlans.commitment2500.amount / 100,
                    name: `(billed monthly, 12 month commitment)`,
                    plan: trialPlans.commitment2500,
                    type: 'is_committed',
                    leads: 2500
                },
                {
                    id: "scalingMTM",
                    amount: trialPlans.monthly2500.amount / 100,
                    name: `(billed monthly, cancel any time)`,
                    plan: trialPlans.monthly2500,
                    type: 'is_monthly',
                    leads: 2500
                },
                {
                    id: "scalingAnnual",
                    amount: trialPlans.annual2500.amount / 100,
                    name: `(billed annually, get all lead credits upfront)`,
                    plan: trialPlans.annual2500,
                    type: 'is_annual',
                    leads: 2500
                },
            ]
        }
        this.setState({trialBillingTerms});
    }

    isUserOnActiveTrial = () => {
        if (this.props.userProfileInfo.userInfo.accounts && (!this.props.userProfileInfo.userInfo.accounts[0].status || this.props.userProfileInfo.userInfo.accounts[0].status === 'active_trial')) {
            const diffInDays = moment(this.props.userProfileInfo.userInfo.accounts[0].trial_expiration_date).diff(moment(), "days");
            if (diffInDays >= 0) {
                this.setState({isUserOnActiveTrial: true});
                return true;
            }
        }
        this.setState({isUserOnActiveTrial: false});
        return false;
    }

    onChangePlan = (plan) => {
        this.setState({selectedPlan: plan});
        const currentSelectedTerm = this.state.selectedTerm.split(plan === "Starter" ? 'scaling' : 'starter');
        this.setState({selectedTerm: plan.toLowerCase() + currentSelectedTerm[1]});
        //  if (plan === "Starter") {
        //    this.setState({selectedTerm: "starterMonthly"});
        //   } else {
        //     this.setState({selectedTerm: "scalingMonthly"});
        //  }
    }

    onChangeTerm = (term) => {
        this.setState({selectedTerm: term});
    }

    setCouponText = (e) => {
        this.setState({coupon: e.target.value});
    }

    showCoupon = () => {
        this.setState({isShowCoupon: true});
    }

    applyCoupon = async () => {
        let toastId = null;
        try {
            toastId = toast('Applying Coupon', {autoClose: 10000});

            const couponRes = await validateCoupon(this.state.coupon);

            // if(couponRes.data.data.is_stripe) {
            this.setState({couponApplied: couponRes.data.data});
            // }

            setTimeout(() => {
                toast.update(toastId, {
                    className: "toast-success-div",
                    render: "Applied Coupon successfully",
                    type: toast.TYPE.SUCCESS,
                    autoClose: 1300,
                });
            }, 200);
        } catch (e) {
            this.setState({couponApplied: undefined});
            setTimeout(() => {
                toast.update(toastId, {
                    className: "toast-error-div",
                    render: "Coupon is not valid",
                    type: toast.TYPE.ERROR,
                    autoClose: 1300,
                });
            }, 200);
        }
    }

    toggleConsent = () => {
        this.setState({consent: !this.state.consent});
    }

    subscribeToPlan = async () => {
        if (this.state.consent) {
            const selectedBillingTerm = this.state.trialBillingTerms[this.state.selectedPlan].find(billingTerm => billingTerm.id === this.state.selectedTerm);
            this.setState({isLoading: true});
            const toastId = toast('Redirecting to secure checkout', {autoClose: 10000});
            const response = await createSubscription(selectedBillingTerm.plan.id, this.state.couponApplied ? this.state.couponApplied.name : '');
            this.setState({isLoading: false});
            const subscriptionId = response.data.data;
            var key = 'pk_test_V9Nr5IkMdwcs981fou4K7LIU'
            if (window.location.host.indexOf("app.") > -1) {
                key = "pk_live_IplF3Obt6CUpzlyrWrKpr7JY"
            }
            const stripePromise = await loadStripe(key);
            stripePromise.redirectToCheckout({
                sessionId: subscriptionId
            }).then(function (result) {
            });

            setTimeout(() => {
                toast.update(toastId, {
                    className: "toast-success-div",
                    render: "Payment form loaded successfully",
                    type: toast.TYPE.SUCCESS,
                    autoClose: 1300,
                });
            }, 500);
        }
    }

    render() {
        return (
            <Fragment>
                <div className="billing-trial-panel">
                    {this.state.isLoading && <Loader/>}

                    <div className="billing-trial-panel__left">
                        <BillingPriceList
                            selectedPlan={this.state.selectedPlan}
                            onChangePlan={this.onChangePlan}
                            selectedTerm={this.state.selectedTerm}
                            coupon={this.state.coupon}
                            couponApplied={this.state.couponApplied}
                            setCouponText={this.setCouponText}
                            applyCoupon={this.applyCoupon}
                        />
                        <div className="couponSection">
                            {!this.state.isShowCoupon ?
                                <label className="title" onClick={this.showCoupon}>Have a coupon ? </label> :

                                <div className="billing-section">
                                    <div className="input-group">
                                        <input type="text" placeholder="Enter Coupon" value={this.state.coupon}
                                               onChange={this.setCouponText}/>
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button"
                                                    onClick={this.applyCoupon}
                                                    disabled={this.state.coupon.length === 0}>APPLY
                                            </button>
                                        </div>
                                    </div>
                                    {this.state.couponApplied ?
                                        <p className="applied-coupon">{this.state.couponApplied.name} coupon
                                            applied</p> : null}
                                </div>}
                        </div>
                    </div>

                    <div className="billing-trial-panel__right">
                        {this.state.trialBillingTerms &&
                        <BillingPlanTerm
                            consent={this.state.consent}
                            coupon={this.state.coupon}
                            couponApplied={this.state.couponApplied}
                            selectedPlan={this.state.selectedPlan}
                            selectedTerm={this.state.selectedTerm}
                            billingTerms={this.state.trialBillingTerms}
                            onChangePlan={this.onChangePlan}
                            onChangeTerm={this.onChangeTerm}
                            setCouponText={this.setCouponText}
                            applyCoupon={this.applyCoupon}
                            toggleConsent={this.toggleConsent}
                            subscribeToPlan={this.subscribeToPlan}
                            // isUserOnActiveTrial={this.isUserOnActiveTrial}
                            isTrialExpired={this.props.isTrialExpired}
                        />
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setBillingOverview
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        user: state.user,
        billing: state.billing,
        userProfileInfo: state.userProfileInfo
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BillingTrial));