import React, {Component} from 'react';
import {Fab} from "@material-ui/core";
import './style.scss';

import {getBillingPlans} from '../BillingPlanAndTerm';

class BillingPlanTerm extends Component {

    state = {
        billingPlans: getBillingPlans()
    };

    getDiscountedAmount(item) {
        if (this.props.couponApplied && this.props.couponApplied.is_stripe && this.props.couponApplied[item.type]) {
            const couponCredit = this.props.couponApplied.lead_credits;
            const isPercentage = this.props.couponApplied.is_lead_credits_percentage;
            let discountedPrice;
            if (isPercentage) {
                discountedPrice = Number(item.amount - (item.amount * couponCredit / 100)).toFixed(2);
            } else {
                discountedPrice = Number(item.amount - (couponCredit / 100)).toFixed(2);
            }
            return discountedPrice;
        }
        return item.amount.toFixed(2);
    }

    getTrialText(item) {
        let tagText = '';
        if (item.type === 'is_committed') {
            tagText = !this.props.isTrialExpired ? 'Trial only offer: Double lead credits to ' + item.leads * 2 : '20% extra leads';
        } else if (item.type === 'is_annual') {
            tagText = !this.props.isTrialExpired ? 'Trial only offer: Triple lead credits to ' + item.leads * 3 : '50% extra leads';
        }
        return tagText;
    }

    render() {
        return (
            <div className="billing-plan-term-panel">
                <label className="title">Choose Your Plan:</label>

                <div className="billing-section">
                    {this.state.billingPlans.map((item, index) =>
                        <label onClick={() => this.props.onChangePlan(item.name)}
                               className={`radio-btn ${item.name === this.props.selectedPlan ? 'active' : ''}`}
                               key={`billingPlan-${index}`}>
                            <div className={`radio-btn__check`}><span></span></div>
                            <p>{item.name} ({item.description})</p>
                        </label>
                    )}
                </div>


                {/* <label className="title">Coupon:</label>

                <div className="billing-section">
                    <div className="input-group">
                        <input type="text" placeholder="Enter Coupon" value={this.props.coupon}
                               onChange={this.props.setCouponText}/>
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" onClick={this.props.applyCoupon}
                                    disabled={this.props.coupon.length === 0}>APPLY
                            </button>
                        </div>
                    </div>
                    {this.props.couponApplied ?
                        <p className="applied-coupon">{this.props.couponApplied.name} coupon applied</p> : null}
                </div> */}

                <label className="title">Choose Your Schedule:</label>

                <div className="billing-section">
                    {this.props.billingTerms[this.props.selectedPlan].map((item, index) =>
                        <label key={`billingTerm-${index}`}>
                            <div onClick={() => this.props.onChangeTerm(item.id)}
                                 className={`radio-btn ${item.id === this.props.selectedTerm ? 'active' : ''}`}>

                                <div className={`radio-btn__check`}><span></span></div>
                                <p>${this.getDiscountedAmount(item)} {item.name}</p>
                            </div>
                            {this.getTrialText(item)
                            &&
                            <div className="tag-div">
                                <p className="bonus-leads">{this.getTrialText(item)}</p>
                            </div>
                            }
                        </label>
                    )}
                </div>

                

                <div className="billing-consent-panel">
                    <label className="title">Agree To Terms:</label>

                    <div className="terms_agree__check">
                        <div className={`check ${this.props.consent ? 'active' : ''}`}
                             onClick={this.props.toggleConsent}></div>
                        <p>By purchasing, you agree to the <a href="https://leadFuze.com/terms-use" target="_blank">terms
                            of service</a>.</p>
                    </div>

                    <Fab
                        disabled={!this.props.consent}
                        variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="add"
                        onClick={this.props.subscribeToPlan}>
                        Subscribe to {this.props.selectedPlan}
                    </Fab>

                </div>
            </div>
        )
    }
}

export default BillingPlanTerm;