import React from 'react'
import inputs from './inputs'
import '../style.scss'

const FindLeads1left = (props) => {
    const {step1, fname, lname, pass, getValue} = props
    const nextTab = () => {
        if (lname && fname && pass) {
            step1()
        } else {
            alert('not fields')
        }
    }

    return (
        <div className="find-leads">
            <img className="find-leads__logo" src="/assest/accountBased/logo.svg" alt=""/>
            <h2 className="find-leads__title">Find New Leads. Instantly.</h2>
            <div className="find-leads__box">
                {
                    inputs.map((item) =>
                        <label className="find-leads__label" key={`${item.name}-id`}>
                            <p>{item.label}</p>
                            <input name={item.name}
                                   value={
                                       item.name === 'fname' ? fname :
                                           item.name === 'lname' ? lname :
                                               item.name === 'pass' ? pass : ''
                                   }
                                   type={item.type}
                                   placeholder={item.placeholder}
                                   onChange={getValue}/>
                        </label>
                    )
                }
            </div>
            <div className="find-leads__buttons">
                <div className="find-leads__btn" onClick={nextTab}>Next Step</div>
            </div>
        </div>
    )
}

export default FindLeads1left