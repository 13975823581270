import React, { Component, Fragment } from 'react';
import FileDrop from 'react-file-drop';
import Loader from '../../../Loader';

class ProfilePicUpdate extends Component {



    onClosePopup = () => {
        const { editProfilePopup } = this.props
        editProfilePopup()
    }

    render() {
        const { profilePopup, pictureUpload, picSelected, picUpdateError, picUpdated } = this.props;
        return (
            <Fragment>
                <div className={`add-list ${profilePopup ? 'open' : ''}`}>
                    <div className="add-list__content">
                        <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={this.onClosePopup} />
                        <p className="add-list__title">Update Profile Picture</p>
                        {!picUpdated && !picUpdateError &&
                            <FileDrop onDrop={(file) => pictureUpload(file, "drop")}>
                                <div className="uploads__box">
                                    {picSelected &&
                                        <Loader uploading={picSelected} />
                                    }
                                    <p className="uploads__descr">Drag & Drop file here</p>
                                    <p className="uploads__text">or</p>
                                    <label htmlFor="forFileUpload" className="uploads__btn">Add File</label>
                                    <input type="file" id="forFileUpload" onChange={(file) => pictureUpload(file, "input")} />
                                    <p className="uploads__types">Upload a jpg, jpeg or png files</p>
                                </div>
                            </FileDrop>
                        }
                        {picUpdated &&
                            <div className="uploads__success-box">
                                <img src="/assest/apiKeys/check-symbol.svg" alt="" />
                                <p>profile picture uploaded successfully</p>
                            </div>
                        }
                        {picUpdateError &&
                            <div className="uploads__error-box">
                                <img src="/assest/apiKeys/Close_red.svg" alt="" />
                                <p>Update Picture error</p>
                            </div>
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default ProfilePicUpdate;
