import news_f from '../files/news_f.json';

export const news_groups = news_f.map(item => {
  return {
    category: item,
    label: item,
    group: item,
    include_tags: [],
    exclude_tags: [],
  }
})

// export const news_groups = [
//   {
//     category: "ClosingOffice",
//     label: "Closing Office",
//     group: "Closing Office",
//     include_tags: ["closes_offices_in"],
//     exclude_tags: []
//   },
//   {
//     category: "CompanyAcquisition",
//     group: "Company Acquisition",
//     label: "Company Acquisition",
//     include_tags: ["acquires", "was_acquired_by", "buys_assets_from"],
//     exclude_tags: []
//   },
//   {
//     category: "CompanyBuyingAssets",
//     label: "Company Buying Assets",
//     group: "Company Buying Assets",
//     include_tags: ["purchases_from"],
//     exclude_tags: []
//   },
//   {
//     category: "CompanyMerger",
//     label: "Company Merger",
//     group: "Company Merger",
//     include_tags: ["merges_with"],
//     exclude_tags: []
//   },
//   {
//     category: "CompanySellingAssets",
//     label: "Company Selling Assets",
//     group: "Company Selling Assets",
//     include_tags: ["sells_assets_to"],
//     exclude_tags: []
//   },
//   {
//     category: "EmployeeGrowth",
//     label: "Employee Growth",
//     group: "Employee Growth",
//     include_tags: ["increases_headcount_by"],
//     exclude_tags: []
//   },
//   {
//     category: "ExpandingOffices",
//     label: "Expanding Offices",
//     group: "Expanding Offices",
//     include_tags: [
//       "expands_offices_to",
//       "expands_offices_in",
//       "expands_facilities",
//       "opens_new_location"
//     ],
//     exclude_tags: []
//   },
//   {
//     category: "Hiring",
//     label: "Hiring",
//     group: "Hiring",
//     include_tags: ["hires"],
//     exclude_tags: []
//   },
//   {
//     category: "IPO",
//     label: "IPO",
//     group: "IPO",
//     include_tags: ["goes_public"],
//     exclude_tags: []
//   },
//   {
//     category: "Investing",
//     label: "Investing",
//     group: "Investing",
//     include_tags: ["invests_into", "invests_into_assets"],
//     exclude_tags: []
//   },
//   {
//     category: "KeyCompetitor",
//     label: "Key Competitor",
//     group: "Key Competitor",
//     include_tags: ["identified_as_competitor_of"],
//     exclude_tags: []
//   },
//   {
//     category: "KeyDepartures",
//     label: "Key Departures",
//     group: "Key Departures",
//     include_tags: ["leaves"],
//     exclude_tags: []
//   },
//   {
//     category: "KeyPromotions",
//     label: "Key Promotions",
//     group: "Key Promotions",
//     include_tags: ["promotes"],
//     exclude_tags: []
//   },
//   {
//     category: "KeyRetirements",
//     label: "Key Retirements",
//     group: "Key Retirements",
//     include_tags: ["retires_from"],
//     exclude_tags: []
//   },
//   {
//     category: "Lawsuit",
//     label: "Lawsuit",
//     group: "Lawsuit",
//     include_tags: ["files_suit_against", "has_issues_with", "is_sued_by"],
//     exclude_tags: []
//   },
//   {
//     category: "Layoffs",
//     label: "Layoffs",
//     group: "Layoffs",
//     include_tags: ["decreases_headcount_by"],
//     exclude_tags: []
//   },
//   {
//     category: "NewCustomer",
//     label: "New Customer",
//     group: "New Customer",
//     include_tags: ["signs_new_client"],
//     exclude_tags: []
//   },
//   {
//     category: "NewDevelopment",
//     label: "New Development",
//     group: "New Development",
//     include_tags: ["is_developing"],
//     exclude_tags: []
//   },
//   {
//     category: "NewIntegration",
//     label: "New Integration",
//     group: "New Integration",
//     include_tags: ["integrates_with"],
//     exclude_tags: []
//   },
//   {
//     category: "NewLaunch",
//     label: "New Launch",
//     group: "New Launch",
//     include_tags: ["launches"],
//     exclude_tags: []
//   },
//   {
//     category: "NewPartnership",
//     label: "New Partnership",
//     group: "New Partnership",
//     include_tags: ["partners_with"],
//     exclude_tags: []
//   },
//   {
//     category: "ReceivedAward",
//     label: "Received Award/Recognition",
//     group: "Received Award/Recognition",
//     include_tags: ["receives_award", "recognized_as"],
//     exclude_tags: []
//   },
//   {
//     category: "ReceivedFunding",
//     label: "Received Funding",
//     group: "Received Funding",
//     include_tags: ["receives_financing", "receives_financing_from"],
//     exclude_tags: []
//   }
// ];
