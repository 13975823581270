import React, { Component } from "react";
import Functions from "../FieldsDropdown/Functions";
import inputsPeople, { companyDropdowns, peopleDropdowns } from "./inputsPeople";
import "./style.scss";
import { adwords_budget } from "../../../../tags/adwords_budget";
import { role_groups } from "../../../../tags/role_groups";
import { industry_groups } from "../../../../tags/industry_groups";
import { hiring_groups } from "../../../../tags/hiring_groups";
import { employee_size } from "../../../../tags/employee_size";
import { news_groups } from "../../../../tags/news_groups";
import { yearsOfExperience } from "../../../../tags/years_of_experience";
import { estimatedSalary } from "../../../../tags/estimated_salary";
import CollegeDropdown from "../FieldsDropdown/Functions/collegeDropdown";
import { timeInLatestJob } from "../../../../tags";

class PeopleMarket extends Component {

  getParticularGroupsProp = (name) => {
    if (name === 'role') {
      const role = this.props.marketBased.role;
      if(role && role.groups) {
        const selectedGroups = [];
        role_groups.map(item => {
          const group = role.groups[item.group];
           if (group) {
            selectedGroups.push({...item, open: group.open,  tags: group.include_keywords? group.include_keywords : [],
              include_tags: group.include_keywords? group.include_keywords : item.include_tags,
              exclude_tags: group.exclude_keywords? group.exclude_keywords : item.exclud_tags,
              excludeTags: group.exclude_keywords? group.exclude_keywords : []});
           }
        });
        return selectedGroups;
      }
    } else if (name === 'adwords') {
      const adwords = this.props.marketBased.adwords_budget;
      if(adwords && adwords.length) {
        const adwordGroups = adwords_budget.filter(item => {
          const index = adwords.indexOf(item.group);
          return index > -1;
        });
        return adwordGroups;
      }
    } else if (name === 'technologies') {
      const technologies = this.props.marketBased.technology;
      if(technologies && technologies.length) {
        return technologies;
      }
    } else if (name === 'industry') {
      const industries = this.props.marketBased.industries;
      if (industries && industries.length) {
        const industryGroups = industry_groups.filter(item => {
          const index = industries.indexOf(item.group);
          return index > -1;
        });
        return industryGroups;
      }
    } else if (name === 'hiring') {
      const hiring = this.props.marketBased.hiring;
      if (hiring && hiring.groups && hiring.groups.length) {
        const hiringGroups = hiring_groups.filter(item => {
          const index = hiring.groups.indexOf(item.group);
          return index > -1;
        });
        return hiringGroups;
      }
    } else if (name === 'employee') {
      const employees = this.props.marketBased.employees;
      if(employees) {
        const selectedGroups = [];
        employee_size.map(item => {
           if (employees[item.group]) {
            selectedGroups.push({...item, tags: employees[item.group], 
              include_tags: employees[item.group]? employees[item.group] : item.include_tags,
              open: (employees.openOrClose? employees.openOrClose[item.group]: false) , excludeTags: []});
           }
        });
        return selectedGroups;
      }
    } else if (name === 'news') {
      const news = this.props.marketBased.news;
      if (news && news.length) {
        const newsGroups = news_groups.filter(item => {
          const index = news.indexOf(item.group);
          return index > -1;
        });
        return newsGroups;
      }
    } else if (name === 'yearsOfExperience') {
      const yearsOfExperienceSelected = this.props.marketBased.yearsOfExperience;
      if (yearsOfExperienceSelected && yearsOfExperienceSelected.length) {
        const yearsOfExperienceGroups = yearsOfExperience.filter(item => {
          const found = yearsOfExperienceSelected.find(yearsOfExp => yearsOfExp.group === item.group);
          return !!found;
        });
        return yearsOfExperienceGroups;
      }
    } else if (name === 'timeInLatestJob') {
      const timeInLatestJobSelected = this.props.marketBased.timeInLatestJob;
      if (timeInLatestJobSelected && timeInLatestJobSelected.length) {
        const timeInLatestJobGroups = timeInLatestJob.filter(item => {
          const found = timeInLatestJobSelected.find(yearsOfExp => yearsOfExp.group === item.group);
          return !!found;
        });
        return timeInLatestJobGroups;
      }
    } else if (name === 'estimatedSalary') {
      const estimatedSalarySelected = this.props.marketBased.estimatedSalary;
      if (estimatedSalarySelected && estimatedSalarySelected.length) {
        const estimatedSalaryGroups = estimatedSalary.filter(item => {
          const found = estimatedSalarySelected.find(estSal => estSal.group === item.group);
          return !!found;
        });
        return estimatedSalaryGroups;
      }
    } else if (name === 'location') {
      const personLocation = this.props.marketBased.personLocation;
      if (personLocation && personLocation.length) {
        return personLocation;
      }
    }
    return [];
  }

  getIncludeKeywordsProp = (name) => {
    if (name === 'role') {
      const role = this.props.marketBased.role;
      if(role) {
        return role.include_keywords ? role.include_keywords : [];
      }
    }
    if (name === 'hiring') {
      const hiring = this.props.marketBased.hiring;
      if(hiring && hiring.roles) {
        return hiring.roles;
      }
    }
    if (name === 'skills' ) {
      const skills = this.props.marketBased.skills;
      if(skills) {
        return skills.include_keywords? skills.include_keywords: [];
      }
    }

    if (name === 'industry2' ) {
      const industry2 = this.props.marketBased.industry2;
      if(industry2) {
        return industry2.include_keywords? industry2.include_keywords: [];
      }
    }
    return [];
  }

  getExcludeKeywordsProp = (name) => {
    if (name === 'role') {
      const role = this.props.marketBased.role;
      if(role) {
        return role.exclude_keywords? role.exclude_keywords: [];
      }
    }
    if (name === 'skills') {
      const skills = this.props.marketBased.skills;
      if(skills) {
        return skills.exclude_keywords? skills.exclude_keywords: [];
      }
    }
    if (name === 'industry2') {
      const industry2 = this.props.marketBased.industry2;
      if(industry2) {
        return industry2.exclude_keywords? industry2.exclude_keywords: [];
      }
    }
    return [];
  }
  
  render() {
    if (this.props.isAnyCompanyCriteriaApplied && this.props.marketBased.include_past_roles) {
      this.props.toggleIncludePastRole();
    }
    const {
      active,
      setGroups,
      setIncludeTags,
      setExcludeTags,
      clearFields,
      updateClearFields,
    } = this.props;

    return (
      <div className={`people-market ${active}`}>
        {peopleDropdowns.map((item, index) => (
          <div className="people-market__col" key={index}>
            <p className="people-market__label">{item.label}</p>
            <Functions
              marketBased={this.props.marketBased}
              label={item.label}
              includeKeywords={this.getIncludeKeywordsProp(item.name)}
              excludeKeywords={this.getExcludeKeywordsProp(item.name)}
              selectedGroups={this.getParticularGroupsProp(item.name)}
              basicHeading1={item.basicHeading1}
              placeholder={item.placeholder}
              inputType={item.inputType}
              type={item.type}
              name={item.name}
              groups={item.group}
              customInput={item.customInput}
              showExclude={item.showExclude}
              showIncludePreviousRole={!this.props.isAnyCompanyCriteriaApplied && item.name === 'role'}
              toggleIncludePastRole={this.props.toggleIncludePastRole}
              include_past_roles={this.props.marketBased.include_past_roles}
              haveTags={item.have_tags}
              technologies={item.technologies}
              setIncludeTags={setIncludeTags}
              setExcludeTags={setExcludeTags}
              setGroups={setGroups}
              clearFields={clearFields}
              updateClearFields={updateClearFields}
            />
          </div>
        ))}
        <div className="people-market__col" key="college" style={{display: 'none'}}>
            <p className="people-market__label">College</p>
            <CollegeDropdown
              label="College"
              basicHeading1="Colleges"
              placeholder="Select Colleges"
              selectedGroups={this.props.marketBased.college}
              inputType="text"
              name="college"
              setGroups={setGroups}
              clearFields={clearFields}
              updateClearFields={updateClearFields}
            />
          </div>
        <div className="people-market__col" key="major" style={{display: 'none'}}>
            <p className="people-market__label">Major</p>
            <CollegeDropdown
              label="Major"
              basicHeading1="Major"
              placeholder="Select Majors"
              selectedGroups={this.props.marketBased.major}
              inputType="text"
              name="major"
              setGroups={setGroups}
              clearFields={clearFields}
              updateClearFields={updateClearFields}
            />
          </div>

          {/* {!this.props.isAnyCompanyCriteriaApplied && 
          <div className={`people-market__col ${this.props.isAnyCompanyCriteriaApplied ? 'previous_experience_not_needed' : ''}`}
                  onClick={() => {if (!this.props.isAnyCompanyCriteriaApplied) {
                    this.props.toggleIncludePastRole()}
                  }}>
                <p className="adv-lead__multi_title">Include Previous Roles</p>
                <div style={{marginTop:  "8px"}}
                  className={`switch ${
                    this.props.marketBased.include_past_roles ? "active" : ""
                  }`}
                >
                  <p className="previous_experience_not_needed__help">It is no longer needed. You already have added company critera</p>
                    <span></span>
                  </div>
                </div>
                } */}
      </div>
    );
  }
}

export default PeopleMarket;
