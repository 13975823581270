import React, { Component } from "react";
import { AutoSizer, List } from "react-virtualized";
import CategoryTechLine from "../CategoryTechLine";
import "../style.scss";

class AddTechnologyGroup extends Component {
  state = { technologyList: [] };

  _noRowsRenderer = () => {
    return <p className="dropdown-in__title">No matches found</p>;
  };

  _rowRenderer = ({ index, isScrolling, key, style }, techList) => {
    return (
      <div
        style={{
          ...style,
        }}
      >
        {techList[index]}
      </div>
    );
  };

  setTags = (allTags, technologyOptions, selectedRowIndex) => {
    this.setState({
      allTags,
      technologyOptions,
      selectedRowIndex,
    });
  };

  render() {
    const { addGroupSelect, addTagInSelect, value, name, haveTags } = this.props;

    let technologyList = [];

    let finishRoleGroups = this.props.finishRoleGroups;
    let excludeSwtich = [];
    if (name === "role" && this.props.excludeOption) {
      finishRoleGroups = [];
    }
    //console.log(finishRoleGroups);
    if (this.props.showExclude) {
      excludeSwtich = [
        <div className="dropdown-in__exclude">
          <p className=""></p>
          <div className="right-filters__switch" onClick={this.props.toggleExcludeOption}>
            <p className="switch__name">Exclude Mode {this.props.excludeOption ? "[ON]" : "[OFF]"} </p>
            <div className={`switch ${this.props.excludeOption ? "active" : ""}`}>
              <span></span>
            </div>
          </div>
        </div>
      ];
      
    }

    if (name === "technologies") {
      let technologyGroups = [];
      if (finishRoleGroups && finishRoleGroups.length) {
        technologyGroups = finishRoleGroups.map((item) => (
          <CategoryTechLine
            category={item.category}
            tags={[]}
            key={"tech-group" + item.label}
            rowIndex={"tech-group" + item.label}
            label={item.label}
            name={name}
            excludeTags={item.technologies}
            addGroupSelect={addGroupSelect}
            addTagInSelect={addTagInSelect}
            haveTags={true}
            isTechName={false}
            isTech={true}
            setTags={this.setTags}
            selectedRowIndex={this.state.selectedRowIndex}
            technologies={item.technologies}
          />
        ));
      }
      const uniqueTechnologies = new Set();
      finishRoleGroups.forEach((item) => {
        if (item.technologies) {
          item.technologies.map((tech) => {
            if (tech.toLowerCase().includes(value.toLowerCase())) {
              uniqueTechnologies.add(tech);
            }
          });
        }
      });
      const technologyItems = [...uniqueTechnologies].map((tech) => (
        <CategoryTechLine
          category={tech}
          tags={[]}
          key={"tech-" + tech}
          rowIndex={"tech-" + tech}
          label={tech}
          excludeTags={[]}
          addGroupSelect={addGroupSelect}
          addTagInSelect={addTagInSelect}
          setTags={this.setTags}
          selectedRowIndex={this.state.selectedRowIndex}
          haveTags={haveTags}
          isTechName={true}
          isTech={true}
        />
      ));
      if (technologyItems.length) {
        if (value) {
          technologyList = [...excludeSwtich, ...[<p className="dropdown-in__title first">Technology Name</p>], ...technologyItems, ...technologyGroups];
        } else {
          technologyList = [...excludeSwtich, ...technologyGroups, ...[<p className="dropdown-in__title">Technology Name</p>], ...technologyItems];
        }
      }
    }

    let techList = technologyList;
    if (technologyList.length === 1) {
      techList = [];
    }

    const allTags = techList.length === 0 ? null : this.state.allTags;
    const technologyOptions = techList.length === 0 ? null : this.state.technologyOptions;

    return (
      <div className="dropdown-in animation-slideDown">
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              className="tech-autosizer"
              ref="List"
              height={techList.length ? 180 : 50}
              overscanRowCount={5}
              noRowsRenderer={this._noRowsRenderer}
              rowCount={techList.length}
              rowHeight={36}
              rowRenderer={(param) => this._rowRenderer(param, techList)}
              width={width}
            />
          )}
        </AutoSizer>
        {allTags || null}
        {technologyOptions || null}
      </div>
    );
  }
}

export default AddTechnologyGroup;
