import React, { Component } from 'react'
import { columnsData } from './columnsDataGoogle'

class ColumnsReadOnly extends Component {


    
    getDisplayName = (column) => {
        const foundColumnData = columnsData.find(colData => colData.value === column);
        return foundColumnData? foundColumnData.name : column;
    }
    render() {
        const { columns, changeCheckForColumn } = this.props
        return (
            <div style={{   
                maxHeight: '200px',
                overflow: 'auto',
                display: 'flex',
                flexWrap: 'wrap',
                marginBottom: '15px',
               }}>
                {columns.map((item, index) =>
                    <div className="right-filters__check check-margin" style={{flex: '50%'}}>
                        <div 
                        
                        // className={`check ${item.check ? 'active' : ''}`}
                            // onClick={() => changeCheckForColumn(index, item.value)}
                            >
                                
                                <img src="/assest/check-orange.svg" alt="" />
                                <span></span>
                        </div>
                        <p style={{cursor: 'auto'}}>{this.getDisplayName(item)}</p>
                    </div>
                )}
            </div>
        )
    }
}

export default ColumnsReadOnly;

