import { GET_LEAD_LIST, CLEAR_LEAD_LIST, UPDATE_LIST_ITEM } from "../constants/constant";

export function getLead(data) {
    return {
        type: GET_LEAD_LIST,
        payload: data
    }
}


export function updateListItem(data) {
    return {
        type: UPDATE_LIST_ITEM,
        payload: data
    }
}

export function clearLead(data) {
    return {
        type: CLEAR_LEAD_LIST,
        payload: data
    }
}