import React, { Component } from 'react';
import CampaignDropDown from './CampaignDropDown';
import '../style.scss';
import { connect } from 'react-redux'
import { updateLemListCampaigns } from '../../../../services/leads';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { LOCAL_STORAGE_KEYS } from '../../../../constants/constant';
import { showSuccessToast, showErrorToast } from '../../../../helpers/toast';

class Lemlist extends Component {
    state = {
        active: false,
        value: "",
        campId: ''
    }

    openListName = () => {
        this.setState({ active: !this.state.active });
    }
    handleChange = (e) => {
        this.setState({ value: e.target.value })
    }
    onUpdateClick = async () => {
        const { openLemlistPopup, listID } = this.props
        const { campId } = this.state
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        let toastId = null;
        toastId = toast('Updating campaign id', {
            autoClose: 10000,
        });
        try {
            await updateLemListCampaigns(userId.id, listID, campId)
            openLemlistPopup()
            showSuccessToast(toastId, "Campaign Id for lemlist has been Updated");
        } catch{
            showErrorToast(toastId, "Unable to Update Campaign ID");
        }
    }
    columnValue = (val, id) => {
        this.setState({ value: val, campId: id })
    }

    render() {
        const { active, value } = this.state
        const { popupLemlist, openLemlistPopup, lemlistCampaigns } = this.props;
        return (
            <div className={`add-list ${popupLemlist ? 'open' : ''}`}>
                <div className="add-list__content">
                    <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={openLemlistPopup} />
                    <p className="add-list__title">lemlist Settings</p>
                    <p className="add-list__label">Campaign Name</p>
                    <div className={`add-list__search-box ${active ? 'active' : ''}`}>
                        <input className="add-list__search"
                            placeholder="type Campaign Name"
                            type="text"
                            value={value}
                            onChange={this.handleChange}
                            required />
                        <img src="/assest/accountBased/role-arr.svg" alt="" onClick={this.openListName} />
                    </div>
                    <CampaignDropDown active={active} columnValue={this.columnValue} lemlistCampaigns={lemlistCampaigns} />
                    <p className="add-list__create">
                    </p>
                    <div className="add-list__buttons">
                        <div className="add-list__btn orange" onClick={() => { this.onUpdateClick() }} >Save</div>
                        <div className="add-list__btn orange-light" onClick={openLemlistPopup}>Cancel</div>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        integration: state.integration,
        user: state.user
    };
}
export default connect(mapStateToProps, null)(Lemlist);
