import React, { Component } from 'react';
import '../style.scss';
import $ from 'jquery';
import { createMailShakeCampaign, getIntegrationCampaigns, addIntegrationLeads, updateIntegrations } from '../../../../services/leads';
import { toast } from 'react-toastify';
import CampaignDropDown from '../Woodpecker/CampaignDropDown';
class MailShake extends Component {
    state = {
        active: false,
        value: "",
        newCampaign: false,
        selectedCampaign: undefined,
        errorMessage: '',
        // campaigns: [],
        campaigns: [],
        make_default: false,
        selectedValue: "",
        loading: true,
        filteredCampaigns: []
    }


    componentWillMount() {
        getIntegrationCampaigns('mailshake').then(response => {
            if (response.data.data) {
                let campaigns = response.data.data;
                campaigns = campaigns.map(comp => {
                    return {
                        id: comp._id,
                        name: comp.title,
                        ...comp,
                    }
                })
                this.setState({ campaigns, filteredCampaigns: campaigns, loading: false });

                let integrations_info = this.props.list ? this.props.list.integrations_info : {};
                if(integrations_info.mailshake_info && integrations_info.mailshake_info.campaign_id) {
                    let defaultCampaign = this.state.campaigns.find(item => item.id == integrations_info.mailshake_info.campaign_id);
                    this.setState({selectedCampaign: defaultCampaign, selectedValue: defaultCampaign.name})
                }

                this.setState({active: true})

            };
        }).catch(err => {
            this.setState({loading: false})
        })
    }

    openListName = () => {
        this.setState({ active: !this.state.active });
    }
    handleChange = (e) => {
        this.setState({ value: e.target.value, errorMessage: '' })
    }
    showSuccessToast = (toastId, message) => {
        toast.done(toastId);
        $(".Toastify").addClass("mes-done");
        setTimeout(() => {
            toast.update(toastId, {
                className: "toast-success-div",
                render: message,
                type: toast.TYPE.SUCCESS
            });
        }, 1300);
        setTimeout(function () {
            toast.dismiss(toastId)
            $(".Toastify").removeClass("mes-done");
        }, 4000)
    }
    showErrorToast = (toastId, errorMessage) => {
        toast.done(toastId)
        $(".Toastify").addClass("mes-done");
        setTimeout(() => {
            toast.update(toastId, {
                className: "toast-error-div",
                render: errorMessage,
                type: toast.TYPE.ERROR
            });
        }, 1300)
        setTimeout(function () {
            toast.dismiss(toastId)
            $(".Toastify").removeClass("mes-done");
        }, 4000)
    }
    onUpdateClick = async (val) => {
        let toastId = null;
        if (!this.state.selectedCampaign) {
            this.setState({ errorMessage: `Campaign name can not be empty` });
            return;
        }
        if (!this.props.inside) {
            // if (!this.state.value) {
            //     this.setState({ errorMessage: 'Campaign Name can not be empty' });
            //     return;
            // }
            try {
                toastId = toast(`Updating Integrations Settings`, {
                    autoClose: 10000,
                });
                //const createCampaignRes = await createMailShakeCampaign(this.state.value);
                // if (createCampaignRes.data.data) {
                //     const newComapign = createCampaignRes.data.data;
                //     const existingCompaings = this.state.campaigns;
                //     const newCampaigns = [...existingCompaings, { ...newComapign, name: newComapign.title }];
                //     this.setState({ campaigns: newCampaigns, newComaign: false, selectedCampaign: newComapign, selectedValue: newComapign.title });
                //     this.showSuccessToast(toastId, 'Mailshake Campaign is created successfully');
                // } else {
                //     this.showErrorToast(toastId, 'Unable to create Mailshake Campaign');
                // }
                let selected = this.state.campaigns.find(item => item.title == val || item.name == val);
                let integrations_info = {};

                integrations_info.mailshake_info = {
                    campaign_id: selected.id
                };
                const response = await updateIntegrations(this.props.listId, integrations_info)
                if (response.data.data) {
                    this.showSuccessToast(toastId, 'Integration settings updated successfully');
                    const { openMailshakePopup } = this.props
                    openMailshakePopup(response.data.data)
                  } else {
                    this.showErrorToast(toastId, 'Unable to update integration settings');
                  }
                
            } catch (e) {
                this.showErrorToast(toastId, 'Unable to update integration settings');
            }
            return;
        }
        else {
            try {
                toastId = toast(`Sending leads to Mailshake`, {
                    autoClose: 10000,
                });
                let query_filters = {
                    added_by_fuzebot: this.props.inside.query_filters.addedByFuzebot,
                    has_email: this.props.inside.query_filters.hasEmail,
                    personal_email: this.props.inside.query_filters.personalEmail,
                    company_email: this.props.inside.query_filters.companyEmail,
                    single_verified_email: this.props.inside.query_filters.singleVerifiedEmail,
                    double_verified_email: this.props.inside.query_filters.doubleVerifiedEmail,
                    has_linkedin: this.props.inside.query_filters.hasLinkedIn,
                    has_phone: this.props.inside.query_filters.hasPhone,
                    has_mailing_address: this.props.inside.query_filters.hasMailingAddress,
                    date_created_from: this.props.inside.query_filters.from,
                    date_created_to: this.props.inside.query_filters.to
                };
                const params = this.props.inside
                params.query_filters = query_filters
                const response = await addIntegrationLeads('mailshake', this.props.listId,'', this.state.selectedCampaign.id, this.props.search_query, null,  null,  this.state.make_default, params);
                if (response.data.data) {
                    let added = response.data.data.added;
                    let skipped = response.data.data.skipped;
                    if (skipped == -1 && added == -1) {
                        this.showSuccessToast(toastId, "Leads are being sent to the integration. Please check back in a couple of minutes.");
                    } else if(skipped == 0) {
                        this.showSuccessToast(toastId, "Leads sent successfully to integration");
                    }
                    else if(skipped > 0) {
                        this.showSuccessToast(toastId, `${added} leads sent successfully to integration. ${skipped} leads were skipped`);
                    }
                    const { openMailshakePopup } = this.props
                    openMailshakePopup();
                } else {
                    this.showErrorToast(toastId, 'Unable to send leads to integration');
                }
            }
            catch (e) {
                this.showErrorToast(toastId, 'Unable to send leads to integration');
            }
        }
    }
    columnValue = (comp) => {
        this.props.updateDefaultIntegration("mailshake_info", {campaign_id: comp.id});
        this.setState({ selectedCampaign: comp, selectedValue: comp.name, errorMessage: '' });
        this.openListName();
    }
    createNewCampaign = () => {
        this.setState({ newCampaign: !this.state.newCampaign })
    }
    getPlaceHolder = () => {
        const { campaigns } = this.state;
        if (!campaigns || !campaigns.length) {
            return `You have no campaigns`;
        }
        return `Choose Campaign`;
    }

    checkMakeDefault = () => {
        this.setState({make_default: !this.state.make_default});
    }

    handleChange = (e) => {
        const value = e.target.value;
        const filteredCampaigns = this.state.campaigns.filter(comp => {
            return comp.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        });
        this.setState({ value: e.target.value, filteredCampaigns })
    }

    render() {
        const { active, value, newCampaign, campaigns, errorMessage, selectedValue, filteredCampaigns, loading } = this.state
        const { popupMailshake, openMailshakePopup, mailshakeCampaigns, inside, search_query } = this.props;

        let updatedCampaigns = campaigns.map(item => { 
            if(!item.name) {
                return {
                    ...item, name: item.title
                }
            }
            return item;
        });

        return (
             <div className={`add-list ${popupMailshake ? 'open' : ''}`}>
                <div className="add-list__content">
                    <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={openMailshakePopup} />
                    <p className="add-list__title">{inside ? 'Send To Mailshake' : 'Mailshake Settings'}</p>
                    <p className="add-list__label">Campaign Name</p>
                    {!newCampaign &&
                        <div>
                            {/* <div className={`add-list__search-box ${active ? 'active' : ''}`}>
                                <img src="/assest/accountBased/role-arr.svg" alt="" onClick={this.openListName} />
                            </div> */}
                            <div className={`add-list__search-box ${active ? 'active' : ''}`} onClick={this.openListName}>
                                <input className="add-list__search"
                                    placeholder={this.getPlaceHolder()}
                                    type="text"
                                    disabled
                                    value={selectedValue}
                                    required />
                                <img src="/assest/accountBased/role-arr.svg" alt="" onClick={this.openListName} />
                            </div>
                            <CampaignDropDown 
                            woodpeckerCampaigns={updatedCampaigns} 
                            mailshakeCampaigns={mailshakeCampaigns} 
                            active={active} 
                            columnValue={this.columnValue}
                            handleChange={this.handleChange}
                            filteredCampaigns={filteredCampaigns}
                            value={value}
                            loading={loading} />
                        </div>
                    }
                    {newCampaign &&
                        <div className={`add-list__search-box ${active ? 'active' : ''}`}>                        
                            <input className="add-list__search"
                                placeholder="Enter Campaign Name"
                                type="text"
                                value={value}
                                onChange={this.handleChange}
                                required />
                        </div>
                    }
                    {/* <div className="coupon__links">
                        {!newCampaign &&
                            <p className="add mv-20" onClick={this.createNewCampaign}>
                                <img src="/assest/accountBased/plus-orange.svg" alt="" />Create New Campaign</p>
                        }
                    </div> */}
                    {errorMessage ? (
                        <div className="lead-header__model_error"><p>{errorMessage}</p></div>) : null}

                    {inside && !search_query && <div className="check-panel">
                        <div className={`check ${this.state.make_default ? 'active' : ''}`}
                                             onClick={() => this.checkMakeDefault()}></div>
                        <div className="check-label" onClick={() => this.checkMakeDefault()}>Make this the default campaign for this list</div>
                    </div>}

                    {/* {newCampaign &&
                        <p className="add-list__create">
                        </p>
                    } */}
                    <div className="add-list__buttons">
                        {!newCampaign &&
                            <div>
                                <div className="add-list__btn orange" onClick={() => { this.onUpdateClick(selectedValue) }} >{inside ? 'Send' : 'Save'}</div>
                                <div className="add-list__btn orange-light" onClick={openMailshakePopup}>Cancel</div>
                            </div>
                        }
                        {newCampaign &&
                            <div>
                                <div className="add-list__btn orange" onClick={() => { this.onUpdateClick(selectedValue) }} >Create</div>
                                <div className="add-list__btn orange-light" onClick={this.createNewCampaign}>Back</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default MailShake;
