import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Pagination} from '../../../../components';
import {
    addLeads,
    getCompanyEmployees,
    getCompany,
    getCompanyNews,
    getCompanyHiring,
    getCompanyTechnologies,
    getLeadDetail,
    getPersonDetailForAddedLead,
    skipLead,
    unSkipSingleLead
} from '../../../../services/searchResult';
import {closeEmployeePopup, openEmployeePopup} from '../../../../actions/loader';
import EmployeesList from './EmployeesList';
import {toast} from 'react-toastify';
import './style.scss';

const list = [
    {name: 'Person'},
    {name: 'Company'},
    {name: 'contact info'},
];

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openEmployeePopup,
        closeEmployeePopup
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        loader: state.loader
    };
}

class Employees extends Component {
    container = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            selectedItems: [],
            selectedLeadId: null,
            search: "",
            totalItems: 0,
            check: false,
            showHeaderButtons: false,
            popup: false,
            currentPageNum: 1,
            recordsPerPage: 25,
            showDropDown: false,
            cursorsByPage: {1: ''},
            showLoader: false,
            showLeadDetailLoader: false,
            leadDetail: null
        };
    }

    componentDidMount() {
        let recordsPerPage = localStorage.getItem('recordsPerPage');
        if (recordsPerPage === null) {
            localStorage.setItem('recordsPerPage', '25');
            recordsPerPage = localStorage.getItem('recordsPerPage');
        }

        this.setState({recordsPerPage: recordsPerPage, currentPageNum: 1}, this.loadCompanyEmployees);
        document.addEventListener("mousedown", this.clickEventHandle);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.clickEventHandle);
    }

    clickEventHandle = (event) => {
        const {showDropDown} = this.state;
        if (this.container.current && !this.container.current.contains(event.target)) {
            if (showDropDown) {
                this.paginationPopup();
            }
        }
    }

    updateSearchString = (e) => {
        this.setState({search: e.target.value});
    }

    onSearchEmployees = () => {
        this.setState({currentPageNum: 1, cursorsByPage: {1: ''}}, this.loadCompanyEmployees);
    }

    paginationPopup = async () => {
        this.setState({showDropDown: !this.state.showDropDown})
    }

    selectItem = ($event, id) => {
        $event.stopPropagation();
        const {items} = this.state;
        let selectedItem = null;

        items.forEach(item => {
            if (item.poolId === id) {
                if (!item.skipped && !item.addedToList) {
                    item.check = !item.check;
                    selectedItem = item;
                }
                return;
            }
        });

        if (selectedItem && selectedItem.check) {
            this.state.selectedItems.push(selectedItem);
        } else {
            this.state.selectedItems.forEach((item, index) => {
                if (item.poolId === id) {
                    this.state.selectedItems.splice(index, 1);
                    return;
                }
            });
        }

        const checkAll = items.length > 0 && this.state.selectedItems.length === items.length;
        this.setState({
            check: checkAll,
            items: items,
            selectedItems: this.state.selectedItems,
            showHeaderButtons: this.state.selectedItems.length > 0
        });
    }

    checkedAll = () => {
        const {items, check} = this.state
        const selectedItems = [];

        items.forEach((item) => {
            if (this.state.check) {
                item.check = false
            } else {
                if (!item.skipped && !item.addedToList) {
                    item.check = true;
                    selectedItems.push(item);
                }
            }
        });

        this.setState({
            check: !check,
            items: items,
            selectedItems: selectedItems,
            showHeaderButtons: selectedItems.length > 0
        });
    }

    showSearchResultItemDetail = async (id) => {
        const {items} = this.state;
        items.forEach(async (item) => {
            if (id && item.poolId === id) {
                if (!item.active && !item.skipped) {
                    this.startLoader({showLeadDetailLoader: true});
                    this.setState({leadDetail: null});

                    item.active = true;
                    let leadDetail = null;

                    if (item.addedToList) {
                        const response = await getPersonDetailForAddedLead(item.leadId);
                        leadDetail = response.data.data.person;
                        leadDetail.leadId = item.leadId;
                        leadDetail.email = item.email;
                    } else {
                        const response = await getLeadDetail(id);
                        leadDetail = response.data.data;
                    }

                    leadDetail.addedToList = item.addedToList;

                    this.setState({leadDetail: leadDetail});
                    this.stopLoader({showLeadDetailLoader: false});
                }
                return;
            } else {
                item.active = false;
            }
        });

        if (id) {
            this.props.openEmployeePopup();
        } else {
            this.props.closeEmployeePopup();
        }
        this.setState({items: items});
    }

    getEmails(leadDetail) {
        const emails = [];
        if (leadDetail.addedToList) {
            if (leadDetail.emails) {
                if (leadDetail.emails.personal) {
                    if (leadDetail.emails.personal.deliverable)
                        emails.push(...leadDetail.emails.personal.deliverable);
                    if (leadDetail.emails.personal.catchall)
                        emails.push(...leadDetail.emails.personal.catchall);
                }

                if (leadDetail.emails.company) {
                    if (leadDetail.emails.company.deliverable)
                        emails.push(...leadDetail.emails.company.deliverable);
                    if (leadDetail.emails.company.catchall)
                        emails.push(...leadDetail.emails.company.catchall);
                }
            }
        }
        return emails;
    }

    skipLead = async ($event, id, skipMultiple) => {
        $event.stopPropagation();
        const toastId = this._showToaster(skipMultiple ? 'Skipping selected leads' : 'Skipping lead');

        const ids = [];

        if (skipMultiple) {
            this.state.selectedItems.forEach((item) => {
                ids.push(item.poolId);
            });
        } else {
            ids.push(id);
        }

        try {
            await skipLead(ids);

            ids.forEach((poolId) => {
                this.state.items.forEach((item) => {
                    if (item.poolId === poolId) {
                        item.skipped = true;
                        item.check = false;
                        item.active = false;
                        return;
                    }
                });
            });

            if (skipMultiple) {
                this.setState({items: this.state.items, check: false, selectedItems: [], showHeaderButtons: false});
            } else {
                this.state.selectedItems.forEach((item, index) => {
                    if (id === item.poolId) {
                        this.state.selectedItems.splice(index, 1);
                        return;
                    }
                });

                this.setState({
                    items: this.state.items,
                    check: false,
                    selectedItems: this.state.selectedItems,
                    showHeaderButtons: this.state.selectedItems.length > 0
                });
            }

        } catch (error) {
        }

        this._updateToaster(toastId, skipMultiple ? 'Selected leads skipped successfully' : 'Lead skipped successfully');
    }

    unSkipSingleLead = async ($event, id) => {
        $event.stopPropagation();
        const toastId = this._showToaster('Returning lead');

        try {
            await unSkipSingleLead(id);

            this.state.items.forEach((item) => {
                if (item.poolId === id) {
                    item.skipped = false;
                    return;
                }
            });

            this.setState({items: this.state.items});

        } catch (error) {
        }

        this._updateToaster(toastId, 'Lead returned successfully');
    }

    addLeadsToList = async (list) => {
        const leadIds = [];

        if (this.state.selectedLeadId) {
            leadIds.push(this.state.selectedLeadId);
        } else {
            this.state.selectedItems.forEach((item) => {
                leadIds.push(item.poolId);
            });
        }

        leadIds.forEach((leadId) => {
            this.state.items.forEach((item) => {
                if (item.poolId === leadId) {
                    item.addingLeadToList = true;
                    item.leadProgressText = "Gathering details";

                    if (item.active) {
                        this.state.leadDetail.addingLeadToList = true;
                        this.state.leadDetail.leadDataLoaded = false;
                    }
                }
            });
        });

        this.setState({items: this.state.items, leadDetail: this.state.leadDetail, popup: false});

        try {
            const response = await addLeads(list._id, leadIds, false, null, null, "people_prospector");

            response.data.data.success.forEach((lead) => {
                this.state.items.forEach((item) => {
                    if (item.poolId === lead.person.id) {

                        this._updateAddLeadProgress(item, lead);


                        item.listName = list.name;
                        item.leadId = lead.id;
                        item.emails = lead.person ? lead.person.emails : null;
                        item.person = {
                            emails: lead.person ? lead.person.emails : null
                        }
                        item.email = lead.email;
                        item.catch_all = lead.catch_all;
                        item.verified = lead.verified;
                        item.phone = lead.person.phone ? lead.person.phone : [];
                        if (lead.person.job && lead.person.job.company && lead.person.job.company.phone) {
                            item.phone.push({
                                number: lead.person.job.company.phone
                            });
                        }
                        item.mailing_address = lead.person.job && lead.person.job.company && lead.person.job.location && lead.person.job.company.location.street ? lead.person.job.company.location.street : '';
                        item.social_profiles = lead.person ? lead.person.social_profiles : null;

                        let selectedItems = this.state.selectedItems.filter((temp) => temp.poolId !== item.poolId);
                        this.setState({selectedItems: selectedItems, showHeaderButtons: selectedItems.length > 0});

                        return;
                    }
                });
            });

            this.setState({items: this.state.items, popup: false});

        } catch (error) {
        }
    }

    _updateAddLeadProgress = (item, lead) => {
        item.leadProgressText = "Info found";
        this.setState({items: this.state.items});

        setTimeout(() => {
            item.leadProgressText = "Reverifying contact details";
            item.leadDataLoaded = true;

            if (item.active) {
                this.state.leadDetail.leadDataLoaded = true;
            }
            this.setState({items: this.state.items, leadDetail: this.state.leadDetail});

        }, 2000);

        setTimeout(() => {
            item.leadProgressText = "";
            item.addingLeadToList = false;
            item.addedToList = true;
            item.check = false;

            if (item.active) {
                lead.person.addedToList = true;
                lead.person.leadId = lead.id;
                lead.person.email = lead.email;
                this.setState({leadDetail: lead.person});
            }

            this.setState({items: this.state.items});
        }, 4000);
    }

    openAddLeadToListPopup = ($event, id) => {
        $event.stopPropagation();
        this.setState({popup: true, selectedLeadId: id});
    }

    closeAddToListPopup = () => {
        this.setState({popup: false, selectedLeadId: null});
    }

    loadCompanyEmployees = async () => {
        this.startLoader({showLoader: true});
        try {
            const res = await getCompanyEmployees(this.props.leadDetail.job.company.id, this.getCurrentSearchQueryParams());

            const updateCursors = {
                ...this.state.cursorsByPage,
                [this.state.currentPageNum + 1]: res.data.data.next_cursor,
            }

            const leads = res.data.data.leads;
            let selectedItems = this.state.selectedItems;

            if (leads) {
                // check all selected items
                this._checkSelectedItems(leads, selectedItems);

                // remove all elements which are not in the view
                selectedItems = this._removeNotInViewElements(leads, selectedItems);

                this.setState({
                    items: leads,
                    totalItems: res.data.data.total,
                    cursorsByPage: updateCursors,
                    selectedItems: selectedItems,
                    showLoader: false
                });
            } else {
                this.setState({
                    items: [],
                    totalItems: res.data.data.total,
                    cursorsByPage: updateCursors,
                    selectedItems: selectedItems,
                    showLoader: false
                });
            }
        } catch (error) {
        }
        this.stopLoader({showLoader: false});
    }

    _checkSelectedItems(leads, selectedItems) {
        leads.forEach((item) => {
            let leadSelected = false;
            selectedItems.forEach((selectedItem) => {
                if (selectedItem.poolId === item.poolId) {
                    leadSelected = true;
                    return;
                }
            });

            if (this.state.check) {
                item.check = true;

                if (!leadSelected) {
                    selectedItems.push(item);
                }
            } else if (leadSelected) {
                item.check = true;
            }
        });
    }

    _removeNotInViewElements(leads, selectedItems) {
        const indexArray = [];

        selectedItems.forEach((selectedItem, index) => {
            let itemInView = false;

            leads.forEach((item) => {
                if (selectedItem.poolId === item.poolId) {
                    itemInView = true;
                    return;
                }
            });

            if (!itemInView) {
                indexArray.push(index);
            }
        });

        selectedItems = selectedItems.filter(function (value, index) {
            return indexArray.indexOf(index) === -1;
        });

        return selectedItems;
    }

    getCurrentSearchQueryParams = () => {
        const queryParam = {
            cursor: this.state.cursorsByPage[this.state.currentPageNum], //this.state.nextCursorId,
            recordsPerPage: this.state.recordsPerPage,
            leadId: this.props.leadDetail.id,
        };

        if (this.state.search) {
            queryParam.search = this.state.search;
        }
        return queryParam;
    }

    paginate = async (num) => {
        window.scrollTo(0, 0);
        this.setState({
            currentPageNum: num,
            check: false,
            selectedItems: [],
            showHeaderButtons: false
        }, this.loadCompanyEmployees);
    }

    incOrDecNum = (sign, indexOfLastPage) => {
        let currentPage = this.state.currentPageNum
        if (sign === "-" && currentPage > 1) {
            currentPage = currentPage - 1
            this.paginate(currentPage)
        } else if (sign === "+" && currentPage < indexOfLastPage) {
            currentPage = currentPage + 1
            this.paginate(currentPage)
        }
    }

    updatePerPage = async (sign, num) => {
        let integer = parseInt(num);
        if (sign === "+" && integer < 100) {
            this.setState({
                recordsPerPage: integer + 25,
                currentPageNum: 1,
                cursorsByPage: {1: ''}
            }, this.loadCompanyEmployees);
            localStorage.setItem('recordsPerPage', integer + 25);
        } else if (sign === "-" && integer > 25) {
            this.setState({
                recordsPerPage: integer - 25,
                currentPageNum: 1,
                cursorsByPage: {1: ''}
            }, this.loadCompanyEmployees);
            localStorage.setItem('recordsPerPage', integer - 25);
        } else if (sign === '') {
            this.setState({
                showDropDown: !this.state.showDropDown,
                recordsPerPage: integer,
                currentPageNum: 1,
                cursorsByPage: {1: ''}
            }, this.loadCompanyEmployees);
            localStorage.setItem('recordsPerPage', integer);
        }
    }

    _showToaster(message) {
        return toast(message, {hideProgressBar: true, autoClose: 10000})
    }

    _updateToaster(toastId, message) {
        toast.update(toastId, {
            render: message,
            className: "toast-success-div",
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
            autoClose: 2000,
        });
    }

    onEnterPressed = (e) => {
        let code = e.keyCode || e.which;
        if (code === 13) {
            this.setState({currentPageNum: 1}, this.loadCompanyEmployees);
        }
    }

    startLoader(loaderState) {
        this.setState(loaderState);
    }

    stopLoader(loaderState) {
        this.setState(loaderState);
    }

    render() {
        return (
            <div className="employees">
                <div className="employees-search">
                    <div className="employees-search__inp-group">
                        <input className="employees-search__inp" value={this.state.search}
                               onChange={this.updateSearchString} onKeyPress={this.onEnterPressed} type="text"
                               placeholder="Search employees of this company by name or role"/>
                        <div className="employees-search__link" onClick={this.onSearchEmployees}>Search</div>
                    </div>
                    {/* {this.state.selectedItems.length > 0 &&
            <p className="employees-search__leads">Leads Selected: {this.state.selectedItems.length}</p>
          } */}
                </div>

                <div className="employees-result">
                    <div className="employees-result__header">
                        <div className="result-header__top">
                            <h2 className="result-header__title">We found {this.state.totalItems} employees of this
                                company</h2>
                            <div className={`result-header__selected ${this.state.showHeaderButtons ? 'active' : ''}`}>
                                <div className="result-header__add"
                                     onClick={(event) => this.openAddLeadToListPopup(event, null)}>Add {this.state.selectedItems.length} Selected
                                    to a List
                                </div>
                                <div className="result-header__skip"
                                     onClick={(event) => this.skipLead(event, null, true)}><span>Skip selected</span>
                                </div>
                            </div>
                        </div>

                        <div className="result-header__list">
                            <div className="result-header__item result-header__check">
                                <div className={`check ${this.state.check ? 'active' : ''}`}
                                     onClick={() => this.checkedAll()}></div>
                            </div>
                            {
                                list.map((item, index) =>
                                    <div key={index} className="result-header__item">{
                                        item.name
                                    }</div>
                                )
                            }
                            <div className="result-header__item"></div>
                        </div>
                    </div>

                    <div className="employees-result__item">
                        <EmployeesList
                            items={this.state.items}
                            selectItem={this.selectItem}
                            skipLead={this.skipLead}
                            unSkipSingleLead={this.unSkipSingleLead}
                            popup={this.state.popup}
                            showLoader={this.state.showLoader}
                            addLeadsToList={this.addLeadsToList}
                            openAddLeadToListPopup={this.openAddLeadToListPopup}
                            closeAddToListPopup={this.closeAddToListPopup}
                            showSearchResultItemDetail={this.showSearchResultItemDetail}
                            leadDetail={this.state.leadDetail}
                            showLeadDetailLoader={this.state.showLeadDetailLoader}/>

                        {// && this.state.totalItems > this.state.recordsPerPage
                            this.state.items && this.state.items.length > 0 &&
                            <Pagination
                                totalLists={this.state.totalItems}
                                recordsPerPage={this.state.recordsPerPage}
                                noPageNumbers={true}
                                restrictJumping={true}
                                currentPageNum={this.state.currentPageNum}
                                paginate={this.paginate}
                                incOrDecNum={this.incOrDecNum}
                                updatePerPage={this.updatePerPage}
                                paginationPopup={this.paginationPopup}
                                showDropDown={this.state.showDropDown}
                                container={this.container}/>
                        }
                    </div>

                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Employees);