import { LOADED_DATA_ACTIONS } from "../constants/constant";

const INITIAL_STATE = {
    companyLocationOptions: [],
  };
  
  export default function loadedData(state = INITIAL_STATE, action) {
    switch (action.type) {
      case LOADED_DATA_ACTIONS.UPDATE_COMPANY_LOCATION_OPTIONS:
        return {
          ...state,
          companyLocationOptions: action.payload
        };
      
      case LOADED_DATA_ACTIONS.CLEAR_ALL:
        return INITIAL_STATE;
      default:
        return {
          ...state
        };
    }
  }
  