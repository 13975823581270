import { START_LOADER, STOP_LOADER, OPEN_POPUP, CLOSE_POPUP, OPEN_EMPLOYEE_POPUP, CLOSE_EMPLOYEE_POPUP } from '../constants/constant';

const INITIAL_STATE = { 
  loading: false, 
  signinLoading: false,
  isPopupOpen: false,
  isEmployeePopupOpen: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case START_LOADER:
      if (action.payload === "signin") {
        return {
          ...state,
          loading: true,
          signinLoading: true
        }
      } else {
        return {
          ...state,
          loading: true
        };
      }
    case STOP_LOADER:
      if (action.payload === "signinFalse") {
        return {
          ...state,
          loading: false,
          signinLoading: false
        }
      }
      return {
        ...state,
        loading: false
      };
    case OPEN_POPUP:
      return {
        ...state,
        isPopupOpen: true
      };
    case CLOSE_POPUP:
      return {
        ...state,
        isPopupOpen: false
      };
    case OPEN_EMPLOYEE_POPUP:
      return {
        ...state,
        isEmployeePopupOpen: true
      };
    case CLOSE_EMPLOYEE_POPUP:
      return {
        ...state,
        isEmployeePopupOpen: false
      };
    default:
      return state;
  }
}
