import { API_KEY_PAGINATION, CREATE_AND_REVOKE, REVOKE_DATE_VAL, CREATE_DATE_VAL, SORT_BY, RECORDS_PER_PAGE } from "../constants/constant";

const INITIAL_STATE = {
    sort: "",
    order: '',
    create_date: "",
    revoke_date: "",
    recordsPerPage: 10,
    pageNum: 1
}

export default function apiKeysFilter(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CREATE_DATE_VAL:
            return {
                ...state,
                create_date: action.payload,
            }
        case REVOKE_DATE_VAL:
            return {
                ...state,
                revoke_date: action.payload,
            }
        case CREATE_AND_REVOKE:
            return {
                ...state,
                create_date: action.payload.createVal,
                revoke_date: action.payload.revokeVal

            }
        case SORT_BY:
            return {
                ...state,
                sort: action.payload.status,
                order: action.payload.order
            }
        case RECORDS_PER_PAGE:
            return {
                ...state,
                recordsPerPage: action.payload,
                pageNum: 1
            }
        case API_KEY_PAGINATION:
            return {
                ...state,
                pageNum: action.payload
            }
        default:
            return {
                ...state
            }
    }
}