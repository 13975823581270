import React, { Component, Fragment } from 'react'
import './style.scss'
import { updateSocialInfo } from '../../../../services/profile';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Fab } from '@material-ui/core';
import { LOCAL_STORAGE_KEYS } from '../../../../constants/constant';
import { showSuccessToast, showErrorToast } from '../../../../helpers/toast';

class SocialPopup extends Component {
  state = {
    phone: "",
    linkedin: "",
    youtube: "",
    facebook: "",
    twitter: "",
    instagram: "",
    formErrors: {},
  }

  componentDidMount = () => {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
    if (user !== null) {
      this.setState({ phone: user.phone, facebook: user.facebook, linkedin: user.linkedin, twitter: user.twitter, instagram: user.instagram, youtube: user.youtube })
    }
  }

  initState = () => {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
    if (user !== null) {
      this.setState({ phone: user.phone, facebook: user.facebook, linkedin: user.linkedin, twitter: user.twitter, instagram: user.instagram, youtube: user.youtube, formErrors: {} })
    }
  }

  onSocialChange = (e, val) => {
    switch (val) {
      case "Phone number":
        this.setState({ phone: e.target.value })
        return true
      case "Linkedin":
        this.setState({ linkedin: e.target.value })
        return true
      case "Facebook":
        this.setState({ facebook: e.target.value })
        return true
      case "Twitter":
        this.setState({ twitter: e.target.value })
        return true
      case "Instagram":
        this.setState({ instagram: e.target.value })
        return true
      case "Youtube":
        this.setState({ youtube: e.target.value })
        return true
      default:
        return false
    }
  }

  isNullOrEmpty(field) {
    return !field || field.trim().length === 0;
  }

  validateForm = () => {
    const {phone, linkedin, facebook, twitter, instagram, youtube} = this.state;
    const formErrors = {};

    if (linkedin && !linkedin.toLocaleLowerCase().startsWith("https://www.linkedin.com/") && !linkedin.toLocaleLowerCase().startsWith("https://linkedin.com/")) {
      formErrors["linkedin"] = "Incorrect URL format";
    }

    if (facebook && !facebook.toLocaleLowerCase().startsWith("https://www.facebook.com/") && !facebook.toLocaleLowerCase().startsWith("https://facebook.com/")) {
      formErrors["facebook"] = "Incorrect URL format";
    }

    if (twitter && !twitter.toLocaleLowerCase().startsWith("https://www.twitter.com/") && !facebook.toLocaleLowerCase().startsWith("https://twitter.com/")) {
      formErrors["twitter"] = "Incorrect URL format";
    }

    if (instagram && !instagram.toLocaleLowerCase().startsWith("https://www.instagram.com/") && !instagram.toLocaleLowerCase().startsWith("https://instagram.com/")) {
      formErrors["instagram"] = "Incorrect URL format";
    }

    if (youtube && !youtube.toLocaleLowerCase().startsWith("https://www.youtube.com/") && !youtube.toLocaleLowerCase().startsWith("https://youtube.com/")) {
      formErrors["youtube"] = "Incorrect URL format";
    }


    this.setState({formErrors: formErrors});
    return Object.keys(formErrors).length === 0;
  }

  SaveChange = async () => {
    const { phone, linkedin, facebook, twitter, instagram, youtube } = this.state
    const { linkChange, phoneChange, openPopup, editProfileToggele } = this.props
    let user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
    if(this.validateForm()) {
      let toastId = null;
      toastId = toast('Updating contact info', {
        autoClose: 10000,
      });
      try {
        const res = await updateSocialInfo(user.id, phone, linkedin, facebook, twitter, instagram, youtube)
        const data = res.data.data
        phoneChange(res.data.data.phone)
        linkChange(res.data.data.linkedin, res.data.data.facebook, res.data.data.twitter, res.data.data.instagram, res.data.data.youtube);
        localStorage.setItem('user', JSON.stringify(data))
        this.setState({ phone: data.phone, facebook: data.facebook, linkedin: data.linkedin, twitter: data.twitter, instagram: data.instagram, youtube: data.youtube })
        openPopup()
        editProfileToggele()
        showSuccessToast(toastId, "Contact info updated successfully");
      }
      catch (error) {
        showErrorToast(toastId, "Unable to update contact info");
      }
    }
  }

  render() {
    const { youtube, twitter, phone, linkedin, facebook, instagram } = this.state
    const { popup, openPopup, editProfile } = this.props
    return (
      <Fragment>
        <div className={`soc-popup ${popup ? 'open' : ''}`}>
          <div className="soc-popup__container">
            <img onClick={openPopup} className="soc-popup__close" src="/assest/accountBased/close.svg" alt="" />
            <p className="soc-popup__title">Social Contact Information</p>
            <div className="soc-popup__box scroll_view">
              <label className="soc-popup__label">
                Phone Number
                    <div className="soc-popup__input">
                  <img src="/assest/accountBased/social-icons/phone.svg" alt="Phone Icon" />
                  <input disabled={!editProfile} placeholder={`${!editProfile ? "" : "Paste phone number here"}`} value={phone} onChange={(e) => this.onSocialChange(e, "Phone number")} name="Phone Number" />
                  {this.state.formErrors.phone &&
                    <div className="error">{this.state.formErrors.phone}</div>}
                </div>
              </label>
              <label className="soc-popup__label">
                Linkedin
                    <div className="soc-popup__input">
                  <img src="/assest/accountBased/social-icons/ln-icon.svg" alt="Linkedin" />
                  <input disabled={!editProfile} placeholder={`${!editProfile ? "" : "Paste link here"}`} value={linkedin} onChange={(e) => this.onSocialChange(e, "Linkedin")} name="Linkedin" />
                  {this.state.formErrors.linkedin &&
                    <div className="error">{this.state.formErrors.linkedin}</div>}
                </div>
              </label>
              <label className="soc-popup__label">
                Facebook
                    <div className="soc-popup__input">
                  <img src="/assest/accountBased/social-icons/fb-icon.svg" alt="Facebook" />
                  <input disabled={!editProfile} placeholder={`${!editProfile ? "" : "Paste link here"}`} value={facebook} onChange={(e) => this.onSocialChange(e, "Facebook")} name="Facebook" />
                  {this.state.formErrors.facebook &&
                    <div className="error">{this.state.formErrors.facebook}</div>}
                </div>
              </label>
              <label className="soc-popup__label">
                Twitter
                    <div className="soc-popup__input">
                  <img src="/assest/accountBased/social-icons/tw-icon.svg" alt="Twitter" />
                  <input disabled={!editProfile} placeholder={`${!editProfile ? "" : "Paste link here"}`} value={twitter} onChange={(e) => this.onSocialChange(e, "Twitter")} name="Twitter" />
                  {this.state.formErrors.twitter &&
                    <div className="error">{this.state.formErrors.twitter}</div>}
                </div>
              </label>
              <label className="soc-popup__label">
                Instagram
                    <div className="soc-popup__input">
                  <img src="/assest/accountBased/social-icons/inst-icon.svg" alt="Instagram" />
                  <input disabled={!editProfile} placeholder={`${!editProfile ? "" : "Paste link here"}`} value={instagram} onChange={(e) => this.onSocialChange(e, "Instagram")} name="Instagram" />
                  {this.state.formErrors.instagram &&
                    <div className="error">{this.state.formErrors.instagram}</div>}
                </div>
              </label>
              <label className="soc-popup__label">
                Youtube
                    <div className="soc-popup__input">
                  <img src="/assest/accountBased/social-icons/yt-icon.svg" alt="Youtube" />
                  <input disabled={!editProfile} placeholder={`${!editProfile ? "" : "Paste link here"}`} value={youtube} onChange={(e) => this.onSocialChange(e, "Youtube")} name="Youtube" />
                  {this.state.formErrors.youtube &&
                    <div className="error">{this.state.formErrors.youtube}</div>}
                </div>
              </label>
            </div>
            <div className="soc-popup__buttons">
              <div className={`${!editProfile ? 'disable-btn' : ''}`}>
                <Fab
                  onClick={this.SaveChange}
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="add"
                >Save Changes</Fab>
              </div>
              <Fab
                onClick={() => {openPopup(); this.initState(); }}
                variant="extended"
                size="medium"
                color="secondary"
                aria-label="add"
              >Cancel</Fab>
            </div>
          </div>
        </div>
      </Fragment >
    )
  }
}

export default SocialPopup
