import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'
import { LOCAL_STORAGE_KEYS } from '../../constants/constant'

const Page404 = (props) => {
  const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
  return (
    <section className="page">
      {userId === null &&
        props.history.push(`/signin`)
      }
      {userId !== null && props.history.location.pathname === '/' &&
        props.history.push('/lead-search/market-based')
      }
      <p className="page__text">We can’t find the page that you’re looking for</p>
      <div className="page__error"><p>404</p>
      </div>
      <Link to="/lead-search/account-based"><div className="page__btn" onClick={() => props.history.goBack()}>Back to Previous Page</div></Link>

    </section>
  )
}

export default Page404
