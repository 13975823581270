import React, { Component } from "react";
import CategoryLine from "../CategoryLine";
import "../style.scss";
import AuxContainer from "../../../../../containers/AuxContainer/aux-container";
import { Loader } from "../../../../../components";
import InfiniteScroll from "react-infinite-scroller";

class AddGroups extends Component {
  state = { loading: false, excludeOption: false };

  componentDidMount() {
    if (this.props.showAll) {
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false });
      }, 2000);
    }
  }

  getCustomInputJsx = () => {
    const customInputHeading = (
      <p className="dropdown-in__title">
        {this.props.name === "hiring" ? "Hiring Role Title" : "Custom input"}
      </p>
    );

    let customIncludeOrExclude = "";
    let customIncludeOrExcludeParentDiv = "";
    let customExcludeOption = "";
    if (this.props.customInput) {
      if (this.props.showExclude) {
        //this.props.name !== 'hiring'
        // customExcludeOption = (<div className="dropdown-in-ex__link" onClick={() => this.props.addExcludeTag(this.props.value, "exclude")} >Exclude</div>);
      }
      const inputValueParagraph = (
        <p
          className="dropdown-in-ex__inp"
          onClick={() => this.props.addExcludeTag(this.props.value)}
        >
          {this.props.value}
        </p>
      );
      customIncludeOrExclude = (
        <div className="dropdown-in-ex">
          {inputValueParagraph}
          {customExcludeOption}
        </div>
      );

      customIncludeOrExcludeParentDiv = (
        <div
          className={`dropdown-in__custom ${this.props.value ? "active" : ""}`}
        >
          {customInputHeading}
          {customIncludeOrExclude}
        </div>
      );
    }
    return customIncludeOrExcludeParentDiv;
  };

  render() {
    const {
      addGroupSelect,
      addTagInSelect,
      value,
      addExcludeTag,
      name,
      label,
      haveTags,
      customInput,
    } = this.props;

    let finishRoleGroups = this.props.finishRoleGroups;
    let excludeSwtich = "";
    let includePreviousRole = "";
    if (name === "role" && this.props.excludeOption) {
      finishRoleGroups = [];
    }

    if (this.props.showExclude) {
      excludeSwtich = (
        <div className="dropdown-in__exclude">
          <p className=""></p>
          <div
            className="right-filters__switch"
            onClick={this.props.toggleExcludeOption}
          >
            <p className="switch__name">
              Exclude Mode {this.props.excludeOption ? "[ON]" : "[OFF]"}{" "}
            </p>
            <div
              className={`switch ${this.props.excludeOption ? "active" : ""}`}
            >
              <span></span>
            </div>
          </div>
        </div>
      );
    }

    if (this.props.showIncludePreviousRole) {
      includePreviousRole = (
        <div className="dropdown-in__exclude roles-dropdown">
          <p className=""></p>
          <div
            className="right-filters__switch"
            onClick={this.props.toggleIncludePastRole}
          >
            <p className="switch__name">
              Search Past Roles{" "}
              {this.props.include_past_roles ? "[YES]" : "[NO]"}{" "}
            </p>
            <div
              className={`switch ${
                this.props.include_past_roles ? "active" : ""
              }`}
            >
              <span></span>
            </div>
          </div>
        </div>
      );
    }

    const customIncludeOrExcludeParentDiv = this.getCustomInputJsx();
    let haveTagsAndNotKeywords = null;
    if (name !== "technologies") {
      if (value && !customInput && !finishRoleGroups.length) {
        haveTagsAndNotKeywords = (
          <p className="dropdown-in__title">No matches found</p>
        );
      } else if (value && customInput && !finishRoleGroups.length) {
        haveTagsAndNotKeywords = null;
      } else if (haveTags) {
        haveTagsAndNotKeywords = (
          <p className="dropdown-in__title">{label} Group</p>
        );
      } else if (!haveTags) {
        haveTagsAndNotKeywords = <p className="dropdown-in__title">{label}</p>;
      }
    }

    let technologyTitle = "";
    let technologyDropDown = "";
    if (name === "technologies") {
      // const justGroups = finishRoleGroups.filter(item => item.showGroup == null || item.showGroup);
      // const justTechnologies = finishRoleGroups.filter(item => item.showTechName == null || item.showTechName);
      let technologyGroups = null;
      if (finishRoleGroups && finishRoleGroups.length) {
        technologyGroups = finishRoleGroups.map(
          (item) => (
            // (<InfiniteScroll
            //     loadMore={this.props.loadMore}
            //     hasMore={this.props.hasMore}
            //     loader={<div className="loader" key={0}>Loading ...</div>}
            //     useWindow={false}>
            <CategoryLine
              category={item.category}
              tags={[]}
              key={"tech-group" + item.label}
              label={item.label}
              name={name}
              excludeTags={item.technologies}
              addGroupSelect={addGroupSelect}
              addTagInSelect={addTagInSelect}
              haveTags={true}
              isTechName={false}
              isTech={true}
              technologies={item.technologies}
            />
          )
          // </InfiniteScroll>
          //)
        );
      }
      const uniqueTechnologies = new Set();
      finishRoleGroups.map((item) => {
        if (item.technologies) {
          item.technologies.map((tech) => {
            if (tech.toLowerCase().includes(value.toLowerCase())) {
              uniqueTechnologies.add(tech);
            }
          });
        }
      });
      const technologyItems = [...uniqueTechnologies].map((tech) => (
        <CategoryLine
          category={tech}
          tags={[]}
          key={"tech-" + tech}
          label={tech}
          excludeTags={[]}
          addGroupSelect={addGroupSelect}
          addTagInSelect={addTagInSelect}
          haveTags={haveTags}
          isTechName={true}
          isTech={true}
        />
      ));
      let technologiesWithHeading = null;
      if (technologyItems.length) {
        technologiesWithHeading = (
          <AuxContainer>
            <p className="dropdown-in__title">Technology Name</p>
            {technologyItems}{" "}
          </AuxContainer>
        );
      }
      technologyTitle = (
        <p className="dropdown-in__title">
          {technologyGroups && technologyGroups.length
            ? "Technology Group"
            : "No matches found"}{" "}
        </p>
      );
      let techSequence = (
        <AuxContainer>
          {technologyTitle}
          {technologyGroups}
          {technologiesWithHeading}{" "}
        </AuxContainer>
      );
      if (value) {
        techSequence = (
          <AuxContainer>
            {technologiesWithHeading}
            {technologyTitle}
            {technologyGroups}
          </AuxContainer>
        );
      }
      technologyDropDown = (
        <div className="dropdown-in__groups">{techSequence}</div>
      );
    }

    /* let heading = '';
        if (!haveTags) {
          let headingText = label;
          if (value && !customInput && !finishRoleGroups.length) {
            headingText = 'No matches found'
          }
          if (!finishRoleGroups.length) {
            heading = <p className="dropdown-in__title">{headingText}</p>
          }
        } */

    return (
      <div className="dropdown-in animation-slideDown">
        {excludeSwtich}
        {includePreviousRole}
        {customIncludeOrExcludeParentDiv}
        {/* {heading} */}
        {haveTagsAndNotKeywords}
        {name !== "keywords" && name !=='industries2'  && !haveTags && name !== "technologies" && (
          <div className="dropdown-in__groups scroll-dropdown">
            {finishRoleGroups.map((item) => {
              return (
                <CategoryLine
                  category={item.category}
                  tags={item.tags}
                  key={item.category}
                  label={item.label}
                  addExcludeTag={addExcludeTag}
                  name={name}
                  excludeTags={item.excludeTags}
                  addGroupSelect={addGroupSelect}
                  addTagInSelect={addTagInSelect}
                  haveTags={haveTags}
                />
              );
            })}
          </div>
        )}
        {name === "industries2"  && !haveTags  && (
          <div className="dropdown-in__groups scroll-dropdown">
            {finishRoleGroups.map((item) => {
             return (
                <CategoryLine
                  category={item.category}
                  tags={item.tags}
                  key={item.category}
                  label={item.label}
                  addExcludeTag={addExcludeTag}
                  name={name}
                  excludeTags={item.excludeTags}
                  addGroupSelect={addGroupSelect}
                  addTagInSelect={addTagInSelect}
                  haveTags={haveTags}
                />
              );
            })}
          </div>
        )}

        {name !== "keywords" && haveTags && (
          <div className="dropdown-in__groups">
            {finishRoleGroups.map((item) =>  {
              return (
              <CategoryLine
                category={item.category}
                tags={item.tags}
                key={"have-tags-" + item.category}
                label={item.label}
                name={name}
                excludeTags={item.excludeTags}
                addGroupSelect={addGroupSelect}
                addTagInSelect={addTagInSelect}
                haveTags={haveTags}
                isTechName={false}
              />
            )})}
          </div>
        )}

      

        {technologyDropDown}
      </div>
    );
  }
}

export default AddGroups;
