import React, {Component} from 'react';
import CancelFeedbackPopup from '../CancelFeedbackPopup';
import CancelPopup from '../CancelPopup';
import moment from 'moment';
import ConfirmationPopup from '../../../ConfirmationPopup';
import ConfirmCancellationPopup from '../ConfirmCancellationPopup'
import {
    cancelSubscription,
    doubleLeadCredits,
    revertSubscriptionCancellation,
    revertSubscriptionUpdate,
} from '../../../../services/billing';
import {toast} from 'react-toastify';
import {EmitterService} from '../../../../services/emitterService';
import DoubleLeadCreditsPopup from "../DoubleLeadCreditPopup";

class BillingCurrentPlan extends Component {

    state = {
        planMoreDetails: true,
        cancelPopup: false,
        doubleLeadCreditsPopup: false,
        cancelFeedbackPopup: false,
        stopCancellationPopup: false,
        confirmCancellationPopup: false,
        maintenance: false,
    };

    
    componentWillMount() {
        EmitterService.subscribe('cancelPlan', () => {
            this.showConfirmCancellationPopup(true);
        })
    }

    togglerMoreDetails = () => {
        this.setState({planMoreDetails: !this.state.planMoreDetails});
    }

    showConfirmCancellationPopup = (show) => {
        this.setState({confirmCancellationPopup: show});
    }
    showDoubleLeadCreditsPopup = (show) => {
        this.setState({doubleLeadCreditsPopup: show, confirmCancellationPopup: false});
    }

    showCancelPopup = (show) => {
        if (!this.isCancelDisabled()) {
            this.setState({cancelPopup: show, doubleLeadCreditsPopup: false});
        }
    }

    stopCancellationPopup = (show) => {
        this.setState({stopCancellationPopup: show});
    }


    showCancelFeedbackPopup = (show) => {
        let maintenance = this.state.maintenance;
        if (show) {
            // setting to default false if popup is reopened
            maintenance = false;
        }
        this.setState({cancelPopup: false, maintenance, cancelFeedbackPopup: show});
    }

    onStopCancellation = async () => {
        this.stopCancellationPopup(false);
        const isOnCancel = this.isSubscriptionOnCancel();
        const toastId = toast(isOnCancel ? 'Stopping cancellation' : 'Reverting update', {autoClose: 10000});
        try {
            if (isOnCancel) {
                await revertSubscriptionCancellation()
            } else {
                await revertSubscriptionUpdate()
            }
            EmitterService.emit("loadBillingOverview");
            setTimeout(() => {
                toast.update(toastId, {
                    className: "toast-success-div",
                    render: isOnCancel ? 'Stopped Cancellation' : 'Stopped Update',
                    type: toast.TYPE.SUCCESS,
                    autoClose: 2000,
                });
            }, 1000);
        } catch (e) {
            setTimeout(() => {
                toast.update(toastId, {
                    className: "toast-error-div",
                    render: `Failed to stop the ${isOnCancel ? 'Cancellation' : 'Update'}`,
                    type: toast.TYPE.ERROR,
                    autoClose: 2000,
                });
            }, 200);
        }
        this.togglerMoreDetails();
    }

    getUpcomingInvoiceDateText() {
        const timeInMillis = this.getUpcomingInvoiceDateTimestamp()
        if (timeInMillis == 0) {
            return 'No upcoming invoice'
        }
        if (timeInMillis) {
            const invoiceDate = this.formatDate(timeInMillis)
            return `$${Number(this.props.billingOverview.upcoming_invoice.amount / 100).toFixed(2)} on ${invoiceDate}`;
        }
        return '';
    }

    onCancelSubscription = async (reason) => {
        this.showCancelFeedbackPopup(false);
        const toastId = toast(this.getCancellationMessage(false), {autoClose: 10000});
        try {
            await cancelSubscription(false, reason);
            EmitterService.emit("loadBillingOverview");
            setTimeout(() => {
                toast.update(toastId, {
                    className: "toast-success-div",
                    render: this.getCancellationMessage(false),
                    type: toast.TYPE.SUCCESS,
                    autoClose: 8000,
                });
            }, 1000);
        } catch (e) {
            setTimeout(() => {
                toast.update(toastId, {
                    className: "toast-error-div",
                    render: 'Unable to cancel subscription.',
                    type: toast.TYPE.ERROR,
                    autoClose: 2000,
                });
            }, 200);
        }
    }

    onMaintainSubscription = async () => {
        this.showCancelPopup(false);
        this.setState({maintenance: true})
        const toastId = toast(this.getCancellationMessage(true), {autoClose: 10000});
        try {
            await cancelSubscription(true, "");
            EmitterService.emit("loadBillingOverview");
            setTimeout(() => {
                toast.update(toastId, {
                    className: "toast-success-div",
                    render: this.getCancellationMessage(true),
                    type: toast.TYPE.SUCCESS,
                    autoClose: 8000,
                });
            }, 1000);
        } catch (e) {
            setTimeout(() => {
                toast.update(toastId, {
                    className: "toast-error-div",
                    render: 'Unable to switch to maintenance mode.',
                    type: toast.TYPE.ERROR,
                    autoClose: 2000,
                });
            }, 200);
        }
    }

    onDoubleLeadCredits = async () => {
        this.showCancelPopup(false);
        const toastId = toast("Requesting to double lead credits.", {autoClose: 10000});
        try {
            await doubleLeadCredits();
            EmitterService.emit("loadBillingOverview");
            setTimeout(() => {
                toast.update(toastId, {
                    className: "toast-success-div",
                    render: "Great! You'll receive DOUBLE the lead credits going forward.",
                    type: toast.TYPE.SUCCESS,
                    autoClose: 8000,
                });
            }, 1000);
        } catch (e) {
            setTimeout(() => {
                toast.update(toastId, {
                    className: "toast-error-div",
                    render: 'Unable to double lead credits',
                    type: toast.TYPE.ERROR,
                    autoClose: 2000,
                });
            }, 200);
        }

    }

    getCancellationMessage = (isMaintenance) => {
        const planName = this.props.billingOverview.plan_name.toLowerCase();
        if (isMaintenance) {
            return planName.indexOf('commitment') > -1 ? 'Your subscription will be moved to maintenance at the end of your contract.'
                : 'Your subscription will be moved to maintenance at the end of your billing cycle.';
        } else {
            return planName.indexOf('commitment') > -1 ? 'Your subscription will be cancelled automatically at the end of your contract.'
                : 'Your subscription will be cancelled automatically at the end of your billing cycle.';
        }
    }

    getMoreDetailsText() {
        const actionDate = this.formatDate(this.getSubscriptionActonTimestamp());
        const action = this.props.billingOverview.subscription_action.action;
        if (action === 'cancel') {
            return `* Cancels on ${actionDate}`;
        } else if (action === 'update' || action === 'change') {
            const plan = this.props.billingOverview.subscription_action.plan;
            return `* Updates to ${plan.indexOf("maintenance") > -1 ? "Maintenance" : this.formatPlanName(plan)} on ${actionDate}`;
        }
        return '';
    }

    isSubscriptionOnCancel() {
        const action = this.props.billingOverview.subscription_action.action;
        return action === 'cancel';
    }

    isSubscriptionOnMaintenanceMode() {
        const action = this.props.billingOverview.subscription_action.action;
        const plan = this.props.billingOverview.subscription_action.plan;
        return this.getPlanName().indexOf("maintenance") > -1 || (action === 'change' && plan.indexOf("maintenance") > -1);
    }

    isSubscriptionOnCancelOrUpdate() {
        const action = this.props.billingOverview.subscription_action.action;
        return action === 'cancel' || action === 'change';
    }

    getUpcomingInvoiceDateTimestamp() {
        return this.props.billingOverview.upcoming_invoice.timestamp;
    }

    getContractRenewalDateTimestamp() {
        return this.props.billingOverview.contract_renewal_date
    }

    getSubscriptionActonTimestamp() {
        return this.props.billingOverview.subscription_action.timestamp;
    }

    getSubscriptionDiscount() {
        return this.props.billingOverview.discount
    }

    getExtraLeadCredits() {
        return this.props.billingOverview.extra_leads
    }

    formatDate(timeInMillis) {
        return moment(timeInMillis * 1000).format('MMM Do, YYYY');
    }

    formatPlanName(plan) {
        const parts = plan.split("_");
        if (parts.length > 2) {
            return parts[0] + " - " + parts[2]
        }

        if (parts.length == 2) {
            return parts[0] + " - " + parts[1]
        }

        if (plan.indexOf("maintenance") > -1) {
            return "Maintenance"
        }
        return ""
    }

    getPlanName() {
        const {billingOverview} = this.props;
        const planName = billingOverview.plan_name;

        /*
        if (planName == "lf_starter_plan") {
            return "Starter Plan";
        }*/

        if (!planName) {
            return 'lifetime';
        }
        let realName = this.formatPlanName(planName);
        return realName;
    }

    isCancelDisabled() {
        return this.getPlanName() === 'lifetime' || this.isSubscriptionOnCancel();
    }

    isSubscriptionOnContract() {
        return this.getPlanName().indexOf("commitment") > -1
    }

    hasDoubledLeadCreditsBefore() {
        return this.props.billingOverview.double_lead_credits_offer
    }

    render() {
        /*
        let userAccount = JSON.parse(localStorage.getItem("userAccount"));
        let cancelTitle = "Cancel Subscription"
        if (userAccount && userAccount.plan_name.indexOf("lf_starter_plan") > -1 && userAccount.status.indexOf("active_trial") > -1) {
            cancelTitle = "Cancel Trial"
        }*/
        
        return (
            <div className="billing-current-plan">
                <h4 className="billing-current-plan-title">Plan: {this.getPlanName()}</h4>

                <p className="billing-current-plan-renew">Upcoming
                    Invoice: <br/><span>{this.getUpcomingInvoiceDateText()}</span>
                </p>
                {this.props.planDetailsTab && this.isSubscriptionOnContract() && !this.isSubscriptionOnCancelOrUpdate() &&
                <p className="billing-current-plan-renew">Contract Renews
                    on: <br/><span>{this.formatDate(this.getContractRenewalDateTimestamp())}</span>
                </p>
                }
                {this.props.planDetailsTab && (this.getSubscriptionDiscount() != "" || this.getExtraLeadCredits() != "") &&
                <p className="billing-current-plan-renew">Extras:
                    {this.getSubscriptionDiscount() != "" ?
                        <span><br/><span>{this.getSubscriptionDiscount() + " off on " + (this.getPlanName().indexOf("annual") > -1 ? "annual" : "monthly") + " bill"}</span></span> : ""}
                    {this.getExtraLeadCredits() != "" ?
                        <span><br/><span>{this.getExtraLeadCredits() + " extra lead credits per " + (this.getPlanName().indexOf("annual") > -1 ? "year" : "month")}</span></span> : ""}
                </p>
                }
                {this.isSubscriptionOnCancelOrUpdate() &&
                <div className="billing-current-plan-details">
                    <p>{this.getMoreDetailsText()}
                        {this.isSubscriptionOnCancelOrUpdate() && <div className="link"
                                                                       onClick={() => this.stopCancellationPopup(true)}>{`Stop ${this.isSubscriptionOnCancel() ? 'Cancellation' : 'Update'}`}</div>}</p>
                </div>
                }
                 {
                    this.props.billingOverview &&  this.props.billingOverview.plan_name === 'commitment_1022_unlimited' &&
                    <div className="billing-current-plan-actions">
                        <div></div>
                        <p className={`link ${this.isCancelDisabled() ? 'disabled' : ''}`}
                           onClick={() =>
                               this.showConfirmCancellationPopup(true)
                           }>Cancel Subscription</p>
                    </div>
                }
                {
                    this.props.planDetailsTab && this.props.billingOverview &&  this.props.billingOverview.plan_name !== 'commitment_1022_unlimited' &&
                    <div className="billing-current-plan-actions">
                        <div></div>
                        <p className={`link ${this.isCancelDisabled() ? 'disabled' : ''}`}
                           onClick={() =>
                               this.showConfirmCancellationPopup(true)
                           }>Cancel Subscription</p>
                    </div>
                }

                {this.state.cancelPopup &&
                <CancelPopup
                    isSubscriptionOnMaintenanceMode={this.isSubscriptionOnMaintenanceMode()}
                    showCancelPopup={this.showCancelPopup}
                    showCancelFeedbackPopup={this.showCancelFeedbackPopup}
                    onMaintainSubscription={this.onMaintainSubscription}
                    subscriptionDiscount={this.getSubscriptionDiscount()}
                    extraLeadCredits={this.getExtraLeadCredits()}
                    planName={this.getPlanName()}
                />
                }
                {this.state.confirmCancellationPopup &&
                <ConfirmCancellationPopup
                    showDoubleLeadCreditsPopup={this.showDoubleLeadCreditsPopup}
                    showConfirmCancellationPopup={this.showConfirmCancellationPopup}
                />
                }
                {this.state.doubleLeadCreditsPopup &&
                <DoubleLeadCreditsPopup
                    hasDoubledLeadCreditsBefore={this.hasDoubledLeadCreditsBefore()}
                    showDoubleLeadCreditsPopup={this.showDoubleLeadCreditsPopup}
                    showCancelPopup={this.showCancelPopup}
                    onDoubleLeadCredits={this.onDoubleLeadCredits}
                    subscriptionDiscount={this.getSubscriptionDiscount()}
                    extraLeadCredits={this.getExtraLeadCredits()}
                    planName={this.getPlanName()}
                />
                }

                {this.state.cancelFeedbackPopup &&
                <CancelFeedbackPopup
                    showCancelFeedbackPopup={this.showCancelFeedbackPopup}
                    onCancelSubscription={this.onCancelSubscription}
                />
                }

                {this.state.stopCancellationPopup &&
                <ConfirmationPopup
                    title={`Stop ${this.isSubscriptionOnCancel() ? 'Cancellation' : 'Update'}`}
                    message={`Are you sure you want to stop this pending ${this.isSubscriptionOnCancel() ? 'cancellation' : 'update'}?`}
                    yesBtnText="YES"
                    noBtnText="NO"
                    onYesClick={this.onStopCancellation}
                    onNoClick={() => this.stopCancellationPopup(false)}
                    onCloseClick={() => this.stopCancellationPopup(false)}
                />

                }

            </div>
        )
    }
}

export default BillingCurrentPlan;