import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import './style.scss';

class BreadCrumb extends Component {
    state = {
        path: window.location.pathname
    }
  render() {
    const { path } = this.state
    const { links, renderAdditionalContent, changeSearch } = this.props
    return (
        <div className="breadcrumb">
            {
                links.map((item, index) =>

                    <li className={`breadcrumb__item ${path === item.slug ? 'active' : ''}`} key={index}>
                        {
                        item.slug ? item.name == 'Market Based' || item.name == 'Account Based' ? <span className="clickable" onClick={changeSearch}>{item.name}</span> :
                        <Link to={item.slug} >
                            {item.name}
                        </Link> : item.name
                        }
                        {index < links.length - 1 && <span className="separator">></span>}
                    </li>
                )
            }
            <div className="breadcrumb__additional-content" >
                {
                    renderAdditionalContent && renderAdditionalContent()
                }
            </div>
        </div>
    )
  }
}

export default BreadCrumb;
