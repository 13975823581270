import { Fab } from "@material-ui/core";
import React, { Component, Fragment } from "react";

export default class SaveSearchNameDialog extends Component {
  state = {
    saveSearchName: "",
    nameRequireError: false
  };

  updateSaveSearch = (e) => {
      this.setState({saveSearchName: e.target.value});
  }

  saveSearch() {
    if (this.state.saveSearchName === "") {
      this.setState({nameRequireError: true});
      return;
    }
    this.setState({nameRequireError: false});
    this.props.onSaveSearch(this.state.saveSearchName);
  }

  onClosePopup = () => {
    this.setState({ error: 0, value: "", errorMessage: '' })
  }

  render() {
    const { open, onCancel } = this.props;
    const { saveSearchName } = this.state;
    return (
      <Fragment>
        <div className={`add-list ${open ? "open" : ""}`}>
          <div className="add-list__content">
            <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={onCancel} />
            <p className="add-list__title">Save Search Criteria</p>
            <p className="add-list__label">Name your saved search</p>
            <input
              type="text"
              name="firstName"
              className={`w-100 ${this.state.nameRequireError ? 'error-field' : ''}`}
              style={{marginBottom: '10px'}}
              placeholder="Enter a name for your search"
              value={saveSearchName}
              onChange={this.updateSaveSearch}
            />

            {this.state.nameRequireError && <div className="error">Field required</div>}
            
            <div className="add-list__buttons save-search__buttons">
              <Fab
                onClick={() => this.saveSearch()}
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add">Save</Fab>

              <Fab
                onClick={onCancel}
                variant="extended"
                size="medium"
                color="secondary"
                aria-label="add">Cancel</Fab>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
