import React, {Component} from 'react'
import {bindActionCreators} from 'redux';
import {loginAttempts, saveUser, signInUserFailure, signInUserSuccess} from '../../actions/users';
import {startLoader, stopLoader} from '../../actions/loader';
import {doSignIn} from '../../services/users';
import Grid from '@material-ui/core/Grid';
import {SignInLeft} from '../../components';
import {connect} from 'react-redux';
import './style.scss';
import {validate} from '../../helpers/validate';
import {LOCAL_STORAGE_KEYS} from '../../constants/constant';
import {EmitterService} from "../../services/emitterService";

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveUser,
        signInUserSuccess,
        startLoader,
        stopLoader,
        signInUserFailure,
        loginAttempts
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.user,
        loader: state.loader
    };
}

class SignIn extends Component {
    state = {
        validationError: "",
        logOut: false
    }

    componentWillMount() {
        EmitterService.emit("loadLeadsRemaining");
    }

    onSubmit = (values) => {
        let error = validate(values)
        if (values && values.email) {
            values.email = values.email.toLowerCase()
        }
        const urlParams = new URLSearchParams(window.location.search);
        const path = urlParams.get('path');
        this.setState({validationError: error})
        const {loginAttempts, user, signInUserSuccess, startLoader, stopLoader, signInUserFailure, history} = this.props;
        if (values.email && values.pass) {
            startLoader(true);
            doSignIn(values).then(function (response) {
                let u = response.data.data[0]
                let acc = response.data.data[1]
                let status = response.data.data[1].status
                if (status === "fraud") {
                    signInUserFailure(error);
                    stopLoader(false);
                } else {
                    signInUserSuccess(u);
                    loginAttempts(0)
                    localStorage.setItem('user', JSON.stringify(u))
                    window.init_user(u.email)
                    localStorage.setItem('userAccount', JSON.stringify(acc))
                    if (status === "trial_expired") {
                        history.push("/billing-and-users")
                    }
                    if (path === "/api-keys") {
                        history.push(path)
                    } else if (path === "/lead-search/market-based") {
                        history.push(path)
                    } else if (path === "/ignore-list") {
                        history.push(path)
                    } else if (path === "/lead-search/account-based") {
                        history.push(path)
                    } else if (path === "/lead-search/saved-searches") {
                        history.push(path)
                    } else if (path === "/people-lists") {
                        history.push(path)
                    } else if (path === "/profile") {
                        history.push(path)
                    } else if (path === "/integrations") {
                        history.push(path)
                    } else if (path === "/admin/coupon") {
                        history.push(path)
                    } else if (path === "/admin/users") {
                        history.push(path)
                    } else if (path !== '' || path === '') {
                        history.push(path)
                    } else {
                        history.push('/lead-search/market-based')
                    }
                    stopLoader("signinFalse");
                }
            }).catch(error => {
                loginAttempts()
                if (user.isLoginAttempts > 2) {
                    history.push("/forgot-pass")
                }
                this.setState({logOut: true})
                signInUserFailure(error);
                stopLoader("signinFalse");

            });

        }
    }

    render() {
        const {history, user, loader} = this.props;
        const {validationError, logOut} = this.state
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        return (
            <section className="log">
                {!(userId === null) &&
                history.push("/lead-search/market-based")
                }
                <Grid
                    container
                    direction="row">
                    <Grid item xs={12} sm={4}>
                        <SignInLeft visible={loader.loading}
                                    responseError={user.error}
                                    logOut={logOut}
                                    onSubmit={this.onSubmit}
                                    validationError={validationError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} className="log-right">
                        <img src="/assest/sign-in/laptop.png" alt=""/>
                    </Grid>
                </Grid>
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
