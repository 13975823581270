import React, {Component, Fragment} from 'react'
import {createDuxSoupIntegration, updateDuxSoupIntegrations} from '../../../services/integrations'
import {toast} from 'react-toastify';
import ReactTooltip from 'react-tooltip'
import {LOCAL_STORAGE_KEYS} from '../../../constants/constant';
import {showErrorToast, showSuccessToast} from '../../../helpers/toast';

class DuxSoupPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            syncLeads: false,
            requireEmail: false,
            personalEmail: false,
            companyEmails: false,
            doubleVerified: false,
            apiKey: '',
            duxSoupId: '',
            formErrors: []
        }
    }

    componentDidMount = () => {
        const {activeIntegrations, nameForPopup} = this.props
        activeIntegrations.map(items => {
            if (items.name === nameForPopup) {
                this.setState({
                    syncLeads: items.auto_sync,
                    requireEmail: items.require_email,
                    personalEmail: items.include_personal_emails,
                    companyEmails: items.include_company_emails,
                    doubleVerified: items.include_double_verified_emails,
                    apiKey: items[nameForPopup].api_key,
                    duxSoupId: items[nameForPopup].user_id,
                })
            }
        })
    }
    changeCheck = (i) => {
        if (i === 1) {
            this.setState({companyEmails: !this.state.companyEmails})
        } else if (i === 2) {
            this.setState({personalEmail: !this.state.personalEmail})
        } else {
            this.setState({doubleVerified: !this.state.doubleVerified})
        }
    }

    changeSwitch = (i) => {
        if (i === 2) {
            this.setState({requireEmail: !this.state.requireEmail})
        } else {
            this.setState({syncLeads: !this.state.syncLeads})
        }
    }
    handleChange = (e) => {
        this.setState({apiKey: e.target.value})
    }
    duxSoupHandleChange = (e) => {
        this.setState({duxSoupId: e.target.value})
    }

    isNullOrEmpty(field) {
        return !field || field.trim().length === 0;
    }

    validateForm = () => {
        const {duxSoupId, apiKey} = this.state;
        const formErrors = {};


        if (this.isNullOrEmpty(duxSoupId)) {
            formErrors["duxSoupId"] = "This field is required";
        }

        if (this.isNullOrEmpty(apiKey)) {
            formErrors["apiKey"] = "This field is required";
        }

        this.setState({formErrors: formErrors});
        return Object.keys(formErrors).length === 0;
    }

    onConfirmClick = async () => {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        const {apiKey, duxSoupId, syncLeads, requireEmail, personalEmail, companyEmails, doubleVerified} = this.state
        const {getIntegrations, nameForPopup, openDuxSoupPopup, addActiveIntegration} = this.props

        if (this.validateForm()) {
            let toastId = null;
            toastId = toast('Turning on integration', {
                autoClose: 15000,
            });
            try {
                const res = await createDuxSoupIntegration(user.id, duxSoupId, apiKey, nameForPopup, syncLeads,
                    requireEmail,
                    companyEmails,
                    personalEmail,
                    doubleVerified,
                    user.account_id)
                showSuccessToast(toastId, "Integration turned on successfully");
                openDuxSoupPopup("", "true")
                addActiveIntegration(res.data.data);
            } catch (error) {
                showErrorToast(toastId, "Unable to turn on integration");
            }
        }
    }


    onUpdateClick = async () => {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        const {duxSoupId, apiKey, syncLeads, requireEmail, personalEmail, companyEmails, doubleVerified} = this.state
        const {nameForPopup, getIntegrations, openDuxSoupPopup, integID} = this.props

        if (this.validateForm()) {
            let toastId = null;
            toastId = toast('Updating integration...', {
                autoClose: 15000,
            });
            try {
                await updateDuxSoupIntegrations(user.id, duxSoupId, apiKey, integID, nameForPopup, syncLeads,
                    requireEmail,
                    personalEmail,
                    companyEmails,
                    doubleVerified)
                showSuccessToast(toastId, "Integration updated successfully")
                openDuxSoupPopup("", "true")
            } catch (error) {
                showErrorToast(toastId, "Unable to update integration")
            }
        }
    }

    onClosePopup = () => {
        const {toggelIndex, openDuxSoupPopup} = this.props
        openDuxSoupPopup("close", "", "", toggelIndex)
    }

    render() {
        const {settingPopup, popupDuxSoup, image, nameForPopup} = this.props
        const {duxSoupId, syncLeads, requireEmail, doubleVerified, companyEmails, personalEmail, apiKey} = this.state
        return (
            <Fragment>
                <div className={`add-list ${popupDuxSoup ? 'open' : ''}`}>
                    <div className={`add-list__content add-list__content-ed`}>
                        <img className="add-list__close" src="/assest/accountBased/close.svg" alt=""
                             onClick={this.onClosePopup}/>
                        <p className="add-list__title image-title">Dux-Soup Integration <img className="integration-img"
                                                                                             src={`/assest/integrations/${image}`}
                                                                                             alt=""/></p>
                        <p className="lead-cont-item__date light-text">Please make sure to <strong>turn on the Remote
                            Control Switch</strong> under Dux-Soup's Extension - Settings - Connect-tab.<br/><br/>
                            Turning on this option enables Dux-Soup's to <strong>automatically view and connect with a
                                contact's LinkedIn profile </strong>after it is added by LeadFuze. This request can only
                            be
                            processed when the user's browser is open.</p>
                        <div className="popup-filters">
                            <p className="add-list__label mar-top">DUX-SOUP USER ID &nbsp;<img
                                src="/assest/icon-help.svg" alt="" width="10"
                                data-tip='The user id will be available under Duxsoup s extension > options/settings > user tab'/>
                            </p>
                            <ReactTooltip/>
                            <input className="add-list__search"
                                   placeholder="Enter Dux-soup User ID"
                                   type="text"
                                   value={duxSoupId}
                                   onChange={this.duxSoupHandleChange}
                                   required/>
                            {this.state.formErrors.duxSoupId &&
                            <div className="error">{this.state.formErrors.duxSoupId}</div>}
                        </div>
                        <div className="popup-filters">
                            <p className="add-list__label mar-top">API KEY &nbsp;<img src="/assest/icon-help.svg" alt=""
                                                                                      width="10"
                                                                                      data-tip={`The api key will be available under Duxsoup's extension > options/settings > connect tab. Please make sure to turn on the remote control switch.`}/>
                            </p>
                            <input className="add-list__search"
                                   placeholder="Enter Api Key"
                                   type="text"
                                   value={apiKey}
                                   onChange={this.handleChange}
                                   required/>
                            {this.state.formErrors.apiKey &&
                            <div className="error">{this.state.formErrors.apiKey}</div>}
                        </div>
                        <div className="popup-filters">
                            <div className="right-filters__data rm_border">
                                <div className={`switch ${syncLeads ? 'active' : ''}`}
                                     onClick={() => this.changeSwitch()}><span></span>
                                </div>
                                <p className="switch__name">SYNC LEADS AUTOMATICALLY?</p>
                            </div>
                            <div className="right-filters__data rm_border">
                                <div className={`switch ${requireEmail ? 'active' : ''}`}
                                     onClick={() => this.changeSwitch(2)}><span></span>
                                </div>
                                <p className="switch__name">REQUIRE EMAIL?</p>
                            </div>
                        </div>
                        <div className={`popup-animation ${requireEmail ? "animate" : ""}`}>
                            <div className="right-filters__check check-margin">
                                <div className={`check ${doubleVerified ? 'active' : ''}`}
                                     onClick={() => this.changeCheck()}></div>
                                <p>Double verified emails only</p>
                            </div>
                            <div className="right-filters__check check-margin">
                                <div className={`check ${companyEmails ? 'active' : ''}`}
                                     onClick={() => this.changeCheck(1)}></div>
                                <p>Include company emails</p>
                            </div>
                            <div className="right-filters__check check-margin">
                                <div className={`check ${personalEmail ? 'active' : ''}`}
                                     onClick={() => this.changeCheck(2)}></div>
                                <p>Include personal emails</p>
                            </div>
                        </div>
                        <p className="add-list__create">
                        </p>

                        {!settingPopup &&
                        <div className="add-list__buttons">
                            <div className="add-list__btn" onClick={() => {
                                this.onConfirmClick()
                            }}>Authenticate
                            </div>
                            <div className="add-list__btn" onClick={this.onClosePopup}>Cancel</div>
                        </div>
                        }
                        {settingPopup &&
                        <div className="add-list__buttons">
                            <div className="add-list__btn" onClick={() => {
                                this.onUpdateClick()
                            }}>Update
                            </div>
                            <div className="add-list__btn" onClick={this.onClosePopup}>Cancel</div>
                        </div>
                        }
                    </div>
                </div>

            </Fragment>
        )
    }
}

export default DuxSoupPopup
