import React, { Fragment } from "react";
import { industry_groups } from "../../../../../tags/industry_groups";
import {getDepartmentLable, getIndustriesLable} from "../../../../../constants/helper";
const Field = (props) => {
  const {
    finishRoleGroupsSelected,
    openPanel,
    includeTag,
    excludeTag,
    delGroupSelect,
    dellExcludeTag,
    name,
    count,
    haveTags,
  } = props;

  const getIncludeItemLabel = (item) => {
    if (name === "hiring" || name === "role") return "Keyword: " + item;
    else if (name === "industries2") return getIndustriesLable(item);
    else if (name === "department") return getDepartmentLable(item);
    else return item;
  };
  const getExcludeItemLabel = (item) => {
    if (name === "industries2") return getIndustriesLable(item);
    else if (name === "department") return getDepartmentLable(item);
    else return item;
  };
  const showGroupHPrefix = haveTags || name === "technology";
  return (
    <div className="people-market-field">
      <div className="people-market-field__box">
        {finishRoleGroupsSelected
          ? finishRoleGroupsSelected.map(
              (item, index) =>
                // item.technologies === null ? (
                {
                  return (
                    <Fragment key={index}>
                      <p
                        className={`tech-base ${
                          item.excludeGroup
                            ? "field__exclude_tag exclude_tag"
                            : "people-market-field__group"
                        } ${item.label.toLowerCase()}`} /* ${item.label.toLowerCase()} */
                        onClick={() => openPanel("edit", item.label)}
                      >
                        {haveTags || item.isGroup
                          ? `Group: ` + item.label
                          : name === "hiring"
                          ? "Category: " + item.label
                          : item.label}
                        <img
                          src={`${
                            item.excludeGroup
                              ? "/assest/apiKeys/Close_red.svg"
                              : "/assest/accountBased/del-tag.svg"
                          }`}
                          alt=""
                          onClick={(e) =>
                            delGroupSelect(e, item.category, item.excluded)
                          }
                        />
                        {/* || !item.technologies || item.technologies.length === 0  */}
                      </p>
                    </Fragment>
                  );
                }
              // ) : ""
            )
          : ""}

        {includeTag
          ? includeTag.map((item, index) => (
              <p
                className={`field__custom_include`}
                key={index}
                onClick={() => openPanel("edit")}
              >
                {getIncludeItemLabel(item)}
                <img
                  src="/assest/accountBased/del-tag.svg"
                  alt=""
                  onClick={(e) => dellExcludeTag(item, e)}
                />
              </p>
            ))
          : ""}
        {excludeTag
          ? excludeTag.map((item, index) => (
              <p
                className={`field__custom_exclude exclude_tag`}
                key={index}
                onClick={() => openPanel("edit")}
              >
                {getExcludeItemLabel(item)}

                <img
                  src="/assest/apiKeys/Close_red.svg"
                  alt=""
                  onClick={(e) => dellExcludeTag(item, e, "exclude")}
                />
              </p>
            ))
          : ""}
      </div>

      {/* <div className="people-market-field__buttons">
        <div
          className="people-market-field__add people-market-field__del"
          onClick={() => openPanel("edit")}
        >
          {count} x
        </div>
        <div className="people-market-field__add" onClick={() => openPanel("add")}>
          <img src="/assest/accountBased/plus.svg" alt="" />
        </div>
        <div className={`people-market-field__arrow`}>
          <img
            src="/assest/accountBased/arrow.svg"
            alt=""
            onClick={() => openPanel("edit")}
          />
        </div>
      </div> */}
    </div>
  );
};

export default Field;
