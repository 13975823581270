import React, { Component } from 'react';
import './style.scss';
import AddNewList from './AddNewList';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { leadSortyBy } from '../../../actions/leadfilters';
import { Button } from '@material-ui/core';
import { getLead } from '../../../actions/lead_users';


const list = [
  { name: `List Name`, order: '' },
  { name: 'Total Leads', order: '' },
  { name: 'Fuzebot status', order: '' },
  { name: 'Leads Remaining', order: '' },
]

class LeadListsHeader extends Component {

  sortList = async (name) => {
    const { getLead, leadSortyBy, leadFilter, leadList } = this.props
    let sortOrder = ''
    if (leadFilter.sorting.sortOrder === "asc") {
      sortOrder = "desc"
    } else {
      sortOrder = "asc"
    }
    list.map(lists => {
      if (name === lists.name) {
        lists.order = sortOrder
      } else {
        lists.order = ''
      }
    })
    switch (name) {
      case "List Name":
        await leadSortyBy({ status: "name", sortOrder: sortOrder })
        //await getLead('')
        leadList()
        return true
      case "Total Leads":
        await leadSortyBy({ status: "total_leads", sortOrder: sortOrder })
        //await getLead('')
        leadList()
        return true
      case "Fuzebot status":
        await leadSortyBy({ status: "fuzebot_status", sortOrder: sortOrder })
        //await getLead('')
        leadList()
        return true
      case "Leads Remaining":
        await leadSortyBy({ status: "remaining_leads", sortOrder: sortOrder })
        //await getLead('')
        leadList()
        return true
      default:
        await leadSortyBy("name")
        //await getLead('')
        leadList()
        return true
    }
  }
  render() {
    const { popupNewList, openNewListPopup, leadList } = this.props
    return (
      <div className="lead-header">
        <div className="coupon__title">
          <h3>Lead Lists </h3>
          <div className="coupon__links">
            <p className="add" onClick={openNewListPopup}>
              <Button>
                <img src="/assest/accountBased/plus-orange.svg" alt="" />
                Add new list
              </Button>
            </p>
          </div>
        </div>

        <div className="lead-header__list">
          {
            list.map((item, index) =>
              <div key={index} className={`lead-header__item ${item.clname} ${item.order ? "sorted" : ''} `} >
                <p onClick={() => this.sortList(item.name)}>{item.name}</p>
                {
                  item.order &&
                  <div onClick={() => this.sortList(item.name)}>{
                    item.order === "asc" ? <img src="/assest/accountBased/sort_asc.svg" alt="" /> : <img src="/assest/accountBased/sort_desc.svg" alt="" />
                  }</div>
                }
                {
                  !item.order &&
                  <div onClick={() => this.sortList(item.name)}>{
                    <img src="/assest/accountBased/sort.svg" alt="" />
                  }</div>
                }
              </div>
            )
          }
          <div className={`lead-header__item show_leads_col`} >
              </div>
          <div className={`lead-header__item `} style={{ width: '20px'}} >
              </div>
        </div>
        <AddNewList openNewListPopup={openNewListPopup} popupNewList={popupNewList} leadList={leadList} />
      </div>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    leadSortyBy,
    getLead
  }, dispatch);
}
function mapStateToProps(state) {
  return {
    leadFilter: state.leadFilter,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadListsHeader)
