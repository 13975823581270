import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    setCompanyFoundedYearFrom,
    setCompanyFoundedYearTo,
    setExcludePreviousExperience,
    setMaxPeoplePerCompany,
    setMaxResultsPerCompany,
    setRequireCompanyEmails,
    setRequireDoubleVerifiedEmails,
    setRequireEmail,
    setRequireEmployment,
    setRequireFacebook,
    setRequireJobStatus,
    setRequireLinkedin,
    setRequireMailingAddress,
    setRequirePersonalEmails,
    setRequirePhoneNumber,
    setRequirePersonalPhoneNumber,
    setRequireCompanyPhoneNumber,
    setRequireSocial,
    setRequireTwitter,
    setRequireUnemployment
} from '../../actions/search_result_filters';
import EmailCheck from './EmailCheck';
import JobStatus from './JobStatus';
import SocialCheck from './SocialCheck';
import './style.scss';
import MaxCompanyResults from '../MarkedBasedBox/AdvLead/MaxCompanyResults/max-company-results';
import PhoneCheck from "./PhoneCheck";


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setRequireEmail,
        setRequireDoubleVerifiedEmails,
        setRequireCompanyEmails,
        setRequirePersonalEmails,

        setRequirePhoneNumber,
        setRequireCompanyPhoneNumber,
        setRequirePersonalPhoneNumber,

        setRequireSocial,
        setRequireLinkedin,
        setRequireFacebook,
        setRequireTwitter,

        setRequireJobStatus,
        setRequireEmployment,
        setRequireUnemployment,

        setRequireMailingAddress,
        setExcludePreviousExperience,

        setMaxPeoplePerCompany,
        setMaxResultsPerCompany,
        setCompanyFoundedYearFrom,
        setCompanyFoundedYearTo

    }, dispatch);
}

function mapStateToProps(state) {
    return {
        searchResultFilters: state.searchResultFilters,
        user: state.user,
        marketBased: state.marketBased,
        accountBased: state.accountBased,
        loader: state.loader
    };
}

class RightPanel extends Component {

    setRequirePhoneNumber = async () => {
        const {setRequirePhoneNumber, searchResultFilters} = this.props;
        await setRequirePhoneNumber(!searchResultFilters.require_phone_number);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }


    setRequireCompanyPhoneNumber = async () => {
        const {setRequireCompanyPhoneNumber, searchResultFilters} = this.props;
        await setRequireCompanyPhoneNumber(!searchResultFilters.require_company_phone_number);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setRequirePersonalPhoneNumber = async () => {
        const {setRequirePersonalPhoneNumber, searchResultFilters} = this.props;
        await setRequirePersonalPhoneNumber(!searchResultFilters.require_personal_phone_number);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setRequireEmail = async () => {
        const {setRequireEmail, setRequireDoubleVerifiedEmails, searchResultFilters} = this.props;
        await setRequireEmail(!searchResultFilters.require_email);
        await setRequireDoubleVerifiedEmails(!searchResultFilters.require_email);
         if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setRequireDoubleVerifiedEmails = async () => {
        const {setRequireDoubleVerifiedEmails, searchResultFilters} = this.props;
        await setRequireDoubleVerifiedEmails(!searchResultFilters.require_double_verified_emails);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setRequireCompanyEmails = async () => {
        const {setRequireCompanyEmails, searchResultFilters} = this.props;
        await setRequireCompanyEmails(!searchResultFilters.require_company_emails);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setRequirePersonalEmails = async () => {
        const {setRequirePersonalEmails, searchResultFilters} = this.props;
        await setRequirePersonalEmails(!searchResultFilters.require_personal_emails);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setRequireSocial = async () => {
        const {setRequireSocial, searchResultFilters} = this.props;
        await setRequireSocial(!searchResultFilters.require_social);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setRequireLinkedin = async () => {
        const {setRequireLinkedin, searchResultFilters} = this.props;
        await setRequireLinkedin(!searchResultFilters.require_linkedin);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setRequireFacebook = async () => {
        const {setRequireFacebook, searchResultFilters} = this.props;
        await setRequireFacebook(!searchResultFilters.require_facebook);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setRequireTwitter = async () => {
        const {setRequireTwitter, searchResultFilters} = this.props;
        await setRequireTwitter(!searchResultFilters.require_twitter);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setRequireTwitter = async () => {
        const {setRequireTwitter, searchResultFilters} = this.props;
        await setRequireTwitter(!searchResultFilters.require_twitter);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setRequireJobStatus = async () => {
        const {setRequireJobStatus, searchResultFilters} = this.props;
        await setRequireJobStatus(!searchResultFilters.require_job_status);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setRequireEmployment = async () => {
        const {setRequireEmployment, searchResultFilters} = this.props;
        await setRequireEmployment(true);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setRequireUnemployment = async () => {
        const {setRequireUnemployment, searchResultFilters} = this.props;
        await setRequireUnemployment(true);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setRequireMailingAddress = async () => {
        const {setRequireMailingAddress, searchResultFilters} = this.props;
        await setRequireMailingAddress(!searchResultFilters.require_mailing_address);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }


    setExcludePreviousExperience = async () => {
        const {setExcludePreviousExperience, searchResultFilters} = this.props;
        await setExcludePreviousExperience(!searchResultFilters.exclude_previous_experience);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    setMaxResultsPerCompany = async (maxResults) => {
        const {setMaxResultsPerCompany} = this.props;
        await setMaxResultsPerCompany(maxResults);
        if (this.props.canApplyFilter)
            await this.props.applyFilter();
    }

    render() {

        const {
            clearAllFilters,
            saveSearch,
            searchResultFilters,
        } = this.props;

        return (
            <section className="right">

                {this.props.loader.loading && <div className="right-filters-overlay"></div>}

                <div className="right-filters">
                    <h4 className="right-filters__title right-search__title">Filters</h4>

                    <EmailCheck
                        setRequireEmail={this.setRequireEmail}
                        setRequireDoubleVerifiedEmails={this.setRequireDoubleVerifiedEmails}
                        setRequireCompanyEmails={this.setRequireCompanyEmails}
                        setRequirePersonalEmails={this.setRequirePersonalEmails}
                        searchResultFilters={searchResultFilters}/>

                    <div className="right-filters__data" onClick={this.setRequireMailingAddress}>
                        <div className={`switch ${searchResultFilters.require_mailing_address ? 'active' : ''}`}>
                            <span></span></div>
                        <p className="switch__name">Require Mailing Address</p>
                    </div>

                    <PhoneCheck
                        setRequirePhoneNumber={this.setRequirePhoneNumber}
                        setRequireCompanyPhoneNumber={this.setRequireCompanyPhoneNumber}
                        setRequirePersonalPhoneNumber={this.setRequirePersonalPhoneNumber}
                        searchResultFilters={searchResultFilters}/>

                    <SocialCheck
                        setRequireSocial={this.setRequireSocial}
                        setRequireLinkedin={this.setRequireLinkedin}
                        setRequireFacebook={this.setRequireFacebook}
                        setRequireTwitter={this.setRequireTwitter}
                        searchResultFilters={searchResultFilters}/>

                    {/* <div className="max-results-div">
                        <p className="max-results-p">Max Results per company</p>
                        <MaxCompanyResults searchResultFilters={searchResultFilters}
                                           setMaxResultsPerCompany={this.setMaxResultsPerCompany}></MaxCompanyResults>
                    </div> */}

                    <JobStatus
                        setRequireJobStatus={this.setRequireJobStatus}
                        setRequireEmployment={this.setRequireEmployment}
                        setRequireUnemployment={this.setRequireUnemployment}
                        searchResultFilters={searchResultFilters}/>

                    <div className="right-filters__buttons">
                        {/* {searchResultFilters.searchType === SearchType.MarketBased ? (<p className={`apply-btn`} onClick={saveSearch}>Save search</p>) :(<p></p>)} */}
                        <p className={`apply-btn`} onClick={saveSearch}>Save search</p>
                        <p className="clear-btn" onClick={clearAllFilters}>Clear All</p>
                    </div>

                </div>
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);