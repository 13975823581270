import React, { Component } from "react";
import CategoryLine from "../CategoryLine";
import { getColleges } from "../../../../../services/colleges";
import { getMajors } from "../../../../../services/majors";

class AddCollegeGroup extends Component {

    state = {
        value: '',
        colleges: [],
    }

    componentWillMount(){
        if (this.props.collegeOptions && this.props.collegeOptions.length) {
            this.setState({colleges: this.props.collegeOptions});
        }
    }

    componentWillUnmount() {
        this.props.setCollegeOptions(this.state.colleges);
    }

    async componentWillUpdate(props, state) {
        if (props.value !== state.value) {
            this.setState({value: props.value});
            if (props.value && props.value.length> 2) {
                try {
                    const collegeResp = await (props.name === 'major' ? getMajors(props.value):  getColleges(props.value));
                    const allColleges = collegeResp.data.data;
                    if (allColleges) {
                        this.setState({colleges: allColleges});
                    } else {
                        this.setState({colleges: []});
                    }
                }
                catch(e) {
                }
            } else{
                this.setState({colleges: []});
            }    
        }
    }
    

    getHeadingAndOptions(heading, colleges) {
        let collegesDiv = '';
        if (colleges.length) {
            const  collegeEntries = 
            colleges.map(loc => {
                return (<CategoryLine
                    category={loc}
                    tags={[]}
                    key={loc.term}
                    label={loc.term}
                    addExcludeTag={this.props.addExcludeTag}
                    name={this.props.name}
                    excludeTags={[]}
                    addGroupSelect={this.props.addGroupSelect}
                    addTagInSelect={this.props.addTagInSelect}
                    haveTags={false}
                  />);
            });
            collegesDiv = (
                <div>
                    <p className="dropdown-in__title">{heading}</p>
                    <div >
                        {collegeEntries}
                    </div>        
                </div>
            );
        }
        return collegesDiv;
    }

  render() {
    const {
      finishRoleGroups,
      addGroupSelect,
      addTagInSelect,
      value,
      type,
      addExcludeTag,
      name,
      label,
      haveTags,
      customInput,
    } = this.props;

    let heading = '';
    let headingText = label;
    // if (value && !customInput && !finishRoleGroups.length) {
    // headingText = 'No matches found'
    // }

    if (!this.state.colleges.length) {
        heading = <p className="dropdown-in__title">No matches found</p>
    }
    const collegesDiv = this.getHeadingAndOptions(this.props.basicHeading1, this.state.colleges, true);

    return (
      <div className="dropdown-in animation-slideDown ">
          <div className="dropdown-in__groups scroll-dropdown">
          {heading}
          {collegesDiv}
          </div>
      </div>
    );
  }
}

export default AddCollegeGroup;
