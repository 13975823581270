import React, { Component, Fragment } from 'react'
import Cards from "react-credit-cards";

class CardView extends Component{
  state = {
  }


  render(){
    const card = this.props.currentCard;
    return(
      <Fragment>
        {card ? 
        <Cards
        expiry={`${('0' + card.exp_month).slice(-2)}/${card.exp_year}`}
        // name={name}
        preview={true}
        number={`**** **** **** ${card.last4}`}
        issuer={card.brand}
      ></Cards>
    : <p>No Card Added Yet</p>}
      </Fragment>
    )
  }
}

export default CardView