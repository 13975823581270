import { GET_LEAD_LIST, CLEAR_LEAD_LIST, UPDATE_LIST_ITEM } from "../constants/constant";

const initialState = { leadList: undefined }

const leadList = (state = initialState, action) => {
    switch (action.type) {
        case GET_LEAD_LIST:
            return {
                ...state,
                leadList: action.payload,
            }
        case CLEAR_LEAD_LIST:
            return {
                ...state,
                leadList: undefined,
            }
        case UPDATE_LIST_ITEM:
            let updatedItem = action.payload;
            
            let items = state.leadList.map(item => {
                if(item.id == updatedItem.id) {
                    return updatedItem;
                }
                return item;
            })

            return {
                ...state,
                leadList: items,
            }
        default:
            return state
    }
}
export default leadList
