import React, { Component } from 'react';
import PeopleSearch from './PeopleSearch';
import MergedSearch from './MergedSearch';
import './style.scss';
import { role_groups } from '../../../tags/role_groups';

class Based extends Component {
  
  state = {
    active: 'peopleAct',
    disableName: false,
  };

  changePeople = () =>{
    this.setState({active: 'peopleAct'})
  };
  
  changeCompany = () =>{
    // this.setState({active: 'companyAct'})
  };
  
  setCompanyTags = (companyTagsFromFile) => {
    if(companyTagsFromFile) {
      // this.setState({disableName: true});
    }
    this.props.setExcludeTags(companyTagsFromFile);
  };

  getRoleSelectedGroups = () => {
      const role = this.props.accountBased.role;
      if(role && role.groups) {
        const selectedGroups = [];
        role_groups.map(item => {
           const group = role.groups[item.group];
           if (group) {
            selectedGroups.push({...item, open: group.open, tags: group.include_keywords? group.include_keywords : [],
              include_tags: group.include_keywords? group.include_keywords : item.include_tags,
              exclude_tags: group.exclude_keywords? group.exclude_keywords : item.exclud_tags,
              excludeTags: group.exclude_keywords? group.exclude_keywords : []});
           }
        });
        return selectedGroups;
      }
      return [];
  }

  getRoleIncludeKeywords = () => {
    const role = this.props.accountBased.role;
    if(role) {
      return role.include_keywords ? role.include_keywords : [];
    }
    return [];
  }

  getExcludeKeywordsProp = () => {
    const role = this.props.accountBased.role;
    if(role) {
      return role.exclude_keywords? role.exclude_keywords: [];
    }
    return [];
  }

  render() {
    const { active } = this.state;
    return(
      <section className={`based`}>
       {/* ${!this.props.expendSearchCriteria ? 'collapsed' : 'expended'} */}
        <div className="market-header__top">
          <h2 className={`based__title ${this.props.expendSearchCriteria ? 'collapse' : 'expend'}`}>Search Criteria</h2>
          
          {!this.props.expendSearchCriteria && 
            <div className="market-header__change" onClick={this.props.changeSearch}>Change Search</div>
          }
        </div>

        {this.props.expendSearchCriteria && 

          <div className="based__container">
            {/* <div className="based-tabs">
              <div className={`based-tabs__title based-tabs__people ${active}`} onClick={this.changePeople}>
                <p>People Lookup</p>
              </div>
              <div className={`based-tabs__title based-tabs__company ${active}`} onClick={this.changeCompany}>
                <p>Company Lookup</p>
              </div>
            </div> */}

            <MergedSearch 
              active={active} 
              includeKeywords={this.getRoleIncludeKeywords()}
              excludeKeywords={this.getExcludeKeywordsProp()} 
              selectedGroups={this.getRoleSelectedGroups()} 
              setCompanyTags={this.setCompanyTags}
              disableName={this.state.disableName}
              {...this.props} />

            {/* <PeopleSearch 
              active={active} 
              includeKeywords={this.getRoleIncludeKeywords()}
              excludeKeywords={this.getExcludeKeywordsProp()} 
              selectedGroups={this.getRoleSelectedGroups()} 
              {...this.props} 
              disableName={this.state.disableName} /> */}

          </div>
        }
      </section>
    )
  };
}

export default Based