import React, {Component, Fragment} from 'react'
import {createIntegrations, updateIntegrations} from '../../../services/integrations'
import ReactTooltip from 'react-tooltip'
import {toast} from 'react-toastify';
import $ from 'jquery';
import { LOCAL_STORAGE_KEYS } from '../../../constants/constant';
import { showSuccessToast, showErrorToast } from '../../../helpers/toast';

class PopupForTokenIntegrations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            syncLeads: false,
            requireEmail: false,
            personalEmail: false,
            companyEmails: false,
            doubleVerified: false,
            value: '',
            formErrors: []
        }
    }

    componentDidMount = () => {
        const {activeIntegrations, nameForPopup, name} = this.props
        activeIntegrations.map(items => {
            if (items.name === nameForPopup) {
                this.setState({
                    syncLeads: items.auto_sync,
                    requireEmail: items.require_email,
                    personalEmail: items.include_personal_emails,
                    companyEmails: items.include_company_emails,
                    doubleVerified: items.include_double_verified_emails,
                })
                if (nameForPopup === "close-io") {
                    this.setState({value: items.close_io.token})
                } else if (nameForPopup === "hubspot") {
                    this.setState({value: items.hubspot.token})
                } else if (nameForPopup === "bidsketch") {
                    this.setState({value: items.bidsketch.token})
                } else if (nameForPopup === "lemlist") {
                    this.setState({value: items.lemlist.token})
                } else if (nameForPopup === "salesflare") {
                    this.setState({value: items.salesflare.token})
                } else if (nameForPopup === "woodpecker") {
                    this.setState({value: items.woodpecker.token})
                } else if (nameForPopup === "jazzhr") {
                    this.setState({value: items.jazzhr.token})
                } else if (nameForPopup === "reply") {
                    this.setState({value: items.reply.token})
                } else if (nameForPopup === "highlevel") {
                    this.setState({value: items.highlevel.token})
                }
            }
        })
    }

    changeCheck = (i) => {
        if (i === 1) {
            this.setState({companyEmails: !this.state.companyEmails})
        } else if (i === 2) {
            this.setState({personalEmail: !this.state.personalEmail})
        } else {
            this.setState({doubleVerified: !this.state.doubleVerified})
        }
    }

    changeSwitch = (i) => {
        if (i === 2) {
            this.setState({requireEmail: !this.state.requireEmail})
        } else {
            this.setState({syncLeads: !this.state.syncLeads})
        }
    }
    handleChange = (e) => {
        this.setState({value: e.target.value})
    }

    isNullOrEmpty(field) {
        return !field || field.trim().length === 0;
    }

    validateForm = () => {
      const {value} = this.state;
      const formErrors = {};

      if (this.isNullOrEmpty(value)) {
          formErrors["value"] = "This field is required";
      }

      this.setState({formErrors: formErrors});
      return Object.keys(formErrors).length === 0;
    }

    onConfirmClick = async () => {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        const {value, syncLeads, requireEmail, personalEmail, companyEmails, doubleVerified} = this.state
        const {getIntegrations, nameForPopup, openIntegrationPopup2} = this.props

        if (this.validateForm()) {
            let toastId = null;
            toastId = toast('Turning on integration', {
                autoClose: 20000,
            });
            try {
                await createIntegrations(user.id, value, nameForPopup, syncLeads,
                    requireEmail,
                    personalEmail,
                    companyEmails,
                    doubleVerified,
                    user.account_id)
                this.setState({
                    syncLeads: false,
                    requireEmail: false,
                    personalEmail: false,
                    companyEmails: false,
                    doubleVerified: false,
                    value: ''
                })
                showSuccessToast(toastId, "Integration turned on successfully");
                openIntegrationPopup2("", "true")
            } catch (error) {
                showErrorToast(toastId, "Unable to turn on integration");
            }
        }
    }
    onUpdateClick = async () => {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        const {value, syncLeads, requireEmail, personalEmail, companyEmails, doubleVerified} = this.state
        const {getIntegrations, nameForPopup, openIntegrationPopup2, integID} = this.props

        if (this.validateForm()) {
            let toastId = null;
            toastId = toast('Updating integration...', {
                autoClose: 12000,
            });
            try {
                await updateIntegrations(user.id, value, integID, nameForPopup, syncLeads,
                    requireEmail,
                    personalEmail,
                    companyEmails,
                    doubleVerified)
                openIntegrationPopup2("", "true")
                this.setState({
                    syncLeads: false,
                    requireEmail: false,
                    personalEmail: false,
                    companyEmails: false,
                    doubleVerified: false,
                    value: ''
                })
                showSuccessToast(toastId, "Integration updated successfully");
            } catch {
                showErrorToast(toastId, "Unable to update integration");
            }
        }
    }

    onClosePopup = () => {
        const {toggelIndex, openIntegrationPopup2} = this.props
        openIntegrationPopup2("close", "", "", toggelIndex)
    }

    render() {
        const {settingPopup, popup2, nameForPopup, image, apiLocation, integrationLabel} = this.props
        const {syncLeads, requireEmail, doubleVerified, companyEmails, personalEmail, value} = this.state

        return (
            <Fragment>
                <div className={`add-list ${popup2 ? 'open' : ''}`}>
                    <div className="add-list__content add-list__content-ed">
                        <img className="add-list__close" src="/assest/accountBased/close.svg" alt=""
                             onClick={this.onClosePopup}/>
                        <p className="add-list__title image-title">{integrationLabel} Integration <img className="integration-img" src={`/assest/integrations/${image}`} alt=""/></p>
                        <p className="add-list__label mar-top">API TOKEN &nbsp;<img src="/assest/icon-help.svg" alt=""
                                                                                    width="10" data-tip={apiLocation}/>
                        </p>
                        <ReactTooltip/>
                        <input className="add-list__search"
                               placeholder="Enter Api Token"
                               type="text"
                               value={value}
                               onChange={this.handleChange}
                               required/>
                        {this.state.formErrors.value &&
                            <div className="error">{this.state.formErrors.value}</div>}
                        <div className="popup-filters">
                            <div className="right-filters__data rm_border">
                                <div className={`switch ${syncLeads ? 'active' : ''}`}
                                     onClick={() => this.changeSwitch()}><span></span>
                                </div>
                                <p className="switch__name">SYNC LEADS AUTOMATICALLY?</p>
                            </div>
                            <div className="right-filters__data rm_border">
                                <div className={`switch ${requireEmail ? 'active' : ''}`}
                                     onClick={() => this.changeSwitch(2)}><span></span>
                                </div>
                                <p className="switch__name">REQUIRE EMAIL?</p>
                            </div>
                        </div>
                        <div className={`popup-animation ${requireEmail ? "animate" : ""}`}>
                            <div className="right-filters__check check-margin">
                                <div className={`check ${doubleVerified ? 'active' : ''}`}
                                     onClick={() => this.changeCheck()}></div>
                                <p>Double verified emails only</p>
                            </div>
                            <div className="right-filters__check check-margin">
                                <div className={`check ${companyEmails ? 'active' : ''}`}
                                     onClick={() => this.changeCheck(1)}></div>
                                <p>Include company emails</p>
                            </div>
                            <div className="right-filters__check check-margin">
                                <div className={`check ${personalEmail ? 'active' : ''}`}
                                     onClick={() => this.changeCheck(2)}></div>
                                <p>Include personal emails</p>
                            </div>
                        </div>
                        {!settingPopup &&
                        <div className="add-list__buttons">
                            <div className="add-list__btn" onClick={() => {
                                this.onConfirmClick()
                            }}>Authenticate
                            </div>
                            <div className="add-list__btn" onClick={this.onClosePopup}>Cancel</div>
                        </div>
                        }
                        {settingPopup &&
                        <div className="add-list__buttons">
                            <div className="add-list__btn" onClick={() => {
                                this.onUpdateClick()
                            }}>Update
                            </div>
                            <div className="add-list__btn" onClick={this.onClosePopup}>Cancel</div>
                        </div>
                        }
                    </div>
                </div>

            </Fragment>
        )
    }
}


export default PopupForTokenIntegrations
