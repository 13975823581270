import React, {Component, Fragment} from 'react';
import './style.scss';
import {addApiKey} from '../../../../services/apiKeys';
import {toast} from 'react-toastify';
import $ from 'jquery';
import {LOCAL_STORAGE_KEYS} from '../../../../constants/constant';
import { showSuccessToast, showErrorToast } from '../../../../helpers/toast';

class AddNewKey extends Component {
    state = {
        error: 0,
        successDiv: false,
        value: "",
        errorMessage: ''
    }

    handleChange = (event) => {
        this.setState({value: event.target.value, errorMessage: ''});
    }

    onAddFunction = async (val) => {
        if (!val) {
            this.setState({errorMessage: 'API key name can not be empty'});
            return;
        }
        const {openAddNewKeyPopup, ApiKeys} = this.props
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        let toastId = null;
        toastId = toast('Generating API key', {
            autoClose: 10000,
        });
        try {
            const res = await addApiKey(userId.id, val)
            showSuccessToast(toastId, "API key generated successfully");
            ApiKeys();
            openAddNewKeyPopup()
        } catch (error) {
            // TODO: @Amna what are possible error codes here?
            // if (error.response.data.errCode === 30600) {
            //     errormsg = 'List name can not be blank'
            // } else if (error.response.data.errCode === 30700) {
            //     errormsg = 'List name already exists'
            // } else {
            //     errormsg = 'Permission Denied'
            // }
            showErrorToast(toastId, "Unable to generate API key");
        }
    }
    onClosePopup = () => {
        const {openAddNewKeyPopup} = this.props
        this.setState({
            value: "",
            errorMessage: ""
        })
        openAddNewKeyPopup()
    }

    render() {
        const {value, errorMessage} = this.state
        const {popupAddNewKey} = this.props;
        return (
            <Fragment>
                <div className={`add-list ${popupAddNewKey ? 'open' : ''}`}>
                    <div className="add-list__content">
                        <img className="add-list__close" src="/assest/accountBased/close.svg" alt=""
                             onClick={this.onClosePopup}/>
                        <p className="add-list__title">Generate API Key</p>
                        <p className="add-list__label">API KEY NAME</p>
                        <div className="add-list__search-box">
                            <input className="add-list__search"
                                   placeholder="Name your key (ie, Hubspot Key)"
                                   type="text"
                                   value={this.state.value}
                                   onChange={this.handleChange}
                            />
                            {
                                errorMessage
                                    ? (
                                        <div className="lead-header__model_error">
                                            <p>{errorMessage}</p>
                                        </div>
                                    )
                                    : null
                            }
                        </div>
                        <p className="add-list__create">
                        </p>
                        <div className="add-list__buttons">
                            <div className="add-list__btn orange" onClick={() => {
                                this.onAddFunction(value)
                            }}>
                                Generate Key
                            </div>
                            <div className="add-list__btn orange-light" onClick={this.onClosePopup}>Cancel</div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default AddNewKey;
