import React from 'react';
import OwnersContent from '../OwnersContent';
import ListNames from '../ListNames'
import Popup from "reactjs-popup";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import '../style.scss';
import { OwnerFilter } from '../../OwnerFilter/OwnerFilter';
import { LOCAL_STORAGE_KEYS } from '../../../constants/constant';

const LeadSearchFilter = (props) => {

  const { fuzeBot,
    listNames,
    listAtMax,
    listWithZeroLead,
    ownerState,
    searchValue,
    searchOwnerFilter,
    searchListFilter,
    ownersLists,
    setFuzeActive,
    setFuzePause,
    setFuzeNone,
    changeCheck,
    changeCheckForName,
    changeNameFilter,
    setNameFilter,
    leadFilter,
    applyFilter,
    clearAllFilter,
    isUserAdmin,
    user } = props;

  const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT))
  return (
    <section className="right">
      <div className="right-filters right-filters__checks actCheck">
        <h4 className="right-filters__title">Filters</h4>
        <h5 className="right-filters__title rm_margin">Filter BY:</h5>
        <div className="right-filters__data rm_border">
          <div className={`switch ${leadFilter.filters.fuzeBot ? 'active' : ''}`} onClick={fuzeBot}><span></span></div>
          <p className="switch__name">FUZEBOT STATUS</p>
        </div>
        {leadFilter.filters.fuzeBot &&
          <div className="fuzebot-status right-filters__checks actCheck">
            <div className="right-filters__check" onClick={setFuzeActive}>
              <div className={`check ${leadFilter.filters.fuzebotActive ? 'active' : ''}`} ><span></span></div>
              <p>Active</p>
            </div>
            <div className="right-filters__check" onClick={setFuzePause}>
              <div className={`check ${leadFilter.filters.fuzebotPaused ? 'active' : ''}`}><span></span></div>
              <p>Paused</p>
            </div>
            <div className="right-filters__check" onClick={setFuzeNone}>
              <div className={`check ${leadFilter.filters.fuzebotNone ? 'active' : ''}`} ><span></span></div>
              <p>None</p>
            </div>
          </div>
        }
        <div className="right-filters__check" onClick={setNameFilter}>
          <div className={`check ${leadFilter.filters.nameFilter ? 'active' : ''}`}><span></span></div>
          <p>Name</p>
        </div>
        {leadFilter.filters.nameFilter &&
          <div className="other_option b_pedding">
            <div className="coupon-search__inp sm_coupon-search__inp">
              {/* <img src="/assest/accountBased/search.svg" alt="" /> */}
              <input type="text" style={{ padding: '9px 15px 9px 10px; !important' }}
                placeholder="Enter list name"
                className="name-search"
                value={leadFilter.listName}
                onInput={searchListFilter} />
            </div>
            {/* listNames &&
              <div className="scroll_view">
                <ListNames listNames={listNames}
                  changeCheckForName={changeCheckForName} />
              </div> */
            }
          </div>
        }
        <div className="right-filters__check lists-capacity" onClick={listAtMax}>
          <div className={`check ${leadFilter.filters.listsAtMax ? 'active' : ''}`} ><span></span></div>
          {/* <p onClick={listAtMax}>Lists at Max Capacity &nbsp;</p> */}
          <p  ><span >
            Lists at Max Capacity &nbsp;</span></p>
          <img data-tip data-for="list_at_max_capacity" data-place="bottom" src="/assest/icon-help.svg" alt="" width="12" />
          <ReactTooltip id="list_at_max_capacity">
            <p className="lead-remaining-tooltip">
              Your maximum list capacity is {userAccount && userAccount.list_capacity} leads to avoid
              overly long load times or de-duplication processing times
            </p>
          </ReactTooltip>
        </div>
        <div className="right-filters__check" onClick={listWithZeroLead}>
          <div className={`check ${leadFilter.filters.listsWithZeroRemaining ? 'active' : ''}`} onClick={listWithZeroLead}><span></span></div>
          <p>Lists with 0 Leads Remaining</p>
        </div>
        {isUserAdmin &&
          <div className="right-filters__check" onClick={ownerState}>
            <div className={`check ${leadFilter.filters.ownerState ? 'active' : ''}`} ><span></span></div>
            <p>Owner</p>
          </div>
        }
        {
          isUserAdmin && leadFilter.filters.ownerState &&
          <OwnerFilter
            searchValue={searchValue}
            searchOwnerFilter={searchOwnerFilter}
            ownersLists={ownersLists}
            changeCheck={changeCheck}
          />
        }
        <div className="btn-align top-mar">
          <div className="sidebarText__link " onClick={applyFilter}>Apply</div>
          <div className="adv-lead__link" onClick={clearAllFilter}><p>Clear all</p></div>
        </div>
      </div>
    </section>
  )
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(LeadSearchFilter);
