const list = [
  {
    name: 'Only me',
    active: false    
  },
  {
    name: '2 to 5',
    active: false    
  },
  {
    name: '6 to 10',
    active: false    
  },
  {
    name: '11 to 20',
    active: false    
  },
  {
    name: '21 to 50',
    active: false    
  },
  {
    name: '51 to 100',
    active: false    
  },
  {
    name: 'More than 100',
    active: false    
  },
]

export default list