import React from 'react'

import '../../DontList/style.scss'

const NoList = ({ msg, submsg }) => {
    return (
        <div className="dont-list">
            <h2 className="dont-list__title">{msg}</h2>
            <p className="dont-list__descr">{submsg}</p>
        </div>
    )
}

export default NoList