import axios from '../axios';
const ROOT_URL = process.env.REACT_APP_ROOT_URL;


export function getApiKeys(userId, apiKeysFilter) {
    return axios({
        method: 'get',
        params: {
            "sort": apiKeysFilter.sort,
            "creationDate": apiKeysFilter.create_date,
            "revokeDate": apiKeysFilter.revoke_date,
            "recordsPerPage": apiKeysFilter.recordsPerPage,
            "pageNum": apiKeysFilter.pageNum,
            "order": apiKeysFilter.order
        },
        url: `/core/api-keys`,
    });
}
export function addApiKey(userId, name) {
    return axios({
        method: 'post',
        url: `/core/api-keys`,
        data: {
            name
        }
    });
}
export function revokeApiKey(userId, id) {
    return axios({
        method: 'patch',
        url: `/core/revoke-api-key/${id}`,
    });
}
