const INITIAL_STATE = {
  role: null,
  companyLocation: null,
  personLocation: null,
  college: [],
  major: [],
  estimatedSalary: [],
  yearsOfExperience: [],
  timeInLatestJob: [],
  industries: [],
  industries2: null,
  departments: [],

  employees: null,
  adwords_budget: [],
  technology: null,
  news: [],
  include_keywords: null,
  exclude_keywords: null,
  include_past_roles: false,
  hiring: null,
  skills: null,
  changed: false,
};

export default function marketBased(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ROLE":
      if (action.name === "groups") {
        return {
          ...state,
          role: action.payload,
          changed: true,
        };
      } else {
        return {
          ...state,
          role: action.payload,
          changed: true,
        };
      }
    case "COMPANY_LOCATION":
      return {
        ...state,
        companyLocation: action.payload,
        changed: true,
      };
    case "PERSON_LOCATION":
      return {
        ...state,
        personLocation: action.payload,
        changed: true,
      };
    case "COLLEGE":
      return {
        ...state,
        college: action.payload,
        changed: true,
      };
    case "MAJOR":
      return {
        ...state,
        major: action.payload,
        changed: true,
      };
    case "ESTIMATED_SALARY":
      return {
        ...state,
        estimatedSalary: action.payload,
        changed: true,
      };
    case "YEARS_OF_EXPERIENCE":
      return {
        ...state,
        yearsOfExperience: action.payload,
        changed: true,
      };
    case "TIME_IN_LATEST_JOB":
      return {
        ...state,
        timeInLatestJob: action.payload,
        changed: true,
      };
    case "INDUSTRIES":

      return {
        ...state,
        industries: action.payload,
        changed: true,
      };
    case "EMPLOYEES":
      return {
        ...state,
        employees: action.payload,
        changed: true,
      };
    case "ADWORDS_BUDGET":
      return {
        ...state,
        adwords_budget: action.payload,
        changed: true,
      };
    case "TECHNOLOGY":
      return {
        ...state,
        technology: action.payload,
        changed: true,
      };
    case "NEWS":
      return {
        ...state,
        news: action.payload,
        changed: true,
      };
    case "SKILLS":
      return {
        ...state,
        skills: action.payload,
        changed: true,
      };
    case "INDUSTRIES2":

      return {
        ...state,
        industries2: action.payload,
        changed: true,
      };
    case "DEPARTMENTS":

      return {
        ...state,
        departments: action.payload,
        changed: true,
      };
    case "INCLUDE_KEYWORDS":
      return {
        ...state,
        include_keywords: action.payload,
        changed: true,
      };
    case "EXCLUDE_KEYWORDS":
      return {
        ...state,
        exclude_keywords: action.payload,
        changed: true,
      };
    case "HIRING":
      return {
        ...state,
        hiring: action.payload,
        changed: true,
      };
    case "INCLUDE_PAST_ROLE":
      return {
        ...state,
        include_past_roles: action.payload,
        changed: true,
      };
    case "MARKET_BASED":
      return {
        ...state,
        role: action.payload.role,
        location: action.payload.location,
        industries: action.payload.industries,
        employees: action.payload.employees,
        adwords_budget: action.payload.adwords_budget,
        technology: action.payload.technology,
        news: action.payload.news,
        estimatedSalary: action.payload.estimated_salary,
        major: action.payload.major,
        college: action.payload.college,
        yearsOfExperience: action.payload.years_of_experience,
        timeInLatestJob: action.payload.time_latest_job,
        include_keywords: action.payload.include_keywords,
        exclude_keywords: action.payload.exclude_keywords,
        hiring: action.payload.hiring,
        skills: action.payload.skills,
        industries2: action.payload.industries2,
        departments: action.payload.departments,

        companyLocation: action.payload.companyLocation,
        personLocation: action.payload.personLocation,
        changed: true,
      };
    case "RESET_STATE":
      return INITIAL_STATE;
    case "CLEAR_MARKETBASED_CHANGED":
      return {
        ...state,
        changed: false,
      };
    default:
      return {
        ...state,
      };
  }
}
