import React, { Fragment, Component } from 'react'
import Functions from '../../../MarkedBasedBox/MarketSearch/FieldsDropdown/Functions'
import inputsPeople from '../../../MarkedBasedBox/MarketSearch/PeopleMarket/inputsPeople'

class PeopleSearch extends Component {
  state = {
    collapse: false,
  }
  
  toggle = () => {
    this.setState({collapse: !this.state.collapse});
  }
  render() {
  const { firstName, lastName, disableName } = this.props
  return(
    <Fragment>
      {/* <div className="based-header__top"> */}
        <h2 className={`adv-lead__title ${this.state.collapse ? "expend" : "collapse"}`}
          onClick={this.toggle}>Person Criteria</h2>
      {/* </div> */}

      {!this.state.collapse &&
      <div className="people">

        <p className="people__label">First name</p>
        <input type="text" name="firstName" placeholder="Search by first name" value={firstName} disabled={disableName} onChange={this.props.onFirstNameChange}/>
        
        <p className="people__label">Last Name</p>
        <input type="text" name="lastName" placeholder="Search by last name" value={lastName}  disabled={disableName} onChange={this.props.onLastNameChange}/>
        
        <div className="people-market peoplePro">
          <div className="people-market__col w-100">
            <p className="people-market__label">{inputsPeople[0].label}</p>
            <Functions
              label={inputsPeople[0].label}
              
              includeKeywords={this.props.includeKeywords}
              excludeKeywords={this.props.excludeKeywords}
              selectedGroups={this.props.selectedGroups}

              placeholder={inputsPeople[0].placeholder}
              inputType={inputsPeople[0].inputType}
              name={inputsPeople[0].name}
              customInput={inputsPeople[0].customInput}
              showExclude={inputsPeople[0].showExclude}
              groups={inputsPeople[0].group}
              haveTags={inputsPeople[0].have_tags}
              technologies={inputsPeople[0].technologies}
              setIncludeTags={this.props.setIncludeTags}
              setExcludeTags={this.props.setExcludeTags}
              setGroups={this.props.setGroups}
              clearFields={this.props.clearFields}
              updateClearFields={this.props.updateClearFields}
            />
          </div>
        </div>
      </div>
      }
    </Fragment>
  )
  }
}

export default PeopleSearch;