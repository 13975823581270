import { ACCOUNT_BASED_ACTIONS, GLOBAL_ACTIONS } from "../constants/constant";

const INITIAL_STATE = {
  
  first_name: null,
  last_name: null,
  role: null,
  include_keywords: null,
  exclude_keywords: null,
  company_domain_tags: null,
  search_past_employment: false,
  changed: false,
};

export default function accountBased(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACCOUNT_BASED_ACTIONS.FORM_SUBMIT:
        return {
          ...state,
          first_name: action.payload.firstName,
          last_name: action.payload.lastName,
          changed: (state.first_name != action.payload.firstName) || (state.last_name != action.payload.lastName),
          linkedin_url_company: action.payload.linkedinUrlCompany,
          // company_domain_tags: action.payload.company,
          // role: action.payload.role,
        };
    case ACCOUNT_BASED_ACTIONS.USER_LIST:
      return {
        ...state,
        company_domain_tags: action.payload.company_domain_tags,
        changed: true,
      };
    case ACCOUNT_BASED_ACTIONS.ROLE:
      if (action.name === "groups") {
        return {
          ...state,
          role: action.payload,
          changed: true,
        };
      } else {
        return {
          ...state,
          role: action.payload,
          changed: true,
        };
      }
      
    case ACCOUNT_BASED_ACTIONS.INCLUDE_KEYWORDS:
      return {
        ...state,
        include_keywords: action.payload,
        changed: true,
      };
    case ACCOUNT_BASED_ACTIONS.EXCLUDE_KEYWORDS:
      return {
        ...state,
        exclude_keywords: action.payload,
        changed: true,
      };
    case ACCOUNT_BASED_ACTIONS.SEARCH_PAST_EMPLOYMENT:
      return {
        ...state,
        search_past_employment: action.payload,
        changed: true,
      };
    case ACCOUNT_BASED_ACTIONS.ALL:
      return {
        ...state,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        linkedin_url_company: action.payload.linkedin_url_company,
        role: action.payload.role,
        include_keywords: action.payload.include_keywords,
        exclude_keywords: action.payload.exclude_keywords,
        company_domain_tags: action.payload.company_domain_tags,
        changed: true,
      };
    case GLOBAL_ACTIONS.RESET_STATE:
      return {
        ...INITIAL_STATE
      };
    case "CLEAR_ACCOUNTBASED_CHANGED":
      return {
        ...state,
        changed: false
      };
    default:
      return {
        ...state
      };
  }
}
