const items = [
  {
    check: false,
    name: 'Added by Fuzebot'
  },
  {
    check: false,
    name: 'Personal Email'
  },
  {
    check: false,
    name: 'Company Email'
  },
  {
    check: false,
    name: 'Single Verified Email'
  },
  {
    check: false,
    name: 'Double Verified Email'
  },
  {
    check: false,
    name: 'Has LinkedIn'
  },
  {
    check: false,
    name: 'Has Phone'
  },
  {
    check: false,
    name: 'Has Mailing Address'
  }
]

export default items