import React, {Component, Fragment} from 'react';
import UserDetails from '../UserDetails';
import BillingDetail from '../BillingDetail';
import PlanDetails from '../PlanDetails';
import BillingScaling from "../../../BillingScaling";


const tabs = [
    {
        icon: 'man.svg',
        hover: 'man-hov.svg',
        name: 'User Details',
        active: true
    },
    {
        icon: 'open-folder.svg',
        hover: 'open-folder-hov.svg',
        name: 'Billing Details',
        active: false
    },
    {
        icon: 'folder.svg',
        hover: 'folder-hov.svg',
        name: 'Plan Details',
        active: false
    },
]

class BillingUsersTabs extends Component {
    state = {
        content: tabs
    }

    changeTab = (i) => {
        const {content} = this.state
        for (let key in content) {
            content[key].active = false
        }
        content[i].active = true
        this.setState({content: content})
        this.props.onTabChange(content[i].name);
    }

    render() {
        const {content} = this.state
        const {billingOverview} = this.props;
        return (
            <Fragment>
                <div className="billing-tabs">                
                    { 
                        
                        content.map((item, index) => {
                            if (billingOverview && billingOverview.plan_name === 'commitment_1022_unlimited') {
                                if  (item.name !== 'Plan Details') {
                                    return   <h4 className={`billing-tabs__name ${item.active ? 'active' : ''}`}
                                    key={index}
                                    onClick={() => this.changeTab(index)}>
                                    <img src={`/assest/billing/${item.active ? item.hover : item.icon}`} alt=""/>
                                    {item.name}
                                    </h4>
                                }                             
                            } else {
                                return   <h4 className={`billing-tabs__name ${item.active ? 'active' : ''}`}
                                key={index}
                                onClick={() => this.changeTab(index)}>
                                <img src={`/assest/billing/${item.active ? item.hover : item.icon}`} alt=""/>
                                {item.name}
                                </h4>
                            }
                        }                           
                        )
                    }
                </div>
                {
                     content[0].active ? <UserDetails billingOverview={this.props.billingOverview} onReceiveUserContent={this.props.onReceiveUserContent}/> :
                     content[1].active ? <BillingDetail/> : 
                     content[2].active ? <BillingScaling billingOverview={this.props.billingOverview} loadBillingOverview={this.props.loadBillingOverview} isUserOnTrial={false} /> :' '         
                }
              
                {/* {
                     content[0].active ? <UserDetails billingOverview={this.props.billingOverview} onReceiveUserContent={this.props.onReceiveUserContent}/> :
                     content[1].active ? <BillingDetail/> : 
                     content[2].active && 
                     (billingOverview && billingOverview.plan_name === 'commitment_1022_unlimited' || billingOverview.plan_name === 'monthly_1019_2500') ? 
                     <BillingScaling billingOverview={this.props.billingOverview} loadBillingOverview={this.props.loadBillingOverview} isUserOnTrial={false} /> : 
                     <PlanDetails billingOverview={this.props.billingOverview} loadBillingOverview={this.props.loadBillingOverview} />  
                }
                 */}
                   
            </Fragment>
        )
    }
}

export default BillingUsersTabs