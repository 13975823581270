import hiring from '../files/hiring_f.json';

export const hiring_groups = hiring.map(item => {
  return {
    category: item,
    label: item,
    group: item,
    include_tags: [],
    exclude_tags: [],
  }
})

// export const hiring_groups = [
//   {
//     category: "Administration",
//     label: "Administration",
//     group: "Administration",
//     include_tags: ["administration"],
//     exclude_tags: []
//   },
//   {
//     category: "Chairmen",
//     label: "Chairmen",
//     group: "Chairmen",
//     include_tags: ["chairmen"],
//     exclude_tags: []
//   },
//   {
//     category: "Health Care",
//     label: "Health Care",
//     group: "Health Care",
//     include_tags: ["healthcare_services"],
//     exclude_tags: []
//   },
//   {
//     category: "Hospitality",
//     label: "Hospitality",
//     group: "Hospitality",
//     include_tags: ["hospitality"],
//     exclude_tags: []
//   },
//   {
//     category: "Engineering",
//     label: "Engineering",
//     group: "Engineering",
//     include_tags: ["engineering"],
//     exclude_tags: []
//   },
//   {
//     category: "Education",
//     label: "Education",
//     group: "Education",
//     include_tags: ["education"],
//     exclude_tags: []
//   },
//   {
//     category: "Maintenance",
//     label: "Maintenance",
//     group: "Maintenance",
//     include_tags: ["maintenance"],
//     exclude_tags: []
//   },
//   {
//     category: "Finance",
//     label: "Finance",
//     group: "Finance",
//     include_tags: ["finance"],
//     exclude_tags: []
//   },
//   {
//     category: "Information Technology",
//     label: "Information Technology",
//     group: "Information Technology",
//     include_tags: ["information_technology"],
//     exclude_tags: []
//   },
//   {
//     category: "Management",
//     label: "Management",
//     group: "Management",
//     include_tags: ["management"],
//     exclude_tags: []
//   },
//   {
//     category: "Operations",
//     label: "Operations",
//     group: "Operations",
//     include_tags: ["operations"],
//     exclude_tags: []
//   },
//   {
//     category: "Partnerships",
//     label: "Partnerships",
//     group: "Partnerships",
//     include_tags: ["partnerships"],
//     exclude_tags: []
//   },
//   {
//     category: "Human Resources",
//     label: "Human Resources",
//     group: "Human Resources",
//     include_tags: ["human_resources"],
//     exclude_tags: []
//   },
//   {
//     category: "Publishing",
//     label: "Publishing",
//     group: "Publishing",
//     include_tags: ["publishing"],
//     exclude_tags: []
//   },
//   {
//     category: "Purchasing",
//     label: "Purchasing",
//     group: "Purchasing",
//     include_tags: ["purchasing"],
//     exclude_tags: []
//   },
//   {
//     category: "Sales",
//     label: "Sales",
//     group: "Sales",
//     include_tags: ["sales"],
//     exclude_tags: []
//   },
//   {
//     category: "Marketing",
//     label: "Marketing",
//     group: "Marketing",
//     include_tags: ["marketing"],
//     exclude_tags: []
//   },
//   {
//     category: "Transportation",
//     label: "Transportation",
//     group: "Transportation",
//     include_tags: ["transportation"],
//     exclude_tags: []
//   },
//   {
//     category: "Directors",
//     label: "Directors",
//     group: "Directors",
//     include_tags: ["directors"],
//     exclude_tags: []
//   },
//   {
//     category: "Design",
//     label: "Design",
//     group: "Design",
//     include_tags: ["design"],
//     exclude_tags: []
//   },
//   {
//     category: "Software Development",
//     label: "Software Development",
//     group: "Software Development",
//     include_tags: ["software_development"],
//     exclude_tags: []
//   },
//   {
//     category: "Technology",
//     label: "Technology",
//     group: "Technology",
//     include_tags: ["general_technology"],
//     exclude_tags: []
//   },
//   {
//     category: "Business Analysis",
//     label: "Business Analysis",
//     group: "Business Analysis",
//     include_tags: ["business_analysis"],
//     exclude_tags: []
//   },
//   {
//     category: "Support",
//     label: "Support",
//     group: "Support",
//     include_tags: ["support"],
//     exclude_tags: []
//   },
//   {
//     category: "Data Analysis",
//     label: "Data Analysis",
//     group: "Data Analysis",
//     include_tags: ["data_analysis"],
//     exclude_tags: []
//   },
//   {
//     category: "Consulting",
//     label: "Consulting",
//     group: "Consulting",
//     include_tags: ["consulting"],
//     exclude_tags: []
//   },
//   {
//     category: "Internship",
//     label: "Internship",
//     group: "Internship",
//     include_tags: ["internship"],
//     exclude_tags: []
//   },
//   {
//     category: "Military",
//     label: "Military",
//     group: "Military",
//     include_tags: ["military_and_protective_services"],
//     exclude_tags: []
//   },
//   {
//     category: "Product Management",
//     label: "Product Management",
//     group: "Product Management",
//     include_tags: ["product_management"],
//     exclude_tags: []
//   },
//   {
//     category: "Quality Assurance",
//     label: "Quality Assurance",
//     group: "Quality Assurance",
//     include_tags: ["quality_assurance"],
//     exclude_tags: []
//   },
//   {
//     category: "Real Estate",
//     label: "Real Estate",
//     group: "Real Estate",
//     include_tags: ["real_estate"],
//     exclude_tags: []
//   },
//   {
//     category: "Research",
//     label: "Research",
//     group: "Research",
//     include_tags: ["research"],
//     exclude_tags: []
//   }
// ];
