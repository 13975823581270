import React, {Component, Fragment} from 'react';
import {Fab} from "@material-ui/core";
import ReactTooltip from 'react-tooltip';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router";
import {setBillingOverview,} from '../../actions/billing';
import { LOCAL_STORAGE_KEYS } from '../../constants/constant';
import {updateSubscription} from "../../services/billing";
import {toast} from 'react-toastify';

import './style.scss';


class BillingScaling extends Component {

    state = {
        active: false,
        addNewList: false,
        showError: false,
        errorMessage: '',
        selectedList: null,
        listName: this.props.selectedList ? this.props.selectedList.name : '',
        lists: [],
    
        pageNumber: 1,
        recordsPerPage: 25,
        totalRecords: 0,
        hasMoreItems: true,
        searchQuery: null,
        loading: false,
    
        consent:''
      };

    componentDidMount() {
       
    }
    
    goToTrialPlanCheckout =(type)=> {

        let userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT)); 
        if (type==='commitment_1022_unlimited') {
          const STRIPE_PLAN_URL = process.env.REACT_APP_STRIPE_UNLIMITED_PLAN_URL + '?prefilled_email=' + userAccount.email;
          window.open(STRIPE_PLAN_URL, '_blank');
        } else if  (type==='monthly_1019_500') {
          const STRIPE_PLAN_URL = process.env.REACT_APP_STRIPE_SCALING_PLAN_URL + '?prefilled_email=' + userAccount.email;
          window.open(STRIPE_PLAN_URL, '_blank');
    
        }
      }

      upgradePlan = async (plan_id) => {
        const toastId = toast("Updating Subscription...", {hideProgressBar: true, autoClose: 10000});
        try {
          const response = await updateSubscription(plan_id, '');
          toast.update(toastId, {
              render: "Subscription updated successfully",
              className: "toast-success-div",
              type: toast.TYPE.SUCCESS,
              hideProgressBar: true,
              autoClose: 2000
          });
      } catch (error) {
          toast.update(toastId, {
              className: "toast-error-div",
              render: "Unable to update subscription",
              type: toast.TYPE.ERROR,
              autoClose: 3000
          });
      }

        this.props.loadBillingOverview();
        this.setState({consent: plan_id});
      }
    
   
      toggleConsent =(type)=> {
        this.setState({consent: type});
      }
      
    render() {
      const {isUserOnTrial, billingOverview} = this.props;
            return (
            <Fragment>
                <div className="billing-trial-panel" style={{"padding":"2rem"}}>
                <div className="pricing-row"  style={{"width":"75rem"}}>
        <div className={`pricing-col starter-plan ${ this.state.consent === '' || this.state.consent === 'monthly_1019_500' ? 'selected' : ''}`} onClick={() => {}}>
          <h3 className="pricing-title">Scaling</h3>
          
          <div className="pricing-for bg-limeblue" style={{padding:"10px"}}>For individuals looking to start prospecting for new customers.</div>
          <div className="pricing-price generate"  style={{padding:`1rem`}}>
          <p className="price_info">$147/mo</p>
            <div className="monthly">500 Leads Credits/<span className="pricing-sub">{'mo'}<sup>*</sup>
              <a href="#" data-for="starterToolTip" data-tip data-place="right"><span className="fa fa-question-circle-o tooltips" aria-hidden="true"></span></a></span>

              <ReactTooltip id="starterToolTip">
                <p className="starter-tooltip">When you add a lead to a list, this uses up one lead credit. You are then given contact info, social data, and more on that lead. You can always buy more lead credits at any time.</p>
              </ReactTooltip>            
            </div>   
            <p className="sub_price_info" style={{padding:`0.5rem`}}>{``}</p>
          </div>
          
          <div className="pricing-items">
            <ul>
              <li><i className="fa fa-list"></i> Unlimited Lists</li>
              <li><i className="fa fa-briefcase"></i> Account Based Search Filters</li>
              <li><i className="fa fa-briefcase"></i> Market Based Search Filters</li>
              <li><i className="fa fa-cogs"></i> Fuzebot List Build Automation</li>
              <li><i className="fa fa-ban"></i> Ignore Lists</li>
              <li><i className="fa fa-download"></i> Export to CSV</li>
              <li><i className="fa fa-clone"></i> De-Duplication</li>
              <li><i className="fa fa-server"></i> Integrations</li>
              <li><i className="fa fa-briefcase"></i>  LinkedIn URL Lookups</li>             
            </ul>
          </div>
         {isUserOnTrial && (<div className="pricing-for bg-limegreen"  style={{padding:`10px`}}>
                    <label className="title">Agree To Terms:</label>
                    <div className="terms_agree__check" style={{marginTop: `15px`, paddingLeft:`1rem`}}   onClick={()=>this.toggleConsent('monthly_1019_500')}>
                        <div className={`check ${this.state.consent === 'monthly_1019_500' ? 'active' : ''}`} style={{padding: `10px`, border: `2px solid rgb(236, 151, 29)`, borderRadius: `12px`}}></div>
                        <p style={{marginBottom: `20px`, marginTop: `-15px`}}>By purchasing, you agree to the <u><a href="https://leadFuze.com/terms-use" target="_blank">terms
                            of service</a></u>.</p>
                    </div>
                    <Fab
                        disabled={this.state.consent !== 'monthly_1019_500'}
                        variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="add"
                        onClick={() => this.goToTrialPlanCheckout('monthly_1019_500')}>
                        Subscribe to Scaling
                    </Fab>
            </div> )}

          {(!isUserOnTrial && billingOverview && billingOverview.plan_name !== "monthly_1019_500" && billingOverview.plan_name !== "commitment_1022_unlimited")
         && (<div className="pricing-for bg-limegreen"  style={{padding:`10px`}}>
                    <label className="title">Agree To Terms:</label>
                    <div className="terms_agree__check" style={{marginTop: `15px`, paddingLeft:`1rem`}}   onClick={()=>this.toggleConsent('monthly_1019_500')}>
                        <div className={`check ${this.state.consent === 'monthly_1019_500' ? 'active' : ''}`} style={{padding: `10px`, border: `2px solid rgb(236, 151, 29)`, borderRadius: `12px`}}></div>
                        <p style={{marginBottom: `20px`, marginTop: `-15px`}}>By purchasing, you agree to the <u><a href="https://leadFuze.com/terms-use" target="_blank">terms
                            of service</a></u>.</p>
                    </div>
                    <Fab
                        disabled={this.state.consent !== 'monthly_1019_500'}
                        variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="add"
                        onClick={() => this.upgradePlan('monthly_1019_500')}>
                        Subscribe to Scaling
                    </Fab>
            </div> )}

        </div>

        <div className={`pricing-col scaling-plan ${this.state.consent === 'commitment_1022_unlimited'  ? 'selected' : ''}`} onClick={() => {}}>
          <h3 className="pricing-title">Unlimited</h3>
          <div className="pricing-for bg-limegreen"  style={{padding:"10px"}}>For individuals or teams looking to scale their lead generation efforts.</div>
          <div className="pricing-price automate" style={{padding:`1rem`}}>
          <p className="price_info">$397/mo</p>
            <div className="monthly">
              <span className="cut-price"></span>Unlimited  Lead Credits/<span className="pricing-sub">{'yr'} <sup>*</sup>
              <a href="#" data-for="scalingToolTip" data-tip data-place="right"><span className="fa fa-question-circle-o tooltips" aria-hidden="true"></span></a></span>
              
              <ReactTooltip id="scalingToolTip">
                <p className="scaling-tooltip">When you add a lead to a list, this uses up one lead credit. You are then given contact info, social data, and more on that lead. You can always buy more lead credits at any time.</p>
              </ReactTooltip>
            </div>
            <p className="sub_price_info">*Annual commitment. Billed monthly</p>
          </div>        
          <div className="pricing-items">
            <ul>
              <li><i className="fa fa-list"></i> Unlimited Lists</li>
              <li><i className="fa fa-briefcase"></i> Account Based Search Filters</li>
              <li><i className="fa fa-briefcase"></i> Market Based Search Filters</li>
              <li><i className="fa fa-cogs"></i> Fuzebot List Build Automation</li>
              <li><i className="fa fa-ban"></i> Ignore Lists</li>
              <li><i className="fa fa-download"></i> Export to CSV</li>
              <li><i className="fa fa-clone"></i> De-Duplication</li>
              <li><i className="fa fa-server"></i> Integrations</li>
              <li><i className="fa fa-briefcase"></i>  LinkedIn URL Lookups</li>
            </ul>
          </div>
          <div className="pricing-for bg-limegreen"  style={{padding:`10px`}}>
                    <label className="title">Agree To Terms:</label>
                    <div className="terms_agree__check" style={{marginTop: `15px`, paddingLeft:`1rem`}}   onClick={()=>this.toggleConsent('commitment_1022_unlimited')}>
                        <div className={`check ${this.state.consent === 'commitment_1022_unlimited' ? 'active' : ''}`} style={{padding: `10px`, border: `2px solid rgb(236, 151, 29)`, borderRadius: `12px`}}></div>
                        <p style={{marginBottom: `20px`, marginTop: `-20px`}}>By purchasing, you agree to the <u><a href="https://leadFuze.com/terms-use" target="_blank">terms
                            of service</a></u>.</p>
                    </div>
                    
                    {isUserOnTrial &&
                      <Fab
                      disabled={this.state.consent !== 'commitment_1022_unlimited'}
                      variant="extended"
                      size="medium"
                      color="primary"
                      aria-label="add"
                      onClick={() => this.goToTrialPlanCheckout('commitment_1022_unlimited')}>
                      Subscribe to Unlimited
                    </Fab>
                    }
                    {!isUserOnTrial &&
                      <Fab
                      disabled={this.state.consent !== 'commitment_1022_unlimited'}
                      variant="extended"
                      size="medium"
                      color="primary"
                      aria-label="add"
                      onClick={() => this.upgradePlan('commitment_1022_unlimited')}>
                      Subscribe to Unlimited
                    </Fab>}
            </div>
				</div>
      </div>
                </div>
            </Fragment>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setBillingOverview
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        user: state.user,
        billing: state.billing,
        userProfileInfo: state.userProfileInfo
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BillingScaling));