import React, { Component } from 'react';
import Calendar from 'react-calendar';
// import Calendar from 'react-calendar/dist/entry.nostyle';
import '../style.scss'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { createAndRevoke, createDateVal, revokeDateVal } from '../../../actions/apikeysfilters';
import { getApiKeysList } from '../../../actions/api_keys';

var moment = require('moment');

class ApiKeysFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            createVal: "",
            revokeVal: "",
            createShow: '',
            revokeShow: '',
            createDate: new Date(),
            revokeDate: new Date()
        }
    }

    apply = async () => {
        const { createVal, revokeVal } = this.state
        const { getApiKeysList, createDateVal, revokeDateVal, ApiKeys, createAndRevoke } = this.props
        let created = moment(createVal).format("YYYY-MM-DD")
        let revoked = moment(revokeVal).format("YYYY-MM-DD")
        const regx = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
        let create_date = created.match(regx)
        let revoke_date = revoked.match(regx)
        if (revoke_date && create_date) {
            const values = { createVal: created, revokeVal: revoked }
            await createAndRevoke(values)
            await getApiKeysList('')
            ApiKeys("", true);
        }
        else if (create_date) {
            await createDateVal(created)
            await getApiKeysList('')
            ApiKeys("", true);

        }
        else if (revoke_date) {
            await revokeDateVal(revoked)
            await getApiKeysList('')
            ApiKeys("", true);
        } else {
            const values = { createVal: "", revokeVal: "" }
            await createAndRevoke(values)
            await getApiKeysList('')
            ApiKeys("", true);
        }

    }
    clearField = async () => {
        this.setState(
            { createVal: "", revokeVal: "", createShow: '', revokeShow: '' },
            this.apply
        )
    }
    onChange = date => {
        let date1 = moment(date).format("YYYY-MM-DD")
        let date2 = moment(date).format("MM-DD-YYYY")
        this.setState({ createVal: date1, createShow: date2, createDate: date })
        // NOTE: pushing close to the next event loop tick because
        // onChange triggers focus for calendar input element, which opens
        // the calendar
        setTimeout(this.props.closeDateCreatedCalendar, 0)
    }
    onRevokeChange = date => {
        let date1 = moment(date).format("YYYY-MM-DD")
        let date2 = moment(date).format("MM-DD-YYYY")
        this.setState({ revokeVal: date1, revokeShow: date2, revokeDate: date })
        // NOTE: pushing close to the next event loop tick because
        // onChange triggers focus for calendar input element, which opens
        // the calendar
        setTimeout(this.props.closeDateDeactivatedCalendar, 0)
    }

    render() {
        const { revokeShow, createShow } = this.state
        const {
            active,
            active1,
            calendarOpen,
        } = this.props
        return (
            <div className="coupon-filter"  >
                <p className="coupon-filter__title">Filters</p>
                <label className="coupon-cont__label">
                    <p>Key date created</p>
                    <input
                        type="text"
                        id="calendarClose"
                        value={createShow}
                        placeholder="MM-DD-YYYY"
                        className="date-field"
                        onFocus={() => {
                            if (!active1) {
                                calendarOpen("active1")
                            }
                        }}
                    />
                    <img src="/assest/cal.svg" alt="" />
                    {active1 &&
                        <div className="cal-body">
                            <Calendar
                                onChange={this.onChange}
                                value={this.state.createDate}
                            />
                        </div>
                    }
                </label>
                <label className="coupon-cont__label" >
                    <p>key date Deactivation </p>
                    <input
                        type="text"
                        id="calendarClose"
                        value={revokeShow}
                        placeholder="MM-DD-YYYY"
                        className="date-field"
                        onFocus={() => calendarOpen("active")}
                    />
                    <img src="/assest/cal.svg" alt="" />
                    {active &&
                        <div className="cal-body">
                            <Calendar
                                onChange={this.onRevokeChange}
                                value={this.state.revokeDate}
                            />
                        </div>
                    }
                </label>
                <div className="btn-align">
                    {/* <Button onClick={this.apply}><div className="sidebarText__link">Apply</div> </Button>
                    <Button onClick={this.clearField}><div className="adv-lead__link">Clear all</div> </Button> */}
                    <div className="sidebarText__link" onClick={this.apply}>Apply</div>
                    <div className="adv-lead__link" onClick={this.clearField}><p>Clear all</p></div>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createAndRevoke,
        createDateVal,
        revokeDateVal,
        getApiKeysList
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(ApiKeysFilter)
