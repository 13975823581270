import React, { Component } from 'react'
import './style.scss'


class IntegrationsRight extends Component {



  render() {
    const { changeCheck, unactiveChecks, checks, integrationSearchHandler } = this.props
    return (
      <section className="int-panel">
        <h4 className="int-panel__title">Filters</h4>
        <h5 className="right-filters__title rm_margin" style={{marginBottom: 10, fontSize: 11}}>Name:</h5>
        <div className="int-panel__box">
          <input type="text" style={{ padding: '9px 15px 9px 10px; !important' }}
                    placeholder="Enter name"
                    className="name-search"
                    onChange={integrationSearchHandler}/>
        </div>
        <h5 className="right-filters__title rm_margin" style={{marginBottom: 10, fontSize: 11}}>Tags:</h5>
        <div className="int-panel__box">
          {
            checks.map((item, index) =>
              <div key={index} className="right-filters__check" onClick={() => changeCheck(index)}>
                <div className={`check ${item.checked ? 'active' : ''}`}></div>
                <p>{item.title}</p>
              </div>
            )
          }
        </div>
        <p className="int-panel__clear" onClick={() => unactiveChecks()}>Clear all</p>
      </section>
    )
  }
}

export default IntegrationsRight
