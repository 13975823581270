import React, { Component } from "react";
import CategoryLine from "../CategoryLine";
import { LocationType, LocationDropdownType } from "../../../../../constants/constant";
import { getCompanyLocations, getPersonLocations } from "../../../../../services/locations";

class AddLocationGroup extends Component {

    state = {
        value: '',
        locations: [],
        excludeOption:  false,
    }
    
    componentWillMount(){
        if (this.props.locationOptions && this.props.locationOptions.length) {
            this.setState({locations: this.props.locationOptions});
        }
    }

    componentWillUnmount() {
        this.props.setLocationOptions(this.state.locations);
    }

    async componentWillUpdate(props, state) {
        if (props.value !== state.value) {
            this.setState({value: props.value});
            if (props.value && props.value.length> 1) {
                try {
                    let locationResp = null;
                    if (this.props.type === LocationDropdownType.Company) {
                        locationResp = await getCompanyLocations(props.value);
                    } else {
                        locationResp = await getPersonLocations(props.value);
                    }
                    const allLocations = locationResp.data.data;
                    if (allLocations) {
                        this.setState({locations: allLocations});
                    } else {
                        this.setState({locations: []});
                    }
                }
                catch(e) {
                }
            } else{
                this.setState({locations: []});
            }    
        }
    }


    

    getHeadingAndOptions(heading, locTypeArr, split) {
        let locationDiv = '';
        if (locTypeArr.length) {
            const  locTypeEntries = 
            locTypeArr.map(loc => {
                return (<CategoryLine
                    category={{...loc , excludeGroup: this.props.excludeOption}}
                    tags={[]}
                    key={loc.keyword}
                    label={split? loc.keyword.replace("|", ", ") : loc.keyword}
                    addExcludeTag={this.props.addExcludeTag}
                    name={this.props.name}
                    excludeTags={[]}
                    addGroupSelect={this.props.addGroupSelect}
                    addTagInSelect={this.props.addTagInSelect}
                    haveTags={false}
                  />);
            });
            locationDiv = (
                <div>
                    <p className="dropdown-in__title">{heading}</p>
                    <div >
                        {locTypeEntries}
                    </div>        
                </div>
            );
        }
        return locationDiv;
    }

  render() {
    const {
      finishRoleGroups,
      addGroupSelect,
      addTagInSelect,
      value,
      type,
      addExcludeTag,
      name,
      label,
      haveTags,
      customInput,
    } = this.props;

    let heading = '';
    let headingText = label;

    // if (value && !customInput && !finishRoleGroups.length) {
    // headingText = 'No matches found'
    // }

    if (!this.state.locations.length && !this.props.value) {
        heading = <p className="dropdown-in__title">No matches found</p>
    }
    const cities = this.state.locations.filter(loc => loc.type === LocationType.City);
    const states = this.state.locations.filter(loc => loc.type === LocationType.State);
    // const metros = this.state.locations.filter(loc => loc.type === LocationType.Metro);
    // const stateRemovedMetros = metros.map(metro => {
    //     let d = metro.keyword.split("|");
    //     return {type: metro.type, keyword: d[0]};
    // })
    
    const groups = this.state.locations.filter(loc => loc.type === LocationType.Group);
    const countries = this.state.locations.filter(loc => loc.type === LocationType.Country);
    const continents = this.state.locations.filter(loc => loc.type === LocationType.Continent);
    const citiesDiv = this.getHeadingAndOptions('Cities', cities, true);
    const statesDiv = this.getHeadingAndOptions('States', states, true);
    // const metrosDiv = this.getHeadingAndOptions('Metros', stateRemovedMetros, true);
    const regionsDiv = this.getHeadingAndOptions('Regions', groups, false);
    const coutriesDiv = this.getHeadingAndOptions('Countries', countries, false);
    const continentsDiv = this.getHeadingAndOptions('Continents', continents, false);
    let excludeSwtich = [];

    if (this.props.showExclude) {
        excludeSwtich = [
          <div className="dropdown-in__exclude">
            <p className=""></p>
            <div className="right-filters__switch"
            onClick={this.props.toggleExcludeOption}
            >
              <p className="switch__name">Exclude Mode {this.props.excludeOption ? "[ON]" : "[OFF]"} </p>
              <div className={`switch ${this.props.excludeOption ? "active" : ""}`}>
                <span></span>
              </div>
            </div>
          </div>
        ];
        
      }

    const inputValueParagraph = (<p className="dropdown-in-ex__inp" onClick={() => this.props.addGroupSelect({type: LocationType.Keyword, keyword: this.props.value, excludeGroup: this.props.excludeOption})} >{this.props.value}</p>);
    const customIncludeOrExclude = (
          <div className="dropdown-in-ex">
            {inputValueParagraph}
          </div>
        );
        const customInputHeading = (
            <p className="dropdown-in__title">
            Custom input
            </p>
          );
  
        const customIncludeOrExcludeParentDiv = (
            
          <div className={`dropdown-in__custom ${this.props.value ? "active" : ""}`}>
           
              {customInputHeading}
              {customIncludeOrExclude}
            </div>
        );

    return (
      <div className="dropdown-in animation-slideDown ">
          <div className="dropdown-in__groups scroll-dropdown">
          {excludeSwtich}    
          {heading}
       

          {/* metrosDiv */}
          {citiesDiv}
          {statesDiv}
          {coutriesDiv}
          {regionsDiv}
          {continentsDiv}
          {customIncludeOrExcludeParentDiv}
          

          </div>
      </div>
    );
  }
}

export default AddLocationGroup;
