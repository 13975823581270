import React, { Component } from 'react';
import { updateUserSettings } from "../../../services/users";
import { EmitterService } from "../../../services/emitterService";
import items from './items';
import $ from 'jquery'

import './style.scss'

class RightPanelBanner extends Component{
  state = {
    active: ''
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    let demo = user.settings.demo;
    let flag = demo[this.props.type];
    if(flag) {
      this.setState({active: 'hidden'});
    }

    this.loadVideos();
  }

  loadVideos = () => {
    let video_id = this.props.video_id;
    const script1 = document.createElement("script");

    script1.src = "https://fast.wistia.com/embed/medias/" + video_id + ".jsonp";
    script1.async = true;

    const script2 = document.createElement("script");

    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);

    window._wq = window._wq || [];
    
    window._wq.push({
        id: video_id, onReady: function (video) {
        }
    });

    $("#play").click(() => {
        window._wq.push({
            id: video_id, onReady: function (video) {
                video.play();
            }
        });
    })
  }

  bannerHidden = async () =>{
    const user = JSON.parse(localStorage.getItem("user"));
    let settings = user.settings;

    settings.demo[this.props.type] = true;
    await updateUserSettings(settings);

    EmitterService.emit("loadLeadsRemaining");

    this.setState({active: 'hidden'});
  }

  render(){
    const { active } = this.state;
    const { title } = this.props;

    return(
      <div className={`right-banner ${active}`}>
        <div className="right-banner__close" onClick={this.bannerHidden}></div>
        <div className="right-banner__content">
          <h4 className="right-banner__title">{title ? title : 'Demo'}</h4>
          <p className="right-banner__video" id="play">Watch Video</p>
        </div>
        <div className="right-banner__img">
          <img src="/assest/accountBased/igni.svg" alt="" />
        </div>
        <div className="wistia_responsive_padding" style={{padding:'56.25% 0 0 0', position: 'relative'}}>
            <div className="wistia_responsive_wrapper" style={{height: '100%', left:0, position: 'absolute', top: 0, width:'100%'}}>
                <span className={`wistia_embed wistia_async_${this.props.video_id} popover=true popoverAnimateThumbnail=true videoFoam=true autoPlay=false`} 
                style={{display:'inline-block', height:'100%', position: 'relative', width: '100%'}}>&nbsp;</span>
                </div>
        </div>
      </div>
    )
  }
}

export default RightPanelBanner