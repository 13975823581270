import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {Fab} from "@material-ui/core";
import ReactTooltip from 'react-tooltip';
import AddToListName from './AddToListName';
import './style.scss';
import { toast } from 'react-toastify';
import { getLeadLists, createLeadList } from '../../../../services/searchResult';
import { LOCAL_STORAGE_KEYS } from '../../../../constants/constant';
const queryString = require('query-string');

class AddToList extends Component {
  state = {
    active: false,
    addNewList: false,
    showError: false,
    errorMessage: '',
    selectedList: null,
    listName: this.props.selectedList ? this.props.selectedList.name : '',
    lists: [],

    pageNumber: 1,
    recordsPerPage: 25,
    totalRecords: 0,
    hasMoreItems: true,
    searchQuery: null,
    loading: false,

    consentUnlimited: false,
    consentScaling: false,
  };

  loadLists = async () => {
    if(!this.state.loading && this.state.hasMoreItems) {
      this.setState({loading: true});
      const res = await getLeadLists(this.state.pageNumber, this.state.recordsPerPage, this.state.searchQuery);
      const lists = this.state.lists;
      
      // TODO: handle it properly
      if(res.data.data.total_number_of_lists === lists.length) {
        this.setState({hasMoreItems: false});
        return;
      }

      lists.push(...res.data.data.lists);
      let hasMore = this.state.hasMoreItems;

      if(res.data.data.total_number_of_lists === lists.length) {
        hasMore = false;
      }

      this.setState({
        lists: lists, 
        totalRecords: res.data.data.total_number_of_lists, 
        pageNumber: this.state.pageNumber + 1,
        hasMoreItems: hasMore,
        loading: false
      });
    }
  }

  openListNameDropDown = () => {
    if (!this.state.addNewList) {
      this.setState({active: !this.state.active});
    }
  };

  updateListName = (e) => {
    this.setState({listName: e.target.value});
  }

  selectList = (list) => {   
    this.setState({selectedList: list, active: false, listName: list.name});
    this.props.selectList(list);
  }

  showCreateNewListField = () => {
    this.setState({
      addNewList: true, 
      listName: '', 
      showError: false, 
      errorMessage: ''
    });
  }

  addToList = async () => {
    this.setState({showError: false, errorMessage: '', active: false});

    if (this.state.addNewList) {
      
      if (this.state.listName) {
        await this._createLeadList();
      } else {
        this.setState({showError: true, errorMessage: 'Field required'});
        return;
      }

    } else {
      if(!this.props.selectedList) {
        this.setState({showError: true, errorMessage: 'Select a list'});
        return;
      }
    }

    if(this.props.selectedList)
      this.props.addLeadsToList(this.props.selectedList);
  }

  _createLeadList = async () => {
    const toastId = toast('Creating Lead List', {
      hideProgressBar: true,
      autoClose: 10000,
    });

    try {
      const res = await createLeadList(this.state.listName);
      res.data.data._id = res.data.data.id;
      this.props.selectList(res.data.data);
      //this.setState({selectedList: res.data.data});
      
      toast.update(toastId, {
        render: 'Lead List Created',
        className: "toast-success-div",
        type: toast.TYPE.SUCCESS,
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
    catch(err) {
      let errormsg = '';
      if (err.response.data.errCode === 30600) {
        errormsg = 'List name can not be blank'
      } else if (err.response.data.errCode === 30700) {
          errormsg = 'List name already exists'
      } else {
          errormsg = 'Permission Denied'
      }
    
      toast.update(toastId, {
        render: errormsg,
        className: "toast-error-div",
        type: toast.TYPE.ERROR,
        hideProgressBar: true,
        autoClose: 2000,
      });

      this.props.closePopup();
    
    }
  }

  goBack = () => {
    this.setState({addNewList: false});
  }

  goToTrialPlanCheckout = (type) => {

    let userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT)); 
    if (type==='commitment_1022_unlimited') {
      const STRIPE_PLAN_URL = process.env.REACT_APP_STRIPE_UNLIMITED_PLAN_URL + '?prefilled_email=' + userAccount.email;
      window.open(STRIPE_PLAN_URL, '_blank');
    } else if  (type==='monthly_1019_500') {
      const STRIPE_PLAN_URL = process.env.REACT_APP_STRIPE_SCALING_PLAN_URL + '?prefilled_email=' + userAccount.email;
      window.open(STRIPE_PLAN_URL, '_blank');

    }
  }

  onClose = async () => {
    const GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL;

    let response = await axios({
    method: 'get',
    baseURL: GATEWAY_URL,
    timeout: 900000,
    url: `/core/info`,
    headers: {
    Authorization: 'Bearer ' + localStorage.getItem("token") //the token is a variable which holds the token
    }
    });
    const user = response.data.data;
    const account = user.accounts[0];
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('userAccount', JSON.stringify(account))
    this.props.closePopup();
  }
  
  toggleConsentScaling = () => {
    this.setState({consentScaling: !this.state.consentScaling});
  }

  toggleConsentUnlimited = () => {
    this.setState({consentUnlimited: !this.state.consentUnlimited});
  }

  render() {
    let user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
    const qParams = queryString.parse(window.location.search);
    const listId = qParams.list_id;

  
    let userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT));  
    if (userAccount && userAccount.status.indexOf("active_trial") > -1) {
      return (
        <div className={`add-list ${this.props.popup ? 'open' : ''}`} style={{paddingTop: this.props.paddingTop}}>
          <div className="add-list__content" style={{"max-width": "980px"}}>
          <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={this.onClose} />
          <div className="pricing-row">
        <div className={`pricing-col starter-plan ${this.state.consentScaling ? 'selected' : ''}`} onClick={() => {}}>
          <h3 className="pricing-title">Scaling</h3>
          
          <div className="pricing-for bg-limeblue" style={{padding:"10px"}}>For individuals looking to start prospecting for new customers.</div>
          
          <div className="pricing-price generate"   style={{padding:`1rem`}}>
          <p className="price_info">$147/mo</p>
            <div className="monthly">500 Leads Credits/<span className="pricing-sub">{'mo'}<sup>*</sup>
              <a href="#" data-for="starterToolTip" data-tip data-place="right"><span className="fa fa-question-circle-o tooltips" aria-hidden="true"></span></a></span>

              <ReactTooltip id="starterToolTip">
                <p className="starter-tooltip">When you add a lead to a list, this uses up one lead credit. You are then given contact info, social data, and more on that lead. You can always buy more lead credits at any time.</p>
              </ReactTooltip>            
            </div>   
            <p className="sub_price_info" style={{padding:`0.5rem`}}>{``}</p>         
          </div>
          
          <div className="pricing-items">
            <ul>
              <li><i className="fa fa-list"></i> Unlimited Lists</li>
              <li><i className="fa fa-briefcase"></i> Account Based Search Filters</li>
              <li><i className="fa fa-briefcase"></i> Market Based Search Filters</li>
              <li><i className="fa fa-cogs"></i> Fuzebot List Build Automation</li>
              <li><i className="fa fa-ban"></i> Ignore Lists</li>
              <li><i className="fa fa-download"></i> Export to CSV</li>
              <li><i className="fa fa-clone"></i> De-Duplication</li>
              <li><i className="fa fa-server"></i> Integrations</li>
              <li><i className="fa fa-briefcase"></i>  LinkedIn URL Lookups</li>
            </ul>
          </div>
          <div className="pricing-for bg-limegreen"  style={{padding:`10px`}}>
                    <label className="title">Agree To Terms:</label>
                    <div className="terms_agree__check" style={{marginTop: `15px`, paddingLeft:`0.8rem`}}  onClick={this.toggleConsentScaling}>
                        <div className={`check ${this.state.consentScaling ? 'active' : ''}`} style={{padding: `6px`, border: `2px solid rgb(236, 151, 29)`, borderRadius: `12px`}}></div>
                        <p style={{marginBottom: `20px`, marginTop: `-20px`}}>By purchasing, you agree to the <u><a href="https://leadFuze.com/terms-use" target="_blank">terms
                            of service</a></u>.</p>
                    </div>
                    <Fab
                        disabled={!this.state.consentScaling}
                        variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="add"
                        onClick={() => this.goToTrialPlanCheckout('monthly_1019_500')}>
                        Subscribe to Scaling 
                    </Fab>
             </div>
        </div>

        <div className={`pricing-col scaling-plan ${this.state.consentUnlimited  ? 'selected' : ''}`} onClick={() => {}}>
          <h3 className="pricing-title">Unlimited</h3>
          <div className="pricing-for bg-limegreen"  style={{padding:"10px"}}>For individuals or teams looking to scale their lead generation efforts.</div>
          <div className="pricing-price automate"  style={{padding:`1rem`}}>
          <p className="price_info">$397/mo</p>
            <div className="monthly">
              <span className="cut-price"></span>Unlimited  Lead Credits/<span className="pricing-sub">{'yr'}<sup>*</sup>
              <a href="#" data-for="scalingToolTip" data-tip data-place="right"><span className="fa fa-question-circle-o tooltips" aria-hidden="true"></span></a></span>
              
              <ReactTooltip id="scalingToolTip">
                <p className="scaling-tooltip">When you add a lead to a list, this uses up one lead credit. You are then given contact info, social data, and more on that lead. You can always buy more lead credits at any time.</p>
              </ReactTooltip>
            </div>
            <p className="sub_price_info">*Annual commitment. Billed monthly</p>
          </div>        
          <div className="pricing-items">
            <ul>
              <li><i className="fa fa-list"></i> Unlimited Lists</li>
              <li><i className="fa fa-briefcase"></i> Account Based Search Filters</li>
              <li><i className="fa fa-briefcase"></i> Market Based Search Filters</li>
              <li><i className="fa fa-cogs"></i> Fuzebot List Build Automation</li>
              <li><i className="fa fa-ban"></i> Ignore Lists</li>
              <li><i className="fa fa-download"></i> Export to CSV</li>
              <li><i className="fa fa-clone"></i> De-Duplication</li>
              <li><i className="fa fa-server"></i> Integrations</li>
              <li><i className="fa fa-briefcase"></i>  LinkedIn URL Lookups</li>
            </ul>
          </div>
          <div className="pricing-for bg-limegreen"  style={{padding:`10px`}}>
                    <label className="title">Agree To Terms:</label>
                    <div className="terms_agree__check" style={{marginTop: `15px`, paddingLeft:`0.8rem`}}  onClick={this.toggleConsentUnlimited}>
                        <div className={`check ${this.state.consentUnlimited ? 'active' : ''}`} style={{padding: `6px`, border: `2px solid rgb(236, 151, 29)`, borderRadius: `12px`}}></div>
                        <p style={{marginBottom: `20px`, marginTop: `-20px`}}>By purchasing, you agree to the <u><a href="https://leadFuze.com/terms-use" target="_blank">terms
                            of service</a></u>.</p>
                    </div>
                    <Fab
                        disabled={!this.state.consentUnlimited}
                        variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="add"
                        onClick={() => this.goToTrialPlanCheckout('commitment_1022_unlimited')}>
                        Subscribe to Unlimited
                    </Fab>
                    {/* <p style={{marginTop: `15px`}}>Use Promo Code: <span  className="pricing-price">GOUNLIMITED</span> on checkout for $200/mo off!</p> */}
            </div>
				</div>
      </div>
        </div>
      </div>
      )
    } else {
    return(
      <div className={`add-list ${this.props.popup ? 'open' : ''}`} style={{paddingTop: this.props.paddingTop}}>
        <div className="add-list__content">
          <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={this.props.closePopup}/>
          
          <p className="add-list__title">{this.props.heading? this.props.heading : 'Add to List'}</p>
          <p className="add-list__label">List Name</p>
          
          <div className={`add-list__search-box ${this.state.active ? 'active' : ''}`}  onClick={this.openListNameDropDown}>
            <input 
              type="text"
              className={`add-list__search ${this.state.showError ? 'error-field' : ''}`}
              disabled={!this.state.addNewList} 
              placeholder={this.state.addNewList ? 'Create List Name' : 'No list selected' } 
              value={this.state.listName}
              onChange={this.updateListName} 
              />
            
            {!this.state.addNewList && <img src="/assest/accountBased/role-arr.svg" alt=""/> }
          </div>

          {this.state.showError && <div className="error">{this.state.errorMessage}</div>}
          
          {!this.state.addNewList &&
            <Fragment>
              <AddToListName 
                active={this.state.active}
                lists={this.state.lists.filter(item => {
                  return item.user_id == user.id && item._id != listId || !this.props.heading
                })}
                loadMore={this.loadLists}
                hasMore={this.state.hasMoreItems}
                selectList={this.selectList} />
            
              <p className="add-list__create" onClick={this.showCreateNewListField}>
                <img src="/assest/accountBased/plus-orange.svg" alt=""/>
                CREATE NEW LIST
              </p>
            </Fragment>
          }

          <div className="add-list__buttons">
            <div className="add-list__btn" onClick={this.addToList}>{this.props.heading? this.props.heading : 'Add to List'}</div>
            { this.state.addNewList ?
            <div className="add-list__btn" onClick={this.goBack}>Back</div>
            :
            <div className="add-list__btn" onClick={this.props.closePopup}>Cancel</div>
            }
          </div>
        </div>
      </div>
    )
  }
  }
}

export default AddToList