import React from 'react'
import IntegrationsItem from '../IntegrationsItem'

const AllIntegrations = (props) => {
    const {activeIntegrations, turningOffIntegrationSwitch, toggelIndex, image, filterFlag, openFreshSalesPopup, popupFreshSales, popupDuxSoup, openDuxSoupPopup, integID, settingPopup, getIntegrations, integrationsData, openIntegrationPopup, popup, popup2, openIntegrationPopup2, nameForPopup, apiLocation, integrationLabel} = props
    var numOfInactiveIntegrations = 0;
    integrationsData.forEach((i) => {
        if (activeIntegrations.map((a) => {return a.name}).indexOf(i.name) < 0 && i.checked) {
            numOfInactiveIntegrations++
        }
    })
    return (
        <div className={`coupon create ${numOfInactiveIntegrations > 0 ? 'active' : ''}`}>
            <h3 className="integrations-cont__title">Available Integrations</h3>
            <div className="integrations-cont__content">
                {
                    integrationsData.map((item, index) =>
                        activeIntegrations.map((a) => {
                            return a.name
                        }).indexOf(item.name) < 0 ?
                            <IntegrationsItem
                                key={index}
                                name={item.name}
                                check={item.checked}
                                id={item.id}
                                active={item.active}
                                featured={item.featured}
                                index={index}
                                text={item.text}
                                image={item.image}
                                tags={item.tags}
                                url={item.url}
                                apiLocation={item.apiLocation}
                                integrationsData={integrationsData}
                                openIntegrationPopup={openIntegrationPopup}
                                openIntegrationPopup2={openIntegrationPopup2}
                                activeID={item.activeID}
                                getIntegrations={getIntegrations}
                                openDuxSoupPopup={openDuxSoupPopup}
                                openFreshSalesPopup={openFreshSalesPopup}
                                filterFlag={filterFlag}
                                turningOffIntegrationSwitch={turningOffIntegrationSwitch}
                                integrationLabel={item.label}
                            /> : ""
                    )
                }
                
            </div>
        </div>
    )
}
export default AllIntegrations
