import { GET_ADMIN_USERS } from "../constants/constant";

const initialState = { adminUsers: [] }

export default function adminUsers(state = initialState, action) {

    switch (action.type) {
        case GET_ADMIN_USERS:
            return {
                ...state,
                adminUsers: action.payload
            }
        default:
            return state
    }
}

