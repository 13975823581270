import { Fab } from "@material-ui/core";
import React, { Component } from "react";
import Functions from "../MarketSearch/FieldsDropdown/Functions";
import "./style.scss";
import MaxCompanyResults from "./MaxCompanyResults/max-company-results";


class AdvLead extends Component {
  state = {
    collapse: this.props.advancedCollapse ? this.props.advancedCollapse : false
  };

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const {
      setIncludeTags,
      setExcludeTags,
      setGroups,
      clearAllFields,
      clearFields,
      updateClearFields,
      searchError,
      resetError,
      searchResultFilters,
      setMaxPeoplePerCompany,
      setMaxResultsPerCompany,
      renderActions
    } = this.props;

    let errorDiv = null;
    if(searchError) {
      errorDiv = (<div className="adv-lead__error_div">
      <p>Please select at least one filter to search leads.</p>
      {/* <img
        src="/assest/apiKeys/Close_red.svg"
        alt=""
        onClick={resetError}
      /> */}
    </div>);
    }
    return (
      <section className="adv-lead">
        <h2
          className={`adv-lead__title ${this.props.advancedCollapse ? "expend" : "collapse"}`}
          onClick={this.props.toggleAdvancedView}
        >
          Advanced Search Criteria
        </h2>

        {!this.props.advancedCollapse ? (
          <div>
            <div className="adv-lead__container">
              <div className="adv-lead-key">
                <div className="people-market__col">
                  <p className="people-market__label">Keywords</p>
                  <Functions
                    placeholder="Keywords"
                    inputType="text"
                    name="keywords"
                    excludeKeywords={this.props.marketBased.exclude_keywords}
                    includeKeywords={this.props.marketBased.include_keywords}
                    groups={[]}
                    customInput={true}
                    haveTags={false}
                    basicHeading1="All Include & Exclude Tags"
                    setIncludeTags={setIncludeTags}
                    setExcludeTags={setExcludeTags}
                    setGroups={setGroups}
                    clearFields={clearFields}
                    updateClearFields={updateClearFields}
                    showExclude={true}
                  />
                </div>
              </div>



              {/* <div className="adv-lead-key mt-20">
                <div className="people-market__col">
                  <h4 className="right-filters-company__title">
                    Max Results per Company
                  </h4>
                  <div className="right-filters-company__list">
                    <input
                      type="number"
                      min="1"
                      placeholder="Select Max Results per Company"
                      value={searchResultFilters.max_people_per_company}
                      onChange={setMaxPeoplePerCompany}
                    />
                  </div>
                </div>
            </div> */}


            </div>



            {/* <div className="adv-lead__container">
              <div className="adv-lead-key">
                <div className="people-market__col">
                  <p className="people-market__label">Search by Location</p>

                  <SearchByLocation />
                </div>
              </div>
            </div> */}
          </div>
        ) : (
          ""
        )}

        {errorDiv}

        <div className="profile__buttons adv-lead-buttons">
          <div className="adv-lead__box ">
            {/* <Link to="/search-result"> */}

            { renderActions() }

            {/* <div className="filter-res__buttons">
              <div
                className="lead-search-key__btn orange-light"
                onClick={saveSearch}
              >
                Save search
              </div>
            </div> */}
          </div>

          <div className="adv-lead__link">
            <p onClick={clearAllFields}>Clear all fields</p>
          </div>
        </div>
      </section>
    );
  }
}

export default AdvLead;
