import React from "react";

const CheckDropdown = props => {
  return (
    <div className={`inside-checkdrop ${props.checkDropdown ? "open" : ""}`}>
      <div onClick={(e) => {
        e.stopPropagation();
        if(props.allLeads) {
          props.deSelectEverything();
        } else {
          props.selectAllLeads();
        }
      } }>
        <div className={`check ${props.allLeads? 'active' : ''}`} />
        <p>All Leads ({props.totalLeads})</p>
      </div>
      <div onClick={(e) => {
        e.stopPropagation();
        if(props.leadsInView) {
          props.deSelectEverything();
        } else {
          props.selectLeadsInView();
        }
      } }>
        <div className={`check ${props.leadsInView? 'active' : ''}`} />
        <p>Leads in View ({props.leadsCountInView})</p>
      </div>
      {/* <div onClick={(e) => {
        e.stopPropagation();
        if(props.newLeads) {
          props.deSelectEverything();
        } else {
          props.selectNewLeads();
        }
      } }>
        <div className={`check ${props.newLeads? 'active' : ''}`} />
        <p>New Leads (5)</p>
      </div> */}
    </div>
  );
};

export default CheckDropdown;
