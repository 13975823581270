const INITIAL_STATE = { image: '', userInfo: {} };

const userProfileInfo = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "UPDATE":
            return {
                ...state,
                image: action.payload,
            }
        case "SAVE_USER_INFO":
            return {
                ...state,
                userInfo: action.payload,
            }
        default:
            return state
    }
}
export default userProfileInfo
