export const columnsData = [{ name: "First Name", value: "first_name", check: true },
{ name: "Last Name", value: "last_name", check: true },
{ name: "Middle Name", value: "middle_name" },
{ name: "Job Role", value: "job_role", check: true },
{ name: "List Name", value: "list_name" },
{ name: "Industry", value: "industry" },
{ name: "Company Email", value: "company_email", check: true },
{ name: "Company Email Status", value: "company_email_status", check: true },
{ name: "Personal Email", value: "personal_email", check: true },
{ name: "Personal Email Status", value: "personal_email_status", check: true },
{ name: "Other Emails", value: "other_emails" },
{value : "mobile_number" , name :"Mobile Number" ,check: true},
{value : "other_phone_numbers" , name :"Other Phone Numbers",check: true},
{ name: "Skills", value: "skills" },
{ name: "Interests", value: "interests" },
{ name: "Gender", value: "gender" },
{ name: "Birthday", value: "birthday" },
{ name: "Location", value: "location", check: true },
{ name: "City", value: "city" },
{ name: "Metro", value: "metro" },
{ value: "state", name: "State" },
{ value: "country", name: "Country" },
{ value: "continent", name: "Continent" },
{ value: "longitude", name: "Longitude" },
{ value: "latitude", name: "Latitude" },
{ value: "college_degrees", name: "College Degrees" },
{ value: "college_minors", name: "College Minors" },
{ value: "college_majors", name: "College Majors" },
{ value: "college", name: "College" },
{ value: "about_me", name: "Aboutme Profile" },
{ value: "angellist", name: "Angellist Profile" },
{ value: "crunchbase", name: "Crunchbase Profile" },
{ value: "dribbble", name: "Dribbble Profile" },
{ value: "facebook", name: "Facebook Profile" },
{ value: "flickr", name: "Flickr Profile" },
{ value: "github", name: "Github Profile" },
{ value: "gitlab", name: "Gitlab Profile" },
{ value: "google", name: "Google Profile" },
{ value: "gravatar", name: "Gravatar Profile" },
{ value: "indeed", name: "Indeed Profile" },
{ value: "linkedin", name: "Linkedin Profile", check: true },
{ value: "meetup", name: "Meetup Profile" },
{ value: "pinterest", name: "Pinterest Profile" },
{ value: "quora", name: "Quora Profile" },
{ value: "reddit", name: "Reddit Profile" },
{ value: "soundcloud", name: "SoundCloud Profile" },
{ value: "stackoverflow", name: "Stackoverflow Profile" },
{ value: "twitter", name: "Twitter Profile" },
{ value: "vimeo", name: "Vimeo Profile" },
{ value: "wordpress", name: "Wordpress Profile" },
{ value: "xing", name: "Xing Profile" },
{ value: "company_name", name: "Company Name", check: true },
{value : "company_phone_number" , name :"Company Phone Number" ,check: true},
{ value: "company_website", name: "Company Website", check: true },
{ value: "company_industry", name: "Company Industry", check: true },
{ value: "company_adwords_budget", name: "Adwords Budget", check: true },
{ value: "company_employees", name: "Employee Range", check: true },
{ value: "phone", name: "Phone", check: true },
{ value: "founded_date", name: "Founded Date" },
{ value: "company_linkedin", name: "Company Linkedin Profile", check: true },
{ value: "company_facebook", name: "Company Facebook Profile" },
{ value: "company_twitter", name: "Company Twitter Profile" },
{ value: "company_crunchbase", name: "Company Crunchbase Profile" },
{ value: "company_mailing_address", name: "Company Mailing Address", check: true },
{ value: "company_location", name: "Company Location" },
{ value: "company_street_1", name: "Company Street 1" },
{ value: "company_street_2", name: "Company Street 2" },
{ value: "company_city", name: "Company City" },
{ value: "company_metro", name: "Company Metro" },
{ value: "company_state", name: "Company State" },
{ value: "company_zipcode", name: "Zip Code" },
{ value: "company_country", name: "Company Country" },
{ value: "company_continent", name: "Company Continent" },
{ value: "company_longitude", name: "Company Longitude" },
{ value: "company_latitude", name: "Company Latitude" },
]
