import React from 'react'
import items from './items'

const UsersHeader = (props) => {

  return (
    <div className="users-tab-header">
      <div className="users-tab-header__row">
        {/* <div className="users-tab-header__check">
          <div className={`check`} onClick={() => { }}></div>
          <img src="/assest/arr.svg" alt="" />
        </div> */}
        
        <div className="users-tab-header__items">
          {
            items.map((item, index) =>
              <div className={`users-tab-header__col ${item.cssClass}`} key={index}>
                <p>{item.name}</p>
              </div>
            )
          }
        </div>
        <ul className="users-tab-header__nav"></ul>
      </div>
    </div>
  )
}
export default UsersHeader
