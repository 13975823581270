import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { startLoader, stopLoader } from '../../actions/loader';
import { tokenFailure, tokenSuccess, loginAttempts, signInUserSuccess, signInUserFailure } from '../../actions/users';
import { resetPassword, doSignIn } from '../../services/users';

import {
  ResetPassContent,
} from '../../components';
import './style.scss';
import { Grid } from '@material-ui/core';
import { validate } from '../../helpers/validate';
import { LOCAL_STORAGE_KEYS } from '../../constants/constant';
import { EmitterService } from "../../services/emitterService";

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    startLoader,
    stopLoader,
    tokenFailure,
    tokenSuccess,
    signInUserFailure,
    signInUserSuccess
  }, dispatch);
}

function mapStateToProps(state, ownProps) {
  return {
    loader: state.loader,
    user: state.user
  };
}

class ResetPass extends Component {
  constructor(props) {
    super(props)

    this.state = {
      responseError: false,
      validationError: ""
    }
  }

  componentWillMount() {
    EmitterService.emit("loadLeadsRemaining");
  }

  onSubmit = (values) => {
    let error = validate(values)
    this.setState({ validationError: error })
    var that = this;
    const { startLoader, stopLoader, history, tokenFailure, tokenSuccess, signInUserSuccess, signInUserFailure } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('t');
    values.token = token
    if (!error.pass && !error.confirm_pass) {
      startLoader(true);
      resetPassword(values).then(function (response) {
        stopLoader(false);
        if (response.data.errCode === 1110) {
          tokenFailure(false);
          history.push('/forgot-pass');
        }
        else {
          tokenSuccess(true);
          let u = response.data.data[0]
          let acc = response.data.data[1]
          let status = response.data.data[1].status
          if (status === "fraud") {
            signInUserFailure(error);
            stopLoader(false);
          }
          else {
            doSignIn({pass: values.pass, email: u.email}).then(function (response) {
              let u = response.data.data[0]
              let acc = response.data.data[1]
              let status = response.data.data[1].status
              if (status === "fraud") {
                signInUserFailure(error);
                stopLoader(false);
              }
              else {
                signInUserSuccess(u);
                loginAttempts(0)
                localStorage.setItem('user', JSON.stringify(u))
                window.init_user(u.email)
                localStorage.setItem('userAccount', JSON.stringify(acc))
                if (status === "trial_expired") {
                  history.push("/billing-and-users")
                } else {
                  history.push('/lead-search/market-based')
                }
                stopLoader("signinFalse");
              }
            }).catch(error => {
              loginAttempts()
              history.push("/forgot-pass")
              signInUserFailure(error);
              stopLoader("signinFalse");
            });
      
            // signInUserSuccess(u);
            // loginAttempts(0)

            //redirect user to signin page after reset password success
            // history.push('/');
            // localStorage.setItem('user', JSON.stringify(u))
            // localStorage.setItem('userAccount', JSON.stringify(acc))
            // if (status === "trial_expired") {
            //   history.push("/billing-and-users")
            // }
            // stopLoader(false);
            // history.push('/lead-search/market-based?status=reset-pass')
          }
        }
      }).catch(error => {
        stopLoader(false);
        that.setState({ responseError: true });
      });
    }
  }

  render() {
    const { loader, history } = this.props;
    const { responseError, validationError } = this.state;
    const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
    return (
      <section className="log">
        {!(userId === null) &&
          history.push("/lead-search/market-based")
        }
        <Grid
          container
          direction="row"
        >
          <Grid item xs={12} sm={4} >
            <ResetPassContent onSubmit={this.onSubmit}
              visible={loader.loading}
              responseError={responseError}
              validationError={validationError}
            />
          </Grid>
          <Grid item xs={12} sm={8} className="log-right">
            <img src="/assest/sign-in/laptop.png" alt="" />
          </Grid>
        </Grid>
      </section>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPass);
