import React, { Component } from "react";

class CompanyTechnologies extends Component {

  showNext = () => {
    this.props.handlePagination(true);
  }

  showLast = () => {
    this.props.handlePagination(false);
  }

  render() {
    let hasPrevious = false;
    let hasMore = false;

    const technologies = [];
    const technology = this.props.leadDetail.technology;
    

    if (technology === null || technology.entries === null || technology.entries.length === 0) {
      return (<div></div>);
    }

    /*
    if (!this.props.leadDetail.addedToList && technology.entries.length > 24) {
      hasMore = true;
    }*/

    if (this.props.leadDetail.next_cursor.length > 0) {
      hasMore = true;
    }
    
    if (this.props.leadDetail.last_cursor.length > 0) {
      hasPrevious = true;
    }

    // if(this.props.leadDetail.addedToList || this.state.isShowAll) {
    //   if(technology.names && technology.names.length > 0) {
    //     technologies.push(...technology.names);
    //   }
  
    //   // if(technology.groups && technology.groups.length > 0) {
    //   //   technologies.push(...technology.groups);
    //   // }

    // } else {
    //   if(technology.names && technology.names.length > 0) {
    //     technology.names.forEach(element => {
    //       if (technologies.length < 24) {
    //         technologies.push(element);
    //       }
    //     });
    //   }
  
    //   // if(technologies.length < 3 && technology.groups && technology.groups.length > 0) {
    //   //   technology.groups.forEach(element => {
    //   //     if (technologies.length < 3) {
    //   //       technologies.push(element);
    //   //     } else {
    //   //       hasMore = true;
    //   //     }
    //   //   });
    //   // }
    // }


    return (
      <div className="company-technologies details">
        <h5 className="company-technologies-title company-title">Technologies Used</h5>

        <ul className="company-technologies-list">
          {technology && technology && technology.entries.map((item, index) => 
             (
              <li key={index} className={`tech-base ${item.toLowerCase()}`}>{item}</li>
             )
          )}

          {/* {(!this.props.leadDetail.addedToList && hasMore) && 
            <li className="see-more-link" onClick={this.showAll}>SHOW MORE</li>
          } */}

      
          {/* {(!this.props.leadDetail.addedToList && hasMore) && 
            <li className="see-more-link" onClick={($event)=> this.props.openAddLeadToListPopup($event, this.props.leadDetail.id)}>SHOW MORE</li>
          } */}
        </ul>
        <div class="details">        
        { 
          <div className={`company-tab__btn ${!hasPrevious ? 'company-disable' : ''}`} onClick={hasPrevious && this.showLast}>&lt;</div>
        }
        { 
          <div className={`company-tab__btn ${!hasMore ? 'company-disable' : ''}`} onClick={hasMore && this.showNext}>&gt;</div>
        }
        </div>
      </div>
       
    );
  }
}
export default CompanyTechnologies;
