import { Subject } from 'rxjs';

export class EmitterService {
  static eventEmitters = {};

  static get(ID) {
    if (!this.eventEmitters[ID]) {
      this.eventEmitters[ID] = new Subject();
    }
    return this.eventEmitters[ID];
  }

  static emit(ID, data) {
    if (this.eventEmitters[ID]) {
      this.eventEmitters[ID].next(data);
    }
  }

  static subscribe(ID, callBack) {
    this.get(ID).subscribe(callBack);
  }

  static unsubscribe(ID) {
    if (this.eventEmitters[ID]) {
      this.eventEmitters[ID].unsubscribe();
      delete this.eventEmitters[ID];
    }
  }
}