import React, { Fragment, Component } from 'react'
import Popup from 'reactjs-popup'
import classNames from 'classnames';

class ConfigureMenu extends Component {

    constructor(props) {
        super(props)
        this.state = {
            windowWidth: window.innerWidth,
            isMobile: window.innerWidth < 900,
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth, isMobile: window.innerWidth < 900 });
    };

    render() {

        return (
            <Fragment>

                <Popup
                    trigger={
                        <div className="add" style={{ padding: '10px' }}
                        >
                            <div className="">
                                <ul className='lead-cont-item__dots' style={{ marginLeft: 0 }}
                                    onClick={this.props.openMenu}
                                >
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>}

                    position="bottom right"
                    arrow={false}
                    closeOnDocumentClick={true}
                    closeOnEscape={true}
                    onClose={this.props.closeMenu}
                    contentStyle={{ 'background': 'none', border: 'default', 'boxShadow': 'none', 'zIndex': '10' }}
                >
                    {close => (
                        <div className="inside-select open">
                            <div className={`menu-item`} onClick={() => {
                                close()
                                this.props.onOpen()
                            }}>
                                <div>
                                    <i className="fa fa-eye" style={{ marginRight: '5px' }}></i>View
                                </div>
                            </div>
                            <div className={classNames("menu-item", {
                                "disable-btn": this.props.slack_webhook_url
                            })}>
                                <div>
                                    <i className="fa fa-slack" style={{ marginRight: '5px' }}></i>
                                    <a
                                        href={this.props.slack_url}
                                        target="_blank"
                                    >Enable Slack</a>
                                </div>
                            </div>
                            <div className={`menu-item `} onClick={e => { close(); this.props.onEdit() }}>
                                <div>
                                    <i className="fa fa-edit" style={{ marginRight: '5px' }}></i>Update
                                </div>
                            </div>
                        </div>)}
                </Popup>
            </Fragment>
        )
    }
}

export default ConfigureMenu