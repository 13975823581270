import { isNotEmpty } from "./validate";

export function filterUsersByNameAndEmail(users, searchTerm) {
  return users.filter(item => {
    if (isNotEmpty(item.owner_name)) {
      return item.owner_name.toLowerCase().includes(searchTerm.toLowerCase());
    }

    return item.email.toLowerCase().includes(searchTerm.toLowerCase());
  })
}
