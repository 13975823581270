import React, { Component } from 'react'

const check = [
  {
    name: 'LinkedIn',
    checked: false,
  },
  {
    name: 'Facebook',
    checked: false,
  },
  {
    name: 'Twitter',
    checked: false,
  },
]

class SocialCheck extends Component{

  render(){
    const {
      setRequireSocial,
      setRequireLinkedin,
      setRequireFacebook,
      setRequireTwitter,
      searchResultFilters
    } = this.props;

    return(
      <div className="right-filters__social">
        <div className="right-filters__switch" onClick={setRequireSocial}>
          <div className={`switch ${searchResultFilters.require_social ? 'active' : ''}`}><span></span></div>
          <p className="switch__name">Require LinkedIn</p>
        </div>
{/* 
        {searchResultFilters.require_social && 
          <div className="right-filters__socChecks actCheck">
            <div className="right-filters__check" onClick={setRequireLinkedin}>
              <div className={`check ${searchResultFilters.require_linkedin ? 'active' : ''}`}></div>
              <p className={`${searchResultFilters.require_linkedin ? 'right-filters__checked' : ''}`}>LinkedIn</p>
            </div>

            <div className="right-filters__check" onClick={setRequireFacebook}>
              <div className={`check ${searchResultFilters.require_facebook ? 'active' : ''}`}></div>
              <p className={`${searchResultFilters.require_facebook ? 'right-filters__checked' : ''}`}>Facebook</p>
            </div>

            <div className="right-filters__check" onClick={setRequireTwitter}>
              <div className={`check ${searchResultFilters.require_twitter ? 'active' : ''}`}></div>
              <p className={`${searchResultFilters.require_twitter ? 'right-filters__checked' : ''}`}>Twitter</p>
            </div>
          </div>
        } */}
      </div>
    )
  }
}

export default SocialCheck