

const items = [
  {
    name: 'Lead Search',
    icon: 'acc-1.svg',
    active: 'acc-1-hover.svg',
    subMenu: false,
    flag: true,
    current: false,
    slug: '/lead-search/market-based'
  },
  {
    name: 'Lists',
    icon: 'acc-5.svg',
    active: 'acc-5-hover.svg',
    subMenu: false,
    flag: false,
    current: false,
    slug: '/people-lists'
  },

  {
    name: 'Billing & Users',
    icon: 'billing.svg',
    active: 'billing-hover.svg',
    subMenu: false,
    flag: false,
    current: false,
    slug: '/billing-and-users'
  },

  {
    name: 'Settings',
    icon: 'acc-7.svg',
    active: 'acc-7-hover.svg',
    subMenu: false,
    flag: true,
    current: false,
    slug: '/integrations'
  },

  {
    name: 'Admin',
    icon: 'acc-8.svg',
    active: 'acc-8-hover.svg',
    subMenu: false,
    flag: true,
    current: false,
    slug: '/admin/users'
  }
]

export default items