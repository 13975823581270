import React, {Component} from 'react';
import Calendar from 'react-calendar';
import Moment from 'moment';


class AccountDetails extends Component {
  calendarContainer = React.createRef();
  calendarInputContainer = React.createRef();
  calendarContainer2 = React.createRef();
  calendarInputContainer2 = React.createRef();
  state = {
    calendarActive: false,
    calendarActive2: false

  }

  componentDidMount() {
  }

  openCalendar = () => {
    this.setState({calendarActive: true});
    document.body.addEventListener('click', this.clickOutsideCalendar);
  }
  openCalendar2 = () => {
    this.setState({calendarActive2: true});
    document.body.addEventListener('click', this.clickOutsideCalendar2);
  }

  clickOutsideCalendar = ( e) => {
    if (this.calendarContainer.current && !this.calendarContainer.current.contains(e.target)) {
      this.closeCalendar();
    }
  }
  clickOutsideCalendar2 = ( e) => {
    if (this.calendarContainer2.current && !this.calendarContainer2.current.contains(e.target)) {
      this.closeCalendar2();
    }
  }

  closeCalendar = () => {
    setTimeout(() => {
      this.setState({calendarActive: false});
      this.calendarInputContainer.current.blur();
      document.body.removeEventListener('click', this.clickOutsideCalendar);
    }, 100);
  }
  closeCalendar2 = () => {
    setTimeout(() => {
      this.setState({calendarActive2: false});
      this.calendarInputContainer2.current.blur();
      document.body.removeEventListener('click', this.clickOutsideCalendar2);
    }, 100);
  }

  render() {

    const user = this.props.user;
    const account = user.accounts[0];
  
    return( 
      <div className="details-cont">
        <div className="details-cont__row">
          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>Trial Expiration</p>

              <div className="trail-expiration-cal" ref={this.calendarContainer}>
                <input ref={this.calendarInputContainer} type="text" placeholder="MM-DD-YYYY" className="date-field" onFocus={() => this.openCalendar()} disabled={!user.editMode} value={this.props.trailExpirationDateStr} />
                <img className="cal-icon" src="/assest/cal.svg" alt="" />

                {this.state.calendarActive && 
                  <div className="cal-body">
                    <Calendar
                      onChange={this.props.onDateChange}
                      value={this.props.trailExpirationDate} />
                  </div>
                }
              </div>
            </label>
          </div>

          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>Contract Start Date</p>

              <div className="trail-expiration-cal" ref={this.calendarContainer2}>
                <input ref={this.calendarInputContainer2} type="text" placeholder="MM-DD-YYYY" className="date-field" onFocus={() => this.openCalendar2()} disabled={!user.editMode} value={Moment(new Date(this.props.contract_start_date * 1000)).format('MM-DD-YYYY')} />
                <img className="cal-icon" src="/assest/cal.svg" alt="" />

                {this.state.calendarActive2 && 
                  <div className="cal-body">
                    <Calendar
                      onChange={this.props.onContractStartDateChange}
                      value={new Date(this.props.contract_start_date * 1000)} />
                  </div>
                }
              </div>
            </label>
          </div>
          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>Available Lead Credits</p>
              <input placeholder="Number here" type="number"   disabled={!user.editMode} 
              value={this.props.available_lead_credits} 
              onChange={(e) => this.props.updateAccountAvailableLeadCredits(e.target.value)}
              
              />
            </label>
          </div>

          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>Company Name</p>
              <input placeholder="Text here" type="text" disabled value={account.company_name}/>
            </label>
          </div>

   


          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>Automation Limit</p>
              <input placeholder="Number here"
                    onChange={(e) => this.props.updateAutomationLimit(e.target.value)} 
              type="number" disabled={!user.editMode}   value={this.props.automation_limit}/>
            </label>
          </div>
          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>List Capacity</p>
              <input placeholder="Number here"
                    onChange={(e) => this.props.updateListCapacity(e.target.value)} 
              type="number" disabled={!user.editMode}  value={this.props.list_capacity}/>
            </label>
          </div>

          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>Monthly Lead Credits</p>
              <input placeholder="Number here"
                    onChange={(e) => this.props.updateMonthlyLeadCredits(e.target.value)} 
              type="number" disabled={!user.editMode}  value={this.props.monthly_lead_credits}/>
            </label>
          </div>

          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>Plan Name</p>
              <input placeholder="Text here"
                    onChange={(e) => this.props.updatePlanName(e.target.value)} 
              type="text" disabled={!user.editMode} value={this.props.plan_name}/>
            </label>
          </div>

          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>Stripe Id</p>
              <input placeholder="Text here"
                    onChange={(e) => this.props.updateStripeId(e.target.value)} 
              type="text" disabled={!user.editMode} value={this.props.stripe_id}/>
            </label>
          </div>

          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>Subscription Id</p>
              <input placeholder="Text here" 
                    onChange={(e) => this.props.updateSubscriptionId(e.target.value)} 
              type="text" disabled={!user.editMode}  value={this.props.subscription_id}/>
            </label>
          </div>

          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>Account Email</p>
              <input placeholder="Text here" type="text" disabled value={account.email}/>
            </label>
          </div>

          <div className="details-cont__col ">
            <div className="check-panel">
              <div className={`check ${user.editMode ? '' : 'disabled'} ${this.props.double_lead_credits_offer ? 'active' : ''}`} onClick={() => this.props.updateIsDoubleLeadCreditsOffer()}></div>
              <div className="check-label">Has Used Double Lead Credits Offer?</div>
            </div>
          </div>

          <div className="details-cont__col ">
            <div className="check-panel">
              <div className={`check ${user.editMode ? '' : 'disabled'} ${this.props.extended_trial ? 'active' : ''}`} onClick={() => this.props.updateIsExtendedTrial()}></div>
              <div className="check-label">Has Extended Trial?</div>
            </div>
          </div>

         

          <div className="details-cont__col m-Style1">
            <div className="check-panel">
              <div className={`check ${user.editMode ? '' : 'disabled'} ${this.props.is_life_time ? 'active' : ''}`} onClick={() => this.props.updateIsLifeTime()}></div>
              <div className="check-label">Is LifeTime?</div>
            </div>
          </div>

          <div className="details-cont__col m-Style1">
            <div className="check-panel">
              <div className={`check ${user.editMode ? '' : 'disabled'} ${this.props.is_unlimited ? 'active' : ''}`} onClick={() => this.props.updateIsUnlimited()}></div>
              <div className="check-label">Is Unlimited?</div>
            </div>
          </div>

          <div className="details-cont__col m-Style1">
            <div className="check-panel">
              <div className={`check ${user.editMode ? '' : 'disabled'} ${this.props.is_fraud ? 'active' : ''}`} onClick={() => this.props.updateIsFraud()}></div>
              <div className="check-label">Is Fraud?</div>
            </div>
          </div>        
        </div>
      </div>
    ) 
  }
}

export default AccountDetails;