import React from 'react'

import './style.scss'

const DontList = (props) =>{
  return(
    <div className="dont-list">
      <h2 className="dont-list__title">
        {props.title || "You don't have any leads for this list"}
      </h2>
      <p className="dont-list__descr">{props.description || "Run a search and save the criteria to have it show here"}</p>
      <div className="dont-list__btn" onClick={(e=> props.onSearchForLeads())}>Search for Leads</div>
    </div>
  )
}

export default DontList
