const tabs = [
  {
    tab: '01',
    active: true,
    success: false
  },
  {
    tab: '02',
    active: false,
    success: false
  },
  {
    tab: '03',
    active: false,
    success: false
  },
  {
    tab: '04',
    active: false,
    success: false
  }
]

export default tabs