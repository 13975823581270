import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import SocialPopup from './SocialPopup'
import './style.scss'
import { updateProfilePic, updateProfile, getBucketUrl } from '../../../services/profile';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Fab } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { startLoader, stopLoader } from '../../../actions/loader';
import Loader from '../../Loader';
import ProfilePicUpdate from './ProfilePicPopup';
import { pictureUpdate } from '../../../actions/profile_update';
import { LOCAL_STORAGE_KEYS } from '../../../constants/constant';
import { showSuccessToast, showErrorToast } from '../../../helpers/toast';
import { Img } from 'react-image';

class ProfileContent extends Component {
  state = {
    picSelected: false,
    picUpdated: false,
    picUpdateError: false,
    profilePopup: false,
    popup: false,
    selectedFile: null,
    profilePic: "",
    bucketPath: '',
    imageHash: "",
    emailInfo: '',
    phoneInfo: '',
    fName: '',
    lName: '',
    email: '',
    compName: '',
    role: '',
    password: '',
    phone: "",
    linkedin: "",
    youtube: "",
    facebook: "",
    twitter: "",
    instagram: "",
    dropedFile: null,
    showRoleDropDown: false,
    isLeadToManyLists: false,
    formErrors: {},
  }
  componentDidMount = async () => {
    const { pictureUpdate, profileImage } = this.props
    const res = await getBucketUrl()
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
    let picture = "https://s3.amazonaws.com/" + res.data.data.s3_base_path + user.profile_pic_url
    pictureUpdate(picture)
    const userAcc = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT))
    if (user !== null && userAcc !== null)
      this.setState({
        emailInfo: user.email,
        phoneInfo: user.phone,
        profilePic: profileImage.image,
        bucketPath: res.data.data.s3_base_path,
        fName: user.first_name,
        lName: user.last_name,
        email: user.email,
        role: user.role,
        compName: userAcc.company_name,
        phone: user.phone,
        facebook: user.facebook,
        linkedin: user.linkedin,
        twitter: user.twitter,
        instagram: user.instagram,
        youtube: user.youtube,
        isLeadToManyLists: userAcc.is_lead_to_many_lists
      })

    this.roleRef = React.createRef();
    document.addEventListener("click", this.handleClickOutside);

  }
  handleClickOutside = e => {
    if (this.roleRef && this.roleRef.current && !this.roleRef.current.contains(e.target) && this.state.showRoleDropDown) {
      this.setState({
        showRoleDropDown: false,
      });
    }
  };
  openPopup = (e) => {
    this.setState({ popup: !this.state.popup })
  }
  editProfilePopup = () => {
    this.setState({ profilePopup: !this.state.profilePopup })
  }
  fNameChange = (e) => {
    this.setState({ fName: e.target.value })
  }
  lNameChange = (e) => {
    this.setState({ lName: e.target.value })
  }
  emailChange = (e) => {
    this.setState({ email: e.target.value })
  }
  passwordChange = (e) => {
    this.setState({ password: e.target.value })
  }

  updateLeadToManyLists = (e) => {
    const {isLeadToManyLists, editProfile} = this.state;
    if (editProfile === true) {
      this.setState({ isLeadToManyLists: !isLeadToManyLists})
    }
  }
  compNameChange = (e) => {
    this.setState({ compName: e.target.value })
  }
  roleChange = (e) => {
    this.setState({ role: e.target.value })
  }
  editProfileToggele = async() => {
    const { pictureUpdate, profileImage } = this.props
    const res = await getBucketUrl()
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
    let picture = "https://s3.amazonaws.com/" + res.data.data.s3_base_path + user.profile_pic_url
    pictureUpdate(picture)
    const userAcc = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT))
    if (user !== null && userAcc !== null)
      this.setState({
        editProfile: !this.state.editProfile,
        formErrors: [],
        emailInfo: user.email,
        phoneInfo: user.phone,
        profilePic: profileImage.image,
        bucketPath: res.data.data.s3_base_path,
        fName: user.first_name,
        lName: user.last_name,
        email: user.email,
        role: user.role,
        compName: userAcc.company_name,
        phone: user.phone,
        facebook: user.facebook,
        linkedin: user.linkedin,
        twitter: user.twitter,
        instagram: user.instagram,
        youtube: user.youtube
      })
  }
  onShowRoleDropDown = () => {
    this.setState({showRoleDropDown: !this.state.showRoleDropDown});
  }
  pictureUpload = (event, uploadType) => {
    const { bucketPath } = this.state
    const { pictureUpdate } = this.props
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
    let fileType = uploadType === "input" ? event.target.files[0].type : event[0].type;
    if (fileType === "image/jpeg" || fileType === "image/png" || fileType === "image/gif" || fileType === "image/bmp" || fileType === "image/jpg") {
      this.setState({ picSelected: true, selectedFile: uploadType === "input" ? event.target.files[0] : event[0] },
        async () => {
          var formData = new FormData();
          formData.append('file', this.state.selectedFile);
          try {
            const res = await updateProfilePic(user.id, formData)
            let picture = "https://s3.amazonaws.com/" + bucketPath + res.data.data.profile_pic_url
            pictureUpdate(picture)
            localStorage.setItem('user', JSON.stringify(res.data.data))
            this.setState({ profilePic: "https://s3.amazonaws.com/" + bucketPath + res.data.data.profile_pic_url, imageHash: Date.now(), picSelected: false, picUpdated: true })
            setTimeout(() => {
              this.editProfilePopup()
              this.editProfileToggele()
              this.setState({ picUpdated: false })
            }, 2000)
          } catch (error) {
            this.setState({ picUpdateError: true, picSelected: false })
            setTimeout(() => {
              this.setState({ picUpdateError: false })
            }, 3000)
          }
        }
      );
    } else {
      toast.error('Only jpg or jpeg, png and gif files allowed', {
        className: "toast-error-div",
        autoClose: 5000
      })
      this.setState({ picSelected: false })
    }
  }

  UpdateProfile = async () => {
    const { editProfile, fName, lName, email, password, compName, role, isLeadToManyLists } = this.state
    const { startLoader, stopLoader } = this.props
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))

    if (this.validateForm()) {
      startLoader(true);
      let toastId = null;
      toastId = toast('Updating profile', {
        autoClose: 10000,
      });
      try {
        const res = await updateProfile(user.id, fName, lName, compName, role, email, password, isLeadToManyLists)
        localStorage.setItem('user', JSON.stringify(res.data.data[0]))
        localStorage.setItem('userAccount', JSON.stringify(res.data.data[1]))
        const data = res.data.data[0]
        this.setState({ 
          editProfile: !editProfile, 
          emailInfo: data.email, 
          email: data.email, 
          fName: data.first_name, 
          lName: data.last_name, 
          role: data.role, 
          compName: res.data.data[1].company_name, 
          password: '' 
        })
        showSuccessToast(toastId, "Profile updated successfully");
      }
      catch (error) {
        showErrorToast(toastId, "Unable to update profile");
      }
      stopLoader(false)
    }
  }

  changePhoneNum = newNum => {
    this.setState({ phoneInfo: newNum })
  }

  changeSocialLink = (linkedin, facebook, twitter, instagram, youtube ) => {
    this.setState({ linkedin, facebook, twitter, instagram, youtube });
  }

  redirectToSocialPage = (social) => {
    const { linkedin, youtube, facebook, twitter, instagram } = this.state
    if (social === "linkedIn") {
      window.open(linkedin, '_blank');
    }
    if (social === "youtube") {
      window.open(youtube, '_blank');
    }
    if (social === "facebook") {
      window.open(facebook, '_blank');
    }
    if (social === "twitter") {
      window.open(twitter, '_blank');
    }
    if (social === "instagram") {
      window.open(instagram, '_blank');
    }

  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isNullOrEmpty(field) {
      return !field || field.trim().length === 0;
  }

  validateForm = () => {
    const {fName, lName, compName, email, role, password} = this.state;
    const formErrors = {};

    if (this.isNullOrEmpty(fName)) {
        formErrors["fName"] = "This field is required";
    }

    if (this.isNullOrEmpty(lName)) {
        formErrors["lName"] = "This field is required";
    }

    if (this.isNullOrEmpty(compName)) {
      formErrors["compName"] = "This field is required";
    }

    if (this.isNullOrEmpty(role)) {
        formErrors["role"] = "Select a value";
    }

    if (this.isNullOrEmpty(email)) {
        formErrors["email"] = "This field is required";
    } else if (!this.validateEmail(email.trim())) {
        formErrors["email"] = "Email format is not valid";
    }

    this.setState({formErrors: formErrors});
    return Object.keys(formErrors).length === 0;
  }

  render() {
    const { picUpdated, picUpdateError, picSelected, profilePopup, popup, profilePic, imageHash, fName, lName, email, password, compName, role, emailInfo, phoneInfo, editProfile, linkedin, facebook, twitter, instagram, youtube, isLeadToManyLists } = this.state
    
    const { loader, profileImage } = this.props
    return (
      <section className="profile">
        {loader.loading && <Loader />}
        <div className="profile__title"><h3>My Profile</h3>
          <p className={`${!editProfile ? "on-pointer" : "on-pointer fadeOut disable-btn"}`} onClick={this.editProfileToggele}><img src="/assest/billing/pan.svg" alt="" />Edit</p>
        </div>
        <div className="profile__content">
          <div className="profile__row profile-row1">
            <div className="profile__col">
              <div className="profile__inp">
                <p className="profile__label">First Name</p>
                <input disabled={!editProfile} type="text" value={fName} onChange={this.fNameChange} placeholder={`${!editProfile ? "" : "Please type your first name here"}`} />
                {this.state.formErrors.fName &&
                  <div className="error">{this.state.formErrors.fName}</div>}
              </div>
              <div className="profile__inp">
                <p className="profile__label">Last Name</p>
                <input disabled={!editProfile} type="text" value={lName} onChange={this.lNameChange} placeholder={`${!editProfile ? "" : "Please type your last name here"}`} />
                {this.state.formErrors.lName &&
                  <div className="error">{this.state.formErrors.lName}</div>}
              </div>
            </div>
            <div className="profile__col">
              <div className="profile-info">
                <div className="profile-info__item">
                  <div className={`profile-info__photo ${editProfile ? "image-cursor" : ''}`}>
                    {editProfile &&
                      <label className="image-cursor" onClick={this.editProfilePopup}></label>
                    }
                    <span className={`profile-info__photo ${editProfile ? "image-cursor" : ''}`}><img onClick={editProfile ? this.editProfilePopup : null} src={`${profileImage.image}?ver=${imageHash}`} alt="" /></span>
                  </div>
                </div>
                <div className="profile-info__item contact-section">
                  {editProfile &&
                    <label className="image-cursor" onClick={this.openPopup}></label>
                  }
                  <h5 className="profile-info__title">Contact Info
                  {/* {editProfile && <img className="on-pointer" src="/assest/billing/pan.svg" alt="" onClick={this.openPopup}/>}  */}

                  </h5>
                  <p className="profile-info__contacts email">{emailInfo}</p>
                  <p className="profile-info__contacts phone">{phoneInfo}</p>
                  <div className={`profile-info__icons`}>
                    <div className={`profile-info__link ${linkedin ? '' : 'disabled'}`} to="" onClick={!editProfile && linkedin ? () => this.redirectToSocialPage("linkedIn") : null}><img src="/assest/accountBased/social-icons/ln-icon.svg" alt="" /></div>
                    <div className={`profile-info__link ${facebook ? '' : 'disabled'}`} to="" onClick={!editProfile && facebook ? () => this.redirectToSocialPage("facebook") : null}><img src="/assest/accountBased/social-icons/fb-icon.svg" alt="" /></div>
                    <div className={`profile-info__link ${twitter ? '' : 'disabled'}`} to="" onClick={!editProfile && twitter ? () => this.redirectToSocialPage("twitter") : null}><img src="/assest/accountBased/social-icons/tw-icon.svg" alt="" /></div>
                    <div className={`profile-info__link ${instagram ? '' : 'disabled'}`} to="" onClick={!editProfile && instagram? () => this.redirectToSocialPage("instagram") : null}><img src="/assest/accountBased/social-icons/inst-icon.svg" alt="" /></div>
                    <div className={`profile-info__link ${youtube ? '' : 'disabled'}`} to="" onClick={!editProfile && youtube ? () => this.redirectToSocialPage("youtube") : null}><img src="/assest/accountBased/social-icons/yt-icon.svg" alt="" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile__row">
            <div className="profile__col">
              <div className="profile__inp">
                <p className="profile__label">Company Name</p>
                <input disabled={!editProfile} type="text" value={compName} onChange={this.compNameChange} placeholder={`${!editProfile ? "" : "Please type your company name here"}`} />
                {this.state.formErrors.compName &&
                  <div className="error">{this.state.formErrors.compName}</div>}
              </div>
            </div>
            <div className="profile__col">
              <div className="profile__inp">
                <p className="profile__label">Email</p>
                <input disabled={true} type="text" value={email} placeholder={email} />
              </div>
            </div>
            <div className="profile__col">
              <div className="profile__inp" onClick={this.onShowRoleDropDown} ref={this.roleRef}>
                <p className="profile__label">Role</p>
                <input disabled={!editProfile} type="text" value={role}  onChange={this.roleChange} placeholder={`${!editProfile ? "" : "Please select your role here"}`} />
                {this.state.formErrors.role &&
                  <div className="error">{this.state.formErrors.role}</div>}
                <div className="role-dropdown" >
                    {/* <div
                        className={`dropdown-value`}
                        onClick={this.onShowRoleDropDown}>{role === "" ? "Select Role" : role}</div> */}

                    {this.state.showRoleDropDown && editProfile &&
                    <div className="dropdown-start dropdown-in animation-slideDown" >
                        <p className="dropdown-in__title">Roles</p>
                        <div className="dropdown-in__groups scroll-dropdown">
                          <div className="dropdown-in-group"
                                onClick={() => this.setState({role: "Founding Team"})}>
                              <p className="dropdown-in-group__name  ">Founding Team</p>
                          </div>
                          <div className="dropdown-in-group"
                                onClick={() => this.setState({role: "Marketing"})}>
                                  <p className="dropdown-in-group__name  ">Marketing</p>
                          </div>
                          <div className="dropdown-in-group"
                                onClick={() => this.setState({role: "Recruiting"})}>
                                  <p className="dropdown-in-group__name  ">Recruiting</p>
                          </div>
                          <div className="dropdown-in-group"
                                onClick={() => this.setState({role: "Sales"})}>
                                  <p className="dropdown-in-group__name  ">Sales</p>
                          </div>
                        </div>
                    </div>
                    }
                </div>
              </div>
            </div>
            <div className="profile__col">
              <div className="profile__inp">
                <p className="profile__label">Password</p>
                <input disabled={!editProfile} type="password" value={password} onChange={this.passwordChange} placeholder={`${!editProfile ? "" : "Please type your password here"}`} />
                {this.state.formErrors.password &&
                  <div className="error">{this.state.formErrors.password}</div>}
              </div>
            </div>
            
          </div>
          <div className="profile__row">
            <div className="profile__col">
                <div className="details-cont__col m-Style1 profile__left">
                  <div className="check-panel">
                      <div className={`check ${editProfile ? 'on-pointer' : 'disabled'} ${isLeadToManyLists ? 'active' : ''}`} onClick={this.updateLeadToManyLists}></div>
                      <div className="check-label">Allow the same lead to be added to multiple lists?</div>
                  </div>
                </div>
              </div>
          </div><br/>
          
          {editProfile &&
            <div className="profile__buttons">
              <Fab
                onClick={this.UpdateProfile}
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add"
              >Save Changes</Fab>
              <Fab
                onClick={this.editProfileToggele}
                variant="extended"
                size="medium"
                color="secondary"
                aria-label="add"
              >Cancel</Fab>
            </div>
          }
        </div>
        <ProfilePicUpdate editProfileToggele={this.editProfileToggele} picUpdated={picUpdated} picUpdateError={picUpdateError} picSelected={picSelected} profilePopup={profilePopup} editProfilePopup={this.editProfilePopup} pictureUpload={this.pictureUpload} onDrop={this.onDrop} />
        <SocialPopup editProfileToggele={this.editProfileToggele} popup={popup} openPopup={this.openPopup} linkChange={this.changeSocialLink} phoneChange={this.changePhoneNum} editProfile={editProfile} />
      </section>
    )

  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    startLoader,
    stopLoader,
    pictureUpdate
  }, dispatch);
}
function mapStateToProps(state) {
  return {
    loader: state.loader,
    profileImage: state.userProfileInfo
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContent)