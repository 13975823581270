import React, { Component, Fragment } from 'react'
import {
  Person,
  Company,
  Employees,
  Activity
} from './tabs';
import './style.scss';
import { Loader } from '../../../components';
import { getUnseenActivitiesCount } from '../../../services/searchResult';
import Details from './Company/Details';

class LeadDetail extends Component {

  constructor(props) {
    super(props);
    const titles = [
      {
        title: 'Person Details',
        active: true,
        points: false,
        visible: false
      },
      {
        title: 'Company Details',
        active: false,
        points: false,
        visible: false
      },
      {
        title: 'Employees',
        active: false,
        points: false,
        visible: false
      },
      {
        title: 'Activity',
        active: false,
        points: true,
        visible: false
      }
    ];

    this.state = {
      titleList: titles,
      acivityCountLoaded: false,
      activitiesCount: 0
    };
  }

  componentDidMount() {
    this.state.titleList.forEach((item) => {
      item.visible = false;
      item.active = false
    });

    this.state.titleList[0].active = true;
    this.setState({ titleList: this.state.titleList });
  }

  changeActiveTitle = (i, tabName) => {
    const { titleList } = this.state;

    if (tabName === 'Activity' && !this.props.leadDetail.addedToList) {
      return;
    }

    titleList.map((item) =>
      item.active = false
    )
    titleList[i].active = true
    this.setState({ titleList: titleList })
  }

  getActivitiesCount = async () => {
    if (!this.state.acivityCountLoaded) {
      const response = await getUnseenActivitiesCount(this.props.leadDetail.leadId);
      this.setState({ acivityCountLoaded: true, activitiesCount: response.data.data.count });
    }
  }

  render() {
    const { titleList } = this.state;

    if (this.props.showTabs) {
      if (this.props.leadDetail) {
        titleList[0].visible = true;
        titleList[3].visible = true;

        if (this.props.leadDetail.job && this.props.leadDetail.job.company) {
          titleList[1].visible = true; // if job is not null then show Company tab
          if (this.props.leadDetail.job.company && this.props.leadDetail.job.company.id)
            titleList[2].visible = true; // if job.company is not null then show Employees tab
        }
      }
    }

    if (this.props.leadDetail && this.props.leadDetail.addedToList) {
      this.getActivitiesCount();
    }

    return (
      <div className="search-tabs">
        {this.props.showTabs &&
          <div className="search-tabs__links">
            {titleList.map((item, index) =>
              <Fragment key={index}>
                {item.visible &&
                  <h3 className={`search-tabs__title ${item.active ? 'active' : ''} ${(item.points && !this.props.leadDetail.addedToList) ? 'locked' : ''}`}
                    onClick={() => this.changeActiveTitle(index, item.title)}>
                    {item.title}
                    {(item.points && this.props.leadDetail.addedToList) ?
                      (this.state.activitiesCount > 0 ? <div className="search-tabs__points">{this.state.activitiesCount}</div> : '')
                      :
                      item.points ? <img src="/assest/accountBased/lock.svg" /> : ''}</h3>
                }
              </Fragment>
            )}
          </div>
        }

        <div className="search-tabs__container">
          {this.props.showLeadDetailLoader && <Loader />}

          {this.props.leadDetail &&
            titleList[0].active ? <Person leadDetail={this.props.leadDetail} openAddLeadToListPopup={this.props.openAddLeadToListPopup} /> :
            titleList[1].active && this.props?.leadDetail?.job.company.id ? <Company leadDetail={this.props.leadDetail} openAddLeadToListPopup={this.props.openAddLeadToListPopup} /> :
              titleList[1].active && !this.props?.leadDetail?.job.company.id ? <Details leadDetail={this.props.leadDetail.job} /> :
                titleList[2].active ? <Employees leadDetail={this.props.leadDetail} openAddLeadToListPopup={this.props.openAddLeadToListPopup} /> :
                  titleList[3].active ? <Activity leadDetail={this.props.leadDetail} /> : ''
          }
        </div>
      </div>
    )
  }
}

export default LeadDetail;