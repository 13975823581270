import React from 'react';

export const renderField = ({
  input,
  label,
  type,
  validationError,
}) => (
    <input {...input} placeholder={label} type={type} className={`${validationError ? "input-error" : ''}`} />
  )
