import React, { Fragment, Component } from "react";
import InsideSelectIntegration from "../InsideSelectIntegration";
import { numberFormatter } from "../../../../constants/helper";
import Popup from "reactjs-popup";
import classNames from 'classnames';

const duxsoup = "duxsoup"
const woodpecker = "woodpecker"
const lemlist = "lemlist"
const reply = "reply"
const highlevel = "highlevel"
const jazzhr = "jazzhr"
const salesforce = "salesforce"
const hubspot = "hubspot"
const freshsales = "freshsales"
const closeio = "close-io"
const bidsketch = "bidsketch"
const pipedrive = "pipedrive"
const salesflare = "salesflare"
const mailshake = "mailshake"
const zapier = "zapier"

class InsideSelect extends Component {
  state = {
    innerIntegrationMenu: false,
    windowWidth: window.innerWidth,
    isMobile : window.innerWidth < 900,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
  }

  handleResize = (e) => {
      this.setState({ windowWidth: window.innerWidth, isMobile : window.innerWidth < 900 });
  };

  doesIntegrationExist = integrationName => {
    const foundIntegration = this.props.integrations.find(
      integration => integration.name === integrationName
    );
    return !!foundIntegration;
  };
  openIntegrationMenu = (e) => {
    e.stopPropagation();
    this.setState({ innerIntegrationMenu: true });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!nextProps.insideSelectDropdown) {
      this.setState({ innerIntegrationMenu: false });
    }
  }

  render() {
    return (
      <Fragment>
        <Popup trigger={<p className="add"
        // onClick={(e) => this.changeCheckDropdown(e)}
        >
          {this.props.allLeads ? numberFormatter.format(this.props.totalLeads) : this.props.selectedLeads.length} Selected Leads Options
              <img src="/assest/accountBased/arrow-orange.svg" alt="" />
        </p>}
          position="bottom center"
          arrow={false}
          closeOnDocumentClick={true}
          closeOnEscape={true}
          contentStyle={{ 'background': '#f6f6f6', border: 'default', boxShadow: 'none' }}
        >
          {close => (
            <div className="inside-select open">
            <div className={`menu-item `} onClick={e => { close(); this.props.showMoveToList()} }><div><img src="/assest/inside/back.svg" style={{ marginRight: '5px' }} alt="" />Move Selected To</div></div>
            <Popup trigger={
              <div className={classNames("menu-item", {
                "disable-btn": !this.props.integrations || this.props.integrations.length == 0
            })}><dvi><img src="/assest/inside/send.svg" style={{ marginRight: '5px', transform: 'rotate(90deg)' }} alt="" />Send Selected To</dvi><img className="menu-arrow revert" src="/assest/accountBased/arrow.svg" alt="" /></div>}
              position={this.state.isMobile ? "left top" : "left top"}
              arrow={false}
              on="hover"
              contentStyle={{ top: '10px', border: 'default', boxShadow: 'none', 'padding': 0 }}
            >
              {this.doesIntegrationExist(duxsoup)? 
                  (
                    <Popup trigger={ 
                      <div className="menu-item" >
                        <div style={{display: 'flex'}}>
                          <img src="/assest/integrations/duxsoup-logo.png" style={{width: 20, paddingLeft: 3, paddingRight: 3, marginRight: 10}} alt="" />Dux-Soup
                        </div>
                        <img className="menu-arrow revert" src="/assest/accountBased/arrow.svg" alt="" /></div>}
                      position={"left top"}
                      arrow={false}
                      on="hover"
                      contentStyle={{top:'10px', border: 'default', 'boxShadow': 'none', padding: 0 }}
                      >
                        <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(duxsoup)} }><i class="fa fa-user-plus" style={{width: 20, paddingLeft: 3, paddingRight: 3, marginRight: 5}}  />Send Connection Request</div>
                        <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration('duxsoup-no-popup')} }><i class="fa fa-eye" style={{width: 20, paddingLeft: 3, paddingRight: 3, marginRight: 5}}  />Visit Profile</div>
                      </Popup>
                  )
                  : null
                }
              {this.doesIntegrationExist(woodpecker) ? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(woodpecker)} }><img className="woodpecker" src="/assest/integrations/woodpecker-logo.ico" alt="" />Woodpecker</div> : null}
              {this.doesIntegrationExist(mailshake) ? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(mailshake)} }><img src="/assest/integrations/mailshake-logo.png" alt="" />Mailshake</div> : null}
              {this.doesIntegrationExist(lemlist) ? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(lemlist)} }><img src="/assest/integrations/lemlist-logo.png" alt="" />lemlist</div> : null}
              {this.doesIntegrationExist(reply) ? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(reply)} }><img src="/assest/integrations/reply-logo.png" alt="" />Reply</div> : null}
              {this.doesIntegrationExist(highlevel) ? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(highlevel)} }><img src="/assest/integrations/highlevel-logo.png" alt="" />Highlevel</div> : null}
              {this.doesIntegrationExist(jazzhr) ? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(jazzhr)} }><img src="/assest/integrations/jazzhr-logo.png" alt="" />Jazz Hr</div> : null}
              {this.doesIntegrationExist(salesforce) ? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(salesforce)} }><img src="/assest/integrations/salesforce-logo.ico" alt="" />Salesforce</div> : null}
              {this.doesIntegrationExist(hubspot) ? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(hubspot)} }><img src="/assest/integrations/hubspot-logo.png" alt="" />Hubspot</div> : null}
              {this.doesIntegrationExist(freshsales) ? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(freshsales)} }><img src="/assest/integrations/freshsales-logo.png" alt="" />Freshsales</div> : null}
              {this.doesIntegrationExist(closeio) ? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(closeio)} }><img src="/assest/integrations/closeio-logo.ico" alt="" />CloseIo</div> : null}
              {this.doesIntegrationExist(bidsketch) ? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(bidsketch)} }><img src="/assest/integrations/bidsketch-logo.ico" alt="" />Bidsketch</div> : null}
              {this.doesIntegrationExist(pipedrive) ? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(pipedrive)} }><img src="/assest/integrations/pipedrive-logo.png" alt="" />Pipedrive</div> : null}
              {this.doesIntegrationExist(salesflare) ? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(salesflare)} }><img src="/assest/integrations/salesflare-logo.png" alt="" />Salesflare</div> : null}
              {this.doesIntegrationExist(zapier)? <div className="menu-item icon-set" onClick={() => { close(); this.props.integrateSelectedLeadsWithIntegration(zapier)} }><img src="/assest/integrations/zapier-logo.ico" alt="" />Zapier</div> : null}
            </Popup>
            <Popup trigger={
              <div className="menu-item"><div><img src="/assest/inside/export.svg" style={{ marginRight: '5px' }} alt="" />Export To</div><img className="menu-arrow" src="/assest/accountBased/arrow.svg" alt="" /></div>}
              position={this.state.isMobile ? "left top" : "left top"}
              arrow={false}
              on="hover"
              closeOnDocumentClick
              contentStyle={{ top: '10px', border: 'default', boxShadow: 'none', 'padding': 0 }}
            >
              <div className="">
                <div className="menu-item" onClick={() => { close(); this.props.exportSelectedLeads()} }>CSV</div>
                <div className={`menu-item ${(this.props.googleSheetIntegrated) ? "" : ''}`} onClick={() => { close(); this.props.exportSelectedLeadsToGoogleSheet()} }>Google Sheet</div>
              </div>
            </Popup>
            <div className={`menu-item `} onClick={e => { close(); this.props.deleteBulkSelected()} }><div><img src="/assest/inside/del.svg" alt="" style={{ marginRight: '5px' }} />Delete Selected</div></div>
          </div>)}
        </Popup>

        {/* <div className="inside-select__back"></div>
        <div
          className={`inside-select ${
            this.props.insideSelectDropdown ? "open" : ""
          }`}
        >
          <div
            className="inside-select__item"
            onClick={e => this.props.showMoveToList()}
          >
            <img src="/assest/inside/back.svg" alt="" />
            <p>Move Selected to</p>
          </div>
          <div
            className="inside-select__item"
            style={{position: 'relative'}}
            onClick={e => this.openIntegrationMenu(e)}
          >
            <img src="/assest/inside/send.svg" alt="" />
            <p>Send Selected to 
              {/* <img src="/assest/accountBased/right.svg" alt="" /> * /}
              </p>
            
            {this.props.insideSelectDropdown && this.state.innerIntegrationMenu? <InsideSelectIntegration {...this.props}></InsideSelectIntegration> : null}
          </div>
          <div
            className="inside-select__item"
            onClick={e => this.props.exportSelectedLeads()}
          >
            <img src="/assest/inside/export.svg" alt="" />
            <p>Export Selected To CSV</p>
          </div>
          <div
            className={`inside-select__item ${
              this.props.googleSheetIntegrated ? "" : "disable-btn"
            }`}
            onClick={e => this.props.exportSelectedLeadsToGoogleSheet()}
          >
            <img src="/assest/inside/export.svg" alt="" />
            <p>Export Selected to Google Sheet</p>
          </div>
          <div
            className="inside-select__item"
            onClick={e => this.props.deleteBulkSelected()}
          >
            <img src="/assest/inside/del.svg" alt="" />
            <p>Delete Selected</p>
          </div>
        </div> */}
      </Fragment>
    );
  }
}

export default InsideSelect;
