import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import './style.scss';

class BillingPriceList extends Component {

  isSelectedTermAnnual() {
    return this.props.selectedTerm.toLowerCase().indexOf('annual') > -1;
  }

  getStarterLeads() {
    if (this.isSelectedTermAnnual()) {
      return 500 * 12;
    }
    return 500;
  }

  getScalingLeads() {
    if (this.isSelectedTermAnnual()) {
      return 2500 * 12;
    }
    return 2500;
  }
  render() {
    return(
      <div className="pricing-row">
        <div className={`pricing-col starter-plan ${this.props.selectedPlan === 'Starter' ? 'selected' : ''}`} onClick={() => this.props.onChangePlan('Starter')}>
          <h3 className="pricing-title">Starter</h3>
          
          <div className="pricing-for bg-limeblue">For individuals looking to start prospecting for new customers.</div>
          
          <div className="pricing-price generate">
            <div className="monthly">{this.getStarterLeads()} Lead Credits/<span className="pricing-sub">{this.isSelectedTermAnnual()? 'yr' : 'mo'}<sup>*</sup>
              <a href="#" data-for="starterToolTip" data-tip data-place="right"><span className="fa fa-question-circle-o tooltips" aria-hidden="true"></span></a></span>

              <ReactTooltip id="starterToolTip">
                <p className="starter-tooltip">When you add a lead to a list, this uses up one lead credit. You are then given contact info, social data, and more on that lead. You can always buy more lead credits at any time.</p>
              </ReactTooltip>            
            </div>
            
            <p className="sub_price_info">*You can add more later</p>
          </div>
          
          <div className="pricing-items">
            <ul>
              <li><i className="fa fa-list"></i> Unlimited Lists</li>
              <li><i className="fa fa-briefcase"></i> Account Based Search Filters</li>
              <li><i className="fa fa-briefcase"></i> Market Based Search Filters</li>
              <li><i className="fa fa-cogs"></i> Fuzebot List Build Automation</li>
              <li><i className="fa fa-ban"></i> Ignore Lists</li>
              <li><i className="fa fa-download"></i> Export to CSV</li>
              <li><i className="fa fa-clone"></i> De-Duplication</li>
              <li><i className="fa fa-server"></i> Integrations</li>
            </ul>
          </div>
        </div>

        <div className={`pricing-col scaling-plan ${this.props.selectedPlan === 'Scaling' ? 'selected' : ''}`} onClick={() => this.props.onChangePlan('Scaling')}>
          <h3 className="pricing-title">Scaling</h3>
          <div className="pricing-for bg-limegreen"> For individuals or teams looking to scale their lead generation efforts.</div>
          <div className="pricing-price automate">
            <div className="monthly">
              <span className="cut-price"></span>{this.getScalingLeads()}  Lead Credits/<span className="pricing-sub">{this.isSelectedTermAnnual()? 'yr' : 'mo'}<sup>*</sup>
              <a href="#" data-for="scalingToolTip" data-tip data-place="right"><span className="fa fa-question-circle-o tooltips" aria-hidden="true"></span></a></span>
              
              <ReactTooltip id="scalingToolTip">
                <p className="scaling-tooltip">When you add a lead to a list, this uses up one lead credit. You are then given contact info, social data, and more on that lead. You can always buy more lead credits at any time.</p>
              </ReactTooltip>
            </div>
            
            <p className="sub_price_info">*You can add more later</p>
          </div>
          <div className="pricing-items">
            <ul>
              <li><i className="fa fa-list"></i> Unlimited Lists</li>
              <li><i className="fa fa-briefcase"></i> Account Based Search Filters</li>
              <li><i className="fa fa-briefcase"></i> Market Based Search Filters</li>
              <li><i className="fa fa-cogs"></i> Fuzebot List Build Automation</li>
              <li><i className="fa fa-ban"></i> Ignore Lists</li>
              <li><i className="fa fa-download"></i> Export to CSV</li>
              <li><i className="fa fa-clone"></i> De-Duplication</li>
              <li><i className="fa fa-server"></i> Integrations</li>
            </ul>
          </div>
				</div>
      </div>
    )
  }
}

export default BillingPriceList;