import React, {Component} from 'react';
import {getBillingHistory} from '../../../../services/billing';
import './style.scss';
import moment from "moment";
import {Loader} from "../../..";

class BillingDetail extends Component {
    state = {
        billingHistoryList: [],
        isLoading: false
    };

    componentDidMount() {
        this.loadBillingHistory();
    }

    loadBillingHistory = async () => {
        this.setState({isLoading: true});
        try {
            const response = await getBillingHistory();
            this.setState({billingHistoryList: response.data.data, isLoading: false});
        }
        catch(error) {
            this.setState({isLoading: false});
        }
    }

    formatDate(date) {
        return moment(date * 1000, "x").format("MMM. DD, YYYY");
    }

    formatAmount(amount) {
        return "$" + (amount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    render() {
        return (
            <section className="billing-history">
                <h3 className="billing-history__title">Billing history</h3>

                <div className="billing-history-table-panel">
                    {this.state.isLoading && <Loader/>}

                    <table className="billing-history__table">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th className="description">Description</th>
                            <th className="status">Status</th>
                            <th className="card">Card</th>
                            <th className="actions">Actions</th>
                        </tr>
                        </thead>

                        <tbody>
                        {this.state.billingHistoryList.map((item, index) =>
                            <tr key={index}>
                                <td>{this.formatDate(item.date)}</td>
                                <td>{this.formatAmount(item.amount)}</td>
                                <td className="description">{item.description}</td>
                                <td className="status">{item.status == "succeeded" ? "SUCCESSFUL" : "FAILED"}</td>
                                <td className="status">{item.card ? <span> <i
                                    className={"fa fa-cc-" + item.card.brand}></i></span> : "N/A"} {item.card.last4}</td>
                                <td className="actions">
                                    {
                                        item.pdf ?
                                            <a href={item.pdf} target="_blank"><i
                                                className="fa fa-file-pdf-o"></i></a> : <p>Unavailable</p>
                                    }
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </section>
        )
    }
}

export default BillingDetail;