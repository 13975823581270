import './style.scss'

import isEqual from 'lodash/isEqual'
import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {setErrorMessage} from '../../actions/error_msg'
import {getIntegration} from '../../actions/integration'
import {getLead, clearLead, updateListItem} from '../../actions/lead_users'
import {
    changeNameFilterAction,
    currentPageNum,
    filterFuzzBotActive,
    filterFuzzBotNone,
    filterFuzzBotPause,
    filterListAtMaxCapacity,
    filterName,
    filterOwnerStatus,
    filterWithZeroLeads,
    fuzzBotStatus,
    leadsRecordsPerPage,
    saveListNames,
    saveOwnersLists,
    setAllCheckToFalse,
    clearPageNumber,
} from '../../actions/leadfilters'
import {startLoader, stopLoader} from '../../actions/loader'
import { openPopup, closePopup } from '../../actions/loader';
import {redirectUser} from '../../actions/users'
import {
    BreadCrumb,
    DontList,
    LeadListsContent,
    LeadListsHeader,
    LeadSearchFilter,
    Loader,
    Pagination,
    RightPanelBanner
} from '../../components'
import {LEAD_FILTERS_INITIAL_STATE} from '../../reducers/leadfilters'
import {
    deleteList,
    getIntegrations,
    getLeadLists,
    getListNames,
    getOwnersLists,
    integrateListIdWithSelectedIntegration,
} from '../../services/leads'
import {filterUsersByNameAndEmail} from '../../helpers/filterUsers'
import {LOCAL_STORAGE_KEYS} from '../../constants/constant'
import { EmitterService } from "../../services/emitterService";

class LeadLists extends Component {
    container = React.createRef();

    constructor(props) {
        super(props)
        this.state = {
            appliedLeadFilter: props.leadFilter,
            leadLists: [],
            ownersLists: [],
            listNames: [],
            searchValue: '',
            newListItem: [],
            listsItems: this.props.leadList.leadList,
            newItems: [],
            popup: false,
            popupCSV: false,
            popupWoodpecker: false,
            popupDuxSoup: false,
            popupDelete: false,
            popupPauseResume: false,
            popupEditList: false,
            popupExpGoogle: false,
            popupNewList: false,
            popupLemlist: false,
            popupMailshake: false,
            popupReply: false,
            popupHighLevel: false,
            popupJazzhr: false,
            allCheck: false,
            totalLists: 0,
            ledListError: false,
            showDropDown: false,
            integrations: [],
            googleSheetIntegrated: false,
            paginationMarginBottom: 0,
        }
    }

    async componentWillMount() {
        EmitterService.emit("loadLeadsRemaining");
        this.props.closePopup();
    }

    async componentDidMount() {
        //await this.clearAll();
        this.props.startLoader(true);
        this.leadList();
        this.ownersLists();
        this.listNames();
        this.integration();
        this.redirectGoogleSheetPopup()
        document.addEventListener("mousedown", this.clickEventHandle)
    }

    async componentWillUnmount() {
        document.removeEventListener("mousedown", this.clickEventHandle)
        const {setErrorMessage, clearLead} = this.props
        setErrorMessage(false)
        this.setState({ledListError: false})
        await this.clearAll();
        // clearPageNumber(1);
        clearLead();
    }

    clickEventHandle = (event) => {
        const {showDropDown} = this.state
        if (this.container.current && !this.container.current.contains(event.target)) {
            if (showDropDown) {
                this.paginationPopup()
            }
        }
    }

    redirectGoogleSheetPopup = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const listID = urlParams.get('listID');
        const gexport = urlParams.get('gexport');
        if (gexport && listID) {
            this.setState({popupExpGoogle: true});
            const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
            this.props.redirectUser(user)
        }
    }

    leadList = async (newList) => {
        var that = this;
        const {leadList, startLoader, stopLoader, leadFilter, setErrorMessage} = this.props;
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        if (newList !== undefined) {
            that.props.getLead([newList, ...leadList.leadList])
        } else {
            startLoader(true);
            try {
                const res = await getLeadLists(userId.id, {
                    ...leadFilter.filters,
                    ...leadFilter.pagination,
                    ...leadFilter.sorting
                })
                await that.props.getLead(res.data.data.lists)
                this.setState({totalLists: res.data.data.total_number_of_lists})
            } catch (error) {
                setErrorMessage(true)
                this.setState({ledListError: true})
            }
            stopLoader(false)
        }
    }
    integration = async () => {
        const {startLoader, stopLoader, getIntegration} = this.props;
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        if (userId !== null) {
            const res = await getIntegrations(userId.id)
            if (res.data) {
                let integrations = res.data.data;
                this.setState({integrations});
                let sheetIntegration = integrations.find(function (el) {
                    return el.name === "googlesheets"
                });
                if (sheetIntegration) {
                    this.setState({googleSheetIntegrated: true});
                }
            }
            await getIntegration(res)
        }
    }

    ownersLists() {
        var that = this;
        const {startLoader, stopLoader} = this.props;
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        
        if (userId !== null) {
            getOwnersLists(userId.id).then(function (response) {
                that.setState({ownersLists: response.data.data, newItems: response.data.data});
            }).catch(error => {
            });
        }
        

    }

    listNames() {
        var that = this;
        const {startLoader, stopLoader} = this.props;
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        
        if (userId !== null) {
            getListNames(userId.id).then(function (response) {
                that.setState({listNames: response.data.data.lists, newListItem: response.data.data.lists})
            }).catch(error => {
            });
        }
        
    }

    fuzeBot = async () => {
        const {fuzzBotStatus, leadFilter} = this.props;
        await fuzzBotStatus(!leadFilter.filters.fuzeBot);
    }

    listAtMax = async () => {
        const {filterListAtMaxCapacity, leadFilter} = this.props;
        await filterListAtMaxCapacity(!leadFilter.filters.listsAtMax);
    }

    ownerState = async () => {
        const {filterOwnerStatus, leadFilter} = this.props;
        if (leadFilter.filters.ownerState) {
            this.clearOwnerList();
        }
        await filterOwnerStatus(!leadFilter.filters.ownerState);
    }

    listWithZeroLead = async () => {
        const {filterWithZeroLeads, leadFilter} = this.props;
        await filterWithZeroLeads(!leadFilter.filters.listsWithZeroRemaining);
    }

    setFuzeActive = async () => {
        const {filterFuzzBotActive, leadFilter} = this.props;
        await filterFuzzBotActive(!leadFilter.filters.fuzebotActive);
    }

    setFuzePause = async () => {
        const {filterFuzzBotPause, leadFilter} = this.props;
        await filterFuzzBotPause(!leadFilter.filters.fuzebotPaused);
    }

    setFuzeNone = async () => {
        const {filterFuzzBotNone, leadFilter} = this.props;
        await filterFuzzBotNone(!leadFilter.filters.fuzebotNone);
    }

    setNameFilter = async () => {
        const {filterName, leadFilter} = this.props;
        await filterName(!leadFilter.filters.nameFilter);
    }

    applyFilter = async () => {
        this.setState({
            appliedLeadFilter: this.props.leadFilter
        })
        window.scrollTo(0, 0);
        const {currentPageNum, getLead} = this.props
        await currentPageNum(1)
        await getLead('')
        this.leadList();
    }
    clearAll = async () => {
        const {setAllCheckToFalse, leadsRecordsPerPage} = this.props
        await leadsRecordsPerPage(10)
        //await currentPageNum(1)
        await setAllCheckToFalse(false)
        this.clearFilterForNameCheck()
    }
    clearAllFilter = async () => {
        await this.clearAll();
        this.leadList()
    }
    clearFilterForNameCheck = () => {
        const {listNames, ownersLists} = this.state
        let i;
        for (i = 0; listNames.length > i; i++) {
            if (listNames[i].check) {
                listNames[i].check = !listNames[i].check;
            }
        }
        this.clearOwnerList();
    }
    clearOwnerList = () => {
        const {ownersLists} = this.state
        for (let i = 0; ownersLists.length > i; i++) {
            if (ownersLists[i].check) {
                ownersLists[i].check = !ownersLists[i].check;
            }
        }
        this.setState({ownersLists: [...ownersLists]});
    }

    openListPopup = () => {
        this.setState({popup: !this.state.popup});
    }
    openCsvPopup = () => {
        this.setState({popupCSV: !this.state.popupCSV});
    }
    openDeletePopup = () => {
        this.setState({popupDelete: !this.state.popupDelete})
    }
    openExpGooglePopup = () => {
        this.setState({popupExpGoogle: !this.state.popupExpGoogle})
    }
    openNewListPopup = () => {
        this.setState({popupNewList: !this.state.popupNewList})
    }
    openPauseResumePopup = () => {
        this.setState({popupPauseResume: !this.state.popupPauseResume})
    }
    openEditListPopup = () => {
        this.setState({popupEditList: !this.state.popupEditList})
    }
    openDuxSoupPopup = (updatedItem) => {
        if(updatedItem) {
            this.props.updateListItem(updatedItem);
        }
        this.setState({popupDuxSoup: !this.state.popupDuxSoup})
    }
    openWoodpeckerPopup = (updatedItem) => {
        if(updatedItem) {
            this.props.updateListItem(updatedItem);
        }
        this.setState({popupWoodpecker: !this.state.popupWoodpecker})
    }
    openLemlistPopup = (updatedItem) => {
        if(updatedItem) {
            this.props.updateListItem(updatedItem);
        }
        this.setState({popupLemlist: !this.state.popupLemlist})
    }
    openMailshakePopup = (updatedItem) => {
        if(updatedItem) {
            this.props.updateListItem(updatedItem);
        }
        this.setState({popupMailshake: !this.state.popupMailshake})
    }
    openReplyPopup = (updatedItem) => {
        if(updatedItem) {
            this.props.updateListItem(updatedItem);
        }
        this.setState({popupReply: !this.state.popupReply})
    }
    openHighLevelPopup = (updatedItem) => {
        
        if(updatedItem) {
            this.props.updateListItem(updatedItem);
        }
        this.setState({popupHighLevel: !this.state.popupHighLevel})
    }
    openJazzhrPopup = (updatedItem) => {
        if(updatedItem) {
            this.props.updateListItem(updatedItem);
        }
        this.setState({popupJazzhr: !this.state.popupJazzhr})
    }

    searchOwnerFilter = (e) => {

        const {newItems} = this.state;
        const value = e.target.value;
        this.setState({searchValue: value, ownersLists: filterUsersByNameAndEmail(newItems, value)});
    }
    searchListFilter = (e) => {
        const {newListItem} = this.state;
        const value = e.target.value;
        const itemsFilter = newListItem.filter(item => {
            return item.name.toLowerCase().includes(value);
        });
        this.setState({listNames: itemsFilter});

        const {changeNameFilterAction} = this.props;
        changeNameFilterAction(value);
    }
    changeCheck = async (i, id) => {
        const {ownersLists} = this.state;
        const {saveOwnersLists, leadFilter} = this.props;
        ownersLists[i].check = !ownersLists[i].check;
        this.setState({ownersLists: ownersLists, allCheck: false});
        let owners
        if (ownersLists[i].check) {
            owners = (leadFilter.filters.owners ? [leadFilter.filters.owners, id] : [id]).join(",")
        } else {
            const arr = leadFilter.filters.owners.split(",")
            owners = arr.filter(function (el) {
                return el !== id
            })
            owners = owners.join(",")
        }
        await saveOwnersLists(owners);
    }

    changeNameFilter = (text) => {
        const {changeNameFilterAction} = this.props;
        changeNameFilterAction(text);
    }

    deleteList = async (id) => {
        const {startLoader} = this.props
        startLoader(true);
        await deleteList(id)
        this.leadList()
    }


    paginate = async (num) => {
        window.scrollTo(0, 0);
        const {currentPageNum, getLead} = this.props
        //await getLead("")
        await currentPageNum(num)
        this.leadList()
    }
    incOrDecNum = (sign, indexOfLastPage) => {
        const {leadFilter} = this.props
        let currentPage = leadFilter.pagination.pageNum
        if (sign === "-" && currentPage > 1) {
            currentPage = currentPage - 1
            this.paginate(currentPage)
        } else if (sign === "+" && currentPage < indexOfLastPage) {
            currentPage = currentPage + 1
            this.paginate(currentPage)
        }
    }
    paginationPopup = async () => {
        this.setState({showDropDown: !this.state.showDropDown})
    }

    updatePerPage = async (sign, num) => {
        const {leadsRecordsPerPage} = this.props
        let integer = parseInt(num);
        if (sign === "+" && integer < 100) {
            await leadsRecordsPerPage(integer  + 25)
            this.leadList()
        } else if (sign === "-" && integer > 25) {
            await leadsRecordsPerPage(integer - 25)
            this.leadList()
        } else if (sign === '') {
            this.setState({showDropDown: !this.state.showDropDown})
            await leadsRecordsPerPage(integer)
            this.leadList()
        }
    }

    viewFuzebotPendingLeadList = (leadList) => {
        this.props.history.push({
            pathname: '/lead-search/market-based',
            search: `?action=preview_leads&list_id=${leadList.id}&list_name=${leadList.name}`
        });
    }

    addOrEditFuzebotToLeadList = (leadList) => {
        this.props.history.push({
            pathname: '/lead-search/market-based',
            search: `?action=edit_fuzebot&list_id=${leadList.id}&list_name=${leadList.name}&fuzebot=${leadList.fuzebot}`
        });
    }

    showLeads = (leadList) => {
        this.props.history.push({
            pathname: '/lead-list/inside-list',
            search: `?list_id=${leadList.id}&list_name=${leadList.name}`
        });
    }

    searchFoLeads = () => {
        this.props.history.push({
            pathname: '/lead-search/market-based',
            // search: `?action=edit_fuzebot&list_id=${leadList.id}`
        });
    }

    integrateWithSelected = async (listId, integrationName) => {
        const {startLoader, stopLoader} = this.props;
        startLoader(true);
        try {
            await integrateListIdWithSelectedIntegration(listId, integrationName);
        } catch (error) {
            setErrorMessage(true);
            this.setState({leadsError: true});
        }
        stopLoader(false);
    }

    setSelectedPopupItem = (id, index) => {
        if(index == this.props.leadList.leadList.length - 1)
        {
            this.setState({paginationMarginBottom: 140});
        }
        else if(index == this.props.leadList.leadList.length - 2)
        {
            this.setState({paginationMarginBottom: 50});
        }
        else {
            this.setState({paginationMarginBottom: 0});
        }
    }

    render() {
        const {loader, leadFilter, leadList, integration, user} = this.props;
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
        const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT));

        const isUserAdmin = Boolean(userId && userId.is_admin);

        const {
            searchValue,
            ownersLists,
            popup,
            popupCSV,
            popupLemlist,
            popupWoodpecker,
            popupDelete,
            popupExpGoogle,
            popupMailshake,
            popupReply,
            popupHighLevel,
            popupJazzhr,
            popupPauseResume,
            popupDuxSoup,
            popupNewList,
            popupEditList,
            allCheck,
            listNames,
            totalLists,
            ledListError,
            showDropDown
        } = this.state;

        let dontHaveList = null;
        if (!loader.loading && (leadList.leadList && leadList.leadList.length === 0)) {
            dontHaveList = (
                <DontList
                    title={
                        !isEqual(this.state.appliedLeadFilter.filters, LEAD_FILTERS_INITIAL_STATE.filters)
                            ? '0 lists found matching these criteria'
                            : ''
                    }
                    onSearchForLeads={this.searchFoLeads}
                />
            )
        }
        let pagination = null;
        if (leadList.leadList && leadList.leadList.length ) { //&& totalLists > leadFilter.pagination.recordsPerPage
            pagination = <Pagination totalLists={totalLists}
                                     recordsPerPage={leadFilter.pagination.recordsPerPage}
                                     currentPageNum={leadFilter.pagination.pageNum}
                                     updatePerPage={this.updatePerPage}
                                     paginationPopup={this.paginationPopup}
                                     paginate={this.paginate}
                                     incOrDecNum={this.incOrDecNum}
                                     showDropDown={showDropDown}
                                     container={this.container}
                                     paginationMarginBottom={this.state.paginationMarginBottom}/>
        }

        const links = [
            {
                name: 'Lists',
                slug: '',
            },
        ]

        return (
            <Fragment>
                <BreadCrumb links={links}></BreadCrumb>
                <div className="leadList-panel">
                    {userId === null && this.props.history.push('/signin?path=/people-lists')}

                    {userAccount.status === "trial_expired" && this.props.history.push('/billing-and-users')}

                    {(user.errorDiv && ledListError) &&
                    <div className="error-div alert-div">
                        <img className="add-list__close" src="/assest/apiKeys/Close_red.svg" alt=""
                             onClick={() => this.setState({ledListError: !ledListError})}/>
                        <p>Sorry, we were unable to retrieve your lists. Please try again later or contact our support
                            team for further assistance.</p>
                    </div>
                    }

                    <div className="leadList-panel__left">

                        <LeadListsHeader
                            openNewListPopup={this.openNewListPopup}
                            popupNewList={popupNewList}
                            leadList={this.leadList}
                        />

                        {loader.loading && <Loader/>}

                        {leadList.leadList &&
                        <LeadListsContent popup={popup}
                                          popupMailshake={popupMailshake}
                                          popupReply={popupReply}
                                          popupHighLevel={popupHighLevel}
                                          popupJazzhr={popupJazzhr}
                                          popupLemlist={popupLemlist}
                                          popupWoodpecker={popupWoodpecker}
                                          popupDuxSoup={popupDuxSoup}
                                          popupCSV={popupCSV}
                                          popupDelete={popupDelete}
                                          popupEditList={popupEditList}
                                          popupPauseResume={popupPauseResume}
                                          showLeads={this.showLeads}
                                          popupExpGoogle={popupExpGoogle}
                                          openDuxSoupPopup={this.openDuxSoupPopup}
                                          openPauseResumePopup={this.openPauseResumePopup}
                                          openEditListPopup={this.openEditListPopup}
                                          openMailshakePopup={this.openMailshakePopup}
                                          openReplyPopup={this.openReplyPopup}
                                          openHighLevelPopup={this.openHighLevelPopup}
                                          openJazzhrPopup={this.openJazzhrPopup}
                                          openListPopup={this.openListPopup}
                                          openWoodpeckerPopup={this.openWoodpeckerPopup}
                                          openLemlistPopup={this.openLemlistPopup}
                                          openCsvPopup={this.openCsvPopup}
                                          openDeletePopup={this.openDeletePopup}
                                          openExpGooglePopup={this.openExpGooglePopup}
                                          deleteList={this.deleteList}
                                          leadList={this.leadList}
                                          integration={integration}
                                          addOrEditFuzebotToLeadList={this.addOrEditFuzebotToLeadList}
                                          viewFuzebotPendingLeadList={this.viewFuzebotPendingLeadList}
                                          googleSheetIntegrated={this.state.googleSheetIntegrated}
                                          integrations={this.state.integrations}
                                          integrateWithSelected={this.integrateWithSelected}
                                          items={leadList.leadList}
                                          setSelectedPopupItem={this.setSelectedPopupItem}
                                          updateListItem={this.props.updateListItem}
                        />
                        }

                        {pagination}

                        {dontHaveList}
                    </div>

                    <div className="leadList-panel__right">
                        <RightPanelBanner title={'Lead List Demo'} page="leadLists" type="people_lists" video_id="af4g9b66jr"/>

                        <LeadSearchFilter
                            fuzeBot={this.fuzeBot}
                            listAtMax={this.listAtMax}
                            ownerState={this.ownerState}
                            listWithZeroLead={this.listWithZeroLead}
                            changeCheck={this.changeCheck}
                            changeNameFilter={this.changeNameFilter}
                            allCheck={allCheck}
                            ownersLists={ownersLists}
                            listNames={listNames}
                            searchValue={searchValue}
                            searchOwnerFilter={this.searchOwnerFilter}
                            searchListFilter={this.searchListFilter}
                            setFuzeActive={this.setFuzeActive}
                            setFuzePause={this.setFuzePause}
                            setFuzeNone={this.setFuzeNone}
                            setNameFilter={this.setNameFilter}
                            leadFilter={leadFilter}
                            applyFilter={this.applyFilter}
                            clearAllFilter={this.clearAllFilter}
                            user={userId}
                            isUserAdmin={isUserAdmin}
                        />
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        startLoader,
        stopLoader,
        openPopup,
        closePopup,
        fuzzBotStatus,
        filterFuzzBotActive,
        filterFuzzBotPause,
        filterFuzzBotNone,
        filterName,
        filterListAtMaxCapacity,
        filterWithZeroLeads,
        filterOwnerStatus,
        saveOwnersLists,
        setAllCheckToFalse,
        saveListNames,
        changeNameFilterAction,
        getLead,
        getIntegration,
        redirectUser,
        currentPageNum,
        setErrorMessage,
        leadsRecordsPerPage,
        clearPageNumber,
        updateListItem,
        clearLead
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        loader: state.loader,
        leadFilter: state.leadFilter,
        user: state.user,
        leadList: state.leadList,
        integration: state.integration
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadLists);
