import React from 'react'
import '../style.scss'

const FindLeads2left = (props) =>{
  const { prevTab, market, step2, getValue } = props 
  const nextTab = () =>{
    if(market){
      step2()
    }else{
      alert('not fields')
    }
  }

  return(
    <div className="find-leads">
      <img className="find-leads__logo" src="/assest/accountBased/logo.svg" alt=""/>
      <h2 className="find-leads__title">Who is your target market?</h2>
      <div className="find-leads__box">
        <label className="find-leads__label">
          <p>Target Market</p>
          <input  type="text" 
                  name="market"
                  placeholder="Describe your target market here in 140 characters" 
                  value={market}
                  onChange={getValue}/>
        </label>
      </div>
      <p className="find-leads__text">Our team will use this information to be able to make personalized recommendations to you!</p>
      <div className="find-leads__buttons">
        <div className="find-leads__btn" onClick={nextTab}>Next Step</div>
        <div className="find-leads__link" onClick={()=>prevTab(1)}>Previous Step</div>
      </div>
    </div>
  )
}

export default FindLeads2left