import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import { numberFormatter } from '../../../constants/helper';
import $ from 'jquery';

const list = [
  {name: 'Person'},
  {name: 'Company'},
  {name: 'contact info'},
];


function mapStateToProps(state) {
  return {
    searchResultFilters: state.searchResultFilters,
  };
} 

class HeaderResult extends Component {

  
  render(){
    const { max_results_per_company } = this.props.searchResultFilters;

    return(
      <div className={`result-header ${!this.props.expendSearchResult ? 'show' : ''}`} style={{top: $('.nav-search').height() + 40}}>
        <div className="result-header__top" style={{backgroundColor: this.props.topColor}}>
          <h2 className={`result-header__title ${this.props.expendSearchResult ? 'collapse' : 'expend'}`}>Search Result {!this.props.loading && this.props.totalItems > 0 && <span className="item-count">{max_results_per_company && max_results_per_company != 'No Limit' && '~ '}{numberFormatter.format(this.props.totalItems)} results found</span> } </h2>
          {/* {this.props.expendSearchResult && this.props.selectedItems.length > 0 && <span className="item-count">Leads Selected: {this.props.addMax ? this.props.maxAddCount : this.props.selectedItems.length}</span> } */}
          {this.props.showHeaderButtons &&
            <div className={`result-header__selected active`}>
              <div className="result-header__add" onClick={(event) => this.props.openAddLeadToListPopup(event, null)}>Add {this.props.addMax ? this.props.maxAddCount : this.props.selectedItems.length} Selected to a List</div>

              {!this.props.addMax &&
                <div className="result-header__skip" onClick={(event) => this.props.skipLead(event, null, true)}><span>Skip selected</span></div>
              }
            </div>
          }
        </div>

        {this.props.expendSearchResult &&
          <div className="result-header__list" style={{backgroundColor: this.props.listColor}}>
            <div className="result-header__item result-header__check" onClick={this.props.onShowCheckDropDown}>
              <div className={`check ${this.props.check ? 'active' : ''}`} onClick={(event) => this.props.checkedAll(event)}></div>

              {this.props.showCheckDropDown &&
                <div className="dropdown-start" ref={this.props.container}>
                  <div className="dropdown-in-group" onClick={() => this.props.selectCheckDropDownItem('all')}>Select All in View</div>
                  <div className="dropdown-in-group" onClick={() => this.props.selectCheckDropDownItem('max')}>Select Max <strong className="ml-5"> ({this.props.maxAddCount})</strong></div>
                </div>
              }
            </div>
            {
              list.map((item, index)=>
                <div key={index} className="result-header__item">{
                  item.name
                }</div>
              )
            }
            <div className="result-header__item"></div>
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps)(HeaderResult);
