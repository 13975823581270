import React, { Component, Fragment } from 'react';
import './style.scss';
import { connect } from 'react-redux';

class Loader extends Component {
  render() {
    const { loader, uploading } = this.props
    return (
      <Fragment>
        {!uploading &&
          <div className={`${loader.signinLoading ? "signin-loader" : "loader-bg"}`}>
            <img src="/assest/accountBased/spinner.svg" alt="" />
          </div>
        }
        {uploading &&
          <div className="success-div-loader">
            <img src="/assest/accountBased/spinner.svg" alt="" />
          </div>
        }
      </Fragment>
    )
  }
}
function mapStateToProps(state) {
  return {
    loader: state.loader
  }
}
export default connect(mapStateToProps, null)(Loader);
