import React, {Component, Fragment} from 'react'
import {authIntegrations, updateIntegrations} from '../../../services/integrations'
import {toast} from 'react-toastify';
import $ from 'jquery';
import '../style.scss'
import { LOCAL_STORAGE_KEYS } from '../../../constants/constant';
import { showSuccessToast, showErrorToast } from '../../../helpers/toast';

class IntegrationPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            syncLeads: false,
            requireEmail: false,
            personalEmail: false,
            companyEmails: false,
            doubleVerified: false,
            activeIntegration: []
        }
    }

    componentDidMount = () => {
        const {activeIntegrations, nameForPopup} = this.props
        activeIntegrations.map(items => {
            if (items.name === nameForPopup) {
                this.setState({
                    syncLeads: items.auto_sync,
                    requireEmail: items.require_email,
                    personalEmail: items.include_personal_emails,
                    companyEmails: items.include_company_emails,
                    doubleVerified: items.include_double_verified_emails,
                })
            }
        })
    }

    changeCheck = (i) => {
        if (i === 1) {
            this.setState({companyEmails: !this.state.companyEmails})
        } else if (i === 2) {
            this.setState({personalEmail: !this.state.personalEmail})
        } else {
            this.setState({doubleVerified: !this.state.doubleVerified})
        }
    }

    changeSwitch = (i) => {
        if (i === 2) {
            this.setState({requireEmail: !this.state.requireEmail})
        } else {
            this.setState({syncLeads: !this.state.syncLeads})
        }
    }

    onConfirmClick = async () => {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        const {syncLeads, requireEmail, personalEmail, companyEmails, doubleVerified} = this.state
        const {nameForPopup, openIntegrationPopup} = this.props
        let toastId = null;
        toastId = toast('Redirecting to integration', {
            autoClose: 12000,
        });
        try {
            const res = await authIntegrations(user.id, nameForPopup, syncLeads,
                requireEmail,
                companyEmails,
                personalEmail,
                doubleVerified)
            window.location.href = res.data.data
            openIntegrationPopup("", "true")
            this.setState({
                syncLeads: false,
                requireEmail: false,
                personalEmail: false,
                companyEmails: false,
                doubleVerified: false
            })
        } catch (error) {
            showErrorToast(toastId, "Unable to turn on integration");
        }
    }
    onUpdateClick = async () => {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        const {syncLeads, requireEmail, personalEmail, companyEmails, doubleVerified} = this.state
        const {nameForPopup, openIntegrationPopup, integID, getIntegrations} = this.props
        let toastId = null;
        toastId = toast('Updating integration...', {
            autoClose: 15000,
        });
        try {
            await updateIntegrations(user.id, "", integID, nameForPopup, syncLeads,
                requireEmail,
                personalEmail,
                companyEmails,
                doubleVerified)
            showSuccessToast(toastId, "Integration updated successfully");
            openIntegrationPopup("", "true")
            this.setState({
                syncLeads: false,
                requireEmail: false,
                personalEmail: false,
                companyEmails: false,
                doubleVerified: false
            })
        } catch (error) {
            showErrorToast(toastId, "Unable to update integration");
        }
    }

    onClosePopup = () => {
        const {toggelIndex, openIntegrationPopup} = this.props
        openIntegrationPopup("close", "", "", toggelIndex)
    }

    render() {
        const {popup, nameForPopup, image, settingPopup, integrationLabel} = this.props
        const {syncLeads, requireEmail, doubleVerified, companyEmails, personalEmail} = this.state

        return (
            <Fragment>
                <div className={`add-list ${popup ? 'open' : ''}`}>
                    <div className={`add-list__content add-list__content-ed`}>
                        <img className="add-list__close" src="/assest/accountBased/close.svg" alt=""
                             onClick={this.onClosePopup}/>
                        <p className="add-list__title image-title">{integrationLabel} Integration <img className="integration-img" src={`/assest/integrations/${image}`} alt=""/></p>
                        <div className="popup-filters">
                            <div className="right-filters__data rm_border">
                                <div className={`switch ${syncLeads ? 'active' : ''}`}
                                     onClick={() => this.changeSwitch()}><span></span>
                                </div>
                                <p className="switch__name">SYNC LEADS AUTOMATICALLY?</p>
                            </div>
                            <div className="right-filters__data rm_border">
                                <div className={`switch ${requireEmail ? 'active' : ''}`}
                                     onClick={() => this.changeSwitch(2)}><span></span>
                                </div>
                                <p className="switch__name">REQUIRE EMAIL?</p>
                            </div>
                        </div>
                        <div className={`popup-animation ${requireEmail ? "animate" : ""}`}>
                            <div className="right-filters__check check-margin">
                                <div className={`check ${doubleVerified ? 'active' : ''}`}
                                     onClick={() => this.changeCheck()}></div>
                                <p>Double verified emails only</p>
                            </div>
                            <div className="right-filters__check check-margin">
                                <div className={`check ${companyEmails ? 'active' : ''}`}
                                     onClick={() => this.changeCheck(1)}></div>
                                <p>Include company emails</p>
                            </div>
                            <div className="right-filters__check check-margin">
                                <div className={`check ${personalEmail ? 'active' : ''}`}
                                     onClick={() => this.changeCheck(2)}></div>
                                <p>Include personal emails</p>
                            </div>
                        </div>

                        {!settingPopup &&
                        <div className="add-list__buttons">
                            <div className="add-list__btn" onClick={() => {
                                this.onConfirmClick()
                            }}>Continue
                            </div>
                            <div className="add-list__btn" onClick={this.onClosePopup}>Cancel</div>
                        </div>
                        }
                        {settingPopup &&
                        <div className="add-list__buttons">
                            <div className="add-list__btn" onClick={() => {
                                this.onUpdateClick()
                            }}>Update
                            </div>
                            <div className="add-list__btn" onClick={this.onClosePopup}>Cancel</div>
                        </div>
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default IntegrationPopup
