import $ from 'jquery';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { currentIgnoreListPageNum } from '../../actions/ignoreListFilter';
import { startLoader, stopLoader } from '../../actions/loader';
import { ConfirmationPopup, IgnoreListAdd, IgnoreListTable, Pagination, RightPanelBanner, BreadCrumb } from '../../components';
import NoList from '../../components/ApiKeysBox/NoApiKeys';
import items from '../../components/IgnoreList/IgnoreListTable/IgnoreListHeader/items';
import { addIgnoreList, deleteIgnoreList, getIgnoreLists } from '../../services/ignoreLists';
import './style.scss';
import { isValidEmail, isValidDomain } from '../../helpers/validate';
import { Redirect } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from '../../constants/constant';
import { EmitterService } from "../../services/emitterService";
import { showSuccessToast, showErrorToast } from '../../helpers/toast';

class IgnoreList extends Component {

    container = React.createRef();

    state = {
        showDropDownForView: false,
        company: '',
        domain: '',
        email: '',
        ignoreListItems: [],
        error: false,
        totalLists: 0,
        keyWord: "",
        showDropDown: false,
        sortOrder: false,
        source: '',
        addIgnoreListForm: false,
        data: '',
        selectedIDs: [],
        deSelectedIDs: [],
        selectAllFlag: false,
        selectInViewFlag: false,
        allChecked: false,
        deleteFlag: false,
        validate: { domain: false, email: false },
        currentPageNum: 1,
        recordsPerPage: 25,
        cursorsByPage: {1: ''},
        showConfirmation: false,
        selectedItemId: null,
        hasAllInputsEmpty: false
    }

    componentWillMount() {
        EmitterService.emit("loadLeadsRemaining");
    }

    componentDidMount = () => {
        let recordsPerPage = localStorage.getItem('recordsPerPage');
        if (recordsPerPage === null) {
            localStorage.setItem('recordsPerPage', '25');
            recordsPerPage = localStorage.getItem('recordsPerPage');
        }

        this.setState({recordsPerPage: recordsPerPage, currentPageNum: 1}, this.IgnoreLists);
        document.addEventListener("mousedown", this.clickEventHandle);
    }

    componentWillUnmount = () => {
        document.removeEventListener("mousedown", this.clickEventHandle)
        items.map(lists => {
            lists.order = ''
        })
    }

    clickEventHandle = (event) => {
        const { showDropDown, showDropDownForView } = this.state
        if (this.container.current && !this.container.current.contains(event.target)) {
            if (showDropDown) {
                this.paginationPopup()
            }
            else if (showDropDownForView && event.target.id !== "selectInView") {
                this.setState({ showDropDownForView: false })
            }
        }
    }

    IgnoreLists = async () => {
        const { startLoader, stopLoader } = this.props
        const { keyWord, sortOrder, source } = this.state
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
        let numberOfrecordsToShow = localStorage.getItem('recordsPerPage');

        try {
            startLoader(true);
            const currentCursor = this.state.cursorsByPage[this.state.currentPageNum];
            const res = await getIgnoreLists(userId.account_id, numberOfrecordsToShow, keyWord, sortOrder, source, currentCursor);
            const updateCursors = {
                ...this.state.cursorsByPage,
                [this.state.currentPageNum + 1]: res.data.data.next_cursor,
            }

            this.setState({
                ignoreListItems: res.data.data.ignore_lists,
                cursorsByPage: updateCursors,
                totalLists: res.data.data.total_number_of_ignore_lists,
                error: false
            });

            this.checkAll("nextPage");
        } catch (error) {
            this.setState({ error: true })
        }
        stopLoader(false);
    }

    getValue = (e) => {
        const name = e.target.name
        const value = e.target.value
        this.setState({ [name]: value })
    }

    sortList = async (index, name) => {
        let order = ''
        items.map(lists => {
            if (name === lists.name) {
                if (lists.order === "") {
                    order = "desc"
                } else if (lists.order === "asc") {
                    order = "desc"
                } else {
                    order = "asc"
                }
                lists.order = order
            } else {
                lists.order = ''
            }
        });

        const { sortOrder } = this.state;
        this.setState({  });

        if (index === 0) {
            this.setState({ source: "type" })
        } else if (index === 1) {
            this.setState({ source: "keyword" })
        } else if (index === 2) {
            this.setState({ source: "source" })
        } else if (index === 3) {
            this.setState({ source: "upload_date" })
        }

        this.setState({ sortOrder: !sortOrder, currentPageNum: 1 }, this.IgnoreLists);
    }

    onEnterPressed = (e) => {
        let code = e.keyCode || e.which;
        if (code === 13) {
            this.setState({ currentPageNum: 1 }, this.IgnoreLists);
        }
    }

    onKeywordChange = (e) => {
        if (e.target.value === "") {
            this.setState({ keyWord: e.target.value, SearchError: true });
        } else {
            this.setState({ keyWord: e.target.value });
        }
    }

    showAddIgnoreList = () => {
        // this.setState({ addIgnoreListForm: !this.state.addIgnoreListForm })
    }

    addIgnoreList = async (deleteLeads) => {
        const { company, domain, email } = this.state
        let items = []

        if (company || domain || email) {
            if (isValidDomain(domain)) {
                items.push({
                    "type": 1,
                    "keyword": domain
                })
            }
            if (company !== "") {
                items.push({
                    "type": 0,
                    "keyword": company
                })
            }
            if (isValidEmail(email)) {
                items.push({
                    "type": 2,
                    "keyword": email
                })
            }

            this.setState({
                validate: {
                    domain: domain && !isValidDomain(domain),
                    email: email && !isValidEmail(email),
                    inputError: !isValidDomain(domain) && !isValidEmail(email) && !company,
                },
                hasAllInputsEmpty: false
            }, () => {
                if (items.length > 0
                    && (!domain || isValidDomain(domain))
                    && (!email ||  isValidEmail(email))
                ) {
                    this.addingKeywordToIgnoreList(items, deleteLeads)
                }
            })
        } else {
            this.setState({
                hasAllInputsEmpty: true,
                validate: { domain: false, email: false }
            })
        }
    }

    validateFail = () => {
        this.setState({
            hasAllInputsEmpty: true,
            validate: { domain: false, email: false }
        })
    }

    addingKeywordToIgnoreList = async (items, deleteLeads) => {
        const { validate } = this.state
        if (!validate.company && !validate.email) {
            let errormsg = "Unable to add ignorelist"
            const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
            let toastId = null;
            toastId = toast('Adding keyword to ignore ist', {
                autoClose: 10000,
            });
            this.showAddIgnoreList()
            try {
                const res = await addIgnoreList(user.account_id, items, deleteLeads)
                this.setState({
                    company: '',
                    domain: '',
                    email: '',
                    companytype: '',
                    emailtype: '',
                    domaintype: '',
                    inputError: false,
                    validate: false
                })
                showSuccessToast(toastId, "Keyword added to ignore list successfully");
                this.IgnoreLists();
            } catch (error) {
                if (error.response) {
                    if (error.response.data.errCode === 14260) {
                        errormsg = 'Exceeded ignore list size limit.'
                    } else {
                        errormsg = 'Unable to add keywords to ignore list.'
                    }
                }
                showErrorToast(toastId, errormsg);
            }
        }
    }


    deleteIgnoreLists = async () => {
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        const { deSelectedIDs, selectedIDs, keyWord, selectAllFlag } = this.state
        const { currentIgnoreListPageNum } = this.props;
        let toastId = null;
        toastId = toast('Deleting keywords', {
            autoClose: 10000,
        });

        deleteIgnoreList(userId.account_id, selectedIDs, deSelectedIDs, keyWord, selectAllFlag).then(result => {
            currentIgnoreListPageNum(1);
            this.setState({ allChecked: false, selectAllFlag: false, selectInViewFlag: false, selectedIDs: [], currentPageNum: 1 });
            showSuccessToast(toastId, "Keywords deleted successfully");
        }, function (error) {
            showErrorToast(toastId, "Unable to delete keywords");
        })
    }

    deleteSingleIgnoreList = async (selectedItemId) => {
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        let toastId = toast('Deleting keyword', { autoClose: 10000 });

        try {
            await deleteIgnoreList(userId.account_id, [selectedItemId])
            showSuccessToast(toastId, "Keyword deleted successfully");
        } catch (error) {
            showErrorToast(toastId, "Unable to delete keyword");
        }
    }

    toggleSelect = () => {
        this.setState({ showDropDownForView: !this.state.showDropDownForView })
    }

    checkAll = (val) => {
        const { ignoreListItems, allChecked, selectInViewFlag, selectAllFlag, deSelectedIDs, selectedIDs } = this.state;

        if (val === 'all') {
            if (allChecked) {
                if (!selectAllFlag) {
                    this.setState({ selectAllFlag: !selectAllFlag, selectInViewFlag: !selectInViewFlag, selectedIDs: [], deSelectedIDs: [] })
                    for (let n = 0; ignoreListItems.length > n; n++) {
                        if (!ignoreListItems[n].check) {
                            ignoreListItems[n].check = !ignoreListItems[n].check
                        }
                    }
                } else {
                    this.setState({ selectAllFlag: !selectAllFlag, selectInViewFlag: false, selectedIDs: [], deSelectedIDs: [], allChecked: !allChecked })
                    for (let n = 0; ignoreListItems.length > n; n++) {
                        if (ignoreListItems[n].check) {
                            ignoreListItems[n].check = !ignoreListItems[n].check
                        }
                    }
                }
            } else {
                this.setState({ selectAllFlag: !this.state.selectAllFlag, selectInViewFlag: false, selectedIDs: [], deSelectedIDs: [], allChecked: !allChecked })
                for (let n = 0; ignoreListItems.length > n; n++) {
                    if (!ignoreListItems[n].check) {
                        ignoreListItems[n].check = !ignoreListItems[n].check
                    }
                    this.setState({ ignoreListItems: ignoreListItems })
                }
            }
        } else if (val === 'nextPage') {
            let deleteButton = false;
            if (selectedIDs.length > 0) {
                selectedIDs.map(item => {
                    ignoreListItems.map(list => {
                        if (item === list.id) {
                            deleteButton = true
                        }
                    })
                });
                this.setState({ deleteFlag: deleteButton });
            } else {
                this.setState({ deleteFlag: deleteButton });
            }

            if (selectAllFlag) {
                this.setState({ selectInViewFlag: false })
                for (let n = 0; ignoreListItems.length > n; n++) {
                    let deSel = false
                    deSelectedIDs.forEach((item) => {
                        if (ignoreListItems[n].id === item) {
                            deSel = true
                        }
                    })
                    if (!ignoreListItems[n].check && !deSel) {
                        ignoreListItems[n].check = !ignoreListItems[n].check
                    }
                }
            } else {
                this.setState({ selectInViewFlag: false, })
                selectedIDs.forEach((item) => {
                    for (let n = 0; n < ignoreListItems.length; n++) {
                        if (item === ignoreListItems[n].id) {
                            ignoreListItems[n].check = !ignoreListItems[n].check
                        }
                    }
                })
            }
        } else if (val === 'inView') {
            let selectedIDsInView = []
            if (!selectInViewFlag) {
                this.setState({ selectAllFlag: false, allChecked: !allChecked, selectInViewFlag: !this.state.selectInViewFlag })
                for (let n = 0; ignoreListItems.length > n; n++) {
                    if (!ignoreListItems[n].check) {
                        ignoreListItems[n].check = !ignoreListItems[n].check
                        selectedIDsInView = selectedIDsInView.concat(ignoreListItems[n].id)
                    }
                }
                this.setState({ selectedIDs: selectedIDs.concat(selectedIDsInView) })
            } else {
                this.setState({ allChecked: false, selectInViewFlag: !selectInViewFlag, selectAllFlag: false })
                for (let n = 0; ignoreListItems.length > n; n++) {
                    if (ignoreListItems[n].check) {
                        ignoreListItems[n].check = !ignoreListItems[n].check
                    }
                }
                this.setState({ selectedIDs: [] })
            }
        }
        else {
            let selectedIDsInView = []
            if (allChecked) {
                if (selectAllFlag) {
                    this.setState({ selectAllFlag: !selectAllFlag, allChecked: !allChecked })
                    for (let n = 0; ignoreListItems.length > n; n++) {
                        if (ignoreListItems[n].check) {
                            ignoreListItems[n].check = !ignoreListItems[n].check
                        }
                    }
                } else {
                    this.setState({ deleteFlag: false, selectedIDs: [], allChecked: !allChecked, selectInViewFlag: !this.state.selectInViewFlag })
                    for (let n = 0; ignoreListItems.length > n; n++) {
                        if (ignoreListItems[n].check) {
                            ignoreListItems[n].check = !ignoreListItems[n].check;
                        }
                    }
                }
            }
            else {
                this.setState({ allChecked: !allChecked, selectAllFlag: false, selectInViewFlag: !this.state.selectInViewFlag })
                for (let n = 0; ignoreListItems.length > n; n++) {
                    if (!ignoreListItems[n].check) {
                        ignoreListItems[n].check = !ignoreListItems[n].check
                    }
                    selectedIDsInView = selectedIDsInView.concat(ignoreListItems[n].id)
                }
                this.setState({ selectedIDs: selectedIDs.concat(selectedIDsInView), deleteFlag: !true })
            }
        }
    }

    changeCheck = (i, id) => {
        const { ignoreListItems, selectedIDs, deSelectedIDs, selectAllFlag } = this.state
        ignoreListItems[i].check = !ignoreListItems[i].check;
        this.setState({ ignoreListItems: ignoreListItems })
        let newCol, deSelectCol
        if (ignoreListItems[i].check) {
            this.setState({ selectedIDs: selectedIDs.concat(id) })
            deSelectCol = deSelectedIDs.filter((el) => el !== id)
            this.setState({ deSelectedIDs: deSelectCol, deleteFlag: true })
        }
        else {
            if (selectAllFlag) {
                this.setState({ deSelectedIDs: deSelectedIDs.concat(id) })
            }
            newCol = selectedIDs.filter((el) => el !== id)
            if (newCol.length > 0) {
                this.setState({ selectedIDs: newCol, deleteFlag: true })
            } else {
                this.setState({ selectedIDs: newCol, deleteFlag: false })
            }
        }
    }

    paginationPopup = () => {
        this.setState({ showDropDown: !this.state.showDropDown })
    }

    paginate = async (num) => {
        window.scrollTo(0, 0);
        const { selectInViewFlag } = this.state
        const { currentIgnoreListPageNum, ignoreListFilter } = this.props;

        await currentIgnoreListPageNum(num);

        if (selectInViewFlag) {
            this.setState({ selectInViewFlag: false, allChecked: false })
        }

        if (num < ignoreListFilter.pageNum || num > ignoreListFilter.pageNum) {
            this.setState({ currentPageNum: num }, this.IgnoreLists);
        }
    }

    incOrDecNum = (sign, indexOfLastPage) => {
        const { ignoreListFilter } = this.props
        let currentPage = ignoreListFilter.pageNum
        if (sign === "-" && currentPage > 1) {
            currentPage = currentPage - 1
            this.paginate(currentPage)
        } else if (sign === "+" && currentPage < indexOfLastPage) {
            currentPage = currentPage + 1
            this.paginate(currentPage)
        }
    }

    updatePerPage = async (sign, num) => {
        let integer = parseInt(num);

        if (sign === "+" && integer < 100) {
            this.setState({recordsPerPage: integer + 25, currentPageNum: 1, cursorsByPage: {1: ''}}, this.IgnoreLists);
            localStorage.setItem('recordsPerPage', integer + 25);
        } else if (sign === "-" && integer > 25) {
            this.setState({recordsPerPage: integer - 25, currentPageNum: 1, cursorsByPage: {1: ''}}, this.IgnoreLists);
            localStorage.setItem('recordsPerPage', integer - 25);
        } else if (sign === '') {
            this.setState({ showDropDown: !this.state.showDropDown });
            this.setState({recordsPerPage: integer, currentPageNum: 1, cursorsByPage: {1: ''}}, this.IgnoreLists);
            localStorage.setItem('recordsPerPage', integer);
        }
    }

    uploadIgnoreList = () => {
        // this.setState({ addIgnoreListForm: false, currentPageNum: 1, cursorsByPage: {1: ''} }, this.IgnoreLists);
        this.setState({ currentPageNum: 1, cursorsByPage: {1: ''} }, this.IgnoreLists);
    }

    render() {
        const { company,
            domain,
            email,
            ignoreListItems,
            error,
            addIgnoreListForm,
            allChecked,
            totalLists,
            showDropDown,
            showDropDownForView,
            selectInViewFlag,
            selectAllFlag,
            selectedIDs,
            keyWord,
            SearchError,
            deleteFlag,
            validate } = this.state;

        const { loader, history } = this.props;
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
        const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT));

        const links = [
            {
                name: 'Lead Search',
                slug: '/lead-search/market-based',
            },
            {
                name: 'Ignore List',
            }
        ]

        return (
            <Fragment>
                <BreadCrumb links={links}></BreadCrumb>
                <div className="ignore-panel">
                    {userId === null && <Redirect to={'/signin?path=/ignore-list'} /> }

                    {userAccount !== null && userAccount.status === "trial_expired" && history.push('/billing-and-users')}

                    <div className="ignore-panel__left">

                        <div className="markbased__table markbased__table_key">

                            <IgnoreListAdd
                                company={company}
                                domain={domain}
                                addIgnoreListForm={addIgnoreListForm}
                                email={email}
                                getValue={this.getValue}
                                addIgnoreList={this.addIgnoreList}
                                showAddIgnoreList={this.showAddIgnoreList}
                                IgnoreLists={this.IgnoreLists}
                                validate={validate}
                                uploadIgnoreList={this.uploadIgnoreList}
                                hasAllInputsEmpty={this.state.hasAllInputsEmpty}
                                validateFail={this.validateFail}
                            />

                            <IgnoreListTable
                                table={ignoreListItems}
                                error={error}
                                numberOfSelectedIDs={selectedIDs.length}
                                onEnterPressed={this.onEnterPressed}
                                onKeywordChange={this.onKeywordChange}
                                sortList={this.sortList}
                                IgnoreLists={this.IgnoreLists}
                                showAddIgnoreList={this.showAddIgnoreList}
                                addIgnoreListForm={addIgnoreListForm}
                                changeCheck={this.changeCheck}
                                checkAll={this.checkAll}
                                allChecked={allChecked}
                                deleteIgnoreLists={this.deleteIgnoreLists}
                                deleteSingleIgnoreList={this.deleteSingleIgnoreList}
                                toggleSelect={this.toggleSelect}
                                showDropDownForView={showDropDownForView}
                                selectInViewFlag={selectInViewFlag}
                                selectAllFlag={selectAllFlag}
                                tableDataLength={ignoreListItems.length}
                                deleteFlag={deleteFlag}
                            />
                        </div>

                        <div>
                            {!loader.loading && (!ignoreListItems.length && !error && !SearchError && !keyWord) &&
                            <NoList
                                msg={"You haven't added any keywords yet."}
                                submsg={`Click on the "+Add to Ignore List" to add an item to your ignore list.`} />
                            }
                            {!loader.loading && (!ignoreListItems.length && (keyWord || SearchError)) &&
                            <NoList
                                msg={"We found 0 matching keywords. "}
                                submsg={`Please try changing your search criteria.`} />
                            }
                        </div>

                        {ignoreListItems && ignoreListItems.length > 0 &&
                        <Pagination totalLists={totalLists}
                                    recordsPerPage={this.state.recordsPerPage}
                                    noPageNumbers={true}
                                    restrictJumping={true}
                                    currentPageNum={this.state.currentPageNum}
                                    paginate={this.paginate}
                                    incOrDecNum={this.incOrDecNum}
                                    updatePerPage={this.updatePerPage}
                                    paginationPopup={this.paginationPopup}
                                    showDropDown={showDropDown}
                                    container={this.container} />
                        }
                    </div>

                    <div className="ignore-panel__right">
                        <RightPanelBanner title={'Ignore List Demo'}  page="ignoreList" type="ignore_list" video_id="t16lq6v74f"/>
                    </div>

                </div>
            </Fragment>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        startLoader,
        stopLoader,
        currentIgnoreListPageNum
    }, dispatch);
}
function mapStateToProps(state) {
    return {
        loader: state.loader,
        user: state.user,
        ignoreListFilter: state.ignoreListFilter
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IgnoreList)
