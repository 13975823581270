import React, { Component } from 'react'
import IgnoreListHeader from './IgnoreListHeader'
import IgnoreListContent from './IgnoreListContent'
import '../style.scss'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loader from '../../Loader'
import { ConfirmationPopup } from '../..'
class IgnoreListTable extends Component {
  state = {
    showBulkDeleteConfirmation: false
  }

  showBulkDeleteConfirmationPopup = () => {
    this.setState({
      showBulkDeleteConfirmation: true
    })
  }

  closeBulkDeleteConfirmationPopup = () => {
    this.setState({
      showBulkDeleteConfirmation: false
    })
  }

  render() {
    const {
      table,
      error,
      onKeywordChange,
      sortList,
      IgnoreLists,
      changeCheck,
      checkAll,
      allChecked,
      deleteIgnoreLists,
      showDropDownForView,
      selectInViewFlag,
      selectAllFlag,
      onEnterPressed,
      numberOfSelectedIDs,
      tableDataLength,
      toggleSelect,
      deleteFlag,
      loader
    } = this.props

    return (
      <>
        <section className="ignore">
          <div className="ignore__title">
            <h3>Ignore List</h3>
            <div className="ignore__links">

              {/* <p onClick={showAddIgnoreList}>
                <Button>{addIgnoreListForm ? <img src="/assest/accountBased/del-tag.svg" alt="" /> : <img src="/assest/accountBased/plus-orange.svg" alt="" />} Add to Ignore List</Button>
              </p> */}

              {(selectInViewFlag || deleteFlag || selectAllFlag) &&
                <p onClick={this.showBulkDeleteConfirmationPopup} >
                  <Button><img src="/assest/accountBased/del-tag.svg" alt="" /> Delete selected</Button>
                </p>
              }
            </div>
          </div>
          <div className="ignore-search">
            <div className="ignore-search__inp">
              <img src="/assest/accountBased/search.svg" alt="" />
              <input type="text" onChange={onKeywordChange} onKeyPress={onEnterPressed} placeholder="Search Company/Domain name/Email address" />
              <p>Press <strong> Enter </strong> to search</p>
            </div>
          </div>
          <div className="ignore-table  lists_table_loader">
            <IgnoreListHeader error={error}
              toggleSelect={toggleSelect}
              tableDataLength={tableDataLength}
              sortList={sortList}
              checkAll={checkAll}
              allChecked={allChecked}
              showDropDownForView={showDropDownForView}
              selectInViewFlag={selectInViewFlag}
              selectAllFlag={selectAllFlag}
              numberOfSelectedIDs={numberOfSelectedIDs} />

            {loader.loading && <Loader />}

            <IgnoreListContent table={table}
              error={error}
              changeCheck={changeCheck}
              IgnoreLists={IgnoreLists}
              onDelete={this.props.deleteSingleIgnoreList} />
          </div>
        </section>
        {
          this.state.showBulkDeleteConfirmation &&
          <ConfirmationPopup
            title="Bulk Delete Ignore List"
            message="ARE YOU SURE YOU WANT TO DELETE THESE RECORDS?"
            onYesClick={() => {
              deleteIgnoreLists()
              this.closeBulkDeleteConfirmationPopup()
            }}
            onNoClick={this.closeBulkDeleteConfirmationPopup}
            onCloseClick={this.closeBulkDeleteConfirmationPopup}
          />
        }
      </>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({

  }, dispatch)
}
function mapStateToProps(state) {
  return {
    loader: state.loader
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(IgnoreListTable)
