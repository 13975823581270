import React, { Component, Fragment } from 'react'
import CardView from './CardView'
import './style.scss'
import AddNewCard from './AddNewCard'
import { addCard, updateCard } from '../../../../services/billing'
import { toast } from 'react-toastify'
import { EmitterService } from '../../../../services/emitterService'

export const ViewMode = {
  ViewCard: 'card',  
  NewCard: 'add',  
  EditCard: 'edit',  
}
class BillingCards extends Component{
  state = {
    viewMode: ViewMode.ViewCard,
    currentCardId: '',
  }

  addNewCard() {
    this.setState({viewMode: ViewMode.NewCard});
  }

  editCard() {
    if (this.getCurrentCard()) {
      this.setState({viewMode: ViewMode.EditCard});
    }
  }

  onNewCardSave =  async(number, cvc, brand, exp_month, exp_year) => {
    const toastId = toast('Updating Card', { autoClose: 10000 });
    try{
      const addCardRes = await addCard(number, cvc, brand, exp_month, exp_year);
      EmitterService.emit("loadBillingOverview");
      setTimeout(() => {
        toast.update(toastId, {
          className: "toast-success-div",
          render: "Card successfully updated",
          type: toast.TYPE.SUCCESS,
          autoClose: 1300,
        });
      }, 1000);
      this.onCancel();
    }
    catch(e) {
      setTimeout(() => {
        toast.update(toastId, {
            className: "toast-error-div",
            render: "Card not added",
            type: toast.TYPE.ERROR,
            autoClose: 1300,
        });
      }, 200);
    }
  }

  getCurrentCard = () => {
    return this.props.billingOverview.card;
  }

  onCardUpdate = async (id, exp_month, exp_year) => {
    const toastId = toast('Updating Card', { autoClose: 10000 });
    const updateCardRes = await updateCard(id, exp_month, exp_year);
    EmitterService.emit("loadBillingOverview");
    setTimeout(() => {
      toast.update(toastId, {
        className: "toast-success-div",
        render: "Card successfully updated",
        type: toast.TYPE.SUCCESS,
        autoClose: 1300,
      });
    }, 1000);
    this.onCancel();
  }

  onCancel = () => {
    this.setState({viewMode: ViewMode.ViewCard});
  }

  getHeading() {
    if (this.state.viewMode === ViewMode.NewCard) {
      return 'Add New Card';
    } else if (this.state.viewMode === ViewMode.EditCard) {
      return 'Edit Card';
    }
    return 'Saved Card'
  }
  render(){
    return(
      <Fragment>
        <h3 className="mt-20 fw-500">{this.getHeading()}</h3>
        {this.state.viewMode === ViewMode.ViewCard &&
          <CardView currentCard={this.getCurrentCard()}/>
        }
        {this.state.viewMode !== ViewMode.ViewCard &&
          <AddNewCard viewMode={this.state.viewMode} cardId={this.state.currentCardId}
          onCardUpdate={this.onCardUpdate}
          onNewCardSave={this.onNewCardSave}
          onCancel={this.onCancel}
          currentCard={this.getCurrentCard()}
          />
        }

        {this.state.viewMode === ViewMode.ViewCard &&
        <div className="billing-cards__actions" >
          <p className="billing-cards__add-card" onClick={() => this.addNewCard()}>Change Card</p>
        </div>
        }
      </Fragment>
    )
  }
}

export default BillingCards