import React, {Component, Fragment} from 'react';
import Popup from "reactjs-popup";
import AddLeadsToList from './AddLeadsToList';
import ExportToCSV from './ExportToCSV';
import ExportToGoogleSheet from './ExportToGoogle';
import DeleteList from './DeleteList';
import PauseResume from './PauseResume';
import EditListName from './EditListName';
import DuxSoup from './DuxSoup';
import Woodpecker from './Woodpecker';
import Lemlist from './Lemlist';
import MailShake from './MailShake';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getLemlist, getMailShake, getWoodpecker, initSpreedSheet, getIntegrationCampaigns, getJazzhrJobs, getUsersSheet} from '../../../services/leads';
import {startLoader, stopLoader} from '../../../actions/loader';
import SavedSearchesItemExpanded from '../../SavedSearchesBox/SavedSearchesContent/SavedSearchesItemExpanded';
import classNames from 'classnames';
import {FUZEBOT_STATUSES, LOCAL_STORAGE_KEYS} from '../../../constants/constant';
import get from 'lodash/get'
import {PopoutResult} from '../../PopoutResult/PopoutResult';
import {numberFormatter} from '../../../constants/helper';
import noop from 'lodash/noop';
import {toast} from 'react-toastify';
import $ from 'jquery';
import { showSuccessToast, showErrorToast } from '../../../helpers/toast';

import { openPopup, closePopup } from '../../../actions/loader';


const duxsoup = "duxsoup"
const woodpecker = "woodpecker"
const lemlist = "lemlist"
const reply = "reply"
const highlevel = "highlevel"

const jazzhr = "jazzhr"
const salesforce = "salesforce"
const hubspot = "hubspot"
const freshsales = "freshsales"
const closeio = "close-io"
const bidsketch = "bidsketch"
const pipedrive = "pipedrive"
const salesflare = "salesflare"
const mailshake = "mailshake"

const editlist = "editlist"
const editleads = "editleads"
const exportcsv = "exportcsv"
const googlesheet = "googlesheet"
const deletelist = "deletelist"
const pauseresume = "pauseresume"

const INTEGRATION_TO_LABEL_MAP = {
    [duxsoup]: {
        label: "Dux-Soup",
        icon: '/assest/integrations/duxsoup-logo.png'
    },
    [woodpecker]: {
        label: "Woodpecker",
        icon: "/assest/integrations/woodpecker-logo.ico"
    },
    [lemlist]: {
        label: "lemlist",
        icon: "/assest/integrations/lemlist-logo.png"
    },
    [reply]: {
        label: "Reply",
        icon: "/assest/integrations/reply-logo.png"
    },
    [highlevel]: {
        label: "HighLevel",
        icon: "/assest/integrations/highlevel-logo.jpg"

    },
    [jazzhr]: {
        label: "Jazz Hr",
        icon: "/assest/integrations/jazzhr-logo.png"
    },
    [salesforce]: {
        label: "Salesforce",
        icon: "/assest/integrations/salesforce-logo.ico"
    },
    [hubspot]: {
        label: "Hubspot",
        icon: "/assest/integrations/hubspot-logo.png"
    },
    [freshsales]: {
        label: "Freshsales",
        icon: "/assest/integrations/freshsales-logo.png"
    },
    [closeio]: {
        label: "CloseIo",
        icon: "/assest/integrations/close-io.png"
    },
    [bidsketch]: {
        label: "Bidsketch",
        icon: "/assest/integrations/bidsketch-logo.ico"
    },
    [pipedrive]: {
        label: "Pipedrive",
        icon: "/assest/integrations/pipedrive-logo.png"
    },
    [salesflare]: {
        label: "Salesflare",
        icon: "/assest/integrations/salesflare-logo.png"
    },
    [mailshake]: {
        label: "Mailshake",
        icon: "/assest/integrations/mailshake-logo.png"
    },
}

const INTEGRATIONS = [
    duxsoup,
    woodpecker,
    mailshake,
    lemlist,
    reply,
    jazzhr,
    highlevel,
    // salesforce,
    // hubspot,
    // freshsales,
    // closeio,
    // bidsketch,
    // pipedrive,
    // salesflare,
]

var moment = require('moment');

class LeadListsContent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            woodpeckerCampaigns: [],
            lemlistCampaigns: [],
            replyCampaigns: [],
            highlevelCampaigns: [],
            jazzhrCampaigns: [],
            mailshakeCampaigns: [],
            listID: '',
            userID: '',
            itemsName: '',
            addedLeadsPerDay: 0,
            isActive: 1,
            sheetID: '',
            error: 0,
            listData: {},
            selectedLeadListId: -1,
            list: {},
            sheetNames: [],
            connection_message: "",
            followup_message: "",
            windowWidth: window.innerWidth,
            isMobile : window.innerWidth < 900,
            sheetLoading: false,
        }
        this.OpenIntegration = this.OpenIntegration.bind(this);

    }

    componentWillMount() {
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        
        if(this.doesIntegrationExist("googlesheets")) {
            // /this.setState({sheetLoading: true});
            // getUsersSheet(userId).then( (response) => {
            //     if (response.data.data && response.data.data.length) {
            //         this.setState({ sheetNames: response.data.data, sheetLoading: false })
            //     }
            // }).catch( (error) => {
            //     const toastId = toast('Unable to load Google Sheets', {
            //         className: "toast-error-div",
            //         type: toast.TYPE.ERROR
            //     });
            //     toast.done(toastId)
            //     $(".Toastify").addClass("mes-done");
            //     setTimeout(function () {
            //         toast.dismiss(toastId)
            //         $(".Toastify").removeClass("mes-done");
            //     }, 3000)
            //     this.setState({sheetLoading: false});
            // });
        }
    
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    doesIntegrationExist = integrationName => {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        const foundIntegration = user.integrations.find(
          integration => integration.name === integrationName
        );
        return !!foundIntegration;
    };

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth, isMobile : window.innerWidth < 900 });
    };

    fuzebotStatus(item) {
        let status = FUZEBOT_STATUSES.RUNNING;
        if (item.fuzebot === null) {
            status = FUZEBOT_STATUSES.NOT_AVAILABLE;
        } else if (item.fuzebot.id !== "" && item.fuzebot.status === 1) {
            status = FUZEBOT_STATUSES.PAUSED;
        }
        return status;
    }

    isFuzebotStatusPaused(item) {
        let status = false;
        if (item.fuzebot && item.fuzebot.id !== "" && item.fuzebot.status === 1) {
            status = true;
        }
        return status;
    }

    openSheets = async (toastId) => {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        try {
            const res = await initSpreedSheet(user, "", "/integrations", "googlesheets", false, false,
                false, false, false)
            window.location.href = res.data.data
        } catch (error) {
            showErrorToast(toastId, "Unable to turn on integration");
        }
    }

    openPopups = (name, items, userId) => {
        const {
            openEditListPopup,
            openCsvPopup,
            openDeletePopup,
            openExpGooglePopup,
            openListPopup,
            openPauseResumePopup
        } = this.props
        this.setState({
            userID: userId, listID: items.id, itemsName: items.name, sheetID: items.integrations_info, listData: items,
            addedLeadsPerDay: items.fuzebot ? items.fuzebot.daily_leads : 0
        })
        switch (name) {
            case editlist:
                openEditListPopup()
                return true
            case editleads:
                openListPopup()
                return true
            case exportcsv:
                openCsvPopup()
                return true
            case googlesheet:
                openExpGooglePopup();
                return;
                if (this.props.googleSheetIntegrated) {
                    openExpGooglePopup()
                } else {
                    let toastId = null;
                    toastId = toast('Redirecting to integration', {
                        autoClose: 12000,
                    });
                    setTimeout(() => {
                        this.openSheets(toastId)
                    }, 500)
                }
                return true
            case deletelist:
                openDeletePopup()
                return true
            case pauseresume:
                if (items.fuzebot.status) {
                    this.setState({isActive: 1})
                } else
                    this.setState({isActive: 0})
                openPauseResumePopup()
                return true
            default:
                return false
        }
    }

    OpenIntegration = async (name, item) => {
        this.setState({listID: item.id, list: item})
        var that = this
        const {
            integration, openWoodpeckerPopup, openLemlistPopup, openMailshakePopup, openDuxSoupPopup, openReplyPopup,openHighLevelPopup, openJazzhrPopup,
        } = this.props
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        if (integration.integration.data) {
            switch (name) {
                case woodpecker:
                    
                    // this.setState({woodpeckerCampaigns: 
                    //     [
                    //         {"id":1, "title":"ABC"}, 
                    //         {"id":1, "title":"BCD"}
                    //     ]
                    // })
                    // await getIntegrationCampaigns('woodpecker').then( (response) => {
                    //     this.setState({woodpeckerCampaigns: response.data.data})
                    // });
                    openWoodpeckerPopup()
                    // getWoodpecker(userId.id).then(function (response) {
                    //     that.setState({woodpeckerCampaigns: response})
                    // });
                    return true
                case lemlist:
                    
                    // this.setState({lemlistCampaigns: 
                    //     [
                    //         {"_id": "a", "name":"ABC"}, 
                    //         {"_id": "b", "name":"BCD"}
                    //     ]
                    // })
                    // await getIntegrationCampaigns('lemlist').then( (response) => {
                    //     this.setState({lemlistCampaigns: response.data.data})
                    // });
                    openLemlistPopup()
                    // getLemlist(userId.id).then(function (response) {
                    //     if (response.data.data) {
                    //         that.setState({lemlistCampaigns: response.data.data})
                    //     } else {
                    //         that.setState({error: response.data.errCode})
                    //     }
                    // });
                    return true
                case reply:
                    
                    // this.setState({replyCampaigns: 
                    //     [
                    //         {"id":1, "name":"ABC"}, 
                    //         {"id":1, "name":"BCD"}
                    //     ]
                    // })
                    // await getIntegrationCampaigns('reply').then( (response) => {
                    //     this.setState({replyCampaigns: response.data.data})
                    // });
                    openReplyPopup();
                    return true;
                case highlevel:
                    
 
                    openHighLevelPopup();
                    return true;
                case jazzhr:
                    
                    // this.setState({jazzhrCampaigns: 
                    //     [
                    //         {"id":1, "title":"ABC"}, 
                    //         {"id":1, "title":"BCD"}
                    //     ]
                    // })
                    // await getJazzhrJobs().then( (response) => {
                    //     this.setState({replyCampaigns: response.data.data})
                    // });
                    openJazzhrPopup();
                    return true
                case mailshake:
                    
                    // this.setState({mailshakeCampaigns: 
                    //     [
                    //         {"id":1, "title":"ABC"}, 
                    //         {"id":1, "title":"BCD"}
                    //     ]
                    // })
                    // await getIntegrationCampaigns('mailshake').then( (response) => {
                    //     this.setState({mailshakeCampaigns: response.data.data})
                    // });
                    openMailshakePopup()
                    // getMailShake(userId.id).then(function (response) {
                    //     that.setState({mailshakeCampaigns: response.data})
                    // });
                    return true
                case duxsoup:
                    if(item.integrations_info.duxsoup_info) {
                        this.setState({
                            connection_message: item.integrations_info.duxsoup_info.connection_message,
                            followup_message: item.integrations_info.duxsoup_info.followup_message,
                        })
                    }
                    openDuxSoupPopup()
                    // getMailShake(userId.id).then(function (response) {
                    //     that.setState({mailshakeCampaigns: response.data})
                    // });
                    return true
                default:
                    this.props.integrateWithSelected(item.id, name);
                    return true
            }
        }
    }

    getFuzebotStatus = (leadList) => {
        if (leadList.fuzebot) {
            if (leadList.fuzebot.status === 0) {
                return 'Pause';
            }
            return 'Resume';
        }
        return 'Pause/Resume';
    }

    selectLeadList = (id) => {
        if(id != -1) {
            this.props.openPopup();
        }
        else {
            this.props.closePopup();
        }
        this.setState({selectedLeadListId: id});
    }
    getFuzebotLeadProgress = (lead) => {
        if(lead.fuzebot.total_leads == 0)
            return 0;
        // NOTE: if no leads - then we loaded everyone
        if (get(lead, 'fuzebot.total_leads', 0) === 0) {
            return 0
        }
        return (100 * (lead.fuzebot.remaining_leads)) / lead.fuzebot.total_leads;
    }

    doesIntegrationExist = (integrationName) => {
        const foundIntegration = this.props.integrations.find(integration => integration.name === integrationName);
        return !!foundIntegration;
    }

    getNameById = (id) => {
        const found = this.props.items.find(item => item.id === id);
        if (found) {
            return found.name;
        }
        return '';
    }

    openMenuPopup = (id, index) => {
        this.setState({
            selectedPopupItemId: id
        })
        this.props.setSelectedPopupItem(id, index);
    }

    closeMenuPopup = () => {
        this.setState({
            selectedPopupItemId: null
        })
        this.props.setSelectedPopupItem(null, null);
    }

    handleMenuSelection = (cb = noop) => {
        return () => {
            this.closeMenuPopup()
            cb()
        }
    }


    getNameOrEmailOfOwner = (item) => {
        let name = '';
        if (item.owner_info && item.owner_info.length) {
            name = item.owner_info[0].first_name + ' ' + item.owner_info[0].last_name;
            if (!name.trim()) {
                name = item.owner_info[0].email;
            }
        }
        return name;
    }

    render() {
        const {
            popup,
            popupCSV,
            popupLemlist,
            openLemlistPopup,
            openJazzhrPopup,
            openReplyPopup,
            openHighLevelPopup,
            popupMailshake,
            openMailshakePopup,
            popupWoodpecker,
            popupReply,
            popupHighLevel,
            popupJazzhr,
            openWoodpeckerPopup,
            openListPopup,
            openPauseResumePopup,
            popupPauseResume,
            popupEditList,
            popupDuxSoup,
            openDuxSoupPopup,
            popupDelete,
            leadList,
            items,
            openEditListPopup,
            openCsvPopup,
            openDeletePopup,
            openExpGooglePopup,
            popupExpGoogle,
            updateListItem
        } = this.props;
        const {mailshakeCampaigns, woodpeckerCampaigns, lemlistCampaigns, jazzhrCampaigns, replyCampaigns,highlevelCampaigns, listID, userID, itemsName, isActive, sheetID, listData, list, connection_message, followup_message} = this.state
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        return (
            <Fragment>
                {items &&
                items.map((item,index) =>
                    <PopoutResult
                        key={item.id}
                        isActive={item.id === this.state.selectedLeadListId}
                        onClose={() => this.selectLeadList(-1)}
                    >
                        <div className="lead-cont" onClick={()=> {}/*() => this.props.showLeads(item)*/}>
                            <div className="lead-cont__list">
                                <div className="lead-cont-item" onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.showLeads(item);
                                    }}>
                                    <p className="lead-cont-item__title">{item.name}</p>
                                    <p className="lead-cont-item__name">{this.getNameOrEmailOfOwner(item)}</p>
                                    {/* <p className="lead-cont-item__name">{user.first_name} {user.last_name}</p> */}
                                </div>
                                <div className="lead-cont-item" onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.showLeads(item);
                                    }}>
                                    <p className="lead-cont-item__total">{item.total_leads} leads {item.new_leads > 0 &&
                                    <span>{item.new_leads} new</span>}</p>
                                    <p className="lead-cont-item__date">{moment(item.last_added_date).format("MM.DD.YYYY")} by {item.last_added_by}</p>
                                </div>
                                <div className="lead-cont-item" onClick={(e) => {
                                        e.stopPropagation();
                                        this.selectLeadList(item.id);
                                    }}>
                                    <div>
                                        <p className={classNames('lead-cont-item__status', {
                                            'lead-cont-item__status_paused': this.fuzebotStatus(item) === FUZEBOT_STATUSES.PAUSED,
                                            'lead-cont-item__status_running': this.fuzebotStatus(item) === FUZEBOT_STATUSES.RUNNING,
                                            'lead-cont-item__status_n-a': this.fuzebotStatus(item) === FUZEBOT_STATUSES.NOT_AVAILABLE,
                                        })}>
                                            {this.fuzebotStatus(item)}
                                        </p>
                                        {item.fuzebot &&
                                        <p className="lead-cont-item__day">{item.fuzebot.daily_leads} each day</p>
                                        }
                                    </div>
                                </div>
                                <div className="lead-cont-item" onClick={(e) => {
                                        e.stopPropagation();
                                        this.selectLeadList(item.id);
                                    }}>
                                    {item.fuzebot &&
                                    <div>
                                        <p className="lead-cont-item__leads">{numberFormatter.format(item.fuzebot.remaining_leads)} leads</p>
                                        <div className={`lead-cont-item__line ${this.getFuzebotLeadProgress(item) >= 70 ? 'greenColor' : this.getFuzebotLeadProgress(item) >= 30 ? 'orange-bar' : 'red-bar' }`}>
                                            <div style={{width: `${this.getFuzebotLeadProgress(item)}%`}}>
                                                {/* <div style={{ width: `${item.fuzebot.remaining_leads}%` }}> */}
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {!item.fuzebot &&
                                    <div>
                                        <p className="lead-cont-item__leads">N/A</p>
                                    </div>
                                    }
                                    <div></div>
                                </div>
                                <div className="lead-cont-item show_leads_col" >
                                    {/* <div className="lead-cont-item__show_overlap" onClick={(e) => {
                                        e.stopPropagation();
                                        this.selectLeadList(item.id);
                                    }}></div> */}
                                    <p className="lead-cont-item__show" onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.showLeads(item);
                                    }}>Show Leads</p>
                                    
                                    {/* <div className="lead-cont-item__icon">
                      <img src="/assest/accountBased/green-icon.svg" alt="" />
                      </div> */}

                                </div>
                                <div className="lead-cont-item" style={{opacity: 1}}>
                                    <div
                                        className="menu"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Popup
                                            trigger={
                                                <ul
                                                    className='lead-cont-item__dots'
                                                    onClick={() => this.openMenuPopup(item.id, index)}
                                                >
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                </ul>
                                            }
                                            onClose={this.closeMenuPopup}
                                            // NOTE: turning off all events so we can trigger open manually
                                            on={[]}
                                            open={this.state.selectedPopupItemId == item.id}
                                            position="bottom center"
                                            arrow={false}
                                            contentStyle={{ 'background': 'none', border: 'default', 'boxShadow': 'none', 'border': '1px solid black' }}
                                            >
                                            <div className="menu">
                                                <Popup
                                                    trigger={
                                                        <div
                                                            className={`menu-item ${(user.is_admin === true || user.id === item.user_id) ? "" : 'disable-btn'}`}>
                                                                <div><i className="fa fa-android" style={{marginRight: '5px'}}></i>Fuzebot Functions</div>
                                                                <img className="menu-arrow revert" src="/assest/accountBased/arrow.svg" alt=""/>
                                                        </div>}
                                                    position={this.state.isMobile ? "left top" : "left top"}
                                                    arrow={false}
                                                    on="hover"
                                                    contentStyle={{ 'margin': 0 }}
                                                    closeOnDocumentClick>
                                                    <div>
                                                        {!item.fuzebot ? <div
                                                            className={classNames("menu-item", {"disable-btn": item.fuzebot})}
                                                            onClick={() => this.props.addOrEditFuzebotToLeadList(item)}>Add
                                                            Fuzebot</div> : null}
                                                        <div
                                                            className={classNames("menu-item", {"disable-btn": !item.fuzebot})}
                                                            onClick={() => this.props.addOrEditFuzebotToLeadList(item)}
                                                        >
                                                            Edit Traits
                                                        </div>
                                                        <div
                                                            className={classNames("menu-item", {"disable-btn": !item.fuzebot})}
                                                            onClick={this.handleMenuSelection(() => this.openPopups(editleads, item, user.id))}
                                                        >
                                                            Edit Leads Added Per Day
                                                        </div>
                                                        <div
                                                            className={classNames("menu-item", {"disable-btn": !item.fuzebot})}
                                                            onClick={this.handleMenuSelection(() => this.openPopups(pauseresume, item, user.id))}
                                                        >
                                                            {this.getFuzebotStatus(item)}
                                                        </div>
                                                        <div
                                                            className={classNames("menu-item", {"disable-btn": !item.fuzebot || this.getFuzebotStatus(item) === 'Resume'})}
                                                            onClick={() => this.props.viewFuzebotPendingLeadList(item)}
                                                        >
                                                            Pending Leads
                                                        </div>
                                                    </div>
                                                </Popup>
                                                <div
                                                    className={classNames("menu-item", {"disable-btn": user.is_admin === false && user.id !== item.user_id})}
                                                    onClick={this.handleMenuSelection(() => this.openPopups(editlist, item, user.id))}
                                                >
                                                    <div><i className="fa fa-pencil-square-o" style={{marginRight: '5px'}}></i>Edit List Name</div>
                                                </div>
                                                <Popup
                                                    trigger={
                                                        <div className="menu-item">
                                                            <div><i className="fa fa-download" style={{marginRight: '5px'}}></i>Export To</div>
                                                            <img className="menu-arrow revert" src="/assest/accountBased/arrow.svg" alt=""/></div>
                                                    }
                                                    position={this.state.isMobile ? "left top" : "left top"}
                                                    arrow={false}
                                                    on="hover"
                                                    closeOnDocumentClick
                                                    contentStyle={{ 'margin': 0 }}>
                                                    <div>
                                                        <div
                                                            className="menu-item"
                                                            onClick={this.handleMenuSelection(() => this.openPopups(exportcsv, item, user.id))}
                                                        >
                                                            CSV
                                                        </div>
                                                        <div
                                                            className="menu-item"
                                                            onClick={this.handleMenuSelection(() => this.openPopups(googlesheet, item, user.id))}
                                                        >
                                                            Google Sheet
                                                        </div>
                                                    </div>
                                                </Popup>
                                                <Popup
                                                    trigger={
                                                        <div className={classNames("menu-item", {
                                                            "disable-btn": user.is_admin === false && user.id !== item.user_id || !INTEGRATIONS.some(this.doesIntegrationExist)
                                                        })}>
                                                            <div><i className="fa fa-cog" style={{marginRight: '5px'}}></i>Integration Settings</div>
                                                            <img className="menu-arrow revert" src="/assest/accountBased/arrow.svg" alt=""/>
                                                        </div>
                                                    }
                                                    position={this.state.isMobile ? "left top" : "left top"}
                                                    arrow={false}
                                                    on="hover"
                                                    closeOnDocumentClick
                                                    contentStyle={{ 'margin': 0 }}
                                                >
                                                    <>
                                                        {
                                                            INTEGRATIONS.map(serviceName => {
                                                                return this.doesIntegrationExist(serviceName) &&
                                                                    <div
                                                                        className="menu-item icon-set"
                                                                        key={serviceName}
                                                                        onClick={this.handleMenuSelection(() => this.OpenIntegration(serviceName, item))}
                                                                    >
                                                                        <img
                                                                            src={INTEGRATION_TO_LABEL_MAP[serviceName].icon}
                                                                            className={serviceName == 'woodpecker' ? 'woodpecker' : ''}
                                                                            alt=""/>
                                                                        {INTEGRATION_TO_LABEL_MAP[serviceName].label}
                                                                    </div>
                                                            })
                                                        }
                                                    </>
                                                </Popup>
                                                <div
                                                    className={`menu-item red-font ${(user.is_admin === true || user.id === item.user_id) ? "" : 'disable-btn'}`}
                                                    onClick={this.handleMenuSelection(() => this.openPopups(deletelist, item, user.id))}
                                                >
                                                    <div><i className="fa fa-trash" style={{marginRight: '5px'}}></i>Delete List</div>
                                                </div>
                                            </div>
                                        </Popup>
                                    </div>
                                </div>
                            </div>
                            {this.state.selectedLeadListId === item.id &&
                            <>
                                <SavedSearchesItemExpanded
                                    savedSearchQuery={item.fuzebot ? item.fuzebot.query : {}}
                                    showEditTraits={false}
                                    showCriteriaFields={item.fuzebot ? true : false}
                                    editSearchTraits={() => {
                                    }}
                                    renderAdditionalContent={() =>
                                        <div>
                                            <div
                                                className="add-list__btn orange"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.props.addOrEditFuzebotToLeadList(item);
                                                }}
                                            >
                                                {
                                                    item.fuzebot
                                                        ? 'Edit Traits'
                                                        : 'Add Fuzebot'
                                                }

                                            </div>

                                            {/* NOTE: this span is just to eliminate ':last-child' css rule on add-list__btn */}
                                            <span></span>
                                        </div>
                                    }
                                />

                            </>
                            }
                        </div>

                    </PopoutResult>
                )
                }

                {popupEditList &&
                <EditListName openEditListPopup={openEditListPopup} existingName={this.getNameById(listID)}
                              popupEditList={popupEditList} id={listID} leadList={leadList} userID={userID}/>
                }
                {popup &&
                <AddLeadsToList openListPopup={openListPopup} addedLeadsPerDay={this.state.addedLeadsPerDay}
                                popup={popup} list={listData} id={listID} updateListItem={updateListItem} leadList={leadList}/>
                }
                {popupPauseResume &&
                <PauseResume popupPauseResume={popupPauseResume} openPauseResumePopup={openPauseResumePopup}
                             name={itemsName} id={listID} leadList={leadList} userID={userID} isActive={isActive}/>
                }
                {popupCSV &&
                <ExportToCSV openCsvPopup={openCsvPopup} popupCSV={popupCSV} id={listID}/>
                }
                {popupExpGoogle &&
                <ExportToGoogleSheet popupExpGoogle={popupExpGoogle} openExpGooglePopup={openExpGooglePopup} id={listID}
                                     sheetID={sheetID} listData={listData} sheetNames={this.state.sheetNames} 
                                     loading={this.state.sheetLoading} type="list"/>
                }
                {popupDelete &&
                <DeleteList popupDelete={popupDelete} openDeletePopup={openDeletePopup} id={listID} leadList={leadList}
                            userID={userID}/>
                }
                {popupDuxSoup &&
                <DuxSoup openDuxSoupPopup={openDuxSoupPopup} popupDuxSoup={popupDuxSoup} list={list} listId={listID} 
                    connection_message={list.integrations_info.duxsoup_info ? list.integrations_info.duxsoup_info.connection_message : ''} 
                    followup_message={list.integrations_info.duxsoup_info ? list.integrations_info.duxsoup_info.followup_message : ''}
                    updateDefaultIntegration={() => {}}/>
                }

                {popupWoodpecker &&
                <Woodpecker list={list} listId={listID} openWoodpeckerPopup={openWoodpeckerPopup} type='woodpecker'
                            popupWoodpecker={popupWoodpecker} woodpeckerCampaigns={woodpeckerCampaigns} updateDefaultIntegration={() => {}}/>
                }
                {popupReply &&
                <Woodpecker list={list} listId={listID} openWoodpeckerPopup={openReplyPopup} type='reply'
                            popupWoodpecker={popupReply} woodpeckerCampaigns={replyCampaigns} updateDefaultIntegration={() => {}}/>
                }
                {popupHighLevel &&
                <Woodpecker list={list} listId={listID} openWoodpeckerPopup={openHighLevelPopup} type='highlevel'
                            popupWoodpecker={popupHighLevel} woodpeckerCampaigns={highlevelCampaigns} updateDefaultIntegration={() => {}}/>
                }
                {popupJazzhr &&
                <Woodpecker list={list} listId={listID} openWoodpeckerPopup={openJazzhrPopup} type='jazzhr'
                            popupWoodpecker={popupJazzhr} woodpeckerCampaigns={jazzhrCampaigns} updateDefaultIntegration={() => {}}/>
                }
                {popupLemlist &&
                <Woodpecker list={list} listId={listID} openWoodpeckerPopup={openLemlistPopup} type='lemlist'
                            popupWoodpecker={popupLemlist} woodpeckerCampaigns={lemlistCampaigns} updateDefaultIntegration={() => {}}/>
                    // <Lemlist listID={listID} openLemlistPopup={openLemlistPopup} popupLemlist={popupLemlist} lemlistCampaigns={lemlistCampaigns} />
                }
                {popupMailshake &&
                <MailShake list={list} listId={listID} mailshakeCampaigns={mailshakeCampaigns} openMailshakePopup={openMailshakePopup} 
                           popupMailshake={popupMailshake} updateDefaultIntegration={() => {}}/>
                }
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        integration: state.integration
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        startLoader,
        stopLoader,
        openPopup,
        closePopup
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadListsContent);
