import React, {Component, Fragment} from 'react';
import BillingUsersTabs from './BillingUsersTabs';
import './style.scss';

class BillingUsersContent extends Component {
    state = {
        totalAvailableLeads: 0,
        stats: [
            {
                num: 0,
                title: 'Total users'
            },
            {
                num: 0,
                title: 'Monthly leads'
            },
            {
                num: 0,
                title: 'Leads remaining'
            },
            {
                num: 0,
                title: 'Unallocated leads'
            }
        ]
    };

    onReceiveUserContent = (totalLists, totalLeadsAdded, totalMaxLeads, isUnlimited) => {
        this.props.onReceiveUserContent(totalLists, totalLeadsAdded)
        this.setState({totalMaxLeads: totalMaxLeads, isUnlimited: isUnlimited})
    }

    render() {
        const billingOverview = this.props.billingOverview;
        const stats = this.state.stats;

        if (billingOverview) {
            stats[0].num = billingOverview.total_users;
            stats[1].num = billingOverview.is_unlimited ? 'Unlimited' : billingOverview.monthly_lead_credits;
            stats[2].num = billingOverview.is_unlimited ? '-' : billingOverview.available_lead_credits;
            //one unlimited? -> 0
            //total max> total per month
            if (this.state.isUnlimited || this.state.totalMaxLeads > billingOverview.available_lead_credits) {
                stats[3].num = 0
            } else {
                stats[3].num = Number(billingOverview.available_lead_credits) - Number(this.state.totalMaxLeads)
            }
            if(billingOverview.is_unlimited) {
                stats[3].num = '-';
            }
            stats[1].title = `${this.props.billingOverview.plan_name.indexOf("annual") > -1 ? "Annual" : "Monthly"} leads`
            stats[3].title = `Unallocated ${this.props.billingOverview.plan_name.indexOf("annual") > -1 ? "annual" : "monthly"} leads`
        }

        return (
            <section className="billing">
                <h3 className="billing__title">Billing and Users</h3>
                <div className="billing-stat">

                    <Fragment>
                        {stats.map((item, index) =>
                            <div
                                className={`billing-stat__col ${index === 3 ? stats[3].num > 0 ? 'last' : 'first' : ''}`}
                                key={index}>
                                <div className="billing-stat__item">
                                    <div className="billing-stat__num">
                                        <span>{item.num}</span>
                                    </div>

                                    <div className="billing-stat__title">
                                        <p>{item.title}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                </div>

                <BillingUsersTabs
                    billingOverview={this.props.billingOverview}
                    loadBillingOverview={this.props.loadBillingOverview}
                    onTabChange={this.props.onTabChange}
                    onReceiveUserContent={this.onReceiveUserContent}
                />
            </section>
        )
    }
}

export default BillingUsersContent