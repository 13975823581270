import { stubTrue, truncate } from 'lodash';
import React, { Component, Fragment } from 'react';
import {getIndustriesLable} from "../../../../constants/helper";

class SavedSearchesKeywordsWithCount extends Component {

  constructor(props) {
    super(props);

    this.index = 0;

    this.state = {
      keywords: []
    };
  }

  componentDidMount() {
    if (this.props.query.type === "people_prospector") {
      this.loadMarketBasedKeywords();
    } else if (this.props.query.type === "people_lookup") {
      this.loadAccountBasedKeywords();
    }
  }

  loadMarketBasedKeywords() {
    const marketBased = this.props.query.people_prospector;

    if (this.props.showCount) {
      this.loadKeywordsForGroupCategory("", marketBased);
    }

    this.loadKeywordsForGroupCategory("Role", marketBased.role);
    this.loadKeywordsForGroupCategory("Skills", marketBased.skills);

    this.loadKeywordsFromIndustries(marketBased.industries, marketBased.exclude_industries);
    this.loadKeywordsForTechnologies("Technology Name", marketBased.technology);
    this.loadKeywordsForLocations(marketBased.company_location);
    this.loadKeywordsForLocations(marketBased.exclude_company_location, true);

    this.loadKeywordsForLocations(marketBased.exclude_people_location, true);
    this.loadKeywordsForLocations(marketBased.people_location);

    this.loadKeywordsForYearsFounded(marketBased);
    this.loadKeywordsForMajor(marketBased.major);
    this.loadKeywordsForEstimatedSalary(marketBased);
    this.loadKeywordsForWorkExperiences(marketBased);
    this.loadKeywordsForCollege(marketBased.college);
    this.loadKeywordsFromSingleTypeCategory("Budget", marketBased.adwords_budget);
    this.loadKeywordsFromSingleTypeCategory("News", marketBased.news);
    
    this.loadKeywordsForHiringRoleGroup(marketBased.hiring);

    if(marketBased.employees)
      this.loadKeywordsForEmployeeSize(marketBased.employees);

  }

  loadAccountBasedKeywords() {
    const accountBased = this.props.query.people_lookup;

    if (this.props.showCount) {
      this.loadKeywordsForGroupCategory("", accountBased);
    }

    this.loadKeywordsForGroupCategory("Role", accountBased.role);

    this.loadKeywordsFromSingleTypeCategory("Company Domain", accountBased.company_domain_tags);
  }

  loadKeywordsFromIndustries(include_keywords, exclude_keywords) {
    if(include_keywords) {
      const selectedKeywords = this.state.keywords;

      include_keywords.forEach((item) => {
        this.index ++;
        selectedKeywords.push(<li key={this.index}>Industry: {getIndustriesLable(item)}</li>);
      });

      if(exclude_keywords) {
        exclude_keywords.forEach((item) => {
          this.index ++;
          selectedKeywords.push(<li className="red" key={this.index}>Industry: {getIndustriesLable(item)}</li>);
        });
      }

      this.setState({ keywords: selectedKeywords });
    }
  }

  loadKeywordsFromSingleTypeCategory(prefix, category) {
    if(category) {
      const selectedKeywords = this.state.keywords;

      category.forEach((item) => {
        this.index ++;
        selectedKeywords.push(<li key={this.index}>{prefix ? prefix + ':' : ''} {item}</li>);
      });

      this.setState({ keywords: selectedKeywords });
    }
  }

  loadKeywordsForLocations(location, excludeGroup = false) {
    if(location) {
      const selectedKeywords = this.state.keywords;

      location.cities && location.cities.forEach((item) => {
        this.index ++;
        if(item.state) {
          selectedKeywords.push(<li className={excludeGroup && 'red'} key={'city' + this.index}>City: {item.city}, {item.state}</li>);
        }
        else {
          selectedKeywords.push(<li className={excludeGroup && 'red'}  key={'city' + this.index}>City: {item.city}</li>);
        }
      });

      location.states && location.states.forEach((item) => {
        this.index ++;
        if(item.country) {
          selectedKeywords.push(<li className={excludeGroup && 'red'}  key={'state' + this.index}>State: {item.state}, {item.country}</li>);
        }
        else {
          selectedKeywords.push(<li className={excludeGroup && 'red'}  key={'state' + this.index}>State: {item.state}</li>);
        }
      });

      location.metros && location.metros.forEach((item) => {
        this.index ++;
        if(item.state) {
          selectedKeywords.push(<li className={excludeGroup && 'red'}  key={'metro' + this.index}>Metro: {item.metro}, {item.state}</li>);
        }
        else {
          selectedKeywords.push(<li className={excludeGroup && 'red'}  key={'metro' + this.index}>Metro: {item.metro}</li>);
        }
      });

      location.countries && location.countries.forEach((item) => {
        this.index ++;
        selectedKeywords.push(<li className={excludeGroup && 'red'}  key={'country' + this.index}>Country: {item}</li>);
      });

      location.group && location.group.forEach((item) => {
        this.index ++;
        selectedKeywords.push(<li className={excludeGroup && 'red'}  key={'region' + this.index}>Region: {item}</li>);
      });

      location.continents && location.continents.forEach((item) => {
        this.index ++;
        selectedKeywords.push(<li className={excludeGroup && 'red'}  key={'continent' + this.index}>Continent: {item}</li>);
      });

      location.keywords && location.keywords.forEach((item) => {
        this.index ++;
        selectedKeywords.push(<li className={excludeGroup && 'red'}  key={'keyword' + this.index}>Keyword: {item}</li>);
      });

      this.setState({ keywords: selectedKeywords });
    }
  }

  loadKeywordsForYearsFounded(query) {
    if(query) {
      const selectedKeywords = this.state.keywords;


      if(query.founded_date_from || query.founded_date_to) {
      selectedKeywords.push(<li>Years Founded: {query.founded_date_from} - {query.founded_date_to}</li>);
      }

      this.setState({ keywords: selectedKeywords });
    }
  }

  loadKeywordsForMajor(major) {
    if(major) {
      const selectedKeywords = this.state.keywords;

      major.forEach((item) => {
        this.index ++;
        selectedKeywords.push(<li key={'major' + this.index}>Major: {item}</li>);
      });


      this.setState({ keywords: selectedKeywords });
    }
  }

  loadKeywordsForEstimatedSalary(query) {
    if(query) {
      const selectedKeywords = this.state.keywords;


     
      if(query.estimated_salary) {
        query.estimated_salary.forEach((item) => {
            this.index ++;
            selectedKeywords.push(<li  key={'salary' + this.index}>Estimated Salary: {item}</li>);
          });
        
      }

      this.setState({ keywords: selectedKeywords });
    }
  }

  loadKeywordsForWorkExperiences(query) {
    if(query) {
      const selectedKeywords = this.state.keywords;

      if(query.years_of_experience) {
        query.years_of_experience.forEach((item) => {
            this.index ++;
            selectedKeywords.push(<li  key={'years_of_experience' + this.index}>Years of Experience: {item}</li>);
          });
      }

      if(query.time_latest_job) {
        query.time_latest_job.forEach((item) => {
            this.index ++;
            selectedKeywords.push(<li  key={'time_latest_job' + this.index}>Time in Latest Role: {item}</li>);
          });
      }

      this.setState({ keywords: selectedKeywords });
    }
  }


  loadKeywordsForCollege(college) {
    if(college) {
      const selectedKeywords = this.state.keywords;

      college.forEach((item) => {
        this.index ++;
        selectedKeywords.push(<li key={'college' + this.index}>College: {item}</li>);
      });


      this.setState({ keywords: selectedKeywords });
    }
  }

    
  
  loadKeywordsForHiringRoleGroup(category) {
    if(category) {
      const selectedKeywords = this.state.keywords;

      if(category.groups) {
        category.groups.forEach((item) => {
          this.index ++;
          selectedKeywords.push(<li key={this.index}>Hiring: {item}</li>);
        });
      }

      if(category.roles) {
        category.roles.forEach((item) => {
          this.index ++;
          selectedKeywords.push(<li key={this.index}>Hiring: {item}</li>);
        });
      }

      this.setState({ keywords: selectedKeywords });
    }
  }

  loadKeywordsForEmployeeSize(category) {
    if(category) {
      const selectedKeywords = this.state.keywords;

      for(let key in category){
        this.index ++;
        selectedKeywords.push(<li key={this.index}>Employee Size: {key}</li>);
      }

      this.setState({ keywords: selectedKeywords });
    }
  }

  loadKeywordsForTechnologies(prefix, technology) {
    let includeTechnologies = [];
    let excludeTechnologies = [];

    const selectedKeywords = this.state.keywords;

    if(technology.include) {
      if(technology.include.names && technology.include.names.length > 0) {
        includeTechnologies.push(...technology.include.names);
      }

      if(technology.include.groups && technology.include.groups.length > 0) {
        includeTechnologies.push(...technology.include.groups);
      }
    }

    if(technology.exclude) {
      if(technology.exclude.names && technology.exclude.names.length > 0) {
        excludeTechnologies.push(...technology.exclude.names);
      }

      if(technology.exclude.groups && technology.exclude.groups.length > 0) {
        excludeTechnologies.push(...technology.exclude.groups);
      }
    }

    includeTechnologies = includeTechnologies.filter((element) => element.trim().length > 0);
    excludeTechnologies = excludeTechnologies.filter((element) => element.trim().length > 0);

    if(includeTechnologies) {
      includeTechnologies.forEach((item) => {
        this.index ++;
        selectedKeywords.push(<li key={this.index}>{prefix ? prefix + ':' : ''} {item}</li>);
      });
    }

    if(excludeTechnologies) {
      excludeTechnologies.forEach((item) => {
        this.index ++;
        selectedKeywords.push(<li key={this.index} className="red">{prefix ? prefix + ':' : ''} {item}</li>);
      });
    }

    this.setState({ keywords: selectedKeywords });

    // if(category) {
    //   const selectedKeywords = this.state.keywords;
    
    //   category.forEach((item) => {
    //     this.index ++;

    //     if(item.excludeGroup) {
    //       selectedKeywords.push(<li key={this.index} className="red">{item.label}</li>);
    //     } else {
    //       selectedKeywords.push(<li key={this.index}>{item.label}</li>);
    //     }
    //   });

    //   this.setState({ keywords: selectedKeywords });
    // }
  }

  loadKeywordsForGroupCategory(prefix, category) {
    if (category) {
      const selectedKeywords = this.state.keywords;
      
      if(category.groups) {
        for(let key in category.groups) {
          this.index ++;
          selectedKeywords.push(<li key={this.index}>{prefix ? prefix + ':' : ''} {key}</li>);
        }
      }

      if(category.include_keywords) {
        category.include_keywords.forEach((item) => {
          this.index ++;
          selectedKeywords.push(<li key={this.index}>{prefix ? prefix + ':' : ''} {item}</li>);
        });
      }

      if(category.exclude_keywords) {
        category.exclude_keywords.forEach((item) => {
          this.index ++;
          selectedKeywords.push(<li key={this.index} className="red">{prefix ? prefix + ':' : ''} {item}</li>);
        });
      }

      this.setState({ keywords: selectedKeywords });
    }
  }

  render() {
    let keywords = [];
    let additionalKeywordsCount = 0;

    if(this.props.showCount) {
      if(this.state.keywords.length > 2) {
        keywords.push(this.state.keywords[0]);
        keywords.push(this.state.keywords[1]);
        additionalKeywordsCount = this.state.keywords.length - 2;
      } else {
        keywords = this.state.keywords;
      }
    } else {
      keywords = this.state.keywords;
    }
   
    return (
      <div className="saved_searches__keywords-panel">
        <ul className={`saved_searches__keywords-with-count ${this.props.showCount ? 'withCount' : 'withoutCount'}`}>

          {keywords.map((item) => 
            item
          )}

        </ul>

          {additionalKeywordsCount > 0 && <div className="additional-keywords-count"><span className="dots">...</span><label>{additionalKeywordsCount}</label></div> }
      </div>
    );
  }
}

export default SavedSearchesKeywordsWithCount;