export function pictureUpdate(img, name) {
    return {
        type: "UPDATE",
        payload: img,
        name: name
    };
}

export function saveUserInfo(info) {
    return {
        type: "SAVE_USER_INFO",
        payload: info,
    };
}
