import React, { Component } from "react";

class SavedSearchTechnologies extends Component {

  render() {

    let includeTechnologies = [];
    let excludeTechnologies = [];

    if (this.props.query.type === "people_prospector") {
      const technology = this.props.query.people_prospector.technology;

      if(technology.include) {
        if(technology.include.names && technology.include.names.length > 0) {
          includeTechnologies.push(...technology.include.names);
        }

        if(technology.include.groups && technology.include.groups.length > 0) {
          includeTechnologies.push(...technology.include.groups);
        }
      }

      if(technology.exclude) {
        if(technology.exclude.names && technology.exclude.names.length > 0) {
          excludeTechnologies.push(...technology.exclude.names);
        }

        if(technology.exclude.groups && technology.exclude.groups.length > 0) {
          excludeTechnologies.push(...technology.exclude.groups);
        }
      }

      includeTechnologies = includeTechnologies.filter((element) => element.trim().length > 0);
      excludeTechnologies = excludeTechnologies.filter((element) => element.trim().length > 0);
    }

    if (excludeTechnologies.length == 0 && includeTechnologies.length == 0) {
      return (
        <div className="empty-list-msg">No technology selected</div>
      );
    }

    return (
      <ul className="saved-search-expanded__technologies">
        
        {includeTechnologies.map((item, index) => 
          <li key={index} className={`tech-base ${item.toLowerCase()}`}>{item}</li>
        )}

        {excludeTechnologies.map((item, index) => 
          <li key={index} className={` grey tech-base ${item.toLowerCase()}`}>{item}</li>
        )}

      </ul>
    );
  }
}
export default SavedSearchTechnologies;
