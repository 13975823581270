import { Switch } from "@material-ui/core";
import React, { Fragment, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard";

export const CreateConfigurations = ({ newConfig, isOpen, errorMessage, onClosePopup, script, onAddFunction, website, handleChange }) => {
    const [copy, setCopy] = useState(false)

    const onCopy = ($event) => {
        $event.stopPropagation();
        setCopy(true);

        setTimeout(() => {
            setCopy(false);
        }, 300);
    }

    // const getFormatted = () => {
    //     const formatted = script?.replace(/</g, "&lt")?.replace(/>/g, "&gt")
    //     console
    //     if (document.getElementById('scr')) { document.getElementById('scr').innerHTML = formatted }
    //     return
    // }

    return <Fragment>
        <div className={`create-config add-list ${isOpen ? 'open' : ''}`}>
            {isOpen !== 'view' ? <div className="add-list__content">
                <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={onClosePopup} />
                <p className="add-list__title">{isOpen === 'edit' ? "Update" : "Create"} Configuration</p>
                <p className="add-list__label">Name</p>
                <div className="add-list__search-box">
                    <input className="add-list__search"
                        placeholder="Enter Name"
                        type="text"
                        value={newConfig.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                    />
                </div>
                {
                    errorMessage?.name
                        ? (
                            <div className="lead-header__model_error">
                                <p>{errorMessage?.name}</p>
                            </div>
                        )
                        : null
                }
                <p className="add-list__label">Website</p>
                <div className="add-list__search-box">
                    <input className="add-list__search"
                        placeholder="Enter Website"
                        type="text"
                        value={newConfig.website}
                        disabled={isOpen === 'edit'}
                        onChange={(e) => handleChange('website', e.target.value)}
                    />

                </div>
                {
                    isOpen === 'edit' && <><p className="add-list__label">Enable Slack</p>
                        <div className="add-list__search-box">
                            <Switch
                                size="large"
                                color="orange-light"
                                onChange={(e) => handleChange('is_slack_enabled', e.target.checked)}
                                disabled={!newConfig.slack_webhook_url}
                                checked={newConfig.is_slack_enabled}
                            />
                        </div></>
                }
                {
                    errorMessage?.website
                        ? (
                            <div className="lead-header__model_error">
                                <p>{errorMessage?.website}</p>
                            </div>
                        )
                        : null
                }
                <p className="add-list__create">
                </p>
                <div className="add-list__buttons">
                    <div className="add-list__btn orange" onClick={() => { onAddFunction() }} >{isOpen === 'edit' ? "Update" : "Create"} Configuration</div>
                    <div className="add-list__btn orange-light" onClick={onClosePopup}>Cancel</div>
                </div>
            </div> : <div className="add-list__content">
                <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={onClosePopup} />
                <div>
                    <CopyToClipboard text={script}><a
                        className={`copy ${copy ? 'copy_text' : null}`}
                        onClick={(event) => onCopy(event)}><img
                            src="/assest/accountBased/Ignore_List.svg"
                            alt="" /></a></CopyToClipboard>

                </div>
                <pre className="script" id='scr'> {script}</pre>
            </div>}
        </div>
    </Fragment>
}

