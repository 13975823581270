import React, { Component, Fragment } from 'react';
import './style.scss';
import moment from "moment";
import { Loader } from "../../..";

class Person extends Component {

  constructor(props) {
    super(props);

    this.state = {
      from: 0,
      to: 0
    };
  }

  componentDidMount() {
    const experiences = this.props.leadDetail.experience;

    if (experiences && experiences.length > 0) {
      this.setState({to: (experiences.length < 3 ? experiences.length : 2)});

      experiences.sort((a, b) => {
        let aStartDate = new Date();
        let bStartDate = new Date();
        if (a.start_date) {
          aStartDate = new Date(a.start_date);
        }

        if (b.start_date) {
          bStartDate = new Date(b.start_date);
        }

        return bStartDate - aStartDate;
      });
    }
  }

  getEmails(leadDetail) {
    const emails = [];
    if(leadDetail.addedToList) {
      if (leadDetail.emails) {
        if (leadDetail.emails.personal) {
          if(leadDetail.emails.personal.deliverable)
            emails.push(...leadDetail.emails.personal.deliverable);
          if(leadDetail.emails.personal.catchall)
            emails.push(...leadDetail.emails.personal.catchall);
        }

        if (leadDetail.emails.company) {
          if(leadDetail.emails.company.deliverable)
            emails.push(...leadDetail.emails.company.deliverable);
          if(leadDetail.emails.company.catchall)
            emails.push(...leadDetail.emails.company.catchall);
        }
      }
    }
    return emails;
  }

  getPhoneNumbers(leadDetail) {
    const phoneNumbers = [];
    if(leadDetail.addedToList) {
      if (leadDetail.phone) {
        leadDetail.phone.forEach(item => {
          if (item.number) {
            phoneNumbers.push(item.number);
          }
        });
      }
    }
    return phoneNumbers;
  }

  getCollegesAndDegrees(leadDetail) {
    const education = {
      colleges: [],
      degrees: []
    };

    if (leadDetail.education) {
      leadDetail.education.forEach(item => {
        if (item.school) {
          education.colleges.push(item.school.name);
        }

        if(item.degrees && item.degrees.length > 0) {
          education.degrees.push(...item.degrees);
        }
      });

      education.degrees = this._getUniqueArray(education.degrees);
    }

    return education;
  }

  _getUniqueArray(array){
    var uniqueArray = [];
    for(let i = 0; i < array.length; i++){
        if(uniqueArray.indexOf(array[i]) === -1) {
            uniqueArray.push(array[i]);
        }
    }
    return uniqueArray;
  }

  getJobPeriod(experience) {
    let dateStr = 'N/A';

    if (experience.start_date && experience.start_date !== '0001-01-01T00:00:00Z') 
      dateStr = moment(experience.start_date).format("MMM YYYY");
   

    if (experience.end_date && experience.end_date !== '0001-01-01T00:00:00Z') {
      if (experience.end_date.toLowerCase() === 'present') {
        return dateStr + ' - PRESENT';
      } else {
        return dateStr + ' - ' + moment(experience.end_date).format("MMM YYYY");
      }
    } 
     
    else return dateStr === 'N/A' ? dateStr : dateStr + ' - PRESENT';

  }

  nextExperience = () => {
    const experiences = this.props.leadDetail.experience;

    if (this.state.to === experiences.length - 1) {
      return;
    }

    let from = this.state.to + 1;
    let to = this.state.to + 3;

    if (to >= experiences.length) {
      to = experiences.length -1;
    }

    this.setState({from: from, to: to});
  }

  previousExperience = () => {
    if (this.state.from === 0) {
      return;
    }

    let from = this.state.from -3;
    if (from < 0) {
      from = 0;
    }

    this.setState({from: from, to: from + 2});
  }

  render() {
    let { leadDetail } = this.props;
    const education = this.getCollegesAndDegrees(leadDetail);

    const showSkills = Boolean(leadDetail.skills && leadDetail.skills.length > 0)
    const showInterests = Boolean(leadDetail.interests && leadDetail.interests.length > 0)
    const showEducation = Boolean(leadDetail.education && leadDetail.education.length > 0)
    const showExperience = Boolean(leadDetail.experience && leadDetail.experience.length > 0)

    const phoneNumbers = this.getPhoneNumbers(leadDetail);

    return(
      <div className="person-dt">

        <div className="person-dt-top">
          <div className="person-dt-top__col info">
            <h5 className="person-dt-top__title">Contact Info</h5>

            {leadDetail.addedToList &&
              <Fragment>
                <div className="person-dt-top__mail">
                  {this.getEmails(leadDetail).map((item, index) =>
                    <p key={`email-${index}`}>{item}</p>
                  )}
                  {/* <p>{this.getEmails(leadDetail)}</p> */}
                </div>

                <div className="person-dt-top__phone">
                  {phoneNumbers.map((item, index) =>
                    <p key={`phoneNumber-${index}`}>{item}</p>
                  )}
                </div>

                {(leadDetail.location && leadDetail.location.name) &&
                  <div className="person-dt-top__location">
                    <p>{leadDetail.location.name}</p>
                  </div>
                }
              </Fragment>
            }

            {!leadDetail.addedToList &&
              <div className={`add-lead-panel ${leadDetail.leadDataLoaded ? 'active' : ''}`}>

                {leadDetail.addingLeadToList && !leadDetail.leadDataLoaded && <Loader  />}

                <p>Add this lead to a list to see contact details</p>

                <a className="cursor-pointer" onClick={($event)=> this.props.openAddLeadToListPopup($event, leadDetail.id)}>ADD TO LIST</a>
              </div>
            }

          </div>

          <div className="person-dt-top__col">
            <h5 className="person-dt-top__title">Estimated Salary</h5>
            <div className="person-dt-top__box active">
                <p>{leadDetail.salary ? leadDetail.salary : 'Unknown'}</p>
            </div>
          </div>

          <div className="person-dt-top__col">
            <h5 className="person-dt-top__title">Net Worth</h5>
            <div className="person-dt-top__box active">
                <p>{leadDetail.net_worth ? leadDetail.net_worth : 'Unknown'}</p>
            </div>
          </div>

          <div className="person-dt-experience-salay">
            <div className="person-dt-top__col info">
              <h5 className="person-dt-top__title">Estimated Years of Work Experience</h5>
              <div className="person-dt-top__box active">
                <p>{leadDetail.year_experience ? leadDetail.year_experience + ' years' : 'Unknown'}</p>
              </div>
            </div>

            <div className="person-dt-top__col">
              <h5 className="person-dt-top__title">Time in Latest Role</h5>
              <div className="person-dt-top__box active">
                <p>{leadDetail.time_in_latest_role ? leadDetail.time_in_latest_role : 'Unknown'}</p>
              </div>
            </div>

            <div className="person-dt-top__col">
              <h5 className="person-dt-top__title">Age Range</h5>
              <div className="person-dt-top__box active">
                <p>{leadDetail.age_range ? leadDetail.age_range : 'Unknown'}</p>
              </div>
            </div>
          </div>


          <div className="person-dt-experience-salay">
            <div className="person-dt-top__col">
                <h5 className="person-dt-top__title">Department</h5>
                <div className="person-dt-top__box active">
                  <p>{leadDetail.department ? 
                    leadDetail.department.split(' ').map(word => word.toLowerCase() === 'and' 
                      ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1)).join(' ') 
                      : 'Unknown'}</p>
                </div>
              </div>
            </div>
          </div>


        <div className="person-dt-bottom">

          <div className="person-dt-bottom__col person-dt-bottom__history">
            <h5 className="person-dt-top__title">Job history</h5>
            <div className={`person-dt-top__box bottom-box ${showExperience ? 'active' : 'active'}`}>

              {showExperience &&
                <div className="job-history__row">
                  {leadDetail.experience.map((item, index)=>
                    <Fragment key={index}>
                      {(index >= this.state.from && index <= this.state.to) &&
                      <div className="job-history">
                        <div className="job-history__item">
                          <p className="job-history__date"><img src="/assest/accountBased/clock.svg" alt="" />{this.getJobPeriod(item)}</p>
                          <p className="job-history__title">{item.role}</p>
                          {item.company &&
                            <Fragment>
                              <p className="job-history__descr">
                                <a className="details-data__link" href={`http://${item.company.website}`} target="_blank">
                                  <img src="/assest/accountBased/social-icons/Comb.svg" alt=""/>{item.company.name}
                                </a>
                              </p>

                              {item.company.industry && item.company.industry.name &&
                                <p className="job-history__industry">{item.company.industry.name}</p>
                              }
                            </Fragment>
                          }

                        </div>
                      </div>
                      }
                    </Fragment>
                  )}

                  {leadDetail.experience.length > 3 &&
                    <div className="job-history__arrows">
                      <img className={`job-history__arr left ${this.state.from === 0 ? 'cursor-notAllowed' : ''}`} onClick={this.previousExperience} src={this.state.from === 0 ? '/assest/accountBased/social-icons/Icon_left_grey.svg' : '/assest/accountBased/social-icons/Icon_left.svg'} alt=""/>
                      <img className={`job-history__arr right ${(this.state.to === leadDetail.experience.length -1) ? 'cursor-notAllowed' : ''}`} onClick={this.nextExperience} src={(this.state.to === leadDetail.experience.length -1) ? '/assest/accountBased/social-icons/Icon_right_grey.svg' : '/assest/accountBased/social-icons/Icon_right.svg'} alt=""/>
                    </div>
                  }
                </div>
              }

              {!showExperience && <p>Unknown</p> }
            </div>
          </div>

          <div className="person-dt-bottom__col person-dt-bottom__right">
              <div className="person-dt-bottom__education">
                <h5 className="person-dt-top__title">Education</h5>
                <div className={`person-dt-top__box slim-scroll ${showEducation ? 'active' : 'active'}`}>

                  {showEducation &&
                    leadDetail.education.map((edu, index) => (
                      <div className="education-entry" key={`education-${index}`}>
                        <div className="education-panel">
                          <div className="education-label">School:</div>
                          <div className="education-value">
                            <div className="data-value">
                              {edu?.school?.name ? edu.school.name : 'N/A'}
                            </div>
                          </div>
                        </div>

                        <div className="education-panel">
                          <div className="education-label">Degrees:</div>
                          <div className="education-value">
                            {edu?.degrees?.length > 0 ? (
                              edu.degrees.map((degree, idx) => (
                                <div className="data-value" key={`degree-${index}-${idx}`}>{degree}</div>
                              ))
                            ) : (
                              'N/A'
                            )}
                          </div>
                        </div>                        
                        <hr className="education-divider" />
                      </div>
                    ))
                  }
                  {!showEducation && <p>Unknown</p> }
                </div>
              </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Person;


/*
 {showEducation && (
            <div className="person-dt-bottom__col person-dt-bottom__right">
              <div className="person-dt-bottom__education">
                <h5 className="person-dt-top__title">Education</h5>
                <div className={`person-dt-top__box slim-scroll ${showEducation ? 'active' : ''}`}>
                  {leadDetail.education?.length > 0 ? (
                    leadDetail.education.map((edu, index) => (
                      <div className="education-entry" key={`education-${index}`}>
                        <div className="education-panel">
                          <div className="education-label">School:</div>
                          <div className="education-value">
                            <div className="data-value">
                              {edu?.school?.name ? edu.school.name : 'N/A'}
                            </div>
                          </div>
                        </div>

                        <div className="education-panel">
                          <div className="education-label">Degrees:</div>
                          <div className="education-value">
                            {edu?.degrees?.length > 0 ? (
                              edu.degrees.map((degree, idx) => (
                                <div className="data-value" key={`degree-${index}-${idx}`}>{degree}</div>
                              ))
                            ) : (
                              'N/A'
                            )}
                          </div>
                        </div>                        
                        <hr className="education-divider" />
                      </div>
                    ))
                  ) : (
                    <p>Education details not available</p>
                  )}
                </div>
              </div>
            </div>
          )}
*/