


var departments = ["Accounting", "Administrative", "Arts and Design", "Business Development", "Community and Social Services", 
  "Consulting", "Education", "Engineering", "Entrepreneurship", "Finance", "Healthcare Services", "Human Resouces", 
  "Information Technology", "Legal", "Marketing", "Media and Communication", "Military and Protective Services", 
  "Operations", "Product Management", "Program and Project Management", "Purchasing", "Quality Assurance", "Real Estate", 
  "Research", "Sales", "Support"]

export const department_groups = departments.map(department => {
  return {
    category: department,
    label: department,
    group: department,
    include_tags: [department],
    exclude_tags: [],
  }
})
