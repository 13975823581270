import React, { Component, Fragment } from 'react';
import { CouponCreate, CouponFilter, Coupons, RightPanelBanner, BreadCrumb } from '../../components';
import items from './items';
import './style.scss';
import { LOCAL_STORAGE_KEYS } from '../../constants/constant';
import { EmitterService } from "../../services/emitterService";

class Coupon extends Component {
  state = {
    addCoupon: false,
    searchValue: '',
    items: items,
    newItems: items,
    allCheck: false,
  }

  componentWillMount() {
    EmitterService.emit("loadLeadsRemaining");
  }

  openAddCoupon = () => {
    this.setState({ addCoupon: true })
  }

  closeAddCoupon = () => {
    this.setState({ addCoupon: false })
  }

  changeAllCheck = () => {
    const { allCheck, items } = this.state
    const check = !allCheck
    for (var i in items) {
      if (check) {
        items[i].check = true
      } else {
        items[i].check = false
      }
    }
    this.setState({ allCheck: !allCheck, items: items })
  }

  changeCheck = (i) => {
    const { items } = this.state
    items[i].check = !items[i].check
    this.setState({ items: items, allCheck: false })
  }

  searchFilter = (e) => {
    const { newItems } = this.state
    const value = e.target.value
    const itemsFilter = newItems.filter(item => {
      return item.code.toLowerCase().includes(value)
    })
    this.setState({ searchValue: value, items: itemsFilter })
  }

  render() {
    const { addCoupon, searchValue, items, allCheck } = this.state
    const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))

    const links = [
      {
        name: 'Admin',
        slug: '/admin/users',
      },
      {
        name: 'Coupon',
        slug: null,
      },
    ]

    return (
      <Fragment>
        <BreadCrumb links={links}></BreadCrumb>
        <div className="coupon-panel">
          <div className="markbased__table">
            <CouponCreate addCoupon={addCoupon} closeAddCoupon={this.closeAddCoupon} />

            <Coupons openAddCoupon={this.openAddCoupon}
              items={items}
              searchValue={searchValue}
              searchFilter={this.searchFilter}
              changeAllCheck={this.changeAllCheck}
              allCheck={allCheck}
              changeCheck={this.changeCheck} />
          </div>

          <div className="markbased__rightbar">
            <RightPanelBanner title={'Coupon Demo'}  page="coupon" type="coupon" video_id="t16lq6v74f"/>

              <CouponFilter />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Coupon;
