import {
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_FAILURE,
  RESET_PASS_TOKEN_EXPIRE,
  RESET_PASS_TOKEN_SUCCESS,
  REDIRECT_USER_TO_LIST,
  SET_ERROR,
  SET_SUCCESS_MESSAGE,
  LOGIN_ATTEMPTS
} from '../constants/constant';

const INITIAL_STATE = { user: null, status: null, error: null, loading: false, validToken: true, errorDiv: false, successDiv: false, isLoginAttempts: 0 };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGNIN_USER:// sign in user,  set loading = true and status = signin
      return {
        ...state,
        user: null,
        status: 'signin',
        error: null,
        loading: true,
        validToken: true
      };
    case SIGNIN_USER_SUCCESS://return authenticated user,  make loading = false and status = authenticated
      return {
        ...state,
        user: action.payload,
        status: 'authenticated',
        error: null,
        loading: false,
        validToken: true
      }; //<-- authenticated
    case SIGNIN_USER_FAILURE:// return error and make loading = false
      return {
        ...state,
        user: null,
        status: 'signin',
        error: true,
        loading: false,
        validToken: true,
      };
    case RESET_PASS_TOKEN_EXPIRE:// return error and make loading = false
      return {
        ...state,
        user: null,
        status: 'signin',
        error: true,
        loading: false,
        validToken: false
      };
    case RESET_PASS_TOKEN_SUCCESS:// return error and make loading = false
      return {
        ...state,
        user: null,
        status: 'signin',
        error: false,
        loading: false,
        token: false,
        validToken: true
      };
    case REDIRECT_USER_TO_LIST:
      return {
        ...state,
        user: action.payload,
        status: 'authenticated',
        error: null,
        loading: false,
        validToken: true
      };
    case SET_ERROR:
      return {
        ...state,
        errorDiv: action.payload
      }
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successDiv: action.payload
      }
    case LOGIN_ATTEMPTS:
      if (action.payload === 0) {
        return {
          isLoginAttempts: action.payload
        }
      } else {
        return {
          ...state,
          isLoginAttempts: state.isLoginAttempts + 1
        }
      }
    default:
      return state;
  }
}
