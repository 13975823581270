import React, { Fragment, Component } from 'react';
import '../../ApiKeysHeader/AddNewKeyPopup/style.scss';
import { revokeApiKey } from '../../../../services/apiKeys';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Fab } from '@material-ui/core';
import { LOCAL_STORAGE_KEYS } from '../../../../constants/constant';
import { showSuccessToast, showErrorToast } from '../../../../helpers/toast';

class RevokeApi extends Component {
    state = {
        error: false,
        successDiv: false
    }

    revokeList = async (id) => {
        const { openRevokePopup } = this.props
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        let toastId = null;
        toastId = toast('Revoking API key', {
            autoClose: 10000,
        });
        try {
            await revokeApiKey(userId.id, id)
            openRevokePopup("", id)
            showSuccessToast(toastId, "API key revoked successfully");
        }
        catch (error) {
            showErrorToast(toastId, "Unable to revoke API key");
        }
    }
    onClosePopup = () => {
        const { openRevokePopup } = this.props
        openRevokePopup()
    }

    render() {
        const { popupRevoke, listId } = this.props;
        return (
            <Fragment>
                <div className={`add-list ${popupRevoke ? 'open' : ''}`}>
                    <div className="add-list__content">
                        <p className="add-list__title">Are you sure you want to revoke the key?</p>
                        <p className="add-list__label">Once revoked, the key can not be restored.
                    </p>
                        <div className="add-list__buttons">
                            <Fab
                                onClick={() => this.revokeList(listId)}
                                variant="extended"
                                size="medium"
                                color="primary"
                                aria-label="add"
                            >Yes, I am sure</Fab>
                            <Fab
                                onClick={this.onClosePopup}
                                variant="extended"
                                size="medium"
                                color="secondary"
                                aria-label="add"
                            >Cancel</Fab>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default RevokeApi;
