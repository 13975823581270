import React, {Component} from 'react';
import './style.scss';

class CancelPopup extends Component {

    onCancel = () => {
        this.props.showCancelFeedbackPopup(true);
    }

    onMaintenanceMode = () => {
        this.props.onMaintainSubscription();
    }

    render() {
        return (
            <div className="popup-pause">
                <div className="popup-pause__container">
                    <img className="popup-pause__close" src="/assest/accountBased/close.svg" alt=""
                         onClick={() => this.props.showCancelPopup(false)}/>
                    <p className="popup-pause__title">By cancelling your account you understand</p>

                    <ul className="popup-pause__list">
                        {this.props.subscriptionDiscount != "" ?
                            <li>You will not longer get {this.props.subscriptionDiscount} off on
                                your {this.props.planName.indexOf("annual") > -1 ? "annual" : "monthly"} bill.
                            </li> : <span></span>}
                        {this.props.extraLeadCredits != "" ?
                            <li>You will no longer get {this.props.extraLeadCredits} extra lead
                                credits per {this.props.planName.indexOf("annual") > -1 ? "year" : "month"}.</li> :
                            <span></span>}
                        <li>You will no longer be able to access or legally use any of the data LeadFuze has supplied to
                            you.
                        </li>
                        <li>Any unused lead credits will be forfeited.</li>
                        <li>Your account will be
                            cancelled {this.props.planName.indexOf("commitmentl") > -1 ? "before your contract renewal date" : "on your next billing cycle"}.
                            You will have
                            access to your account until then and won't be billed further.
                        </li>
                    </ul>
                    <p className="popup-pause__text">Alternatively, you can put your account on “Maintenance Mode” for
                        $49/mo. New lead credits will not become available, but you can use any unused lead credits,
                        keep your lists, and continue to use your lead data legally.</p>
                    <div className="popup-pause__buttons">
                        {
                            this.props.isSubscriptionOnMaintenanceMode ?
                                <div
                                    className="popup-pause__btn"
                                    onClick={() => this.props.showCancelPopup(false)}>Stay on Maintenance Mode
                                </div> : <div
                                    className="popup-pause__btn"
                                    onClick={this.onMaintenanceMode}>Maintenance Mode for $49/mo
                                </div>
                        }

                        <div className="popup-pause__btn cancel" onClick={this.onCancel}>Cancel</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CancelPopup;