import React, {Component, Fragment} from 'react';
import moment from "moment";
import ReactTooltip from 'react-tooltip';

class HiringRole extends Component {
  state = {
    isShowAll: false,
  }

  showNext = () => {
    this.props.handlePagination(true);
  }

  showLast = () => {
    this.props.handlePagination(false);
  }


  formatDate(date) {
    return moment(date).format("MMMM D, YYYY");
  }

  showAll = () => {
    let f = this.state.isShowAll;
    this.setState({isShowAll: !f});
  }

  render() {

    const hiring = this.props.leadDetail.hiring;
    if (hiring === null || hiring.entries === null || hiring.entries.length === 0) {
      return (<div></div>);
    }

    let hasPrevious = false;
    let hasMore = false;
    const hiringEntries = [];

    if (hiring.entries && hiring.entries.length > 0) {
      hiringEntries.push(...hiring.entries);
    }

    if (this.props.leadDetail.next_cursor.length > 0) {
      hasMore = true;
    }
    
    if (this.props.leadDetail.last_cursor.length > 0) {
      hasPrevious = true;
    }

    let sortedHiringEntries = hiringEntries.sort(function(a, b) {
      return moment(b.date) - moment(a.date);
    });

    return(
      <div className="company-tab details">
        <h5 className="company-title">Hiring by Role</h5>

        <div className="company-tab__row">
          {sortedHiringEntries.map((item, index) => 
            <Fragment key={`hiringRole-${index}`}>
              {
                <div className="company-tab__col">
                  <p className="company-tab__date">{this.formatDate(item.date)}</p>
                  <p className="company-tab__name">
                    {item.role ? item.role : ''}
                    {/* {item.groups && item.groups.length > 0 ? (item.groups[0]) : ''}
                    {item.groups && item.groups.length > 1 &&
                      <Fragment>
                        <span className="group-count" data-for={`hiringRoleGroup-${index}`} data-tip>+ {item.groups.length - 1}</span>
                        
                        <ReactTooltip id={`hiringRoleGroup-${index}`}>
                          {item.groups.map((item, innerIndex) => 
                            <Fragment key={`inner-${index}-${innerIndex}`}>
                              {innerIndex > 0 &&
                                <p className="hiringGroup-tooltip-item">{item}</p>
                              }
                            </Fragment>
                          )}
                        </ReactTooltip>
                      </Fragment>
                    } */}
                  </p>
                  <a className="company-tab__link" href={item.url} target="_blank">Read More</a>
                </div>
              }
            </Fragment>
          )}
        </div>

        {/* { hasMore && 
          <div className="company-tab__btn" onClick={($event)=> this.props.openAddLeadToListPopup($event, this.props.leadDetail.id)}>Show more</div>
        } */}
            { 
          <div className={`company-tab__btn ${!hasPrevious ? 'company-disable' : ''}`} onClick={hasPrevious && this.showLast}>&lt;</div>
        }
        { 
          <div className={`company-tab__btn ${!hasMore ? 'company-disable' : ''}`} onClick={hasMore && this.showNext}>&gt;</div>
        }
      </div>
    )
  }
}

export default HiringRole;