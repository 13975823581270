import React, {Component, Fragment} from 'react';
import {RightPanelBanner} from '../../components';
import BillingUsersContent from './BillingUsersContent';
import BillingCards from './BillingUsersContent/BillingCards';
import BillingCurrentPlan from "./BillingUsersContent/BillingCurrentPlan";
import {getBillingOverview} from "../../services/billing";
import {EmitterService} from "../../services/emitterService";
import {toast} from 'react-toastify';
import { showSuccessToast, showErrorToast } from '../../helpers/toast';

class BillingAndUser extends Component {
    state = {
        billingOverview: null,
        selectedTab: 'User Details',
        totalLists: 0,
        totalLeadsAdded: 0
    };

    componentWillMount() {
        this.loadBillingOverview();

        EmitterService.subscribe("loadBillingOverview", () => {
            this.loadBillingOverview();
        });

    }

    onTabChange = (name) => {
        this.setState({selectedTab: name});
    }

    onReceiveUserContent = (totalLists, totalLeadsAdded) => {
        this.setState({totalLists: totalLists, totalLeadsAdded: totalLeadsAdded})
    }

    componentWillUnmount() {
        EmitterService.unsubscribe("loadBillingOverview");
    }

    loadBillingOverview = async () => {
        const toastId = toast("Requesting billing overview", {hideProgressBar: true, autoClose: 10000});

        try {
            const response = await getBillingOverview();
            this.setState({billingOverview: response.data.data});

            showSuccessToast(toastId, "Billing overview loaded successfully");
        } catch (error) {
            showErrorToast(toastId, "Unable to load billing overview");
        }
    }

    render() {

        return (
            <div className="billing-panel">
                <div className="billing-panel__left">
                    <BillingUsersContent
                        billingOverview={this.state.billingOverview}
                        loadBillingOverview={this.loadBillingOverview}
                        onTabChange={this.onTabChange}
                        onReceiveUserContent={this.onReceiveUserContent}
                    />
                </div>

                <div className="billing-panel__right">
                    <RightPanelBanner title={'Billing Demo'} page="billing" type="billing" video_id="b4cjztqcak"/>

                    {this.state.billingOverview &&
                    <Fragment>
                        {this.state.selectedTab === 'Billing Details' ?
                            <BillingCards billingOverview={this.state.billingOverview}/>
                            :
                            <BillingCurrentPlan billingOverview={this.state.billingOverview}
                                                planDetailsTab={this.state.selectedTab === 'Plan Details'}
                                                totalLists={this.state.totalLists}
                                                totalLeadsAdded={this.state.totalLeadsAdded}/>
                        }
                    </Fragment>
                    }

                </div>
            </div>
        )
    }
}

export default BillingAndUser