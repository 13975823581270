import React from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../Loader';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../helpers/renderfield';
import { Fab } from '@material-ui/core';
import '../style.scss';

const SignIn = (props) => {
  const { logOut, validationError, visible, responseError, handleSubmit } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get('status');
  let logedOut
  if (status) {
    if (logOut) {
      logedOut = false
    } else {
      logedOut = true
    }
  }
  if (!status) {
    logedOut = false
  }
  return (
    <div className="sign-in">
      <div className="sign-in__logo">
        <img src="/assest/accountBased/logo.svg" alt="" />
      </div>
      <div className="height-notification">
        {responseError && !logedOut &&
          < div className="error-div">
            <p>Sorry, we were unable to authenticate you. Please try again later or contact help@leadfuze.co for further assistance.</p>
          </div>
        }
        {logedOut &&
          <div className="success-div-logout">
            <p>You have been logged out from your LeadFuze account.</p>
          </div>
        }
      </div>
      <div className={`${responseError || logedOut ? ' mt-0' : ''}`}>
        <h2 className="sign-in__title ">Sign In</h2>
        <form method="post" onSubmit={handleSubmit}>
          <div className="sign-in__cont input-field lists_table_loader">
            {visible && <Loader />}
            <label className="sign-in__label">
              <img src="/assest/sign-in/user.svg" alt="" />
              <p>Email</p>
              <Field name="email" component={renderField} type="email" label="Please type your email here" />
              {/* <Field name="email" component={renderField} type="email" label="Please type your email here" validationError={validationError.email} /> */}
              {/* {validationError &&
                <span className="error-red">{validationError.email}</span>
              } */}
            </label>

            <div className="sign-in__cont input-field">
              <label className="sign-in__label">
                <img src="/assest/sign-in/pass.svg" alt="" />
                <p>Password</p>
                <Field name="pass" component={renderField} type="password" label="Please type your password" validationError={validationError.pass} />
                {validationError &&
                  <span className="error-red">{validationError.pass}</span>
                }
              </label>
              <Link to="/forgot-pass" className="forgot-pass-link">
                <p className="sign-in__forgot" >Forgot Password?</p>
              </Link>
            </div>
            <Fab
              type="submit"
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
            >Sign In</Fab>
          </div>
        </form>
      </div>
      <p className="sign-in__text"><span>Don’t have an account?</span> <a className="sign-in__sign" href="https://leadfuze.com/start/">Sign Up!</a> <span>It takes less than a minute.</span></p>
    </div >
  )
}

export default reduxForm({
  form: 'signin',
})(SignIn)
