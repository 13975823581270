import React from 'react'
import IntegrationsItem from '../IntegrationsItem'


const Activated = (props) => {
    const {
        activeIntegrations,
        turningOffIntegrationSwitch,
        toggelIndex,
        image,
        filterFlag,
        openFreshSalesPopup,
        popupFreshSales,
        popupDuxSoup,
        openDuxSoupPopup,
        integID,
        settingPopup,
        getIntegrations,
        integrationsData,
        openIntegrationPopup,
        popup,
        openIntegrationPopup2,
        popup2,
        nameForPopup,
        apiLocation,
        integrationLabel,
        name,
        removeActiveIntegration
    } = props

    var numOfActiveIntegrations = 0;
    integrationsData.forEach((i) => {
        if (activeIntegrations.map((a) => {
            return a.name
        }).indexOf(i.name) > -1 && i.checked) {
            numOfActiveIntegrations++
        }
    })
    return (
        <div className={`coupon create  ${numOfActiveIntegrations > 0 ? 'active' : ''}`}>
            <h3 className="integrations-cont__title">Active Integrations</h3>
            <div className="integrations-cont__content">
                {
                    integrationsData.map((item, index) =>
                        activeIntegrations.map((a) => {
                            return a.name
                        }).indexOf(item.name) > -1 ?
                            <IntegrationsItem
                                key={index}
                                getIntegrations={getIntegrations}
                                name={item.name}
                                check={item.checked}
                                id={item.id}
                                active={item.active}
                                featured={item.featured}
                                index={index}
                                text={item.text}
                                image={item.image}
                                tags={item.tags}
                                url={item.url}
                                apiLocation={item.apiLocation}
                                integrationsData={integrationsData}
                                openIntegrationPopup={openIntegrationPopup}
                                openIntegrationPopup2={openIntegrationPopup2}
                                openDuxSoupPopup={openDuxSoupPopup}
                                activeID={item.activeID}
                                filterFlag={filterFlag}
                                openFreshSalesPopup={openFreshSalesPopup}
                                turningOffIntegrationSwitch={turningOffIntegrationSwitch}
                                integrationLabel={item.label}
                                removeActiveIntegration = { removeActiveIntegration }
                            /> : ''
                    )
                }
                {/* {popup &&
                <IntegrationPopups activeIntegrations={activeIntegrations} toggelIndex={toggelIndex} integID={integID}
                                   settingPopup={settingPopup} getIntegrations={getIntegrations}
                                   openIntegrationPopup={openIntegrationPopup} popup={popup}
                                   nameForPopup={nameForPopup} label={integrationLabel}/>
                }
                {popup2 &&
                <PopupForTokenIntegrations activeIntegrations={activeIntegrations} toggelIndex={toggelIndex}
                                           integID={integID} settingPopup={settingPopup}
                                           getIntegrations={getIntegrations} popup2={popup2} nameForPopup={nameForPopup}
                                           openIntegrationPopup2={openIntegrationPopup2} apiLocation={apiLocation}
                                           getIntegrations={getIntegrations} label={integrationLabel}/>
                } */}
                {/* {popupDuxSoup &&
                <DuxSoupPopup activeIntegrations={activeIntegrations} toggelIndex={toggelIndex} integID={integID}
                              settingPopup={settingPopup} getIntegrations={getIntegrations} popupDuxSoup={popupDuxSoup}
                              nameForPopup={nameForPopup} openDuxSoupPopup={openDuxSoupPopup}/>
                }
                {popupFreshSales &&
                <FreshSalesPopup activeIntegrations={activeIntegrations} toggelIndex={toggelIndex} integID={integID}
                                 settingPopup={settingPopup} getIntegrations={getIntegrations}
                                 popupFreshSales={popupFreshSales} nameForPopup={nameForPopup}
                                 openFreshSalesPopup={openFreshSalesPopup}/>
                } */}
            </div>
        </div>
    )
}

export default Activated