import {
  INSIDE_LIST_ACTIONS
} from '../constants/constant';

export const INSIDE_LIST_FILTER_INITIAL_STATE = {
  addedByFuzebot: false,
  hasEmail: false,
  personalEmail: false,
  companyEmail: false,
  singleVerifiedEmail: false,
  doubleVerifiedEmail: false,
  hasLinkedIn: false,
  hasPhone: false,
  hasMailingAddress: false,
  from: '',
  to: '',
};

export default function insideListFilter(state = INSIDE_LIST_FILTER_INITIAL_STATE, action) {
  switch (action.type) {
    case INSIDE_LIST_ACTIONS.ADDED_BY_FUZEBOT:
      return {
        ...state,
        addedByFuzebot: action.payload,
      };
    case INSIDE_LIST_ACTIONS.HAS_EMAIL:
      return {
        ...state,
        hasEmail: action.payload,
      };
    case INSIDE_LIST_ACTIONS.PERSONAL_EMAIL:
      return {
        ...state,
        personalEmail: action.payload,
      };
    case INSIDE_LIST_ACTIONS.COMPANY_EMAIL:
      return {
        ...state,
        companyEmail: action.payload,
      };
    case INSIDE_LIST_ACTIONS.SINGLE_VERIFIED_EMAIL:
      return {
        ...state,
        singleVerifiedEmail: action.payload,
      };
    case INSIDE_LIST_ACTIONS.DOUBLE_VERIFIED_EMAIL:
      return {
        ...state,
        doubleVerifiedEmail: action.payload,
      };
    case INSIDE_LIST_ACTIONS.HAS_LINKED_IN:
      return {
        ...state,
        hasLinkedIn: action.payload,
      };

    case INSIDE_LIST_ACTIONS.HAS_PHONE:
      return {
        ...state,
        hasPhone: action.payload,
      };
    case INSIDE_LIST_ACTIONS.HAS_MAILING_EMAIL:
      return {
        ...state,
        hasMailingAddress: action.payload,
      };
    case INSIDE_LIST_ACTIONS.FROM_TO:
      return {
        ...state,
        from: action.payload.from,
        to: action.payload.to,
      };

    case INSIDE_LIST_ACTIONS.CLEAR_ALL:
      return INSIDE_LIST_FILTER_INITIAL_STATE;
    default:
      return state;
  }
}
