import React, { Component } from 'react'
import items from './items'

class IgnoreListHeader extends Component {

  sortList = (index, name) => {
    if(this.props.error || this.props.tableDataLength === 0) {
      return;
    }

    this.props.sortList(index, name);
  }

  render() {
    const { tableDataLength, checkAll, allChecked, showDropDownForView, selectInViewFlag, selectAllFlag, toggleSelect } = this.props
    return (
      <div className="ignore-header">
        <div className="ignore-header__row">
          <div className="ignore-header__check">
            <div className={`check ${allChecked || selectAllFlag || selectInViewFlag ? 'active' : ''}`} onClick={() => checkAll()}></div>
            <img onClick={toggleSelect} id="dropdownImage" src="/assest/arr.svg" alt="" />
            {showDropDownForView && (tableDataLength !== 0) &&
              <div className="dropdown-start" >
                <div id="selectInView" className="dropdown-in-group" onClick={() => checkAll("inView")}>
                  <div id="selectInView" className={`check ${selectInViewFlag ? 'active' : ''}`}></div>
                  <p id="selectInView" className="dropdown-in-ignoreName pagination-item"  >Select In View</p>
                </div>
                <div id="selectInView" className="dropdown-in-group" onClick={() => checkAll("all")}>
                  <div id="selectInView" className={`check ${selectAllFlag ? 'active' : ''}`} ></div>
                  <p id="selectInView" className="dropdown-in-ignoreName pagination-item">Select All</p>
                </div>
                {/* {numberOfSelectedIDs !== 0 &&
                  <div className="dropdown-in-group">
                    <p className="dropdown-in-ignoreName pagination-item"  >Selected Items #{numberOfSelectedIDs}</p>
                  </div>
                } */}
              </div>
            }
          </div>
          <div className="ignore-header__items">
            {
              items.map((item, index) =>
                <div className={`ignore-header__col ${item.clname} ${item.order ? "sorted" : ''}`} key={index}>
                  <p onClick={() => this.sortList(index, item.name)}>{item.name}</p>
                  {
                    item.order &&
                    <div onClick={() => this.sortList(index, item.name)}>{
                      item.order === "asc" ? <img src="/assest/accountBased/sort_asc.svg" alt="" /> : <img src="/assest/accountBased/sort_desc.svg" alt="" />
                    }</div>
                  }
                  {
                    !item.order &&
                    <div onClick={() => this.sortList(index, item.name)}>{
                      <img src="/assest/accountBased/sort.svg" alt="" />
                    }</div>
                  }
                </div>
              )
            }
            <div className="ignore-header__close"></div>
          </div>
        </div>
      </div >
    )
  }
}

export default IgnoreListHeader
