import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from 'react-toastify';
import { bindActionCreators } from "redux";
import { setErrorMessage } from "../../actions/error_msg";
import { startLoader, stopLoader } from "../../actions/loader";
import { openPopup, closePopup } from '../../actions/loader';
import { adwordsBudget, employees, includePastRole, excludeKeywords, hiring, includeKeywords, yearsOfExperience, estimatedSalary, industries, college, companyLocation, personLocation, major, marketBasedAction, news, resetState, role, skills,industries2, departments, technologies, timeInLatestJobAction } from "../../actions/marketBased";
import {
  setMaxResultsPerCompany,
  setMaxPeoplePerCompany,
  setCompanyFoundedYearFrom,
  setCompanyFoundedYearTo

} from '../../actions/search_result_filters';
import { clearAllFilters, setAllSearchFilter, setSearchType } from '../../actions/search_result_filters';
import { AdvLead, MarketSearch, RightPanel, RightPanelBanner, BreadCrumb } from "../../components";
import SaveSearchNameDialog from "../../components/MarkedBasedBox/MarketSearch/SaveSearchNameDialog";
import { SearchType, LocationDropdownType, LOCAL_STORAGE_KEYS, MARKET_BASED_QUERY_PARAM_ACTIONS } from "../../constants/constant";
import { convertTechnologiesFromPropToStats,parseResponseIndestries,parseResponseDepartments, parseResponseLocation, convertLocationFromPropsToStats, getPeopleLookupObjectFromProps } from "../../constants/helper";
import { getSavedSearchItemDetail, saveSearchCriteria } from "../../services/saveSearch";
import SearchResult from "../SearchResult";
import "./style.scss";
import { EmitterService } from "../../services/emitterService";
import { getLeadListById, updateTraits } from "../../services/leads";
import { ROUTES } from "../../routes/routes";
import { Link } from "react-router-dom";
import { Fab } from "@material-ui/core";
const queryString = require('query-string');

class MarketBased extends Component {
  queryParams = {};
  constructor(props) {
    super(props);
    this.state = {
      clearFields: false,
      searchError: false,
      expendSearchCriteria: true,
      expendSearchResult: false,
      hasFiltersVisible: false,
      saveSearchNameDialogOpen: false,
      searchTitle: '',
      peopleCollapse: true,
      advancedCollapse: true,
    };
  }
  componentWillMount = () => {
    EmitterService.emit("loadLeadsRemaining");
    this.props.resetState();
    this.props.clearAllFilters(SearchType.MarketBased);
    // this.props.setSearchType(SearchType.MarketBased);
    this.processQueryParams();
    this.props.closePopup();
  };

  processQueryParams = async () => {
    const queryParams = queryString.parse(this.props.history.location.search);
    this.queryParams = queryParams;
    if (
      queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.PREVIEW_SAVE_SEARCH ||
      queryParams.action === "edit_save_search" ||
      queryParams.action === "preview_leads" ||
      queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.EDIT_FUZEBOT
    ) {
      this.props.startLoader(true);

      switch (queryParams.action) {
        case MARKET_BASED_QUERY_PARAM_ACTIONS.PREVIEW_SAVE_SEARCH:
        case MARKET_BASED_QUERY_PARAM_ACTIONS.PREVIEW_LEADS: {
          this.setState({
            expendSearchCriteria: false,
            expendSearchResult: true,
            hasFiltersVisible: true
          });
          localStorage.setItem("expendSearchResult", true);
          break
        }
        case MARKET_BASED_QUERY_PARAM_ACTIONS.EDIT_FUZEBOT: {
          this.setState({
            hasFiltersVisible: true
          });
          break
        }
      }

      const toastMessage = (queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.PREVIEW_SAVE_SEARCH || queryParams.action === "edit_save_search") ? 'Loading Saved Search' : 'Loading Lead List';

      const searchId = queryParams.id;
      const listId = queryParams.list_id;
      if (listId) {
        // NOTE: this call can be processed in the background, so no 'await'
        getLeadListById(listId)
          .then(res => {
            // this.setState({
            //   searchTitle: <Link to={ROUTES.PEOPLE_LIST}>List name: {res.data.data.name}</Link>
            // })
          })
      }
      let toastId = null;
      toastId = toast(toastMessage, {
        hideProgressBar: true,
        autoClose: 10000
      });
      let detailRes;
      if (queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.PREVIEW_SAVE_SEARCH ) {
        detailRes = await getSavedSearchItemDetail(searchId);
        this.parseResponseFilters(queryParams, detailRes.data.data.query, toastId, false);
      } else if (queryParams.action === "edit_save_search" ) {
        detailRes = await getSavedSearchItemDetail(searchId);
        this.parseResponseFilters(queryParams, detailRes.data.data.query, toastId, true);
      } else {
        detailRes = await getLeadListById(listId);
        if (detailRes.data.data.fuzebot) {
          this.parseResponseFilters(queryParams, detailRes.data.data.fuzebot.query, toastId, true);        } else {
          this.props.stopLoader(true);
          toast.update(toastId, {
            render: "Lead List Loaded",
            className: "toast-success-div",
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
            autoClose: 2000
          });
        }
      }
    }
  };

  parseResponseFilters = (queryParams, detailResData, toastId, isForEdit) => {
    if(!detailResData || !detailResData.people_prospector)
      return;
    const technologies =
      detailResData.people_prospector.technology;

    if (isForEdit && technologies) {
      const totalTechs = convertTechnologiesFromPropToStats(technologies);
      detailResData.people_prospector.technology = totalTechs;
    }
    // ici 3.1
    const companyLocation = parseResponseLocation(detailResData.people_prospector.company_location,
      detailResData.people_prospector.exclude_company_location
      );
    const personLocation = parseResponseLocation(detailResData.people_prospector.people_location,
      detailResData.people_prospector.exclude_people_location
      );

   const indestries2 = parseResponseIndestries(detailResData.people_prospector.industries,
    detailResData.people_prospector.exclude_industries
    );
    const departments = parseResponseDepartments(detailResData.people_prospector.department, 
      detailResData.people_prospector.exclude_exclude_department);

    if (detailResData.people_prospector.estimated_salary) {
        detailResData.people_prospector.estimated_salary = detailResData.people_prospector.estimated_salary.map(eSal => {
          return {
            group: eSal
          }});
    }
    if (detailResData.people_prospector.time_latest_job) {
        detailResData.people_prospector.time_latest_job = detailResData.people_prospector.time_latest_job.map(eSal => {
          return {
            group: eSal
          }});
    }
    if (detailResData.people_prospector.years_of_experience) {
        detailResData.people_prospector.years_of_experience = detailResData.people_prospector.years_of_experience.map(eSal => {
          return {
            group: eSal
          }});
    }
    if (companyLocation) {
      const arrangedLocation = convertLocationFromPropsToStats(
        companyLocation
      );
      detailResData.people_prospector.companyLocation = arrangedLocation;
    }
    if (personLocation) {
      const arrangedLocation = convertLocationFromPropsToStats(
        personLocation
      );
      detailResData.people_prospector.personLocation = arrangedLocation;
    }
    detailResData.people_prospector.industries2 = indestries2;
    detailResData.people_prospector.departments = departments;

    const message = queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.PREVIEW_SAVE_SEARCH || queryParams.action === "edit_save_search"? 'Saved Search Loaded' : 'Fuzebot traits loaded'
    toast.update(toastId, {
      render: message,
      className: "toast-success-div",
      type: toast.TYPE.SUCCESS,
      hideProgressBar: true,
      autoClose: 2000
    });
    this.props.marketBasedAction(
      detailResData.people_prospector
    );
    this.props.setAllSearchFilter(
      {...detailResData.people_prospector, searchType: SearchType.MarketBased}
    );

    if (queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.PREVIEW_SAVE_SEARCH || queryParams.action === "preview_leads") {
      this._loadSearchResults();
    } else {
      this.props.stopLoader(true);
    }
  }

  setIncludeTags = (tags, name) => {
    const { marketBased, role, includeKeywords, hiring, skills, industries2, departments } = this.props;
    switch (name) {
      case "role":
        let currentRoleData = marketBased.role;
        if (currentRoleData === null) {
          currentRoleData = { include_keywords: tags };
        } else {
          currentRoleData.include_keywords = tags;
        }
        role(currentRoleData);
        break;
      case "keywords":
        includeKeywords(tags);
        break;
      case "hiring":
        let currentHiring = marketBased.hiring;
        if (currentHiring === null) {
          currentHiring = { roles: tags };
        } else {
          currentHiring.roles = tags;
        }
        hiring(currentHiring);
        break;
      case "skills":
        let currentSkills = marketBased.skills;
       

        if (currentSkills === null) {
          currentSkills = { include_keywords: tags };
        } else {
          currentSkills.include_keywords = tags;
        }
        skills(currentSkills);

        break;
      case "industries2":
      
        let currentIndustries2 = marketBased.industries2;
        if (currentIndustries2 === null) {
          currentIndustries2 = { include_keywords: tags };
        } else {
          currentIndustries2.include_keywords = tags;
        }
        industries2(currentIndustries2);
        break;
      case "department":
        let currentDepartments = marketBased.departments;
        if (currentDepartments === null) {
          currentDepartments = { include_keywords: tags };
        } else {
          currentDepartments.include_keywords = tags;
        }
        departments(currentDepartments);
        break;      
      default:
        return;
    }
  };
  setExcludeTags = (tags, name) => {
    const { marketBased, role, excludeKeywords, skills, industries2, departments } = this.props;
    switch (name) {
      case "role":
        let currentRoleData = marketBased.role;
        if (currentRoleData === null) {
          currentRoleData = { exclude_keywords: tags };
        } else {
          currentRoleData.exclude_keywords = tags;
        }
        role(currentRoleData);
        break;
      case "keywords":
        excludeKeywords(tags);
        break;
      case "skills":
        let currentSkills = marketBased.skills;
        if (currentSkills === null) {
          currentSkills = { exclude_keywords: tags };
        } else {
          currentSkills.exclude_keywords = tags;
        }
        skills(currentSkills);
        break;
      case "industries2":
        let currentIndustries2= marketBased.industries2;
        if (currentIndustries2 === null) {
          currentIndustries2 = { exclude_keywords: tags };
        } else {
          currentIndustries2.exclude_keywords = tags;
        }
        industries2(currentIndustries2);
        break;
      case "department":
        let currentDepartments = marketBased.departments;
        if (currentDepartments  === null) {
          currentDepartments  = { exclude_keywords: tags };
        } else {
          currentDepartments.exclude_keywords = tags;
        }
        departments(currentDepartments);
        break;
      default:
        return;
    }
  };
  setGroups = (name, data, locationType) => {
    const {
      role,
      employees,
      college,
      major,
      yearsOfExperience,
      timeInLatestJobAction,
      estimatedSalary,
      companyLocation,
      personLocation,
      industries,
      adwordsBudget,
      hiring,
      technologies,
      news,
      skills,
      marketBased,
      industries2,
      departments
    } = this.props;
    switch (name) {
      case "role":
        let currentRoleGroups = marketBased.role;
        if (currentRoleGroups === null) {
          let groupsMap = {};
          for (let i = 0; i < data.length; i++) {
            groupsMap[data[i].group] = {
              open: data[i].open,
              include_keywords: data[i].tags,
              exclude_keywords: data[i].excludeTags
            };
          }
          currentRoleGroups = { groups: groupsMap };
        } else {
          let groupsMap = {};
          for (let i = 0; i < data.length; i++) {
            groupsMap[data[i].group] = {
              open: data[i].open,
              include_keywords: data[i].tags,
              exclude_keywords: data[i].excludeTags
            };
          }
          currentRoleGroups.groups = groupsMap;
        }
        role(currentRoleGroups, "groups");
        break;
      case "employee":
        let groupsMap = {openOrClose: {}};
        for (let i = 0; i < data.length; i++) {
          groupsMap.openOrClose[data[i].group] = data[i].open;
          groupsMap[data[i].group] = data[i].tags;
        }
        employees(groupsMap);
        break;
      case "location":
        if(locationType === LocationDropdownType.Company) {
          companyLocation(data);
        } else {
          personLocation(data);
        }
        break;
      case "college":
        college(data);
        break;
      case "major":
        major(data);
        break;
      case "yearsOfExperience":
        yearsOfExperience(data);
        break;
      case "timeInLatestJob":
        timeInLatestJobAction(data);
        break;
      case "estimatedSalary":
        estimatedSalary(data);
        break;
      case "industry":
        let industryGroups = [];
        for (let i = 0; i < data.length; i++) {
          industryGroups.push(data[i].group);
        }
        
        industries(industryGroups);
        break;
      case "adwords":
        let adwordsGroups = [];
        for (let i = 0; i < data.length; i++) {
          adwordsGroups.push(data[i].label);
        }
        adwordsBudget(adwordsGroups);
        break;
      case "technologies":
        technologies(data);
        break;
      case "hiring":
        let currentHiringGroups = marketBased.hiring;
        if (currentHiringGroups === null) {
          let hiringGroups = [];
          for (let i = 0; i < data.length; i++) {
            hiringGroups.push(data[i].category);
          }
          currentHiringGroups = { groups: hiringGroups };
        } else {
          let hiringGroups = [];
          for (let i = 0; i < data.length; i++) {
            hiringGroups.push(data[i].category);
          }
          currentHiringGroups.groups = hiringGroups;
        }
        hiring(currentHiringGroups, "groups");
        break;
      case "news":
        let newsGroups = [];
        for (let i = 0; i < data.length; i++) {
          newsGroups.push(data[i].group);
        }
        news(newsGroups);
        break;
      case "skills":
        let skillsGroups = [];
        for (let i = 0; i < data.length; i++) {
          skillsGroups.push(data[i].group);
        }
        // news(newsGroups);

        let currentSkills = marketBased.skills;
        if (currentSkills === null) {
          currentSkills = { include_keywords: skillsGroups };
        } else {
          currentSkills.include_keywords = skillsGroups;
        }
        skills(currentSkills);
        break;
      case "industries2":
          let industries2Groups = [];
          for (let i = 0; i < data.length; i++) {
            industries2Groups.push(data[i].group);
          }
          // news(newsGroups);
  
          let currentIndustries2 = marketBased.industries2;
          if (currentIndustries2 === null) {
            currentIndustries2 = { include_keywords: industries2Groups };
          } else {
            currentIndustries2.include_keywords = industries2Groups;
          }
          industries2(currentIndustries2);
          break;
      case "department":
          let departmentsGroups = [];
          for (let i = 0; i < data.length; i++) {
            departmentsGroups.push(data[i].group);
          }
          // news(newsGroups);
  
          let currentDepartments = marketBased.departments;
          if (currentDepartments === null) {
            currentDepartments = { include_keywords: departmentsGroups };
          } else {
            currentDepartments.include_keywords = departmentsGroups;
          }
          departments(currentDepartments);
          break;
    }
  };


  setMaxPeoplePerCompany = async (event) => {
    const { setMaxPeoplePerCompany } = this.props;
    await setMaxPeoplePerCompany(event.target.value);
  }

  setMaxResultsPerCompany = async (event) => {
    const { setMaxResultsPerCompany } = this.props;
    await setMaxResultsPerCompany(event);
  }

  toggleIncludePastRole = async () => {
    const { includePastRole } = this.props;
    await includePastRole(!this.props.marketBased.include_past_roles);
  }

  setCompanyFoundedYearFrom = async (event) => {
    const { setCompanyFoundedYearFrom } = this.props;
    await setCompanyFoundedYearFrom(event);
  }

  setCompanyFoundedYearTo = async (event) => {
    const { setCompanyFoundedYearTo } = this.props;
    await setCompanyFoundedYearTo(event);
  }

  getLeads = async () => {
    const {
      setErrorMessage,
      marketBased,
      setSearchType
    } = this.props;

    const data = {
      ...marketBased,
    };

    EmitterService.emit("startSearching");
    setTimeout(() => {
      setErrorMessage(false);
      this.setState({ searchError: false });

      if (!this.validateMarketSearch()) {
        setErrorMessage(true);
        this.setState({ searchError: true });
      } else {
        window.scrollTo(0, 0);
        this._loadSearchResults();
        this.setState({expendSearchCriteria: false, expendSearchResult: true, hasFiltersVisible: true});
        localStorage.setItem("expendSearchResult", true);
      }
    }, 100)
    
    
  };

  isAnyCompanyCriteriaApplied = () => {
    const {
      companyLocation,
      employees,
      adwords_budget,
      technology,
      news,
      hiring,
    } = this.props.marketBased;

    if (companyLocation && companyLocation.length) {
      return true;
    }

    if (employees && Object.keys(employees).length > 1) {
      return true;
    }
    if (technology && technology.length) {
      return true;
    }

    if (
      hiring &&
      ((hiring.roles && hiring.roles.length) ||
        (hiring.groups && hiring.groups.length))
    ) {
      return true;
    }

    if (
      adwords_budget.length ||
      news.length
    ) {
      return true;
    }
  }

  validateMarketSearch = () => {
    const {
      role,
      companyLocation,
      personLocation,
      college,
      major,
      yearsOfExperience,
      timeInLatestJob,
      estimatedSalary,
      industries,
      employees,
      adwords_budget,
      technology,
      news,
      include_keywords,
      exclude_keywords,
      hiring,
      skills,
      industries2,
      departments
    } = this.props.marketBased;

    const {
      founded_date_from,
      founded_date_to,
      recordsPerPage,
    } = this.props.searchResultFilters;

    if (
      role &&
      ((role.include_keywords && role.include_keywords.length) ||
        (role.exclude_keywords && role.exclude_keywords.length) ||
        (role.groups && Object.keys(role.groups).length))
    ) {
      return true;
    }
    if (
      industries.length ||
      adwords_budget.length ||
      (include_keywords && include_keywords.length) ||
      (exclude_keywords && exclude_keywords.length) ||
      news.length
    ) {
      return true;
    }
    if (
      hiring &&
      ((hiring.roles && hiring.roles.length) ||
        (hiring.groups && hiring.groups.length))
    ) {
      return true;
    }
    if (
      skills &&
      ((skills.include_keywords && skills.include_keywords.length) ||
        (skills.exclude_keywords && skills.exclude_keywords.length))
    ) {
      return true;
    }
    if (
      industries2 &&
      ((industries2.include_keywords && industries2.include_keywords.length) ||
        (industries2.exclude_keywords && industries2.exclude_keywords.length))
    ) {
      return true;
    }
    if (
      departments &&
      ((departments.include_keywords && departments.include_keywords.length) ||
        (departments.exclude_keywords && departments.exclude_keywords.length))
    ) {
      return true;
    }
    if (employees && ((employees.openOrClose && Object.keys(employees).length > 1) ||
    (!employees.openOrClose && Object.keys(employees).length > 0))  ) {
      return true;
    }
    if (technology && technology.length) {
      return true;
    }
    if (companyLocation && companyLocation.length) {
      return true;
    }
    if (personLocation && personLocation.length) {
      return true;
    }
    if (major && major.length) {
      return true;
    }
    if (college && college.length) {
      return true;
    }
    if (estimatedSalary && estimatedSalary.length) {
      return true;
    }
    if (yearsOfExperience && yearsOfExperience.length) {
      return true;
    }
    if (timeInLatestJob && timeInLatestJob.length) {
      return true;
    }
    if (founded_date_from !== 'None' || founded_date_to !== 'None' ) {
      return true;
    }
    return false;
  };

  clearAllFields = () => {
    this.props.clearAllFilters(SearchType.MarketBased);
    this.props.resetState();
    this.setState({ clearFields: true });
  };

  updateClearFields = () => {
    this.setState({ clearFields: false });
  };

  openSaveSearchDialog = () => {
    this.setState({saveSearchNameDialogOpen: true})
  }

  createNewSaveSearch = async(name) => {
    this.onSaveSearchCancel();
    const {marketBased, searchResultFilters} = this.props;
    let toastId = null;
    toastId = toast('Saving search criteria', {
      hideProgressBar: true,
      autoClose: 10000,
    });
    try {
      const res = await saveSearchCriteria(
        marketBased,
        undefined,
        searchResultFilters,
        SearchType.MarketBased,
        name
      );
      toast.update(toastId, {
        render: 'Search criteria saved successfully',
        className: "toast-success-div",
        type: toast.TYPE.SUCCESS,
        hideProgressBar: true,
        autoClose: 2000,
      });
      // await this.props.setSearchResultsList(res.data.data.leads);
      // this.setState({ items: res.data.data.leads, totalItems: res.data.data.total, cursorsByPage: updateCursors});
    } catch (error) {
      toast.update(toastId, {
        render: 'Save Search criteria failed',
        className: "toast-error-div",
        type: toast.TYPE.ERROR,
        hideProgressBar: true,
        autoClose: 2000,
      });
      this.props.setErrorMessage(true);
      this.setState({ searchResultError: true });
    }
  }

  onSaveSearch = () => {
    if (!this.validateMarketSearch()) {
      setErrorMessage(true);
      this.setState({ searchError: true });
    } else {
      setErrorMessage(false);
      this.setState({ searchError: false });
      // if (this.isActionForFuzebot()) {
      //   this.updateFuzeboatTraits();
      // } else {
        this.openSaveSearchDialog();
      // }
    }
  };

  isActionForFuzebot = () => {
    return this.queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.PREVIEW_LEADS
      || this.queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.EDIT_FUZEBOT;
  }

  updateFuzeboatTraits = async () => {
    const { history } = this.props;
    if(this.queryParams.fuzebot == 'null') {
      const traits = getPeopleLookupObjectFromProps(this.props.marketBased, this.props.searchResultFilters);
      let toastId = null;
      toastId = toast('Adding Fuzebot', {
        hideProgressBar: true,
        autoClose: 10000,
      });

      await updateTraits(this.queryParams.list_id, traits);
      toast.update(toastId, {
        render: 'Fuzebot added successfully',
        className: "toast-success-div",
        type: toast.TYPE.SUCCESS,
        hideProgressBar: true,
        autoClose: 2000,
      });
      history.push("/people-lists");
    } else {
      const traits = getPeopleLookupObjectFromProps(this.props.marketBased, this.props.searchResultFilters);
      let toastId = null;
      toastId = toast('Updating Fuzebot', {
        hideProgressBar: true,
        autoClose: 10000,
      });
      //toast.done(toastId)

      await updateTraits(this.queryParams.list_id, traits);
      toast.update(toastId, {
        render: 'Fuzebot updated successfully',
        className: "toast-success-div",
        type: toast.TYPE.SUCCESS,
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }

  applyFilter = async () => {
    if (this.validateMarketSearch()) {
      window.scrollTo(0, 0);
      this._loadSearchResults();
    }
  }

  clearAllFilters = async () => {
    await this.props.clearAllFilters(SearchType.MarketBased);
    this._loadSearchResults();
  }

  _loadSearchResults() {
    EmitterService.emit("loadSearchResults", true);
  };

  changeSearch = () => {
    this.setState({expendSearchCriteria: true, expendSearchResult: false, hasFiltersVisible: false});
    localStorage.removeItem("expendSearchResult");
    //EmitterService.emit("loadSearchResults", false);
  }

  onSaveSearchCancel = () => {
    this.setState({saveSearchNameDialogOpen : false});
  }

  togglePeopleView = () => {
    this.setState({ peopleCollapse: !this.state.peopleCollapse });
  };

  toggleAdvancedView = () => {
    this.setState({ advancedCollapse: !this.state.advancedCollapse });
  };

  renderActions = () => {
    if (this.queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.EDIT_FUZEBOT) {
      return (
        <>
          <Fab
            onClick={this.updateFuzeboatTraits}
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
          >
            {this.queryParams.fuzebot == 'null' ? 'Add Fuzebot' : 'Update Traits'}
          </Fab>
          <Fab
            onClick={this.getLeads}
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
          >
            Preview Results
          </Fab>
        </>
      )
    }
    return (
      <Fab
        onClick={this.getLeads}
        variant="extended"
        size="medium"
        color="primary"
        aria-label="add"
      >
        Start Searching
      </Fab>
    )
  }

  render() {
    const urlQueryTest = queryString.stringify(this.props.marketBased);
    const { clearFields, searchError } = this.state;
    const userId = JSON.parse(localStorage.getItem("user"));
    const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT));

    let links = this.state.expendSearchCriteria ? [
      {
        name: 'Lead Search',
        slug: '/lead-search/market-based',
      },
      {
        name: 'Market Based',
        slug: '/lead-search/market-based',
      },
      {
        name: 'Search Criteria',
        slug: null,
      },
    ] : [
      {
        name: 'Lead Search',
        slug: '/lead-search/market-based',
      },
      {
        name: 'Market Based',
        slug: '/lead-search/market-based',
      },
      {
        name: 'Search Results',
        slug: null,
      },
    ];

    const queryParams = queryString.parse(this.props.history.location.search);
    if ( queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.PREVIEW_SAVE_SEARCH ) {
      links = [
        {
          name: 'Lead Search',
          slug: '/lead-search/market-based',
        },
        {
          name: 'Saved Searches',
          slug: '/lead-search/saved-searches',
        },
        {
          name: queryParams.sname,
          slug: null,
        },
        {
          name: 'Show Results',
          slug: null,
        },
      ];
    }

    if ( queryParams.action === "edit_save_search" ) {
      links = this.state.expendSearchCriteria ? [
        {
          name: 'Lead Search',
          slug: '/lead-search/market-based',
        },
        {
          name: 'Saved Searches',
          slug: '/lead-search/saved-searches',
        },
        {
          name: queryParams.sname,
          slug: null,
        },
        {
          name: 'Edit Traits',
          slug: null,
        },
      ] : [
        {
          name: 'Lead Search',
          slug: '/lead-search/market-based',
        },
        {
          name: 'Saved Searches',
          slug: '/lead-search/saved-searches',
        },
        {
          name: queryParams.sname,
          slug: null,
        },
        {
          name: 'Search Results',
          slug: null,
        },
      ]
    }

    if ( queryParams.action === "edit_fuzebot" ) {
      links = this.state.expendSearchCriteria ? ( queryParams.fuzebot == 'null' ? [
        {
          name: 'Lists',
          slug: '/people-lists',
        },
        {
          name: queryParams.list_name,
          slug: null,
        },
        {
          name: 'Add Fuzebot',
          slug: null,
        },
      ] : [
        {
          name: 'Lists',
          slug: '/people-lists',
        },
        {
          name: queryParams.list_name,
          slug: null,
        },
        {
          name: 'Edit Fuzebot Traits',
          slug: null,
        },
      ] ) : [
        {
          name: 'Lists',
          slug: '/people-lists',
        },
        {
          name: queryParams.list_name,
          slug: null,
        },
        {
          name: 'Preview Results',
          slug: null,
        },
      ]
    }

    if ( queryParams.action === "preview_leads" ) {
      links = [
        {
          name: 'Lists',
          slug: '/people-lists',
        },
        {
          name: queryParams.list_name,
          slug: null,
        },
        {
          name: 'Pending Leads',
          slug: null,
        },
      ];
    }

    const pathname = this.props.history.location.pathname;

    return (
      <div className="marketBased-panel">
        {userId === null && this.props.history.push("/signin?path=/lead-search/market-based")}

        {userAccount && userAccount.status === "trial_expired" && this.props.history.push('/billing-and-users')}

        {pathname.split("?").length === 1 && this.state.expendSearchResult && window.history.pushState('', '',  `${pathname}?results=true`) }

        <BreadCrumb links={links} changeSearch={this.changeSearch}></BreadCrumb>

        <SaveSearchNameDialog open={this.state.saveSearchNameDialogOpen} onCancel={this.onSaveSearchCancel} onSaveSearch={this.createNewSaveSearch} />

        <div className="content-row">
          <div className="content-col-1">
            <div className="markbased__table">
              <MarketSearch
                title={this.state.searchTitle}
                marketBased={this.props.marketBased}
                setExcludeTags={this.setExcludeTags}
                setIncludeTags={this.setIncludeTags}
                setGroups={this.setGroups}
                clearFields={clearFields}
                updateClearFields={this.updateClearFields}
                expendSearchCriteria={this.state.expendSearchCriteria}
                changeSearch={this.changeSearch}
                companyLocationOptions={this.props.loadedData.companyLocationOptions}
                setCompanyFoundedYearFrom={this.setCompanyFoundedYearFrom}
                setCompanyFoundedYearTo={this.setCompanyFoundedYearTo}
                searchResultFilters={this.props.searchResultFilters}
                toggleIncludePastRole={this.toggleIncludePastRole}
                isAnyCompanyCriteriaApplied={this.isAnyCompanyCriteriaApplied()}
                hideChangeSearch={this.queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.PREVIEW_LEADS}
                peopleCollapse={this.state.peopleCollapse}
                togglePeopleView={this.togglePeopleView}
              />

              {this.state.expendSearchCriteria &&
                <AdvLead
                  marketBased={this.props.marketBased}
                  setExcludeTags={this.setExcludeTags}
                  setIncludeTags={this.setIncludeTags}
                  setGroups={this.setGroups}
                  clearAllFields={this.clearAllFields}
                  clearFields={clearFields}
                  updateClearFields={this.updateClearFields}
                  searchError={this.state.searchError}
                  setMaxPeoplePerCompany={this.setMaxPeoplePerCompany}
                  searchResultFilters={this.props.searchResultFilters}
                  resetError={() => this.setState({ searchError: !searchError })}
                  renderActions={this.renderActions}
                  advancedCollapse={this.state.advancedCollapse}
                  toggleAdvancedView={this.toggleAdvancedView}
                />
              }
            </div>

            <section className="mt-20">
              <SearchResult
                expendSearchResult={this.state.expendSearchResult}
              />
            </section>
          </div>

          <div className="content-col-2">
            <div className="markbased__rightbar">
              <RightPanelBanner title="Market Based Demo"  page="marketBased" type="market_based_search" video_id="4shofmeh7t"/>

              {(this.state.hasFiltersVisible || this.queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.EDIT_FUZEBOT) &&
                <RightPanel
                  applyFilter={this.applyFilter}
                  clearAllFilters={this.clearAllFilters}
                  saveSearch={this.onSaveSearch}
                  canApplyFilter={(this.queryParams.action !== MARKET_BASED_QUERY_PARAM_ACTIONS.EDIT_FUZEBOT) || (this.queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.EDIT_FUZEBOT && this.state.expendSearchResult)}
                  updateTraits={this.queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.PREVIEW_LEADS || this.queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.EDIT_FUZEBOT}
                />
              }

            </div>
          </div>

        </div>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      role,
      companyLocation,
      personLocation,
      industries,
      employees,
      college,
      major,
      yearsOfExperience,
      timeInLatestJobAction,
      estimatedSalary,
      technologies,
      adwordsBudget,
      hiring,
      news,
      skills,
      industries2,
      departments,
      includeKeywords,
      excludeKeywords,
      resetState,
      startLoader,
      stopLoader,
      setErrorMessage,
      setSearchType,

      setMaxPeoplePerCompany,
      setMaxResultsPerCompany,
      setCompanyFoundedYearFrom,
      setCompanyFoundedYearTo,

      includePastRole,
      marketBasedAction,
      setAllSearchFilter,
      clearAllFilters,
      openPopup,
      closePopup,
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    user: state.user,
    marketBased: state.marketBased,
    loadedData: state.loadedData,
    searchResultFilters: state.searchResultFilters
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MarketBased));
