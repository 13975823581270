import React, { Component } from 'react';
import '../style.scss';
import Columns from './columns'
import { columnsData } from './columnsDataGoogle';
import { initSpreedSheet, exportListGoogleSheet } from '../../../../services/leads';
import { exportLeadsToGoogleSheet } from '../../../../services/insideList';
import { LOCAL_STORAGE_KEYS } from '../../../../constants/constant';
import { toast } from 'react-toastify';
import { showSuccessToast, showErrorToast } from '../../../../helpers/toast';

class CreateNewSheet extends Component {
    state = {
        columns: columnsData,
        newColumns: columnsData,
        selectedColumns: ["first_name", "last_name", "job_role", "company_email", "company_email_status", "personal_email", "personal_emailStatus", "location", "linkedin", "company_name", "company_website", "company_industry", "company_adwords_budget", "company_employees", "phone", "company_linkedin", "company_mailing_address","mobile_number","company_phone_number","other_phone_numbers"],
        searchValueCol: '',
        email: "",
        sheetName: '',
        automate: false
    }

    /* propss
    searchQuery: string
    selectAll: boolean
    selectedIds: []
    deSelectedIds: []
    */
    componentDidMount() {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        this.setState({ email: user.email })
    }

    selectAll() {
        const columns = this.state.columns.map(col => {
            return {
                ...col,
                check: true,
                }
        });
        const newColumns = [...columns];
        this.setState({ selectedColumns: columns.map(item => item.value), columns, newColumns })
    }

    clearAll() {
        const columns = this.state.columns.map(col => {
            return {
                ...col,
                check: false,
                }
        });
        const newColumns = [...columns];
        this.setState({ selectedColumns: [], columns, newColumns })
    }

    searchColumnFilter = (e) => {
        const { newColumns } = this.state
        const val = e.target.value;
        const columnsFilter = newColumns.filter(item => {
            return item.name.toLowerCase().includes(val)
        })
        this.setState({ columns: columnsFilter })
    }
    changeCheckForColumn = (i, value) => {
        const { selectedColumns, columns } = this.state
        columns[i].check = !columns[i].check;
        this.setState({ columns: columns })
        let newCol
        if (columns[i].check) {
            this.setState({ selectedColumns: selectedColumns.concat(value) })
        }
        else {
            newCol = selectedColumns.filter((el) => el !== value)
            this.setState({ selectedColumns: newCol })
        }
    }
    changeAutomate = () => {
        const { automate } = this.state
        this.setState({ automate: !automate })
    }
    exportSheet = async (isAuth, listId) => {
        const { openNewSheetPopup, openExpGooglePopup } = this.props
        const { searchQuery, selectAll, selectedIds, deSelectedIds, type } = this.props
        const { selectedColumns, email, sheetName, automate } = this.state
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))

        let toastId = null;
        let errormsg;
        toastId = toast(`Exporting to Google Sheets`, {
            autoClose: 10000,
        });
        try {

            if (!isAuth) {
                const res = await initSpreedSheet(user, listId, "/people-lists")
                window.location.href = res.data.data
            } else {
                if (!this.props.insideListFilters) {
                    await exportListGoogleSheet(email, listId, selectedColumns, automate,  undefined, sheetName)
                } else {
                    let query_filters = {
                        added_by_fuzebot: this.props.insideListFilters.addedByFuzebot,
                        has_email: this.props.insideListFilters.hasEmail,
                        personal_email: this.props.insideListFilters.personalEmail,
                        company_email: this.props.insideListFilters.companyEmail,
                        single_verified_email: this.props.insideListFilters.singleVerifiedEmail,
                        double_verified_email: this.props.insideListFilters.doubleVerifiedEmail,
                        has_linkedin: this.props.insideListFilters.hasLinkedIn,
                        has_phone: this.props.insideListFilters.hasPhone,
                        has_mailing_address: this.props.insideListFilters.hasMailingAddress,
                        date_created_from: this.props.insideListFilters.from,
                        date_created_to: this.props.insideListFilters.to
                    };

                    await exportLeadsToGoogleSheet(automate, listId, email, searchQuery, query_filters, selectAll, selectedIds, deSelectedIds, sheetName, undefined, selectedColumns)
                }
                showSuccessToast(toastId, `Export started. Check your email in a few minutes.`);
                openNewSheetPopup()
                openExpGooglePopup();
            }
        } catch (error) {
            if(!error.response) {
                errormsg = `Unable to export to Google Sheets`
            }
            else if (error.response && error.response.data.errCode === 30120) {
                errormsg = 'Permission denied'
            } else {
                errormsg = `Unable to export to Google Sheets`
            }
            showErrorToast(toastId, errormsg);
            openNewSheetPopup()
            openExpGooglePopup();
        }
    }
    changeEmail = (e) => {
        this.setState({ email: e.target.value })
    }

    updateSheetName = (name) => {
        this.setState({sheetName: name});
    }

    render() {
        const { columns, email, automate } = this.state
        const { openNewSheetPopup, popupCreateNewSheet, isAuth, listId, sheetID } = this.props;
        return (
            <div className={`add-list ${popupCreateNewSheet ? 'open' : ''}`}>
                <div className="add-list__content">
                    <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={openNewSheetPopup} />
                    <p className="add-list__title">Export To Google Sheets</p>
                    <p className="add-list__label field-margin">Sheet Name</p>
                    <input className="add-list__search" placeholder="Sheet name" type="text" value={this.state.sheetName} onChange={(e) => this.updateSheetName(e.target.value)} />
                    <p className="add-list__label field-margin">Email</p>
                    <input className="add-list__search" placeholder="Enter Email" type="email" value={email} onChange={this.changeEmail} />
                    <p className="add-list__label field-margin">Columns</p>
                    <div className="coupon-search__inp sm_coupon-search__inp">
                        <img src="/assest/accountBased/search.svg" alt="" />
                        <input type="text" placeholder="Search Columns" onInput={this.searchColumnFilter} />
                    </div>
                    <div className="scroll_view">
                        <Columns columns={columns} changeCheckForColumn={this.changeCheckForColumn} />
                    </div>
                    <p className="add-list__title add-list__clear field-margin">
                        <span onClick={() => this.selectAll()}>Select All</span>
                        <span onClick={() => this.clearAll()}>Clear All</span>
                    </p>
                    {/* <p className="add-list__title add-list__clear field-margin" onClick={() => this.clearAll()}>Clear All</p> */}

                    {listId? (
                    <div className="right-filters__check check-margin" onClick={() => this.changeAutomate()}>
                        <div className={`check ${automate ? 'active' : ''}`}>
                        </div>
                        <p>Automatically add new leads to this sheet</p>
                    </div>
                    ) : null}
                    <p className="add-list__create">
                    </p>
                    <div className="add-list__buttons">
                        <div className={`add-list__btn orange ${!isAuth || !this.state.sheetName ? "disable-btn" : ''}`} onClick={() => { this.exportSheet(isAuth, listId) }} >Export Sheet</div>
                        <div className="add-list__btn orange-light" onClick={openNewSheetPopup}>Back to Select</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateNewSheet;
