import React, {Component} from 'react';
import './style.scss';

class DoubleLeadCreditsPopup extends Component {

    onCancel = () => {
        this.props.showCancelPopup(true)
    }

    onDoubleLeadCredits = () => {
        this.props.onDoubleLeadCredits()
    }

    render() {
        return (
            <div className="popup-pause">
                <div className="popup-pause__container">
                    <img className="popup-pause__close" src="/assest/accountBased/close.svg" alt=""
                         onClick={() => this.props.showDoubleLeadCreditsPopup(false)}/>
                    <p className="popup-pause__title">Double your lead credits</p>
                    <span className="popup-pause__text">By cancelling your account you understand</span>
                    <br/><br/>
                    <ul className="popup-pause__list">
                        {this.props.subscriptionDiscount != "" ?
                            <li>You will not longer get {this.props.subscriptionDiscount} off on
                                your {this.props.planName.indexOf("annual") > -1 ? "annual" : "monthly"} bill.
                            </li> : <span></span>}
                        {this.props.extraLeadCredits != "" ?
                            <li>You will no longer get {this.props.extraLeadCredits} extra lead
                                credits per {this.props.planName.indexOf("annual") > -1 ? "year" : "month"}.</li> :
                            <span></span>}
                        <li>You will no longer be able to access or legally use any of the data LeadFuze has supplied to
                            you.
                        </li>
                        <li>Any unused lead credits will be forfeited.</li>
                        <li>Your account will be
                            cancelled {this.props.planName.indexOf("commitmentl") > -1 ? "before your contract renewal date" : "on your next billing cycle"}.
                            You will have
                            access to your account until then and won't be billed further.
                        </li>
                    </ul>

                    <p className="popup-pause__text">If we offered to double your lead credits at no additional charge,
                        would you be willing to stay on as a customer?</p>
                    <div className="popup-pause__buttons">
                        {
                            this.props.hasDoubledLeadCreditsBefore ?
                                <div className="popup-pause__btn"
                                     onClick={() => this.props.showDoubleLeadCreditsPopup(false)}>Keep my double lead
                                    credit offer</div> :
                                <div className="popup-pause__btn"
                                     onClick={this.onDoubleLeadCredits}>Yes - Double my
                                    lead credits!</div>
                        }

                        <div className="popup-pause__btn cancel" onClick={this.onCancel}>No thanks</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DoubleLeadCreditsPopup;