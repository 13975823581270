import React, {Component, Fragment} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {NavSearch, Sidebar,} from './components'
import {FindLeads, ForgotPass, Page404, ResetPass, SignIn} from './containers'
import routes from './routes'
import './App.scss';
import './scss/responsive.scss';
import {connect} from 'react-redux';
import {ThemeProvider} from '@material-ui/core';
import {theme} from './materialTheme';
import {LOCAL_STORAGE_KEYS} from './constants/constant';

function PrivateRoute({component: Component, ...rest}) {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
    let isAdmin = user ? user.is_admin : false;

    // TODO: uncomment when go live
    // if(rest.path == '/billing-and-users' && !isAdmin)
    //   return <Route component={Page404} />

    return (
        <Route
            {...rest}
            render={props => (
                user
                    ? <Component {...props} />
                    : <Redirect to="/signin"/>
            )}
        />
    );
}

class App extends Component {

    state = {
        sideMenu: false
    }
    sideMenuToggel = (toggel) => {
        if (toggel === "open") {
            this.setState({sideMenu: !this.state.sideMenu})
        } else if (toggel === "close") {
            this.setState({sideMenu: false})
        }
    }

    componentWillMount() {
        this.props.history.listen(this.onRouteChange.bind(this));
        //this.props.history.replace({pathname: '/desiredRoute'});
        if (this.props.history.location.pathname == '/#/billing')
            this.props.history.replace({pathname: '/billing-and-users'});

    }

    onRouteChange = (route) => {
        if (route.pathname == '/#/billing')
            this.props.history.replace({pathname: '/billing-and-users'});
    }


    render() {

        // const { fetching, dog, onRequestDog, error } = this.props;
        const {user} = this.props
        const {sideMenu} = this.state
        const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT))

        return (
            <ThemeProvider theme={theme}>
                <Switch>

                    <Route path="/hub.html"/>
                    <Route path="/forgot-pass" component={ForgotPass}/>
                    <Route path="/find-leads" component={FindLeads}/>
                    <Route path="/signin" component={SignIn}/>
                    <Route path="/reset-pass" component={ResetPass}/>
                    <Route
                        path="/#/ignorelist"
                        render={() => {
                            return (
                                <Redirect to="/ignore-list"/>
                            )
                        }}
                    />
                    <Route path="billing"><Redirect to="/billing-and-users"/></Route>
                    <Route path="#/addleads"><Redirect to="/people-lists"/></Route>
                    <Fragment>
                        <main className="accbased">
                            <ToastContainer rtl={true}/>
                            <div className={`sidebar-fixed ${sideMenu ? "left-menu" : ''}`}>
                                <div className="bg-gray" onClick={() => this.sideMenuToggel("close")}></div>
                                {userAccount !== null &&
                                <Sidebar userProfileInfo={this.props.userProfileInfo.userInfo}/>
                                }
                            </div>
                            <div className="accbased__right">
                                <NavSearch sideMenuToggel={this.sideMenuToggel}/>                                
                                {/* <div class="success-div-logout">
                                    <p>We are making infrastructure changes and leads won't be available for several hours tonight.</p>
                                </div> */}                                
                                <div
                                    className={`accbased__content ${localStorage.getItem("expendSearchResult") !== null ? 'expanded' : ''} ${user.errorDiv ? 'accbased__content-error' : ''} ${user.successDiv ? 'accbased__content-error' : ''}`}>
                                    <div className="page-content">
                                        <Switch>
                                            {
                                                routes.map((item, index) =>
                                                    <PrivateRoute key={index} exact path={item.path}
                                                                  component={item.component}/>
                                                )
                                            }
                                            <Route component={Page404}/>
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </Fragment>
                </Switch>
            </ThemeProvider>
        );
    }
}

// const mapStateToProps = state => {
//   return {
//     fetching: state.fetching,
//     dog: state.dog,
//     error: state.error
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return {
//     onRequestDog: () => dispatch({ type: 'API_CALL_REQUEST' })
//   };
// };

const mapStateToProps = state => {
    return {
        user: state.user,
        userProfileInfo: state.userProfileInfo
    };
};
export default withRouter(connect(
    mapStateToProps,
)(App))
