import React, { Component, Fragment } from 'react';
import '../style.scss';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { createList } from '../../../../services/leads';
import { isNotEmpty } from '../../../../helpers/validate';
import { LOCAL_STORAGE_KEYS } from '../../../../constants/constant';
import { showSuccessToast, showErrorToast } from '../../../../helpers/toast';

class AddNewList extends Component {
    state = {
        popup: this.props.popup,
        value: "",
    }

    handleChange = (event) => {
        this.setState({ value: event.target.value, errorMessage: '' });
    }

    onAddFunction = async (val) => {
        if (!isNotEmpty(val)) {
            this.setState({errorMessage: 'List name can not be empty'});
            return;
        }
        const { leadList, openNewListPopup } = this.props
        let toastId = null;
        let errormsg
        toastId = toast('Creating new list', {
            autoClose: 10000,
        });
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        try {
            const res = await createList(user.id, user.account_id, val)
            showSuccessToast(toastId, "List created successfully")
            openNewListPopup()
            leadList();
            this.setState({ value: "", errorMessage: '' })
        } catch (error) {
            if (error.response.data.errCode === 30600) {
                errormsg = 'List name can not be blank'
            } else if (error.response.data.errCode === 30700) {
                errormsg = 'List name already exists'
            } else {
                errormsg = 'Permission Denied'
            }
            showErrorToast(toastId, errormsg)
            this.setState({ value: "", errorMessage: '' })
        }
    }
    onClosePopup = () => {
        const { openNewListPopup } = this.props
        this.setState({ error: 0, value: "", errorMessage: '' })
        openNewListPopup()
    }

    render() {
        const { value, errorMessage } = this.state
        const { popupNewList } = this.props;
        return (
            <Fragment>
                <div className={`add-list ${popupNewList ? 'open' : ''}`}>
                    <div className="add-list__content">
                        <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={this.onClosePopup} />
                        <p className="add-list__title">Create New List</p>
                        <p className="add-list__label">List Name</p>
                        <div className="add-list__search-box">
                            <input className="add-list__search"
                                placeholder="Enter List Name"
                                type="text"
                                value={this.state.value}
                                onChange={this.handleChange}
                            />
                            {
                                errorMessage
                                    ? (
                                        <div className="lead-header__model_error">
                                            <p>{errorMessage}</p>
                                        </div>
                                    )
                                    : null
                            }
                        </div>
                        <p className="add-list__create">
                        </p>
                        <div className="add-list__buttons">
                            <div className="add-list__btn orange" onClick={() => { this.onAddFunction(value) }} >Create List</div>
                            <div className="add-list__btn orange-light" onClick={this.onClosePopup}>Cancel</div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default AddNewList;
