import React, {useEffect, useState} from 'react'
import ConfirmationPopup from "../../../ConfirmationPopup";
import {updateSubscription, validateCoupon} from "../../../../services/billing";
import {EmitterService} from '../../../../services/emitterService'
import {Loader,} from '../../../../components'
import {withStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import {billingFormatter} from '../../../../constants/helper';
import {toast} from 'react-toastify';
import './style.scss'

const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const PrettoSlider = withStyles({
    root: {
        color: '#ec971d',
        height: 8,
    },
    thumb: {
        height: 18,
        width: 18,
        color: '#fff',
        backgroundColor: '#ec971d',
        border: '5px solid currentColor',
        boxShadow: '0 0 4px rgba(139, 139, 139, 0.5)',
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 6,
        borderRadius: 4,
    },
    rail: {
        height: 6,
        borderRadius: 3,
        background: '#eeeeee',
        opacity: 1
    },
    mark: {
        display: 'none'
    },
    markLabel: {
        fontSize: 10,
        color: '#bdbdbd',
        '&::before': {
            content: ' ',
            display: 'block',
            height: 10,
            width: 1,
        },
    }
})(Slider);


const line = [
    {progress: 500, active: true},
    {progress: 750, active: true},
    {progress: 1000, active: true},
    {progress: 1500, active: false},
    {progress: 2000, active: false},
    {progress: 2500, active: false},
    {progress: 3000, active: false},
    {progress: 4000, active: false},
    {progress: 5000, active: false},
    {progress: 6000, active: false},
    {progress: 7500, active: false},
    {progress: 10000, active: false},
    // {progress: 'unlimited', active: false},
]

const marks = [
    {value: 1, label: 500},
    {value: 3, label: 750},
    {value: 5, label: 1000},
    {value: 7, label: 1500},
    {value: 9, label: 2000},
    {value: 11, label: 2500},
    {value: 13, label: 3000},
    {value: 15, label: 4000},
    {value: 17, label: 5000},
    {value: 19, label: 6000},
    {value: 21, label: 7500},
    {value: 23, label: 10000},
];


const billings = [
    {
        name: 'monthly',
        description: 'This billing schedule is month-to-month. You can cancel any time. You are missing savings and free lead credit bonuses when on this schedule.',
        price: 9700,
        leads: 250,
        times: 1
    },
    {
        name: 'commitment',
        description: 'This is an annual plan, but you are billed in 12 monthly installments. You will receive discounted pricing and additional free lead credits.',
        price: 8730,
        leads: 250,
        times: 1.2
    },
    {
        name: 'annual',
        description: 'When selecting this schedule, you are paying 12 months upfront. You get 2 months free, 50% free lead credits, AND get all lead credits upfront.',
        price: 93120,
        leads: 250,
        times: 1.5
    },
]

const PlanDetails = ({billingOverview, loadBillingOverview}) => {
    const [isShowCancelFeedbackPopup, setIsShowCancelFeedbackPopup] = useState(false);
    const [isShowCancelSubscriptionPopup, setIsShowCancelSubscriptionPopup] = useState(false);
    const [isShowChangeConfirmation, setIsShowChangeConfirmation] = useState(false);
    const [isShowClearConfirmation, setIsShowClearConfirmation] = useState(false);
    const [selectedPlanName, setSelectedPlanName] = useState('commitment');
    const [leads, setLeads] = useState(250);
    const [selectedMark, setSelectedMark] = useState(marks[0]);
    const [coupon, setCoupon] = useState('');
    const [couponApplied, setCouponApplied] = useState(undefined);
    const [isShowCoupon, setIsShowCoupon] = useState(false);

    useEffect(() => {
        if (billingOverview) {
            let plan_str = billingOverview.plan_name.split("_");
            if (billingOverview.plan_name.indexOf("unlimited")) {
                setLeads("500");
                setSelectedMark(marks.find(mark => {
                    return mark.label == "500"
                }));
            } else {
                setLeads(plan_str[2]);
                setSelectedMark(marks.find(mark => {
                    return mark.label == plan_str[2]
                }));
            }

        }
    }, [billingOverview]);

    const toggleShowCancelFeedbackPopup = () => {
        setIsShowCancelFeedbackPopup(!isShowCancelFeedbackPopup)
    }

    const onCancelSubscription = async (reason) => {
        setIsShowCancelFeedbackPopup(false);
    }

    const onChangeProgress = (event, value) => {
        let mark = marks.find(mark => {
            return mark.value == value
        })
        setSelectedMark(mark);
        setLeads(Number(mark.label));
    }

    const onChangePlan = (plan_name) => {
        setSelectedPlanName(plan_name);
        setIsShowChangeConfirmation(true);
    }

    const formatPrice = (price) => {
        return price / 100.0;
    }

    const getDiscountedAmount = (price) => {
        if (couponApplied && couponApplied.is_stripe) {
            const couponCredit = couponApplied.lead_credits;
            const isPercentage = couponApplied.is_lead_credits_percentage;
            let discountedPrice;
            if (isPercentage) {
                discountedPrice = Number(price - (price * couponCredit / 100)).toFixed(2);
            } else {
                discountedPrice = Number(price - (couponCredit / 100)).toFixed(2);
            }
            return discountedPrice;
        }
        return price.toFixed(2);
    }

    const getPrice = (plan_name, leads) => {
        let selectedPlan = billingOverview.plans[plan_name].find(plan => plan.metadata.leads == leads);
        return getDiscountedAmount(formatPrice(selectedPlan.amount));
    }

    const getNewPlanId = () => {
        let selectedPlan = billingOverview.plans[selectedPlanName].find(plan => plan.metadata.leads == leads);
        return selectedPlan.id;
    }

    const getOldPlanNickname = () => {
        let plan_str = billingOverview.plan_name.split("_");
        let selectedPlan = billingOverview.plans[plan_str[0]].find(plan => plan.id == billingOverview.plan_name);
        return selectedPlan.nickname;
    }

    const getNewPlanNickname = () => {
        let selectedPlan = billingOverview.plans[selectedPlanName].find(plan => plan.metadata.leads == leads);
        return selectedPlan.nickname;
    }

    const getUpgradeText = () => {
      /*
        let userAccount = JSON.parse(localStorage.getItem("userAccount"));  
        if (userAccount && userAccount.plan_name.indexOf("lf_starter_plan") > -1 && userAccount.status.indexOf("active_trial") > -1) {
            return `You are changing your plan from Trial to ${getNewPlanNickname()}. You will be billed $${billingFormatter.format(getPrice(selectedPlanName, selectedMark.label))} right away. Do you want to continue?`;
         }
    */
        let plan_str = billingOverview.plan_name.split("_");
        let old_plan = plan_str[0];
        let new_plan = selectedPlanName;
        let old_leads = plan_str[2];
        let new_leads = leads;
        if (new_plan == 'monthly' || old_plan == new_plan && new_plan == 'commitment' && Number(new_leads) < Number(old_leads)) {
            return `You are changing your plan from ${getOldPlanNickname()} to ${getNewPlanNickname()}. This change will happen at the end of your contract. Do you want to continue?`;
        }
        return `You are changing your plan from ${getOldPlanNickname()} to ${getNewPlanNickname()}. You will be billed $${billingFormatter.format(getPrice(selectedPlanName, selectedMark.label))} right away. Do you want to continue?`;
    }

    const toggleShowCancelPopup = () => {
        setIsShowCancelSubscriptionPopup(!isShowCancelSubscriptionPopup)
    }

    const isSubscriptionOnMaintenanceMode = () => {
        return false;
    }

    const closeConfirmationPopup = () => {
        setIsShowChangeConfirmation(false);
        setIsShowClearConfirmation(false);
    }

    const onMaintainSubscription = async () => {
        toggleShowCancelPopup();
    }

    const onCancelPlan = () => {
        EmitterService.emit("cancelPlan");
        //setIsShowClearConfirmation(true);
    }

    const changePlan = async () => {
        closeConfirmationPopup();
        let selectedPlan = billingOverview.plans[selectedPlanName].find(plan => plan.metadata.leads == leads);
        if (selectedPlan) {
            let plan_id = selectedPlan.id;
            let coupon = couponApplied ? couponApplied.name : '';
            const toastId = toast("Updating Subscription...", {hideProgressBar: true, autoClose: 10000});

            try {
                const response = await updateSubscription(plan_id, coupon);


                toast.update(toastId, {
                    render: "Subscription updated successfully",
                    className: "toast-success-div",
                    type: toast.TYPE.SUCCESS,
                    hideProgressBar: true,
                    autoClose: 2000
                });
            } catch (error) {
                toast.update(toastId, {
                    className: "toast-error-div",
                    render: "Unable to update subscription",
                    type: toast.TYPE.ERROR,
                    autoClose: 3000
                });
            }

            loadBillingOverview();
        }
    }

    const cancelPlan = () => {
        closeConfirmationPopup();
    }

    const setCouponText = (e) => {
        setCoupon(e.target.value);
    }

    const showCoupon = () => {
        setIsShowCoupon(true);
    }

    const applyCoupon = async () => {
        let toastId = null;
        try {
            toastId = toast('Applying Coupon', {autoClose: 10000});

            const couponRes = await validateCoupon(coupon);

            setCouponApplied(couponRes.data.data);

            setTimeout(() => {
                toast.update(toastId, {
                    className: "toast-success-div",
                    render: "Applied Coupon successfully",
                    type: toast.TYPE.SUCCESS,
                    autoClose: 1300,
                });
            }, 200);
        } catch (e) {
            setCouponApplied(undefined);

            setTimeout(() => {
                toast.update(toastId, {
                    className: "toast-error-div",
                    render: "Coupon is not valid",
                    type: toast.TYPE.ERROR,
                    autoClose: 1300,
                });
            }, 200);
        }
    }

    return (
        <>
            {!billingOverview ? <Loader/>
                : <section className="billing-details">
                    <h3 className="billing-details__title">How many lead credits do you need each month?
                        <div className="billing-details__img"><img src="/assest/icon-help.svg" alt=""/>
                            <p className="billing-details__help">A general rule of thumb is 500 to 1000 per user. About
                                20% of
                                our users do more (on a per user basis). Each lead comes with contact info (email,
                                phone,
                                mailing address, social, etc) for you to use.<span></span></p></div>
                    </h3>
                    <p className="billing-details__descr">Drag the slider below to your desired number of lead
                        credits.<br/>
                        Upgrades go into effect immediately and will reset your billing date. New lead credits will be
                        available
                        immediately.
                        <br/>Commitment and Annual bonuses are added on top of the amount you choose. Downgrades will go
                        into effect on your next billing cycle.
                    </p>
                    <PrettoSlider
                        getAriaValueText={() => 'valuetext'}
                        value={selectedMark ? selectedMark.value : 0}
                        step={null}
                        marks={marks}
                        valueLabelDisplay="off"
                        min={0}
                        max={24}
                        onChange={onChangeProgress}
                    />
                    {/* <div className="billing-details__line"><span></span>
                {leads == 250 && <div className="billing-details__pin"></div> }
            </div>
            <div className="billing-details__field">
                {
                    line.map((item, index) =>
                        <div className={item.progress == leads ? 'billing-details__progress selected' : 'billing-details__progress'} 
                            key={'line' + index}
                            onClick={() => setProgress(item.progress)}
                            >
                            <span></span>
                            <p>{item.progress == 'unlimited' ? <p class="infinity">&#8734;</p> : item.progress}</p>
                            {item.progress == leads && 
                                <div className="pin"></div>
                            }
                        </div>
                    )
                }
            </div> */}
                    <div className="billing-details__boxes">
                        {
                            billings.map((item, index) => {
                                    var pl = billingOverview.plans[item.name].find(plan => plan.metadata.leads == leads)
                                    return (
                                        <div className={pl.id == billingOverview.plan_name ? 'box active' : 'box'}
                                             key={'box' + index}>
                                            {item.name == "commitment" &&
                                            <div className="tags">
                                                <div className="tag">
                                                    <div className="percentage">- 10%</div>
                                                    <span>of cost</span>
                                                </div>
                                                <div className="tag">
                                                    <div className="percentage">+ 20%</div>
                                                    <span>to leads</span>
                                                </div>
                                            </div>
                                            }
                                            {item.name == "annual" &&
                                            <div className="tags">
                                                <div className="tag">
                                                    <div className="percentage">- 20%</div>
                                                    <span>of cost</span>
                                                </div>
                                                <div className="tag">
                                                    <div className="percentage">+ 50%</div>
                                                    <span>to leads</span>
                                                </div>
                                            </div>
                                            }
                                            <div className="name">
                                                {item.name}
                                            </div>
                                            <div className="price-line">
                                                <span className="currency">$</span><span
                                                className="price">{billingFormatter.format(getPrice(item.name, selectedMark.label))}</span> per {item.name == 'annual' ? 'year' : 'month'}
                                            </div>
                                            <div className="description">
                                                {item.description}
                                            </div>
                                            <div className="feature-list">
                                                <p><img src="/assest/accountBased/check-orange.svg" alt=""/> <span
                                                    className="leads">{selectedMark.label * item.times}</span> Leads Credits
                                                    per Month</p>
                                                <p><img src="/assest/accountBased/check-orange.svg" alt=""/> Unlimited Lists
                                                </p>
                                                <p><img src="/assest/accountBased/check-orange.svg" alt=""/> Account Based
                                                    Search Filters</p>
                                                <p><img src="/assest/accountBased/check-orange.svg" alt=""/> Market Based
                                                    Search Filters</p>
                                                <p><img src="/assest/accountBased/check-orange.svg" alt=""/> Fuzebot List
                                                    Build Automation</p>
                                                <p><img src="/assest/accountBased/check-orange.svg" alt=""/> Ignore Lists
                                                </p>
                                                <p><img src="/assest/accountBased/check-orange.svg" alt=""/> Export to CSV
                                                </p>
                                                <p><img src="/assest/accountBased/check-orange.svg" alt=""/> De-Duplication
                                                </p>
                                                <p><img src="/assest/accountBased/check-orange.svg" alt=""/> Integrations
                                                </p>
                                            </div>
                                            <div className="actions">
                                                {pl && pl.id != billingOverview.plan_name
                                                    ? <div className="button sidebarText__btn" style={{"color":"white"}} onClick={() => onChangePlan(item.name)}>Choose
                                                        Plan</div>
                                                    : <div className="white-button" onClick={onCancelPlan}>Cancel Plan</div>
                                                }
                                                {/* {
                                    billingOverview && billingOverview.plan_name.includes(item.name) && <div className="white-button" onClick={onCancelPlan}>Cancel Plan</div>
                                } */}
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }

                    </div>
                    <div className="couponSection">
                        {!isShowCoupon ? <label className="title" onClick={showCoupon}>Have a coupon ? </label> :

                            <div className="billing-section">
                                <div className="input-group">
                                    <input type="text" placeholder="Enter Coupon" value={coupon}
                                           onChange={setCouponText}/>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button"
                                                onClick={applyCoupon}
                                                disabled={coupon.length === 0}>APPLY
                                        </button>
                                    </div>
                                </div>
                                {couponApplied ?
                                    <p className="applied-coupon">{couponApplied.name} coupon applied</p> : null}
                            </div>}
                    </div>
                    {/* <input type="range" id="volume" name="volume"
         min="0" max="12"></input> */}
                    {/* {isShowCancelFeedbackPopup &&
                <CancelFeedbackPopup
                    showCancelFeedbackPopup={toggleShowCancelFeedbackPopup}
                    onCancelSubscription={onCancelSubscription}
                />
            }
            {isShowClearConfirmation &&
                <CancelSubscriptionPopup
                    isSubscriptionOnMaintenanceMode={isSubscriptionOnMaintenanceMode()}
                    showCancelPopup={toggleShowCancelPopup}
                    showCancelFeedbackPopup={toggleShowCancelPopup}
                    onMaintainSubscription={onMaintainSubscription}
                />
            } */}
                    {isShowChangeConfirmation &&
                    <ConfirmationPopup
                        title="Upgrade Plan"
                        message={getUpgradeText()}
                        yesBtnText="Pay"
                        noBtnText="Cancel"
                        onYesClick={changePlan}
                        onNoClick={closeConfirmationPopup}
                        onCloseClick={closeConfirmationPopup}/>
                    }
                    {isShowClearConfirmation &&
                    <ConfirmationPopup
                        title="Are you sure to cancel?"
                        message={`The change will take into effect at the end of your contract. Do you want to continue?`}
                        yesBtnText="Confirm"
                        noBtnText="Cancel"
                        onYesClick={cancelPlan}
                        onNoClick={closeConfirmationPopup}
                        onCloseClick={closeConfirmationPopup}/>
                    }
                </section>
            }
        </>
    )
}

export default PlanDetails