import axios from '../axios';
const queryString = require('query-string');

const POOL_SERVICE_URL = process.env.REACT_APP_POOL_SERVICE_URL;

export function getColleges(search) {
  const user = JSON.parse(localStorage.getItem("user"));
  const queryStr = queryString.stringify({search});
  return axios({
    method: "get",
    url: `/pool/college?${queryStr}`
  });
}
