import axios from '../axios';

export function getBillingOverview() {
    return axios({
        method: 'get',
        url: `/core/billing-overview`,
    });
}

export function extendTrial() {
    return axios({
        method: 'put',
        url: `/core/extend-trial`,
    });
}

export function validateCoupon(coupon) {
    return axios({
        method: 'get',
        url: `/core/validate-coupon/${coupon}`,
    });
}

export function createSubscription(plan_id, coupon) {
    return axios({
        method: 'post',
        url: `/core/create-subscription`,
        data: {
            plan_id,
            coupon,
        }
    });
}

export function updateSubscription(plan_id, coupon) {
    return axios({
        method: 'post',
        url: `/core/update-subscription`,
        data: {
            plan_id,
            coupon,
        }
    });
}

export function cancelSubscription(maintenance, reason) {
    return axios({
        method: 'post',
        url: `/core/cancel-subscription`,
        data: {
            maintenance,
            reason,
        }
    });
}

export function doubleLeadCredits() {
    return axios({
        method: 'get',
        url: `/core/double-lead-credits`
    });
}

export function addBillingUser(user) {
    const users = [];
    users.push(user);

    return axios({
        method: 'post',
        url: `/core/add-users`,
        data: {
            users: users
        }
    });
}

export function updateBillingUser(user) {
    return axios({
        method: 'post',
        url: `/core/update-user`,
        data: user
    });
}

export function deleteBillingUser(userId) {
    return axios({
        method: 'delete',
        url: `/core/remove-user/${userId}`,
    });
}

export function addUsersCostPreview(number_of_users) {
    return axios({
        method: 'post',
        url: `/core/add-users-cost-preview`,
        data: {
            number_of_users,
        }
    });
}

export function revertSubscriptionCancellation() {
    return axios({
        method: 'get',
        url: `/core/cancel-subscription-revert`,
    });
}

export function revertSubscriptionUpdate() {
    return axios({
        method: 'get',
        url: `/core/update-subscription-revert`,
    });
}

export function addCard(number, cvc, brand, exp_month, exp_year) {
    return axios({
        method: 'post',
        url: `/core/add-card`,
        data: {
            card: {
                number,
                cvc,
                brand,
                exp_month,
                exp_year,
            },
        }
    });
}

export function updateCard(id, exp_month, exp_year) {
    return axios({
        method: 'post',
        url: `/core/update-card`,
        data: {
            card: {
                id,
                exp_month,
                exp_year,
            },
        }
    });
}

export function getBillingUsers(currentPage, recordsPerPage, searchQuery) {
    const user = JSON.parse(localStorage.getItem("user"));

    return axios({
        method: 'get',
        headers: {
            userID: user.id,
            accountID: user.account_id
        },
        url: `/core/account-users?pageNum=${currentPage}&recordsPerPage=${recordsPerPage}&search=${searchQuery}`,
    });
}

export function getBillingHistory() {
    const user = JSON.parse(localStorage.getItem("user"));

    return axios({
        method: 'get',
        headers: {
            userID: user.id,
            accountID: user.account_id
        },
        url: `/core/past-invoices`,
    });
}
