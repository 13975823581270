export const adwords_budget = [
  {
    category: "None",
    label: "None",
    group: "None",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: "Oneplus",
    label: "$1 to $1,000",
    group: "$1 to $1,000",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: "OneThousandPlus",
    label: "$1,001 to $5,000",
    group: "$1,001 to $5,000",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: "FiveThousandPlus",
    group: "$5,001+",
    label: "$5,001+",
    include_tags: [],
    exclude_tags: []
  }
];
