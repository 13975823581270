import React, {Component} from 'react';
import CampaignDropDown from './CampaignDropDown';
import '../style.scss';
import {connect} from 'react-redux'
import $ from 'jquery';
import {
    addIntegrationLeads,
    getIntegrationCampaigns,
    getJazzhrJobs,
    updateIntegrations
} from '../../../../services/leads';
import {toast} from 'react-toastify';

const woodpecker = "woodpecker"
const lemlist = "lemlist"
const reply = "reply"
const highlevel = "highlevel"
const jazzhr = "jazzhr"

class Woodpecker extends Component {
    state = {
        active: false,
        value: "",
        selectedValue: "",
        campaigns: [],
        selectedCampaign: undefined,
        make_default: false,
        loading: true,
        filteredCampaigns: []
    }

    componentWillMount() {
        const type = this.props.type;
        const that = this;
        let camapaignPromise;
        if (type === jazzhr) {
            camapaignPromise = getJazzhrJobs();
        } else {
            camapaignPromise = getIntegrationCampaigns(type);
        }
        camapaignPromise.then(response => {
            if (response.data.data) {
                let campaigns = response.data.data;
                if (type === lemlist) {
                    campaigns = campaigns.map(comp => {
                        return {
                            id: comp._id,
                            name: comp.name,
                            ...comp,
                        }
                    })
                } else if (type === woodpecker) {
                    campaigns = campaigns.map(comp => {
                        return {
                            id: comp.id,
                            name: comp.name,
                            ...comp,
                        }
                    })
                } else if (type === reply) {
                    campaigns = campaigns.map(comp => {
                        return {
                            id: comp.id,
                            name: comp.name,
                            ...comp,
                        }
                    })
                } else if (type === highlevel) {
                    campaigns = campaigns.map(comp => {
                        return {
                            id: comp.id,
                            name: comp.name,
                            ...comp,
                        }
                    })
                } else if (type === jazzhr) {
                    campaigns = campaigns.map(comp => {
                        return {
                            id: comp.id,
                            name: comp.title,
                            ...comp,
                        }
                    })
                }
                that.setState({campaigns, filteredCampaigns: campaigns, loading: false});

                let integrations_info = this.props.list ? this.props.list.integrations_info : {};
                if (this.props.type === woodpecker) {
                    if (integrations_info.woodpecker_info && integrations_info.woodpecker_info.campaign_id) {
                        let defaultCampaign = this.state.campaigns.find(item => item.id.toString() == integrations_info.woodpecker_info.campaign_id);
                        this.setState({selectedCampaign: defaultCampaign, selectedValue: defaultCampaign.name})
                    }
                } else if (this.props.type === lemlist) {
                    if (integrations_info.lemlist_info && integrations_info.lemlist_info.campaign_id) {
                        let defaultCampaign = this.state.campaigns.find(item => item.id.toString() == integrations_info.lemlist_info.campaign_id);
                        this.setState({selectedCampaign: defaultCampaign, selectedValue: defaultCampaign.name})
                    }
                } 
                else if (this.props.type === reply) {
                    if (integrations_info.reply_info && integrations_info.reply_info.campaign_id) {
                        let defaultCampaign = this.state.campaigns.find(item => item.id.toString() == integrations_info.reply_info.campaign_id);
                        this.setState({selectedCampaign: defaultCampaign, selectedValue: defaultCampaign.name})
                    }
                } 
                else if (this.props.type === highlevel) {
                    if (integrations_info.highlevel_info && integrations_info.highlevel_info.campaign_id) {
                        let defaultCampaign = this.state.campaigns.find(item => item.id.toString() == integrations_info.highlevel_info.campaign_id);
                        this.setState({selectedCampaign: defaultCampaign, selectedValue: defaultCampaign.name})
                    }
                } 
                else if (this.props.type === jazzhr) {
                    if (integrations_info.jazz_info && integrations_info.jazz_info.job_id) {
                        let defaultCampaign = this.state.campaigns.find(item => item.id.toString() == integrations_info.jazz_info.job_id);
                        this.setState({selectedCampaign: defaultCampaign, selectedValue: defaultCampaign.name})
                    }
                }
                this.setState({active: true})
            }
            ;
        }).catch(err => {
            this.setState({loading: false})
        })


    }


    openListName = () => {
        this.setState({active: !this.state.active});
    }
    showSuccessToast = (toastId, message) => {
        toast.done(toastId);
        $(".Toastify").addClass("mes-done");
        setTimeout(() => {
            toast.update(toastId, {
                className: "toast-success-div",
                render: message,
                type: toast.TYPE.SUCCESS
            });
        }, 1300);
        setTimeout(function () {
            toast.dismiss(toastId)
            $(".Toastify").removeClass("mes-done");
        }, 4000)
    }
    showErrorToast = (toastId, errorMessage) => {
        toast.done(toastId)
        $(".Toastify").addClass("mes-done");
        setTimeout(() => {
            toast.update(toastId, {
                className: "toast-error-div",
                render: errorMessage,
                type: toast.TYPE.ERROR
            });
        }, 1300)
        setTimeout(function () {
            toast.dismiss(toastId)
            $(".Toastify").removeClass("mes-done");
        }, 4000)
    }

    onUpdateClick = async (val) => {
        
        if (!this.state.selectedCampaign) {
            this.setState({errorMessage: `${this.getLabel()} can not be empty`});
            return;
        }

        if (this.props.inside) {
            let toastId = null;
            toastId = toast(`Sending leads to ${this.props.type}`, {
                autoClose: 30000,
            });
            try {
                //const response = await addIntegrationLeads(this.props.type, this.props.listId, this.state.selectedCampaign.id, this.props.inside)
             
                var type = ""
                let selected = null;
                if (this.props.type === woodpecker) {
                    selected = this.state.campaigns.find(item => item.name == val);
                } else if (this.props.type === lemlist) {
                    selected = this.state.campaigns.find(item => item.name == val);
                } 
                else if (this.props.type === reply) {
                    selected = this.state.campaigns.find(item => item.name == val);
                }
                else if (this.props.type === highlevel) {
                   
                    selected = this.state.campaigns.find(item => item.name == val);
                    type = selected.type 
                }
                 else if (this.props.type === jazzhr) {
                    selected = this.state.campaigns.find(item => item.name == val);
                }
                
                let query_filters = {
                    added_by_fuzebot: this.props.inside.query_filters.addedByFuzebot,
                    has_email: this.props.inside.query_filters.hasEmail,
                    personal_email: this.props.inside.query_filters.personalEmail,
                    company_email: this.props.inside.query_filters.companyEmail,
                    single_verified_email: this.props.inside.query_filters.singleVerifiedEmail,
                    double_verified_email: this.props.inside.query_filters.doubleVerifiedEmail,
                    has_linkedin: this.props.inside.query_filters.hasLinkedIn,
                    has_phone: this.props.inside.query_filters.hasPhone,
                    has_mailing_address: this.props.inside.query_filters.hasMailingAddress,
                    date_created_from: this.props.inside.query_filters.from,
                    date_created_to: this.props.inside.query_filters.to
                };
                const params = this.props.inside
                params.query_filters = query_filters
                const response = await addIntegrationLeads(this.props.type, this.props.listId, this.props.type === jazzhr ? selected.id : "", this.props.type != jazzhr ? selected.id : "", this.props.search_query, null, null, this.state.make_default,
                    params, type)
                if (response.data.data) {
                    let added = response.data.data.added;
                    let skipped = response.data.data.skipped;
                    if (skipped == -1 && added == -1) {
                        this.showSuccessToast(toastId, "Leads are being sent to the integration. Please check back in a couple of minutes.");
                    } else if (skipped == 0) {
                        this.showSuccessToast(toastId, "Leads sent successfully to integration");
                    } else if (skipped > 0) {
                        this.showSuccessToast(toastId, `${added} leads sent successfully to integration. ${skipped} leads were skipped`);
                    }
                    const {openWoodpeckerPopup} = this.props
                    openWoodpeckerPopup()
                    this.props.deSelectEverything()
                } else {
                    this.showErrorToast(toastId, 'Unable to send leads to integration');
                }
            } catch (e) {
                this.showErrorToast(toastId, 'Unable to send leads to integration');
            }
        } else {
            let toastId = null;
            //Integrating list with ${this.props.type}
            toastId = toast(`Updating Integrations Settings`, {
                autoClose: 10000,
            });
            try {

                //const response = await addIntegrationLeads(this.props.type, this.props.listId, this.state.selectedCampaign.id, this.props.inside)
                let integrations_info = {};
                if (this.props.type === woodpecker) {
                    let selected = this.state.campaigns.find(item => item.name == val);
                    integrations_info.woodpecker_info = {
                        campaign_id: selected.id
                    };
                } else if (this.props.type === lemlist) {
                    let selected = this.state.campaigns.find(item => item.name == val)
                    integrations_info.lemlist_info = {
                        campaign_id: selected._id
                    };
                } 
                else if (this.props.type === reply) {
                    let selected = this.state.campaigns.find(item => item.name == val)
                    integrations_info.reply_info = {
                        campaign_id: selected.id
                    };
                }
                else if (this.props.type === highlevel) {
                    let selected = this.state.campaigns.find(item => item.name == val)
                
                    integrations_info.highlevel_info = {
                        campaign_id: selected.id,
                        type:selected.type
                    };
                }
                 else if (this.props.type === jazzhr) {
                    let selected = this.state.campaigns.find(item => item.name == val)
                    integrations_info.jazz_info = {
                        job_id: selected.id
                    };
                }

                const response = await updateIntegrations(this.props.listId, integrations_info)
                if (response.data.data) {
                    this.showSuccessToast(toastId, "Integration settings updated successfully");

                    const {openWoodpeckerPopup} = this.props
                    openWoodpeckerPopup(response.data.data)
                } else {
                    this.showErrorToast(toastId, 'Unable to update integration settings');
                }
            } catch (e) {
                this.showErrorToast(toastId, 'Unable to update integration settings');
            }
        }

    }
    columnValue = (comp) => {
        if (this.props.type == woodpecker) {
            this.props.updateDefaultIntegration("woodpecker_info", {campaign_id: comp.id});
        } else if (this.props.type == lemlist) {
            this.props.updateDefaultIntegration("lemlist_info", {campaign_id: comp.id});
        } 
        else if (this.props.type == reply) {
            this.props.updateDefaultIntegration("reply_info", {campaign_id: comp.id});
        } 
        else if (this.props.type == highlevel) {
            this.props.updateDefaultIntegration("highlevel_info", {campaign_id: comp.id});
        } 
        else if (this.props.type == jazzhr) {
            this.props.updateDefaultIntegration("jazz_info", {job_id: comp.id});
        }
        this.setState({selectedCampaign: comp, selectedValue: comp.name, errorMessage: ''});
        this.openListName();
    }

    getIntegrationName = () => {
        if (this.props.type === woodpecker) {
            return 'Woodpecker';
        } else if (this.props.type === lemlist) {
            return 'lemlist';
        } 
        else if (this.props.type === reply) {
            return 'Reply';
        }
        else if (this.props.type === highlevel) {
            return 'HighLevel';
        }
         else if (this.props.type === jazzhr) {
            return 'Jazzhr';
        }
    }

    getLabel = () => {
        if (this.props.type === jazzhr) {
            return 'Job title';
        }
        return 'Campaign name'
    }

    getCampaignTitle = () => this.props.type === jazzhr ? 'Job' : 'Campaigns and Workflows';
    getPlaceHolder = () => {
        const {campaigns} = this.state;
        if (!campaigns || !campaigns.length) {
            if (this.props.type === jazzhr) {
                return 'Select Job Posting'
            }
            return `Search ${this.getCampaignTitle()}`;
        }
        return `Search ${this.getCampaignTitle()}`;
    }

    checkMakeDefault = () => {
        this.setState({make_default: !this.state.make_default});
    }

    handleChange = (e) => {
        const value = e.target.value;
        const filteredCampaigns = this.state.campaigns.filter(comp => {
            return comp.title.toLowerCase().indexOf(value.toLowerCase()) > -1
        });
        this.setState({value: e.target.value, filteredCampaigns})
    }

    render() {
        const {active, value, selectedValue, errorMessage, campaigns, loading, filteredCampaigns} = this.state
        const {popupWoodpecker, openWoodpeckerPopup, woodpeckerCampaigns, inside, type, search_query} = this.props;

        let updatedCampaigns = campaigns.map(item => {
            if (!item.name) {
                return {
                    ...item, name: item.title
                }
            }
            return item;
        });
        return (
            <div className={`add-list ${popupWoodpecker ? 'open' : ''}`}>
                <div className="add-list__content">
                    <img className="add-list__close" src="/assest/accountBased/close.svg" alt=""
                         onClick={openWoodpeckerPopup}/>
                    <p className="add-list__title">{inside ? 'Send To ' + this.getIntegrationName() : this.getIntegrationName() + ' Settings'} </p>
                    <p className="add-list__label">{this.getLabel()}</p>
                    <div className={`add-list__search-box ${active ? 'active' : ''}`} onClick={this.openListName}>
                        <input className="add-list__search"
                               placeholder={this.getPlaceHolder()}
                               type="text"
                               disabled
                               value={selectedValue}
                            // onChange={this.handleChange}
                               required/>
                        <img src="/assest/accountBased/role-arr.svg" alt=""/>
                    </div>
                    <CampaignDropDown
                        active={active}
                        loading={loading}
                        columnValue={this.columnValue}
                        openListName={this.openListName}
                        woodpeckerCampaigns={campaigns}
                        filteredCampaigns={filteredCampaigns}
                        value={value}
                        handleChange={this.handleChange}
                    />
                    {/* <p className="add-list__create">
                    </p> */}

                    {errorMessage ? (
                        <div className="lead-header__model_error"><p>{errorMessage}</p></div>) : null}

                    {inside && !search_query && <div className="check-panel">
                        <div className={`check ${this.state.make_default ? 'active' : ''}`}
                             onClick={() => this.checkMakeDefault()}></div>
                        <div className="check-label"
                             onClick={() => this.checkMakeDefault()}>{type != 'jazzhr' ? 'Make this the default campaign for this list' : 'Make this the default job posting for this list'}</div>
                    </div>}

                    <div className="add-list__buttons">
                        <div className="add-list__btn orange" onClick={() => {
                            this.onUpdateClick(selectedValue)
                        }}>{inside ? 'Send' : 'Save'}</div>
                        <div className="add-list__btn orange-light" onClick={openWoodpeckerPopup}>Cancel</div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        integration: state.integration,
        user: state.user
    };
}

export default connect(mapStateToProps, null)(Woodpecker);
