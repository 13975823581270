import React, {Component} from 'react';

class CampaignDropDown extends Component {
    state = {
        value: '',
        filteredCampaigns: [],
    }
    // componentWillMount() {
    //     this.setState({filteredCampaigns: this.props.woodpeckerCampaigns});
    // }

    // handleChange = (e) => {
    //     const value = e.target.value;
    //     const filteredCampaigns = this.props.woodpeckerCampaigns.filter(comp => {
    //         return comp.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    //     });
    //     this.setState({ value: e.target.value, filteredCampaigns })
    // }
    render() {

        //const { filteredCampaigns } = this.state;
        const {active, columnValue, loading, woodpeckerCampaigns, handleChange, value} = this.props;
        return (
            <div className={`add-list-name ${active ? 'active' : ''}`}>
                <div className="add-list-name__search">
                    <img src="/assest/accountBased/search.svg" alt=""/>
                    <input placeholder="Search List" type="text"
                           value={value}
                           onChange={handleChange}
                    />
                </div>
                <div className="add-list-name__row">
                    {loading ? <p className="no-matches-found">Loading...</p> :

                        woodpeckerCampaigns.length > 0 ?
                            woodpeckerCampaigns.map((item, index) =>
                                <div className="add-list-name__item" key={index} onClick={() => {
                                    columnValue(item)
                                }}>
                                    <p className="add-list-name__text">{item.name}</p>
                                </div>
                            ) : <p className="no-matches-found">No matches found</p>
                    }
                </div>
            </div>
        )
    }
}

export default CampaignDropDown;
