import React from 'react'
import classNames from 'classnames'
import './popout-result.scss'
import noop from 'lodash/noop'

export const PopoutResult = ({ isActive, children, onClose, ...containerProps }) => {
  return (
    <div
      {...containerProps}
      className={classNames(containerProps.className, 'popout-result', {
        ['popout-result_active']: isActive
      })}
    >
      {children}
      <div
        className="popout-result__overlay"
        onClick={onClose}
      >

      </div>
    </div>
  )
}
