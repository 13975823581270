import React, { Component } from 'react'
import list from './list'
import '../style.scss'

class FindLeads4left extends Component{
  state = {
    list: list
  }

  changeItem = (i) =>{
    const { list } = this.state
    for(let j = 0; j < list.length; j++){
      list[j].active = false
    }
    list[i].active = true
    this.setState({list: list})
  }

  render(){
    const { list } = this.state
    return(
      <div className="find-leads">
        <img className="find-leads__logo" src="/assest/accountBased/logo.svg" alt=""/>
        <h2 className="find-leads__title long">How many people on your team will be using LeadFuze to generate leads?</h2>
        <div className="find-leads__tags">
          {
            list.map((item, index)=>
              <p className={item.active ? 'active' : ''} key={index} onClick={()=>this.changeItem(index)}>
                {item.name}
              </p>
            )
          }
        </div>
        <div className="find-leads__buttons">
          <div className="find-leads__btn">Start Searching for Leads</div>
        </div>
      </div>
    )
  }
}

export default FindLeads4left