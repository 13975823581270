export const ROUTES = {
  LEAD_SEARCH__ACCOUNT_BASED: '/lead-search/account-based',
  LEAD_SEARCH__MARKET_BASED: '/lead-search/market-based',
  HOT_LEADS: '/lead-search/hot-leads',
  PEOPLE_LIST: '/people-lists',
  SEARCH_RESULT: '/search-result',
  INTEGRATIONS: '/integrations',
  API_KEYS: '/api-keys',
  PROFILE: '/profile',
  BILLING_AND_USERS: '/billing-and-users',
  ADMIN__COUPON: '/admin/coupon',
  ADMIN__USERS: '/admin/users',
  IGNORE_LIST: '/ignore-list',
  LEAD_LIST__INSIDE_LIST: '/lead-list/inside-list',
  LEAD_LIST__SAVED_SEARCHES: '/lead-search/saved-searches'
}
