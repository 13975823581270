const items = [
  {
    name: 'Code',
    clname: 'code',
    icon: false 
  },
  {
    name: 'Description',
    clname: 'description',
    icon: false 
  },
  {
    name: 'One Time',
    clname: 'col',
    icon: false 
  },
  {
    name: 'Monthly',
    clname: 'col',
    icon: false 
  },
  {
    name: 'Max Usage',
    clname: 'col-big',
    icon: false 
  },
  {
    name: 'Redeemers',
    clname: 'col-big',
    icon: true 
  },
  {
    name: 'Expire',
    clname: 'date',
    icon: true 
  },

]

export default items