import {
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_FAILURE,
  RESET_PASS_TOKEN_EXPIRE,
  RESET_PASS_TOKEN_SUCCESS,
  REDIRECT_USER_TO_LIST,
  LOGIN_ATTEMPTS
} from '../constants/constant';

export function saveUser(user) {
  return {
    type: SIGNIN_USER,
    payload: user
  };
}

export function signInUserSuccess(user) {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: user
  };
}

export function signInUserFailure(error) {
  return {
    type: SIGNIN_USER_FAILURE,
    payload: error
  };
}

export function tokenFailure(status) {
  return {
    type: RESET_PASS_TOKEN_EXPIRE,
    payload: status
  };
}

export function tokenSuccess(status) {
  return {
    type: RESET_PASS_TOKEN_SUCCESS,
    payload: status
  };
}
export function redirectUser(user) {
  return {
    type: REDIRECT_USER_TO_LIST,
    payload: user
  };
}
export function loginAttempts(num) {
  return {
    type: LOGIN_ATTEMPTS,
    payload: num
  };
}

