import React, {Component, Fragment} from 'react'
import FileDrop from 'react-file-drop'
import './style.scss'
import {addIgnoreListViaFile} from '../../services/ignoreLists';
import {toast} from 'react-toastify';
import * as XLSX from 'xlsx';
import Loader from '../Loader';
import {LOCAL_STORAGE_KEYS} from '../../constants/constant';
import {ConfirmationPopup} from '..'

let errormsg
let accountErrorMsg = false

class Uploads extends Component {

    static allowedFileExtensions = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/plain', 'text/csv']

    constructor(props) {
        super(props)
        this.state = {
            selectedFiles: null,
            droped: false,
            uploadSuccess: false,
            uploadError: false,
            showBulkUploadConfirmation: false,
            uploadFiles: null
        }
    }

    onDrop(files) {
        if (files && files[0]) {
            if (this.props.page === "AccountBased") {
                this.handleFile(files[0]);
            } else if (this.props.page === "IgnoreList") {
                this.setState({
                    showBulkUploadConfirmation: true,
                    uploadFiles: files
                })
                //this.onFilePick(files, "drop");
            }
        }
    };

    onFileChoose = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            if (this.props.page === "AccountBased") {
                this.handleFile(files[0]);
            } else if (this.props.page === "IgnoreList") {
                this.setState({
                    showBulkUploadConfirmation: true,
                    uploadFiles: e.target.files
                })
                //this.onFilePick(e, "input");
            }
        }
    };

    closeBulkUploadConfirmationPopup = () => {
        this.setState({
            showBulkUploadConfirmation: false
        })
    }

    clearUploadError = () => {
        this.setState({uploadError: '', droped: false});
    }

    handleFile = (file/*:File*/) => {
        const validFileTypeIndex = Uploads.allowedFileExtensions.findIndex(allowedExt => allowedExt === '' + file.type);
        if (validFileTypeIndex < 0) {
            this.setState({uploadError: 'Invalid file extension', droped: false, uploadSuccess: false});
            errormsg = 'File format not supported. Try Again';
            accountErrorMsg = true;
            return;
        } else {
            this.setState({uploadError: '', droped: false, uploadSuccess: true});
        }
        this.file = file;
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            // const wb = XLSX.read(bstr, {type:rABS ? 'binary' : 'array'});
            const wb = XLSX.read(bstr, {type: validFileTypeIndex === 0 ? 'binary' : 'string'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {header: 1});
            const allCompanies = [];
            data.map(rows => {
                allCompanies.push(...rows);
            });
            const requiredCompanies = allCompanies.slice(0, 2000);
            this.props.setCompanyTags(requiredCompanies);

            // this.props.saveData(data);
            // const cols = makeCols(data[0]);
            // this.fileHeadersSelectedSet = [];
            /* Update state */
            // this.setState({ data: data, cols, datasourceName: this.file.name });
        };
        if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
    };

    onFilePick = (event, uploadType, deleteLeads) => {
        this.setState({droped: !this.state.droped})
        const {buttonMessage} = this.props
        buttonMessage(true)
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        let fileType = uploadType === "input" ? event.target.files[0].type : event[0].type;
        if (fileType === "application/vnd.ms-excel" || fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType === "text/plain" || fileType === "text/csv") {
            this.setState({selectedFile: uploadType === "input" ? event.target.files[0] : event[0]},
                async () => {
                    let formData = new FormData();
                    formData.append('file', this.state.selectedFile);
                    if (this.state.selectedFile) {
                        console.log(this.state.selectedFile.type)
                    }
                    try {
                        await addIgnoreListViaFile(user.account_id, formData, deleteLeads, user.id)
                        this.setState({droped: false, uploadSuccess: true})
                        buttonMessage(false)
                        setTimeout(() => {
                            this.setState({uploadSuccess: false})
                            this.props.uploadIgnoreList();
                        }, 1500);
                    } catch (error) {
                        if (error.response.data.errCode === 14210) {
                            errormsg = 'Incorrect file format. Please check your file.'
                        } else if (error.response.data.errCode === 14260) {
                            errormsg = 'Exceeded ignore list size limit.'
                        } else {
                            errormsg = 'Unable to add keywords to ignore list.'
                        }
                        this.setState({droped: false, uploadError: true})
                        setTimeout(() => {
                            this.setState({uploadError: false})
                        }, 5000)
                    }
                }
            )
            ;
        } else {
            toast.error('Only csv,txt and xls files allowed', {
                className: "toast-error-div",
                autoClose: 4000
            })
            this.setState({droped: false})

        }
    }

    render() {
        const {droped, uploadSuccess, uploadError} = this.state
        return (
            <Fragment>
                <div className="uploads">
                    <h3 className="uploads__title">Bulk Uploads</h3>
                    {!uploadSuccess && !uploadError &&
                    < FileDrop onDrop={(file) => this.onDrop(file)}>
                        <div className="uploads__box">
                            {droped &&
                            <Loader uploading={droped}/>
                            }
                            <p className="uploads__descr">Drag & Drop file here</p>
                            <p className="uploads__text">or</p>
                            <label htmlFor="forFileUpload" className="uploads__btn">Add File</label>
                            <input type="file" id="forFileUpload" accept=".csv,text/plain,.xlsx"
                                   onChange={(file) => this.onFileChoose(file)}/>
                            <p className="uploads__types">Upload a .txt,.csv, or .xls file</p>
                        </div>
                    </FileDrop>
                    }
                    {uploadSuccess &&
                    <div className="uploads__success-box">
                        <img src="/assest/apiKeys/check-symbol.svg" alt=""/>
                        <p>File Uploaded Successfully</p>
                    </div>
                    }
                    {uploadError && (accountErrorMsg ?
                        <div className="uploads__error-box">
                            <img src="/assest/apiKeys/Close_red.svg" alt=""/>
                            <p>File format not supported. <span className="underline"
                                                                onClick={() => this.clearUploadError()}>Try Again</span>
                            </p>
                        </div> :
                        <div className="uploads__error-box" onClick={() => this.clearUploadError()}>
                            <img src="/assest/apiKeys/Close_red.svg" alt=""/>
                            <p>{errormsg}</p>
                        </div>)
                    }
                    <div className="download-icon">
                        <p><a href="/assest/sample_file.csv" download="sample_file.csv"><img src="/assest/Download.svg"
                                                                                             alt=""/>Download Sample</a>
                        </p>
                    </div>
                </div>
                {
                    this.state.showBulkUploadConfirmation &&
                    <ConfirmationPopup
                        title="Delete Existing Leads"
                        message="Do you want to delete existing leads that match these new ignore list keywords?"
                        onYesClick={() => {
                            this.onFilePick(this.state.uploadFiles, "drop", true)
                            this.closeBulkUploadConfirmationPopup()
                        }}
                        onNoClick={() => {
                            this.onFilePick(this.state.uploadFiles, "drop", false)
                            this.closeBulkUploadConfirmationPopup()
                        }}
                        onCloseClick={() => {
                            this.closeBulkUploadConfirmationPopup()
                        }}
                    />
                }
            </Fragment>
        )
    }
}


export default Uploads
