import {
  role_groups,
  tags,
  employee_size,
  adwords_budget,
  technology_groups,
  department_groups,
  hiring_groups,
  news_groups,
  industry_groups,
  skills,
  yearsOfExperience,
  estimatedSalary,
  timeInLatestJob
} from "../../../../tags";
import { LocationDropdownType } from "../../../../constants/constant";

export const companyDropdowns = [  
  // {
  //   label: "Industry",
  //   basicHeading1: 'All Selected Industries',
  //   inputType: "text",
  //   name: "industry",
  //   customInput: false,
  //   placeholder: "Enter the industry (or industries) for your targeted leads",
  //   have_tags: false,
  //   showExclude: true,

  //   group: industry_groups
  // },

  {
    label: "Industry",
    basicHeading1: 'All Selected Industries',
    inputType: "text",
    name: "industries2",
    customInput: false,
    placeholder: "Enter the industry (or industries) for your targeted leads",
    have_tags: false,
    showExclude: true,

    group: industry_groups
  },
  {
    label: "Department",
    basicHeading1: 'All Selected Department',
    inputType: "text",
    name: "department",
    customInput: false,
    placeholder: "Choose department",
    have_tags: false,
    showExclude: true,
    
    group: department_groups
  },
  {
    label: "Location",
    basicHeading1: 'All Selected Tags',
    inputType: "text",
    name: "location",
    customInput: true,
    type: LocationDropdownType.Company,
    placeholder: "City/State/Country",
    have_tags: true,
    showExclude: true,
    group: []
  },
  {
    label: "Employee Size",
    basicHeading1: '',
    inputType: "text",
    name: "employee",
    customInput: false,
    placeholder: "Choose employee size range(s)",
    have_tags: true,
    group: employee_size
  },
  // {
  //   label: "Monthly Adwords Budget",
  //   basicHeading1: 'All Selected Tags',
  //   inputType: "text",
  //   name: "adwords",
  //   placeholder: "Enter monthly adwords budget",
  //   have_tags: false,
  //   customInput: false,
  //   group: adwords_budget
  // },
  // {
  //   label: "Technologies Being Used",
  //   basicHeading1: 'All Selected Technologies',
  //   inputType: "text",
  //   name: "technologies",
  //   placeholder: "Choose technologies",
  //   have_tags: false,
  //   customInput: false,
  //   showExclude: true,
  //   group: technology_groups
  // },
  // {
  //   label: "Hiring For",
  //   basicHeading1: 'Hiring Titles',
  //   inputType: "text",
  //   name: "hiring",
  //   customInput: true,
  //   showExclude: false,
  //   placeholder: "Input job titles to find those hiring",
  //   have_tags: false,
  //   group: hiring_groups
  // },
  // {
  //   label: "News",
  //   basicHeading1: 'All Selected Tags',
  //   inputType: "text",
  //   name: "news",
  //   placeholder: "Select categories of news events",
  //   have_tags: false,
  //   group: news_groups
  // },
];


export const peopleDropdowns = [
  {
    label: "Role",
    basicHeading1: 'All Include & Exclude Tags',
    inputType: "text",
    name: "role",
    customInput: true,
    showExclude: true,
    placeholder: "Choose role",
    have_tags: true,
    group: role_groups
  },
  {
    label: "Location",
    basicHeading1: 'All Selected Tags',
    inputType: "text",
    customInput: true,
    showExclude: true,
    name: "location",
    type: LocationDropdownType.People,
    placeholder: "City/State/Country",
    have_tags: true,
    group: []
  },
  // {
  //   label: "Years of Work Experience",
  //   basicHeading1: 'All Selected Options',
  //   inputType: "text",
  //   name: "yearsOfExperience",
  //   customInput: false,
  //   showExclude: false,
  //   placeholder: "Choose experience range",
  //   have_tags: false,
  //   group: yearsOfExperience
  // },
  {
    label: "Time in Latest Role",
    basicHeading1: 'All Selected Options',
    inputType: "text",
    name: "timeInLatestJob",
    customInput: false,
    showExclude: false,
    placeholder: "Choose experience range",
    have_tags: false,
    group: timeInLatestJob
  },
  {
    label: "Estimated Salary",
    basicHeading1: 'All Selected Options',
    inputType: "text",
    name: "estimatedSalary",
    customInput: false,
    showExclude: false,
    placeholder: "Choose estimated salaries",
    have_tags: false,
    group: estimatedSalary,
  },
  // {
  //   label: "Skills",
  //   basicHeading1: 'All Selected Skills',
  //   inputType: "text",
  //   name: "skills",
  //   customInput: true,
  //   showExclude: true,
  //   placeholder: "Choose skills",
  //   have_tags: false,
  //   group: skills
  // },
];





const inputsPeople = [
  {
    label: "Role",
    basicHeading1: 'All Include & Exclude Tags',
    inputType: "text",
    name: "role",
    customInput: true,
    showExclude: true,
    placeholder: "Choose role",
    have_tags: true,
    group: role_groups
  },
  {
    label: "Location",
    basicHeading1: 'All Selected Tags',
    inputType: "text",
    name: "location",
    type: 'companyLocation',
    placeholder: "City/State/Country/Continent/Region",
    have_tags: true,
    group: []
  },
  {
    label: "Industry",
    basicHeading1: 'All Selected Industries',
    inputType: "text",
    name: "industry",
    customInput: false,
    placeholder: "Enter the industry (or industries) for your targeted leads",
    have_tags: false,
    group: industry_groups
  },
  {
    label: "Employee Size",
    basicHeading1: '',
    inputType: "text",
    name: "employee",
    customInput: false,
    placeholder: "Choose employee size range(s)",
    have_tags: true,
    group: employee_size
  },
  {
    label: "Monthly Adwords Budget",
    basicHeading1: 'All Selected Tags',
    inputType: "text",
    name: "adwords",
    placeholder: "Enter monthly adwords budget",
    have_tags: false,
    customInput: false,
    group: adwords_budget
  },
  {
    label: "Technologies Being Used",
    basicHeading1: 'All Selected Technologies',
    inputType: "text",
    name: "technologies",
    placeholder: "Choose technologies",
    have_tags: false,
    customInput: false,
    showExclude: true,
    group: technology_groups
  },
  {
    label: "Hiring For",
    basicHeading1: 'Hiring Titles',
    inputType: "text",
    name: "hiring",
    customInput: true,
    showExclude: false,
    placeholder: "Input job titles to find those hiring",
    have_tags: false,
    group: hiring_groups
  },
  {
    label: "Company News",
    basicHeading1: 'All Selected Tags',
    inputType: "text",
    name: "news",
    placeholder: "Select categories of news events",
    have_tags: false,
    group: news_groups
  },
  {
    label: "Skills",
    basicHeading1: 'All Selected Skills',
    inputType: "text",
    name: "skills",
    customInput: true,
    showExclude: true,
    placeholder: "Choose skills",
    have_tags: false,
    group: skills
  },
  {
    label: "Time in Latest Job",
    basicHeading1: 'All Selected Options',
    inputType: "text",
    name: "yearsOfExperience",
    customInput: false,
    showExclude: false,
    placeholder: "Choose experience range",
    have_tags: false,
    group: yearsOfExperience
  },
  {
    label: "Estimated Salary",
    basicHeading1: 'All Selected Options',
    inputType: "text",
    name: "estimatedSalary",
    customInput: false,
    showExclude: false,
    placeholder: "Choose estimated salaries",
    have_tags: false,
    group: estimatedSalary,
  }
];

export default inputsPeople;
