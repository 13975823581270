import React, { Component, Fragment } from 'react';
import './style.scss';
import { AddGroups } from '../../MarkedBasedBox/MarketSearch/FieldsDropdown';
var moment = require('moment');

class CompanyFoundedYear extends Component {
  container = React.createRef();
  state = {
    yearOptions: [],
    filteredYears: [],
    fromMenuOpen: false,
    toMenuOpen: false,
  }
  constructor(props) {
    super(props);
    const yearOptions = this.getYearOptions();
    this.setState({
      yearOptions,
      filteredYears: yearOptions,
    });
  }

  componentDidMount() {
    document.addEventListener("click", this.clickEventHandle)
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.clickEventHandle)
  }

  clickEventHandle = (event) => {
    if (this.container.current && !this.container.current.contains(event.target)) {
      this.setState({
        fromMenuOpen: false,
        toMenuOpen: false,
      })
    }
  }

  getYearOptions() {
    const from = 1980;
    let currentYear = Number(moment().format("YYYY"));

    const yearOptions = [];
    while (from <= currentYear) {
      yearOptions.push({ category: currentYear, label: '' + currentYear });
      currentYear -= 1;
    }
    yearOptions.push({ category: 'None', label: 'None' });
    return yearOptions;
  }
  addGroupSelectFrom = (selectedFromYear) => {
    this.setState({ fromMenuOpen: false });
    this.props.setCompanyFoundedYearFrom(selectedFromYear);
  }
  addGroupSelectTo = (selectedToYear) => {
    this.setState({ toMenuOpen: false });
    this.props.setCompanyFoundedYearTo(selectedToYear);
  }
  render() {
    const {
      searchResultFilters
    } = this.props;

    return (
      <div className="">
        <h4 className="right-filters-company__title">Year founded</h4>
        <div className="right-filters-company__list animation-slideDown" ref={this.container}>
          <div className="founded-date__from-to">
            <p className="founded-date__from-label">From</p>
            {<input type="text" min="1" className="founded-date__input"
              placeholder="From"
              onFocus={() => this.setState({ fromMenuOpen: true })}
              value={searchResultFilters.founded_date_from}
              // onChange={setCompanyFoundedYearFrom}
            />}
            {this.state.fromMenuOpen ?
              <AddGroups
                addGroupSelect={this.addGroupSelectFrom}
                roleGroups={this.getYearOptions()}
                finishRoleGroups={this.getYearOptions()}
                addExcludeTag={this.addExcludeTag}
                addTagInSelect={this.addExcludeTag}
                value={searchResultFilters.founded_date_from}
                customInput={false}
                // showMessage={showMessage}
                name={'foundedFrom'}
                haveTags={false}
                label={'From Years'}
                showExclude={false}
                excludeOption={false}
              // toggleExcludeOption={this.toggleExcludeOption}
              /> : null}
          </div>

          {/* <div className="founded-date__line"></div> */}


          <div className="founded-date__from-to founded-date__to">
            <p className="founded-date__from-label">To</p>
            <input type="text" min="1" className="founded-date__input"
              placeholder="To"
              onFocus={() => this.setState({ toMenuOpen: true })}
              value={searchResultFilters.founded_date_to}
            />

            {this.state.toMenuOpen ?
              <AddGroups
                addGroupSelect={this.addGroupSelectTo}
                roleGroups={this.getYearOptions()}
                finishRoleGroups={this.getYearOptions()}
                addExcludeTag={this.addExcludeTag}
                addTagInSelect={this.addExcludeTag}
                value={searchResultFilters.founded_date_from}
                customInput={false}
                // showMessage={showMessage}
                name={'foundedTo'}
                haveTags={false}
                label={'To Years'}
                showExclude={false}
                excludeOption={false}
              // toggleExcludeOption={this.toggleExcludeOption}
              /> : null}
          </div>
        </div>
      </div>
    )
  }
}

export default CompanyFoundedYear;
