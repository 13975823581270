import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";
import {BillingAndUser, BillingScaling, BreadCrumb} from "../../components";
import "./style.scss";
import {toast} from "react-toastify";
import { LOCAL_STORAGE_KEYS } from "../../constants/constant";
import { EmitterService } from "../../services/emitterService";
import Loader from '../../components/Loader';
import { getLoggedInUserInfo } from '../../services/users';
import { startLoader, stopLoader } from "../../actions/loader";
import { saveUserInfo } from '../../actions/profile_update';
import { isNull } from "lodash";

const queryString = require('query-string');

class BillingAndUsers extends Component {

    async componentWillMount() {
        const queryParams = queryString.parse(this.props.history.location.search);
        
        let session_id = queryParams.session_id;
        if(session_id) {
            this.props.startLoader(true);
            const response = await getLoggedInUserInfo();
            const user = response.data.data;
            this.props.saveUserInfo(user);


            setTimeout(async () => { 
                
                const response = await getLoggedInUserInfo();
                const user = response.data.data;
                this.props.saveUserInfo(user);
                if(user && user.accounts[0].status == 'paid')
                    this.props.stopLoader();
            }, 3000);

            setTimeout(async () => { 
                const response = await getLoggedInUserInfo();
                const user = response.data.data;
                this.props.saveUserInfo(user);
                if(user && user.accounts[0].status == 'paid')
                    this.props.stopLoader();
            }, 7000);

            setTimeout(async () => { 
                const response = await getLoggedInUserInfo();
                const user = response.data.data;
                if(user && user.accounts[0].status == 'paid')
                    this.props.stopLoader();
            }, 10000);
        }
        else {
            EmitterService.emit("loadLeadsRemaining");
        }
    }

    componentDidMount() {
        const queryParams = queryString.parse(this.props.history.location.search);
        if (queryParams.session_id) {
            toast.info('Your payment was successful. Happy Prospecting!', {
                className: "toast-success-div",
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
            });
        } else if (queryParams.cancel) {
            toast.error('We were unable to process your payment. Please try again', {
                className: "toast-error-div",
                type: toast.TYPE.ERROR,
                autoClose: 5000,
            });
        }
    }

    isUserOnTrial = () => {
        const accounts = this.props.userProfileInfo.userInfo.accounts;
        return !accounts || !accounts[0].status || accounts[0].status === 'active_trial' || accounts[0].status === 'trial_expired';
    }

    isTrialExpired = () => {
        const accounts = this.props.userProfileInfo.userInfo.accounts;
        return accounts && accounts[0].status === 'trial_expired';
    }

    render() {

        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));

        const links = [
            {
              name: 'Billing and Users',
              slug: '/billing-and-users',
            },
        ]

        return (
            <div className="billing-page">
                {user === null && this.props.history.push(`/signin`)}

                <BreadCrumb links={links}></BreadCrumb>
                
                {this.props.loader.loading ? <Loader/> :

                    this.props.userProfileInfo.userInfo.accounts &&
                    <Fragment>
                        {this.isUserOnTrial() ? <BillingScaling billingOverview={null} isUserOnTrial={true}/> : <BillingAndUser/>}
                    </Fragment>
                    
                }
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            // setBillingOverview
            startLoader,
            stopLoader,
            saveUserInfo
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        userProfileInfo: state.userProfileInfo,
        loader: state.loader
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BillingAndUsers));
