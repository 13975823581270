import React, { Component, Fragment } from 'react';
import '../style.scss';
import { deleteList } from '../../../../services/leads';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { showSuccessToast, showErrorToast } from '../../../../helpers/toast';

class DeleteList extends Component {
    state = {
        popup: this.props.popup,
        error: false,
        errCode: 0,
        value: "",
        successDiv: false
    }
    handleChange = (e) => {
        this.setState({ value: e.target.value })
    }
    onUpdateClick = async (value) => {
        const { id, openDeletePopup, leadList, userID } = this.props
        if (value !== "DELETE") {
            this.setState({ error: true })
        } else {
            let toastId = null;
            let errormsg
            toastId = toast('Deleting list', {
                autoClose: 1000,
            });
            try {
                deleteList(userID, id)
                openDeletePopup()
                leadList();
                showSuccessToast(toastId, "List deleted successfully")
            } catch (error) {
                if(!error.response) {
                    errormsg = 'Unable to Delete list'
                }
                else if (error.response.data.errCode === 30120) {
                    errormsg = 'Permission denied'
                } else {
                    errormsg = 'Unable to Delete list'
                }
                showErrorToast(toastId, errormsg);
            }
        }
    }
    onClosePopup = () => {
        const { openDeletePopup } = this.props
        this.setState({ value: "" })
        openDeletePopup()
    }

    render() {
        const { value, error } = this.state
        const { popupDelete } = this.props;
        return (
            <Fragment>
                <div className={`add-list ${popupDelete ? 'open' : ''}`}>
                    <div className="add-list__content">
                        <img className="add-list__close" src="/assest/accountBased/close.svg" alt="" onClick={this.onClosePopup} />
                        {error &&
                            <div className="error-div">
                                <p>To continue with your request, please type "DELETE" without quotes on the line below to confirm you want to delete this list.</p>
                            </div>
                        }
                        <p className="add-list__title">Delete List</p>
                        <p className="lead-cont__delete-list__desc">By deleting this list, you will delete all leads within it. This will not reset your lead remaining count and means you could potentially add these leads again in the future.
                     To continue with your request, please type "DELETE" without quotes on the line below to confirm you want to delete this list.</p>
                        <input className="add-list__search check-margin"
                            placeholder="Type &#34;DELETE&#34;"
                            type="text"
                            value={value}
                            autoFocus
                            onChange={this.handleChange}
                            required />
                        <p className="add-list__create">
                        </p>
                        <div className="add-list__buttons">
                            <div className="add-list__btn orange" onClick={() => { this.onUpdateClick(value) }} >Delete List</div>
                            <div className="add-list__btn orange-light" onClick={this.onClosePopup}>Cancel</div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default DeleteList;
