import React, { Component } from 'react'
import './style.scss'

class GreenBanner extends Component {
  state = {
    hidden: false
  }

  hiddenBanner = () => {
    this.setState({ hidden: !this.state.hidden })
  }

  render() {
    const { hidden } = this.state
    return (
      <section className={`green ${hidden ? 'hidden' : ''}`}>
        <img className="green__close" src="/assest/accountBased/close-green.svg" alt="" onClick={this.hiddenBanner} />
        <p className="green__text">{ this.props.msg ? this.props.msg : 'Complete your task list and we will give you 25 free additional lead credits!' }</p>
        {/* <p className="green__video">Watch Video</p> */}
      </section>
    )
  }
}

export default GreenBanner