import {SEARCH_RESULT_ACTIONS} from '../constants/constant';

var moment = require('moment');

const INITIAL_STATE = {
    require_email: false,
    require_double_verified_emails: false,
    require_company_emails: false,
    require_personal_emails: false,

    require_phone_number: false,
    require_company_phone_number: false,
    require_personal_phone_number: false,

    require_social: false,
    require_linkedin: false,
    require_facebook: false,
    require_twitter: false,

    require_job_status: true,
    require_employment: true,
    require_unemployment: false,

    require_mailing_address: false,
    exclude_previous_experience: false,

    max_people_per_company: 0,
    max_results_per_company: 'No Limit',

    founded_date_from: 'None',
    // 1980,
    founded_date_to: 'None',
    // Number(moment().format("YYYY")),
    recordsPerPage: 10,
    searchType: '',
    changed: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SEARCH_RESULT_ACTIONS.REQUIRE_EMAIL:
            if (action.payload) {
                return {
                    ...state,
                    require_email: action.payload,
                    changed: true
                };
            } else {
                return {
                    ...state,
                    require_email: action.payload,
                    require_double_verified_emails: action.payload,
                    require_company_emails: action.payload,
                    require_personal_emails: action.payload,
                    changed: true
                };
            }
        case SEARCH_RESULT_ACTIONS.REQUIRE_DOUBLE_VERIFIED_EMAILS:
            return {
                ...state,
                require_double_verified_emails: action.payload,
                changed: true
            };
        case SEARCH_RESULT_ACTIONS.REQUIRE_COMPANY_EMAILS:
            return {
                ...state,
                require_company_emails: action.payload,
                changed: true
            };
        case SEARCH_RESULT_ACTIONS.REQUIRE_PERSONAL_EMAILS:
            return {
                ...state,
                require_personal_emails: action.payload,
                changed: true
            };

        case SEARCH_RESULT_ACTIONS.REQUIRE_PHONE_NUMBER:
            if (action.payload) {
                return {
                    ...state,
                    require_phone_number: action.payload,
                    changed: true
                };
            } else {
                return {
                    ...state,
                    require_phone_number: action.payload,
                    require_company_phone_number: action.payload,
                    require_personal_phone_number: action.payload,
                    changed: true
                };
            }
        case SEARCH_RESULT_ACTIONS.REQUIRE_COMPANY_PHONE_NUMBER:
            return {
                ...state,
                require_company_phone_number: action.payload,
                changed: true
            };
        case SEARCH_RESULT_ACTIONS.REQUIRE_PERSONAL_PHONE_NUMBER:
            return {
                ...state,
                require_personal_phone_number: action.payload,
                changed: true
            };
        case SEARCH_RESULT_ACTIONS.REQUIRE_SOCIAL:
            if (action.payload) {
                return {
                    ...state,
                    require_social: action.payload,
                    changed: true
                };
            } else {
                return {
                    ...state,
                    require_social: action.payload,
                    require_linkedin: action.payload,
                    require_facebook: action.payload,
                    require_twitter: action.payload,
                    changed: true
                };
            }
        case SEARCH_RESULT_ACTIONS.REQUIRE_LINKEDIN:
            return {
                ...state,
                require_linkedin: action.payload,
                changed: true
            };
        case SEARCH_RESULT_ACTIONS.REQUIRE_FACEBOOK:
            return {
                ...state,
                require_facebook: action.payload,
                changed: true
            };
        case SEARCH_RESULT_ACTIONS.REQUIRE_TWITTER:
            return {
                ...state,
                require_twitter: action.payload,
                changed: true
            };
        case SEARCH_RESULT_ACTIONS.REQUIRE_JOB_STATUS:
            if (action.payload) {
                return {
                    ...state,
                    require_job_status: action.payload,
                    require_employment: action.payload,
                    changed: true
                };
            } else {
                return {
                    ...state,
                    require_job_status: action.payload,
                    require_employment: action.payload,
                    require_unemployment: action.payload,
                    changed: true
                };
            }
        case SEARCH_RESULT_ACTIONS.REQUIRE_EMPLOYMENT:
            return {
                ...state,
                require_employment: action.payload,
                require_unemployment: !action.payload,
                changed: true
            };
        case SEARCH_RESULT_ACTIONS.REQUIRE_UNEMPLOYMENT:
            return {
                ...state,
                require_employment: !action.payload,
                require_unemployment: action.payload,
                changed: true
            };
        case SEARCH_RESULT_ACTIONS.REQUIRE_MAILING_ADDRESS:
            return {
                ...state,
                require_mailing_address: action.payload,
                changed: true
            }
        case SEARCH_RESULT_ACTIONS.EXCLUDE_PREVIOUS_EXPERIENCE:
            return {
                ...state,
                exclude_previous_experience: action.payload,
                changed: true
            }
        case SEARCH_RESULT_ACTIONS.MAX_PEOPLE_PER_COMPANY:
            return {
                ...state,
                max_people_per_company: action.payload,
                changed: true
            }
        case SEARCH_RESULT_ACTIONS.MAX_RESULTS_PER_COMPANY:
            return {
                ...state,
                max_results_per_company: action.payload,
                changed: true
            }
        case SEARCH_RESULT_ACTIONS.COMPANY_FOUNDED_DATE_FROM:
            return {
                ...state,
                founded_date_from: action.payload,
                changed: true
            }
        case SEARCH_RESULT_ACTIONS.COMPANY_FOUNDED_DATE_TO:
            return {
                ...state,
                founded_date_to: action.payload,
                changed: true
            }
        case SEARCH_RESULT_ACTIONS.SEARCH_TYPE:
            return {
                ...state,
                searchType: action.payload,
                changed: true
            }
        case SEARCH_RESULT_ACTIONS.SET_ALL:
            return {
                ...state,
                require_email: action.payload.require_email,
                require_double_verified_emails: action.payload.require_double_verified_emails,
                require_company_emails: action.payload.require_company_emails,
                require_personal_emails: action.payload.require_personal_emails,
                require_phone_number: action.payload.require_phone_number,
                require_company_phone_number: action.payload.require_company_phone_number,
                require_personal_phone_number: action.payload.require_personal_phone_number,
                require_social: action.payload.require_social,
                require_linkedin: action.payload.require_linkedin,
                require_facebook: action.payload.require_facebook,
                require_twitter: action.payload.require_twitter,
                require_job_status: action.payload.require_job_status,
                require_employment: action.payload.require_employment,
                require_unemployment: action.payload.require_unemployment,
                require_mailing_address: action.payload.require_mailing_address,

                exclude_previous_experience: action.payload.exclude_previous_experience,
                max_people_per_company: action.payload.max_people_per_company,
                max_results_per_company: action.payload.max_results_per_company,
                founded_date_from: action.payload.founded_date_from,
                founded_date_to: action.payload.founded_date_to,
                recordsPerPage: action.payload.recordsPerPage,
                searchType: action.payload.searchType,
                changed: true
            }

        case SEARCH_RESULT_ACTIONS.CLEAR_ALL:
            return {
                ...INITIAL_STATE,
                searchType: action.payload,
            }
        case SEARCH_RESULT_ACTIONS.CLEAR_SEARCHRESULTFILTER_CHANGED:
            return {
                ...state,
                changed: false,
            }
        default:
            return state;
    }
}
