import React from "react";

const CategoryLine = props => {
  //Группа
  const {
    tags,
    category,
    label,
    name,
    addGroupSelect,
    addTagInSelect,
    addExcludeTag,
    excludeTags,
    haveTags,
    isTechName,
    isTech,
    technologies,
  } = props;
  // const imageSrc = `https://s3.amazonaws.com/leadfuze-tech/icons/${label}.png`;

  const haveTagsAndCount = haveTags ? (
    <p className="dropdown-in-group__tag">
      This group include {tags.length + excludeTags.length} tags
    </p>) : ("");

    let technologyOptions = null;
    if (technologies) {
      technologyOptions =(<div className={`tags-group`}>
          {technologies.map((technology, index) => (
        <div
          className={`tags-group__name tech-base ${technology.toLowerCase()}`}
          key={'tech-' + technology + index}
          onClick={e => {
            // addGroupSelect(category, isTechName, label, "", technology);
            addGroupSelect(technology, true, label, "", technology);
            e.stopPropagation();
          }}
          >
            {/* <img src={`https://s3.amazonaws.com/leadfuze-tech/icons/${technology}.png`} alt="" width="1" height="1" /> */}
          {technology}
        </div>
      ))}
      </div>)
    }
/* 
  const excludeAction = isTech ? (
    <p className="dropdown-in-ex__link" onClick={e => {
          addGroupSelect(category, isTechName, label, "exclude");
          e.stopPropagation();
        }}> Exclude 
      </p>) : (""); */

  /* const excludeSkill = name === 'skills' ? (
    <p className="dropdown-in-ex__link" onClick={e => {
          addExcludeTag(category, "exclude");
          e.stopPropagation();
        }}> Exclude 
      </p>) : (""); */

  let allTags = "";
  if (haveTags) {
    allTags = (
      <div className={`tags-group`}>
        {tags.map((element) => (
          <div
            className={`tags-group__name`}
            key={'tag-'+element}
            onClick={e => {
              addGroupSelect(category, isTechName, label, "", element);
              e.stopPropagation();
            }}>
            {element}
          </div>
        ))}
        {excludeTags.map((element, i) => (
          <div className="field__exclude_tag exclude_tag" key={'excl-' + element + i}
          onClick={e => {
           // addGroupSelect(category, isTechName, label, "exclude", element);
            e.stopPropagation();
          }}>
            {element}
          </div>
        ))}
      </div>
    );
  }
  // ici 
  return (
    <div
      className={`dropdown-in-group ${isTech ? "default-cursor" : ""}`}
      onClick={() => addGroupSelect(category, isTechName, label)}
    >
      {/* {isTechName && (<img src={`https://s3.amazonaws.com/leadfuze-tech/icons/${label}.png`} alt="" width="1" height="1" />)} */}
      <p className={`dropdown-in-group__name  ${isTechName? ('tech-base ' + label.toLowerCase()) : ''}`}>{label}</p>
      {haveTagsAndCount}
      {/* {excludeAction} */}
      {/* {excludeSkill} */}
      {allTags}
      {technologyOptions}
    </div>
  );
};

export default CategoryLine;
