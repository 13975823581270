import React, { Component } from "react";

class SavedSearchesFilters extends Component {

  state = {
    searchFilters: {},
    emailRequireBorder: false,
    socialProfileBorder: false,
    anyFilterSelected: false
  };

  async componentDidMount() {
    let searchFilters = {};
    if (this.props.query.type === "people_prospector") {
      searchFilters = this.props.query.people_prospector;

    } else if (this.props.query.type === "people_lookup") {
      searchFilters = this.props.query.people_lookup;
    }

    await this.setState({searchFilters: searchFilters});

    this._setAnyFilterSelected();
    this._setRequireEmailBorder();
    this._setSocialProfileBorder();
  }

  _setAnyFilterSelected() {
    const searchFilters = this.state.searchFilters;

    if (searchFilters.require_email
      || searchFilters.require_social
      || searchFilters.require_mailing_address
      || searchFilters.require_phone_number
      || searchFilters.require_job_status
      || (searchFilters.max_results_per_company != "No Limit"
           && searchFilters.max_results_per_company != null)
    ) {

      this.setState({anyFilterSelected: true});
    }
  }

  _setRequireEmailBorder() {
    const searchFilters = this.state.searchFilters;

    if((searchFilters.require_company_emails || searchFilters.require_personal_emails ||searchFilters.require_double_verified_emails)
      && (searchFilters.require_linkedin || searchFilters.require_facebook || searchFilters.require_twitter)) {

      this.setState({emailRequireBorder: true});
    }
  }

  _setSocialProfileBorder() {
    const searchFilters = this.state.searchFilters;

    if((searchFilters.require_linkedin || searchFilters.require_facebook || searchFilters.require_twitter)
        && (searchFilters.require_mailing_address || searchFilters.require_phone_number)) {

      this.setState({socialProfileBorder: true});
    }
  }

  render() {
    const searchFilters = this.state.searchFilters;

    if (!this.state.anyFilterSelected) {
      return (
        <div className="empty-list-msg">No filter selected</div>
      );
    }

    return (
      <ul className="saved-search-expanded__filters">
        {searchFilters.require_email && !searchFilters.require_company_emails && !searchFilters.require_personal_emails && !searchFilters.require_double_verified_emails && <li>Require Email</li> }
        {searchFilters.require_company_emails && <li>Require Company Emails</li> }
        {searchFilters.require_personal_emails && <li>Require Personal Emails</li> }
        {searchFilters.require_double_verified_emails && <li>Require Double Verified Emails</li> }

        {/* {this.state.emailRequireBorder && <li className="separator"></li>} */}

        {searchFilters.require_email && searchFilters.require_mailing_address && <li className="separator"></li> }
        {searchFilters.require_mailing_address && <li>Require Mailing Address</li> }
        {(searchFilters.require_email || searchFilters.require_mailing_address) && searchFilters.require_phone_number && <li className="separator"></li> }
        {searchFilters.require_phone_number && <li>Require Phone Number</li> }

        {(searchFilters.require_email || searchFilters.require_mailing_address || searchFilters.require_phone_number) && searchFilters.require_social && <li className="separator"></li>}
        {searchFilters.require_social && !searchFilters.require_linkedin && !searchFilters.require_facebook && !searchFilters.require_twitter && <li>Require Social Profile</li> }
        {searchFilters.require_linkedin && <li>Require Linkedin</li> }
        {searchFilters.require_facebook && <li>Require Facebook</li> }
        {searchFilters.require_twitter && <li>Require Twitter</li> }

        {
            (
              searchFilters.require_email
            || searchFilters.require_mailing_address
            || searchFilters.require_phone_number 
            || searchFilters.require_social
            ) 
            && (
              searchFilters.max_results_per_company != "No Limit"
              && searchFilters.max_results_per_company != null
              && searchFilters.max_results_per_company != ""
            ) 
          && <li className="separator"></li>}
        {searchFilters.max_results_per_company != "No Limit" && searchFilters.max_results_per_company != "" && searchFilters.max_results_per_company != null && <li>Max Results Per Company : { searchFilters.max_results_per_company } </li> }

        {
          (
            searchFilters.require_email
          || searchFilters.require_mailing_address
          || searchFilters.require_phone_number 
          || searchFilters.require_social
          || (
            searchFilters.max_results_per_company != "No Limit"
            && searchFilters.max_results_per_company != null
            && searchFilters.max_results_per_company != ""
            )
          ) && searchFilters.require_job_status && <li className="separator"></li>}
        {searchFilters.require_job_status && <li>{searchFilters.require_employment ? 'Currently Employed' : 'Currently Unemployed'}</li> }

        {
          (
            searchFilters.require_email
          || searchFilters.require_mailing_address
          || searchFilters.require_phone_number 
          || searchFilters.require_social
          || (
            searchFilters.max_results_per_company != "No Limit"
            && searchFilters.max_results_per_company != null
            && searchFilters.max_results_per_company != ""
            )
          || searchFilters.require_job_status
          ) && searchFilters.search_past_employment && <li className="separator"></li>}
        {searchFilters.search_past_employment && <li>{'Search Past Employment History'}</li> }

        {
          (
            searchFilters.require_email
          || searchFilters.require_mailing_address
          || searchFilters.require_phone_number 
          || searchFilters.require_social
          || (
            searchFilters.max_results_per_company != "No Limit"
            && searchFilters.max_results_per_company != null
            && searchFilters.max_results_per_company != ""
            )
          || searchFilters.require_job_status
          || searchFilters.search_past_employment
          ) && searchFilters.include_past_roles && <li className="separator"></li>}
        {searchFilters.include_past_roles && <li>{'Include Past Roles'}</li> }
      </ul>
    );
  }
}
export default SavedSearchesFilters;
