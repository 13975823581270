import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { bindActionCreators } from "redux";
import { accountBasedAction, accountBasedExcludeKeywords, accountBasedFormSubmit, accountBasedIncludeKeywords, accountBasedRole, accountBasedUserListUpload, accountBasedSearchPastEmployment, resetState } from "../../actions/account_based";
import { setErrorMessage } from '../../actions/error_msg';
import { clearAllFilters, setAllSearchFilter, setSearchType } from '../../actions/search_result_filters';
import { Based, LeadSearch, RightPanel, RightPanelBanner, BreadCrumb } from '../../components';
import SaveSearchNameDialog from '../../components/MarkedBasedBox/MarketSearch/SaveSearchNameDialog';
import { SearchType, LOCAL_STORAGE_KEYS, MARKET_BASED_QUERY_PARAM_ACTIONS } from '../../constants/constant';
import { getSavedSearchItemDetail, saveSearchCriteria } from '../../services/saveSearch';
import SearchResult from "../SearchResult";
import { startLoader, stopLoader } from "../../actions/loader";
import { openPopup, closePopup } from '../../actions/loader';
import './style.scss';
import { EmitterService } from "../../services/emitterService";
const queryString = require('query-string');

class AccountBased extends Component {

  constructor(props) {
    super(props);

    this.state = {
      active: 'peopleAct',
      firstName: '',
      lastName: '',
      linkedinUrlCompany: [],
      role: '',
      company: '',
      userList: [],

      expendSearchCriteria: true,
      expendSearchResult: false,
      saveSearchNameDialogOpen: false,
    };
  }

  componentWillMount = () => {
    EmitterService.emit("loadLeadsRemaining");
    this.props.resetState();
    this.props.clearAllFilters(SearchType.AccountBased);
    // this.props.setSearchType(SearchType.AccountBased);
    this.processQueryParams();
    this.props.closePopup();
  };

  componentWillUnmount = () => {
    this.props.accountBasedAction({
      first_name: '',
      last_name: '',
      linkedin_url_company:'',
      role: '',
      include_keywords: '',
      exclude_keywords: '',
      company_domain_tags: '',
    });
  };

  processQueryParams = async() => {
    const queryParams = queryString.parse(this.props.history.location.search);
    if (queryParams.action === "preview_save_search" || queryParams.action === "edit_save_search") {
      this.props.startLoader(true);

      if(queryParams.action === "preview_save_search") {
        this.setState({expendSearchCriteria: false, expendSearchResult: true});
        localStorage.setItem("expendSearchResult", true);
      }

      let toastId = null;
      toastId = toast('Creating Saved Search', {
        hideProgressBar: true,
        autoClose: 10000,
      });

      const searchId = queryParams.id;
      const detailRes = await getSavedSearchItemDetail(searchId);
      const peopleQuery = detailRes.data.data.query.people_lookup;
      this.setState({selectedSavedSearch: detailRes.data.data, firstName: peopleQuery.first_name,
      lastName: peopleQuery.last_name});
      toast.update(toastId, {
        render: 'Saved Search created successfully',
        className: "toast-success-div",
        type: toast.TYPE.SUCCESS,
        hideProgressBar: true,
        autoClose: 2000,
      });
      this.props.accountBasedAction(detailRes.data.data.query.people_lookup);
      this.props.setAllSearchFilter(detailRes.data.data.query.people_lookup);

      if(queryParams.action === "preview_save_search") {
        this._loadSearchResults();
      } else {
        this.props.stopLoader(true);
      }
    }
  }

  onFirstNameChange = (e => {
    this.setState({firstName:  e.target.value});
  });

  onLinkedinURLCompanyChange = (e => {
    this.setState({linkedinUrlCompany:  [e.target.value]});
  });

  setLinkedinUrlCompany = (linkedinUrlCompany => {
    this.setState({linkedinUrlCompany});
  });

 
  delLinkedInRow = (item) => {
    let linkedinUrlCompanyList = [];
    const {linkedinUrlCompany} = this.state;

    linkedinUrlCompany.forEach(element => {
      if (element != item) {
        linkedinUrlCompanyList.push(element)
      }
    }); 

    this.setState({linkedinUrlCompany: linkedinUrlCompanyList});
  }
  
  onLastNameChange = (e => {
    this.setState({lastName: e.target.value});
  });

  onRoleChange = (e => {
    this.setState({role: e.target.value});
  });

  onCompanyChange = (e => {
    this.setState({company: e.target.value});
  });

  applyAccountBasedForm = (async e => {
    EmitterService.emit("startSearching");

    setTimeout(async () => {
      this.props.setErrorMessage(false);
      this.setState({searchError: false});
      
      const {firstName, lastName, company, linkedinUrlCompany, role} = this.state;

      const { company_domain_tags  } = this.props.accountBased;
      
      if((!linkedinUrlCompany || !linkedinUrlCompany.length) &&
          (!company_domain_tags || !company_domain_tags.length)) {
        this.props.setErrorMessage(true);
        this.setState({searchError: true});
        return;
      }

     
      await this.props.accountBasedFormSubmit({
        firstName: firstName,
        lastName: lastName,
        company: company,
        linkedinUrlCompany: linkedinUrlCompany,
        role: role,
      });
      this._loadSearchResults();
      this.setState({expendSearchCriteria: false, expendSearchResult: true});
      localStorage.setItem("expendSearchResult", true);
    }, 100)
  });

  resetFilters = () => {
    this.setState({
      firstName: '',
      lastName: '',
      company: '',
      linkedinUrlCompany: '',
      role: null,
      clearFields: true,
    })
    this.props.resetState();
  };

  updateClearFields = () => {
    this.setState({ clearFields: false });
  };

  setIncludeTags = (tags, name) => {
    
    const { accountBased, accountBasedRole } = this.props;

    if(name === 'role') {
      let currentRoleData = accountBased.role;

    if (currentRoleData === null) {
      currentRoleData = { include_keywords: tags };
    } else {
      currentRoleData.include_keywords = tags;
    }
      accountBasedRole(currentRoleData);
    } else if (name === 'keywords') {
      this.props.accountBasedIncludeKeywords(tags);
    } else {
      this.props.accountBasedUserListUpload(tags);
    }
  };

  setExcludeTags = (tags, name) => {
    const { accountBased, accountBasedRole } = this.props;

    if(name === 'role') {
      let currentRoleData = accountBased.role;

    if (currentRoleData === null) {
      currentRoleData = { exclude_keywords: tags };
    } else {
      currentRoleData.exclude_keywords = tags;
    }
      accountBasedRole(currentRoleData);
    } else if (name === 'keywords') {
      this.props.accountBasedExcludeKeywords(tags);
    } else {
      this.props.accountBasedUserListUpload(tags);
    }
  };

  setGroups = (name, data) => {
    const {
      accountBasedRole,
      accountBased
    } = this.props;
        let currentRoleGroups = accountBased.role;
        if (currentRoleGroups === null) {
          let groupsMap = {};
          for (let i = 0; i < data.length; i++) {
            groupsMap[data[i].group] = {
              open: data[i].open,
              include_keywords: data[i].tags,
              exclude_keywords: data[i].excludeTags
            };
          }
          currentRoleGroups = { groups: groupsMap };
        } else {
          let groupsMap = {};
          for (let i = 0; i < data.length; i++) {
            groupsMap[data[i].group] = {
              open: data[i].open,
              include_keywords: data[i].tags,
              exclude_keywords: data[i].excludeTags
            };
          }
          currentRoleGroups.groups = groupsMap;
        }
        accountBasedRole(currentRoleGroups, "groups");
  };

  applyFilter = async () => {
    window.scrollTo(0, 0);
    this._loadSearchResults();
  }

  clearAllFilters = async () => {
    await this.props.clearAllFilters(SearchType.AccountBased);
    this._loadSearchResults();
  }

  _loadSearchResults() {
    EmitterService.emit("loadSearchResults", true);
  }

  changeSearch = () => {
    this.setState({expendSearchCriteria: true, expendSearchResult: false});
    localStorage.removeItem("expendSearchResult");
    //EmitterService.emit("loadSearchResults", false);
  }

  openSaveSearchDialog = () => {
    this.setState({saveSearchNameDialogOpen: true})
  }

  createNewSaveSearch = async(name) => {
    this.onSaveSearchCancel();
    const {accountBased, searchResultFilters} = this.props;
    try {
      let toastId = null;
      toastId = toast('Saving search criteria', {
        hideProgressBar: true,
        autoClose: 10000,
      });
      const res = await saveSearchCriteria(
        undefined,
        {...accountBased, first_name: this.state.firstName, last_name: this.state.lastName},
        searchResultFilters,
        SearchType.AccountBased,
        name
      );
      toast.update(toastId, {
        render: 'Search criteria saved successfully',
        className: "toast-success-div",
        type: toast.TYPE.SUCCESS,
        hideProgressBar: true,
        autoClose: 2000,
      });
    } catch (error) {
    }
  }

  onSaveSearch = () => {
    if(!this.props.accountBased.company_domain_tags.length) {
      this.props.setErrorMessage(true);
      this.setState({searchError: true});
      return;
    } else {
      this.props.setErrorMessage(false);
      this.setState({ searchError: false });
      this.openSaveSearchDialog();
    }
  };

  onSaveSearchCancel = () => {
    this.setState({saveSearchNameDialogOpen : false});
  }

  togglePastEmployment = () => {
    this.props.accountBasedSearchPastEmployment(!this.props.accountBased.search_past_employment);
  }

  render() {
    const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
    const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT));

    let links = this.state.expendSearchCriteria ? [
      {
        name: 'Lead Search',
        slug: '/lead-search/market-based',
      },
      {
        name: 'Account Based',
        slug: '/lead-search/account-based',
      },
      {
        name: 'Search Criteria',
        slug: null,
      },
    ] : [
      {
        name: 'Lead Search',
        slug: '/lead-search/market-based',
      },
      {
        name: 'Account Based',
        slug: '/lead-search/account-based',
      },
      {
        name: 'Search Results',
        slug: null,
      },
    ];

    const queryParams = queryString.parse(this.props.history.location.search);
    if ( queryParams.action === MARKET_BASED_QUERY_PARAM_ACTIONS.PREVIEW_SAVE_SEARCH ) {
      links = [
        {
          name: 'Lead Search',
          slug: '/lead-search/account-based',
        },
        {
          name: 'Saved Searches',
          slug: '/lead-search/saved-searches',
        },
        {
          name: queryParams.name,
          slug: null,
        },
        {
          name: 'Show Results',
          slug: null,
        },
      ];
    }

    if ( queryParams.action === "edit_save_search" ) {
      links = this.state.expendSearchCriteria ? [
        {
          name: 'Lead Search',
          slug: '/lead-search/account-based',
        },
        {
          name: 'Saved Searches',
          slug: '/lead-search/saved-searches',
        },
        {
          name: queryParams.name,
          slug: null,
        },
        {
          name: 'Edit Traits',
          slug: null,
        },
      ] : [
        {
          name: 'Lead Search',
          slug: '/lead-search/account-based',
        },
        {
          name: 'Saved Searches',
          slug: '/lead-search/saved-searches',
        },
        {
          name: queryParams.name,
          slug: null,
        },
        {
          name: 'Search Results',
          slug: null,
        },
      ]
    }

    const { searchError } = this.state;

    const pathname = this.props.history.location.pathname;
    return (
      <div className="accountBased-panel">
        {userId === null && this.props.history.push("/signin?path=/lead-search/account-based")}

        {userAccount.status === "trial_expired" && this.props.history.push('/billing-and-users')}
        {pathname.split("?").length === 1 && this.state.expendSearchResult && window.history.pushState('', '',  `${pathname}?results=true`) }

        <BreadCrumb links={links} changeSearch={this.changeSearch}></BreadCrumb>

        <SaveSearchNameDialog open={this.state.saveSearchNameDialogOpen} onCancel={this.onSaveSearchCancel} onSaveSearch={this.createNewSaveSearch} />

        <div className="accountbased__table content-row">

          <div className="content-col-1">
            <section className="accountbased__search-criteria">
              <Based
                {...this.state}
                onFirstNameChange={this.onFirstNameChange}
                onLastNameChange={this.onLastNameChange}
                onLinkedinURLCompanyChange={this.onLinkedinURLCompanyChange}
                setLinkedinUrlCompany={this.setLinkedinUrlCompany}
                delLinkedInRow={this.delLinkedInRow}
                onRoleChange={this.onRoleChange}
                onCompanyChange={this.onCompanyChange}
                excludedCompanies={this.props.accountBased.company_domain_tags}
                setIncludeTags={this.setIncludeTags}
                setExcludeTags={this.setExcludeTags}
                setGroups={this.setGroups}
                clearFields={this.state.clearFields}
                updateClearFields={this.updateClearFields}
                expendSearchCriteria={this.state.expendSearchCriteria}
                changeSearch={this.changeSearch}
                accountBased={this.props.accountBased}
              />

              {this.state.expendSearchCriteria &&
                <LeadSearch
                  accountBased={this.props.accountBased}
                  applyAccountBasedForm={this.applyAccountBasedForm}
                  togglePastEmployment={this.togglePastEmployment}
                  resetFilters={this.resetFilters}
                  setIncludeTags={this.setIncludeTags}
                  setExcludeTags={this.setExcludeTags}
                  setGroups={this.setGroups}
                  clearFields={this.state.clearFields}
                  updateClearFields={this.updateClearFields}
                  searchError={this.state.searchError}
                  resetError={() => this.setState({ searchError: !searchError })}
                />
              }
            </section>

            <section className="mt-20">
              <SearchResult
                expendSearchResult={this.state.expendSearchResult}
              />
            </section>
          </div>

          <div className="content-col-2">
            <div className="accountbased__rightbar">
              <RightPanelBanner title="Account Based Demo" page="accountBased" type="account_based_search" video_id="t16lq6v74f"/>

              {this.state.expendSearchResult &&
                <RightPanel
                  applyFilter={this.applyFilter}
                  canApplyFilter={true}
                  clearAllFilters={this.clearAllFilters}
                  saveSearch={this.onSaveSearch}
                  expendSearchCriteria={this.state.expendSearchCriteria} />
              }
            </div>
          </div>

        </div>
      </div>
    );
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      accountBasedRole,
      accountBasedIncludeKeywords,
      accountBasedExcludeKeywords,
      accountBasedSearchPastEmployment,
      accountBasedFormSubmit,
      accountBasedUserListUpload,
      setSearchType,
      setErrorMessage,
      resetState,
      accountBasedAction,
      setAllSearchFilter,
      clearAllFilters,
      startLoader,
      stopLoader,
      openPopup,
      closePopup,
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    accountBased: state.accountBased,
    searchResultFilters: state.searchResultFilters,
    user: state.user
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountBased);
