import React, {Component, Fragment} from 'react';
import './style.scss';
import {Loader} from "../../../..";
import {addBillingUser, addUsersCostPreview, updateBillingUser} from '../../../../../services/billing';
import {toast} from 'react-toastify';
import { showSuccessToast, showErrorToast } from '../../../../../helpers/toast';

class AddUserPopup extends Component {
    container = React.createRef();

    state = {
        showRoleDropDown: false,
        isNewUser: true,
        isLoading: false,
        isCalculatingCost: false,
        cost: 0,
        formErrors: {},
        unlimitedLeads: false,
        is_admin: false,
        user: {
            first_name: "",
            last_name: "",
            email: "",
            role: "",
            max_lead_credits: "",
            is_admin: false
        }
    };

    componentDidMount() {
        this.loadUserFormData();
        document.addEventListener("mousedown", this.clickEventHandle);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.clickEventHandle);
    }

    clickEventHandle = (event) => {
        if (this.container.current && !this.container.current.contains(event.target)) {
            if (this.state.showRoleDropDown) {
                this.onShowRoleDropDown();
            }
        }
    }

    loadUserFormData = () => {
        if (this.props.user) {
            const user = {
                id: this.props.user.id,
                first_name: this.props.user.first_name,
                last_name: this.props.user.last_name,
                email: this.props.user.email,
                role: this.props.user.role,
                is_admin: this.props.user.is_admin
            };

            let unlimitedLeads = false;
            if (this.props.user.max_lead_credits === -1) {
                user.max_lead_credits = "";
                unlimitedLeads = true;
            } else {
                user.max_lead_credits = this.props.user.max_lead_credits;
            }

            this.setState({
                isNewUser: false,
                user: user,
                unlimitedLeads: unlimitedLeads,
            });
        } else {
            this.calculateAddUserCost();
        }
    }

    checkUnlimitedLeads = () => {
        const user = this.state.user;
        user.max_lead_credits = "";
        this.setState({user: user, unlimitedLeads: !this.state.unlimitedLeads});
    }

    calculateAddUserCost = async () => {
        this.setState({isCalculatingCost: true});
        const calculateRes = await addUsersCostPreview(1);
        const cost = calculateRes.data.data;
        this.setState({isCalculatingCost: false, cost});
    }

    onShowRoleDropDown = () => {
        this.setState({showRoleDropDown: !this.state.showRoleDropDown});
    }

    getButtonText = () => {
        if (this.state.isNewUser) {
            return this.state.isCalculatingCost ? 'Calculating...' : `Pay $${(this.state.cost / 100).toFixed(2)}`;
        } else {
            return 'Update';
        }
    }

    updateValue = (field, value) => {
        const user = this.state.user;
        if (field == "is_admin") {
            user.is_admin = !user.is_admin
        } else {
            user[field] = value;
        }
        this.setState({user: user, showRoleDropDown: false});
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    isNullOrEmpty(field) {
        return !field || field.trim().length === 0;
    }

    validateForm = () => {
        const user = this.state.user;
        const formErrors = {};

        if (this.isNullOrEmpty(user.first_name)) {
            formErrors["first_name"] = "This field is required";
        }

        if (this.isNullOrEmpty(user.last_name)) {
            formErrors["last_name"] = "This field is required";
        }

        if (this.isNullOrEmpty(user.role)) {
            formErrors["role"] = "Select a value";
        }

        if (this.isNullOrEmpty(user.email)) {
            formErrors["email"] = "This field is required";
        } else if (!this.validateEmail(user.email.trim())) {
            formErrors["email"] = "Email format is not valid";
        }
        if (this.isNullOrEmpty(user.max_lead_credits.toString()) && !this.state.unlimitedLeads) {
            formErrors["max_lead_credits"] = "This field is required";
        }

        this.setState({formErrors: formErrors});
        return Object.keys(formErrors).length === 0;
    }

    onSubmit = async () => {
        if (this.validateForm()) {
            this.setState({isLoading: true});
            const user = JSON.parse(JSON.stringify(this.state.user));
            user.max_lead_credits = this.state.unlimitedLeads ? -1 : parseInt(user.max_lead_credits);

            try {
                let response = null;
                if (this.state.isNewUser) {
                    response = await addBillingUser(user);
                } else {
                    response = await updateBillingUser(user);
                }

                this.setState({isLoading: false});
                this.props.closePopup(true);
            } catch (error) {
                this.setState({isLoading: false});
                const msg = this.state.isNewUser ? "Unable to save user" : "Unable to update user";

                toast.error(msg, {
                    className: "toast-error-div",
                    type: toast.TYPE.ERROR,
                    autoClose: 3000
                });
            }
        }
    }

    render() {

        return (
            <Fragment>
                <div className={`add-user-popup`}>
                    <div className="add-user-popup__content">
                        <img className="add-user-popup__close" src="/assest/accountBased/close.svg" alt=""
                             onClick={() => this.props.closePopup(false)}/>
                        <p className="add-user-popup__title">{this.state.isNewUser ? "Add User" : "Edit User"}</p>

                        {this.state.isLoading && <Loader/>}

                        <div className="form-panel">

                            <div className="row">
                                <div className="column">
                                    <label className="form__label">
                                        <p>First Name</p>
                                        <input placeholder="Enter First Name" type="text"
                                               value={this.state.user.first_name}
                                               onChange={(e) => this.updateValue("first_name", e.target.value)}
                                               className={this.state.formErrors.first_name ? 'error-field' : ''}/>
                                        {this.state.formErrors.first_name &&
                                        <div className="error">{this.state.formErrors.first_name}</div>}
                                    </label>
                                </div>

                                <div className="column">
                                    <label className="form__label">
                                        <p>Last Name</p>
                                        <input placeholder="Enter Last Name" type="text"
                                               value={this.state.user.last_name}
                                               onChange={(e) => this.updateValue("last_name", e.target.value)}
                                               className={this.state.formErrors.last_name ? 'error-field' : ''}/>
                                        {this.state.formErrors.last_name &&
                                        <div className="error">{this.state.formErrors.last_name}</div>}
                                    </label>
                                </div>

                                <div className="column">
                                    <label className="form__label">
                                        <p>Email</p>
                                        <input placeholder="Enter Email" type="text" value={this.state.user.email}
                                               onChange={(e) => this.updateValue("email", e.target.value)}
                                               className={this.state.formErrors.email ? 'error-field' : ''}/>
                                        {this.state.formErrors.email &&
                                        <div className="error">{this.state.formErrors.email}</div>}
                                    </label>
                                </div>

                                <div className="column">
                                    <label className="form__label">
                                        <p>Role</p>

                                        <div className="role-dropdown">
                                            <div
                                                className={`dropdown-value ${this.state.formErrors.role ? 'error-field' : ''}`}
                                                onClick={this.onShowRoleDropDown}>{this.state.user.role === "" ? "Select Role" : this.state.user.role}</div>

                                            {this.state.showRoleDropDown &&
                                            <div className="dropdown-start" ref={this.container}>
                                                <div className="dropdown-in-group"
                                                     onClick={() => this.updateValue("role", "Founding Team")}>Founding
                                                    Team
                                                </div>
                                                <div className="dropdown-in-group"
                                                     onClick={() => this.updateValue("role", "Marketing")}>Marketing
                                                </div>
                                                <div className="dropdown-in-group"
                                                     onClick={() => this.updateValue("role", "Recruiting")}>Recruiting
                                                </div>
                                                <div className="dropdown-in-group"
                                                     onClick={() => this.updateValue("role", "Sales")}>Sales
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        {this.state.formErrors.role &&
                                        <div className="error">{this.state.formErrors.role}</div>}
                                    </label>
                                </div>

                                <div className="column max-leads-col" >
                                    <div style={{marginTop: 2}}
                                        className={`check ${this.state.user && this.state.user.is_admin ? 'active' : ''}`}
                                        onClick={(e) => this.updateValue("is_admin", null)}></div>
                                    <div className="check-label">Is Admin</div>
                                </div>

                                <div className="column max-leads-col" style={{marginTop: '15px'}}>
                                    <label className="form__label">
                                        {/* <p>Max Leads</p> */}
                                        <input placeholder="Enter Max Leads" disabled={this.state.unlimitedLeads}
                                               min="0" type="number" value={this.state.user.max_lead_credits}
                                               onChange={(e) => this.updateValue("max_lead_credits", e.target.value)}
                                               className={this.state.formErrors.max_lead_credits ? 'error-field' : ''}/>
                                        {this.state.formErrors.max_lead_credits &&
                                        <div className="error">{this.state.formErrors.max_lead_credits}</div>}
                                    </label>

                                    <div className="check-panel">
                                        <div className={`check ${this.state.unlimitedLeads ? 'active' : ''}`}
                                             onClick={() => this.checkUnlimitedLeads()}></div>
                                        <div className="check-label">Unlimited</div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="add-user-popup__buttons">
                            <div className={`btn orange ${this.state.isCalculatingCost ? 'disabled' : ''}`}
                                 onClick={this.onSubmit}>{this.getButtonText()}</div>
                            <div className="btn orange-light" onClick={() => this.props.closePopup(false)}>Cancel</div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default AddUserPopup;
