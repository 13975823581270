import React, { Component, Fragment } from 'react';
import './styles.scss';

class ConfirmationPopup extends Component {
   
  render() {

    return (
      <Fragment>
        <div className={`confirmation-popup`}>
          <div className="confirmation-popup__content">
            <img className="confirmation-popup__close" src="/assest/accountBased/close.svg" alt="" onClick={this.props.onCloseClick} />
            <p className="confirmation-popup__title">{this.props.title}</p>
            
            <p className="confirmation-popup__message">{this.props.message}</p>

            <div className="confirmation-popup__buttons">
              <div className="confirmation-popup__btn orange" onClick={this.props.onYesClick} >{this.props.yesBtnText ? this.props.yesBtnText : 'Yes' }</div>
              <div className="confirmation-popup__btn orange-light" onClick={this.props.onNoClick}>{this.props.noBtnText ? this.props.noBtnText : 'No' }</div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ConfirmationPopup;
