import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {ContentResult, HeaderResult, Pagination} from '../../components';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import './style.scss';
import {toast} from 'react-toastify';

import {setErrorMessage} from '../../actions/error_msg';
import {closePopup, openPopup, startLoader, stopLoader} from '../../actions/loader';
import {
    addLeads,
    getLeadDetail,
    getPersonDetailForAddedLead,
    getSearchResults,
    skipLead,
    unSkipSingleLead
} from '../../services/searchResult';
import {clearMarketBasedChanged} from '../../actions/marketBased'
import {clearAccountBasedChanged} from '../../actions/account_based'
import {clearSearchResultFiltersChanged} from '../../actions/search_result_filters'
import {EmitterService} from "../../services/emitterService";
import {getLeadListById} from '../../services/leads';
import {getOnlyMarketPlaceObjectFromProps} from "../../constants/helper";

const queryString = require('query-string');

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        startLoader,
        stopLoader,
        openPopup,
        closePopup,
        setErrorMessage,
        clearMarketBasedChanged,
        clearAccountBasedChanged,
        clearSearchResultFiltersChanged
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        searchResultFilters: state.searchResultFilters,
        user: state.user,
        marketBased: state.marketBased,
        accountBased: state.accountBased,
    };
}

class SearchResult extends Component {
    container = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            items: undefined,
            selectedItems: [],
            selectedLeadId: null,
            totalItems: 0,
            maxAddCount: 0,
            addMax: false,
            check: false,
            showHeaderButtons: false,
            popup: false,
            searchResultError: false,
            currentPageNum: 1,
            recordsPerPage: 25,
            showDropDown: false,
            cursorsByPage: {1: ''},
            showCheckDropDown: false,
            showLoader: true,
            showLeadDetailLoader: false,
            leadDetail: null,
            stickyDarkColor: false,
        };
    }

    componentDidMount() {
        let recordsPerPage = localStorage.getItem('recordsPerPage');
        if (recordsPerPage === null) {
            localStorage.setItem('recordsPerPage', '25');
            recordsPerPage = localStorage.getItem('recordsPerPage');
        }

        this.setState({recordsPerPage: recordsPerPage});
        document.addEventListener("mousedown", this.clickEventHandle);

        EmitterService.subscribe("loadSearchResults", (loadData) => {
            if (loadData) {
                // if(!this.props.marketBased.changed && !this.props.accountBased.changed && !this.props.searchResultFilters.changed) {
                //   return;
                // }
                // else {
                this.setState({currentPageNum: 1});
                this.loadSearchResultList();
                //}
            } else {
                this._clearState();
            }
        });
    }

    componentWillMount() {
        EmitterService.emit("loadLeadsRemaining");
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.clickEventHandle);
        this.props.setErrorMessage(false);
        this.setState({searchResultError: false});
        EmitterService.unsubscribe("loadSearchResults");
    }

    clickEventHandle = (event) => {
        const {showDropDown} = this.state;
        if (this.container.current && !this.container.current.contains(event.target)) {
            if (showDropDown) {
                this.paginationPopup();
            }

            if (this.state.showCheckDropDown) {
                this.onShowCheckDropDown();
            }
        }
    }

    _clearState() {
        this.setState({
            items: [],
            selectedItems: [],
            selectedLeadId: null,
            totalItems: 0,
            maxAddCount: 0,
            addMax: false,
            check: false,
            showHeaderButtons: false,
            popup: false,
            searchResultError: false,
            currentPageNum: 1,
            recordsPerPage: 25,
            showDropDown: false,
            cursorsByPage: {1: ''},
            showCheckDropDown: false,
            showLoader: false,
            showLeadDetailLoader: false,
            leadDetail: null,
            stickyDarkColor: false,
        });
    }

    paginationPopup = async () => {
        this.setState({showDropDown: !this.state.showDropDown});
    }

    selectItem = ($event, id) => {
        $event.stopPropagation();

        this.setState(state => {
            const {items} = state;
            let selectedItem = null;

            items.forEach(item => {
                if (item.poolId === id) {
                    if (!item.skipped && !item.addedToList) {
                        item.check = !item.check;
                        selectedItem = item;
                    }
                    return;
                }
            });

            let tmpSelectedItems = [];
            if (selectedItem && selectedItem.check) {
                tmpSelectedItems = [...state.selectedItems, selectedItem]
            } else {
                tmpSelectedItems = state.selectedItems.filter((item, index) => {
                    if (item.poolId === id) {
                        return false;
                    }
                    return true;
                });
            }

            const checkAll = items.length > 0 && tmpSelectedItems.length === items.length;

            return {
                check: checkAll,
                items: items,
                selectedItems: tmpSelectedItems,
                showHeaderButtons: state.selectedItems.length > 0,
                addMax: checkAll ? state.addMax : false
            }
        })
    }

    checkedAll = ($event, value) => {
        if ($event) {
            $event.stopPropagation();
        }
        const selectedItems = [];

        let check;

        if (value) {
            check = false;
        } else {
            check = this.state.check;
        }

        this.state.items.forEach((item) => {
            if (check) {
                item.check = false
            } else {
                if (!item.skipped && !item.addedToList) {
                    item.check = true;
                    selectedItems.push(item);
                }
            }
        });

        this.setState({
            check: !check,
            items: this.state.items,
            selectedItems: selectedItems,
            showHeaderButtons: selectedItems.length > 0,
            addMax: (this.state.addMax && check) ? false : this.state.addMax
        });
    }

    showSearchResultItemDetail = async (id) => {
        if (id) {
            this.setState({stickyDarkColor: true});
            this.props.openPopup();
        } else {
            this.setState({stickyDarkColor: false});
            this.props.closePopup();
        }
        const {items} = this.state;
        items.forEach(async (item) => {
            if (id && item.poolId === id) {
                if (!item.active && !item.skipped) {
                    this.startLoader({showLeadDetailLoader: true});
                    this.setState({leadDetail: null});

                    item.active = true;
                    let leadDetail = null;

                    if (item.addedToList) {
                        const response = await getPersonDetailForAddedLead(item.leadId);
                        leadDetail = response.data.data.person;
                        leadDetail.leadId = item.leadId;
                        leadDetail.email = item.email;
                    } else {
                        const response = await getLeadDetail(id);
                        leadDetail = response.data.data;
                    }

                    leadDetail.addedToList = item.addedToList;

                    this.setState({leadDetail: leadDetail});
                    this.stopLoader({showLeadDetailLoader: false});
                }
                return;
            } else {
                item.active = false;
            }
        });

        this.setState({items: items});
    }

    skipLead = async ($event, id, skipMultiple) => {
        $event.stopPropagation();
        const toastId = this._showToaster(skipMultiple ? 'Skipping selected leads' : 'Skipping lead');

        const ids = [];

        if (skipMultiple) {
            this.state.selectedItems.forEach((item) => {
                ids.push(item.poolId);
            });
        } else {
            ids.push(id);
        }

        try {
            await skipLead(ids);
            ids.forEach((poolId) => {
                this.state.items.forEach((item) => {
                    if (item.poolId === poolId) {
                        item.skipped = true;
                        item.check = false;
                        item.active = false;
                        return;
                    }
                });
            });

            if (skipMultiple) {
                this.setState({items: this.state.items, check: false, selectedItems: [], showHeaderButtons: false});
            } else {
                this.state.selectedItems.forEach((item, index) => {
                    if (id === item.poolId) {
                        this.state.selectedItems.splice(index, 1);
                        return;
                    }
                });

                this.setState({
                    items: this.state.items,
                    check: false,
                    selectedItems: this.state.selectedItems,
                    showHeaderButtons: this.state.selectedItems.length > 0
                });
            }

        } catch (error) {
        }

        this._updateToaster(toastId, skipMultiple ? 'Selected leads skipped successfully' : 'Lead skipped successfully');
    }

    unSkipSingleLead = async ($event, id) => {
        $event.stopPropagation();
        const toastId = this._showToaster('Returning lead');

        try {
            await unSkipSingleLead(id);
            this.state.items.forEach((item) => {
                if (item.poolId === id) {
                    item.skipped = false;
                    return;
                }
            });

            this.setState({items: this.state.items});

        } catch (error) {
        }

        this._updateToaster(toastId, 'Lead returned successfully');
        ;
    }

    addLeadsToList = async (list) => {
        const leadIds = [];

        let addMax = this.state.addMax;

        if (this.state.selectedLeadId) {
            leadIds.push(this.state.selectedLeadId);
            addMax = false;
        } else {
            this.state.selectedItems.forEach((item) => {
                leadIds.push(item.poolId);
            });
        }

        leadIds.forEach((leadId) => {
            this.state.items.forEach((item) => {
                if (item.poolId === leadId) {
                    item.addingLeadToList = true;
                    item.leadProgressText = "Gathering details";

                    if (item.active) {
                        this.state.leadDetail.addingLeadToList = true;
                        this.state.leadDetail.leadDataLoaded = false;
                    }
                }
            });
        });

        this.setState({items: this.state.items, leadDetail: this.state.leadDetail, popup: false});

        try {
            let list_id = list.id ? list.id : list._id ? list._id : null;

            if (list_id === null) {
                let toastId = toast('No List Selected', {
                    autoClose: 3000,
                    className: "toast-error-div",
                    type: toast.TYPE.ERROR
                });
            }


            const {accountBased, marketBased, searchResultFilters} = this.props;

            let people_prospector = {...searchResultFilters};
            let people_lookup = {...searchResultFilters};
            let type = "people_prospector";
            if (this.props.history.location.pathname.includes('account-based')) {
                people_lookup = {...people_lookup, ...accountBased};
                people_prospector = null;
                type = "people_lookup";
            } else {
                let marketBasedObj = getOnlyMarketPlaceObjectFromProps(marketBased, searchResultFilters);
                people_prospector = {
                    ...people_prospector,
                    ...marketBasedObj
                };
                people_lookup = null;
            }

            const response = await addLeads(list_id, leadIds, addMax, people_prospector, people_lookup, type);
            // if (addMax) {

            //   this.props.history.push({
            //     pathname: '/lead-list/inside-list',
            //     search: `?list_id=${list._id}`
            //   });
            //   return;
            // }

            if (addMax) {
                this.state.items.forEach((item) => {
                    item.addingLeadToList = false;
                    item.addedToList = false;
                    item.check = false
                    item.warningMsg = "We are validating contact info and will add leads that pass validation to your list. We will send you an email when this process is complete."
                })

            } else {

                this.state.items.forEach((item) => {
                    response.data.data.success && response.data.data.success.forEach((lead) => {
                        if (item.poolId === lead.person.id) {

                            this._updateAddLeadProgress(item, lead);


                            item.listName = list.name;
                            item.listId = list._id;
                            item.leadId = lead.id;
                            item.emails = lead.person ? lead.person.emails : null;
                            item.person = {
                                emails: lead.person ? lead.person.emails : null
                            }
                            item.email = lead.email;
                            item.phone = lead.person.phone ? lead.person.phone : [];
                            if (lead.person.job && lead.person.job.company && lead.person.job.company.phone) {
                                item.phone.push({
                                    number: lead.person.job.company.phone
                                });
                            }
                            item.mailing_address = item.hasMailingAddress && lead.person.job && lead.person.job.company && lead.person.job.company.location ? lead.person.job.company.location.street : '';
                            item.catch_all = lead.catch_all;
                            item.verified = lead.verified;
                            item.social_profiles = lead.person ? lead.person.social_profiles : null;
                            let selectedItems = this.state.selectedItems.filter((temp) => temp.poolId !== item.poolId);
                            this.setState({selectedItems: selectedItems, showHeaderButtons: selectedItems.length > 0});

                            return;
                        }
                    });
                });

                //failure, failed_validation, list_full, no_credits
                response.data.data.failure && response.data.data.failure.forEach((lead) => {
                    this.state.items.forEach((item) => {
                        if (item.poolId === lead) {

                            this._updateAddLeadFailure(item, lead, 'There was a server error. Try again later.');

                            let selectedItems = this.state.selectedItems.filter((temp) => temp.poolId !== item.poolId);
                            this.setState({selectedItems: selectedItems, showHeaderButtons: selectedItems.length > 0});

                            return;
                        }
                    });
                });

                response.data.data.failed_validation && response.data.data.failed_validation.forEach((lead) => {
                    this.state.items.forEach((item) => {
                        if (item.poolId === lead) {

                            this._updateAddLeadFailure(item, lead, 'Not added. Email is now undeliverable.');

                            let selectedItems = this.state.selectedItems.filter((temp) => temp.poolId !== item.poolId);
                            this.setState({selectedItems: selectedItems, showHeaderButtons: selectedItems.length > 0});

                            return;
                        }
                    });
                });

                response.data.data.list_full && response.data.data.list_full.forEach((lead) => {
                    this.state.items.forEach((item) => {
                        if (item.poolId === lead) {

                            this._updateAddLeadFailure(item, lead, 'Your list is full. Add to a new list.');

                            let selectedItems = this.state.selectedItems.filter((temp) => temp.poolId !== item.poolId);
                            this.setState({selectedItems: selectedItems, showHeaderButtons: selectedItems.length > 0});

                            return;
                        }
                    });
                });

                response.data.data.no_credits && response.data.data.no_credits.forEach((lead) => {
                    this.state.items.forEach((item) => {
                        if (item.poolId === lead) {

                            this._updateAddLeadFailure(item, lead, 'You are out of lead credits. Upgrade.');

                            let selectedItems = this.state.selectedItems.filter((temp) => temp.poolId !== item.poolId);
                            this.setState({selectedItems: selectedItems, showHeaderButtons: selectedItems.length > 0});

                            return;
                        }
                    });
                });

            }

            this.setState({items: this.state.items});

        } catch (error) {
            leadIds.forEach((leadId) => {
                this.state.items.forEach((item) => {
                    if (item.poolId === leadId) {
                        item.leadProgressText = "There was a server error. Try again later.";
                        item.addingLeadToList = false;
                        item.addedToList = false;
                        item.check = false;
                        item.errorMsg = "There was a server error. Try again later.";
                    }
                });
            });
        }
    }

    _updateAddLeadProgress = (item, lead) => {
        item.leadProgressText = "Info found";
        this.setState({items: this.state.items});

        setTimeout(() => {
            item.leadProgressText = "Reverifying contact details";
            item.leadDataLoaded = true;

            if (item.active) {
                this.state.leadDetail.leadDataLoaded = true;
            }
            this.setState({items: this.state.items, leadDetail: this.state.leadDetail});

        }, 2000);

        setTimeout(() => {
            item.leadProgressText = "";
            item.addingLeadToList = false;
            item.addedToList = true;
            item.check = false;

            if (item.active) {
                if (!lead.person) {
                    lead.person = {};
                }
                lead.person.addedToList = true;
                lead.person.leadId = lead.id;
                lead.person.email = lead.email;
                this.setState({leadDetail: lead.person});
            }

            this.setState({items: this.state.items});
        }, 4000);
    }

    _updateAddLeadFailure = (item, lead_id, message) => {
        item.errorMsg = message;
        item.warningMessage = message
        this.setState({items: this.state.items});

        setTimeout(() => {
            item.leadProgressText = message;
            item.addingLeadToList = false;
            item.addedToList = false;
            item.check = false;
            item.errorMsg = message;
            item.warningMessage = message

            // if (item.active) {
            //   lead.person.addedToList = true;
            //   lead.person.leadId = lead.id;
            //   lead.person.email = lead.email;
            //   this.setState({leadDetail: lead.person});
            // }

            this.setState({items: this.state.items});
        }, 2000);
    }

    openAddLeadToListPopup = async ($event, id) => {
        $event.stopPropagation();
        const qParams = queryString.parse(this.props.history.location.search);
        const listId = qParams.list_id;

        if (listId) {
            this.setState({selectedLeadId: id});
            const res = await getLeadListById(listId)
            await this.addLeadsToList(res.data.data)
            return
        }
        this.setState({popup: true, selectedLeadId: id});
    }

    closeAddToListPopup = () => {
        this.setState({popup: false, selectedLeadId: null});
    }

    loadSearchResultList = async () => {
        this.startLoader({showLoader: true});
        try {
            const res = await getSearchResults(this.props.marketBased, this.props.accountBased, this.props.searchResultFilters, this.getCurrentSearchQueryParams());
            this.props.clearMarketBasedChanged();
            this.props.clearAccountBasedChanged();
            this.props.clearSearchResultFiltersChanged();
            const updateCursors = {
                ...this.state.cursorsByPage,
                [this.state.currentPageNum + 1]: res.data.data.next_cursor,
            }

            const leads = res.data.data.leads;
            let selectedItems = this.state.selectedItems;

            // check all selected items
            this._checkSelectedItems(leads, selectedItems);

            // remove all elements which are not in the view
            selectedItems = this._removeNotInViewElements(leads, selectedItems);

            this.setState({
                items: leads,
                maxAddCount: res.data.data.max_add_count,
                totalItems: res.data.data.total,
                cursorsByPage: updateCursors,
                selectedItems: selectedItems,
                showHeaderButtons: selectedItems.length > 0
            });
        } catch (err) {
            let errormsg = 'some went wrong, may be invalid data in the file,please double check before submitting';
            if (err.response.data.errCode === 900) {
              errormsg = 'Invalid data in file, please make sure to send correct data'
            }

            let toastId = null;
            toastId = toast('Invalid data in the file', {
                autoClose: 9000,
            });
          
            toast.update(toastId, {
              render: errormsg,
              className: "toast-error-div",
              type: toast.TYPE.ERROR,
              hideProgressBar: true,
              autoClose: 9000,
            });
           // console.log(error)
            this.props.setErrorMessage(true);

            this.setState({searchResultError: true,});
        }
        this.stopLoader({showLoader: false});
    }

    _checkSelectedItems(leads, selectedItems) {
        leads && leads.forEach((item) => {
            let leadSelected = false;
            selectedItems.forEach((selectedItem) => {
                if (selectedItem.poolId === item.poolId) {
                    leadSelected = true;
                    return;
                }
            });

            if (this.state.check) {
                item.check = true;

                if (!leadSelected) {
                    selectedItems.push(item);
                }
            } else if (leadSelected) {
                item.check = true;
            }
        });
    }

    _removeNotInViewElements(leads, selectedItems) {
        const indexArray = [];

        selectedItems.forEach((selectedItem, index) => {
            let itemInView = false;

            leads.forEach((item) => {
                if (selectedItem.poolId === item.poolId) {
                    itemInView = true;
                    return;
                }
            });

            if (!itemInView) {
                indexArray.push(index);
            }
        });

        selectedItems = selectedItems.filter(function (value, index) {
            return indexArray.indexOf(index) === -1;
        });

        return selectedItems;
    }

    getCurrentSearchQueryParams = () => {
        return {
            cursor: this.state.cursorsByPage[this.state.currentPageNum], //this.state.nextCursorId,
            recordsPerPage: this.state.recordsPerPage,
        }
    }

    paginate = async (num) => {
        window.scrollTo(0, 0);
        this.setState({currentPageNum: num, check: false, selectedItems: [], showHeaderButtons: false, addMax: false});
        setTimeout(() => this.loadSearchResultList(), 20);
    }

    incOrDecNum = (sign, indexOfLastPage) => {
        let currentPage = this.state.currentPageNum
        if (sign === "-" && currentPage > 1) {
            currentPage = currentPage - 1
            this.paginate(currentPage)
        } else if (sign === "+" && currentPage < indexOfLastPage) {
            currentPage = currentPage + 1
            this.paginate(currentPage)
        }
    }

    updatePerPage = async (sign, num) => {
        let integer = parseInt(num);
        if (sign === "+" && integer < 100) {
            this.setState({recordsPerPage: integer + 25, currentPageNum: 1, cursorsByPage: {1: ''}});
            localStorage.setItem('recordsPerPage', integer + 25);
            setTimeout(() => this.loadSearchResultList(), 20);
        } else if (sign === "-" && integer > 25) {
            this.setState({recordsPerPage: integer - 25, currentPageNum: 1, cursorsByPage: {1: ''}});
            localStorage.setItem('recordsPerPage', integer - 25);
            setTimeout(() => this.loadSearchResultList(), 20);
        } else if (sign === '') {
            this.setState({
                showDropDown: !this.state.showDropDown,
                recordsPerPage: integer,
                currentPageNum: 1,
                cursorsByPage: {1: ''}
            });
            localStorage.setItem('recordsPerPage', integer);
            setTimeout(() => this.loadSearchResultList(), 20);
        }
    }

    _showToaster(message) {
        return toast(message, {hideProgressBar: true, autoClose: 10000})
    }

    _updateToaster(toastId, message) {
        toast.update(toastId, {
            render: message,
            className: "toast-success-div",
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
            autoClose: 2000,
        });
    }

    startLoader(loaderState) {
        this.props.startLoader(true);
        this.setState(loaderState);
    }

    stopLoader(loaderState) {
        this.props.stopLoader(false);
        this.setState(loaderState);
    }

    onShowCheckDropDown = () => {
        this.setState({showCheckDropDown: !this.state.showCheckDropDown});
    }

    selectCheckDropDownItem = (value) => {
        this.setState({addMax: value === "max" ? true : false});
        setTimeout(() => this.checkedAll(null, true), 20);
        this.setState({showCheckDropDown: false});
    }

    render() {

        return (
            <Fragment>

                <div className={`result-page__table ${this.props.expendSearchResult ? 'expended' : ''}`}>

                    {/* {this.props.expendSearchResult && this.state.selectedItems.length > 0 && <div className="selected-item-count">Leads Selected: {this.state.addMax ? this.state.maxAddCount : this.state.selectedItems.length}</div>} */}

                    <HeaderResult
                        loading={this.state.showLoader}
                        items={this.state.items}
                        skipLead={this.skipLead}
                        openAddLeadToListPopup={this.openAddLeadToListPopup}
                        closeAddToListPopup={this.closeAddToListPopup}
                        checkedAll={this.checkedAll}
                        showHeaderButtons={this.state.showHeaderButtons}
                        check={this.state.check}
                        expendSearchResult={this.props.expendSearchResult}
                        totalItems={this.state.totalItems}
                        maxAddCount={this.state.maxAddCount}
                        addMax={this.state.addMax}
                        showCheckDropDown={this.state.showCheckDropDown}
                        onShowCheckDropDown={this.onShowCheckDropDown}
                        selectCheckDropDownItem={this.selectCheckDropDownItem}
                        container={this.container}
                        selectedItems={this.state.selectedItems}
                        // topColor={this.state.stickyDarkColor ? '#dddddd' : ''}
                        // listColor={this.state.stickyDarkColor ? '#e5e5e5' : ''}
                    />

                    {this.props.expendSearchResult &&
                    <Fragment>
                        <ContentResult
                            items={this.state.items}
                            selectItem={this.selectItem}
                            skipLead={this.skipLead}
                            unSkipSingleLead={this.unSkipSingleLead}
                            popup={this.state.popup}
                            showLoader={this.state.showLoader}
                            addLeadsToList={this.addLeadsToList}
                            openAddLeadToListPopup={this.openAddLeadToListPopup}
                            closeAddToListPopup={this.closeAddToListPopup}
                            showSearchResultItemDetail={this.showSearchResultItemDetail}
                            leadDetail={this.state.leadDetail}
                            showLeadDetailLoader={this.state.showLeadDetailLoader}
                            errorDiv={this.props.user.errorDiv}
                            searchResultError={this.state.searchResultError}
                        />

                        {//  && this.state.totalItems > this.state.recordsPerPage
                            this.state.items && this.state.items.length > 0 &&
                            <Pagination
                                totalLists={this.state.totalItems}
                                recordsPerPage={this.state.recordsPerPage}
                                noPageNumbers={true}
                                restrictJumping={true}
                                currentPageNum={this.state.currentPageNum}
                                paginate={this.paginate}
                                incOrDecNum={this.incOrDecNum}
                                updatePerPage={this.updatePerPage}
                                paginationPopup={this.paginationPopup}
                                showDropDown={this.state.showDropDown}
                                container={this.container}/>
                        }
                    </Fragment>
                    }
                </div>

            </Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResult));
