import { IGNORE_LIST_PAGINATION, IGNORE_LIST_RECORDS_PER_PAGE } from "../constants/constant";

const INITIAL_STATE = {
    recordsPerPage: 25,
    pageNum: 1
}

export default function ignoreListFilter(state = INITIAL_STATE, action) {
    switch (action.type) {
        case IGNORE_LIST_RECORDS_PER_PAGE:
            return {
                ...state,
                recordsPerPage: action.payload,
                pageNum: 1
            }
        case IGNORE_LIST_PAGINATION:
            return {
                ...state,
                pageNum: action.payload
            }
        default:
            return {
                ...state
            }
    }
}