import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { closePopup, openPopup, startLoader, stopLoader } from '../../actions/loader';
import { bindActionCreators } from 'redux';
import { setErrorMessage } from '../../actions/error_msg';
import {
    BreadCrumb,
    InsideListFilter,
    InsideListHeader,
    Loader,
    Pagination,
    RightPanelBanner
} from '../../components'
import './style.scss';
import { getIntegration } from '../../actions/integration';
import {
    clearAllInsideFilters,
    setInsideListAddedByFuzebote,
    setInsideListCompanyEmail,
    setInsideListDoubleVerifiedEmail,
    setInsideListFromTo,
    setInsideListHasEmail,
    setInsideListHasLinkedIn,
    setInsideListHasMailingAddress,
    setInsideListHasPhone,
    setInsideListPersonalEmail,
    setInsideListSingleVerifiedEmail
} from '../../actions/inside_list_filters';
import { getHotLeads } from '../../services/leads';

import isEqual from 'lodash/isEqual';
import { INSIDE_LIST_FILTER_INITIAL_STATE } from '../../reducers/inside_list_filters';
import { LOCAL_STORAGE_KEYS } from '../../constants/constant';
import { EmitterService } from "../../services/emitterService";
import InsideListItem from '../../components/InsideList/InsideListContent/InsideListItem';
import { getHotLeadConfiguration } from '../../services/hotLeads';
import ConfigurationList from './Configurations/ConfigurationList';

const queryString = require('query-string');

class HotLeads extends Component {
    container = React.createRef();
    state = {
        leads: [],
        totalRecords: 0,
        totalLeads: 0,
        totalNewLeads: 0,
        allLeads: false,
        leadsInView: false,
        newLeads: false,
        selectedLeads: [],
        deSelectedLeads: [],
        recordsPerPage: 25,
        pageNum: 1,
        showDropDown: false,
        showExportCsvPopup: false,
        showExportGoogleSheetPopup: false,
        googleSheetIntegrated: false,
        integrations: [],
        selctedOneLead: undefined,
        sortOrder: '',
        sortingColumn: '',
        paginationMarginBottom: 0,
        woodpeckerCampaigns: [],
        lemlistCampaigns: [],
        replyCampaigns: [],
        highlevelCampaigns: [],
        jazzhrCampaigns: [],
        mailshakeCampaigns: [],
        sheetNames: [],
        connection_message: "",
        list: null,
        sheetLoading: false,
    };

    async componentWillMount() {
        EmitterService.emit("loadLeadsRemaining");
        const queryParams = queryString.parse(this.props.history.location.search);
        if (queryParams.hlcId) {
            this.getHotLeads();
        }
        this.getConfigurations()
    }

    getConfigurations = async () => {
        const { startLoader, stopLoader, setErrorMessage } = this.props;
        startLoader(true);
        try {
            const res = await getHotLeadConfiguration();
            this.setState({
                configs: res.data.data,
            });

        } catch (error) {
            setErrorMessage(true);
            this.setState({ leadsError: true });
        }
        this.props.closePopup();
        stopLoader(false);
    };

    getHotLeads = async () => {
        const { startLoader, stopLoader, setErrorMessage } = this.props;
        startLoader(true);
        const queryParams = queryString.parse(this.props.history.location.search);
        const hlcId = queryParams.hlcId
        try {
            const params = {
                fromDate: this.props.insideListFilters.from,
                toDate: this.props.insideListFilters.to
            };
            const queryParams = queryString.stringify(params);
            const res = await getHotLeads(
                hlcId,
                queryParams,
                this.state.recordsPerPage,
                this.state.pageNum,
                this.state.sortingColumn,
                this.state.sortOrder,
            );
            const response = res.data.data
            const leads = response?.leads?.map((item) => {
                return {
                    ...item,
                    person: {
                        leadId: item.user_id,
                        salary: item.income_range,
                        addedToList: true,
                        experience: item.work_history ?
                            JSON.parse(item.work_history)?.map((item) => ({ role: item.position, company: { name: item.company_name } }))
                            : '',
                        full_name: item.first_name + " " + item.last_name,
                        job: {
                            role: item.job_title,
                            company: {
                                website: item.company_domain,
                                name: item.company_name,
                                industry: {
                                    name: item.primary_industry
                                },
                                location: {
                                    name: item.company_address ? item.company_address + "," + item.company_city + ',' + item.company_state : '',
                                    street: item.company_address
                                },
                            }
                        },
                        emails: {
                            company: {
                                deliverable: item.business_email ? [item.business_email] : []
                            },
                            personal: {
                                deliverable: item.personal_emails ? [item.personal_emails] : [],
                                catchall: item.additional_personal_emails?.split(',')
                            }
                        },
                        location: {
                            name: item.personal_address ? item.personal_address + "," + item.personal_city + ',' + item.personal_state : ''
                        },
                        social_profiles: {
                            linkedin: item.linkedin_url,
                        },
                        phone: [{ number: item.company_phone }, { number: item.personal_phone }, { number: item.mobile_phone }],

                    },
                    creation_date: item.created_at,
                    phone: [{ number: item.company_phone }, { number: item.personal_phone }, { number: item.mobile_phone }],
                }
            });
            this.setState({
                leads:leads||[],
                totalRecords: response?.total_records
            });

        } catch (error) {
            setErrorMessage(true);
            this.setState({ leadsError: true });
        }
        this.props.closePopup();
        stopLoader(false);
    };

    componentDidMount() {
        let recordsPerPage = localStorage.getItem("recordsPerPage");
        if (recordsPerPage === null) {
            localStorage.setItem("recordsPerPage", "25");
            recordsPerPage = localStorage.getItem("recordsPerPage");
        }
        this.setState({ recordsPerPage: recordsPerPage });
    }

    updatePerPage = async (sign, num) => {
        let integer = parseInt(num);
        if (sign === "+" && integer < 100) {
            this.setState({ recordsPerPage: integer + 25, pageNum: 1 });
            localStorage.setItem("recordsPerPage", integer + 25);
            setTimeout(() => this.getHotLeads(), 20);
        } else if (sign === "-" && integer > 25) {
            this.setState({ recordsPerPage: integer - 25, pageNum: 1 });
            localStorage.setItem("recordsPerPage", integer - 25);
            setTimeout(() => this.getHotLeads(), 20);
        } else if (sign === "") {
            this.setState({
                showDropDown: !this.state.showDropDown,
                recordsPerPage: integer,
                pageNum: 1
            });
            if (integer - 25 < 25) {
                localStorage.setItem("recordsPerPage", 25);
            } else {
                localStorage.setItem("recordsPerPage", integer - 25);
            }

            setTimeout(() => this.getHotLeads(), 20);
        }
    };

    paginationPopup = async () => {
        this.setState({ showDropDown: !this.state.showDropDown });
    };

    paginate = async num => {
        window.scrollTo(0, 0);
        this.setState({ pageNum: num });
        setTimeout(() => this.getHotLeads(), 20);
    };
    incOrDecNum = (sign, indexOfLastPage) => {
        let currentPage = this.state.pageNum;
        if (sign === "-" && currentPage > 1) {
            currentPage = currentPage - 1;
            this.paginate(currentPage);
        } else if (sign === "+" && currentPage < indexOfLastPage) {
            currentPage = currentPage + 1;
            this.paginate(currentPage);
        }
    };

    isLeadSelected = (lead) => {
        const selectedItemIndex = this.state.leads.find(leadId => leadId === lead.id);
        if (selectedItemIndex) {
            return true;
        }
        return false
    }

    isMenuOpened = (leadId) => {
        return this.state.openMenuFor === leadId;
    }

    openMenu = (index) => {
        //this.setState({openMenuFor: leadId});
        this.props.setSelectedPopupItem(1, index);
    }

    showLeadDetail = (leadId) => {
        const { leads } = this.state;

        if (leadId) {
            this.props.openPopup();
        } else {
            this.props.closePopup();
        }

        leads.forEach((item) => {
            if (leadId && item.id === leadId) {
                if (!item.active) {
                    item.active = true;
                    return;
                }
            } else {
                item.active = false;
            }
        });

        this.setState({ leads: leads });
    }

    setSelectedPopupItem = (id, index) => {
        if (index == this.state.leads.length - 1) {
            this.setState({ paginationMarginBottom: 50 });
        } else {
            this.setState({ paginationMarginBottom: 0 });
        }
    }

    switchSort = async (name) => {
        let sortOrder = this.state.sortOrder;
        if (name === this.state.sortingColumn) {
            sortOrder = this.state.sortOrder === 'desc' ? 'asc' : 'desc';
            await this.setState({ sortOrder });
        } else {
            sortOrder = 'desc';
            await this.setState({ sortOrder, sortingColumn: name });
        }
        this.getHotLeads();
    }

    setInsideListFromTo = async (from, to) => {
        await this.props.setInsideListFromTo(from, to);
        this.getHotLeads();
    };

    clearAllFilters = async () => {
        await this.props.clearAllInsideFilters();
        this.getHotLeads();
    };

    showLeads = (hlcId) => {
        this.props.history.push({
            pathname: '/lead-search/hot-leads',
            search: `?hlcId=${hlcId}`
        });
        this.getHotLeads();
    }

    render() {
        const userId = JSON.parse(localStorage.getItem("user"));
        const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT));
        const queryParams = queryString.parse(this.props.history.location.search);

        const { loader } = this.props;
        const {
            totalLeads, pageNum, showDropDown, recordsPerPage
        } = this.state;
        let dontHaveList = null;
        if (!loader.loading && (!this.state.leads || this.state.leads.length == 0)) {
            dontHaveList = (
                <>
                    <div className="dont-list">
                        <h2 className="dont-list__title">
                            {!isEqual(this.props.insideListFilters, INSIDE_LIST_FILTER_INITIAL_STATE)
                                ? '0 leads found matching these filters'
                                : '0 hot leads found'}
                        </h2>
                        <p className="dont-list__descr">{!isEqual(this.props.insideListFilters, INSIDE_LIST_FILTER_INITIAL_STATE)
                            ? 'Please try changing the filters'
                            : ''}</p>
                    </div>
                </>
            );
        }
        let pagination = null;
        if (queryParams.hlcId && this.state.leads && this.state.leads.length) { //  && totalLeads > recordsPerPage
            pagination = (
                <Pagination
                    totalLists={this.state.totalRecords}
                    recordsPerPage={recordsPerPage}
                    currentPageNum={pageNum}
                    updatePerPage={this.updatePerPage}
                    paginationPopup={this.paginationPopup}
                    paginate={this.paginate}
                    incOrDecNum={this.incOrDecNum}
                    showDropDown={showDropDown}
                    paginationMarginBottom={this.state.paginationMarginBottom}
                />
            );
        }


        const links = queryParams.hlcId ?
            [
                {
                    name: 'Configuration',
                    slug: '/lead-search/hot-leads',
                },
                {
                    name: 'Hot Leads',
                    slug: null,
                },
            ]
            : [
                {
                    name: 'Configuration',
                    slug: null,
                },
            ];

        return (
            <div className="inside-page">
                <div className="inside-list-panel">

                    {userId === null && this.props.history.push("/signin?path=/lead-search/hot-leads")}

                    {userAccount.status === "trial_expired" && this.props.history.push('/billing-and-users')}

                    {this.props.user.errorDiv && this.state.leadsError && (
                        <div className="error-div alert-div">
                            <img
                                className="add-list__close"
                                src="/assest/apiKeys/Close_red.svg"
                                alt=""
                                onClick={() =>
                                    this.setState({ leadsError: !this.state.leadsError })
                                }
                            />
                            <p>Sorry, we were unable to perform the required action.</p>
                        </div>
                    )}

                    <div className="inside-list-panel__content">
                        <BreadCrumb
                            links={links}
                        >

                        </BreadCrumb>
                        {
                            queryParams.hlcId ? <div className="markbased__table">
                                <InsideListHeader
                                    hotlead
                                    allLeads={this.state.leads}
                                    totalLeads={totalLeads}
                                    leadsCountInView={this.state.leads.length}
                                    newLeads={this.state.newLeads}
                                    leadsInView={this.state.leadsInView}
                                    googleSheetIntegrated={this.state.googleSheetIntegrated}
                                    selectAllLeads={this.selectAllLeads}
                                    selectLeadsInView={this.selectLeadsInView}
                                    selectNewLeads={this.selectNewLeads}
                                    selectedLeads={this.state.selectedLeads}
                                    deSelectEverything={this.deSelectEverything}
                                    exportSelectedLeads={this.exportSelectedLeads}
                                    exportSelectedLeadsToGoogleSheet={
                                        this.exportSelectedLeadsToGoogleSheet
                                    }
                                    sortingColumn={this.state.sortingColumn}
                                    sortOrder={this.state.sortOrder}
                                    switchSort={this.switchSort}
                                    searchValue={this.state.searchValue}
                                    deleteBulkSelected={this.deleteBulkSelected}
                                    deleteSelectedLead={this.deleteSelectedLead}
                                    moveBulkSelectedToList={this.moveBulkSelectedToList}
                                    moveSelectedToList={this.moveSelectedToList}
                                    integrations={this.state.integrations}
                                    integrateSelectedLeadsWithIntegration={this.integrateSelectedLeadsWithIntegration}
                                />

                                <div className="inside-list-loader">
                                    {loader.loading && <Loader />}
                                </div>
                                {dontHaveList}

                                <Fragment>
                                    {this.state.leads && this.state.leads.map(
                                        (item, index) => {
                                            return <InsideListItem
                                                hotlead
                                                item={item} key={index} {...this.props} selected={this.isLeadSelected(item)}
                                                isMenuOpened={this.isMenuOpened(item.id)}
                                                openMenu={() => this.openMenu(index)}
                                                closeMenu={() => this.openMenu(null)}
                                                showLeadDetail={this.showLeadDetail} />;
                                        })
                                    }
                                </Fragment>
                            </div> :
                                <ConfigurationList
                                    config={this.state.configs?.[0] || {}}
                                    showLeads={this.showLeads}
                                    getConfigurations={this.getConfigurations}
                                    loader={loader} />
                        }


                        {this.state.leads && pagination}

                    </div>

                    {queryParams.hlcId && <div className="markbased__rightbar">
                        <div className="breadcrumb-space-holder"></div>
                        <RightPanelBanner title={"Inside Lead List Demo"} page="insideList" type="inside_people_list"
                            video_id="6ozmqht6xh" />
                        <InsideListFilter
                            hotlead
                            filters={this.props.insideListFilters}
                            toggleAddedByFuzebot={this.toggleAddedByFuzebot}
                            toggleHasEmail={this.toggleHasEmail}
                            togglePersonalEmail={this.togglePersonalEmail}
                            toggleCompanyEmail={this.toggleCompanyEmail}
                            toggleSingleVerifiedEmail={this.toggleSingleVerifiedEmail}
                            toggleDoubleVerifiedEmail={this.toggleDoubleVerifiedEmail}
                            toggleHasPhone={this.toggleHasPhone}
                            toggleHasLinkedIn={this.toggleHasLinkedIn}
                            toggleHasMailingAddress={this.toggleHasMailingAddress}
                            setInsideListFromTo={this.setInsideListFromTo}
                            clearAllFilters={this.clearAllFilters}
                        />
                    </div>}
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        startLoader,
        stopLoader,
        openPopup,
        closePopup,
        setInsideListAddedByFuzebote,
        setInsideListCompanyEmail,
        setInsideListHasEmail,
        setInsideListPersonalEmail,
        setInsideListSingleVerifiedEmail,
        setInsideListDoubleVerifiedEmail,
        setInsideListHasLinkedIn,
        setInsideListHasPhone,
        setInsideListHasMailingAddress,
        setInsideListFromTo,
        clearAllInsideFilters,
        getIntegration,
        setErrorMessage,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        loader: state.loader,
        leadFilter: state.leadFilter,
        user: state.user,
        leadList: state.leadList,
        integration: state.integration,
        insideListFilters: state.insideListFilters,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(HotLeads);
