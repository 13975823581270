import React, {Fragment} from 'react'
import LearnMore from '../LearnMore'
import IntegrationsToggle from '../ToggleIntegrations'

const IntegrationsItem = (props) => {
    const {
        turningOffIntegrationSwitch,
        filterFlag,
        check,
        openFreshSalesPopup,
        openDuxSoupPopup,
        getIntegrations,
        activeID,
        text,
        name,
        tags,
        url,
        apiLocation, 
        image, 
        featured, 
        active, 
        index, 
        id, 
        integrationsData, 
        openIntegrationPopup, 
        openIntegrationPopup2, 
        integrationLabel,
        removeActiveIntegration,
        addActiveIntegration
    } = props
    return (
        <Fragment>
            {check &&
            <div className={`integrations-col ${active ? 'active' : ''}`}>
                <div className="integrations-col__item">
                    <div className={`integrations-col__featured ${featured ? 'active' : ''}`}>Featured{featured}</div>
                    <div className="integrations-col__top">
                        <span className="integrations-col__img">
                            <img className="integration-img" src={`/assest/integrations/${image}`} alt=""/>
                        </span>

                        <p className="integrations-col__text">{text}</p>
                        <div className="integrations-col__tags">
                            {
                                tags.map((tag, index) =>
                                    <div className="integrations-col__tag" key={index}>{tag}</div>
                                )
                            }
                        </div>
                    </div>

                    <div className="integrations-col__bottom">
                        <LearnMore url={url}/>
                        <IntegrationsToggle
                            turningOffIntegrationSwitch={turningOffIntegrationSwitch} index={index}
                            activeID={activeID} active={active} integrationsData={integrationsData}
                            image={image}
                            getIntegrations={getIntegrations}
                            openIntegrationPopup={openIntegrationPopup}
                            openDuxSoupPopup={openDuxSoupPopup}
                            openIntegrationPopup2={openIntegrationPopup2}
                            openFreshSalesPopup={openFreshSalesPopup} name={name} id={id} apiLocation={apiLocation}
                            integrationLabel={integrationLabel}/>
                    </div>
                </div>
            </div>
            }
        </Fragment>

    )
}

export default IntegrationsItem
