import React, { Component } from 'react';
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroller';

class AddToListName extends Component {
  dropDownRef = null;

  state = {
    search: ''
  }

  constructor(props) {
    super(props)
    this.dropDownRef = React.createRef();   // Create a ref object 
  }

  formatDate(date) {
    return moment(date).format("MM-DD-YYYY");
  }

  selectItem(item) {
    this.props.selectList(item);
    this.dropDownRef.current.scrollTo(0, 0);
  }

  updateSearch = (e) => {
    this.setState({search: e.target.value});
  }

  render(){
    const lists = this.props.lists.filter(item => {
      return item.name.toLowerCase().includes(this.state.search.toLowerCase());
    })

    return(
      <div className={`add-list-name ${this.props.active ? 'active' : ''}`}>
        <div className="add-list-name__search">
          <img src="/assest/accountBased/search.svg" alt=""/>
          <input placeholder="Search List" type="text" onChange={this.updateSearch} />
        </div>

        <div className="add-list-name__row slim-scroll" ref={this.dropDownRef}>

          <InfiniteScroll
            loadMore={this.props.loadMore}
            hasMore={this.props.hasMore}
            loader={<div className="loader" key={0}>Loading ...</div>}
            useWindow={false}>
            
            {lists.length ? lists.map((item, index) =>
              <div className="add-list-name__item" key={index} onClick={() => this.selectItem(item)}>
                <p className="add-list-name__text">{item.name}</p>
                <p className="add-list-name__edit">{this.formatDate(item.created_at)} {`by ${item.owner_info.length ? (item.owner_info[0].first_name + ' ' + item.owner_info[0].last_name ) : ''}`}</p>
              </div>
            ) : 
            <div className="add-list-name__item" style={{justifyContent: 'center'}}>
              <p className="add-list-name__text loader">No matching results</p>
            </div>}

          </InfiniteScroll>

        </div>
      </div>
    )
  }
}

export default AddToListName