import moment from "moment";
import React, { Component, Fragment } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import InsideSelectMenu from './InsideSelectMenu';
import { LeadDetail } from '../../../../components';


class InsideListItem extends Component {

    state = {
        copy: false
    }

    getEmails = (emailObject) => {
        const emails = [];
        if (emailObject) {
            if (emailObject.personal) {
                if (emailObject.personal.deliverable && emailObject.personal.deliverable.length > 0) {
                    emails.push(...emailObject.personal.deliverable);
                }
                if (emailObject.personal.catchall && emailObject.personal.catchall.length > 0) {
                    emails.push(...emailObject.personal.catchall);
                }
            }

            if (emailObject.company) {
                if (emailObject.company.deliverable && emailObject.company.deliverable.length > 0) {
                    emails.push(...emailObject.company.deliverable);
                }
                if (emailObject.company.catchall && emailObject.company.catchall.length > 0) {
                    emails.push(...emailObject.company.catchall);
                }
            }
        }
        return emails.join(',');
    }

    getPhoneNumbers(phone) {
        const phoneNumbers = [];
        if (phone) {
            phone.forEach(item => {
                if (item.number) {
                    phoneNumbers.push(item.number);
                }
            });
        }
        return phoneNumbers.join(',');
    }

    onCopy = ($event) => {
        $event.stopPropagation();
        this.setState({ copy: true });

        setTimeout(() => {
            this.setState({ copy: false });
        }, 300);
    }


    formatDate(savedSearchDate) {
        return moment(savedSearchDate).format("MM-DD-YYYY");
    }

    differenceInTime(savedSearchDate) {
        const searchDate = moment(savedSearchDate);
        const diffInDays = moment().diff(searchDate, "days");
        if (diffInDays === 0) {
            const diffInHours = moment().diff(searchDate, "hours");
            if (diffInHours === 0) {
                const diffInMinutes = moment().diff(searchDate, "minutes");
                return `${diffInMinutes} minutes ago`;
            }
            return `${diffInHours} hours ago`;
        }
        return `${diffInDays} days ago`;
    }

    toggleLeadSelect = (item) => {
        if (this.props.selected) {
            this.props.deSelectLead(item.id);
        } else {
            this.props.selectLead(item.id);
        }
    }

    render() {
        const { item, showLeadDetail, hotlead } = this.props;

        const fuzebotIcon = (item.add_type) ? (
            <div className="result-item__skip" style={{ borderRadius: '50px' }} data-tip="Added By Fuzebot">
                <img src="/assest/accountBased/igni.svg" style={{ width: '7px' }} alt="" />
                <ReactTooltip />
            </div>) : <div style={{ width: '7px' }}></div>;

        item.mailing_address = item.person.job && item.person.job.company && item.person.job.company.location && item.person.job.company.location.street ? item.person.job.company.location.street : '';


        return (
            <Fragment>
                <div className={`result-item leadAdded ${item.active ? 'active' : ''} `}
                    onClick={() => showLeadDetail(item.id)}>
                    <img className="result-item__close" src="/assest/accountBased/close.svg" alt=""
                        onClick={($event) => {
                            $event.stopPropagation();
                            showLeadDetail()
                        }} />

                    <div className="result-item__row">

                        <div className="result-item__col result-item__check" onClick={(e) => {
                            e.stopPropagation();
                            this.toggleLeadSelect(item)
                        }
                        }>
                            {!hotlead && <div className={`check ${this.props.selected ? 'active' : ''}`}></div>
                            }
                        </div>

                        <div className="result-item__col">
                            <img src={item.person.profile_pic ? item.person.profile_pic : "/assest/accountBased/nothink.png"} className="profile-img" alt=""/>
                            <div className="result-item__data">
                                <p className="result-item__name">{item.person.full_name}</p>
                                {item.person.job &&
                                    <p className="result-item__education">{item.person.job.role}</p>
                                }
                            </div>
                        </div>


                        <div className="result-item__col">
                            {item.person.job && item.person.job.company &&
                                <Fragment>
                                    <p className="result-item__company"><a onClick={($event) => $event.stopPropagation()}
                                        href={`http://${item.person.job.company.website}`}
                                        target="_blank">{item.person.job.company.name}</a>
                                    </p>

                                    {item.person.job.company.industry &&
                                        <p className="result-item__position">{item.person.job.company.industry.name}</p>
                                    }
                                </Fragment>
                            }
                        </div>

                        <div className="result-item__col">
                            <div className={`result-item__social addedToList`}>

                                {this.getEmails(item.person.emails).length > 0 ?
                                    <CopyToClipboard text={this.getEmails(item.person.emails)}><a
                                        className={`copy ${this.state.copy ? 'copy_text' : null}`}
                                        onClick={(event) => this.onCopy(event)}><img
                                            src="/assest/accountBased/social-icons/email-icon.svg"
                                            alt="" /></a></CopyToClipboard> : ''}
                                {item.mailing_address ?
                                    <CopyToClipboard text={item.mailing_address}>
                                        <a className={`copy ${this.state.copy ? 'copy_text' : null}`} onClick={(event) => this.onCopy(event)}>
                                            <i className="fa fa-building-o active" aria-hidden="true" /></a>
                                    </CopyToClipboard> : ''}
                                {this.getPhoneNumbers(item.phone) ? <CopyToClipboard text={this.getPhoneNumbers(item.phone)}><a
                                    className={`copy ${this.state.copy ? 'copy_text' : null}`}
                                    onClick={(event) => this.onCopy(event)}><img src="/assest/phone-icon.svg"
                                        alt="" /></a></CopyToClipboard> : ''}
                                {item.person && item.person.social_profiles && item.person.social_profiles.linkedin ?
                                    <a href={'http://' + item.person.social_profiles.linkedin} target="_blank"><img
                                        src="/assest/accountBased/social-icons/ln-icon.svg" alt="" /></a> : ''}
                                {item.person && item.person.social_profiles && item.person.social_profiles.facebook ?
                                    <a href={'http://' + item.person.social_profiles.facebook} target="_blank"><img
                                        src="/assest/accountBased/social-icons/fb-icon.svg" alt="" /></a> : ''}
                                {item.person && item.person.social_profiles && item.person.social_profiles.twitter ?
                                    <a href={'http://' + item.person.social_profiles.twitter} target="_blank"><img
                                        src="/assest/accountBased/social-icons/tw-icon.svg" alt="" /></a> : ''}
                            </div>

                            <p className="result-item__location">
                                <img src="/assest/pin.svg" alt="" />
                                {item.person && item.person.location && item.person.location.name ? item.person.location.name : ''}
                            </p>
                        </div>

                        <div className="result-item__col">
                            <div className="result-item__data">
                                <p className="result-item__name fw-normal">
                                    {this.formatDate(item.creation_date)}
                                </p>
                                <p className="result-item__education">
                                    {this.differenceInTime(item.creation_date)}
                                </p>
                            </div>
                        </div>

                        <div className="result-item__col-skip">
                            {fuzebotIcon}

                            <div onClick={(e) => {
                                e.stopPropagation();
                                //this.props.openMenu(item.id);
                            }}>

                                {/* <ul className='lead-cont-item__dots' style={{marginLeft: 0}}> <li></li><li></li><li></li></ul> */}
                                {
                                    !hotlead && <InsideSelectMenu
                                        insideSelectDropdown={this.props.isMenuOpened}
                                        leadId={item.id}
                                        showMoveToList={this.props.showMoveToList}
                                        searchValue={this.props.searchValue}
                                        showSendToList={this.props.showSendToList}
                                        moveSelectedToList={this.props.moveSelectedToList}
                                        deleteSelectedLead={this.props.deleteSelectedLead}
                                        integrations={this.props.integrations}
                                        openMenu={this.props.openMenu}
                                        closeMenu={this.props.closeMenu}
                                        integrateOneLeadWithIntegration={(integName) => this.props.integrateOneLeadWithIntegration(this.props.item.id, integName)} />

                                }
                            </div>
                        </div>

                    </div>

                    {item.active &&
                        <LeadDetail
                            leadDetail={item.person}
                            showTabs={true} />
                    }
                </div>

                <div className={`result-item__hide ${item.active ? 'active' : ''}`} onClick={() => showLeadDetail()}></div>
            </Fragment>
        )
    }
}

export default InsideListItem;