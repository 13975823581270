import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from "moment";
import './style.scss';
import {getActivities} from '../../../../services/searchResult';
import {Loader} from '../../../../components';

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
    return {
        loader: state.loader
    };
}

class Activity extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showLoader: false,
            activities: [],
            from: 0,
            to: 0
        };
    }

    componentDidMount() {
        this.loadActivities();
    }

    loadActivities = async () => {
        this.startLoader();
        const res = await getActivities(this.props.leadDetail.leadId);
        const activities = [];

        res.data.data.forEach((item) => {
            activities.push(this.formatActivityObject(item));
        });

        this.setState({activities: activities, to: (activities.length < 3 ? activities.length : 2)});
        this.stopLoader();
    }

    nextActivity = () => {
        const activities = this.state.activities;

        if (this.state.to === activities.length - 1) {
            return;
        }

        let from = this.state.to + 1;
        let to = this.state.to + 3;

        if (to >= activities.length) {
            to = activities.length - 1;
        }

        this.setState({from: from, to: to});
    }

    previousActivity = () => {
        if (this.state.from === 0) {
            return;
        }

        let from = this.state.from - 3;
        if (from < 0) {
            from = 0;
        }

        this.setState({from: from, to: from + 2});
    }

    formatDate(date) {
        return moment(date).format("MMMM D, YYYY");
    }

    formatActivityObject(activity) {
        const formattedActivity = {
            date: this.formatDate(activity.date),
            title: null,
            description: null
        };


        if (activity.event_added) {
            formattedActivity.title = "Lead Added";
            formattedActivity.description = 'Added to List ' + '<a target="_blank" href="/lead-list/inside-list?list_id=' + activity.event_added.list_id + '&amp;list_name=' + activity.event_added.list_name + '"><span>' + activity.event_added.list_name + '</span></a>' + ' by ' + activity.event_added.added_by;

        } else if (activity.event_integrated) {
            formattedActivity.title = "Lead Integrated";
            formattedActivity.description = activity.event_integrated.description;

        } else if (activity.event_exported) {
            formattedActivity.title = "Lead Exported";

            let fileType = "Google Sheet";
            formattedActivity.description = 'Exported to ' + fileType
                + ': <a target="_blank" href="https://docs.google.com/spreadsheets/d/' + activity.event_exported.sheet_id + '"><span>' + activity.event_exported.sheet_name + '</span></a>'
            if (activity.event_exported.is_csv) {
                fileType = "CSV";
                formattedActivity.description = 'Exported to ' + fileType
                    + ': <span>' + activity.event_exported.sheet_name + '</span>'
            }


        } else if (activity.event_skipped) {
            formattedActivity.title = "Lead Skipped";
            if (activity.event_skipped.reason == "filtered_out") {
                formattedActivity.description = "Lead was skipped due to not matching the " + this.capitalize(activity.event_skipped.integration) + " filter criteria in LeadFuze."
            } else if (activity.event_skipped.reason == "missing_required_field") {
                formattedActivity.description = "Lead was missing a property that was required by " + this.capitalize(activity.event_skipped.integration) + "."
            } else if (activity.event_skipped.reason == "already_added") {
                formattedActivity.description = "Lead had already been sent to " + this.capitalize(activity.event_skipped.integration) + " before."
            } else if (activity.event_skipped.reason == "error") {
                formattedActivity.description = "An error occurred while sending this lead to " + this.capitalize(activity.event_skipped.integration) + "."
            } else {
                formattedActivity.description = activity.event_skipped.reason;
            }

        } else if (activity.event_moved) {
            formattedActivity.title = "Lead Moved";
            formattedActivity.description = "Lead moved from " + '<a target="_blank" href="/lead-list/inside-list?list_id=' + activity.event_moved.from_list_id + '&amp;list_name=' + activity.event_moved.from_list_name + '"><span>' + activity.event_moved.from_list_name + '</span></a>' + " list to "
                + '<a target="_blank" href="/lead-list/inside-list?list_id=' + activity.event_moved.to_list_id + '&amp;list_name=' + activity.event_moved.to_list_name + '"><span>' + activity.event_moved.to_list_name + '</span></a>' + " list";
        }

        return formattedActivity;
    }

    capitalize = function (s) {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    startLoader() {
        this.setState({showLoader: true});
    }

    stopLoader() {
        this.setState({showLoader: false});
    }

    render() {
        return (
            <Fragment>
                <div className="activity">

                    {this.state.showLoader && <Loader/>}

                    <div className="activity__col lead">
                        <h5 className="activity__title">Lead Activity</h5>

                        <div className="activity__row">

                            {this.state.activities.map((item, index) =>
                                <Fragment key={index}>
                                    {(index >= this.state.from && index <= this.state.to) &&
                                    <div className="activity-lead">
                                        <div className="activity-lead__item">
                                            <p className="activity-lead__date"><img src="/assest/accountBased/clock.svg"
                                                                                    alt=""/> {item.date}</p>
                                            <p className="activity-lead__title">{item.title}</p>
                                            {/* <p className="activity-lead__descr">{item.description}</p> */}
                                            <p className="activity-lead__descr"
                                               dangerouslySetInnerHTML={{__html: item.description}}></p>
                                        </div>
                                    </div>
                                    }
                                </Fragment>
                            )}

                            {this.state.activities.length > 3 &&
                            <div className="activity-lead__arrows">
                                <img
                                    className={`activity-lead__arr left ${this.state.from === 0 ? 'cursor-notAllowed' : ''}`}
                                    onClick={this.previousActivity}
                                    src={this.state.from === 0 ? '/assest/accountBased/social-icons/Icon_left_grey.svg' : '/assest/accountBased/social-icons/Icon_left.svg'}
                                    alt=""/>
                                <img
                                    className={`activity-lead__arr right ${(this.state.to === this.state.activities.length - 1) ? 'cursor-notAllowed' : ''}`}
                                    onClick={this.nextActivity}
                                    src={(this.state.to === this.state.activities.length - 1) ? '/assest/accountBased/social-icons/Icon_right_grey.svg' : '/assest/accountBased/social-icons/Icon_right.svg'}
                                    alt=""/>
                            </div>
                            }
                        </div>
                    </div>


                    {/* <div className="activity__col twitter">
            <h5 className="activity__title">Twitter Activity</h5>
            <div className="activity-twitter">
              {
                twitter.map((item, index)=>
                  <div className="activity-twitter__item" key={index}>
                    <div className="activity-twitter__top">
                      <p className="activity-lead__title">{item.name}</p>
                      <p className="activity-lead__date">{item.tag}</p>
                      <p className="activity-lead__date">{item.ago}</p>
                    </div>
                    <p className="activity-lead__descr">{item.text}</p>
                  </div>
                )
              }
            </div>
            <div className="activity__btn">Show more</div>
          </div> */}

                </div>
            </Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activity);