import React, { Component } from "react";
import SavedSearchesFilters from "./SavedSearchFilters";
import SavedSearchTechnologies from "./SavedSearchTechnologies";
import './style.scss'
import SavedSearchKeywords from "./SavedSearchKeywords";

class SavedSearchesItemExpanded extends Component {

  render() {

    return (
      <div className="saved-search-expanded">
        {this.props.showCriteriaFields && 
        <div className="saved-search-expanded__row">
          <div className="saved-search-expanded__col">
            <p>Search Criteria</p>
            <SavedSearchKeywords query={this.props.savedSearchQuery} />
          </div>

          {/* <div className="saved-search-expanded__col">
            <p>Technologies Used</p>
            <SavedSearchTechnologies query={this.props.savedSearchQuery} />
          </div> */}

          <div className="saved-search-expanded__col">
            <p>Filters</p>
            <SavedSearchesFilters query={this.props.savedSearchQuery} />
          </div>

        </div>
        }
        {this.props.showEditTraits &&
          <div className="filter-res__buttons">
            <a className="edit-traits-btn" onClick={this.props.editSearchTraits}>Edit Traits</a>
          </div>
        }
        {
          this.props.renderAdditionalContent && this.props.renderAdditionalContent()
        }
      </div>
    );
  }
}
export default SavedSearchesItemExpanded;
