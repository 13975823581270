import React from "react";

const CategoryLine = (props) => {
  const { tags, category, label, addGroupSelect, setTags, excludeTags, haveTags, isTechName, isTech, technologies } = props;

  const haveTagsAndCount = haveTags ? <p className="dropdown-in-group__tag">This group include {tags.length + excludeTags.length} tags</p> : "";

  let technologyOptions = null;
  if (technologies) {
    technologyOptions = (
      <div className={`tags-group tech-tags`}>
        {technologies.map((technology, index) => (
          <div
            className={`tags-group__name tech-base ${technology.toLowerCase()}`}
            key={"tech-" + technology + index}
            onClick={(e) => {
              addGroupSelect(technology, true, label, "", technology);
              e.stopPropagation();
            }}
          >
            {technology}
          </div>
        ))}
      </div>
    );
  }

  let allTags = "";
  if (haveTags) {
    allTags = (
      <div className={`tags-group tech-tags`}>
        {tags.map((element) => (
          <div
            className={`tags-group__name`}
            key={"tag-" + element}
            onClick={(e) => {
              addGroupSelect(category, isTechName, label, "", element);
              e.stopPropagation();
            }}
          >
            {element}
          </div>
        ))}
        {excludeTags.map((element, i) => (
          <div
            className="field__exclude_tag exclude_tag"
            key={"excl-" + element + i}
            onClick={(e) => {
              addGroupSelect(category, isTechName, label, "exclude", element);
              e.stopPropagation();
            }}
          >
            {element}
          </div>
        ))}
      </div>
    );
  }

  return (
    <React.Fragment>
      <div
        className={`dropdown-in-group ${isTech ? "default-cursor" : ""} ${props.selectedRowIndex && props.selectedRowIndex === props.rowIndex ? "selected" : ""}`}
        onClick={() => addGroupSelect(category, isTechName, label)}
        onMouseOver={() => setTags(allTags, technologyOptions, props.rowIndex)}
      >
        <p className={`dropdown-in-group__name  ${isTechName ? "tech-base " + label.toLowerCase() : ""}`}>{label}</p>
        {haveTagsAndCount}
      </div>
    </React.Fragment>
  );
};

export default CategoryLine;
