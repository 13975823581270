const actions = new Map([
  [
    "add_lead", ['Add a Lead to a List', 'Run a search for leads and add at least one to a list']
  ],
  [
    "update_ignore_list", ['Update Ignore List', 'Avoid adding current customers or competitors by updating your Ignore List']
  ],
  [
    "export_list", ['Export a List', 'Take your LeadFuze data with you in CSV or Google Sheets format']
  ],
  [
    "connect_integration", ['Connect an Integration', 'Automate sales process workflows by sending data to other tools you use']
  ],
  [
    "invite_team_member", ['Invite Team Member(s)', 'Each account can have up to 20 team members that will share your lead credits']
  ]
])

export default actions