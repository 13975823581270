import React, {Component} from "react";
import "../style.scss";
import InsideListItem from "./InsideListItem";
import AddToList from "../../SearchResultBox/ContentResult/AddToList";

class InsideListContent extends Component {

    state = {
        openMenuFor: undefined,
        selectedLeadId: undefined,
        movePopup: false,
        sendPopup: false,
        selectedList: null,
    }

    componentDidMount() {
        document.body.addEventListener('click', this.closeItemDropdown);
    }

    closeItemDropdown = () => {
        this.setState({openMenuFor: undefined});
        this.props.setSelectedPopupItem(null, null);
    }

    componentWillUnmount = () => {
        document.body.removeEventListener('click', this.closeItemDropdown);
    }


    isLeadSelected = (lead) => {
        const selectedItemIndex = this.props.selectedLeads.find(leadId => leadId === lead.id);
        if (selectedItemIndex) {
            return true;
        } else if (this.props.allLeadsSelected) {
            const deselectedItem = this.props.deSelectedLeads.find(leadId => leadId === lead.id);
            if (!deselectedItem) {
                return true;
            }
        }
        // else if (this.props.newLeadsSelected) {
        //   const deselectedItem = this.props.deSelectedLeads.find(ld => ld.id === lead.id);
        //   if (!deselectedItem && !lead.seen) {
        //     return true;
        //   }
        // }
        return false
    }

    openMenu = (index) => {
        //this.setState({openMenuFor: leadId});
        this.props.setSelectedPopupItem(1, index);
    }

    showMoveToList = (leadId) => {
        this.setState({movePopup: true, sendPopup: false, selectedLeadId: leadId, openMenuFor: undefined});
    }

    showSendToList = (leadId) => {
        this.setState({movePopup: false, sendPopup: true, selectedLeadId: leadId, openMenuFor: undefined});
    }

    closeAddToListPopup = () => {
        this.setState({movePopup: false, sendPopup: false});
    }

    addLeadsToList = async (list) => {
        this.closeAddToListPopup();
        await this.props.moveSelectedToList(this.state.selectedLeadId, list._id);
    }

    isMenuOpened = (leadId) => {
        return this.state.openMenuFor === leadId;
    }

    selectList = (list) => {
        this.setState({selectedList: list});
    }

    render() {

        return <div>

            {this.props.leads.map(
                (item, index) => {
                    return <InsideListItem item={item} key={index} {...this.props} selected={this.isLeadSelected(item)}
                                           isMenuOpened={this.isMenuOpened(item.id)}
                                           moveSelectedToList={this.props.moveSelectedToList}
                                           deleteSelectedLead={this.props.deleteSelectedLead}
                                           showMoveToList={this.showMoveToList}
                                           searchValue={this.props.searchValue}
                                           showSendToList={this.showSendToList}
                                           integrations={this.props.integrations}
                                           integrateOneLeadWithIntegration={this.props.integrateOneLeadWithIntegration}
                                           openMenu={() => this.openMenu(index)}
                                           closeMenu={() => this.openMenu(null)}
                                           showLeadDetail={this.props.showLeadDetail}/>;
                })
            }
            {(this.state.movePopup || this.state.sendPopup) &&
            <AddToList heading={this.state.movePopup ? 'Move to List' : 'Send to List'}
                       closePopup={this.closeAddToListPopup} addLeadsToList={this.addLeadsToList}
                       selectedList={this.state.selectedList} selectList={this.selectList}
                       popup={this.state.movePopup || this.state.sendPopup}/>
            }
        </div>
        /* items.map((item, index)=>
        <div className="inside-list-list coupon-header" key={index}>
          <div className="inside-list-list__row">
            <div className="inside-list-header__check">
              <div className={`check`} onClick={()=>this.changeCheck()}></div>
            </div>
            <div className="inside-list-list__items">
              <div className="inside-list-header__col person">
                <img src={`/assest/accountBased/${item.photo}`} alt=""/>
                <div className="inside-list-list__data">
                  <p className="inside-list-list__name">{item.firstName} {item.lastName}</p>
                  <p className="inside-list-list__education">{item.education}</p>
                </div>
              </div>
              <div className="inside-list-header__col comp">
                <p className="inside-list-list__company">{item.company}</p>
                <p className="inside-list-list__position">{item.position}</p>
              </div>
              <div className="inside-list-header__col info">
                <div className="inside-list-list__social">
                  {item.phone ? <Link to={item.phone} ><img src="/assest/phone-icon.svg" alt=""/></Link> : ''}
                  {item.mail ? <Link to={item.mail} ><img src="/assest/accountBased/social-icons/email-icon.svg" alt=""/></Link> : ''}
                  {item.icon ? <Link to={item.icon} ><img src="/assest/icon.svg" alt=""/></Link> : ''}
                  {item.linkedIn ? <Link to={item.linkedIn} ><img src="/assest/accountBased/social-icons/ln-icon.svg" alt=""/></Link> : ''}
                  {item.facebook ? <Link to={item.facebook} ><img src="/assest/accountBased/social-icons/fb-icon.svg" alt=""/></Link> : ''}
                  {item.twitter ? <Link to={item.twitter} ><img src="/assest/accountBased/social-icons/tw-icon.svg" alt=""/></Link> : ''}
                </div>
                <p className="inside-list-list__location">
                  <img src="/assest/pin.svg" alt=""/>
                  {item.address}
                </p>
              </div>
              <div className="inside-list-header__col added date">
                <div className="date__box">
                  <p className="inside-list-list__added">{item.added}</p>
                  <p className="inside-list-list__ago">{item.ago} days ago</p>
                </div>
               <div className="date__img"><img src="/assest/accountBased/igni.svg" alt=""/></div>
              </div>
            </div>
            <ul className="inside-list-list__nav">
              <li></li>
              <li></li>
              <li></li>
              {index === 1 ? <ItemDropdown /> : ''}
            </ul>
          </div>
        </div> */
    }
}

export default InsideListContent;
