import React, { Component, Fragment } from 'react';
import UsersHeader from './UsersHeader';
import UsersContent from './UsersContent';
import '../style.scss';
import { Loader } from '../../../components';
import { numberFormatter } from "../../../constants/helper";

class UsersTable extends Component {

  render() {

    return (
      <section className="users-tab">
        
        <div className="users-tab__title">
          <h3>Users</h3>
          {!this.props.userListingLoading && <span className="item-count">{numberFormatter.format(this.props.totalUsers)} results found</span>}
        </div>

        <div className="users-tab-search">
          <div className="ignore-search__inp">
            <img src="/assest/accountBased/search.svg" alt="" />
            <input type="text" placeholder="Search Users" onChange={this.props.onKeywordChange} onKeyPress={this.props.onEnterPressed} />

            <p>Press <strong> Enter </strong> to search</p>
          </div>
        </div>

        <div className="users-tab-table">
          <UsersHeader/>

          {this.props.userListingLoading && <Loader />}

          <UsersContent 
            items={this.props.items}
            toggleUserDetail={this.props.toggleUserDetail}
            toggleMenuDropDown={this.props.toggleMenuDropDown}
            loginAsUser={this.props.loginAsUser}
            showConfirmationPopup={this.props.showConfirmationPopup}
            enableUserEditMode={this.props.enableUserEditMode}
            updateUserData={this.props.updateUserData} />
            
        </div>
      </section>
    )
  }
}

export default UsersTable;
