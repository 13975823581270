import React, { Component, Fragment } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/lib/styles.scss";
import {
  formatExpirationDate,
  formatCreditCardNumber,
  formatCVC
} from "../../../../../constants/helper";
import { ViewMode } from "..";
import { toast } from "react-toastify";

class AddNewCard extends Component {
  state = {
    cardNumber: '',
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    focused: '',
    issuer: '',
    submitted: false,
  };

  componentDidMount() {
    const {viewMode, currentCard} = this.props;
    if (viewMode === ViewMode.EditCard) { 
      this.setState({issuer: currentCard.brand, expiry: `${('0' + currentCard.exp_month).slice(-2)}/${currentCard.exp_year}`,
      
    })
    }
  }

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
    this.setState({isValid, issuer});
  };

  addNewCard = () => {
    const {cvc, name, cardNumber, expiry, issuer, isValid} = this.state;
    const expiryArr = expiry.split('/');
    if (this.isNewCardFormValid()) {
      this.props.onNewCardSave(cardNumber, cvc, issuer, expiryArr[0],  expiryArr[1]);
    } 
    this.setState({submitted: true});
    // else {
    //   toast.error('Please enter valid card number, ccv and expiry', {
    //     autoClose: 2000,
    //   })
    // }
  }

  isNewCardFormValid = () => {
    const {cvc, expiry, isValid} = this.state;
    return isValid && expiry.length === 7 && cvc;
  }

  updateCard = () => {
    const {expiry} = this.state;
    const expiryArr = expiry.split('/');
    if (this.isUpdateCardFormValid()) {
      this.props.onCardUpdate(this.props.currentCard.id, expiryArr[0],  expiryArr[1]);
    }
    this.setState({submitted: true});
    // else {
    //   toast.error('Please enter valid card number, ccv and expiry', {
    //     autoClose: 2000,
    //   })
    // }
  }

  isUpdateCardFormValid = () => {
    const {expiry} = this.state;
    return expiry.length === 7
  }

  handleInputFocus = e => {
    this.setState({ focus: e.target.name });
  };

  handleCardNumChange = e => {
    const formattedCreditCardNum = formatCreditCardNumber(e.target.value);
    this.setState({ cardNumber: formattedCreditCardNum });
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  handleExpiryChange = e => {
    const formattedExpiry = formatExpirationDate(e.target.value);
    this.setState({ expiry: formattedExpiry });
  };

  handleCvcChange = e => {
    const formattedCvc = formatCVC(e.target.value);
    this.setState({ cvc: formattedCvc });
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name
    });
  };

  render() {
    const { cardNumber, expiry, cvc, name, focused } = this.state;
    return (
      <Fragment>
        {this.props.viewMode === ViewMode.NewCard ? 
        <Cards
        className="billing-cards__credit-card"
        cvc={cvc}
        expiry={expiry}
        focused={focused}
        name={name}
        number={cardNumber}
        callback={this.handleCallback}
        ></Cards>
        :
        <Cards
        className="billing-cards__credit-card"
        expiry={expiry}
        preview
        issuer={this.props.currentCard.brand}
        number={`**** **** **** ${this.props.currentCard.last4}`}
        callback={this.handleCallback}
        ></Cards>
      }
        {/* <div className="billing-cards__field-div">
          <p className="profile__label">Name On Card</p>
          <input
            disabled={this.props.viewMode === ViewMode.EditCard}
            name="name"
            type="text"
            onFocus={this.handleInputFocus}
            value={name}
            onChange={this.handleNameChange}
            placeholder="FirstName LastName"
          />
        </div> */}
        
        {this.props.viewMode === ViewMode.NewCard && 
        <div className="billing-cards__field-div">
          <p className="profile__label">Card Number</p>
          <input
            disabled={this.props.viewMode === ViewMode.EditCard}
            type="text"
            name="number"
            onFocus={this.handleInputFocus}
            value={cardNumber}
            onChange={this.handleCardNumChange}
            placeholder="0000 0000 0000 0000"
          />
        </div>
        }
        <div className="billing-cards__field-div">
          <p className="profile__label">Expiry</p>
          <input
            name="expiry"
            type="text"
            onFocus={this.handleInputFocus}
            value={expiry}
            onChange={this.handleExpiryChange}
            placeholder="MM / YYYY"
          />
        </div>
        
        {this.props.viewMode === ViewMode.NewCard &&
        <div className="billing-cards__field-div">
          <p className="profile__label">CVC/CCV Code</p>
          <input
            disabled={this.props.viewMode === ViewMode.EditCard}
            name="cvc"
            type="text"
            onFocus={this.handleInputFocus}
            value={cvc}
            onChange={this.handleCvcChange}
            placeholder="000"
          />
        </div>
        }
        {this.props.viewMode === ViewMode.NewCard && !this.isNewCardFormValid() && this.state.submitted && <p className="error">Please enter valid card info</p>}
        {this.props.viewMode === ViewMode.EditCard && !this.isUpdateCardFormValid() && this.state.submitted && <p className="error">Please enter valid card expiry</p>}
        <div className="billing-cards__actions" >
        {this.props.viewMode === ViewMode.NewCard && <p className={`billing-cards__add-card `} onClick={() => this.addNewCard()}>Add New Card</p>}
        {this.props.viewMode === ViewMode.EditCard && <p className={`billing-cards__add-card  `} onClick={() => this.updateCard()}>Update Card</p>}
          <p className="billing-cards__edit-card"  onClick={() => this.props.onCancel()}>Cancel</p>
        </div>
      </Fragment>
    );
  }
}

export default AddNewCard;
