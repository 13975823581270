import React, { Component } from 'react'

class Columns extends Component {



    render() {
        const { columns, changeCheckForColumn } = this.props
        return (
            <div style={{   
                maxHeight: '170px',
                overflow: 'auto',
               }}>
                {columns.map((item, index) =>
                    <div className="right-filters__check check-margin" key={'col' + index}>
                        <div className={`check ${item.check ? 'active' : ''}`}
                            onClick={() => changeCheckForColumn(index, item.value)}><span></span>
                        </div>
                        <p>{item.name}</p>
                    </div>
                )}
            </div>
        )
    }
}

export default Columns;

