import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { startLoader, stopLoader } from '../../../actions/loader'
import {
    createFreshsalesIntegration,
    freshSalesGetUsers,
    updateFreshsalesIntegration
} from '../../../services/integrations'
import { toast } from 'react-toastify';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip'
import Loader from '../../Loader'
import InfiniteScroll from "react-infinite-scroller";
import { LOCAL_STORAGE_KEYS } from '../../../constants/constant'
import { showSuccessToast, showErrorToast } from '../../../helpers/toast';

class FreshSalesPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            syncLeads: false,
            requireEmail: false,
            personalEmail: false,
            companyEmails: false,
            doubleVerified: false,
            apiKey: '',
            crmDomain: '',
            user: '',
            originalFreshsalesUsers: [],
            freshSaleusers: [],
            email: '',
            getUsersError: false,
            showUserDropdown: true,
            userName: "",
            formErrors: []
        }
    }

    componentDidMount = () => {
        const { activeIntegrations, nameForPopup } = this.props
        activeIntegrations.map(items => {
            if (items.name === nameForPopup) {
                this.setState({
                    syncLeads: items.auto_sync,
                    requireEmail: items.require_email,
                    personalEmail: items.include_personal_emails,
                    companyEmails: items.include_company_emails,
                    doubleVerified: items.include_double_verified_emails,
                    crmDomain: items[items.name].domain,
                    apiKey: items[items.name].api_key,
                    user: items[items.name].user_id
                })
                this.getFreshSalesUsers(items[items.name].api_key, items[items.name].domain, items[items.name].user_id)
            }
        })
    }

    openListName = async () => {
        this.setState({ active: !this.state.active });
    }
    changeSwitch = (i) => {
        if (i === 2) {
            this.setState({ requireEmail: !this.state.requireEmail })
        } else {
            this.setState({ syncLeads: !this.state.syncLeads })
        }
    }
    changeCheck = (i) => {
        if (i === 1) {
            this.setState({ companyEmails: !this.state.companyEmails })
        } else if (i === 2) {
            this.setState({ personalEmail: !this.state.personalEmail })
        } else {
            this.setState({ doubleVerified: !this.state.doubleVerified })
        }
    }
    handleChange = (e) => {
        this.setState({ apiKey: e.target.value })
    }
    leadOwnerChange = (id) => {
        var username = this.getUserByID(id)
        this.setState({ user: id, showUserDropdown: false, userName: username })
    }
    crmDomainChange = (e) => {
        this.setState({ crmDomain: e.target.value })
    }

    getFreshSalesID = (id, userEmail) => {
        this.setState({ user: id, email: userEmail, active: !this.state.active })
    }

    isNullOrEmpty(field) {
        return !field || field.trim().length === 0;
    }

    validateForm = () => {
        const { crmDomain, apiKey, userName } = this.state;
        const formErrors = {};

        if (this.isNullOrEmpty(crmDomain)) {
            formErrors["crmDomain"] = "This field is required";
        }

        if (this.isNullOrEmpty(apiKey)) {
            formErrors["apiKey"] = "This field is required";
        }

        if (this.isNullOrEmpty(userName)) {
            formErrors["userName"] = "This field is required";
        }

        this.setState({ formErrors: formErrors });
        return Object.keys(formErrors).length === 0;
    }

    onConfirmClick = async () => {
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        const { apiKey, crmDomain, user, syncLeads, requireEmail, personalEmail, companyEmails, doubleVerified } = this.state
        const { getIntegrations, nameForPopup, openFreshSalesPopup } = this.props
        const user_id = parseInt(user);

        if (this.validateForm()) {
            let toastId = null;
            toastId = toast('Turning on integration', {
                autoClose: 15000,
            });
            try {
                await createFreshsalesIntegration(userId.id, crmDomain, apiKey, user_id, nameForPopup, syncLeads, requireEmail, companyEmails, personalEmail, doubleVerified, userId.account_id)
                showSuccessToast(toastId, "Integration turned on successfully");
                openFreshSalesPopup("", "true")
            } catch (error) {
                showErrorToast(toastId, "Unable to turn on integration");
            }
        }
    }

    onUpdateClick = async () => {
        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        const { apiKey, crmDomain, user, syncLeads, requireEmail, personalEmail, companyEmails, doubleVerified } = this.state
        const { getIntegrations, nameForPopup, openFreshSalesPopup, integID } = this.props

        if (this.validateForm()) {
            let toastId = null;
            toastId = toast('Updating integration...', {
                autoClose: 15000,
            });
            try {
                await updateFreshsalesIntegration(userId.id, crmDomain, apiKey, integID, user, nameForPopup, syncLeads, requireEmail, companyEmails, personalEmail, doubleVerified)
                showSuccessToast(toastId, "Integration updated successfully");
                openFreshSalesPopup("", "true")
            } catch (error) {
                showErrorToast(toastId, "Unable to update integration");
            }
        }
    }

    getFreshSalesUsers = async (api_key, crm_domain, userid) => {
        const { apiKey, crmDomain, user } = this.state
        try {
            if (api_key != "" || (apiKey && crmDomain !== "")) {
                const res = await freshSalesGetUsers(crmDomain ? crmDomain : crm_domain, apiKey ? apiKey : api_key)
                this.setState({
                    freshSaleusers: res.data.data,
                    originalFreshsalesUsers: res.data.data,
                    getUsersError: false
                })
                if (api_key) {
                    res.data.data.forEach((u) => {
                        if (u.id == userid) {
                            this.setState({ userName: u.display_name, showUserDropdown: false })
                        }
                    })
                }
            }
        } catch {
            this.setState({ getUsersError: true })
        }
    }

    onClosePopup = () => {
        const { toggelIndex, openFreshSalesPopup } = this.props
        openFreshSalesPopup("close", "", "", toggelIndex)
    }

    onToggleUserDropdown = () => {
        this.setState({ showUserDropdown: !this.state.showUserDropdown })
    }

    getUserByID = (id) => {
        var name = ""
        const { freshSaleusers } = this.state
        if (freshSaleusers) {
            freshSaleusers.forEach((u) => {
                if (u.id == id) {
                    name = u.display_name
                }
            })
        }
        return name
    }

    searchUsers = (e) => {
        const { originalFreshsalesUsers } = this.state
        var q = e.target.value.toLowerCase()
        var users = originalFreshsalesUsers.filter((u) => {
            return u.display_name.toLowerCase().indexOf(q) > -1 || u.email.toLowerCase().indexOf(q) > -1
        })
        this.setState({ freshSaleusers: users })
    }

    render() {
        const { settingPopup, popupFreshSales, image, loader } = this.props
        const { originalFreshsalesUsers, user, userName, showUserDropdown, getUsersError, freshSaleusers, active, crmDomain, syncLeads, requireEmail, apiKey, doubleVerified, companyEmails, personalEmail } = this.state
        return (
            <Fragment>
                {loader.loading &&
                    <Loader />
                }
                <div className={`add-list ${popupFreshSales ? 'open' : ''}`}>
                    <div className={`add-list__content add-list__content-ed`}>
                        <img className="add-list__close" src="/assest/accountBased/close.svg" alt=""
                            onClick={this.onClosePopup} />
                        <p className="add-list__title image-title">Freshsales Settings <img className="integration-img" src={`/assest/integrations/${image}`} alt="" /></p>
                        <div className="popup-filters">
                            <p className="link-CRM">.freshsales.io</p>
                            <p className="add-list__label mar-top">CRM DOMAIN &nbsp;<img src="/assest/icon-help.svg"
                                alt="" width="10"
                                data-tip='This is the domain of the CRM that is used to login to the Freshsales account' />
                            </p>
                            <ReactTooltip />
                            <input className="add-list__search pad-input-right"
                                placeholder="Enter CRM domain"
                                type="text"
                                value={crmDomain}
                                onBlur={this.getFreshSalesUsers}
                                onChange={this.crmDomainChange}
                                required />
                            {this.state.formErrors.crmDomain &&
                                <div className="error">{this.state.formErrors.crmDomain}</div>}
                        </div>
                        <div className="popup-filters">
                            <p className="add-list__label mar-top">API KEY &nbsp;<img src="/assest/icon-help.svg" alt=""
                                width="10"
                                data-tip="Login to your Freshsales account. The api token will be available under Personal Settings > API Settings" />
                            </p>
                            <input className="add-list__search"
                                placeholder="Enter Api key"
                                type="text"
                                value={apiKey}
                                onChange={this.handleChange}
                                onBlur={this.getFreshSalesUsers}
                                required />
                            {this.state.formErrors.apiKey &&
                                <div className="error">{this.state.formErrors.apiKey}</div>}
                        </div>
                        <div className="popup-filters">
                            <p className="add-list__label mar-top">LEAD OWNER &nbsp;<img src="/assest/icon-help.svg"
                                alt="" width="10"
                                data-tip='This is the domain of the CRM that is used to login to the Freshsales account' />
                            </p>
                            <div className={`add-list__search-box ${showUserDropdown ? 'active' : ''}`}
                                onClick={this.onToggleUserDropdown}>
                                <input
                                    type="text"
                                    className={`add-list__search ${getUsersError ? 'error-field' : ''}`}
                                    disabled={true}
                                    placeholder="No User selected"
                                    value={userName}
                                />
                                {!showUserDropdown && <img src="/assest/accountBased/role-arr.svg" alt="" />}
                                {this.state.formErrors.userName &&
                                    <div className="error">{this.state.formErrors.userName}</div>}
                            </div>

                            {getUsersError &&
                                <div className="error">Unable to get users from Freshsales. Please make sure the API key and
                                domain you entered are correct.</div>}

                            <Fragment>
                                <div
                                    className={`add-list-name ${originalFreshsalesUsers.length > 0 && showUserDropdown ? 'active' : ''}`}>
                                    <div className="add-list-name__search">
                                        <img src="/assest/accountBased/search.svg" alt="" />
                                        <input placeholder="Search Users" type="text" onChange={this.searchUsers} />
                                    </div>

                                    <div className="add-list-name__row slim-scroll" ref={this.dropDownRef}>

                                        <InfiniteScroll
                                            hasMore={false}
                                            loader={<div className="loader" key={0}>Loading ...</div>}
                                            useWindow={false}>
                                            {freshSaleusers.map((item, index) =>
                                                <div className="add-list-name__item" key={index}
                                                    onClick={this.leadOwnerChange.bind(this, item.id)}>
                                                    <p className="add-list-name__text">{item.display_name}</p>
                                                    <p className="add-list-name__edit">{item.email}</p>
                                                </div>
                                            )}

                                        </InfiniteScroll>

                                    </div>
                                </div>
                            </Fragment>
                        </div>

                        <div className="popup-filters">
                            <div className="right-filters__data rm_border">
                                <div className={`switch ${syncLeads ? 'active' : ''}`}
                                    onClick={() => this.changeSwitch()}><span></span>
                                </div>
                                <p className="switch__name">SYNC LEADS AUTOMATICALLY?</p>
                            </div>
                            <div className="right-filters__data rm_border">
                                <div className={`switch ${requireEmail ? 'active' : ''}`}
                                    onClick={() => this.changeSwitch(2)}><span></span>
                                </div>
                                <p className="switch__name">REQUIRE EMAIL?</p>
                            </div>
                        </div>
                        <div className={`popup-animation ${requireEmail ? "animate" : ""}`}>
                            <div className="right-filters__check check-margin">
                                <div className={`check ${doubleVerified ? 'active' : ''}`}
                                    onClick={() => this.changeCheck()}></div>
                                <p>Double verified emails only</p>
                            </div>
                            <div className="right-filters__check check-margin">
                                <div className={`check ${companyEmails ? 'active' : ''}`}
                                    onClick={() => this.changeCheck(1)}></div>
                                <p>Include company emails</p>
                            </div>
                            <div className="right-filters__check check-margin">
                                <div className={`check ${personalEmail ? 'active' : ''}`}
                                    onClick={() => this.changeCheck(2)}></div>
                                <p>Include personal emails</p>
                            </div>
                        </div>

                        {!settingPopup &&
                            <div className="add-list__buttons">
                                <div
                                    className={`add-list__btn ${originalFreshsalesUsers.length === 0 ? "disable-btn" : ''}`}
                                    onClick={() => {
                                        this.onConfirmClick()
                                    }}>Authenticate
                            </div>
                                <div className="add-list__btn" onClick={this.onClosePopup}>Cancel</div>
                            </div>
                        }
                        {settingPopup &&
                            <div className="add-list__buttons">
                                <div className="add-list__btn" onClick={() => {
                                    this.onUpdateClick()
                                }}>Update
                            </div>
                                <div className="add-list__btn" onClick={this.onClosePopup}>Cancel</div>
                            </div>
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        loader: state.loader,
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        startLoader,
        stopLoader
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FreshSalesPopup)
