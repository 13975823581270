import {SEARCH_RESULT_ACTIONS} from '../constants/constant';

export function setRequireEmail(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_EMAIL,
        payload: status
    };
}

export function setRequireDoubleVerifiedEmails(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_DOUBLE_VERIFIED_EMAILS,
        payload: status
    };
}

export function setRequireCompanyEmails(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_COMPANY_EMAILS,
        payload: status
    };
}

export function setRequirePersonalEmails(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_PERSONAL_EMAILS,
        payload: status
    };
}

export function setRequirePhoneNumber(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_PHONE_NUMBER,
        payload: status
    };
}

export function setRequireCompanyPhoneNumber(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_COMPANY_PHONE_NUMBER,
        payload: status
    };
}

export function setRequirePersonalPhoneNumber(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_PERSONAL_PHONE_NUMBER,
        payload: status
    };
}

export function setRequireSocial(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_SOCIAL,
        payload: status
    };
}

export function setRequireLinkedin(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_LINKEDIN,
        payload: status
    };
}

export function setRequireFacebook(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_FACEBOOK,
        payload: status
    };
}

export function setRequireTwitter(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_TWITTER,
        payload: status
    };
}

export function setRequireJobStatus(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_JOB_STATUS,
        payload: status
    };
}

export function setRequireEmployment(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_EMPLOYMENT,
        payload: status
    };
}

export function setRequireUnemployment(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_UNEMPLOYMENT,
        payload: status
    };
}

export function setRequireMailingAddress(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.REQUIRE_MAILING_ADDRESS,
        payload: status
    };
}

export function setExcludePreviousExperience(status) {
    return {
        type: SEARCH_RESULT_ACTIONS.EXCLUDE_PREVIOUS_EXPERIENCE,
        payload: status
    };
}

export function setMaxPeoplePerCompany(value) {
    return {
        type: SEARCH_RESULT_ACTIONS.MAX_PEOPLE_PER_COMPANY,
        payload: value
    };
}

export function setMaxResultsPerCompany(value) {
    return {
        type: SEARCH_RESULT_ACTIONS.MAX_RESULTS_PER_COMPANY,
        payload: value
    };
}

export function setCompanyFoundedYearFrom(value) {
    return {
        type: SEARCH_RESULT_ACTIONS.COMPANY_FOUNDED_DATE_FROM,
        payload: value
    };
}

export function setCompanyFoundedYearTo(value) {
    return {
        type: SEARCH_RESULT_ACTIONS.COMPANY_FOUNDED_DATE_TO,
        payload: value
    };
}

export function setSearchType(value) {
    return {
        type: SEARCH_RESULT_ACTIONS.SEARCH_TYPE,
        payload: value
    };
}

export function setAllSearchFilter(value) {
    return {
        type: SEARCH_RESULT_ACTIONS.SET_ALL,
        payload: value
    };
}

export function clearAllFilters(searchType) {
    return {
        type: SEARCH_RESULT_ACTIONS.CLEAR_ALL,
        payload: searchType,
    };
}


export function clearSearchResultFiltersChanged(searchType) {
    return {
        type: SEARCH_RESULT_ACTIONS.CLEAR_SEARCHRESULTFILTER_CHANGED,
        payload: searchType,
    };
}
