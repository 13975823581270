import axios from '../axios';
import { SearchType } from "../constants/constant";
import { convertTechnologiesFromStateToProps, convertLocationFromStateToProps, getOnlyMarketPlaceObjectFromProps } from "../constants/helper";
const queryString = require('query-string');

const POOL_SERVICE_URL = process.env.REACT_APP_ROOT_URL;

export function saveSearchCriteria(
  marketBased,
  accountBased,
  filterOptions,
  filterType,
  name
) {
  let object = { ...filterOptions };
  if (filterType === SearchType.AccountBased) {
    object = { ...object, ...accountBased };
  } else {
    let marketBasedObj = getOnlyMarketPlaceObjectFromProps(marketBased, filterOptions);
    object = {
      ...object,
      ...marketBasedObj
    };
  }
  const searchCriteria = {
    name,
    query: {
      type:
        filterType === SearchType.AccountBased
          ? "people_lookup"
          : "people_prospector",
      people_lookup:
        filterType === SearchType.AccountBased ? object : null,
      people_prospector:
        filterType === SearchType.MarketBased ? object : null
    }
  };
  return axios({
    method: "post",
    data: {
      ...searchCriteria
    },
    url: `/core/squery`
  });
}

export function updateSearchCriteria(
  marketBased,
  accountBased,
  filterOptions,
  filterType,
  id,
  name
) {
  let object = { ...filterOptions };
  if (filterType === SearchType.AccountBased) {
    object = { ...object, ...accountBased };
  } else {
    // const technology = convertTechnologiesFromStateToProps(
    //   marketBased.technology
    // );
    const technology = marketBased.technology && marketBased.technology.include && marketBased.technology.exclude ? marketBased.technology : convertTechnologiesFromStateToProps(
      marketBased.technology
    );
    let marketBasedObj = {
      ...marketBased,
      technology
    };
    object = {
      ...object,
      ...marketBasedObj
    };
  }
  const searchCriteria = {
    name,
    query: {
      type:
        filterType === SearchType.AccountBased
          ? "people_lookup"
          : "people_prospector",
      people_lookup:
        filterType === SearchType.AccountBased ? object : null,
      people_prospector:
        filterType === SearchType.MarketBased ? object : null
    }
  };

  return axios({
    method: "patch",
    data: {
      ...searchCriteria
    },
    url: `/core/squery/${id}`
  });
}

export function getSavedSearches(pageNum, recordsPerPage, sort, sortOrder, ownersFilter, nameFilter) {
  const queryStr = queryString.stringify({pageNum, recordsPerPage, sort, sortOrder, owners: ownersFilter, name: nameFilter});
  return axios({
    method: "get",
    url: `/core/squeries?${queryStr}`
  });
}

export function getSavedSearchItemDetail(saveSearchId) {
  return axios({
    method: "get",
    url: `/core/squery/${saveSearchId}`
  });
}

export function deleteSavedSearcheById(saveSearchId) {
  return axios({
    method: "delete",
    url: `/core/squery/${saveSearchId}`
  });
}
