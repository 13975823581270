import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux';
import {closePopup, openPopup, startLoader, stopLoader} from '../../actions/loader';
import {bindActionCreators} from 'redux';
import {
    deleteBulkLeads,
    deleteLead,
    exportLeadsToCSV,
    getLeadsByListId,
    moveBulkLeads,
    moveLead,
    searchLeadsByKeyword
} from '../../services/insideList';
import {setErrorMessage} from '../../actions/error_msg';
import $ from 'jquery';
import {
    BreadCrumb,
    DontList,
    InsideListContent,
    InsideListFilter,
    InsideListHeader,
    Loader,
    Pagination,
    RightPanelBanner
} from '../../components'
import './style.scss';
import {getIntegration} from '../../actions/integration';
import {
    clearAllInsideFilters,
    setInsideListAddedByFuzebote,
    setInsideListCompanyEmail,
    setInsideListDoubleVerifiedEmail,
    setInsideListFromTo,
    setInsideListHasEmail,
    setInsideListHasLinkedIn,
    setInsideListHasMailingAddress,
    setInsideListHasPhone,
    setInsideListPersonalEmail,
    setInsideListSingleVerifiedEmail
} from '../../actions/inside_list_filters';
import ExportToCSV from '../../components/LeadListsBox/LeadListsContent/ExportToCSV';
import ExportToGoogleSheet from '../../components/LeadListsBox/LeadListsContent/ExportToGoogle';
import {addIntegrationLeads, getIntegrations, getLeadListById, initSpreedSheet} from '../../services/leads';
import DuxSoup from '../../components/LeadListsBox/LeadListsContent/DuxSoup';
import Woodpecker from '../../components/LeadListsBox/LeadListsContent/Woodpecker';
import MailShake from '../../components/LeadListsBox/LeadListsContent/MailShake';
import {toast} from 'react-toastify';
import isEqual from 'lodash/isEqual';
import {INSIDE_LIST_FILTER_INITIAL_STATE} from '../../reducers/inside_list_filters';
import {LOCAL_STORAGE_KEYS} from '../../constants/constant';
import {EmitterService} from "../../services/emitterService";
import {showSuccessToast} from "../../helpers/toast";

const queryString = require('query-string');

class InsideList extends Component {
    container = React.createRef();
    state = {
        leads: [],
        totalLeads: 0,
        totalNewLeads: 0,
        allLeads: false,
        leadsInView: false,
        newLeads: false,
        selectedLeads: [],
        deSelectedLeads: [],
        recordsPerPage: 25,
        pageNum: 1,
        showDropDown: false,
        showExportCsvPopup: false,
        showExportGoogleSheetPopup: false,
        googleSheetIntegrated: false,
        integrations: [],
        selctedOneLead: undefined,
        sortOrder: '',
        sortingColumn: '',
        paginationMarginBottom: 0,
        woodpeckerCampaigns: [],
        lemlistCampaigns: [],
        replyCampaigns: [],
        highlevelCampaigns: [],
        jazzhrCampaigns: [],
        mailshakeCampaigns: [],
        sheetNames: [],
        connection_message: "",
        list: null,
        sheetLoading: false,
    };

    async componentWillMount() {
        EmitterService.emit("loadLeadsRemaining");
        this.integration();
        await this.props.clearAllInsideFilters();
        this.getLeadsForSelectedList();
        this.getListInfo();
        this.props.closePopup();

        const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        // if(this.doesIntegrationExist("googlesheets")) {
        //   this.setState({sheetLoading: true});
        //   getUsersSheet(userId).then( (response) => {
        //       if (response.data.data && response.data.data.length) {
        //         this.setState({ sheetNames: response.data.data, sheetLoading: false })
        //       }
        //   }).catch( (error) => {
        //     const toastId = toast('Unable to load Google Sheets', {
        //         className: "toast-error-div",
        //         type: toast.TYPE.ERROR
        //       });
        //       toast.done(toastId)
        //       $(".Toastify").addClass("mes-done");
        //       setTimeout(function () {
        //         toast.dismiss(toastId)
        //         $(".Toastify").removeClass("mes-done");
        //       }, 3000)
        //       this.setState({sheetLoading: false});
        //   });
        // }

    }

    componentDidMount() {
        let recordsPerPage = localStorage.getItem("recordsPerPage");
        if (recordsPerPage === null) {
            localStorage.setItem("recordsPerPage", "25");
            recordsPerPage = localStorage.getItem("recordsPerPage");
        }
        this.setState({recordsPerPage: recordsPerPage});
    }

    doesIntegrationExist = integrationName => {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        const foundIntegration = user.integrations.find(
            integration => integration.name === integrationName
        );
        return !!foundIntegration;
    }

    getListInfo = async () => {
        const qParams = queryString.parse(this.props.history.location.search);
        const list_id = qParams.list_id;
        if (list_id) {
            const res = await getLeadListById(list_id);
            const list = res.data.data;
            this.setState({list});
        }
    }

    integration = async () => {
        const {getIntegration} = this.props;
        const userId = JSON.parse(localStorage.getItem("user"));
        if (userId !== null) {
            const res = await getIntegrations(userId.id);
            if (res.data) {
                let integrations = res.data.data;
                this.setState({integrations});
                let sheetIntegration = integrations.find(function (el) {
                    return el.name === "googlesheets";
                });
                if (sheetIntegration) {
                    this.setState({googleSheetIntegrated: true});
                }
            }
            await getIntegration(res);
        }
    };

    openDuxSoupPopup = () => {
        let list = this.state.list;
        if (list.integrations_info.duxsoup_info) {
            this.setState({
                connection_message: list.integrations_info.duxsoup_info.connection_message,
            })
        }
        this.setState({popupDuxSoup: !this.state.popupDuxSoup})
    }
    openWoodpeckerPopup = async () => {
        // await getIntegrationCampaigns('woodpecker').then( (response) => {
        //   this.setState({woodpeckerCampaigns: response.data.data})
        // });
        this.setState({popupWoodpecker: !this.state.popupWoodpecker})
        // this.setState({woodpeckerCampaigns:
        //   [
        //       {"id":1, "title":"ABC"},
        //       {"id":1, "title":"BCD"}
        //   ]
        // })

    }
    openLemlistPopup = async () => {
        // await getIntegrationCampaigns('lemlist').then( (response) => {
        //   this.setState({lemlistCampaigns: response.data.data})
        // });
        this.setState({popupLemlist: !this.state.popupLemlist})
        // this.setState({lemlistCampaigns:
        //   [
        //       {"_id": "a", "name":"ABC"},
        //       {"_id": "b", "name":"BCD"}
        //   ]
        // })

    }
    openMailshakePopup = async () => {
        // await getIntegrationCampaigns('mailshake').then( (response) => {
        //   this.setState({mailshakeCampaigns: response.data.data})
        // });
        this.setState({popupMailshake: !this.state.popupMailshake})
        // this.setState({mailshakeCampaigns:
        //   [
        //       {"id":1, "title":"ABC"},
        //       {"id":1, "title":"BCD"}
        //   ]
        // })

    }
    openReplyPopup = async () => {
        // await getIntegrationCampaigns('reply').then( (response) => {
        //   this.setState({replyCampaigns: response.data.data})
        // });
        this.setState({popupReply: !this.state.popupReply})
        // this.setState({replyCampaigns:
        //   [
        //       {"id":1, "name":"ABC"},
        //       {"id":1, "name":"BCD"}
        //   ]
        // })

    }
    openHighlevelPopup = async () => {
        // await getIntegrationCampaigns('reply').then( (response) => {
        //   this.setState({replyCampaigns: response.data.data})
        // });
        this.setState({popupHighlevel: !this.state.popupHighlevel})
        // this.setState({replyCampaigns:
        //   [
        //       {"id":1, "name":"ABC"},
        //       {"id":1, "name":"BCD"}
        //   ]
        // })

    }
    
    openJazzhrPopup = async () => {
        // await getJazzhrJobs().then( (response) => {
        //   this.setState({replyCampaigns: response.data.data})
        // });
        this.setState({popupJazzhr: !this.state.popupJazzhr})
        // this.setState({jazzhrCampaigns:
        //   [
        //       {"id":1, "title":"ABC"},
        //       {"id":1, "title":"BCD"}
        //   ]
        // })
    }


    integrateSelectedLeadsWithIntegration = async integrationName => {
        this.setState({selctedOneLead: undefined});
        switch (integrationName) {
            case "woodpecker":
                this.openWoodpeckerPopup()
                return true
            case "lemlist":
                this.openLemlistPopup()
                return true
            case "reply":
                this.openReplyPopup();
                return true;
                case "highlevel":
                    this.openHighlevelPopup();
                    return true;
            case "jazzhr":
                this.openJazzhrPopup();
                return true
            case "mailshake":
                this.openMailshakePopup()
                return true
            case "duxsoup":
                this.openDuxSoupPopup()
                return true
            default:

                const {startLoader, stopLoader} = this.props;
                startLoader(true);

                let name = integrationName;
                if (integrationName == 'duxsoup-no-popup')
                    name = 'duxsoup';

                let toastId = null;
                toastId = toast(`Sending leads to ${name}`, {
                    autoClose: 10000,
                });
                try {

                    let query_filters = {
                        added_by_fuzebot: this.props.insideListFilters.addedByFuzebot,
                        has_email: this.props.insideListFilters.hasEmail,
                        personal_email: this.props.insideListFilters.personalEmail,
                        company_email: this.props.insideListFilters.companyEmail,
                        single_verified_email: this.props.insideListFilters.singleVerifiedEmail,
                        double_verified_email: this.props.insideListFilters.doubleVerifiedEmail,
                        has_linkedin: this.props.insideListFilters.hasLinkedIn,
                        has_phone: this.props.insideListFilters.hasPhone,
                        has_mailing_address: this.props.insideListFilters.hasMailingAddress,
                        date_created_from: this.props.insideListFilters.from,
                        date_created_to: this.props.insideListFilters.to
                    };
                    const params = {
                        "query_filters": query_filters,
                        'list_id': this.state.listId,
                        'select_all': this.state.allLeads,
                        'selected_ids': this.state.selectedLeads,
                        'search_query': this.state.searchQuery,
                        'deselected_ids': this.state.deSelectedLeads,
                    };
                    const response = await addIntegrationLeads(
                        name,
                        this.state.listId,
                        '',
                        undefined,
                        '',
                        null,
                        name == 'duxsoup' ? "visit_profile" : undefined,
                        false,
                        params,
                    );
                    if (response.data.data) {
                        let added = response.data.data.added;
                        let skipped = response.data.data.skipped;
                        if (skipped == -1 && added == -1) {
                            this.showSuccessToast(toastId, "Leads are being sent to the integration. Please check back in a couple of minutes.");
                        } else if (skipped == 0) {
                            this.showSuccessToast(toastId, "Leads sent successfully to integration");
                        } else if (skipped > 0) {
                            this.showSuccessToast(toastId, `${added} leads sent successfully to integration. ${skipped} leads were skipped`);
                        }
                    } else {
                        this.showErrorToast(toastId, 'Unable to send leads to integration');
                    }
                } catch (error) {
                    //setErrorMessage(true);
                    this.showErrorToast(toastId, 'Unable to send leads to integration');
                    this.setState({leadsError: true});
                }
                stopLoader(false);
        }
    };

    integrateOneLeadWithIntegration = async (leadId, integrationName) => {
        this.setState({selctedOneLead: leadId, oneLeadSelected: true});
        const {startLoader, stopLoader, setErrorMessage} = this.props;
        switch (integrationName) {
            case "woodpecker":
                this.openWoodpeckerPopup()
                return true
            case "lemlist":
                this.openLemlistPopup()
                return true
            case "reply":
                this.openReplyPopup();
                return true;
            case "highlevel":
                this.openHighlevelPopup();
                return true;
            case "jazzhr":
                this.openJazzhrPopup();
                return true
            case "mailshake":
                this.openMailshakePopup()
                return true
            case "duxsoup":
                this.openDuxSoupPopup()
                return true
            default:
                startLoader(true);
                let toastId = null;
                let name = integrationName;
                if (integrationName == 'duxsoup-no-popup')
                    name = 'duxsoup';

                toastId = toast(`Sending leads to ${name}`, {
                    autoClose: 10000,
                });
                try {

                    let query_filters = {
                        added_by_fuzebot: this.props.insideListFilters.addedByFuzebot,
                        has_email: this.props.insideListFilters.hasEmail,
                        personal_email: this.props.insideListFilters.personalEmail,
                        company_email: this.props.insideListFilters.companyEmail,
                        single_verified_email: this.props.insideListFilters.singleVerifiedEmail,
                        double_verified_email: this.props.insideListFilters.doubleVerifiedEmail,
                        has_linkedin: this.props.insideListFilters.hasLinkedIn,
                        has_phone: this.props.insideListFilters.hasPhone,
                        has_mailing_address: this.props.insideListFilters.hasMailingAddress,
                        date_created_from: this.props.insideListFilters.from,
                        date_created_to: this.props.insideListFilters.to
                    };
                    
                    const params = {
                        "query_filters": query_filters,
                        'list_id': this.state.listId,
                        'select_all': false,
                        'selected_ids': [leadId],
                        'search_query': '',
                        'deselected_ids': []
                    };
                    const response = await addIntegrationLeads(
                        name,
                        this.state.listId,
                        '',
                        undefined,
                        '',
                        null,
                        name == 'duxsoup' ? "visit_profile" : undefined,
                        false,
                        params,
                    );
                    if (response.data.data) {
                        let added = response.data.data.added;
                        let skipped = response.data.data.skipped;
                        if (skipped == -1 && added == -1) {
                            this.showSuccessToast(toastId, "Leads are being sent to the integration. Please check back in a couple of minutes.");
                        } else if (skipped == 0) {
                            this.showSuccessToast(toastId, "Leads sent successfully to integration");
                        } else if (skipped > 0) {
                            this.showSuccessToast(toastId, `${added} leads sent successfully to integration. ${skipped} leads were skipped`);
                        }

                    } else {
                        this.showErrorToast(toastId, 'Unable to send leads to integration');
                    }
                    // await integrateLeadsWithSelectedIntegrations(
                    //   false,
                    //   this.state.listId,
                    //   '',
                    //   [leadId],
                    //   [],
                    //   integrationName
                    // );
                } catch (error) {
                    //setErrorMessage(true);
                    this.setState({leadsError: true});
                    this.showErrorToast(toastId, 'Unable to send leads to integration');
                }
                stopLoader(false);
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        const qParams = queryString.parse(this.props.history.location.search);
        const searchQuery = qParams.search;
        if (this.state.searchValue !== searchQuery) {
            this.setState({searchValue: searchQuery});
            this.getLeadsForSelectedList();
        }
    }

    selectAllLeads = () => {
        this.setState({
            selctedOneLead: undefined,
            allLeads: true,
            leadsInView: false,
            newLeads: false,
            selectedLeads: [],
            deSelectedLeads: []
        });
    };

    deSelectEverything = () => {
        this.setState({
            selctedOneLead: undefined,
            allLeads: false,
            leadsInView: false,
            newLeads: false,
            selectedLeads: [],
            deSelectedLeads: []
        });
    };

    selectLeadsInView = () => {
        const selectedLeads = this.state.leads.map(lead => lead.id);
        this.setState({
            selctedOneLead: undefined,
            allLeads: false,
            leadsInView: true,
            newLeads: false,
            selectedLeads,
            deSelectedLeads: []
        });
    };

    selectNewLeads = () => {
        const unSeenLeads = this.state.leads.filter(lead => !lead.seen);
        const selectedLeads = unSeenLeads.map(lead => lead.id);
        this.setState({
            selctedOneLead: undefined,
            allLeads: false,
            leadsInView: false,
            newLeads: true,
            selectedLeads,
            deSelectedLeads: []
        });
    };

    selectLead = id => {
        const newDeSelectedLeads = this.state.deSelectedLeads.filter(
            leadId => leadId !== id
        );
        const newSelectedLeads = [...this.state.selectedLeads, id];
        this.setState({
            selctedOneLead: undefined,
            selectedLeads: newSelectedLeads,
            deSelectedLeads: newDeSelectedLeads
        });
    };

    paginate = async num => {
        window.scrollTo(0, 0);
        this.setState({pageNum: num});
        setTimeout(() => this.getLeadsForSelectedList(), 20);
    };
    incOrDecNum = (sign, indexOfLastPage) => {
        let currentPage = this.state.pageNum;
        if (sign === "-" && currentPage > 1) {
            currentPage = currentPage - 1;
            this.paginate(currentPage);
        } else if (sign === "+" && currentPage < indexOfLastPage) {
            currentPage = currentPage + 1;
            this.paginate(currentPage);
        }
    };
    paginationPopup = async () => {
        this.setState({showDropDown: !this.state.showDropDown});
    };

    updatePerPage = async (sign, num) => {
        let integer = parseInt(num);
        if (sign === "+" && integer < 100) {
            this.setState({recordsPerPage: integer + 25, pageNum: 1});
            localStorage.setItem("recordsPerPage", integer + 25);
            setTimeout(() => this.getLeadsForSelectedList(), 20);
        } else if (sign === "-" && integer > 25) {
            this.setState({recordsPerPage: integer - 25, pageNum: 1});
            localStorage.setItem("recordsPerPage", integer - 25);
            setTimeout(() => this.getLeadsForSelectedList(), 20);
        } else if (sign === "") {
            this.setState({
                showDropDown: !this.state.showDropDown,
                recordsPerPage: integer,
                pageNum: 1
            });
            if (integer - 25 < 25) {
                localStorage.setItem("recordsPerPage", 25);
            } else {
                localStorage.setItem("recordsPerPage", integer - 25);
            }

            setTimeout(() => this.getLeadsForSelectedList(), 20);
        }
    };

    deSelectLead = id => {
        const newSelectedLeads = this.state.selectedLeads.filter(
            leadId => leadId !== id
        );
        const newDeSelectedLeads = [...this.state.deSelectedLeads, id];
        this.setState({
            selectedLeads: newSelectedLeads,
            deSelectedLeads: newDeSelectedLeads
        });
    };

    toggleAddedByFuzebot = async () => {
        await this.props.setInsideListAddedByFuzebote(
            !this.props.insideListFilters.addedByFuzebot
        );
        this.getLeadsForSelectedList();
    };


    toggleHasEmail = async () => {
        await this.props.setInsideListHasEmail(
            !this.props.insideListFilters.hasEmail
        );
        this.getLeadsForSelectedList();
    };
    togglePersonalEmail = async () => {
        await this.props.setInsideListPersonalEmail(
            !this.props.insideListFilters.personalEmail
        );
        this.getLeadsForSelectedList();
    };

    toggleCompanyEmail = async () => {
        await this.props.setInsideListCompanyEmail(
            !this.props.insideListFilters.companyEmail
        );
        this.getLeadsForSelectedList();
    };

    toggleSingleVerifiedEmail = async () => {
        await this.props.setInsideListSingleVerifiedEmail(
            !this.props.insideListFilters.singleVerifiedEmail
        );
        this.getLeadsForSelectedList();
    };

    toggleDoubleVerifiedEmail = async () => {
        await this.props.setInsideListDoubleVerifiedEmail(
            !this.props.insideListFilters.doubleVerifiedEmail
        );
        this.getLeadsForSelectedList();
    };

    toggleHasLinkedIn = async () => {
        await this.props.setInsideListHasLinkedIn(
            !this.props.insideListFilters.hasLinkedIn
        );
        this.getLeadsForSelectedList();
    };

    toggleHasPhone = async () => {
        await this.props.setInsideListHasPhone(
            !this.props.insideListFilters.hasPhone
        );
        this.getLeadsForSelectedList();
    };

    toggleHasMailingAddress = async () => {
        await this.props.setInsideListHasMailingAddress(
            !this.props.insideListFilters.hasMailingAddress
        );
        this.getLeadsForSelectedList();
    };

    setInsideListFromTo = async (from, to) => {
        await this.props.setInsideListFromTo(from, to);
        this.getLeadsForSelectedList();
    };

    clearAllFilters = async () => {
        await this.props.clearAllInsideFilters();
        this.getLeadsForSelectedList();
    };

    searchFoLeads = () => {
        this.props.history.push({
            pathname: "/lead-search/market-based"
            // search: `?action=edit_fuzebot&list_id=${leadList.id}`
        });
    };

    doesListIdExist = () => {
        const qParams = queryString.parse(this.props.history.location.search);
        const listId = qParams.list_id;
        return !!listId;
    };


    switchSort = async (name) => {
        let sortOrder = this.state.sortOrder;
        if (name === this.state.sortingColumn) {
            sortOrder = this.state.sortOrder === 'desc' ? 'asc' : 'desc';
            await this.setState({sortOrder});
        } else {
            sortOrder = 'desc';
            await this.setState({sortOrder, sortingColumn: name});
        }
        this.getLeadsForSelectedList();
    }

    getLeadsForSelectedList = async () => {
        const qParams = queryString.parse(this.props.history.location.search);
        const searchQuery = qParams.search;
        const listId = qParams.list_id;
        this.setState({searchValue: searchQuery, listId});
        const {startLoader, stopLoader, setErrorMessage} = this.props;
        startLoader(true);
        try {
            if (listId) {
                const params = {
                    listID: listId,
                    ...this.props.insideListFilters
                };
                const queryParams = queryString.stringify(params);
                const res = await getLeadsByListId(
                    queryParams,
                    this.state.recordsPerPage,
                    this.state.pageNum,
                    this.state.sortingColumn,
                    this.state.sortOrder,
                );

                res.data.data.people_leads.forEach((item) => {
                    item.person.addedToList = true;
                    item.addedToList = true;
                    item.person.leadId = item.id;
                    item.person.email = item.email;

                    item.phone = item.person.phone ? item.person.phone : [];
                    if (item.person.job && item.person.job.company && item.person.job.company.phone) {
                        item.phone.push({
                            number: item.person.job.company.phone
                        });
                    }

                    return item;
                });


                this.setState({
                    leads: res.data.data.people_leads,
                    totalLeads: res.data.data.total_leads,
                    totalNewLeads: res.data.data.total_new_leads
                });

            } else {
                const res = await searchLeadsByKeyword(
                    searchQuery,
                    this.state.recordsPerPage,
                    this.state.pageNum
                );

                res.data.data.people_leads.forEach((item) => {
                    item.person.addedToList = true;
                    item.addedToList = true;
                    item.person.leadId = item.id;
                    item.person.email = item.email;
                });

                this.setState({
                    leads: res.data.data.people_leads,
                    totalLeads: res.data.data.total_leads,
                    totalNewLeads: res.data.data.total_new_leads
                });
            }
        } catch (error) {
            setErrorMessage(true);
            this.setState({leadsError: true});
        }
        this.props.closePopup();
        stopLoader(false);
    };

    moveBulkSelectedToList = async listId => {
        const {startLoader, stopLoader, setErrorMessage} = this.props;
        startLoader(true);
        let toastId = null;
        try {
            toastId = toast(`Moving selected leads`, {
                autoClose: 10000,
            });

            const queryParams = queryString.parse(this.props.history.location.search);
            let list_id = queryParams.list_id;
            let search_query = queryParams.search;
            let query_filters = {
                added_by_fuzebot: this.props.insideListFilters.addedByFuzebot,
                has_email: this.props.insideListFilters.hasEmail,
                personal_email: this.props.insideListFilters.personalEmail,
                company_email: this.props.insideListFilters.companyEmail,
                single_verified_email: this.props.insideListFilters.singleVerifiedEmail,
                double_verified_email: this.props.insideListFilters.doubleVerifiedEmail,
                has_linkedin: this.props.insideListFilters.hasLinkedIn,
                has_phone: this.props.insideListFilters.hasPhone,
                has_mailing_address: this.props.insideListFilters.hasMailingAddress,
                date_created_from: this.props.insideListFilters.from,
                date_created_to: this.props.insideListFilters.to
            };
            const resp = await moveBulkLeads(
                list_id,
                search_query,
                query_filters,
                this.state.allLeads,
                this.state.newLeads,
                this.state.leadsInView,
                this.state.selectedLeads,
                this.state.deSelectedLeads,
                listId
            );
            this.showSuccessToast(toastId, 'Selected leads moved to list successfully');
            //this.getLeadsForSelectedList();
            this.paginate(1);
        } catch (e) {
            this.showErrorToast(toastId, 'Unable to move selected leads');
            //setErrorMessage(true);
        }
        stopLoader(false);
    };

    exportSelectedLeads = () => {
        this.setState({showExportCsvPopup: true});
    };

    exportSelectedLeadsToGoogleSheet = () => {
        if (this.state.googleSheetIntegrated)
            this.setState({showExportGoogleSheetPopup: true});
        else {
            let toastId = toast('Redirecting to integration', {
                autoClose: 12000,
            });
            this.openSheets(toastId);
        }
    };

    openSheets = async (toastId) => {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        try {
            const res = await initSpreedSheet(user, "", "/integrations", "googlesheets", false, false,
                false, false, false)
            window.location.href = res.data.data
        } catch (error) {
            toast.done(toastId)
            $(".Toastify").addClass("mes-done");
            setTimeout(() => {
                toast.update(toastId, {
                    className: "toast-error-div",
                    render: "Unable to turn on integration",
                    type: toast.TYPE.ERROR
                });
            }, 1300)
            setTimeout(function () {
                toast.dismiss(toastId)
                $(".Toastify").removeClass("mes-done");
            }, 4000)
        }
    }

    onExportFromPopup = async (email, selectedColumns) => {
        this.closeExportCsvPopup();
        const {startLoader, stopLoader, setErrorMessage} = this.props;
        startLoader(true);
        let toastId = null;
        toastId = toast(`Exporting selected leads`, {
            autoClose: 10000,
        });
        try {
            const queryParams = queryString.parse(this.props.history.location.search);
            let list_id = queryParams.list_id;
            let search_query = queryParams.search;
            let query_filters = {
                added_by_fuzebot: this.props.insideListFilters.addedByFuzebot,
                has_email: this.props.insideListFilters.hasEmail,
                personal_email: this.props.insideListFilters.personalEmail,
                company_email: this.props.insideListFilters.companyEmail,
                single_verified_email: this.props.insideListFilters.singleVerifiedEmail,
                double_verified_email: this.props.insideListFilters.doubleVerifiedEmail,
                has_linkedin: this.props.insideListFilters.hasLinkedIn,
                has_phone: this.props.insideListFilters.hasPhone,
                has_mailing_address: this.props.insideListFilters.hasMailingAddress,
                date_created_from: this.props.insideListFilters.from,
                date_created_to: this.props.insideListFilters.to
            };
            await exportLeadsToCSV(
                list_id,
                search_query,
                query_filters,
                email,
                this.state.allLeads,
                this.state.newLeads,
                this.state.leadsInView,
                this.state.selectedLeads,
                this.state.deSelectedLeads,
                selectedColumns
            );
            showSuccessToast(toastId, `Export started. Check your email in a few minutes.`);
        } catch (e) {
            //setErrorMessage(true);
            this.showErrorToast(toastId, 'Unable to export selected leads');
        }
        stopLoader(false);
    };

    closeExportCsvPopup = () => {
        this.setState({showExportCsvPopup: false});
    };

    toggleExportGoogleSheetPopup = () => {
        this.setState({showExportGoogleSheetPopup: false});
    };

    moveSelectedToList = async (leadId, listId) => {
        const {startLoader, stopLoader, setErrorMessage} = this.props;
        startLoader(true);
        let toastId = null;
        try {
            toastId = toast(`Moving selected leads`, {
                autoClose: 10000,
            });
            const resp = await moveLead(leadId, listId);
            this.showSuccessToast(toastId, 'Selected leads moved to list successfully');
            //this.getLeadsForSelectedList();
            this.paginate(1);
        } catch (e) {
            this.showErrorToast(toastId, 'Unable to move selected leads');
        }
        stopLoader(false);
    };

    showSuccessToast = (toastId, message) => {
        toast.done(toastId);
        $(".Toastify").addClass("mes-done");
        setTimeout(() => {
            toast.update(toastId, {
                className: "toast-success-div",
                render: message,
                type: toast.TYPE.SUCCESS
            });
        }, 1300);
        setTimeout(function () {
            toast.dismiss(toastId)
            $(".Toastify").removeClass("mes-done");
        }, 4000)
    }
    showErrorToast = (toastId, errorMessage) => {
        toast.done(toastId)
        $(".Toastify").addClass("mes-done");
        setTimeout(() => {
            toast.update(toastId, {
                className: "toast-error-div",
                render: errorMessage,
                type: toast.TYPE.ERROR
            });
        }, 1300)
        setTimeout(function () {
            toast.dismiss(toastId)
            $(".Toastify").removeClass("mes-done");
        }, 4000)
    }

    deleteBulkSelected = async () => {
        const {startLoader, stopLoader} = this.props;
        startLoader(true);
        let toastId = null;
        try {
            toastId = toast(`Deleting selected leads`, {
                autoClose: 10000,
            });
            const qParams = queryString.parse(this.props.history.location.search);

            const list_id = qParams.list_id;
            let search_query = qParams.search;

            let query_filters = {
                added_by_fuzebot: this.props.insideListFilters.addedByFuzebot,
                has_email: this.props.insideListFilters.hasEmail,
                personal_email: this.props.insideListFilters.personalEmail,
                company_email: this.props.insideListFilters.companyEmail,
                single_verified_email: this.props.insideListFilters.singleVerifiedEmail,
                double_verified_email: this.props.insideListFilters.doubleVerifiedEmail,
                has_linkedin: this.props.insideListFilters.hasLinkedIn,
                has_phone: this.props.insideListFilters.hasPhone,
                has_mailing_address: this.props.insideListFilters.hasMailingAddress,
                date_created_from: this.props.insideListFilters.from,
                date_created_to: this.props.insideListFilters.to
            };
            const resp = await deleteBulkLeads(
                search_query,
                query_filters,
                this.state.allLeads,
                this.state.newLeads,
                this.state.leadsInView,
                this.state.selectedLeads,
                this.state.deSelectedLeads,
                list_id
            );
            this.showSuccessToast(toastId, 'Selected leads deleted successfully');
            this.getLeadsForSelectedList();
        } catch (e) {
            this.showErrorToast(toastId, 'Unable to delete selected leads');
        }
        stopLoader(false);
    };

    deleteSelectedLead = async leadId => {
        const {startLoader, stopLoader, setErrorMessage} = this.props;
        startLoader(true);
        let toastId = null;
        try {
            toastId = toast(`Deleting selected Lead`, {
                autoClose: 10000,
            });
            const resp = await deleteLead(leadId);
            this.showSuccessToast(toastId, 'Selected lead deleted successfully');
            this.getLeadsForSelectedList();
        } catch (e) {
            this.showErrorToast(toastId, 'Unable to delete selected lead');
        }
        stopLoader(false);
    };

    showLeadDetail = (leadId) => {
        const {leads} = this.state;

        if (leadId) {
            this.props.openPopup();
        } else {
            this.props.closePopup();
        }

        leads.forEach((item) => {
            if (leadId && item.id === leadId) {
                if (!item.active) {
                    item.active = true;
                    return;
                }
            } else {
                item.active = false;
            }
        });

        this.setState({leads: leads});
    }

    setSelectedPopupItem = (id, index) => {
        if (index == this.state.leads.length - 1) {
            this.setState({paginationMarginBottom: 50});
        } else {
            this.setState({paginationMarginBottom: 0});
        }
    }

    updateDefaultIntegration = (type, value) => {
        let list = this.state.list;
        if (list) {
            if (!list.integrations_info) {
                list.integrations_info = {}
            }
            list.integrations_info[type] = value;
        }
    }

    render() {
        const userId = JSON.parse(localStorage.getItem("user"));
        const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT));
        const queryParams = queryString.parse(this.props.history.location.search);

        const {loader} = this.props;
        const {
            totalLeads, pageNum, showDropDown, recordsPerPage, popupDuxSoup, listId, list,
            popupWoodpecker, popupReply,popupHighlevel, popupJazzhr, popupLemlist, popupMailshake, mailshakeCampaigns, woodpeckerCampaigns, lemlistCampaigns, jazzhrCampaigns, replyCampaigns, highlevelCampaigns, connection_message
        } = this.state;

        let dontHaveList = null;

        const search_query = queryParams.search;
        if (!loader.loading && (!this.state.leads || this.state.leads.length == 0)) {
            dontHaveList = (
                // <DontList
                //   title={
                //     !isEqual(this.props.insideListFilters, INSIDE_LIST_FILTER_INITIAL_STATE)
                //       ? 'We found 0 leads matching this search criteria'
                //       : ''
                //   }
                //   onSearchForLeads={this.searchFoLeads} />
                <DontList
                    title={
                        queryParams.search
                            ? 'No results found matching this search criteria.'
                            : !isEqual(this.props.insideListFilters, INSIDE_LIST_FILTER_INITIAL_STATE)
                            ? '0 leads found matching these filters'
                            : ''
                    }
                    description={
                        queryParams.search
                            ? 'Please try modifying your search criteria.'
                            : !isEqual(this.props.insideListFilters, INSIDE_LIST_FILTER_INITIAL_STATE)
                            ? 'Please try changing the filters'
                            : ''
                    }
                    onSearchForLeads={this.searchFoLeads}/>
            );
        }
        const selectedLeads = this.state.selctedOneLead ? [this.state.selctedOneLead, ...this.state.selectedLeads] : this.state.selectedLeads;
        const params = {
            query_filters: {
                ...this.props.insideListFilters
            },
            'list_id': listId,
            'select_all': this.state.allLeads,
            'selected_ids': selectedLeads,
            'search_query': this.state.searchQuery,
            'deselected_ids': this.state.deSelectedLeads
        };

        let pagination = null;
        if (this.state.leads && this.state.leads.length) { //  && totalLeads > recordsPerPage
            pagination = (
                <Pagination
                    totalLists={totalLeads}
                    recordsPerPage={recordsPerPage}
                    currentPageNum={pageNum}
                    updatePerPage={this.updatePerPage}
                    paginationPopup={this.paginationPopup}
                    paginate={this.paginate}
                    incOrDecNum={this.incOrDecNum}
                    showDropDown={showDropDown}
                    paginationMarginBottom={this.state.paginationMarginBottom}
                />
            );
        }


        const links = queryParams.search ? [
            {
                name: 'Search',
                slug: '/people-lists',
            },
            {
                name: 'Leads',
                slug: null,
            },
        ] : [
            {
                name: 'Lists',
                slug: '/people-lists',
            },
            {
                name: queryParams.list_name,
                slug: null,
            },
            {
                name: 'Leads',
                slug: null,
            },
        ];

        // let leads = this.state.leads.map(item => {
        //   item.phone = item.person.phone ? item.person.phone : [];
        //     if(item.person.job.company.phone) {
        //         item.phone.push({
        //             number: item.person.job.company.phone
        //         });
        //     }

        //   return item;
        // })

        return (
            <div className="inside-page">
                <div className="inside-list-panel">

                    {userId === null && this.props.history.push("/signin?path=/people-lists")}

                    {userAccount.status === "trial_expired" && this.props.history.push('/billing-and-users')}

                    {this.props.user.errorDiv && this.state.leadsError && (
                        <div className="error-div alert-div">
                            <img
                                className="add-list__close"
                                src="/assest/apiKeys/Close_red.svg"
                                alt=""
                                onClick={() =>
                                    this.setState({leadsError: !this.state.leadsError})
                                }
                            />
                            <p>Sorry, we were unable to perform the required action.</p>
                        </div>
                    )}

                    {/* <div className="markbased__table-content"> */}
                    <div className="inside-list-panel__content">
                        <BreadCrumb
                            links={links}
                            // renderAdditionalContent={() => selectedLeads.length > 0 ? `Leads selected: ${selectedLeads.length}` : ''}
                        >

                        </BreadCrumb>

                        <div className="markbased__table">
                            <InsideListHeader
                                allLeads={this.state.allLeads}
                                totalLeads={totalLeads}
                                leadsCountInView={this.state.leads.length}
                                newLeads={this.state.newLeads}
                                leadsInView={this.state.leadsInView}
                                googleSheetIntegrated={this.state.googleSheetIntegrated}
                                selectAllLeads={this.selectAllLeads}
                                selectLeadsInView={this.selectLeadsInView}
                                selectNewLeads={this.selectNewLeads}
                                selectedLeads={this.state.selectedLeads}
                                deSelectEverything={this.deSelectEverything}
                                exportSelectedLeads={this.exportSelectedLeads}
                                exportSelectedLeadsToGoogleSheet={
                                    this.exportSelectedLeadsToGoogleSheet
                                }
                                sortingColumn={this.state.sortingColumn}
                                sortOrder={this.state.sortOrder}
                                switchSort={this.switchSort}
                                searchValue={this.state.searchValue}
                                deleteBulkSelected={this.deleteBulkSelected}
                                deleteSelectedLead={this.deleteSelectedLead}
                                moveBulkSelectedToList={this.moveBulkSelectedToList}
                                moveSelectedToList={this.moveSelectedToList}
                                integrations={this.state.integrations}
                                integrateSelectedLeadsWithIntegration={this.integrateSelectedLeadsWithIntegration}
                            />

                            <div className="inside-list-loader">
                                {loader.loading && <Loader/>}
                            </div>
                            {dontHaveList}

                            <Fragment>
                                {this.state.leads && (
                                    <InsideListContent
                                        leads={this.state.leads}
                                        selectedLeads={this.state.selectedLeads}
                                        deSelectedLeads={this.state.deSelectedLeads}
                                        allLeadsSelected={this.state.allLeads}
                                        newLeadsSelected={this.state.newLeads}
                                        leadsInViewSelected={this.state.leadsInView}
                                        searchValue={this.state.searchValue}
                                        selectLead={this.selectLead}
                                        deSelectLead={this.deSelectLead}
                                        moveSelectedToList={this.moveSelectedToList}
                                        deleteSelectedLead={this.deleteSelectedLead}
                                        integrations={this.state.integrations}
                                        showLeadDetail={this.showLeadDetail}
                                        integrateOneLeadWithIntegration={this.integrateOneLeadWithIntegration}
                                        setSelectedPopupItem={this.setSelectedPopupItem}
                                    />
                                )}
                            </Fragment>
                            {this.state.showExportCsvPopup && (
                                <ExportToCSV
                                    popupCSV={this.state.showExportCsvPopup}
                                    openCsvPopup={this.closeExportCsvPopup}
                                    onExportFromPopup={this.onExportFromPopup}
                                />
                            )}
                            {popupDuxSoup &&
                            <DuxSoup openDuxSoupPopup={this.openDuxSoupPopup} search_query={search_query}
                                     popupDuxSoup={popupDuxSoup} listId={listId} inside={params}
                                     connection_message={connection_message} followup_message=""
                                     updateDefaultIntegration={this.updateDefaultIntegration}/>
                            }
                            {popupWoodpecker &&
                            <Woodpecker list={list} listId={listId} search_query={search_query}
                                        openWoodpeckerPopup={this.openWoodpeckerPopup} type='woodpecker'
                                        popupWoodpecker={popupWoodpecker} woodpeckerCampaigns={woodpeckerCampaigns}
                                        inside={params} updateDefaultIntegration={this.updateDefaultIntegration}
                                        deSelectEverything={this.deSelectEverything}

                            />
                            }
                            {popupReply &&
                            <Woodpecker list={list} listId={listId} search_query={search_query}
                                        openWoodpeckerPopup={this.openReplyPopup} type='reply'
                                        popupWoodpecker={popupReply} woodpeckerCampaigns={replyCampaigns}
                                        inside={params} updateDefaultIntegration={this.updateDefaultIntegration}
                                        deSelectEverything={this.deSelectEverything}
                            />
                            }
                                  {popupHighlevel &&
                            <Woodpecker list={list} listId={listId} search_query={search_query}
                                        openWoodpeckerPopup={this.openHighlevelPopup} type='highlevel'
                                        popupWoodpecker={popupHighlevel} woodpeckerCampaigns={highlevelCampaigns}
                                        inside={params} updateDefaultIntegration={this.updateDefaultIntegration}
                                        deSelectEverything={this.deSelectEverything}
                            />
                            }
                            {popupJazzhr &&
                            <Woodpecker list={list} listId={listId} search_query={search_query}
                                        openWoodpeckerPopup={this.openJazzhrPopup} type='jazzhr'
                                        popupWoodpecker={popupJazzhr} woodpeckerCampaigns={jazzhrCampaigns}
                                        inside={params} updateDefaultIntegration={this.updateDefaultIntegration}
                                        deSelectEverything={this.deSelectEverything}
                            />
                            }
                            {popupLemlist &&
                            <Woodpecker list={list} listId={listId} search_query={search_query}
                                        openWoodpeckerPopup={this.openLemlistPopup} type='lemlist'
                                        popupWoodpecker={popupLemlist} woodpeckerCampaigns={lemlistCampaigns}
                                        inside={params} updateDefaultIntegration={this.updateDefaultIntegration}
                                        deSelectEverything={this.deSelectEverything}
                            />
                                // <Lemlist listID={listID} openLemlistPopup={this.openLemlistPopup} popupLemlist={popupLemlist} lemlistCampaigns={lemlistCampaigns} />
                            }
                            {popupMailshake &&
                            <MailShake list={list} listId={listId} mailshakeCampaigns={mailshakeCampaigns}
                                       search_query={search_query} openMailshakePopup={this.openMailshakePopup}
                                       popupMailshake={popupMailshake} inside={params}
                                       updateDefaultIntegration={this.updateDefaultIntegration}
                                       deSelectEverything={this.deSelectEverything}
                            />
                            }

                            {this.state.showExportGoogleSheetPopup && (
                                <ExportToGoogleSheet
                                    id={this.state.listId}
                                    popupExpGoogle={this.state.showExportGoogleSheetPopup}
                                    openExpGooglePopup={this.toggleExportGoogleSheetPopup}
                                    searchQuery={this.state.searchValue}
                                    selectAll={this.state.allLeads}
                                    selectedIds={this.state.selectedLeads}
                                    deSelectedIds={this.state.deSelectedLeads}
                                    sheetNames={this.state.sheetNames}
                                    loading={this.state.sheetLoading}
                                    insideListFilters={this.props.insideListFilters}
                                    type="leads"
                                />
                            )}
                        </div>

                        {this.state.leads && pagination}

                    </div>

                    {/* </div> */}
                    <div className="markbased__rightbar">
                        <div className="breadcrumb-space-holder"></div>
                        <RightPanelBanner title={"Inside Lead List Demo"} page="insideList" type="inside_people_list"
                                          video_id="6ozmqht6xh"/>

                        {this.doesListIdExist() && (
                            <InsideListFilter
                                filters={this.props.insideListFilters}
                                toggleAddedByFuzebot={this.toggleAddedByFuzebot}
                                toggleHasEmail={this.toggleHasEmail}
                                togglePersonalEmail={this.togglePersonalEmail}
                                toggleCompanyEmail={this.toggleCompanyEmail}
                                toggleSingleVerifiedEmail={this.toggleSingleVerifiedEmail}
                                toggleDoubleVerifiedEmail={this.toggleDoubleVerifiedEmail}
                                toggleHasPhone={this.toggleHasPhone}
                                toggleHasLinkedIn={this.toggleHasLinkedIn}
                                toggleHasMailingAddress={this.toggleHasMailingAddress}
                                setInsideListFromTo={this.setInsideListFromTo}
                                clearAllFilters={this.clearAllFilters}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        startLoader,
        stopLoader,
        openPopup,
        closePopup,
        setInsideListAddedByFuzebote,
        setInsideListCompanyEmail,
        setInsideListHasEmail,
        setInsideListPersonalEmail,
        setInsideListSingleVerifiedEmail,
        setInsideListDoubleVerifiedEmail,
        setInsideListHasLinkedIn,
        setInsideListHasPhone,
        setInsideListHasMailingAddress,
        setInsideListFromTo,
        clearAllInsideFilters,
        getIntegration,
        setErrorMessage,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        loader: state.loader,
        leadFilter: state.leadFilter,
        user: state.user,
        leadList: state.leadList,
        integration: state.integration,
        insideListFilters: state.insideListFilters,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(InsideList);
