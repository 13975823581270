import { API_KEY_PAGINATION, CREATE_AND_REVOKE, REVOKE_DATE_VAL, CREATE_DATE_VAL, SORT_BY, RECORDS_PER_PAGE } from "../constants/constant";



export function currentApiKeyPageNum(num) {
    return {
        type: API_KEY_PAGINATION,
        payload: num
    };
}
export function createDateVal(cdate) {
    return {
        type: CREATE_DATE_VAL,
        payload: cdate
    };
}
export function revokeDateVal(rdate) {
    return {
        type: REVOKE_DATE_VAL,
        payload: rdate
    };
}
export function createAndRevoke(crdate) {
    return {
        type: CREATE_AND_REVOKE,
        payload: crdate
    };
}
export function sortyBy(sort) {
    return {
        type: SORT_BY,
        payload: sort
    };
}
export function recordsPerPage(num) {
    return {
        type: RECORDS_PER_PAGE,
        payload: num
    };
}
