import axios from 'axios';
import {EmitterService} from "./services/emitterService";
// import Config from './app/config';
const GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL;

const instance = axios.create({
    // TODO: Need to work on baseUrl changes
    // http://ec2-54-206-43-164.ap-southeast-2.compute.amazonaws.com:8080/api';
    // http://localhost:8080/api';
    baseURL: GATEWAY_URL,
    timeout: 900000
});

instance.interceptors.request.use(request => {
    request.headers.authorization = 'Bearer ' + localStorage.getItem("token");
    request.headers['content-type'] = 'application/json';
    return request;
}, error => {
    return Promise.reject(error);
});

instance.interceptors.response.use(async response => {
    if(!response.config.url.includes("/core/info")) {
        EmitterService.emit("loadLeadsRemaining");
        // let response = await axios({
        //     method: 'get',
        //     baseURL: GATEWAY_URL,
        //     timeout: 300000,
        //     url: `/core/info`,
        //     headers: {
        //         Authorization: 'Bearer ' + localStorage.getItem("token") //the token is a variable which holds the token
        //     }
        // });
        // const user = response.data.data;
        // const account = user.accounts[0];
        // localStorage.setItem('user', JSON.stringify(user))
        // localStorage.setItem('userAccount', JSON.stringify(account))
    }
    const token = response.headers.x_auth_token;
    localStorage.setItem('token', token);
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        localStorage.clear();
        if(!window.location.href.includes("signin") && !window.location.href.includes("forgot-pass")) {
            window.location.href = window.location.origin;
        }
    }
    return Promise.reject(error);
});

export default instance;