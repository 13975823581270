export const yearsOfExperience = [
  {
    category: 0,
    label: "Less than 1 year",
    group: "Less than 1 year",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 1,
    label: "1 to 3 years",
    group: "1 to 3 years",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 2,
    label: "3 to 5 years",
    group: "3 to 5 years",
    include_tags: [""],
    exclude_tags: []
  },
  {
    category: 3,
    label: "5 to 10 years",
    group: "5 to 10 years",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 4,
    label: "10 to 20 years",
    group: "10 to 20 years",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 5,
    label: "More than 20 years",
    group: "More than 20 years",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 6,
    label: "Unknown",
    group: "Unknown",
    include_tags: [],
    exclude_tags: []
  }
];