import {
  LEAD_FILTER_FUZZBOT_STATUS,
  LEAD_FILTER_FUZZBOT_ACTIVE,
  LEAD_FILTER_FUZZBOT_PAUSE,
  LEAD_FILTER_FUZZBOT_NONE,
  LEAD_FILTER_FILTER_NAME,
  LEAD_FILTER_LIST_AT_MAX_CAPACITY,
  LEAD_FILTER_LIST_WITH_ZERO_LEADS,
  LEAD_FILTER_OWNER,
  LEAD_FILTER_OWNERS,
  LEAD_LIST_NAMES,
  LEAD_LIST_PAGINATION,
  LEADS_RECORDS_PER_PAGE,
  LEADS_CLEAR_PAGE_NUMBER,
  LEADS_SORT_BY,
  SET_ALL_CHECK_TO_FALSE,
  LEAD_NAME_FILTER_ACTION
} from '../constants/constant';

export function fuzzBotStatus(status) {
  return {
    type: LEAD_FILTER_FUZZBOT_STATUS,
    payload: status
  };
}

export function filterFuzzBotActive(status) {
  return {
    type: LEAD_FILTER_FUZZBOT_ACTIVE,
    payload: status
  };
}

export function filterFuzzBotPause(status) {
  return {
    type: LEAD_FILTER_FUZZBOT_PAUSE,
    payload: status
  };
}

export function filterFuzzBotNone(status) {
  return {
    type: LEAD_FILTER_FUZZBOT_NONE,
    payload: status
  };
}

export function filterName(status) {
  return {
    type: LEAD_FILTER_FILTER_NAME,
    payload: status
  };
}

export function filterListAtMaxCapacity(status) {
  return {
    type: LEAD_FILTER_LIST_AT_MAX_CAPACITY,
    payload: status
  };
}

export function filterWithZeroLeads(status) {
  return {
    type: LEAD_FILTER_LIST_WITH_ZERO_LEADS,
    payload: status
  };
}

export function filterOwnerStatus(status) {
  return {
    type: LEAD_FILTER_OWNER,
    payload: status
  };
}

export function saveOwnersLists(status) {
  return {
    type: LEAD_FILTER_OWNERS,
    payload: status
  };
}

export function saveListNames(listIDs) {
  return {
    type: LEAD_LIST_NAMES,
    payload: listIDs
  };
}


export function changeNameFilterAction(text) {
  return {
    type: LEAD_NAME_FILTER_ACTION,
    payload: text
  };
}
export function currentPageNum(num) {
  return {
    type: LEAD_LIST_PAGINATION,
    payload: num
  };
}
export function leadsRecordsPerPage(num) {
  return {
    type: LEADS_RECORDS_PER_PAGE,
    payload: num
  };
}
export function clearPageNumber(num) {
  return {
    type: LEADS_CLEAR_PAGE_NUMBER,
    payload: num
  };
}
export function leadSortyBy(sort) {
  return {
    type: LEADS_SORT_BY,
    payload: sort
  };
}
export function setAllCheckToFalse(val) {
  return {
    type: SET_ALL_CHECK_TO_FALSE,
    payload: val
  };
}

