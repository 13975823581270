import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import { toast } from 'react-toastify';
import $ from 'jquery';
import NoList from '../../components/ApiKeysBox/NoApiKeys';
import {
  SavedSearchesContent,
  SavedSearchesHeader,
  RightPanelBanner,
  Pagination,
  ConfirmationPopup,
  BreadCrumb
} from "../../components";

import { startLoader, stopLoader } from "../../actions/loader";
import { setErrorMessage } from "../../actions/error_msg";
import "./style.scss";
import { getSavedSearches, deleteSavedSearcheById } from "../../services/saveSearch";
import { getOwnersLists } from "../../services/leads";
import SaveSearchesRightFilter from "../../components/SavedSearchesBox/SaveSearchesRightFilter";
import { filterUsersByNameAndEmail } from "../../helpers/filterUsers";
import { LOCAL_STORAGE_KEYS } from "../../constants/constant";
import { EmitterService } from "../../services/emitterService";

class SavedSearches extends Component {
  container = React.createRef();

  state = {
    savedSearches: [],
    searchValue: '',
    totalSavedSearches: 0,
    recordsPerPage: 25,
    currentPageNum: 1,
    sortOrder: 'desc',
    sortingColumn: 'creation_date',
    showDropDown: false,
    showConfirmation: false,
    selectedItemId: null,
    newItems: [],
    ownersLists: [],
    ownersFilter: '',
    nameFilter: false,
    name: ''
  };

  componentWillMount() {
    EmitterService.emit("loadLeadsRemaining");
  }
  
  componentDidMount() {
    let recordsPerPage = localStorage.getItem('recordsPerPage');
    if (recordsPerPage === null) {
      localStorage.setItem('recordsPerPage', '25');
      recordsPerPage = localStorage.getItem('recordsPerPage');
    }

    this.ownersLists();
    this.setState({recordsPerPage: recordsPerPage});
    this.fetchSavedSearchesAndLoad(this.state.currentPageNum, this.state.recordsPerPage, this.state.sortingColumn, this.state.sortOrder);
    document.addEventListener("mousedown", this.clickEventHandle);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickEventHandle);
    this.props.setErrorMessage(false);
    this.setState({ ledListError: false });
  }

  clickEventHandle = (event) => {
    const { showDropDown } = this.state;
    if (this.container.current && !this.container.current.contains(event.target)) {
      if (showDropDown) {
        this.paginationPopup();
      }
    }
  }

  paginationPopup = async () => {
    this.setState({ showDropDown: !this.state.showDropDown })
  }

  switchSort = (name) => {
    let sortOrder = this.state.sortOrder;
    if (name === this.state.sortingColumn) {
      sortOrder = this.state.sortOrder === 'desc'? 'asc' : 'desc';
      this.setState({sortOrder});
    } else {
      sortOrder = 'desc';
      this.setState({sortOrder, sortingColumn: name});
    }
    this.fetchSavedSearchesAndLoad(this.state.currentPageNum, this.state.recordsPerPage, name, sortOrder);
  }

  fetchSavedSearchesAndLoad = async (pageNum, recordsPerPage, sortColumn, sortOrder) => {
    this.props.startLoader(true);
    try {
      await this.loadSavedSearches(pageNum, recordsPerPage, sortColumn, sortOrder, this.state.ownersFilter, this.state.name);
    } catch (error) {
      //this.props.setErrorMessage(true);
      const toastId = toast('Unable to load Saved Searches', {
          className: "toast-error-div",
          type: toast.TYPE.ERROR
      });
      toast.done(toastId)
      $(".Toastify").addClass("mes-done");
      setTimeout(function () {
          toast.dismiss(toastId)
          $(".Toastify").removeClass("mes-done");
      }, 3000)
      this.setState({ searchResultError: true, });
    }
    this.props.stopLoader(false);
  }

  loadSavedSearches = async (pageNum, recordsPerPage, sortColumn, sortOrder, ownersFilter, nameFilter) => {
    const savedSearchesRes = await getSavedSearches(pageNum, recordsPerPage, sortColumn, sortOrder, ownersFilter, nameFilter);
    const allLoadedSavedSearches = savedSearchesRes.data.data.saved_searches;
    const totalSavedSearches = savedSearchesRes.data.data.total_saved_searches;
    this.setState({savedSearches: allLoadedSavedSearches, totalSavedSearches});
  }


  editSearchTraits = (item) => {
    if (item.query.people_lookup) {
      this.props.history.push({
        pathname: '/lead-search/account-based',
        search: `?action=edit_save_search&id=${item.id}&sname=${item.name}`
      });
    } else if (item.query.people_prospector) {
      this.props.history.push({
        pathname: '/lead-search/market-based',
        search: `?action=edit_save_search&id=${item.id}&sname=${item.name}`
      });
    }
  }

  showSearchResults = (item) => {
    if (item.query.people_lookup) {
      this.props.history.push({
        pathname: '/lead-search/account-based',
        search: `?action=preview_save_search&id=${item.id}&sname=${item.name}`
      });
    } else if (item.query.people_prospector) {
      this.props.history.push({
        pathname: '/lead-search/market-based',
        search: `?action=preview_save_search&id=${item.id}&sname=${item.name}`
      });
    }
  }

  deleteSavedSearch = async () => {
    let toastId = toast('Deleting saved search', { hideProgressBar: true, autoClose: 10000 });

    try {
      await deleteSavedSearcheById(this.state.selectedItemId);
    } catch (error) {
      this.props.setErrorMessage(true);
      this.setState({ searchResultError: true, });
    }

    toast.update(toastId, {
      render: 'Saved search deleted successfully',
      className: "toast-success-div",
      type: toast.TYPE.SUCCESS,
      hideProgressBar: true,
      autoClose: 2000,
    });

    this.setState({showConfirmation: false, selectedItemId: null});
    this.fetchSavedSearchesAndLoad(this.state.sortingColumn, this.state.sortOrder);
  }

  paginate = async (num) => {
    window.scrollTo(0, 0);
    this.setState({currentPageNum: num});
    this.fetchSavedSearchesAndLoad(num, this.state.recordsPerPage, this.state.sortingColumn, this.state.sortOrder);
  }
  incOrDecNum = (sign, indexOfLastPage) => {
      let currentPage = this.state.currentPageNum;
      if (sign === "-" && currentPage > 1) {
          currentPage = currentPage - 1
          this.paginate(currentPage)
      } else if (sign === "+" && currentPage < indexOfLastPage) {
          currentPage = currentPage + 1
          this.paginate(currentPage)
      }
  }
  updatePerPage = async (sign, num) => {
    let integer = parseInt(num);
    if (sign === "+" && integer < 100) {
      this.setState({recordsPerPage: integer + 25, currentPageNum: 1});
      localStorage.setItem('recordsPerPage', integer + 25);
      this.fetchSavedSearchesAndLoad(1, integer * 2, this.state.sortingColumn, this.state.sortOrder);
    } else if (sign === "-" && integer > 25) {
      this.setState({recordsPerPage: integer - 25, currentPageNum: 1});
      localStorage.setItem('recordsPerPage', integer - 25);
      this.fetchSavedSearchesAndLoad(1, integer/2, this.state.sortingColumn, this.state.sortOrder);
    } else if (sign === '') {
        this.setState({ showDropDown: !this.state.showDropDown, recordsPerPage: integer, currentPageNum: 1 });
        localStorage.setItem('recordsPerPage', integer);
        this.fetchSavedSearchesAndLoad(1, integer, this.state.sortingColumn, this.state.sortOrder);
    }
  }


  ownersLists() {
    var that = this;
    const { startLoader, stopLoader } = this.props;
    const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
    startLoader(true);
    if (userId !== null) {
      getOwnersLists(userId.id).then(function (response) {
        that.setState({ ownersLists: response.data.data, newItems: response.data.data });
      }).catch(error => {
      });
    }
    stopLoader(false);

  }

  searchOwnerFilter = (e) => {
    const { newItems } = this.state;
    const value = e.target.value;
    this.setState({ searchValue: value, ownersLists: filterUsersByNameAndEmail(newItems, value) });
  }

  toggleNameFilter = () => {
    this.setState({nameFilter: !this.state.nameFilter, name: ''});
  }

  changeName = (e) => {
    this.setState({name: e.target.value});
  }

  applyFilter = async () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      this.fetchSavedSearchesAndLoad(this.state.currentPageNum, this.state.recordsPerPage, this.state.recordsPerPage, this.state.sortingColumn, this.state.sortOrder);
    }, 200);
  }
  clearAllFilter = async () => {
    this.setState({ownersFilter: ''});
    this.clearOwnersFilter();
    setTimeout(() => {
      this.fetchSavedSearchesAndLoad(this.state.currentPageNum, this.state.recordsPerPage, this.state.recordsPerPage, this.state.sortingColumn, this.state.sortOrder);
    }, 200);
  }

  clearOwnersFilter = () => {
    const { ownersLists } = this.state
    for (let i = 0; ownersLists.length > i; i++) {
      if (ownersLists[i].check) {
        ownersLists[i].check = !ownersLists[i].check;
      }
    }
    this.setState({ownersLists: [...ownersLists]});
  }

  changeCheck = async (i, id) => {
    const { ownersLists } = this.state;
    ownersLists[i].check = !ownersLists[i].check;
    this.setState({ ownersLists: ownersLists });
    let ownersFilter
    if (ownersLists[i].check) {
      ownersFilter = (this.state.ownersFilter? [this.state.ownersFilter, id] : [id]).join(",")
    }
    else {
      const arr = this.state.ownersFilter.split(",")
      ownersFilter = arr.filter(function (el) {
        return el !== id
      })
      ownersFilter = ownersFilter.join(",")
    }
    this.setState({ownersFilter: ownersFilter});
  }


  showConfirmationPopup = (selectedItemId) => {
    this.setState({showConfirmation: true, selectedItemId: selectedItemId});
  }

  closeConfirmationPopup = () => {
    this.setState({showConfirmation: false, selectedItemId: null});
  }

  render() {
    const userId = JSON.parse(localStorage.getItem("user"));
    const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT));

    const links = [
      {
        name: 'Lead Search',
        slug: '/lead-search/market-based',
      },
      {
        name: 'Saved Searches',
        slug: null,
      },
    ]

    const isUserAdmin = Boolean(userId && userId.is_admin);
    const { searchError } = this.state;

    return (
      <div className="page-content">
        <BreadCrumb links={links}></BreadCrumb>
      <div className="savedSearches-panel">
        {userId === null && this.props.history.push("/signin?path=/lead-search/saved-searches")}

        {userAccount.status === "trial_expired" && this.props.history.push('/billing-and-users')}

        

        {(this.props.user.errorDiv && searchError) &&
          <div className="error-div alert-div">
            <img className="add-list__close" src="/assest/apiKeys/Close_red.svg" alt="" onClick={() => this.setState({ searchError: !searchError })} />
            <p>Please select at least one filter to search leads.</p>
          </div>
        }

        <div className="savedSearches-panel__left">

          <div className="saved_searches">
            <SavedSearchesHeader
              sortOrder={this.state.sortOrder}
              sortingColumn={this.state.sortingColumn}
              switchSort={this.switchSort}/>

            <div>
              {(!this.props.loader.loading && !this.state.savedSearches.length) &&
                <NoList
                  msg={"You haven't saved any searches yet."}
                  submsg={`Start with a new market-based or account-based search and save their criteria to a saved search.`} />
              }
            </div>

            <SavedSearchesContent
              loader={this.props.loader}
              savedSearches={this.state.savedSearches}
              editSearchTraits={this.editSearchTraits}
              showSearchResults={this.showSearchResults}
              container={this.container}
              showDeleteConfirmationPopup={this.showConfirmationPopup} />


            {this.state.showConfirmation &&
              <ConfirmationPopup
                title="Delete Saved Search"
                message="Are you sure you want to delete this record?"
                onYesClick={this.deleteSavedSearch}
                onNoClick={this.closeConfirmationPopup}
                onCloseClick={this.closeConfirmationPopup} />
            }



            { // && this.state.totalSavedSearches > this.state.recordsPerPage 
            this.state.savedSearches && this.state.savedSearches.length > 0 &&
              <Pagination
                  totalLists={this.state.totalSavedSearches}
                  recordsPerPage={this.state.recordsPerPage}
                  currentPageNum={this.state.currentPageNum}
                  paginate={this.paginate}
                  incOrDecNum={this.incOrDecNum}
                  updatePerPage={this.updatePerPage}
                  paginationPopup={this.paginationPopup}
                  showDropDown={this.state.showDropDown}
                  container={this.container} />
            }
          </div>
        </div>

        <div className="savedSearches-panel__right">
          <RightPanelBanner title={"Saved Searches Demo"} page="savedSearches"  type="saved_searches" video_id="nde2tdcpoa"/>


          <SaveSearchesRightFilter
            isUserAdmin={isUserAdmin}
            searchValue={this.state.searchValue}
            applyFilter={this.applyFilter}
            clearAllFilter={this.clearAllFilter}
            ownersLists={this.state.ownersLists}
            searchOwnerFilter={this.searchOwnerFilter}
            changeCheck={this.changeCheck}
            nameFilter={this.state.nameFilter}
            toggleNameFilter={this.toggleNameFilter}
            name={this.name}
            changeName={this.changeName}
          />
        </div>

      </div>
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // role,
      startLoader,
      stopLoader,
      setErrorMessage,
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    user: state.user,
    loader: state.loader
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SavedSearches);
