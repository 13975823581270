const items = [
  {
    name: 'Person',
    clname: 'person',
    sortName: 'person',
    sort: true,
  },
  {
    name: 'Company',
    clname: 'comp',
    sortName: 'company',
    sort: true,
  },
  {
    name: 'Contact info',
    clname: 'info',
    sort: false,
  },
  {
    name: 'Date Added',
    clname: 'added',
    sortName: 'updated_at',
    sort: true,
  }
]

export default items