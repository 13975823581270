import React, { Component } from 'react'
import '../style.scss'

class CouponFilter extends Component{
  state = {
    check: false
  }

  changeCheck = () =>{
    this.setState({check: !this.state.check})
  }

  render(){
    const { check } = this.state
    return(
      <div className="coupon-filter">
        <p className="coupon-filter__title">Filters</p> 
        <label className="coupon-cont__label">
          <p>Coupon Date created</p>
          <input type="text" placeholder="MM-DD-YYYY"/>
          <img src="/assest/cal.svg" alt=""/>
        </label>
        <div className="coupon-cont__check">
          <div className={`check ${check ? 'active' : ''}`} onClick={this.changeCheck}></div>
          <p className={`${check ? 'active' : ''}`}>Stripe</p>
        </div>
      </div>
    )
  }
}

export default CouponFilter