import { IGNORE_LIST_PAGINATION, IGNORE_LIST_RECORDS_PER_PAGE } from "../constants/constant";


export function ignoreListrecordsPerPage(num) {
    return {
        type: IGNORE_LIST_RECORDS_PER_PAGE,
        payload: num
    };
}

export function currentIgnoreListPageNum(num) {
    return {
        type: IGNORE_LIST_PAGINATION,
        payload: num
    };
}