import { BILLING_ACTIONS } from "../constants/constant";

const INITIAL_STATE = {
  billingOverview: undefined,
};

export default function billing(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BILLING_ACTIONS.SET_BILLING_OVERVIEW:
        return {
          ...state,
          billingOverview: action.payload
        };
    case BILLING_ACTIONS.CLEAR_ALL:
      return INITIAL_STATE;
    default:
      return {
        ...state
      };
  }
}
