import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss'
import { getUserActivity } from '../../../services/profile';
import { startLoader, stopLoader } from '../../../actions/loader';
import { setErrorMessage } from '../../../actions/error_msg';
import actions from './ actions';
import { toast } from 'react-toastify';
import { LOCAL_STORAGE_KEYS } from '../../../constants/constant';
import {
  GreenBanner,
} from '../../../components'
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

class ProfileRight extends Component {

  constructor(props) {
    super(props);
    this.state = {
      list: null,
      err: false
    }
  }

  componentDidMount = () => {
    this.Activity()
  }
  Activity = async () => {
    const { startLoader, stopLoader } = this.props
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
    const { list } = this.state

    startLoader(true)
    if (!list) {
      try {
        const res = await getUserActivity(user.id)
        this.setState({ list: res.data.data.sort((a, b) => (a.date > b.date || !a.date) ? 1 : -1) })
      } catch (error) {
        toast.error('Sorry, we were unable to get your activity. Please try again later', {
          className: "toast-error-div",
          autoClose: 4000
        })
      }
      stopLoader(false)
    }
  }
  render() {
    const { list } = this.state
    const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT))

    let incomplete = false;
    if(list) {
      list.map(item => {
        if(!item.date) {
          incomplete = true;
        }
      })
    }
    
    return (
      <section className="pro-actions">
        {userAccount.status === "active_trial" && incomplete && <GreenBanner />}
        {userAccount.status === "active_trial" && !incomplete && <GreenBanner msg="Congratulations! You completed all tasks and received 25 free additional lead credits."/>}
        <div className="pro-actions__list">
          {list &&
            list.map((item, index) =>
              <div className={`pro-actions__item ${item.date ? 'opacity' : ''}`} key={index}>
                <div className="pro-actions__num">{!item.date ? (index < 10 ? '0' + (index + 1) : index + 1) : <img src="/assest/accountBased/check-orange.svg" alt="" />}</div>
                <h4 className="pro-actions__action">{actions.get(item.name)[0]}</h4>
                <p className="pro-actions__descr">{actions.get(item.name)[1]}</p>
              </div>
            )
          }
        </div>
      </section>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    loader: state.loader,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    startLoader,
    stopLoader,
    setErrorMessage,
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileRight)
