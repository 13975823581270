import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { AddGroups, Field, EditGroups } from "../../FieldsDropdown";
import AddLocationGroup from "../AddLocationGroup";
import EditLocationGroups from "../EditLocationGroups";
import AddTechnologyGroup from "../AddTechnologyGroup";
import LocationField from "../LocationField";
import { LocationType } from "../../../../../constants/constant";
import { EmitterService } from "../../../../../services/emitterService";

let newSelectedGroup = null;
class Functions extends Component {
  state = {
    i: 0,
    value: "",
    addGroupPanel: false,
    editGroupsPanel: false,
    fieldPanel: false,
    roleGroups: this.props.groups, //исходный массив в обычном виде
    excludeTag: [],
    includeTag: [],
    includeTag2: [],
    excludeTag2: [],
    selectedGroups: [], //массив выбранных групп в обычном виде
    finishRoleGroups: [], //исходный переделаный массив
    filteredRoleGroups: [],
    finishRoleGroupsSelected: [], //массив выбранных групп в изменном виде
    notField: false,
    stateGroup: false,
    showMessage: false,
    showExclude: false,
    excludeOption: false,
    selectedLocations: [],
    locationOptions: [],
    hasMore: true,
    showAll: false,
    technologyGroups: [],
    technologyLength: 0,
    /* technology: {
      include: {names: [], groups: []},
      exclude: {names: [], groups: []},
    }, */
  };

  constructor(props) {
    super(props);

 

  }



  loadMore = () => {};

  componentDidMount() {

    const { roleGroups } = this.state;
    this.sort(roleGroups);
    document.addEventListener("click", this.handleClickOutside);
    this.inputRef = React.createRef();
    // const includeTag, excludeTag, selectedGroups = [];

    // if (this.props.includeKeywords && this.props.includeKeywords.length) {
    //   includeTag = this.props.includeKeywords;
    // }
  }

  componentWillUpdate() {


    const { clearFields, updateClearFields, groups, includedTags } = this.props;
    // if(marketBased.industries2 !== null){
    //   if(marketBased.industries2.include_keywords){
    //     if(marketBased.industries2.exclude_keywords){
    //       this.setState({ excludeTag2: marketBased.industries2.exclude_keywords,
    //         includeTag2: marketBased.industries2.include_keywords
    //       });

    //     }else {
    //       this.setState({ 
    //         includeTag2: marketBased.industries2.include_keywords
    //       });
    //     }
    //   }

    // }
    
    if (clearFields) {
      updateClearFields();
      this.setState({
        i: 0,
        value: "",
        addGroupPanel: false,
        editGroupsPanel: this.state.editGroupsPanel,
        fieldPanel: false,
        roleGroups: groups,
        excludeTag: [],
        includeTag: includedTags ? includedTags : [],
        selectedGroups: [],
        finishRoleGroups: [],
        finishRoleGroupsSelected: [],
        notField: false,
        stateGroup: false,
        showMessage: false,
        excludeOption: false,
        selectedLocations: [],
        locationOptions: [],
        showAll: false,
      });
      this.sort(groups);
      return true;
    } else if (
      includedTags &&
      includedTags.length !== this.state.includeTag.length
    ) {

      this.setState({
        includeTag: includedTags,
      });
      return true;
    }
    // this.updateSelectedGroups();
    EmitterService.subscribe("startSearching", () => {
      if (this.props.customInput && this.state.value) {
        this.addExcludeTag(this.state.value);
      }
    });
    
  }

  componentDidUpdate(prevProps, prevState) {
  

    this.updateSelectedGroups(prevProps);
  }

  updateSelectedGroups = (props) => {

    const { selectedGroups, includeKeywords, excludeKeywords } = props;

    if (
      Boolean(
        selectedGroups &&
          selectedGroups.length !== this.state.finishRoleGroupsSelected.length
      ) ||
      Boolean(
        includeKeywords &&
          includeKeywords.length !== this.state.includeTag.length
      ) ||
      Boolean(
        excludeKeywords &&
          excludeKeywords.length !== this.state.excludeTag.length
      )
    ) {
      this.setState({
        excludeTag: excludeKeywords ? excludeKeywords : this.state.excludeTag,
        includeTag: includeKeywords ? includeKeywords : this.state.includeTag,
        filteredRoleGroups: selectedGroups
          ? this.state.finishRoleGroups.filter((item) => {
              return !selectedGroups.find((selected) => {
                return selected.label == item.label;
              });
            })
          : this.state.filteredRoleGroups,
        finishRoleGroupsSelected: selectedGroups
          ? selectedGroups
          : this.state.finishRoleGroupsSelected,
        selectedGroups: selectedGroups
          ? selectedGroups
          : this.state.finishRoleGroupsSelected,
        selectedLocations: selectedGroups
          ? selectedGroups
          : this.state.finishRoleGroupsSelected,
        fieldPanel:
          !this.state.addGroupPanel && !this.state.editGroupsPanel
            ? true
            : this.state.fieldPanel,
      });
      // this.sort(this.state.roleGroups);

    }
  };

  handleClickOutside = (e) => {
    if (this.inputRef.current && !this.inputRef.current.contains(e.target)) {
      this.setState({
        addGroupPanel: false,
        editGroupsPanel: false,
        fieldPanel: true,
        showAll: false,
      });
    }
  };

  getValue = (e) => {
    const value = e.target.value;
    if (
      (this.props.customInput || this.props.name === "keywords") &&
      value.length === 1
    ) {
      this.openPanel("add");
    }
    this.setState({ value: value });
    if (value === "") {
      this.sort(this.state.roleGroups);
    } else {
      this.searchTag(value);
    }
  };

  openPanel = (index) => {
    document.addEventListener("click", this.handleClickOutside);
    if (index === "add") {
      document.removeEventListener("click", this.handleClickOutside);
      this.setState({
        addGroupPanel: true,
        editGroupsPanel: false,
        fieldPanel: false,
      });
      setTimeout(() => {
        this.setState({ showAll: true });
      }, 1000);
      // document.addEventListener("click", this.handleClickOutside);
    } else if (index === "edit") {
      // ici
      if (
        this.props.name === "role" ||
        this.props.name === "location" ||
        this.props.name === "employee" ||
        this.props.name === "technologies"
      ) {
        document.removeEventListener("click", this.handleClickOutside);
        this.setState({
          addGroupPanel: false,
          editGroupsPanel: !this.state.editGroupsPanel,
          showAll: false,
        });
      }
    } else if (index === "field") {
      this.setState({ addGroupPanel: false, fieldPanel: true, showAll: false });
    } else {
      document.removeEventListener("click", this.handleClickOutside);
      this.setState({
        fieldPanel: false,
        editGroupsPanel: false,
        showAll: false,
      });
    }
    document.addEventListener("click", this.handleClickOutside);
  };

  searchTag = (input) => {

    const { finishRoleGroups } = this.state;
    let items = [];
    finishRoleGroups.forEach((item) => {
      if (item.label.toLowerCase().includes(input.toLowerCase())) {
        items.push(item);
        // this.setState({ roleGroups: items });
      } else if (this.props.name !== "technologies") {
        let existsInIncludeTags = false;
        let existsInExcludeTags = false;
        item.tags.forEach((inct) => {
          if (inct.toLowerCase().includes(input.toLowerCase())) {
            existsInIncludeTags = true;
          }
        });
        if (!existsInIncludeTags) {
          item.excludeTags.forEach((exct) => {
            if (exct.toLowerCase().includes(input.toLowerCase())) {
              existsInExcludeTags = true;
            }
          });
        }
        if (existsInIncludeTags || existsInExcludeTags) {
          items.push(item);
          // this.setState({ finishRoleGroups: items });
        }
      } else {
        let existsInTech = false;
        item.technologies.map((tech) => {
          if (tech.toLowerCase().includes(input.toLowerCase())) {
            existsInTech = true;
          }
        });
        if (existsInTech) {
          items.push(item);
        }
      }
    });
    this.setState({ filteredRoleGroups: items });
  };

  addExcludeTag = (val, role) => {
    const { excludeTag, includeTag} = this.state;
    const { setExcludeTags, setIncludeTags, name } = this.props;
    // if (role === "exclude") {
    let trimmedVal = val.trim();
      if (
        this.state.excludeOption ||
        (trimmedVal.startsWith("-") &&
          (this.props.name === "keywords" ||
            this.props.name === "role" ||
            this.props.name === "skills" ||
            this.props.name === "location" ||
            this.props.name === "industries2" ||
            this.props.name === "department"))
      ) {
        if( this.props.name === "location"){
          this.addGroupSelect({ type: LocationType.Keyword, keyword: val, excludeGroup: this.state.excludeOption });

        }
        else {if (trimmedVal.startsWith("-")) {
          trimmedVal = val.substr(1, trimmedVal.length);
        }
        if (!excludeTag.includes(trimmedVal)) {
          excludeTag.push(trimmedVal);
          setExcludeTags(excludeTag, name);
        }}
      } else {
        if (trimmedVal.startsWith("-")) {

          trimmedVal = val.substr(1, trimmedVal.length);
        }
        if (name === "location") {
          this.addGroupSelect({ type: LocationType.Keyword, keyword: val, excludeGroup: this.state.excludeOption });
        } else {
          //trimmedVal = trimmedVal.toLowerCase();
          // trim the tag not to include https://www
          if (trimmedVal.startsWith("https://www.")) {
            trimmedVal = trimmedVal.slice(12);
          }
          if (trimmedVal.startsWith("http://www.")) {
            trimmedVal = trimmedVal.slice(11);
          } else if (trimmedVal.startsWith("https://")) {
            trimmedVal = trimmedVal.slice(8);
          } else if (trimmedVal.startsWith("http://")) {
            trimmedVal = trimmedVal.slice(7);
          }
          if (!includeTag.includes(trimmedVal)) {
            includeTag.push(trimmedVal);
            setIncludeTags(includeTag, name);
          }
        }
      }
      this.setState({
        excludeTag: excludeTag,
        value: "",
        filteredRoleGroups: this.state.finishRoleGroups,
        includeTag: includeTag,
      });

      this.openPanel("field");
    
  };

  dellExcludeTag = (item, e, role) => {
    const { name, setExcludeTags, setIncludeTags } = this.props;
    e.stopPropagation();
    document.removeEventListener("click", this.handleClickOutside);
    const { excludeTag, includeTag } = this.state;
   
      if (role === "exclude") {
        const exclArr = excludeTag.filter((el) => {
          return item !== el;
        });
        this.setState({ excludeTag: exclArr });
        setExcludeTags(exclArr, name);
      } else {
        const inclArr = includeTag.filter((el) => {
          return item !== el;
        });
        this.setState({ includeTag: inclArr });
        setIncludeTags(inclArr, name);
      }
    
    document.addEventListener("click", this.handleClickOutside);
  };

  //сортировка по категории
  sort = (groups) => {
    const { name, setGroups } = this.props;
    let sortArrow = groups;
    if (name !== "employee" && name !== "adwords") {
      sortArrow = groups.sort((a, b) => {
        let index;
        if (a.category > b.category) {
          index = 1;
        } else if (a.category < b.category) {
          index = -1;
        } else {
          index = 0;
        }
        return index;
      });
    }

    if (name === "adwords") {
      sortArrow = groups.sort((a, b) => {
        let index;
        if (a.group > b.group) {
          index = 1;
        } else if (a.group < b.group) {
          index = -1;
        } else {
          index = 0;
        }
        return index;
      });
    }

    let lastCategory = null;
    let index = -1;
    const finishArrow = [];

    sortArrow.forEach((el) => {
      if (el.include_tags != null) {
        finishArrow.push({
          category: el.category,
          group: el.group,
          label: el.label,
          tags: el.include_tags,
          excludeTags: [...el.exclude_tags],
          technologies: el.technologies,
          open: el.open,
          // this.state.stateGroup === el.category ? true : false,
          showGroup: el.showGroup == null ? true : el.showGroup,
          showTechName: el.showTechName == null ? true : el.showTechName,
          excludeGroup: el.excludeGroup == null ? false : el.excludeGroup,
          excludeTech: el.excludeTech == null ? null : el.excludeTech,
          /* isTechName: el.isTechName,
          techName: el.techName,
          excluded: el.excluded,
          isGroup: el.isGroup, */
        });
      } else {
        if (el.category !== lastCategory) {
          finishArrow.push({
            category: el.category,
            label: el.label,
            tags: [...el.tags],
            excludeTags: [...el.excludeTags],
            group: el.group,
            /* technologies: el.technologies,
            isTechName: el.isTechName,
            techName: el.techName,
            excluded: el.excluded,
            isGroup: el.isGroup, */
            open: el.open,
            // open: this.state.stateGroup === el.category ? true : false
          }); // добавление в массив объекта по категории
          index++;
        } else if (el.category === lastCategory) {
          finishArrow[index].tags.push(el.tag); // добавление тегов в массив tags
        }
        lastCategory = el.category;
      }
    });
    /*if (finishArrow.length > 0) {
      finishArrow[0].open = true;
    }*/
    if (groups === this.state.selectedGroups) {
      this.setState({ finishRoleGroupsSelected: finishArrow });
      setGroups(name, finishArrow);
    } else if (groups === newSelectedGroup) {
      this.setState({
        finishRoleGroupsSelected: finishArrow,
        selectedGroups: groups,
      });
      setGroups(name, finishArrow);
    } else {
      this.setState({
        finishRoleGroups: finishArrow,
        filteredRoleGroups: finishArrow,
        roleGroups: groups,
      }); // отсортированый исходный массив и преобразованый массив
    }
  };

  isTagIncludedInGroup = (selectedGroup, tag) => {
    const ind = selectedGroup.include_tags.findIndex(
      (selectedTag) => selectedTag === tag
    );
    return ind > -1;
  };

  isTagExcludedInGroup = (selectedGroup, tag) => {
    const ind = selectedGroup.exclude_tags.findIndex(
      (selectedTag) => selectedTag === tag
    );
    return ind > -1;
  };

  //добавление группы в выбранные
  addGroupSelect = (cat, isTechName, techName, role, includeOnlyTag) => {
    const { roleGroups, selectedGroups } = this.state;
    const { name } = this.props;
    let i = 0;
    let index = 0;
    let addedGroup = null;
    let existsInSelectedTechNames = false;
    let existsInSelectedGroups = false;
    let technologyNamesCount = 0;

    if (name === "technologies") {
      const alreadyExists = selectedGroups.find(
        (item) => item.category === cat
      );
      const updatedSelectedGroups = [...selectedGroups];
      const existingTech = roleGroups.find((item) => item.category === cat);
      if (!alreadyExists) {
        updatedSelectedGroups.push({
          ...existingTech,
          group: existingTech ? existingTech.group : cat,
          category: cat,
          technologies: existingTech ? [...existingTech.technologies] : [],
          label: techName,
          isTechName,
          techName,
          excludeGroup: this.state.excludeOption,
          isGroup: !isTechName,
        });
      }
      this.setState({
        value: "",
        filteredRoleGroups: this.state.finishRoleGroups,
        finishRoleGroupsSelected: updatedSelectedGroups,
        selectedGroups: updatedSelectedGroups,
        addGroupPanel: false,
        showAll: false,
      });
      this.props.setGroups(name, updatedSelectedGroups);
      // this.sort(updatedSelectedGroups);
      this.openPanel("field");
      // this.addTechnologyName(cat, techName, role)
    } else if (name === "location") {
      const foundAlready = this.state.selectedLocations.find(
        (loc) => loc.keyword === cat.keyword
      );
      if (!foundAlready) {
        const updatedLocations = [...this.state.selectedLocations];
        
        
        updatedLocations.push(cat);
        this.setState({
          selectedLocations: updatedLocations,
          addGroupPanel: false,
          value: "",
          showAll: false,
        });

        this.props.setGroups(name, updatedLocations, this.props.type);
      }
    } else {
      if (name === "skills" || name === "industries2" || name == "department") {
        this.addExcludeTag(cat);
        return;
      }

      let filterFromRoleGroups = false;
      selectedGroups.forEach((sg) => {
        if (
          sg.category === cat &&
          (sg.include_tags.length > 0 || sg.exclude_tags.length > 0)
        ) {
          if (!sg.technologies) {
            existsInSelectedGroups = true;
            index = i;
          } else if (sg.technologies.length > 0) {
            existsInSelectedTechNames = true;
            technologyNamesCount = sg.technologies.length;
          }
          // } else if (!includeOnlyTag) {
          //   existsInSelectedGroups = true;
        }
        i++;
      });
      roleGroups.forEach((item) => {
        if (item.category === cat) {
          let includeIndex = -1;
          let excludeIndex = -1;
          includeIndex = item.include_tags
            ? item.include_tags.findIndex((tag) => tag === includeOnlyTag)
            : -1;
          excludeIndex = item.exclude_tags
            ? item.exclude_tags.findIndex((tag) => tag === includeOnlyTag)
            : -1;

          if (existsInSelectedGroups) {
            addedGroup = selectedGroups[index];
            if (includeIndex > -1) {
              selectedGroups[index].include_tags &&
                selectedGroups[index].include_tags.push(includeOnlyTag);
            } else if (excludeIndex > -1) {
              selectedGroups[index].exclude_tags &&
                selectedGroups[index].exclude_tags.push(includeOnlyTag);
            } else {
              selectedGroups[index].include_tags &&
                selectedGroups[index].include_tags.push(...item.include_tags);
              selectedGroups[index].exclude_tags &&
                selectedGroups[index].exclude_tags.push(...item.exclude_tags);
            }
          } else {
            if (item.technologies != null) {
              if (
                item.technologies.length === technologyNamesCount &&
                existsInSelectedTechNames
              ) {
                filterFromRoleGroups = true;
              }
              let itemToPush = Object.assign({}, item);
              itemToPush.technologies = [];
              if (role === "exclude") {
                itemToPush.excludeGroup = true;
              }
              selectedGroups.push(itemToPush);
              addedGroup = itemToPush;
            } else {
              let itemToPush = Object.assign({}, item);
              if (includeIndex > -1) {
                itemToPush.include_tags = [includeOnlyTag];
                itemToPush.exclude_tags = [];
              } else if (excludeIndex > -1) {
                itemToPush.exclude_tags = [includeOnlyTag];
                itemToPush.include_tags = [];
              }
              selectedGroups.push(itemToPush);
              addedGroup = itemToPush;
            }
          }
        } /* else if (techName) {
         const alreadyExists = selectedGroups.find(item => item.category === cat);
         if (!alreadyExists) {
           selectedGroups.push({category: cat, isTechName, techName, excluded: role === 'exclude', isGroup: !!isTechName});
         }
        } */
      });
      const newRoleGroup = [];
      roleGroups.forEach((element) => {
        if (element.category !== cat) {
          newRoleGroup.push(element);
        } else {
          if (includeOnlyTag) {
            let elementCopy = Object.assign({}, element);
            elementCopy.include_tags = [];
            elementCopy.exclude_tags = [];
            element.include_tags.forEach((elem) => {
              if (!this.isTagIncludedInGroup(addedGroup, elem)) {
                elementCopy.include_tags.push(elem);
              }
            });
            element.exclude_tags.forEach((elem) => {
              if (!this.isTagExcludedInGroup(addedGroup, elem)) {
                elementCopy.exclude_tags.push(elem);
              }
            });
            if (
              elementCopy.include_tags.length ||
              elementCopy.exclude_tags.length
            ) {
              newRoleGroup.push(elementCopy);
            }
          }
          if (this.props.name === "technologies") {
            element.showGroup = false;
            element.showTechName = false;
            if (!filterFromRoleGroups) {
              element.showTechName = true;
              newRoleGroup.push(element);
            }
          }
        }
      });

      this.sort(newRoleGroup);
      this.sort(selectedGroups);
      this.openPanel("field");
      this.setState({ value: "" });
    }
  };

  //удаление группы из выбраных
  delGroupSelect = (e, cat, isExcludeTag, isTechName, techName) => {

    document.removeEventListener("click", this.handleClickOutside);
    e.stopPropagation();
    const { roleGroups, selectedGroups, finishRoleGroupsSelected } = this.state;
    let i = 0;
    let index = 0;
    let existsInRoleGroups = false;
    if (this.props.name === "technologies") {
      newSelectedGroup = selectedGroups.filter(
        (element) => element.category !== cat
      );
      const keepOpened = newSelectedGroup.length ? true : false;
      this.setState({
        finishRoleGroupsSelected: newSelectedGroup,
        selectedGroups: newSelectedGroup,
        // addGroupPanel: keepOpened
      });
      this.props.setGroups(this.props.name, newSelectedGroup);
      // this.sort(updatedSelectedGroups);
      // this.openPanel("field");
      // this.sort(roleGroups);
      // this.sort(newSelectedGroup);
      // this.delTechnologyName(cat, isExcludeTag, techName)
    } else if (this.props.name === "location") {
      const filteredLocations = this.state.selectedLocations.filter(
        (loc) => loc.keyword !== cat.keyword
      );
      this.setState({ selectedLocations: filteredLocations });
      this.props.setGroups(this.props.name, filteredLocations, this.props.type);
    } else {
      const selected = selectedGroups.find((gr) => gr.category === cat);
      roleGroups.forEach((rg) => {
        if (
          rg.category === cat &&
          (rg.include_tags.length > 0 || rg.exclude_tags.length > 0)
        ) {
          existsInRoleGroups = true;
          index = i;
        } else if (
          rg.category === cat &&
          (!selected.include_tags || !selected.include_tags.length) &&
          (!selected.exclude_tags || !selected.exclude_tags.length)
        ) {
          existsInRoleGroups = true;
        }
        i++;
      });
      selectedGroups.forEach((item) => {
        if (item.category === cat) {
          if (existsInRoleGroups) {
            if (item.technologies && item.technologies.length === 0) {
              roleGroups[index].showGroup = true;
            } else {
              const intags = new Set();
              item.include_tags &&
                item.include_tags.forEach((item) => intags.add(item));
              roleGroups[index].include_tags.forEach((item) =>
                intags.add(item)
              );
              const extags = new Set();
              item.exclude_tags &&
                item.exclude_tags.forEach((item) => extags.add(item));
              roleGroups[index].exclude_tags.forEach((item) =>
                extags.add(item)
              );
              roleGroups[index].include_tags = Array.from(intags);
              roleGroups[index].exclude_tags = Array.from(extags);
            }
          } else {
            if (item.technologies && item.technologies.length === 0) {
              let itemToPush = Object.assign({}, item);
              item.showGroup = true;
              item.showTechName = false;
              roleGroups.push(itemToPush);
            } else {
              /* if(!item.include_tags || item.include_tags.length) {
                item.include_tags = [];
              } */
              roleGroups.push(item);
            }
          }
        }
        //roleGroups.push(item)
      });
      newSelectedGroup = finishRoleGroupsSelected.filter(
        (element) => element.category !== cat
      );
      this.sort(roleGroups);
      this.sort(newSelectedGroup);
    }
    document.addEventListener("click", this.handleClickOutside);
  };

  //открыть группу в выбранных
  openEditGroupsTags = (i) => {
    const { finishRoleGroupsSelected } = this.state;
    finishRoleGroupsSelected[i].open = !finishRoleGroupsSelected[i].open;
    this.setState({
      finishRoleGroupsSelected: finishRoleGroupsSelected,
      stateGroup: finishRoleGroupsSelected[i].category,
    });
  };

  delTagInSelect = (e, tag, cat) => {
    e.stopPropagation();
    document.removeEventListener("click", this.handleClickOutside);
    const { roleGroups, selectedGroups, finishRoleGroupsSelected } = this.state;
    let i = 0;
    let index = 0;
    let existsInRoleGroups = false;
    roleGroups.forEach((rg) => {
      if (
        rg.category === cat &&
        (rg.include_tags.length > 0 || rg.exclude_tags.length > 0)
      ) {
        existsInRoleGroups = true;
        index = i;
      }
      i++;
    });
    selectedGroups.forEach((el) => {
      if (el.category === cat) {
        let found = false;
        el.include_tags.forEach((tg) => {
          if (tg === tag) {
            if (existsInRoleGroups) {
              roleGroups[index].include_tags.push(tg);
            } else {
              let delGroup = Object.assign({}, el);
              delGroup.include_tags = [tg];
              delGroup.exclude_tags = [];
              roleGroups.push(delGroup);
            }
            found = true;
          }
        });
        if (found === false) {
          el.exclude_tags.forEach((tg) => {
            if (tg === tag) {
              if (existsInRoleGroups) {
                roleGroups[index].exclude_tags.push(tg);
              } else {
                let delGroup = Object.assign({}, el);
                delGroup.exclude_tags = [tg];
                delGroup.include_tags = [];
                roleGroups.push(delGroup);
              }
            }
          });
        }
      }
      /* if(el.tag === tag && el.category === cat){
      roleGroups.push(el)
    }*/
    });
    let removeGroup = false;
    newSelectedGroup = JSON.parse(JSON.stringify(selectedGroups));
    newSelectedGroup.forEach((el, i) => {
      if (cat === el.category) {
        const filteredGroup = finishRoleGroupsSelected.find(
          (group) => group.category === el.category
        );
        if (filteredGroup) {
          el.open = filteredGroup.open;
        }
        el.include_tags = el.include_tags.filter((element) => element !== tag);
        el.exclude_tags = el.exclude_tags.filter((element) => element !== tag);
        if (el.include_tags.length === 0 && el.exclude_tags.length === 0) {
          removeGroup = true;
        }
      }
    });
    if (removeGroup) {
      newSelectedGroup = selectedGroups.filter(
        (element) => element.category !== cat
      );
    }
    /*newSelectedGroup = selectedGroups.filter(element=>
      cat === element.category ? element.tag !== tag : element
    )*/
    this.sort(newSelectedGroup);
    this.sort(roleGroups);
    document.addEventListener("click", this.handleClickOutside);
  };

  closeField = (bool) => {
    this.setState({ notField: bool });
  };

  addTechnologyName = (cat, techName, role) => {
    const { roleGroups, selectedGroups } = this.state;
    let i = 0;
    let index = 0;
    let existsInSelectedTechNames = false;
    let existsInSelectedGroups = false;

    selectedGroups.forEach((sg) => {
      if (sg.category === cat) {
        if (sg.technologies.length > 0) {
          existsInSelectedTechNames = true;
          index = i;
        } else {
          existsInSelectedGroups = true;
        }
      }
      i++;
    });
    roleGroups.forEach((item) => {
      if (item.category === cat) {
        if (existsInSelectedTechNames) {
          selectedGroups[index].technologies.push(techName);
          if (role === "exclude") {
            if (selectedGroups[index].excludeTech == null) {
              selectedGroups[index].excludeTech = [techName];
            } else {
              selectedGroups[index].excludeTech.push(techName);
            }
          }
        } else {
          let itemToPush = Object.assign({}, item);
          itemToPush.technologies = [techName];
          if (role === "exclude") {
            itemToPush.excludeTech = [techName];
          }
          selectedGroups.push(itemToPush);
        }
      }
    });
    let noTechnologyLeft = false;
    let newRoleGroup = [];
    roleGroups.forEach((element) => {
      if (element.category === cat) {
        element.technologies = element.technologies.filter(
          (tech) => tech !== techName
        );
        if (element.technologies.length === 0) {
          noTechnologyLeft = true;
          element.showTechName = false;
        } else {
          element.showTechName = true;
        }
        if (existsInSelectedGroups) {
          element.showGroup = false;
        } else {
          element.showGroup = true;
        }
      }
      newRoleGroup.push(element);
    });
    if (noTechnologyLeft && existsInSelectedGroups) {
      newRoleGroup = roleGroups.filter((element) => element.category !== cat);
    }
    this.sort(newRoleGroup);
    this.sort(selectedGroups);
    this.openPanel("field");
  };

  delTechnologyName = (cat, isExcludeTag, techName) => {
    const { roleGroups, selectedGroups } = this.state;
    let i = 0;
    let index = 0;
    let existsInRoleGroups = false;
    roleGroups.forEach((rg) => {
      if (
        rg.category === cat &&
        (rg.include_tags.length > 0 || rg.exclude_tags.length > 0)
      ) {
        existsInRoleGroups = true;
        index = i;
      }
      i++;
    });
    selectedGroups.forEach((item) => {
      if (item.category === cat) {
        if (existsInRoleGroups) {
          if (
            roleGroups[index].technologies.length > 0 &&
            roleGroups[index].technologies !== null &&
            item.technologies.length > 0 &&
            item.technologies !== null
          ) {
            roleGroups[index].showTechName = true;
            roleGroups[index].technologies.push(techName);
          } else {
            roleGroups[index].showTechName = true;
            roleGroups[index].technologies = [techName];
          }
        } else {
          let itemToPush = Object.assign({}, item);
          item.showGroup = false;
          item.showTechName = true;
          roleGroups.push(itemToPush);
        }
      }
    });
    let newSelectedGroup = [];
    selectedGroups.forEach((element) => {
      if (element.category === cat && element.technologies.length > 0) {
        element.technologies = element.technologies.filter(
          (tech) => tech !== techName
        );
        if (element.technologies.length > 0) {
          element.showTechName = true;
          newSelectedGroup.push(element);
        }
      } else {
        newSelectedGroup.push(element);
      }
    });
    this.sort(roleGroups);
    this.sort(newSelectedGroup);
  };

  toggleExcludeOption = async () => {
    this.setState({ excludeOption: !this.state.excludeOption });
  };

  setLocationOptions = (locations) => {
    this.setState({ locationOptions: locations });
  };

  openPanelAndExpandGroup = (mode, label) => {
    const openedGroups = this.state.finishRoleGroupsSelected.map((group) => {
      const shouldOpen = group.label === label;
      return {
        ...group,
        open: shouldOpen,
      };
    });
    this.setState({ finishRoleGroupsSelected: openedGroups });
    this.openPanel(mode);
  };

  render() {
    const {
      editGroupsPanel,
      roleGroups,
      addGroupPanel,
      fieldPanel,
      filteredRoleGroups,
      finishRoleGroupsSelected,
      value,
      excludeTag,
      showMessage,
      includeTag,
      includeTag2,
      excludeTag2,
    } = this.state;
    const {
      inputType,
      placeholder,
      name,
      haveTags,
      label,
      customInput,
      showExclude,
      basicHeading1,
      showIncludePreviousRole,
    } = this.props;
    const count =
      (finishRoleGroupsSelected ? finishRoleGroupsSelected.length : 0) +
      (includeTag ? includeTag.length : 0) +
      (excludeTag ? excludeTag.length : 0);

    const count2 =
      (finishRoleGroupsSelected ? finishRoleGroupsSelected.length : 0) +
      (includeTag2 ? includeTag2.length : 0) +
      (excludeTag2 ? excludeTag2.length : 0);

    const locationCount = this.state.selectedLocations
      ? this.state.selectedLocations.length
      : 0;
    return (
      <div ref={this.inputRef}>
        <input
          value={value}
          type={inputType}
          name={name}
          autoComplete="nope"
          onKeyDown={(e) => {
            if (customInput && this.state.value && e.keyCode === 13) {
              this.addExcludeTag(value);
            }
          }}
          placeholder={placeholder}
          onChange={this.getValue}
          onFocus={() => {
            if (name !== "keywords") {
              this.openPanel("add");
            }
          }}
        />

        {this.props.showPen && (
          <i
            className="fa fa-paperclip"
            aria-hidden="true"
            onClick={this.props.onPenClick}
            style={{
              position: "absolute",
              top: "35px",
              right: "42px",
              cursor: "pointer",
              color: "#bdbdbd",
            }}
          ></i>
        )}

        {addGroupPanel &&
        name !== "location" &&
        name !== "company" &&
        name !== "technologies" ? (
          <AddGroups
            addGroupSelect={this.addGroupSelect}
            roleGroups={roleGroups}
            finishRoleGroups={filteredRoleGroups}
            addExcludeTag={this.addExcludeTag}
            addTagInSelect={this.addExcludeTag}
            value={value}
            customInput={customInput}
            showMessage={showMessage}
            name={name}
            haveTags={haveTags}
            label={label}
            showExclude={showExclude}
            showIncludePreviousRole={showIncludePreviousRole}
            toggleIncludePastRole={this.props.toggleIncludePastRole}
            include_past_roles={this.props.include_past_roles}
            excludeOption={this.state.excludeOption}
            toggleExcludeOption={this.toggleExcludeOption}
          />
        ) : (
          ""
        )}

        {addGroupPanel && name === "technologies" ? (
          <AddTechnologyGroup
            addGroupSelect={this.addGroupSelect}
            roleGroups={roleGroups}
            finishRoleGroups={
              this.state.showAll
                ? filteredRoleGroups
                : filteredRoleGroups.slice(0, 40)
            }
            addExcludeTag={this.addExcludeTag}
            addTagInSelect={this.addExcludeTag}
            value={value}
            customInput={customInput}
            showMessage={showMessage}
            name={name}
            haveTags={haveTags}
            label={label}
            showExclude={showExclude}
            showIncludePreviousRole={showIncludePreviousRole}
            toggleIncludePastRole={this.props.toggleIncludePastRole}
            include_past_roles={this.props.include_past_roles}
            excludeOption={this.state.excludeOption}
            toggleExcludeOption={this.toggleExcludeOption}
            loadMore={this.loadMore}
            hasMore={this.state.hasMore}
            showAll={this.state.showAll}
          />
        ) : (
          ""
        )}

        {addGroupPanel && name === "company" && value ? (
          <AddGroups
            addGroupSelect={this.addGroupSelect}
            roleGroups={roleGroups}
            finishRoleGroups={filteredRoleGroups}
            addExcludeTag={this.addExcludeTag}
            addTagInSelect={this.addExcludeTag}
            value={value}
            customInput={customInput}
            showMessage={showMessage}
            name={name}
            haveTags={haveTags}
            label={label}
            showExclude={showExclude}
            excludeOption={this.state.excludeOption}
            toggleExcludeOption={this.toggleExcludeOption}
          />
        ) : (
          ""
        )}
        {/* ici */}
        {addGroupPanel && name === "location" ? (
          <AddLocationGroup
            addGroupSelect={this.addGroupSelect}
            roleGroups={roleGroups}
            finishRoleGroups={filteredRoleGroups}
            addExcludeTag={this.addExcludeTag}
            addTagInSelect={this.addExcludeTag}
            value={value}
            customInput={customInput}
            showMessage={showMessage}
            name={name}
            type={this.props.type}
            haveTags={haveTags}
            label={label}
            showExclude={showExclude}
            excludeOption={this.state.excludeOption}
            toggleExcludeOption={this.toggleExcludeOption}
            locationOptions={this.state.locationOptions}
            setLocationOptions={this.setLocationOptions}
          />
        ) : (
          ""
        )}

        {
          // fieldPanel &&
          name === "industries2" && count2 > 0 ? (
            <Field
              finishRoleGroupsSelected={finishRoleGroupsSelected}
              openPanel={this.openPanelAndExpandGroup}
              delGroupSelect={this.delGroupSelect}
              closeField={this.closeField}
              excludeTag={excludeTag}
              includeTag={includeTag}
            
              name={this.props.name}
              dellExcludeTag={this.dellExcludeTag}
              count={count}
              haveTags={haveTags}
            />
          ) : (
            ""
          )
        }

        {
          name === "department" && count2 > 0 ? (
            <Field
              finishRoleGroupsSelected={finishRoleGroupsSelected}
              openPanel={this.openPanelAndExpandGroup}
              delGroupSelect={this.delGroupSelect}
              closeField={this.closeField}
              excludeTag={excludeTag}
              includeTag={includeTag}
            
              name={this.props.name}
              dellExcludeTag={this.dellExcludeTag}
              count={count}
              haveTags={haveTags}
            />
          ) : (
            ""
          )
        }

        {
          // fieldPanel &&
          name !== "location" && count > 0 ? (
            <Field
              finishRoleGroupsSelected={finishRoleGroupsSelected}
              openPanel={this.openPanelAndExpandGroup}
              delGroupSelect={this.delGroupSelect}
              closeField={this.closeField}
              excludeTag={excludeTag}
              includeTag={includeTag}
            
              name={this.props.name}
              dellExcludeTag={this.dellExcludeTag}
              count={count}
              haveTags={haveTags}
            />
          ) : (
            ""
          )
        }
        {/* ici tags*/}
        {fieldPanel && name === "location" && count > 0 ? (
          <LocationField
            openPanel={this.openPanel}
            delGroupSelect={this.delGroupSelect}
            closeField={this.closeField}
            count={locationCount}
            labelField="keyword"
            locations={this.state.selectedLocations}
          />
        ) : (
          ""
        )}
        {editGroupsPanel && name !== "location" && count > 0 ? (
          <EditGroups
            finishRoleGroupsSelected={finishRoleGroupsSelected}
            openEditGroupsTags={this.openEditGroupsTags}
            delGroupSelect={this.delGroupSelect}
            delTagInSelect={this.delTagInSelect}
            excludeTag={excludeTag}
            dellExcludeTag={this.dellExcludeTag}
            includeTag={includeTag}
            dellIncludeTag={this.dellIncludeTag}
            haveTags={haveTags}
            name={name}
            basicHeading1={basicHeading1}
          />
        ) : (
          ""
        )}
        {editGroupsPanel && name === "location" && count > 0 ? (
          <EditLocationGroups
            finishRoleGroupsSelected={finishRoleGroupsSelected}
            openEditGroupsTags={this.openEditGroupsTags}
            delGroupSelect={this.delGroupSelect}
            delTagInSelect={this.delTagInSelect}
            excludeTag={excludeTag}
            dellExcludeTag={this.dellExcludeTag}
            includeTag={includeTag}
            dellIncludeTag={this.dellIncludeTag}
            haveTags={haveTags}
            name={name}
            labelField="keyword"
            basicHeading1={basicHeading1}
            locations={this.state.selectedLocations}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Functions;
