export const timeInLatestJob = [
  {
    category: 0,
    label: "0 to 6 months",
    group: "0 to 6 months",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 1,
    label: "6 months to 12 months",
    group: "6 months to 12 months",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 2,
    label: "1 year to 2 years",
    group: "1 year to 2 years",
    include_tags: [""],
    exclude_tags: []
  },
  {
    category: 3,
    label: "2 years to 5 years",
    group: "2 years to 5 years",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 4,
    label: "5+ years",
    group: "5+ years",
    include_tags: [],
    exclude_tags: []
  },
  {
    category: 5,
    label: "Unknown",
    group: "Unknown",
    include_tags: [],
    exclude_tags: []
  }
];