import {
  AccountBased,
  MarketBased,
  LeadLists,
  SearchResult,
  Integrations,
  Profile,
  BillingAndUsers,
  Coupon,
  IgnoreList,
  Users,
  InsideList,
  SavedSearches,
  HotLeads
} from '../containers'

import ApiKeys from '../containers/ApiKeys';
import { ROUTES } from './routes';

const routes = [
  {
    path: ROUTES.LEAD_SEARCH__ACCOUNT_BASED,
    component: AccountBased
  },
  {
    path: ROUTES.HOT_LEADS,
    component: HotLeads
  },
  {
    path: ROUTES.LEAD_SEARCH__MARKET_BASED,
    component: MarketBased
  },
  {
    path: ROUTES.PEOPLE_LIST,
    component: LeadLists
  },
  {
    path: ROUTES.SEARCH_RESULT,
    component: SearchResult
  },
  {
    path: ROUTES.INTEGRATIONS,
    component: Integrations
  },
  {
    path: ROUTES.API_KEYS,
    component: ApiKeys
  },
  {
    path: ROUTES.PROFILE,
    component: Profile
  },
  {
    path: ROUTES.BILLING_AND_USERS,
    component: BillingAndUsers
  },
  {
    path: ROUTES.ADMIN__COUPON,
    component: Coupon
  },
  {
    path: ROUTES.IGNORE_LIST,
    component: IgnoreList
  },
  {
    path: ROUTES.ADMIN__USERS,
    component: Users
  },
  {
    path: ROUTES.LEAD_LIST__INSIDE_LIST,
    component: InsideList
  },
  {
    path: ROUTES.LEAD_LIST__SAVED_SEARCHES,
    component: SavedSearches
  },
];

export default routes
