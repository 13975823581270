import React, { Component } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import AuxContainer from "../../../../../containers/AuxContainer/aux-container";
import { LocationType } from "../../../../../constants/constant";

class EditLocationGroups extends Component {

  
  getHeadingAndOptions(heading, locTypeArr) {
    let locationDiv = '';
    if (locTypeArr.length) {
      
        const  locTypeEntries = 
        locTypeArr.map((item, index) => {
            return (<div>
              <p className={!item.excludeGroup  ? 'field__exclude_ex' : 'field__custom_exclude exclude_tag'} key={"edit-selected-groups-" + index}>
                {item[this.props.labelField].replace("|", ", ")}
                <img
                  src={!item.excludeGroup  ? '/assest/accountBased/del-tag.svg' : '/assest/apiKeys/Close_red.svg' }
                  alt=""
                  onClick={e => this.props.delGroupSelect(e, item)}
                />
              </p>
            </div>);
        });
        locationDiv = (
            <div>
                <p className="dropdown-in__title">{heading}</p>
                <div className="field__exclude" style={{paddingTop: '5px'}}>
                    {locTypeEntries}
                </div>        
            </div>
        );
    }
    return locationDiv;
}

  render() {
  const {
    locations,
    openEditGroupsTags,
    delGroupSelect,
    dellExcludeTag,
    name,
    labelField,
    basicHeading1
  } = this.props;
  let allIncludeAndExcludeTagHeading = null;
  if (basicHeading1) {
    allIncludeAndExcludeTagHeading = (
      <div className="field__include">{basicHeading1}</div>
    );
  }

  const cities = locations.filter(loc => loc.type === LocationType.City);
  const states = locations.filter(loc => loc.type === LocationType.State);
  const metros = locations.filter(loc => loc.type === LocationType.Metro);
  const stateRemovedMetros = metros.map(metro => {
    let d = metro.keyword.split("|");
    return {type: metro.type, keyword: d[0]};
  })
  
  const groups = locations.filter(loc => loc.type === LocationType.Group);
  const countries = locations.filter(loc => loc.type === LocationType.Country);
  const continents = locations.filter(loc => loc.type === LocationType.Continent);
  const keywords = locations.filter(loc => loc.type === LocationType.Keyword);
  const citiesDiv = this.getHeadingAndOptions('Cities', cities);
  const statesDiv = this.getHeadingAndOptions('States', states);
  // const metrosDiv = this.getHeadingAndOptions('Metros', metros);
  const regionsDiv = this.getHeadingAndOptions('Regions', groups);
  const coutriesDiv = this.getHeadingAndOptions('Countries', countries);
  const continentsDiv = this.getHeadingAndOptions('Continents', continents);
  const keywordsDiv = this.getHeadingAndOptions('Keywords', keywords);

        /*  const locationTags = locations.map((item, index) => (
            <div>
              <p className="field__exclude_ex" key={"selected-groups-" + index}>
                {item[labelField].replace("|", ", ")}
                <img
                  src="/assest/accountBased/del-tag.svg"
                  alt=""
                  onClick={e => delGroupSelect(e, item)}
                />
              </p>
            </div>
          )) */

  return (
    <div className="dropdown-in animation-slideDown scroll-dropdown">
      {allIncludeAndExcludeTagHeading}



          {/* metrosDiv */}
          {citiesDiv}
          {statesDiv}
          {coutriesDiv}
          {regionsDiv}
          {continentsDiv}
          {keywordsDiv}

      {/* <div className="field__exclude">
      {locationTags}
      </div> */}
    </div>
  );
};
}

export default EditLocationGroups;
