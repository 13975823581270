import moment from "moment";
import React, {Component} from 'react';
import { LOCAL_STORAGE_KEYS } from "../../../../../constants/constant";
import Popup from 'reactjs-popup'
class UserDetailsContent extends Component {

    formatDate(date) {
        if (date) {
            return moment(date).format("MM-DD-YYYY");
        }

        return "N/A";
    }

    differenceInTime(dateStr) {
        if (!dateStr) {
            return "";
        }

        const date = moment(dateStr);
        const diffInDays = moment().diff(date, "days");
        if (diffInDays === 0) {
            const diffInHours = moment().diff(date, "hours");
            if (diffInHours === 0) {
                const diffInMinutes = moment().diff(date, "minutes");
                return `${diffInMinutes < 0 ? 0 : diffInMinutes} minutes ago`;
            }
            return `${diffInHours} hours ago`;
        }
        return `${diffInDays} days ago`;
    }

    redirectToSocialPage = (link) => {
        window.open(link, '_blank');
    }

    render() {
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
        return (
            this.props.items.map((item, index) =>
                <div className="billing-user-item" key={index}>

                    <div className="billing-user-item__name col user-email">
                        <p className="billing-user-name">{item.first_name == "" && item.last_name == "" ? "N/A" : item.first_name + " " + item.last_name}</p>
                        <p className="billing-user-item__opacity">{item.email}</p>
                        <p className="billing-user-item__opacity">{item.role ? item.role : "N/A"}</p>
                        <div className="billing-user-item__opacity">
                            <div className={`billing-social__icons`}>
                                {item.linkedin && <div className={`profile-info__link`} to="" onClick={() => this.redirectToSocialPage(item.linkedin)}><img src="/assest/accountBased/social-icons/ln-icon.svg" alt="" /></div>}
                                {item.facebook && <div className={`profile-info__link`} to="" onClick={() => this.redirectToSocialPage(item.facebook)}><img src="/assest/accountBased/social-icons/fb-icon.svg" alt="" /></div>}
                                {item.twitter && <div className={`profile-info__link`} to="" onClick={() => this.redirectToSocialPage(item.twitter)}><img src="/assest/accountBased/social-icons/tw-icon.svg" alt="" /></div>}
                                {item.instagram && <div className={`profile-info__link`} to="" onClick={() => this.redirectToSocialPage(item.instagram)}><img src="/assest/accountBased/social-icons/inst-icon.svg" alt="" /></div>}
                                {item.youtube && <div className={`profile-info__link`} to="" onClick={() => this.redirectToSocialPage(item.youtube)}><img src="/assest/accountBased/social-icons/yt-icon.svg" alt="" /></div>}
                            </div>
                        </div>
                    </div>

                    <div className="billing-user-item__added col leads-added">
                        <p>{item.used_lead_credits}</p>
                    </div>

                    <div className="billing-user-item__leads col max-leads">
                        <p>{item.max_lead_credits === -1 ?
                            <span className="infinity">&#8734;</span> : item.max_lead_credits}</p>
                    </div>

                    <div className="billing-user-item__remainig col leads-remaining">
                        <p>{item.available_lead_credits} leads</p>
                    </div>

                    <div className="billing-user-item__lists col number-of-lists">
                        <p>{item.list_count}</p>
                    </div>

                    <div className="billing-user-item__date col last-login-date">
                        <p>{this.formatDate(item.last_login_date)}</p>
                        <p className="billing-user-item__opacity">{this.differenceInTime(item.last_login_date)}</p>
                    </div>

                    <Popup trigger={
                        <div className="add" style={{padding: '10px'}}  // onClick={(e) => this.changeCheckDropdown(e)}
                        >
                            <div className="">
                            <ul className='lead-cont-item__dots' style={{marginLeft: 0}} 
                                // onClick={(e) => e.stopPropagation()}
                                > 
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                            </div>
                            </div>}
                                    position="bottom center"
                                    arrow={false}
                                    closeOnDocumentClick={true}
                                    closeOnEscape={true}
                                    contentStyle={{ 'background': 'none', border: 'default', 'boxShadow': 'none', 'zIndex': '10' }}
                                    >
                                    <div className="inside-select open" style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                        <div className={`menu-item `} onClick={() => this.props.showAddUserPopup(item) } ><div><i className="fa fa-pencil-square-o" style={{marginRight: '5px'}}></i>Edit</div></div>
                                        
                                        {user.id !== item.id && this.props.showActionButton() && 
                                            <div className={`menu-item `} onClick={() => this.props.showConfirmationPopup(item)}><div><i className="fa fa-trash" style={{marginRight: '5px'}}></i>Delete</div></div>
                                        }
                                    </div>
                    </Popup>
                    {/* <div className={`billing-user-item__dropdown ${item.menuDropDownActive ? 'active' : ''}`}>
                        <ul className={`${item.menuDropDownActive ? 'active' : ''}`} onClick={(event) => {
                            event.stopPropagation();
                            this.props.toggleMenuDropDown(item.id, true)
                        }}>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>

                    <div className={`billing-user-item__dropdown__overlay ${item.menuDropDownActive ? 'active' : ''}`}
                         onClick={(event) => {
                             event.stopPropagation();
                             this.props.toggleMenuDropDown(item.id, false)
                         }}></div>

                    {item.menuDropDownActive &&
                    <div className={`billing-user-list`}>
                        <div className="billing-user-list__login"
                             onClick={() => this.props.showAddUserPopup(item)}><i className="fa fa-pencil-square-o" style={{marginRight: '5px'}}></i>&nbsp;Edit
                        </div>

                        {user.id !== item.id && this.props.showActionButton() &&
                        <div className="billing-user-list__del"
                             onClick={() => this.props.showConfirmationPopup(item)}><i className="fa fa-trash" style={{marginRight: '5px'}}></i>Delete</div>
                        }
                    </div>
                    } */}
                </div>
            )
        )
    }
}

export default UserDetailsContent
