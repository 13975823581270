export const skills = [
  {
    category: "Microsoft Office",
    label: "Microsoft Office",
    group: "Microsoft Office",
    include_tags: ["Microsoft Office"],
    exclude_tags: []
  },
  {
    category: "Management",
    label: "Management",
    group: "Management",
    include_tags: ["Management"],
    exclude_tags: []
  },
  {
    category: "Customer Service",
    label: "Customer Service",
    group: "Customer Service",
    include_tags: ["Customer Service"],
    exclude_tags: []
  },
  {
    category: "Microsoft Excel",
    label: "Microsoft Excel",
    group: "Microsoft Excel",
    include_tags: ["Microsoft Excel"],
    exclude_tags: []
  },
  {
    category: "Microsoft Word",
    label: "Microsoft Word",
    group: "Microsoft Word",
    include_tags: ["Microsoft Word"],
    exclude_tags: []
  },
  {
    category: "Leadership",
    label: "Leadership",
    group: "Leadership",
    include_tags: ["Leadership"],
    exclude_tags: []
  },
  {
    category: "Sales",
    label: "Sales",
    group: "Sales",
    include_tags: ["Sales"],
    exclude_tags: []
  },
  {
    category: "Strategic Planning",
    label: "Strategic Planning",
    group: "Strategic Planning",
    include_tags: ["Strategic Planning"],
    exclude_tags: []
  },
  {
    category: "Powerpoint",
    label: "Powerpoint",
    group: "Powerpoint",
    include_tags: ["Powerpoint"],
    exclude_tags: []
  },
  {
    category: "Project Management",
    label: "Project Management",
    group: "Project Management",
    include_tags: ["Project Management"],
    exclude_tags: []
  },
  {
    category: "Public Speaking",
    label: "Public Speaking",
    group: "Public Speaking",
    include_tags: ["Public Speaking"],
    exclude_tags: []
  },
  {
    category: "Research",
    label: "Research",
    group: "Research",
    include_tags: ["Research"],
    exclude_tags: []
  },
  {
    category: "Marketing",
    label: "Marketing",
    group: "Marketing",
    include_tags: ["Marketing"],
    exclude_tags: []
  },
  {
    category: "Social Media",
    label: "Social Media",
    group: "Social Media",
    include_tags: ["Social Media"],
    exclude_tags: []
  },
  {
    category: "Training",
    label: "Training",
    group: "Training",
    include_tags: ["Training"],
    exclude_tags: []
  },
  {
    category: "Team Building",
    label: "Team Building",
    group: "Team Building",
    include_tags: ["Team Building"],
    exclude_tags: []
  },
  {
    category: "Negotiation",
    label: "Negotiation",
    group: "Negotiation",
    include_tags: ["Negotiation"],
    exclude_tags: []
  },
  {
    category: "Business Strategy",
    label: "Business Strategy",
    group: "Business Strategy",
    include_tags: ["Business Strategy"],
    exclude_tags: []
  },
  {
    category: "Sales Management",
    label: "Sales Management",
    group: "Sales Management",
    include_tags: ["Sales Management"],
    exclude_tags: []
  },
  {
    category: "Marketing Strategy",
    label: "Marketing Strategy",
    group: "Marketing Strategy",
    include_tags: ["Marketing Strategy"],
    exclude_tags: []
  },
  {
    category: "New Business Development",
    label: "New Business Development",
    group: "New Business Development",
    include_tags: ["New Business Development"],
    exclude_tags: []
  },
  {
    category: "Business Development",
    label: "Business Development",
    group: "Business Development",
    include_tags: ["Business Development"],
    exclude_tags: []
  },
  {
    category: "Team Leadership",
    label: "Team Leadership",
    group: "Team Leadership",
    include_tags: ["Team Leadership"],
    exclude_tags: []
  },
  {
    category: "English",
    label: "English",
    group: "English",
    include_tags: ["English"],
    exclude_tags: []
  },
  {
    category: "Teaching",
    label: "Teaching",
    group: "Teaching",
    include_tags: ["Teaching"],
    exclude_tags: []
  },
  {
    category: "Strategy",
    label: "Strategy",
    group: "Strategy",
    include_tags: ["Strategy"],
    exclude_tags: []
  },
  {
    category: "Teamwork",
    label: "Teamwork",
    group: "Teamwork",
    include_tags: ["Teamwork"],
    exclude_tags: []
  },
  {
    category: "Event Planning",
    label: "Event Planning",
    group: "Event Planning",
    include_tags: ["Event Planning"],
    exclude_tags: []
  },
  {
    category: "Budgets",
    label: "Budgets",
    group: "Budgets",
    include_tags: ["Budgets"],
    exclude_tags: []
  },
  {
    category: "Project Planning",
    label: "Project Planning",
    group: "Project Planning",
    include_tags: ["Project Planning"],
    exclude_tags: []
  },
  {
    category: "Time Management",
    label: "Time Management",
    group: "Time Management",
    include_tags: ["Time Management"],
    exclude_tags: []
  },
  {
    category: "Photoshop",
    label: "Photoshop",
    group: "Photoshop",
    include_tags: ["Photoshop"],
    exclude_tags: []
  },
  {
    category: "Engineering",
    label: "Engineering",
    group: "Engineering",
    include_tags: ["Engineering"],
    exclude_tags: []
  },
  {
    category: "Team Management",
    label: "Team Management",
    group: "Team Management",
    include_tags: ["Team Management"],
    exclude_tags: []
  },
  {
    category: "Change Management",
    label: "Change Management",
    group: "Change Management",
    include_tags: ["Change Management"],
    exclude_tags: []
  },
  {
    category: "Finance",
    label: "Finance",
    group: "Finance",
    include_tags: ["Finance"],
    exclude_tags: []
  },
  {
    category: "Retail",
    label: "Retail",
    group: "Retail",
    include_tags: ["Retail"],
    exclude_tags: []
  },
  {
    category: "Analysis",
    label: "Analysis",
    group: "Analysis",
    include_tags: ["Analysis"],
    exclude_tags: []
  },
  {
    category: "Communication",
    label: "Communication",
    group: "Communication",
    include_tags: ["Communication"],
    exclude_tags: []
  },
  {
    category: "Microsoft Powerpoint",
    label: "Microsoft Powerpoint",
    group: "Microsoft Powerpoint",
    include_tags: ["Microsoft Powerpoint"],
    exclude_tags: []
  },
  {
    category: "Social Media Marketing",
    label: "Social Media Marketing",
    group: "Social Media Marketing",
    include_tags: ["Social Media Marketing"],
    exclude_tags: []
  },
  {
    category: "Coaching",
    label: "Coaching",
    group: "Coaching",
    include_tags: ["Coaching"],
    exclude_tags: []
  },
  {
    category: "Account Management",
    label: "Account Management",
    group: "Account Management",
    include_tags: ["Account Management"],
    exclude_tags: []
  },
  {
    category: "Accounting",
    label: "Accounting",
    group: "Accounting",
    include_tags: ["Accounting"],
    exclude_tags: []
  },
  {
    category: "Customer Satisfaction",
    label: "Customer Satisfaction",
    group: "Customer Satisfaction",
    include_tags: ["Customer Satisfaction"],
    exclude_tags: []
  },
  {
    category: "Process Improvement",
    label: "Process Improvement",
    group: "Process Improvement",
    include_tags: ["Process Improvement"],
    exclude_tags: []
  },
  {
    category: "Outlook",
    label: "Outlook",
    group: "Outlook",
    include_tags: ["Outlook"],
    exclude_tags: []
  },
  {
    category: "Program Management",
    label: "Program Management",
    group: "Program Management",
    include_tags: ["Program Management"],
    exclude_tags: []
  },
  {
    category: "Advertising",
    label: "Advertising",
    group: "Advertising",
    include_tags: ["Advertising"],
    exclude_tags: []
  },
  {
    category: "Human Resources",
    label: "Human Resources",
    group: "Human Resources",
    include_tags: ["Human Resources"],
    exclude_tags: []
  },
  {
    category: "Operations Management",
    label: "Operations Management",
    group: "Operations Management",
    include_tags: ["Operations Management"],
    exclude_tags: []
  },
  {
    category: "Windows",
    label: "Windows",
    group: "Windows",
    include_tags: ["Windows"],
    exclude_tags: []
  },
  {
    category: "Financial Analysis",
    label: "Financial Analysis",
    group: "Financial Analysis",
    include_tags: ["Financial Analysis"],
    exclude_tags: []
  },
  {
    category: "Public Relations",
    label: "Public Relations",
    group: "Public Relations",
    include_tags: ["Public Relations"],
    exclude_tags: []
  },
  {
    category: "Inventory Management",
    label: "Inventory Management",
    group: "Inventory Management",
    include_tags: ["Inventory Management"],
    exclude_tags: []
  },
  {
    category: "Contract Negotiation",
    label: "Contract Negotiation",
    group: "Contract Negotiation",
    include_tags: ["Contract Negotiation"],
    exclude_tags: []
  },
  {
    category: "Sql",
    label: "Sql",
    group: "Sql",
    include_tags: ["Sql"],
    exclude_tags: []
  },
  {
    category: "Business Analysis",
    label: "Business Analysis",
    group: "Business Analysis",
    include_tags: ["Business Analysis"],
    exclude_tags: []
  },
  {
    category: "Healthcare",
    label: "Healthcare",
    group: "Healthcare",
    include_tags: ["Healthcare"],
    exclude_tags: []
  },
  {
    category: "Event Management",
    label: "Event Management",
    group: "Event Management",
    include_tags: ["Event Management"],
    exclude_tags: []
  },
  {
    category: "Html",
    label: "Html",
    group: "Html",
    include_tags: ["Html"],
    exclude_tags: []
  },
  {
    category: "Autocad",
    label: "Autocad",
    group: "Autocad",
    include_tags: ["Autocad"],
    exclude_tags: []
  },
  {
    category: "Product Development",
    label: "Product Development",
    group: "Product Development",
    include_tags: ["Product Development"],
    exclude_tags: []
  },
  {
    category: "Manufacturing",
    label: "Manufacturing",
    group: "Manufacturing",
    include_tags: ["Manufacturing"],
    exclude_tags: []
  },
  {
    category: "Liderazgo De Equipos",
    label: "Liderazgo De Equipos",
    group: "Liderazgo De Equipos",
    include_tags: ["Liderazgo De Equipos"],
    exclude_tags: []
  },
  {
    category: "Business Planning",
    label: "Business Planning",
    group: "Business Planning",
    include_tags: ["Business Planning"],
    exclude_tags: []
  },
  {
    category: "Nonprofits",
    label: "Nonprofits",
    group: "Nonprofits",
    include_tags: ["Nonprofits"],
    exclude_tags: []
  },
  {
    category: "C",
    label: "C",
    group: "C",
    include_tags: ["C"],
    exclude_tags: []
  },
  {
    category: "Recruiting",
    label: "Recruiting",
    group: "Recruiting",
    include_tags: ["Recruiting"],
    exclude_tags: []
  },
  {
    category: "Java",
    label: "Java",
    group: "Java",
    include_tags: ["Java"],
    exclude_tags: []
  },
  {
    category: "Editing",
    label: "Editing",
    group: "Editing",
    include_tags: ["Editing"],
    exclude_tags: []
  },
  {
    category: "Liderazgo",
    label: "Liderazgo",
    group: "Liderazgo",
    include_tags: ["Liderazgo"],
    exclude_tags: []
  },
  {
    category: "Banking",
    label: "Banking",
    group: "Banking",
    include_tags: ["Banking"],
    exclude_tags: []
  },
  {
    category: "Marketing Communications",
    label: "Marketing Communications",
    group: "Marketing Communications",
    include_tags: ["Marketing Communications"],
    exclude_tags: []
  },
  {
    category: "Entrepreneurship",
    label: "Entrepreneurship",
    group: "Entrepreneurship",
    include_tags: ["Entrepreneurship"],
    exclude_tags: []
  },
  {
    category: "Redes Sociales",
    label: "Redes Sociales",
    group: "Redes Sociales",
    include_tags: ["Redes Sociales"],
    exclude_tags: []
  },
  {
    category: "Risk Management",
    label: "Risk Management",
    group: "Risk Management",
    include_tags: ["Risk Management"],
    exclude_tags: []
  },
  {
    category: "Social Networking",
    label: "Social Networking",
    group: "Social Networking",
    include_tags: ["Social Networking"],
    exclude_tags: []
  },
  {
    category: "Troubleshooting",
    label: "Troubleshooting",
    group: "Troubleshooting",
    include_tags: ["Troubleshooting"],
    exclude_tags: []
  },
  {
    category: "Data Analysis",
    label: "Data Analysis",
    group: "Data Analysis",
    include_tags: ["Data Analysis"],
    exclude_tags: []
  },
  {
    category: "Construction",
    label: "Construction",
    group: "Construction",
    include_tags: ["Construction"],
    exclude_tags: []
  },
  {
    category: "Estrategia Empresarial",
    label: "Estrategia Empresarial",
    group: "Estrategia Empresarial",
    include_tags: ["Estrategia Empresarial"],
    exclude_tags: []
  },
  {
    category: "Community Outreach",
    label: "Community Outreach",
    group: "Community Outreach",
    include_tags: ["Community Outreach"],
    exclude_tags: []
  },
  {
    category: "Javascript",
    label: "Javascript",
    group: "Javascript",
    include_tags: ["Javascript"],
    exclude_tags: []
  },
  {
    category: "Networking",
    label: "Networking",
    group: "Networking",
    include_tags: ["Networking"],
    exclude_tags: []
  },
  {
    category: "Fundraising",
    label: "Fundraising",
    group: "Fundraising",
    include_tags: ["Fundraising"],
    exclude_tags: []
  },
  {
    category: "Policy",
    label: "Policy",
    group: "Policy",
    include_tags: ["Policy"],
    exclude_tags: []
  },
  {
    category: "Logistics",
    label: "Logistics",
    group: "Logistics",
    include_tags: ["Logistics"],
    exclude_tags: []
  },
  {
    category: "Atendimento Ao Cliente",
    label: "Atendimento Ao Cliente",
    group: "Atendimento Ao Cliente",
    include_tags: ["Atendimento Ao Cliente"],
    exclude_tags: []
  },
  {
    category: "Higher Education",
    label: "Higher Education",
    group: "Higher Education",
    include_tags: ["Higher Education"],
    exclude_tags: []
  },
  {
    category: "Crm",
    label: "Crm",
    group: "Crm",
    include_tags: ["Crm"],
    exclude_tags: []
  },
  {
    category: "Integration",
    label: "Integration",
    group: "Integration",
    include_tags: ["Integration"],
    exclude_tags: []
  },
  {
    category: "Leadership Development",
    label: "Leadership Development",
    group: "Leadership Development",
    include_tags: ["Leadership Development"],
    exclude_tags: []
  },
  {
    category: "C++",
    label: "C++",
    group: "C++",
    include_tags: ["C++"],
    exclude_tags: []
  },
  {
    category: "Sales Operations",
    label: "Sales Operations",
    group: "Sales Operations",
    include_tags: ["Sales Operations"],
    exclude_tags: []
  },
  {
    category: "Science",
    label: "Science",
    group: "Science",
    include_tags: ["Science"],
    exclude_tags: []
  },
  {
    category: "Market Research",
    label: "Market Research",
    group: "Market Research",
    include_tags: ["Market Research"],
    exclude_tags: []
  },
  {
    category: "Testing",
    label: "Testing",
    group: "Testing",
    include_tags: ["Testing"],
    exclude_tags: []
  },
  {
    category: "Continuous Improvement",
    label: "Continuous Improvement",
    group: "Continuous Improvement",
    include_tags: ["Continuous Improvement"],
    exclude_tags: []
  },
  {
    category: "Start Ups",
    label: "Start Ups",
    group: "Start Ups",
    include_tags: ["Start Ups"],
    exclude_tags: []
  },
  {
    category: "Forecasting",
    label: "Forecasting",
    group: "Forecasting",
    include_tags: ["Forecasting"],
    exclude_tags: []
  },
  {
    category: "Databases",
    label: "Databases",
    group: "Databases",
    include_tags: ["Databases"],
    exclude_tags: []
  },
  {
    category: "Ventas",
    label: "Ventas",
    group: "Ventas",
    include_tags: ["Ventas"],
    exclude_tags: []
  },
  {
    category: "Financial Reporting",
    label: "Financial Reporting",
    group: "Financial Reporting",
    include_tags: ["Financial Reporting"],
    exclude_tags: []
  },
  {
    category: "Hospitals",
    label: "Hospitals",
    group: "Hospitals",
    include_tags: ["Hospitals"],
    exclude_tags: []
  },
  {
    category: "Data Entry",
    label: "Data Entry",
    group: "Data Entry",
    include_tags: ["Data Entry"],
    exclude_tags: []
  },
  {
    category: "Auditing",
    label: "Auditing",
    group: "Auditing",
    include_tags: ["Auditing"],
    exclude_tags: []
  },
  {
    category: "Management Consulting",
    label: "Management Consulting",
    group: "Management Consulting",
    include_tags: ["Management Consulting"],
    exclude_tags: []
  },
  {
    category: "Government",
    label: "Government",
    group: "Government",
    include_tags: ["Government"],
    exclude_tags: []
  },
  {
    category: "Sap",
    label: "Sap",
    group: "Sap",
    include_tags: ["Sap"],
    exclude_tags: []
  },
  {
    category: "Software Development",
    label: "Software Development",
    group: "Software Development",
    include_tags: ["Software Development"],
    exclude_tags: []
  },
  {
    category: "Consulting",
    label: "Consulting",
    group: "Consulting",
    include_tags: ["Consulting"],
    exclude_tags: []
  },
  {
    category: "Responsibility",
    label: "Responsibility",
    group: "Responsibility",
    include_tags: ["Responsibility"],
    exclude_tags: []
  },
  {
    category: "Writing",
    label: "Writing",
    group: "Writing",
    include_tags: ["Writing"],
    exclude_tags: []
  },
  {
    category: "Microsoft Sql Server",
    label: "Microsoft Sql Server",
    group: "Microsoft Sql Server",
    include_tags: ["Microsoft Sql Server"],
    exclude_tags: []
  },
  {
    category: "Trabajo En Equipo",
    label: "Trabajo En Equipo",
    group: "Trabajo En Equipo",
    include_tags: ["Trabajo En Equipo"],
    exclude_tags: []
  },
  {
    category: "Contract Management",
    label: "Contract Management",
    group: "Contract Management",
    include_tags: ["Contract Management"],
    exclude_tags: []
  },
  {
    category: "Telecommunications",
    label: "Telecommunications",
    group: "Telecommunications",
    include_tags: ["Telecommunications"],
    exclude_tags: []
  },
  {
    category: "Security",
    label: "Security",
    group: "Security",
    include_tags: ["Security"],
    exclude_tags: []
  },
  {
    category: "Digital Marketing",
    label: "Digital Marketing",
    group: "Digital Marketing",
    include_tags: ["Digital Marketing"],
    exclude_tags: []
  },
  {
    category: "Business Process Improvement",
    label: "Business Process Improvement",
    group: "Business Process Improvement",
    include_tags: ["Business Process Improvement"],
    exclude_tags: []
  },
  {
    category: "Graphic Design",
    label: "Graphic Design",
    group: "Graphic Design",
    include_tags: ["Graphic Design"],
    exclude_tags: []
  },
  {
    category: "Supply Chain Management",
    label: "Supply Chain Management",
    group: "Supply Chain Management",
    include_tags: ["Supply Chain Management"],
    exclude_tags: []
  },
  {
    category: "Problem Solving",
    label: "Problem Solving",
    group: "Problem Solving",
    include_tags: ["Problem Solving"],
    exclude_tags: []
  },
  {
    category: "Cross Functional Team Leadership",
    label: "Cross Functional Team Leadership",
    group: "Cross Functional Team Leadership",
    include_tags: ["Cross Functional Team Leadership"],
    exclude_tags: []
  },
  {
    category: "Matlab",
    label: "Matlab",
    group: "Matlab",
    include_tags: ["Matlab"],
    exclude_tags: []
  },
  {
    category: "Linux",
    label: "Linux",
    group: "Linux",
    include_tags: ["Linux"],
    exclude_tags: []
  },
  {
    category: "Staff Development",
    label: "Staff Development",
    group: "Staff Development",
    include_tags: ["Staff Development"],
    exclude_tags: []
  },
  {
    category: "Merchandising",
    label: "Merchandising",
    group: "Merchandising",
    include_tags: ["Merchandising"],
    exclude_tags: []
  },
  {
    category: "Service Client",
    label: "Service Client",
    group: "Service Client",
    include_tags: ["Service Client"],
    exclude_tags: []
  },
  {
    category: "Investments",
    label: "Investments",
    group: "Investments",
    include_tags: ["Investments"],
    exclude_tags: []
  },
  {
    category: "Gestion De Projet",
    label: "Gestion De Projet",
    group: "Gestion De Projet",
    include_tags: ["Gestion De Projet"],
    exclude_tags: []
  },
  {
    category: "Spanish",
    label: "Spanish",
    group: "Spanish",
    include_tags: ["Spanish"],
    exclude_tags: []
  },
  {
    category: "Requirements Analysis",
    label: "Requirements Analysis",
    group: "Requirements Analysis",
    include_tags: ["Requirements Analysis"],
    exclude_tags: []
  },
  {
    category: "Real Estate",
    label: "Real Estate",
    group: "Real Estate",
    include_tags: ["Real Estate"],
    exclude_tags: []
  },
  {
    category: "Software",
    label: "Software",
    group: "Software",
    include_tags: ["Software"],
    exclude_tags: []
  },
  {
    category: "Lean Manufacturing",
    label: "Lean Manufacturing",
    group: "Lean Manufacturing",
    include_tags: ["Lean Manufacturing"],
    exclude_tags: []
  },
  {
    category: "Insurance",
    label: "Insurance",
    group: "Insurance",
    include_tags: ["Insurance"],
    exclude_tags: []
  },
  {
    category: "Photography",
    label: "Photography",
    group: "Photography",
    include_tags: ["Photography"],
    exclude_tags: []
  },
  {
    category: "Purchasing",
    label: "Purchasing",
    group: "Purchasing",
    include_tags: ["Purchasing"],
    exclude_tags: []
  },
  {
    category: "Curriculum Design",
    label: "Curriculum Design",
    group: "Curriculum Design",
    include_tags: ["Curriculum Design"],
    exclude_tags: []
  },
  {
    category: "Art",
    label: "Art",
    group: "Art",
    include_tags: ["Art"],
    exclude_tags: []
  },
  {
    category: "Planejamento Empresarial",
    label: "Planejamento Empresarial",
    group: "Planejamento Empresarial",
    include_tags: ["Planejamento Empresarial"],
    exclude_tags: []
  },
  {
    category: "Design",
    label: "Design",
    group: "Design",
    include_tags: ["Design"],
    exclude_tags: []
  },
  {
    category: "Mergers And Acquisitions",
    label: "Mergers And Acquisitions",
    group: "Mergers And Acquisitions",
    include_tags: ["Mergers And Acquisitions"],
    exclude_tags: []
  },
  {
    category: "Organizational Development",
    label: "Organizational Development",
    group: "Organizational Development",
    include_tags: ["Organizational Development"],
    exclude_tags: []
  },
  {
    category: "Clinical Research",
    label: "Clinical Research",
    group: "Clinical Research",
    include_tags: ["Clinical Research"],
    exclude_tags: []
  },
  {
    category: "Mysql",
    label: "Mysql",
    group: "Mysql",
    include_tags: ["Mysql"],
    exclude_tags: []
  },
  {
    category: "Small Business",
    label: "Small Business",
    group: "Small Business",
    include_tags: ["Small Business"],
    exclude_tags: []
  },
  {
    category: "Procurement",
    label: "Procurement",
    group: "Procurement",
    include_tags: ["Procurement"],
    exclude_tags: []
  },
  {
    category: "Business Intelligence",
    label: "Business Intelligence",
    group: "Business Intelligence",
    include_tags: ["Business Intelligence"],
    exclude_tags: []
  },
  {
    category: "Css",
    label: "Css",
    group: "Css",
    include_tags: ["Css"],
    exclude_tags: []
  },
  {
    category: "Performance Management",
    label: "Performance Management",
    group: "Performance Management",
    include_tags: ["Performance Management"],
    exclude_tags: []
  },
  {
    category: "Medicine",
    label: "Medicine",
    group: "Medicine",
    include_tags: ["Medicine"],
    exclude_tags: []
  },
  {
    category: "Military",
    label: "Military",
    group: "Military",
    include_tags: ["Military"],
    exclude_tags: []
  },
  {
    category: "Business Services",
    label: "Business Services",
    group: "Business Services",
    include_tags: ["Business Services"],
    exclude_tags: []
  },
  {
    category: "Curriculum Development",
    label: "Curriculum Development",
    group: "Curriculum Development",
    include_tags: ["Curriculum Development"],
    exclude_tags: []
  },
  {
    category: "Product Management",
    label: "Product Management",
    group: "Product Management",
    include_tags: ["Product Management"],
    exclude_tags: []
  },
  {
    category: "B2b",
    label: "B2b",
    group: "B2b",
    include_tags: ["B2b"],
    exclude_tags: []
  },
  {
    category: "Electronics",
    label: "Electronics",
    group: "Electronics",
    include_tags: ["Electronics"],
    exclude_tags: []
  },
  {
    category: "System",
    label: "System",
    group: "System",
    include_tags: ["System"],
    exclude_tags: []
  },
  {
    category: "Project",
    label: "Project",
    group: "Project",
    include_tags: ["Project"],
    exclude_tags: []
  },
  {
    category: "Internal Audit",
    label: "Internal Audit",
    group: "Internal Audit",
    include_tags: ["Internal Audit"],
    exclude_tags: []
  },
  {
    category: "Vendor Management",
    label: "Vendor Management",
    group: "Vendor Management",
    include_tags: ["Vendor Management"],
    exclude_tags: []
  },
  {
    category: "Vendas",
    label: "Vendas",
    group: "Vendas",
    include_tags: ["Vendas"],
    exclude_tags: []
  },
  {
    category: "Credit",
    label: "Credit",
    group: "Credit",
    include_tags: ["Credit"],
    exclude_tags: []
  },
  {
    category: "Operations",
    label: "Operations",
    group: "Operations",
    include_tags: ["Operations"],
    exclude_tags: []
  },
  {
    category: "Illustrator",
    label: "Illustrator",
    group: "Illustrator",
    include_tags: ["Illustrator"],
    exclude_tags: []
  },
  {
    category: "Technical Support",
    label: "Technical Support",
    group: "Technical Support",
    include_tags: ["Technical Support"],
    exclude_tags: []
  },
  {
    category: "Online Marketing",
    label: "Online Marketing",
    group: "Online Marketing",
    include_tags: ["Online Marketing"],
    exclude_tags: []
  },
  {
    category: "Payroll",
    label: "Payroll",
    group: "Payroll",
    include_tags: ["Payroll"],
    exclude_tags: []
  },
  {
    category: "Healthcare Management",
    label: "Healthcare Management",
    group: "Healthcare Management",
    include_tags: ["Healthcare Management"],
    exclude_tags: []
  },
  {
    category: "Supply Chain",
    label: "Supply Chain",
    group: "Supply Chain",
    include_tags: ["Supply Chain"],
    exclude_tags: []
  },
  {
    category: "Key Account Management",
    label: "Key Account Management",
    group: "Key Account Management",
    include_tags: ["Key Account Management"],
    exclude_tags: []
  },
  {
    category: "Accounts Payable",
    label: "Accounts Payable",
    group: "Accounts Payable",
    include_tags: ["Accounts Payable"],
    exclude_tags: []
  },
  {
    category: "Hospitality",
    label: "Hospitality",
    group: "Hospitality",
    include_tags: ["Hospitality"],
    exclude_tags: []
  },
  {
    category: "Construction Management",
    label: "Construction Management",
    group: "Construction Management",
    include_tags: ["Construction Management"],
    exclude_tags: []
  },
  {
    category: "Quality Assurance",
    label: "Quality Assurance",
    group: "Quality Assurance",
    include_tags: ["Quality Assurance"],
    exclude_tags: []
  },
  {
    category: "Online Advertising",
    label: "Online Advertising",
    group: "Online Advertising",
    include_tags: ["Online Advertising"],
    exclude_tags: []
  },
  {
    category: "Selling",
    label: "Selling",
    group: "Selling",
    include_tags: ["Selling"],
    exclude_tags: []
  },
  {
    category: "Reports",
    label: "Reports",
    group: "Reports",
    include_tags: ["Reports"],
    exclude_tags: []
  },
  {
    category: "Financial Accounting",
    label: "Financial Accounting",
    group: "Financial Accounting",
    include_tags: ["Financial Accounting"],
    exclude_tags: []
  },
  {
    category: "Indesign",
    label: "Indesign",
    group: "Indesign",
    include_tags: ["Indesign"],
    exclude_tags: []
  },
  {
    category: "Oracle",
    label: "Oracle",
    group: "Oracle",
    include_tags: ["Oracle"],
    exclude_tags: []
  },
  {
    category: "Employee Relations",
    label: "Employee Relations",
    group: "Employee Relations",
    include_tags: ["Employee Relations"],
    exclude_tags: []
  },
  {
    category: "Facebook",
    label: "Facebook",
    group: "Facebook",
    include_tags: ["Facebook"],
    exclude_tags: []
  },
  {
    category: "Direct Sales",
    label: "Direct Sales",
    group: "Direct Sales",
    include_tags: ["Direct Sales"],
    exclude_tags: []
  },
  {
    category: "Strategic Partnerships",
    label: "Strategic Partnerships",
    group: "Strategic Partnerships",
    include_tags: ["Strategic Partnerships"],
    exclude_tags: []
  },
  {
    category: "Applications",
    label: "Applications",
    group: "Applications",
    include_tags: ["Applications"],
    exclude_tags: []
  },
  {
    category: "Xml",
    label: "Xml",
    group: "Xml",
    include_tags: ["Xml"],
    exclude_tags: []
  },
  {
    category: "Php",
    label: "Php",
    group: "Php",
    include_tags: ["Php"],
    exclude_tags: []
  },
  {
    category: "Six Sigma",
    label: "Six Sigma",
    group: "Six Sigma",
    include_tags: ["Six Sigma"],
    exclude_tags: []
  },
  {
    category: "Interviews",
    label: "Interviews",
    group: "Interviews",
    include_tags: ["Interviews"],
    exclude_tags: []
  },
  {
    category: "Sistemas Operacionais",
    label: "Sistemas Operacionais",
    group: "Sistemas Operacionais",
    include_tags: ["Sistemas Operacionais"],
    exclude_tags: []
  },
  {
    category: "Sales Process",
    label: "Sales Process",
    group: "Sales Process",
    include_tags: ["Sales Process"],
    exclude_tags: []
  },
  {
    category: "Agile Methodologies",
    label: "Agile Methodologies",
    group: "Agile Methodologies",
    include_tags: ["Agile Methodologies"],
    exclude_tags: []
  },
  {
    category: "Cloud Computing",
    label: "Cloud Computing",
    group: "Cloud Computing",
    include_tags: ["Cloud Computing"],
    exclude_tags: []
  },
  {
    category: "Invoicing",
    label: "Invoicing",
    group: "Invoicing",
    include_tags: ["Invoicing"],
    exclude_tags: []
  },
  {
    category: "Office Administration",
    label: "Office Administration",
    group: "Office Administration",
    include_tags: ["Office Administration"],
    exclude_tags: []
  },
  {
    category: "Blogging",
    label: "Blogging",
    group: "Blogging",
    include_tags: ["Blogging"],
    exclude_tags: []
  },
  {
    category: "Security Clearance",
    label: "Security Clearance",
    group: "Security Clearance",
    include_tags: ["Security Clearance"],
    exclude_tags: []
  },
  {
    category: "Web Design",
    label: "Web Design",
    group: "Web Design",
    include_tags: ["Web Design"],
    exclude_tags: []
  },
  {
    category: "Program Development",
    label: "Program Development",
    group: "Program Development",
    include_tags: ["Program Development"],
    exclude_tags: []
  },
  {
    category: "Programming",
    label: "Programming",
    group: "Programming",
    include_tags: ["Programming"],
    exclude_tags: []
  },
  {
    category: "Business Process",
    label: "Business Process",
    group: "Business Process",
    include_tags: ["Business Process"],
    exclude_tags: []
  },
  {
    category: "C#",
    label: "C#",
    group: "C#",
    include_tags: ["C#"],
    exclude_tags: []
  },
  {
    category: "Adobe Creative Suite",
    label: "Adobe Creative Suite",
    group: "Adobe Creative Suite",
    include_tags: ["Adobe Creative Suite"],
    exclude_tags: []
  },
  {
    category: "Internal Controls",
    label: "Internal Controls",
    group: "Internal Controls",
    include_tags: ["Internal Controls"],
    exclude_tags: []
  },
  {
    category: "Administration",
    label: "Administration",
    group: "Administration",
    include_tags: ["Administration"],
    exclude_tags: []
  },
  {
    category: "Estrategia De Mercadotecnia",
    label: "Estrategia De Mercadotecnia",
    group: "Estrategia De Mercadotecnia",
    include_tags: ["Estrategia De Mercadotecnia"],
    exclude_tags: []
  },
  {
    category: "Tutoring",
    label: "Tutoring",
    group: "Tutoring",
    include_tags: ["Tutoring"],
    exclude_tags: []
  },
  {
    category: "Sdlc",
    label: "Sdlc",
    group: "Sdlc",
    include_tags: ["Sdlc"],
    exclude_tags: []
  },
  {
    category: "Supervisory Skills",
    label: "Supervisory Skills",
    group: "Supervisory Skills",
    include_tags: ["Supervisory Skills"],
    exclude_tags: []
  },
  {
    category: "Accounts Receivable",
    label: "Accounts Receivable",
    group: "Accounts Receivable",
    include_tags: ["Accounts Receivable"],
    exclude_tags: []
  },
  {
    category: "Windows Server",
    label: "Windows Server",
    group: "Windows Server",
    include_tags: ["Windows Server"],
    exclude_tags: []
  },
  {
    category: "Due Diligence",
    label: "Due Diligence",
    group: "Due Diligence",
    include_tags: ["Due Diligence"],
    exclude_tags: []
  },
  {
    category: "Pricing",
    label: "Pricing",
    group: "Pricing",
    include_tags: ["Pricing"],
    exclude_tags: []
  },
  {
    category: "5s",
    label: "5s",
    group: "5s",
    include_tags: ["5s"],
    exclude_tags: []
  },
  {
    category: "Energy",
    label: "Energy",
    group: "Energy",
    include_tags: ["Energy"],
    exclude_tags: []
  },
  {
    category: "Mercadotecnia",
    label: "Mercadotecnia",
    group: "Mercadotecnia",
    include_tags: ["Mercadotecnia"],
    exclude_tags: []
  },
  {
    category: "Project Engineering",
    label: "Project Engineering",
    group: "Project Engineering",
    include_tags: ["Project Engineering"],
    exclude_tags: []
  },
  {
    category: "Portfolio Management",
    label: "Portfolio Management",
    group: "Portfolio Management",
    include_tags: ["Portfolio Management"],
    exclude_tags: []
  },
  {
    category: "Educational Leadership",
    label: "Educational Leadership",
    group: "Educational Leadership",
    include_tags: ["Educational Leadership"],
    exclude_tags: []
  },
  {
    category: "Financial Modeling",
    label: "Financial Modeling",
    group: "Financial Modeling",
    include_tags: ["Financial Modeling"],
    exclude_tags: []
  },
  {
    category: "Transportation",
    label: "Transportation",
    group: "Transportation",
    include_tags: ["Transportation"],
    exclude_tags: []
  },
  {
    category: "Vente",
    label: "Vente",
    group: "Vente",
    include_tags: ["Vente"],
    exclude_tags: []
  },
  {
    category: "Cashiering",
    label: "Cashiering",
    group: "Cashiering",
    include_tags: ["Cashiering"],
    exclude_tags: []
  },
  {
    category: "Legal Writing",
    label: "Legal Writing",
    group: "Legal Writing",
    include_tags: ["Legal Writing"],
    exclude_tags: []
  },
  {
    category: "Oratoria",
    label: "Oratoria",
    group: "Oratoria",
    include_tags: ["Oratoria"],
    exclude_tags: []
  },
  {
    category: "Classroom",
    label: "Classroom",
    group: "Classroom",
    include_tags: ["Classroom"],
    exclude_tags: []
  },
  {
    category: "Enterprise Software",
    label: "Enterprise Software",
    group: "Enterprise Software",
    include_tags: ["Enterprise Software"],
    exclude_tags: []
  },
  {
    category: "Financial Services",
    label: "Financial Services",
    group: "Financial Services",
    include_tags: ["Financial Services"],
    exclude_tags: []
  },
  {
    category: "Net",
    label: "Net",
    group: "Net",
    include_tags: ["Net"],
    exclude_tags: []
  },
  {
    category: "Adobe Photoshop",
    label: "Adobe Photoshop",
    group: "Adobe Photoshop",
    include_tags: ["Adobe Photoshop"],
    exclude_tags: []
  },
  {
    category: "Product Marketing",
    label: "Product Marketing",
    group: "Product Marketing",
    include_tags: ["Product Marketing"],
    exclude_tags: []
  },
  {
    category: "Nursing",
    label: "Nursing",
    group: "Nursing",
    include_tags: ["Nursing"],
    exclude_tags: []
  },
  {
    category: "Legal Research",
    label: "Legal Research",
    group: "Legal Research",
    include_tags: ["Legal Research"],
    exclude_tags: []
  },
  {
    category: "E Commerce",
    label: "E Commerce",
    group: "E Commerce",
    include_tags: ["E Commerce"],
    exclude_tags: []
  },
  {
    category: "Community",
    label: "Community",
    group: "Community",
    include_tags: ["Community"],
    exclude_tags: []
  },
  {
    category: "Yahoo",
    label: "Yahoo",
    group: "Yahoo",
    include_tags: ["Yahoo"],
    exclude_tags: []
  },
  {
    category: "Corporate Finance",
    label: "Corporate Finance",
    group: "Corporate Finance",
    include_tags: ["Corporate Finance"],
    exclude_tags: []
  },
  {
    category: "Fashion",
    label: "Fashion",
    group: "Fashion",
    include_tags: ["Fashion"],
    exclude_tags: []
  },
  {
    category: "Information Technology",
    label: "Information Technology",
    group: "Information Technology",
    include_tags: ["Information Technology"],
    exclude_tags: []
  },
  {
    category: "Operating Systems",
    label: "Operating Systems",
    group: "Operating Systems",
    include_tags: ["Operating Systems"],
    exclude_tags: []
  },
  {
    category: "Email Marketing",
    label: "Email Marketing",
    group: "Email Marketing",
    include_tags: ["Email Marketing"],
    exclude_tags: []
  },
  {
    category: "Itil",
    label: "Itil",
    group: "Itil",
    include_tags: ["Itil"],
    exclude_tags: []
  },
  {
    category: "Inventory",
    label: "Inventory",
    group: "Inventory",
    include_tags: ["Inventory"],
    exclude_tags: []
  },
  {
    category: "Presentations",
    label: "Presentations",
    group: "Presentations",
    include_tags: ["Presentations"],
    exclude_tags: []
  },
  {
    category: "Active Directory",
    label: "Active Directory",
    group: "Active Directory",
    include_tags: ["Active Directory"],
    exclude_tags: []
  },
  {
    category: "Restaurants",
    label: "Restaurants",
    group: "Restaurants",
    include_tags: ["Restaurants"],
    exclude_tags: []
  },
  {
    category: "Erp",
    label: "Erp",
    group: "Erp",
    include_tags: ["Erp"],
    exclude_tags: []
  },
  {
    category: "Web Development",
    label: "Web Development",
    group: "Web Development",
    include_tags: ["Web Development"],
    exclude_tags: []
  },
  {
    category: "Python",
    label: "Python",
    group: "Python",
    include_tags: ["Python"],
    exclude_tags: []
  },
  {
    category: "Statistics",
    label: "Statistics",
    group: "Statistics",
    include_tags: ["Statistics"],
    exclude_tags: []
  },
  {
    category: "Pesquisa",
    label: "Pesquisa",
    group: "Pesquisa",
    include_tags: ["Pesquisa"],
    exclude_tags: []
  },
  {
    category: "Hotels",
    label: "Hotels",
    group: "Hotels",
    include_tags: ["Hotels"],
    exclude_tags: []
  },
  {
    category: "Competitive Analysis",
    label: "Competitive Analysis",
    group: "Competitive Analysis",
    include_tags: ["Competitive Analysis"],
    exclude_tags: []
  },
  {
    category: "Digital Media",
    label: "Digital Media",
    group: "Digital Media",
    include_tags: ["Digital Media"],
    exclude_tags: []
  },
  {
    category: "Portuguese",
    label: "Portuguese",
    group: "Portuguese",
    include_tags: ["Portuguese"],
    exclude_tags: []
  },
  {
    category: "Software Project Management",
    label: "Software Project Management",
    group: "Software Project Management",
    include_tags: ["Software Project Management"],
    exclude_tags: []
  },
  {
    category: "Jquery",
    label: "Jquery",
    group: "Jquery",
    include_tags: ["Jquery"],
    exclude_tags: []
  },
  {
    category: "Litigation",
    label: "Litigation",
    group: "Litigation",
    include_tags: ["Litigation"],
    exclude_tags: []
  },
  {
    category: "Assistenza Clienti",
    label: "Assistenza Clienti",
    group: "Assistenza Clienti",
    include_tags: ["Assistenza Clienti"],
    exclude_tags: []
  },
  {
    category: "Automotive",
    label: "Automotive",
    group: "Automotive",
    include_tags: ["Automotive"],
    exclude_tags: []
  },
  {
    category: "Petroleum",
    label: "Petroleum",
    group: "Petroleum",
    include_tags: ["Petroleum"],
    exclude_tags: []
  },
  {
    category: "Loans",
    label: "Loans",
    group: "Loans",
    include_tags: ["Loans"],
    exclude_tags: []
  },
  {
    category: "Creative Writing",
    label: "Creative Writing",
    group: "Creative Writing",
    include_tags: ["Creative Writing"],
    exclude_tags: []
  },
  {
    category: "Tax",
    label: "Tax",
    group: "Tax",
    include_tags: ["Tax"],
    exclude_tags: []
  },
  {
    category: "Unix",
    label: "Unix",
    group: "Unix",
    include_tags: ["Unix"],
    exclude_tags: []
  },
  {
    category: "Music",
    label: "Music",
    group: "Music",
    include_tags: ["Music"],
    exclude_tags: []
  },
  {
    category: "Account Reconciliation",
    label: "Account Reconciliation",
    group: "Account Reconciliation",
    include_tags: ["Account Reconciliation"],
    exclude_tags: []
  },
  {
    category: "Planeamiento De Proyectos",
    label: "Planeamiento De Proyectos",
    group: "Planeamiento De Proyectos",
    include_tags: ["Planeamiento De Proyectos"],
    exclude_tags: []
  },
  {
    category: "Computer Hardware",
    label: "Computer Hardware",
    group: "Computer Hardware",
    include_tags: ["Computer Hardware"],
    exclude_tags: []
  },
  {
    category: "Employee Training",
    label: "Employee Training",
    group: "Employee Training",
    include_tags: ["Employee Training"],
    exclude_tags: []
  },
  {
    category: "History",
    label: "History",
    group: "History",
    include_tags: ["History"],
    exclude_tags: []
  },
  {
    category: "Warehousing",
    label: "Warehousing",
    group: "Warehousing",
    include_tags: ["Warehousing"],
    exclude_tags: []
  },
  {
    category: "International",
    label: "International",
    group: "International",
    include_tags: ["International"],
    exclude_tags: []
  },
  {
    category: "Marketing Management",
    label: "Marketing Management",
    group: "Marketing Management",
    include_tags: ["Marketing Management"],
    exclude_tags: []
  },
  {
    category: "Talent Management",
    label: "Talent Management",
    group: "Talent Management",
    include_tags: ["Talent Management"],
    exclude_tags: []
  },
  {
    category: "University Teaching",
    label: "University Teaching",
    group: "University Teaching",
    include_tags: ["University Teaching"],
    exclude_tags: []
  },
  {
    category: "Cpr Certified",
    label: "Cpr Certified",
    group: "Cpr Certified",
    include_tags: ["Cpr Certified"],
    exclude_tags: []
  },
  {
    category: "Sports",
    label: "Sports",
    group: "Sports",
    include_tags: ["Sports"],
    exclude_tags: []
  },
  {
    category: "Inspection",
    label: "Inspection",
    group: "Inspection",
    include_tags: ["Inspection"],
    exclude_tags: []
  },
  {
    category: "Volunteer Management",
    label: "Volunteer Management",
    group: "Volunteer Management",
    include_tags: ["Volunteer Management"],
    exclude_tags: []
  },
  {
    category: "Lesson Planning",
    label: "Lesson Planning",
    group: "Lesson Planning",
    include_tags: ["Lesson Planning"],
    exclude_tags: []
  },
  {
    category: "Sourcing",
    label: "Sourcing",
    group: "Sourcing",
    include_tags: ["Sourcing"],
    exclude_tags: []
  },
  {
    category: "R",
    label: "R",
    group: "R",
    include_tags: ["R"],
    exclude_tags: []
  },
  {
    category: "Employee Benefits",
    label: "Employee Benefits",
    group: "Employee Benefits",
    include_tags: ["Employee Benefits"],
    exclude_tags: []
  },
  {
    category: "System Administration",
    label: "System Administration",
    group: "System Administration",
    include_tags: ["System Administration"],
    exclude_tags: []
  },
  {
    category: "Servers",
    label: "Servers",
    group: "Servers",
    include_tags: ["Servers"],
    exclude_tags: []
  },
  {
    category: "Commissioning",
    label: "Commissioning",
    group: "Commissioning",
    include_tags: ["Commissioning"],
    exclude_tags: []
  },
  {
    category: "Salesforce.com",
    label: "Salesforce.com",
    group: "Salesforce.com",
    include_tags: ["Salesforce.com"],
    exclude_tags: []
  },
  {
    category: "Film",
    label: "Film",
    group: "Film",
    include_tags: ["Film"],
    exclude_tags: []
  },
  {
    category: "Mis",
    label: "Mis",
    group: "Mis",
    include_tags: ["Mis"],
    exclude_tags: []
  },
  {
    category: "Education",
    label: "Education",
    group: "Education",
    include_tags: ["Education"],
    exclude_tags: []
  },
  {
    category: "Seo",
    label: "Seo",
    group: "Seo",
    include_tags: ["Seo"],
    exclude_tags: []
  },
  {
    category: "Food And Beverage",
    label: "Food And Beverage",
    group: "Food And Beverage",
    include_tags: ["Food And Beverage"],
    exclude_tags: []
  },
  {
    category: "Food",
    label: "Food",
    group: "Food",
    include_tags: ["Food"],
    exclude_tags: []
  },
  {
    category: "Schedules",
    label: "Schedules",
    group: "Schedules",
    include_tags: ["Schedules"],
    exclude_tags: []
  },
  {
    category: "Access",
    label: "Access",
    group: "Access",
    include_tags: ["Access"],
    exclude_tags: []
  },
  {
    category: "Cad",
    label: "Cad",
    group: "Cad",
    include_tags: ["Cad"],
    exclude_tags: []
  },
  {
    category: "Visual Merchandising",
    label: "Visual Merchandising",
    group: "Visual Merchandising",
    include_tags: ["Visual Merchandising"],
    exclude_tags: []
  },
  {
    category: "Television",
    label: "Television",
    group: "Television",
    include_tags: ["Television"],
    exclude_tags: []
  },
  {
    category: "Budgeting",
    label: "Budgeting",
    group: "Budgeting",
    include_tags: ["Budgeting"],
    exclude_tags: []
  },
  {
    category: "Microsoft Windows",
    label: "Microsoft Windows",
    group: "Microsoft Windows",
    include_tags: ["Microsoft Windows"],
    exclude_tags: []
  },
  {
    category: "Microsoft Project",
    label: "Microsoft Project",
    group: "Microsoft Project",
    include_tags: ["Microsoft Project"],
    exclude_tags: []
  },
  {
    category: "Executive Management",
    label: "Executive Management",
    group: "Executive Management",
    include_tags: ["Executive Management"],
    exclude_tags: []
  },
  {
    category: "Pharmaceutical Industry",
    label: "Pharmaceutical Industry",
    group: "Pharmaceutical Industry",
    include_tags: ["Pharmaceutical Industry"],
    exclude_tags: []
  },
  {
    category: "Educational Technology",
    label: "Educational Technology",
    group: "Educational Technology",
    include_tags: ["Educational Technology"],
    exclude_tags: []
  },
  {
    category: "Talent Acquisition",
    label: "Talent Acquisition",
    group: "Talent Acquisition",
    include_tags: ["Talent Acquisition"],
    exclude_tags: []
  },
  {
    category: "Call Centers",
    label: "Call Centers",
    group: "Call Centers",
    include_tags: ["Call Centers"],
    exclude_tags: []
  },
  {
    category: "Civil Engineering",
    label: "Civil Engineering",
    group: "Civil Engineering",
    include_tags: ["Civil Engineering"],
    exclude_tags: []
  },
  {
    category: "Quickbooks",
    label: "Quickbooks",
    group: "Quickbooks",
    include_tags: ["Quickbooks"],
    exclude_tags: []
  },
  {
    category: "Materials",
    label: "Materials",
    group: "Materials",
    include_tags: ["Materials"],
    exclude_tags: []
  },
  {
    category: "Copywriting",
    label: "Copywriting",
    group: "Copywriting",
    include_tags: ["Copywriting"],
    exclude_tags: []
  },
  {
    category: "Hardware",
    label: "Hardware",
    group: "Hardware",
    include_tags: ["Hardware"],
    exclude_tags: []
  },
  {
    category: "Docencia",
    label: "Docencia",
    group: "Docencia",
    include_tags: ["Docencia"],
    exclude_tags: []
  },
  {
    category: "Languages",
    label: "Languages",
    group: "Languages",
    include_tags: ["Languages"],
    exclude_tags: []
  },
  {
    category: "Solution Selling",
    label: "Solution Selling",
    group: "Solution Selling",
    include_tags: ["Solution Selling"],
    exclude_tags: []
  },
  {
    category: "Journalism",
    label: "Journalism",
    group: "Journalism",
    include_tags: ["Journalism"],
    exclude_tags: []
  },
  {
    category: "Gas",
    label: "Gas",
    group: "Gas",
    include_tags: ["Gas"],
    exclude_tags: []
  },
  {
    category: "Office Management",
    label: "Office Management",
    group: "Office Management",
    include_tags: ["Office Management"],
    exclude_tags: []
  },
  {
    category: "Dod",
    label: "Dod",
    group: "Dod",
    include_tags: ["Dod"],
    exclude_tags: []
  },
  {
    category: "Formation",
    label: "Formation",
    group: "Formation",
    include_tags: ["Formation"],
    exclude_tags: []
  },
  {
    category: "Teacher Training",
    label: "Teacher Training",
    group: "Teacher Training",
    include_tags: ["Teacher Training"],
    exclude_tags: []
  },
  {
    category: "Solidworks",
    label: "Solidworks",
    group: "Solidworks",
    include_tags: ["Solidworks"],
    exclude_tags: []
  },
  {
    category: "Wordpress",
    label: "Wordpress",
    group: "Wordpress",
    include_tags: ["Wordpress"],
    exclude_tags: []
  },
  {
    category: "French",
    label: "French",
    group: "French",
    include_tags: ["French"],
    exclude_tags: []
  },
  {
    category: "Classroom Management",
    label: "Classroom Management",
    group: "Classroom Management",
    include_tags: ["Classroom Management"],
    exclude_tags: []
  },
  {
    category: "Military Operations",
    label: "Military Operations",
    group: "Military Operations",
    include_tags: ["Military Operations"],
    exclude_tags: []
  },
  {
    category: "Cell",
    label: "Cell",
    group: "Cell",
    include_tags: ["Cell"],
    exclude_tags: []
  },
  {
    category: "Shipping",
    label: "Shipping",
    group: "Shipping",
    include_tags: ["Shipping"],
    exclude_tags: []
  },
  {
    category: "Hospitality Industry",
    label: "Hospitality Industry",
    group: "Hospitality Industry",
    include_tags: ["Hospitality Industry"],
    exclude_tags: []
  },
  {
    category: "Hospitality Management",
    label: "Hospitality Management",
    group: "Hospitality Management",
    include_tags: ["Hospitality Management"],
    exclude_tags: []
  },
  {
    category: "Automation",
    label: "Automation",
    group: "Automation",
    include_tags: ["Automation"],
    exclude_tags: []
  },
  {
    category: "Entertainment",
    label: "Entertainment",
    group: "Entertainment",
    include_tags: ["Entertainment"],
    exclude_tags: []
  },
  {
    category: "Grant Writing",
    label: "Grant Writing",
    group: "Grant Writing",
    include_tags: ["Grant Writing"],
    exclude_tags: []
  },
  {
    category: "Students",
    label: "Students",
    group: "Students",
    include_tags: ["Students"],
    exclude_tags: []
  },
  {
    category: "It Strategy",
    label: "It Strategy",
    group: "It Strategy",
    include_tags: ["It Strategy"],
    exclude_tags: []
  },
  {
    category: "Media Relations",
    label: "Media Relations",
    group: "Media Relations",
    include_tags: ["Media Relations"],
    exclude_tags: []
  },
  {
    category: "Video",
    label: "Video",
    group: "Video",
    include_tags: ["Video"],
    exclude_tags: []
  },
  {
    category: "Proposal Writing",
    label: "Proposal Writing",
    group: "Proposal Writing",
    include_tags: ["Proposal Writing"],
    exclude_tags: []
  },
  {
    category: "Disaster Recovery",
    label: "Disaster Recovery",
    group: "Disaster Recovery",
    include_tags: ["Disaster Recovery"],
    exclude_tags: []
  },
  {
    category: "Catering",
    label: "Catering",
    group: "Catering",
    include_tags: ["Catering"],
    exclude_tags: []
  },
  {
    category: "Analytics",
    label: "Analytics",
    group: "Analytics",
    include_tags: ["Analytics"],
    exclude_tags: []
  },
  {
    category: "Software Documentation",
    label: "Software Documentation",
    group: "Software Documentation",
    include_tags: ["Software Documentation"],
    exclude_tags: []
  },
  {
    category: "Inventory Control",
    label: "Inventory Control",
    group: "Inventory Control",
    include_tags: ["Inventory Control"],
    exclude_tags: []
  },
  {
    category: "Compliance",
    label: "Compliance",
    group: "Compliance",
    include_tags: ["Compliance"],
    exclude_tags: []
  },
  {
    category: "Corporate Law",
    label: "Corporate Law",
    group: "Corporate Law",
    include_tags: ["Corporate Law"],
    exclude_tags: []
  },
  {
    category: "Logistics Management",
    label: "Logistics Management",
    group: "Logistics Management",
    include_tags: ["Logistics Management"],
    exclude_tags: []
  },
  {
    category: "Sharepoint",
    label: "Sharepoint",
    group: "Sharepoint",
    include_tags: ["Sharepoint"],
    exclude_tags: []
  },
  {
    category: "Ms Project",
    label: "Ms Project",
    group: "Ms Project",
    include_tags: ["Ms Project"],
    exclude_tags: []
  },
  {
    category: "Dutch",
    label: "Dutch",
    group: "Dutch",
    include_tags: ["Dutch"],
    exclude_tags: []
  },
  {
    category: "Saas",
    label: "Saas",
    group: "Saas",
    include_tags: ["Saas"],
    exclude_tags: []
  },
  {
    category: "Windows 7",
    label: "Windows 7",
    group: "Windows 7",
    include_tags: ["Windows 7"],
    exclude_tags: []
  },
  {
    category: "Scrum",
    label: "Scrum",
    group: "Scrum",
    include_tags: ["Scrum"],
    exclude_tags: []
  },
  {
    category: "Patient Safety",
    label: "Patient Safety",
    group: "Patient Safety",
    include_tags: ["Patient Safety"],
    exclude_tags: []
  },
  {
    category: "Economics",
    label: "Economics",
    group: "Economics",
    include_tags: ["Economics"],
    exclude_tags: []
  },
  {
    category: "It Management",
    label: "It Management",
    group: "It Management",
    include_tags: ["It Management"],
    exclude_tags: []
  },
  {
    category: "Data Center",
    label: "Data Center",
    group: "Data Center",
    include_tags: ["Data Center"],
    exclude_tags: []
  },
  {
    category: "Hiring",
    label: "Hiring",
    group: "Hiring",
    include_tags: ["Hiring"],
    exclude_tags: []
  },
  {
    category: "Civil Litigation",
    label: "Civil Litigation",
    group: "Civil Litigation",
    include_tags: ["Civil Litigation"],
    exclude_tags: []
  },
  {
    category: "Video Production",
    label: "Video Production",
    group: "Video Production",
    include_tags: ["Video Production"],
    exclude_tags: []
  },
  {
    category: "Contractual Agreements",
    label: "Contractual Agreements",
    group: "Contractual Agreements",
    include_tags: ["Contractual Agreements"],
    exclude_tags: []
  },
  {
    category: "Web Services",
    label: "Web Services",
    group: "Web Services",
    include_tags: ["Web Services"],
    exclude_tags: []
  },
  {
    category: "Customer Experience",
    label: "Customer Experience",
    group: "Customer Experience",
    include_tags: ["Customer Experience"],
    exclude_tags: []
  },
  {
    category: "Documentation",
    label: "Documentation",
    group: "Documentation",
    include_tags: ["Documentation"],
    exclude_tags: []
  },
  {
    category: "Contractors",
    label: "Contractors",
    group: "Contractors",
    include_tags: ["Contractors"],
    exclude_tags: []
  },
  {
    category: "Press Releases",
    label: "Press Releases",
    group: "Press Releases",
    include_tags: ["Press Releases"],
    exclude_tags: []
  },
  {
    category: "Mentoring",
    label: "Mentoring",
    group: "Mentoring",
    include_tags: ["Mentoring"],
    exclude_tags: []
  },
  {
    category: "Meetings",
    label: "Meetings",
    group: "Meetings",
    include_tags: ["Meetings"],
    exclude_tags: []
  },
  {
    category: "Investment Properties",
    label: "Investment Properties",
    group: "Investment Properties",
    include_tags: ["Investment Properties"],
    exclude_tags: []
  },
  {
    category: "Computer Proficiency",
    label: "Computer Proficiency",
    group: "Computer Proficiency",
    include_tags: ["Computer Proficiency"],
    exclude_tags: []
  },
  {
    category: "Retail Sales",
    label: "Retail Sales",
    group: "Retail Sales",
    include_tags: ["Retail Sales"],
    exclude_tags: []
  },
  {
    category: "Healthcare Information Technology",
    label: "Healthcare Information Technology",
    group: "Healthcare Information Technology",
    include_tags: ["Healthcare Information Technology"],
    exclude_tags: []
  },
  {
    category: "Military Experience",
    label: "Military Experience",
    group: "Military Experience",
    include_tags: ["Military Experience"],
    exclude_tags: []
  },
  {
    category: "Mental Health",
    label: "Mental Health",
    group: "Mental Health",
    include_tags: ["Mental Health"],
    exclude_tags: []
  },
  {
    category: "Mechanical Engineering",
    label: "Mechanical Engineering",
    group: "Mechanical Engineering",
    include_tags: ["Mechanical Engineering"],
    exclude_tags: []
  },
  {
    category: "Public Policy",
    label: "Public Policy",
    group: "Public Policy",
    include_tags: ["Public Policy"],
    exclude_tags: []
  },
  {
    category: "Strategic Communications",
    label: "Strategic Communications",
    group: "Strategic Communications",
    include_tags: ["Strategic Communications"],
    exclude_tags: []
  },
  {
    category: "Visio",
    label: "Visio",
    group: "Visio",
    include_tags: ["Visio"],
    exclude_tags: []
  },
  {
    category: "Customer Retention",
    label: "Customer Retention",
    group: "Customer Retention",
    include_tags: ["Customer Retention"],
    exclude_tags: []
  },
  {
    category: "Residential Homes",
    label: "Residential Homes",
    group: "Residential Homes",
    include_tags: ["Residential Homes"],
    exclude_tags: []
  },
  {
    category: "Dos",
    label: "Dos",
    group: "Dos",
    include_tags: ["Dos"],
    exclude_tags: []
  },
  {
    category: "E Learning",
    label: "E Learning",
    group: "E Learning",
    include_tags: ["E Learning"],
    exclude_tags: []
  },
  {
    category: "Coordination",
    label: "Coordination",
    group: "Coordination",
    include_tags: ["Coordination"],
    exclude_tags: []
  },
  {
    category: "Psychology",
    label: "Psychology",
    group: "Psychology",
    include_tags: ["Psychology"],
    exclude_tags: []
  },
  {
    category: "Store Management",
    label: "Store Management",
    group: "Store Management",
    include_tags: ["Store Management"],
    exclude_tags: []
  },
  {
    category: "Qualitative Research",
    label: "Qualitative Research",
    group: "Qualitative Research",
    include_tags: ["Qualitative Research"],
    exclude_tags: []
  },
  {
    category: "Financial Statements",
    label: "Financial Statements",
    group: "Financial Statements",
    include_tags: ["Financial Statements"],
    exclude_tags: []
  },
  {
    category: "Reporting",
    label: "Reporting",
    group: "Reporting",
    include_tags: ["Reporting"],
    exclude_tags: []
  },
  {
    category: "Administrative Assistants",
    label: "Administrative Assistants",
    group: "Administrative Assistants",
    include_tags: ["Administrative Assistants"],
    exclude_tags: []
  },
  {
    category: "Brand Development",
    label: "Brand Development",
    group: "Brand Development",
    include_tags: ["Brand Development"],
    exclude_tags: []
  },
  {
    category: "Quality Control",
    label: "Quality Control",
    group: "Quality Control",
    include_tags: ["Quality Control"],
    exclude_tags: []
  },
  {
    category: "Gerencia De Ventas",
    label: "Gerencia De Ventas",
    group: "Gerencia De Ventas",
    include_tags: ["Gerencia De Ventas"],
    exclude_tags: []
  },
  {
    category: "Creative Direction",
    label: "Creative Direction",
    group: "Creative Direction",
    include_tags: ["Creative Direction"],
    exclude_tags: []
  },
  {
    category: "Wireless",
    label: "Wireless",
    group: "Wireless",
    include_tags: ["Wireless"],
    exclude_tags: []
  },
  {
    category: "Non Profits",
    label: "Non Profits",
    group: "Non Profits",
    include_tags: ["Non Profits"],
    exclude_tags: []
  },
  {
    category: "It Service Management",
    label: "It Service Management",
    group: "It Service Management",
    include_tags: ["It Service Management"],
    exclude_tags: []
  },
  {
    category: "Plan De Negocio",
    label: "Plan De Negocio",
    group: "Plan De Negocio",
    include_tags: ["Plan De Negocio"],
    exclude_tags: []
  },
  {
    category: "Apparel",
    label: "Apparel",
    group: "Apparel",
    include_tags: ["Apparel"],
    exclude_tags: []
  },
  {
    category: "Employee Engagement",
    label: "Employee Engagement",
    group: "Employee Engagement",
    include_tags: ["Employee Engagement"],
    exclude_tags: []
  },
  {
    category: "Defense",
    label: "Defense",
    group: "Defense",
    include_tags: ["Defense"],
    exclude_tags: []
  },
  {
    category: "Managerial Finance",
    label: "Managerial Finance",
    group: "Managerial Finance",
    include_tags: ["Managerial Finance"],
    exclude_tags: []
  },
  {
    category: "Elementary Education",
    label: "Elementary Education",
    group: "Elementary Education",
    include_tags: ["Elementary Education"],
    exclude_tags: []
  },
  {
    category: "Commercial Litigation",
    label: "Commercial Litigation",
    group: "Commercial Litigation",
    include_tags: ["Commercial Litigation"],
    exclude_tags: []
  },
  {
    category: "General Ledger",
    label: "General Ledger",
    group: "General Ledger",
    include_tags: ["General Ledger"],
    exclude_tags: []
  },
  {
    category: "Outsourcing",
    label: "Outsourcing",
    group: "Outsourcing",
    include_tags: ["Outsourcing"],
    exclude_tags: []
  },
  {
    category: "Road",
    label: "Road",
    group: "Road",
    include_tags: ["Road"],
    exclude_tags: []
  },
  {
    category: "Profit",
    label: "Profit",
    group: "Profit",
    include_tags: ["Profit"],
    exclude_tags: []
  },
  {
    category: "Vendors",
    label: "Vendors",
    group: "Vendors",
    include_tags: ["Vendors"],
    exclude_tags: []
  },
  {
    category: "Administrative Assistance",
    label: "Administrative Assistance",
    group: "Administrative Assistance",
    include_tags: ["Administrative Assistance"],
    exclude_tags: []
  },
  {
    category: "Calls",
    label: "Calls",
    group: "Calls",
    include_tags: ["Calls"],
    exclude_tags: []
  },
  {
    category: "Virtualization",
    label: "Virtualization",
    group: "Virtualization",
    include_tags: ["Virtualization"],
    exclude_tags: []
  },
  {
    category: "Tourism",
    label: "Tourism",
    group: "Tourism",
    include_tags: ["Tourism"],
    exclude_tags: []
  },
  {
    category: "Retail Banking",
    label: "Retail Banking",
    group: "Retail Banking",
    include_tags: ["Retail Banking"],
    exclude_tags: []
  },
  {
    category: "Home",
    label: "Home",
    group: "Home",
    include_tags: ["Home"],
    exclude_tags: []
  },
  {
    category: "Policy Analysis",
    label: "Policy Analysis",
    group: "Policy Analysis",
    include_tags: ["Policy Analysis"],
    exclude_tags: []
  },
  {
    category: "Architecture",
    label: "Architecture",
    group: "Architecture",
    include_tags: ["Architecture"],
    exclude_tags: []
  },
  {
    category: "Obtain",
    label: "Obtain",
    group: "Obtain",
    include_tags: ["Obtain"],
    exclude_tags: []
  },
  {
    category: "Command",
    label: "Command",
    group: "Command",
    include_tags: ["Command"],
    exclude_tags: []
  },
  {
    category: "Integrated Marketing",
    label: "Integrated Marketing",
    group: "Integrated Marketing",
    include_tags: ["Integrated Marketing"],
    exclude_tags: []
  },
  {
    category: "Legal Advice",
    label: "Legal Advice",
    group: "Legal Advice",
    include_tags: ["Legal Advice"],
    exclude_tags: []
  },
  {
    category: "Vmware",
    label: "Vmware",
    group: "Vmware",
    include_tags: ["Vmware"],
    exclude_tags: []
  },
  {
    category: "Organizational Structure",
    label: "Organizational Structure",
    group: "Organizational Structure",
    include_tags: ["Organizational Structure"],
    exclude_tags: []
  },
  {
    category: "Entrenamiento",
    label: "Entrenamiento",
    group: "Entrenamiento",
    include_tags: ["Entrenamiento"],
    exclude_tags: []
  },
  {
    category: "Electricians",
    label: "Electricians",
    group: "Electricians",
    include_tags: ["Electricians"],
    exclude_tags: []
  },
  {
    category: "Cooking",
    label: "Cooking",
    group: "Cooking",
    include_tags: ["Cooking"],
    exclude_tags: []
  },
  {
    category: "Maintenance And Repair",
    label: "Maintenance And Repair",
    group: "Maintenance And Repair",
    include_tags: ["Maintenance And Repair"],
    exclude_tags: []
  },
  {
    category: "Valuation",
    label: "Valuation",
    group: "Valuation",
    include_tags: ["Valuation"],
    exclude_tags: []
  },
  {
    category: "Public Health",
    label: "Public Health",
    group: "Public Health",
    include_tags: ["Public Health"],
    exclude_tags: []
  },
  {
    category: "Electrical Engineering",
    label: "Electrical Engineering",
    group: "Electrical Engineering",
    include_tags: ["Electrical Engineering"],
    exclude_tags: []
  },
  {
    category: "Video Editing",
    label: "Video Editing",
    group: "Video Editing",
    include_tags: ["Video Editing"],
    exclude_tags: []
  },
  {
    category: "Work Ethic",
    label: "Work Ethic",
    group: "Work Ethic",
    include_tags: ["Work Ethic"],
    exclude_tags: []
  },
  {
    category: "Corporate Communications",
    label: "Corporate Communications",
    group: "Corporate Communications",
    include_tags: ["Corporate Communications"],
    exclude_tags: []
  },
  {
    category: "Brand Management",
    label: "Brand Management",
    group: "Brand Management",
    include_tags: ["Brand Management"],
    exclude_tags: []
  },
  {
    category: "Treatment",
    label: "Treatment",
    group: "Treatment",
    include_tags: ["Treatment"],
    exclude_tags: []
  },
  {
    category: "Commercial Real Estate",
    label: "Commercial Real Estate",
    group: "Commercial Real Estate",
    include_tags: ["Commercial Real Estate"],
    exclude_tags: []
  },
  {
    category: "Customer Relations",
    label: "Customer Relations",
    group: "Customer Relations",
    include_tags: ["Customer Relations"],
    exclude_tags: []
  },
  {
    category: "Emergency Management",
    label: "Emergency Management",
    group: "Emergency Management",
    include_tags: ["Emergency Management"],
    exclude_tags: []
  },
  {
    category: "Lead Generation",
    label: "Lead Generation",
    group: "Lead Generation",
    include_tags: ["Lead Generation"],
    exclude_tags: []
  },
  {
    category: "Scheduling",
    label: "Scheduling",
    group: "Scheduling",
    include_tags: ["Scheduling"],
    exclude_tags: []
  },
  {
    category: "Medical Education",
    label: "Medical Education",
    group: "Medical Education",
    include_tags: ["Medical Education"],
    exclude_tags: []
  },
  {
    category: "Network Administration",
    label: "Network Administration",
    group: "Network Administration",
    include_tags: ["Network Administration"],
    exclude_tags: []
  },
  {
    category: "Coordinated",
    label: "Coordinated",
    group: "Coordinated",
    include_tags: ["Coordinated"],
    exclude_tags: []
  },
  {
    category: "Mobile Devices",
    label: "Mobile Devices",
    group: "Mobile Devices",
    include_tags: ["Mobile Devices"],
    exclude_tags: []
  },
  {
    category: "Academic",
    label: "Academic",
    group: "Academic",
    include_tags: ["Academic"],
    exclude_tags: []
  },
  {
    category: "Army",
    label: "Army",
    group: "Army",
    include_tags: ["Army"],
    exclude_tags: []
  },
  {
    category: "Facilities Management",
    label: "Facilities Management",
    group: "Facilities Management",
    include_tags: ["Facilities Management"],
    exclude_tags: []
  },
  {
    category: "Real Estate Transactions",
    label: "Real Estate Transactions",
    group: "Real Estate Transactions",
    include_tags: ["Real Estate Transactions"],
    exclude_tags: []
  },
  {
    category: "Personnel Management",
    label: "Personnel Management",
    group: "Personnel Management",
    include_tags: ["Personnel Management"],
    exclude_tags: []
  },
  {
    category: "Instructional Design",
    label: "Instructional Design",
    group: "Instructional Design",
    include_tags: ["Instructional Design"],
    exclude_tags: []
  },
  {
    category: "Market",
    label: "Market",
    group: "Market",
    include_tags: ["Market"],
    exclude_tags: []
  },
  {
    category: "Cold Calling",
    label: "Cold Calling",
    group: "Cold Calling",
    include_tags: ["Cold Calling"],
    exclude_tags: []
  },
  {
    category: "Pl/sql",
    label: "Pl/sql",
    group: "Pl/sql",
    include_tags: ["Pl/sql"],
    exclude_tags: []
  },
  {
    category: "Mathematics",
    label: "Mathematics",
    group: "Mathematics",
    include_tags: ["Mathematics"],
    exclude_tags: []
  },
  {
    category: "Microsoft Exchange",
    label: "Microsoft Exchange",
    group: "Microsoft Exchange",
    include_tags: ["Microsoft Exchange"],
    exclude_tags: []
  },
  {
    category: "International Sales",
    label: "International Sales",
    group: "International Sales",
    include_tags: ["International Sales"],
    exclude_tags: []
  },
  {
    category: "Operational Planning",
    label: "Operational Planning",
    group: "Operational Planning",
    include_tags: ["Operational Planning"],
    exclude_tags: []
  },
  {
    category: "Equities",
    label: "Equities",
    group: "Equities",
    include_tags: ["Equities"],
    exclude_tags: []
  },
  {
    category: "Bookkeeping",
    label: "Bookkeeping",
    group: "Bookkeeping",
    include_tags: ["Bookkeeping"],
    exclude_tags: []
  },
  {
    category: "Logo Design",
    label: "Logo Design",
    group: "Logo Design",
    include_tags: ["Logo Design"],
    exclude_tags: []
  },
  {
    category: "Lecturing",
    label: "Lecturing",
    group: "Lecturing",
    include_tags: ["Lecturing"],
    exclude_tags: []
  },
  {
    category: "Spss",
    label: "Spss",
    group: "Spss",
    include_tags: ["Spss"],
    exclude_tags: []
  },
  {
    category: "Hipaa",
    label: "Hipaa",
    group: "Hipaa",
    include_tags: ["Hipaa"],
    exclude_tags: []
  },
  {
    category: "Asp.net",
    label: "Asp.net",
    group: "Asp.net",
    include_tags: ["Asp.net"],
    exclude_tags: []
  },
  {
    category: "R&d",
    label: "R&d",
    group: "R&d",
    include_tags: ["R&d"],
    exclude_tags: []
  },
  {
    category: "Business Administration",
    label: "Business Administration",
    group: "Business Administration",
    include_tags: ["Business Administration"],
    exclude_tags: []
  },
  {
    category: "Financial Transactions",
    label: "Financial Transactions",
    group: "Financial Transactions",
    include_tags: ["Financial Transactions"],
    exclude_tags: []
  },
  {
    category: "Asset Management",
    label: "Asset Management",
    group: "Asset Management",
    include_tags: ["Asset Management"],
    exclude_tags: []
  },
  {
    category: "Project Estimation",
    label: "Project Estimation",
    group: "Project Estimation",
    include_tags: ["Project Estimation"],
    exclude_tags: []
  },
  {
    category: "Medical Devices",
    label: "Medical Devices",
    group: "Medical Devices",
    include_tags: ["Medical Devices"],
    exclude_tags: []
  },
  {
    category: "Drawing",
    label: "Drawing",
    group: "Drawing",
    include_tags: ["Drawing"],
    exclude_tags: []
  },
  {
    category: "Process Engineering",
    label: "Process Engineering",
    group: "Process Engineering",
    include_tags: ["Process Engineering"],
    exclude_tags: []
  },
  {
    category: "Software Installation",
    label: "Software Installation",
    group: "Software Installation",
    include_tags: ["Software Installation"],
    exclude_tags: []
  },
  {
    category: "Engineering Management",
    label: "Engineering Management",
    group: "Engineering Management",
    include_tags: ["Engineering Management"],
    exclude_tags: []
  },
  {
    category: "Filing",
    label: "Filing",
    group: "Filing",
    include_tags: ["Filing"],
    exclude_tags: []
  },
  {
    category: "International Relations",
    label: "International Relations",
    group: "International Relations",
    include_tags: ["International Relations"],
    exclude_tags: []
  },
  {
    category: "Community Development",
    label: "Community Development",
    group: "Community Development",
    include_tags: ["Community Development"],
    exclude_tags: []
  },
  {
    category: "Relationship Management",
    label: "Relationship Management",
    group: "Relationship Management",
    include_tags: ["Relationship Management"],
    exclude_tags: []
  },
  {
    category: "Sustainability",
    label: "Sustainability",
    group: "Sustainability",
    include_tags: ["Sustainability"],
    exclude_tags: []
  },
  {
    category: "Investment Banking",
    label: "Investment Banking",
    group: "Investment Banking",
    include_tags: ["Investment Banking"],
    exclude_tags: []
  },
  {
    category: "Temporary Placement",
    label: "Temporary Placement",
    group: "Temporary Placement",
    include_tags: ["Temporary Placement"],
    exclude_tags: []
  },
  {
    category: "Software Engineering",
    label: "Software Engineering",
    group: "Software Engineering",
    include_tags: ["Software Engineering"],
    exclude_tags: []
  },
  {
    category: "Onboarding",
    label: "Onboarding",
    group: "Onboarding",
    include_tags: ["Onboarding"],
    exclude_tags: []
  },
  {
    category: "Interior Design",
    label: "Interior Design",
    group: "Interior Design",
    include_tags: ["Interior Design"],
    exclude_tags: []
  },
  {
    category: "Kaizen",
    label: "Kaizen",
    group: "Kaizen",
    include_tags: ["Kaizen"],
    exclude_tags: []
  },
  {
    category: "Pre Sales",
    label: "Pre Sales",
    group: "Pre Sales",
    include_tags: ["Pre Sales"],
    exclude_tags: []
  },
  {
    category: "Critical Thinking",
    label: "Critical Thinking",
    group: "Critical Thinking",
    include_tags: ["Critical Thinking"],
    exclude_tags: []
  },
  {
    category: "Gmp",
    label: "Gmp",
    group: "Gmp",
    include_tags: ["Gmp"],
    exclude_tags: []
  },
  {
    category: "Stocks",
    label: "Stocks",
    group: "Stocks",
    include_tags: ["Stocks"],
    exclude_tags: []
  },
  {
    category: "Cash",
    label: "Cash",
    group: "Cash",
    include_tags: ["Cash"],
    exclude_tags: []
  },
  {
    category: "Credit Risk",
    label: "Credit Risk",
    group: "Credit Risk",
    include_tags: ["Credit Risk"],
    exclude_tags: []
  },
  {
    category: "Html5",
    label: "Html5",
    group: "Html5",
    include_tags: ["Html5"],
    exclude_tags: []
  },
  {
    category: "Anglais",
    label: "Anglais",
    group: "Anglais",
    include_tags: ["Anglais"],
    exclude_tags: []
  },
  {
    category: "Travel",
    label: "Travel",
    group: "Travel",
    include_tags: ["Travel"],
    exclude_tags: []
  },
  {
    category: "Mortgage Lending",
    label: "Mortgage Lending",
    group: "Mortgage Lending",
    include_tags: ["Mortgage Lending"],
    exclude_tags: []
  },
  {
    category: "Medical Terminology",
    label: "Medical Terminology",
    group: "Medical Terminology",
    include_tags: ["Medical Terminology"],
    exclude_tags: []
  },
  {
    category: "Quality Management",
    label: "Quality Management",
    group: "Quality Management",
    include_tags: ["Quality Management"],
    exclude_tags: []
  },
  {
    category: "Solution Architecture",
    label: "Solution Architecture",
    group: "Solution Architecture",
    include_tags: ["Solution Architecture"],
    exclude_tags: []
  },
  {
    category: "Graphics",
    label: "Graphics",
    group: "Graphics",
    include_tags: ["Graphics"],
    exclude_tags: []
  },
  {
    category: "Market Analysis",
    label: "Market Analysis",
    group: "Market Analysis",
    include_tags: ["Market Analysis"],
    exclude_tags: []
  },
  {
    category: "Property Management",
    label: "Property Management",
    group: "Property Management",
    include_tags: ["Property Management"],
    exclude_tags: []
  },
  {
    category: "Risk Assessment",
    label: "Risk Assessment",
    group: "Risk Assessment",
    include_tags: ["Risk Assessment"],
    exclude_tags: []
  },
  {
    category: "Direct Marketing",
    label: "Direct Marketing",
    group: "Direct Marketing",
    include_tags: ["Direct Marketing"],
    exclude_tags: []
  },
  {
    category: "New York",
    label: "New York",
    group: "New York",
    include_tags: ["New York"],
    exclude_tags: []
  },
  {
    category: "Delivery",
    label: "Delivery",
    group: "Delivery",
    include_tags: ["Delivery"],
    exclude_tags: []
  },
  {
    category: "Emr",
    label: "Emr",
    group: "Emr",
    include_tags: ["Emr"],
    exclude_tags: []
  },
  {
    category: "National Security",
    label: "National Security",
    group: "National Security",
    include_tags: ["National Security"],
    exclude_tags: []
  },
  {
    category: "International Business",
    label: "International Business",
    group: "International Business",
    include_tags: ["International Business"],
    exclude_tags: []
  },
  {
    category: "Aviation",
    label: "Aviation",
    group: "Aviation",
    include_tags: ["Aviation"],
    exclude_tags: []
  },
  {
    category: "Trattative",
    label: "Trattative",
    group: "Trattative",
    include_tags: ["Trattative"],
    exclude_tags: []
  },
  {
    category: "Biotechnology",
    label: "Biotechnology",
    group: "Biotechnology",
    include_tags: ["Biotechnology"],
    exclude_tags: []
  },
  {
    category: "Process Scheduler",
    label: "Process Scheduler",
    group: "Process Scheduler",
    include_tags: ["Process Scheduler"],
    exclude_tags: []
  },
  {
    category: "Psychotherapy",
    label: "Psychotherapy",
    group: "Psychotherapy",
    include_tags: ["Psychotherapy"],
    exclude_tags: []
  },
  {
    category: "Bls",
    label: "Bls",
    group: "Bls",
    include_tags: ["Bls"],
    exclude_tags: []
  },
  {
    category: "Sellers",
    label: "Sellers",
    group: "Sellers",
    include_tags: ["Sellers"],
    exclude_tags: []
  },
  {
    category: "Copy Editing",
    label: "Copy Editing",
    group: "Copy Editing",
    include_tags: ["Copy Editing"],
    exclude_tags: []
  },
  {
    category: "Fmcg",
    label: "Fmcg",
    group: "Fmcg",
    include_tags: ["Fmcg"],
    exclude_tags: []
  },
  {
    category: "Aerospace",
    label: "Aerospace",
    group: "Aerospace",
    include_tags: ["Aerospace"],
    exclude_tags: []
  },
  {
    category: "Financial Risk",
    label: "Financial Risk",
    group: "Financial Risk",
    include_tags: ["Financial Risk"],
    exclude_tags: []
  },
  {
    category: "Credit Analysis",
    label: "Credit Analysis",
    group: "Credit Analysis",
    include_tags: ["Credit Analysis"],
    exclude_tags: []
  },
  {
    category: "Customer Relationship Management Crm",
    label: "Customer Relationship Management Crm",
    group: "Customer Relationship Management Crm",
    include_tags: ["Customer Relationship Management Crm"],
    exclude_tags: []
  },
  {
    category: "Payments",
    label: "Payments",
    group: "Payments",
    include_tags: ["Payments"],
    exclude_tags: []
  },
  {
    category: "Newsletters",
    label: "Newsletters",
    group: "Newsletters",
    include_tags: ["Newsletters"],
    exclude_tags: []
  },
  {
    category: "Illustration",
    label: "Illustration",
    group: "Illustration",
    include_tags: ["Illustration"],
    exclude_tags: []
  },
  {
    category: "Capital Markets",
    label: "Capital Markets",
    group: "Capital Markets",
    include_tags: ["Capital Markets"],
    exclude_tags: []
  },
  {
    category: "Trading",
    label: "Trading",
    group: "Trading",
    include_tags: ["Trading"],
    exclude_tags: []
  },
  {
    category: "Voip",
    label: "Voip",
    group: "Voip",
    include_tags: ["Voip"],
    exclude_tags: []
  },
  {
    category: "Fitness",
    label: "Fitness",
    group: "Fitness",
    include_tags: ["Fitness"],
    exclude_tags: []
  },
  {
    category: "Web Applications",
    label: "Web Applications",
    group: "Web Applications",
    include_tags: ["Web Applications"],
    exclude_tags: []
  },
  {
    category: "Publishing",
    label: "Publishing",
    group: "Publishing",
    include_tags: ["Publishing"],
    exclude_tags: []
  },
  {
    category: "Sketchup",
    label: "Sketchup",
    group: "Sketchup",
    include_tags: ["Sketchup"],
    exclude_tags: []
  },
  {
    category: "Root Cause Analysis",
    label: "Root Cause Analysis",
    group: "Root Cause Analysis",
    include_tags: ["Root Cause Analysis"],
    exclude_tags: []
  },
  {
    category: "Art Direction",
    label: "Art Direction",
    group: "Art Direction",
    include_tags: ["Art Direction"],
    exclude_tags: []
  },
  {
    category: "Digital Strategy",
    label: "Digital Strategy",
    group: "Digital Strategy",
    include_tags: ["Digital Strategy"],
    exclude_tags: []
  },
  {
    category: "Safety",
    label: "Safety",
    group: "Safety",
    include_tags: ["Safety"],
    exclude_tags: []
  },
  {
    category: "Windows Xp",
    label: "Windows Xp",
    group: "Windows Xp",
    include_tags: ["Windows Xp"],
    exclude_tags: []
  },
  {
    category: "Wellness",
    label: "Wellness",
    group: "Wellness",
    include_tags: ["Wellness"],
    exclude_tags: []
  },
  {
    category: "Cisco Technologies",
    label: "Cisco Technologies",
    group: "Cisco Technologies",
    include_tags: ["Cisco Technologies"],
    exclude_tags: []
  },
  {
    category: "India",
    label: "India",
    group: "India",
    include_tags: ["India"],
    exclude_tags: []
  },
  {
    category: "Adult Education",
    label: "Adult Education",
    group: "Adult Education",
    include_tags: ["Adult Education"],
    exclude_tags: []
  },
  {
    category: "Legal",
    label: "Legal",
    group: "Legal",
    include_tags: ["Legal"],
    exclude_tags: []
  },
  {
    category: "Molecular Biology",
    label: "Molecular Biology",
    group: "Molecular Biology",
    include_tags: ["Molecular Biology"],
    exclude_tags: []
  },
  {
    category: "Underwriting",
    label: "Underwriting",
    group: "Underwriting",
    include_tags: ["Underwriting"],
    exclude_tags: []
  },
  {
    category: "Written",
    label: "Written",
    group: "Written",
    include_tags: ["Written"],
    exclude_tags: []
  },
  {
    category: "Freight",
    label: "Freight",
    group: "Freight",
    include_tags: ["Freight"],
    exclude_tags: []
  },
  {
    category: "Microsoft Outlook",
    label: "Microsoft Outlook",
    group: "Microsoft Outlook",
    include_tags: ["Microsoft Outlook"],
    exclude_tags: []
  },
  {
    category: "Network Security",
    label: "Network Security",
    group: "Network Security",
    include_tags: ["Network Security"],
    exclude_tags: []
  },
  {
    category: "Social Services",
    label: "Social Services",
    group: "Social Services",
    include_tags: ["Social Services"],
    exclude_tags: []
  },
  {
    category: "Program Evaluation",
    label: "Program Evaluation",
    group: "Program Evaluation",
    include_tags: ["Program Evaluation"],
    exclude_tags: []
  },
  {
    category: "Proofreading",
    label: "Proofreading",
    group: "Proofreading",
    include_tags: ["Proofreading"],
    exclude_tags: []
  },
  {
    category: "Preventive Maintenance",
    label: "Preventive Maintenance",
    group: "Preventive Maintenance",
    include_tags: ["Preventive Maintenance"],
    exclude_tags: []
  },
  {
    category: "Forklift Operator",
    label: "Forklift Operator",
    group: "Forklift Operator",
    include_tags: ["Forklift Operator"],
    exclude_tags: []
  },
  {
    category: "Requirements Gathering",
    label: "Requirements Gathering",
    group: "Requirements Gathering",
    include_tags: ["Requirements Gathering"],
    exclude_tags: []
  },
  {
    category: "Resume",
    label: "Resume",
    group: "Resume",
    include_tags: ["Resume"],
    exclude_tags: []
  },
  {
    category: "Systems Engineering",
    label: "Systems Engineering",
    group: "Systems Engineering",
    include_tags: ["Systems Engineering"],
    exclude_tags: []
  },
  {
    category: "Oil And Gas",
    label: "Oil And Gas",
    group: "Oil And Gas",
    include_tags: ["Oil And Gas"],
    exclude_tags: []
  },
  {
    category: "User Experience",
    label: "User Experience",
    group: "User Experience",
    include_tags: ["User Experience"],
    exclude_tags: []
  },
  {
    category: "Google Analytics",
    label: "Google Analytics",
    group: "Google Analytics",
    include_tags: ["Google Analytics"],
    exclude_tags: []
  },
  {
    category: "Eclipse",
    label: "Eclipse",
    group: "Eclipse",
    include_tags: ["Eclipse"],
    exclude_tags: []
  },
  {
    category: "Contabilidad",
    label: "Contabilidad",
    group: "Contabilidad",
    include_tags: ["Contabilidad"],
    exclude_tags: []
  },
  {
    category: "Merchandise",
    label: "Merchandise",
    group: "Merchandise",
    include_tags: ["Merchandise"],
    exclude_tags: []
  },
  {
    category: "Hris",
    label: "Hris",
    group: "Hris",
    include_tags: ["Hris"],
    exclude_tags: []
  },
  {
    category: "Magazines",
    label: "Magazines",
    group: "Magazines",
    include_tags: ["Magazines"],
    exclude_tags: []
  },
  {
    category: "Painting",
    label: "Painting",
    group: "Painting",
    include_tags: ["Painting"],
    exclude_tags: []
  },
  {
    category: "Visual Basic",
    label: "Visual Basic",
    group: "Visual Basic",
    include_tags: ["Visual Basic"],
    exclude_tags: []
  },
  {
    category: "House",
    label: "House",
    group: "House",
    include_tags: ["House"],
    exclude_tags: []
  },
  {
    category: "First Aid",
    label: "First Aid",
    group: "First Aid",
    include_tags: ["First Aid"],
    exclude_tags: []
  },
  {
    category: "Forklift Operation",
    label: "Forklift Operation",
    group: "Forklift Operation",
    include_tags: ["Forklift Operation"],
    exclude_tags: []
  },
  {
    category: "Planejamento De Projetos",
    label: "Planejamento De Projetos",
    group: "Planejamento De Projetos",
    include_tags: ["Planejamento De Projetos"],
    exclude_tags: []
  },
  {
    category: "Marketing Digital",
    label: "Marketing Digital",
    group: "Marketing Digital",
    include_tags: ["Marketing Digital"],
    exclude_tags: []
  },
  {
    category: "American",
    label: "American",
    group: "American",
    include_tags: ["American"],
    exclude_tags: []
  },
  {
    category: "Chemistry",
    label: "Chemistry",
    group: "Chemistry",
    include_tags: ["Chemistry"],
    exclude_tags: []
  },
  {
    category: "Excel",
    label: "Excel",
    group: "Excel",
    include_tags: ["Excel"],
    exclude_tags: []
  },
  {
    category: "Final Cut Pro",
    label: "Final Cut Pro",
    group: "Final Cut Pro",
    include_tags: ["Final Cut Pro"],
    exclude_tags: []
  },
  {
    category: "Commercial Banking",
    label: "Commercial Banking",
    group: "Commercial Banking",
    include_tags: ["Commercial Banking"],
    exclude_tags: []
  },
  {
    category: "Hotel Management",
    label: "Hotel Management",
    group: "Hotel Management",
    include_tags: ["Hotel Management"],
    exclude_tags: []
  },
  {
    category: "Special Education",
    label: "Special Education",
    group: "Special Education",
    include_tags: ["Special Education"],
    exclude_tags: []
  },
  {
    category: "Vendite",
    label: "Vendite",
    group: "Vendite",
    include_tags: ["Vendite"],
    exclude_tags: []
  },
  {
    category: "Sarbanes Oxley Act",
    label: "Sarbanes Oxley Act",
    group: "Sarbanes Oxley Act",
    include_tags: ["Sarbanes Oxley Act"],
    exclude_tags: []
  },
  {
    category: "Student Affairs",
    label: "Student Affairs",
    group: "Student Affairs",
    include_tags: ["Student Affairs"],
    exclude_tags: []
  },
  {
    category: "Parler En Public",
    label: "Parler En Public",
    group: "Parler En Public",
    include_tags: ["Parler En Public"],
    exclude_tags: []
  },
  {
    category: "Enterprise Architecture",
    label: "Enterprise Architecture",
    group: "Enterprise Architecture",
    include_tags: ["Enterprise Architecture"],
    exclude_tags: []
  },
  {
    category: "Strategia Di Marketing",
    label: "Strategia Di Marketing",
    group: "Strategia Di Marketing",
    include_tags: ["Strategia Di Marketing"],
    exclude_tags: []
  },
  {
    category: "Translation",
    label: "Translation",
    group: "Translation",
    include_tags: ["Translation"],
    exclude_tags: []
  },
  {
    category: "Product Launch",
    label: "Product Launch",
    group: "Product Launch",
    include_tags: ["Product Launch"],
    exclude_tags: []
  },
  {
    category: "Vehicles",
    label: "Vehicles",
    group: "Vehicles",
    include_tags: ["Vehicles"],
    exclude_tags: []
  },
  {
    category: "Stakeholder Management",
    label: "Stakeholder Management",
    group: "Stakeholder Management",
    include_tags: ["Stakeholder Management"],
    exclude_tags: []
  },
  {
    category: "Legal Assistance",
    label: "Legal Assistance",
    group: "Legal Assistance",
    include_tags: ["Legal Assistance"],
    exclude_tags: []
  },
  {
    category: "Onshore",
    label: "Onshore",
    group: "Onshore",
    include_tags: ["Onshore"],
    exclude_tags: []
  },
  {
    category: "Cleaning",
    label: "Cleaning",
    group: "Cleaning",
    include_tags: ["Cleaning"],
    exclude_tags: []
  },
  {
    category: "Visual Studio",
    label: "Visual Studio",
    group: "Visual Studio",
    include_tags: ["Visual Studio"],
    exclude_tags: []
  },
  {
    category: "Strategies",
    label: "Strategies",
    group: "Strategies",
    include_tags: ["Strategies"],
    exclude_tags: []
  },
  {
    category: "Sales Presentations",
    label: "Sales Presentations",
    group: "Sales Presentations",
    include_tags: ["Sales Presentations"],
    exclude_tags: []
  },
  {
    category: "New Media",
    label: "New Media",
    group: "New Media",
    include_tags: ["New Media"],
    exclude_tags: []
  },
  {
    category: "Governance",
    label: "Governance",
    group: "Governance",
    include_tags: ["Governance"],
    exclude_tags: []
  },
  {
    category: "Broadcast",
    label: "Broadcast",
    group: "Broadcast",
    include_tags: ["Broadcast"],
    exclude_tags: []
  },
  {
    category: "Branding",
    label: "Branding",
    group: "Branding",
    include_tags: ["Branding"],
    exclude_tags: []
  },
  {
    category: "System Monitoring",
    label: "System Monitoring",
    group: "System Monitoring",
    include_tags: ["System Monitoring"],
    exclude_tags: []
  },
  {
    category: "Layout",
    label: "Layout",
    group: "Layout",
    include_tags: ["Layout"],
    exclude_tags: []
  },
  {
    category: "Materials Management",
    label: "Materials Management",
    group: "Materials Management",
    include_tags: ["Materials Management"],
    exclude_tags: []
  },
  {
    category: "Editorial",
    label: "Editorial",
    group: "Editorial",
    include_tags: ["Editorial"],
    exclude_tags: []
  },
  {
    category: "K 12",
    label: "K 12",
    group: "K 12",
    include_tags: ["K 12"],
    exclude_tags: []
  },
  {
    category: "Reviews",
    label: "Reviews",
    group: "Reviews",
    include_tags: ["Reviews"],
    exclude_tags: []
  },
  {
    category: "Recherche",
    label: "Recherche",
    group: "Recherche",
    include_tags: ["Recherche"],
    exclude_tags: []
  },
  {
    category: "Ajax",
    label: "Ajax",
    group: "Ajax",
    include_tags: ["Ajax"],
    exclude_tags: []
  },
  {
    category: "Tcp/ip",
    label: "Tcp/ip",
    group: "Tcp/ip",
    include_tags: ["Tcp/ip"],
    exclude_tags: []
  },
  {
    category: "Hr Policies",
    label: "Hr Policies",
    group: "Hr Policies",
    include_tags: ["Hr Policies"],
    exclude_tags: []
  },
  {
    category: "Digital Photography",
    label: "Digital Photography",
    group: "Digital Photography",
    include_tags: ["Digital Photography"],
    exclude_tags: []
  },
  {
    category: "Pianificazione Strategica",
    label: "Pianificazione Strategica",
    group: "Pianificazione Strategica",
    include_tags: ["Pianificazione Strategica"],
    exclude_tags: []
  },
  {
    category: "Treinamento",
    label: "Treinamento",
    group: "Treinamento",
    include_tags: ["Treinamento"],
    exclude_tags: []
  },
  {
    category: "Great",
    label: "Great",
    group: "Great",
    include_tags: ["Great"],
    exclude_tags: []
  },
  {
    category: "First Time Home Buyers",
    label: "First Time Home Buyers",
    group: "First Time Home Buyers",
    include_tags: ["First Time Home Buyers"],
    exclude_tags: []
  },
  {
    category: "Has Errors",
    label: "Has Errors",
    group: "Has Errors",
    include_tags: ["Has Errors"],
    exclude_tags: []
  },
  {
    category: "Surgery",
    label: "Surgery",
    group: "Surgery",
    include_tags: ["Surgery"],
    exclude_tags: []
  },
  {
    category: "Revenue",
    label: "Revenue",
    group: "Revenue",
    include_tags: ["Revenue"],
    exclude_tags: []
  },
  {
    category: "Floors",
    label: "Floors",
    group: "Floors",
    include_tags: ["Floors"],
    exclude_tags: []
  },
  {
    category: "Pubbliche Relazioni",
    label: "Pubbliche Relazioni",
    group: "Pubbliche Relazioni",
    include_tags: ["Pubbliche Relazioni"],
    exclude_tags: []
  },
  {
    category: "Strategia D'impresa",
    label: "Strategia D'impresa",
    group: "Strategia D'impresa",
    include_tags: ["Strategia D'impresa"],
    exclude_tags: []
  },
  {
    category: "Efficient",
    label: "Efficient",
    group: "Efficient",
    include_tags: ["Efficient"],
    exclude_tags: []
  },
  {
    category: "Property",
    label: "Property",
    group: "Property",
    include_tags: ["Property"],
    exclude_tags: []
  },
  {
    category: "Investigation",
    label: "Investigation",
    group: "Investigation",
    include_tags: ["Investigation"],
    exclude_tags: []
  },
  {
    category: "Claim",
    label: "Claim",
    group: "Claim",
    include_tags: ["Claim"],
    exclude_tags: []
  },
  {
    category: "Private Equity",
    label: "Private Equity",
    group: "Private Equity",
    include_tags: ["Private Equity"],
    exclude_tags: []
  },
  {
    category: "Adobe Illustrator",
    label: "Adobe Illustrator",
    group: "Adobe Illustrator",
    include_tags: ["Adobe Illustrator"],
    exclude_tags: []
  },
  {
    category: "Infrastructure",
    label: "Infrastructure",
    group: "Infrastructure",
    include_tags: ["Infrastructure"],
    exclude_tags: []
  },
  {
    category: "Projektmanagement",
    label: "Projektmanagement",
    group: "Projektmanagement",
    include_tags: ["Projektmanagement"],
    exclude_tags: []
  },
  {
    category: "Business Plan",
    label: "Business Plan",
    group: "Business Plan",
    include_tags: ["Business Plan"],
    exclude_tags: []
  },
  {
    category: "Automobile",
    label: "Automobile",
    group: "Automobile",
    include_tags: ["Automobile"],
    exclude_tags: []
  },
  {
    category: "Mergers",
    label: "Mergers",
    group: "Mergers",
    include_tags: ["Mergers"],
    exclude_tags: []
  },
  {
    category: "Fmea",
    label: "Fmea",
    group: "Fmea",
    include_tags: ["Fmea"],
    exclude_tags: []
  },
  {
    category: "Food Safety",
    label: "Food Safety",
    group: "Food Safety",
    include_tags: ["Food Safety"],
    exclude_tags: []
  },
  {
    category: "Environmental Awareness",
    label: "Environmental Awareness",
    group: "Environmental Awareness",
    include_tags: ["Environmental Awareness"],
    exclude_tags: []
  },
  {
    category: "Validation",
    label: "Validation",
    group: "Validation",
    include_tags: ["Validation"],
    exclude_tags: []
  },
  {
    category: "Websites",
    label: "Websites",
    group: "Websites",
    include_tags: ["Websites"],
    exclude_tags: []
  },
  {
    category: "Lingua Inglese",
    label: "Lingua Inglese",
    group: "Lingua Inglese",
    include_tags: ["Lingua Inglese"],
    exclude_tags: []
  },
  {
    category: "Monitors",
    label: "Monitors",
    group: "Monitors",
    include_tags: ["Monitors"],
    exclude_tags: []
  },
  {
    category: "Venture Capital",
    label: "Venture Capital",
    group: "Venture Capital",
    include_tags: ["Venture Capital"],
    exclude_tags: []
  },
  {
    category: "Distribution",
    label: "Distribution",
    group: "Distribution",
    include_tags: ["Distribution"],
    exclude_tags: []
  },
  {
    category: "Courts",
    label: "Courts",
    group: "Courts",
    include_tags: ["Courts"],
    exclude_tags: []
  },
  {
    category: "Market Planning",
    label: "Market Planning",
    group: "Market Planning",
    include_tags: ["Market Planning"],
    exclude_tags: []
  },
  {
    category: "Computer Science",
    label: "Computer Science",
    group: "Computer Science",
    include_tags: ["Computer Science"],
    exclude_tags: []
  },
  {
    category: "Differentiated Instruction",
    label: "Differentiated Instruction",
    group: "Differentiated Instruction",
    include_tags: ["Differentiated Instruction"],
    exclude_tags: []
  },
  {
    category: "Factory",
    label: "Factory",
    group: "Factory",
    include_tags: ["Factory"],
    exclude_tags: []
  },
  {
    category: "Cost",
    label: "Cost",
    group: "Cost",
    include_tags: ["Cost"],
    exclude_tags: []
  },
  {
    category: "Weapons",
    label: "Weapons",
    group: "Weapons",
    include_tags: ["Weapons"],
    exclude_tags: []
  },
  {
    category: "Engenharia",
    label: "Engenharia",
    group: "Engenharia",
    include_tags: ["Engenharia"],
    exclude_tags: []
  },
  {
    category: "Publications",
    label: "Publications",
    group: "Publications",
    include_tags: ["Publications"],
    exclude_tags: []
  },
  {
    category: "Front Office",
    label: "Front Office",
    group: "Front Office",
    include_tags: ["Front Office"],
    exclude_tags: []
  },
  {
    category: "Supervision",
    label: "Supervision",
    group: "Supervision",
    include_tags: ["Supervision"],
    exclude_tags: []
  },
  {
    category: "Pmp",
    label: "Pmp",
    group: "Pmp",
    include_tags: ["Pmp"],
    exclude_tags: []
  },
  {
    category: "Typing",
    label: "Typing",
    group: "Typing",
    include_tags: ["Typing"],
    exclude_tags: []
  },
  {
    category: "Service Delivery",
    label: "Service Delivery",
    group: "Service Delivery",
    include_tags: ["Service Delivery"],
    exclude_tags: []
  },
  {
    category: "Aircraft",
    label: "Aircraft",
    group: "Aircraft",
    include_tags: ["Aircraft"],
    exclude_tags: []
  },
  {
    category: "Life Insurance",
    label: "Life Insurance",
    group: "Life Insurance",
    include_tags: ["Life Insurance"],
    exclude_tags: []
  },
  {
    category: "Presupuestos",
    label: "Presupuestos",
    group: "Presupuestos",
    include_tags: ["Presupuestos"],
    exclude_tags: []
  },
  {
    category: "Communication Marketing",
    label: "Communication Marketing",
    group: "Communication Marketing",
    include_tags: ["Communication Marketing"],
    exclude_tags: []
  },
  {
    category: "Project Delivery",
    label: "Project Delivery",
    group: "Project Delivery",
    include_tags: ["Project Delivery"],
    exclude_tags: []
  },
  {
    category: "Medicare",
    label: "Medicare",
    group: "Medicare",
    include_tags: ["Medicare"],
    exclude_tags: []
  },
  {
    category: "Mac",
    label: "Mac",
    group: "Mac",
    include_tags: ["Mac"],
    exclude_tags: []
  },
  {
    category: "Java Enterprise Edition",
    label: "Java Enterprise Edition",
    group: "Java Enterprise Edition",
    include_tags: ["Java Enterprise Edition"],
    exclude_tags: []
  },
  {
    category: "Literacy",
    label: "Literacy",
    group: "Literacy",
    include_tags: ["Literacy"],
    exclude_tags: []
  },
  {
    category: "Technical Recruiting",
    label: "Technical Recruiting",
    group: "Technical Recruiting",
    include_tags: ["Technical Recruiting"],
    exclude_tags: []
  },
  {
    category: "Project Coordination",
    label: "Project Coordination",
    group: "Project Coordination",
    include_tags: ["Project Coordination"],
    exclude_tags: []
  },
  {
    category: "Intellectual Property",
    label: "Intellectual Property",
    group: "Intellectual Property",
    include_tags: ["Intellectual Property"],
    exclude_tags: []
  },
  {
    category: "Strategic Financial Planning",
    label: "Strategic Financial Planning",
    group: "Strategic Financial Planning",
    include_tags: ["Strategic Financial Planning"],
    exclude_tags: []
  },
  {
    category: "Git",
    label: "Git",
    group: "Git",
    include_tags: ["Git"],
    exclude_tags: []
  },
  {
    category: "Regulations",
    label: "Regulations",
    group: "Regulations",
    include_tags: ["Regulations"],
    exclude_tags: []
  },
  {
    category: "Concrete",
    label: "Concrete",
    group: "Concrete",
    include_tags: ["Concrete"],
    exclude_tags: []
  },
  {
    category: "Ip",
    label: "Ip",
    group: "Ip",
    include_tags: ["Ip"],
    exclude_tags: []
  },
  {
    category: "Surveying",
    label: "Surveying",
    group: "Surveying",
    include_tags: ["Surveying"],
    exclude_tags: []
  },
  {
    category: "Internal Communications",
    label: "Internal Communications",
    group: "Internal Communications",
    include_tags: ["Internal Communications"],
    exclude_tags: []
  },
  {
    category: "Distribution Center Operations",
    label: "Distribution Center Operations",
    group: "Distribution Center Operations",
    include_tags: ["Distribution Center Operations"],
    exclude_tags: []
  },
  {
    category: "Personal Development",
    label: "Personal Development",
    group: "Personal Development",
    include_tags: ["Personal Development"],
    exclude_tags: []
  },
  {
    category: "Nutrition",
    label: "Nutrition",
    group: "Nutrition",
    include_tags: ["Nutrition"],
    exclude_tags: []
  },
  {
    category: "Health Insurance",
    label: "Health Insurance",
    group: "Health Insurance",
    include_tags: ["Health Insurance"],
    exclude_tags: []
  },
  {
    category: "Menu Development",
    label: "Menu Development",
    group: "Menu Development",
    include_tags: ["Menu Development"],
    exclude_tags: []
  },
  {
    category: "Safety Management Systems",
    label: "Safety Management Systems",
    group: "Safety Management Systems",
    include_tags: ["Safety Management Systems"],
    exclude_tags: []
  },
  {
    category: "Spreadsheets",
    label: "Spreadsheets",
    group: "Spreadsheets",
    include_tags: ["Spreadsheets"],
    exclude_tags: []
  },
  {
    category: "Film Production",
    label: "Film Production",
    group: "Film Production",
    include_tags: ["Film Production"],
    exclude_tags: []
  },
  {
    category: "Ifrs",
    label: "Ifrs",
    group: "Ifrs",
    include_tags: ["Ifrs"],
    exclude_tags: []
  },
  {
    category: "Loss Prevention",
    label: "Loss Prevention",
    group: "Loss Prevention",
    include_tags: ["Loss Prevention"],
    exclude_tags: []
  },
  {
    category: "Acting",
    label: "Acting",
    group: "Acting",
    include_tags: ["Acting"],
    exclude_tags: []
  },
  {
    category: "Airlines",
    label: "Airlines",
    group: "Airlines",
    include_tags: ["Airlines"],
    exclude_tags: []
  },
  {
    category: "Mediation",
    label: "Mediation",
    group: "Mediation",
    include_tags: ["Mediation"],
    exclude_tags: []
  },
  {
    category: "Receptionist Duties",
    label: "Receptionist Duties",
    group: "Receptionist Duties",
    include_tags: ["Receptionist Duties"],
    exclude_tags: []
  },
  {
    category: "Theatre",
    label: "Theatre",
    group: "Theatre",
    include_tags: ["Theatre"],
    exclude_tags: []
  },
  {
    category: "San",
    label: "San",
    group: "San",
    include_tags: ["San"],
    exclude_tags: []
  },
  {
    category: "Restaurant Management",
    label: "Restaurant Management",
    group: "Restaurant Management",
    include_tags: ["Restaurant Management"],
    exclude_tags: []
  },
  {
    category: "Storytelling",
    label: "Storytelling",
    group: "Storytelling",
    include_tags: ["Storytelling"],
    exclude_tags: []
  },
  {
    category: "Active",
    label: "Active",
    group: "Active",
    include_tags: ["Active"],
    exclude_tags: []
  },
  {
    category: "Iso",
    label: "Iso",
    group: "Iso",
    include_tags: ["Iso"],
    exclude_tags: []
  },
  {
    category: "Critical Care",
    label: "Critical Care",
    group: "Critical Care",
    include_tags: ["Critical Care"],
    exclude_tags: []
  },
  {
    category: "Dreamweaver",
    label: "Dreamweaver",
    group: "Dreamweaver",
    include_tags: ["Dreamweaver"],
    exclude_tags: []
  },
  {
    category: "Cash Flow",
    label: "Cash Flow",
    group: "Cash Flow",
    include_tags: ["Cash Flow"],
    exclude_tags: []
  },
  {
    category: "Telephone Skills",
    label: "Telephone Skills",
    group: "Telephone Skills",
    include_tags: ["Telephone Skills"],
    exclude_tags: []
  },
  {
    category: "Sound",
    label: "Sound",
    group: "Sound",
    include_tags: ["Sound"],
    exclude_tags: []
  },
  {
    category: "Embedded Systems",
    label: "Embedded Systems",
    group: "Embedded Systems",
    include_tags: ["Embedded Systems"],
    exclude_tags: []
  },
  {
    category: "Corporate Governance",
    label: "Corporate Governance",
    group: "Corporate Governance",
    include_tags: ["Corporate Governance"],
    exclude_tags: []
  },
  {
    category: "Case Management",
    label: "Case Management",
    group: "Case Management",
    include_tags: ["Case Management"],
    exclude_tags: []
  },
  {
    category: "Pipelines",
    label: "Pipelines",
    group: "Pipelines",
    include_tags: ["Pipelines"],
    exclude_tags: []
  },
  {
    category: "Radio",
    label: "Radio",
    group: "Radio",
    include_tags: ["Radio"],
    exclude_tags: []
  },
  {
    category: "Life Sciences",
    label: "Life Sciences",
    group: "Life Sciences",
    include_tags: ["Life Sciences"],
    exclude_tags: []
  },
  {
    category: "Derivatives",
    label: "Derivatives",
    group: "Derivatives",
    include_tags: ["Derivatives"],
    exclude_tags: []
  },
  {
    category: "Product Design",
    label: "Product Design",
    group: "Product Design",
    include_tags: ["Product Design"],
    exclude_tags: []
  },
  {
    category: "Credit Cards",
    label: "Credit Cards",
    group: "Credit Cards",
    include_tags: ["Credit Cards"],
    exclude_tags: []
  },
  {
    category: "Renovation",
    label: "Renovation",
    group: "Renovation",
    include_tags: ["Renovation"],
    exclude_tags: []
  },
  {
    category: "Multi Tasking",
    label: "Multi Tasking",
    group: "Multi Tasking",
    include_tags: ["Multi Tasking"],
    exclude_tags: []
  },
  {
    category: "Gestione Vendite",
    label: "Gestione Vendite",
    group: "Gestione Vendite",
    include_tags: ["Gestione Vendite"],
    exclude_tags: []
  },
  {
    category: "Subcontracting",
    label: "Subcontracting",
    group: "Subcontracting",
    include_tags: ["Subcontracting"],
    exclude_tags: []
  },
  {
    category: "Sop",
    label: "Sop",
    group: "Sop",
    include_tags: ["Sop"],
    exclude_tags: []
  },
  {
    category: "Simulations",
    label: "Simulations",
    group: "Simulations",
    include_tags: ["Simulations"],
    exclude_tags: []
  },
  {
    category: "Inpatient",
    label: "Inpatient",
    group: "Inpatient",
    include_tags: ["Inpatient"],
    exclude_tags: []
  },
  {
    category: "Architectural Design",
    label: "Architectural Design",
    group: "Architectural Design",
    include_tags: ["Architectural Design"],
    exclude_tags: []
  },
  {
    category: "Conferences",
    label: "Conferences",
    group: "Conferences",
    include_tags: ["Conferences"],
    exclude_tags: []
  },
  {
    category: "Construction Safety",
    label: "Construction Safety",
    group: "Construction Safety",
    include_tags: ["Construction Safety"],
    exclude_tags: []
  },
  {
    category: "Childcare",
    label: "Childcare",
    group: "Childcare",
    include_tags: ["Childcare"],
    exclude_tags: []
  },
  {
    category: "Oil/gas",
    label: "Oil/gas",
    group: "Oil/gas",
    include_tags: ["Oil/gas"],
    exclude_tags: []
  },
  {
    category: "Coordinate",
    label: "Coordinate",
    group: "Coordinate",
    include_tags: ["Coordinate"],
    exclude_tags: []
  },
  {
    category: "Data Warehousing",
    label: "Data Warehousing",
    group: "Data Warehousing",
    include_tags: ["Data Warehousing"],
    exclude_tags: []
  },
  {
    category: "Uml",
    label: "Uml",
    group: "Uml",
    include_tags: ["Uml"],
    exclude_tags: []
  },
  {
    category: "Erp Planejamento De Recursos Empresariais",
    label: "Erp Planejamento De Recursos Empresariais",
    group: "Erp Planejamento De Recursos Empresariais",
    include_tags: ["Erp Planejamento De Recursos Empresariais"],
    exclude_tags: []
  },
  {
    category: "Laboratory",
    label: "Laboratory",
    group: "Laboratory",
    include_tags: ["Laboratory"],
    exclude_tags: []
  },
  {
    category: "Packaging",
    label: "Packaging",
    group: "Packaging",
    include_tags: ["Packaging"],
    exclude_tags: []
  },
  {
    category: "General Insurance",
    label: "General Insurance",
    group: "General Insurance",
    include_tags: ["General Insurance"],
    exclude_tags: []
  },
  {
    category: "Fixed Income",
    label: "Fixed Income",
    group: "Fixed Income",
    include_tags: ["Fixed Income"],
    exclude_tags: []
  },
  {
    category: "Relations Publiques",
    label: "Relations Publiques",
    group: "Relations Publiques",
    include_tags: ["Relations Publiques"],
    exclude_tags: []
  },
  {
    category: "Real Estate Development",
    label: "Real Estate Development",
    group: "Real Estate Development",
    include_tags: ["Real Estate Development"],
    exclude_tags: []
  },
  {
    category: "Microsoft Access",
    label: "Microsoft Access",
    group: "Microsoft Access",
    include_tags: ["Microsoft Access"],
    exclude_tags: []
  },
  {
    category: "Execution",
    label: "Execution",
    group: "Execution",
    include_tags: ["Execution"],
    exclude_tags: []
  },
  {
    category: "Fine Art",
    label: "Fine Art",
    group: "Fine Art",
    include_tags: ["Fine Art"],
    exclude_tags: []
  },
  {
    category: "Ricerca",
    label: "Ricerca",
    group: "Ricerca",
    include_tags: ["Ricerca"],
    exclude_tags: []
  },
  {
    category: "Property And Casualty Insurance",
    label: "Property And Casualty Insurance",
    group: "Property And Casualty Insurance",
    include_tags: ["Property And Casualty Insurance"],
    exclude_tags: []
  },
  {
    category: "Cambio Organizacional",
    label: "Cambio Organizacional",
    group: "Cambio Organizacional",
    include_tags: ["Cambio Organizacional"],
    exclude_tags: []
  },
  {
    category: "Campaigns",
    label: "Campaigns",
    group: "Campaigns",
    include_tags: ["Campaigns"],
    exclude_tags: []
  },
  {
    category: "Biochemistry",
    label: "Biochemistry",
    group: "Biochemistry",
    include_tags: ["Biochemistry"],
    exclude_tags: []
  },
  {
    category: "Help Desk Support",
    label: "Help Desk Support",
    group: "Help Desk Support",
    include_tags: ["Help Desk Support"],
    exclude_tags: []
  },
  {
    category: "Short Sales",
    label: "Short Sales",
    group: "Short Sales",
    include_tags: ["Short Sales"],
    exclude_tags: []
  },
  {
    category: "Quality System",
    label: "Quality System",
    group: "Quality System",
    include_tags: ["Quality System"],
    exclude_tags: []
  },
  {
    category: "Desenvolvimento De Produtos",
    label: "Desenvolvimento De Produtos",
    group: "Desenvolvimento De Produtos",
    include_tags: ["Desenvolvimento De Produtos"],
    exclude_tags: []
  },
  {
    category: "Conflict Resolution",
    label: "Conflict Resolution",
    group: "Conflict Resolution",
    include_tags: ["Conflict Resolution"],
    exclude_tags: []
  },
  {
    category: "Trade Shows",
    label: "Trade Shows",
    group: "Trade Shows",
    include_tags: ["Trade Shows"],
    exclude_tags: []
  },
  {
    category: "Politics",
    label: "Politics",
    group: "Politics",
    include_tags: ["Politics"],
    exclude_tags: []
  },
  {
    category: "Strategic Sourcing",
    label: "Strategic Sourcing",
    group: "Strategic Sourcing",
    include_tags: ["Strategic Sourcing"],
    exclude_tags: []
  },
  {
    category: "Crisis Intervention",
    label: "Crisis Intervention",
    group: "Crisis Intervention",
    include_tags: ["Crisis Intervention"],
    exclude_tags: []
  },
  {
    category: "Single Family Homes",
    label: "Single Family Homes",
    group: "Single Family Homes",
    include_tags: ["Single Family Homes"],
    exclude_tags: []
  },
  {
    category: "Assessment",
    label: "Assessment",
    group: "Assessment",
    include_tags: ["Assessment"],
    exclude_tags: []
  },
  {
    category: "Pediatrics",
    label: "Pediatrics",
    group: "Pediatrics",
    include_tags: ["Pediatrics"],
    exclude_tags: []
  },
  {
    category: "Succession Planning",
    label: "Succession Planning",
    group: "Succession Planning",
    include_tags: ["Succession Planning"],
    exclude_tags: []
  },
  {
    category: "Sap Erp",
    label: "Sap Erp",
    group: "Sap Erp",
    include_tags: ["Sap Erp"],
    exclude_tags: []
  },
  {
    category: "Hr Consulting",
    label: "Hr Consulting",
    group: "Hr Consulting",
    include_tags: ["Hr Consulting"],
    exclude_tags: []
  },
  {
    category: "Foreclosures",
    label: "Foreclosures",
    group: "Foreclosures",
    include_tags: ["Foreclosures"],
    exclude_tags: []
  },
  {
    category: "Sketching",
    label: "Sketching",
    group: "Sketching",
    include_tags: ["Sketching"],
    exclude_tags: []
  },
  {
    category: "Managed Care",
    label: "Managed Care",
    group: "Managed Care",
    include_tags: ["Managed Care"],
    exclude_tags: []
  },
  {
    category: "Services",
    label: "Services",
    group: "Services",
    include_tags: ["Services"],
    exclude_tags: []
  },
  {
    category: "Instrumentation",
    label: "Instrumentation",
    group: "Instrumentation",
    include_tags: ["Instrumentation"],
    exclude_tags: []
  },
  {
    category: "Transportation Management",
    label: "Transportation Management",
    group: "Transportation Management",
    include_tags: ["Transportation Management"],
    exclude_tags: []
  },
  {
    category: "Evaluation",
    label: "Evaluation",
    group: "Evaluation",
    include_tags: ["Evaluation"],
    exclude_tags: []
  },
  {
    category: "Export",
    label: "Export",
    group: "Export",
    include_tags: ["Export"],
    exclude_tags: []
  },
  {
    category: "Analytical Skills",
    label: "Analytical Skills",
    group: "Analytical Skills",
    include_tags: ["Analytical Skills"],
    exclude_tags: []
  },
  {
    category: "Android",
    label: "Android",
    group: "Android",
    include_tags: ["Android"],
    exclude_tags: []
  },
  {
    category: "Recursos Humanos",
    label: "Recursos Humanos",
    group: "Recursos Humanos",
    include_tags: ["Recursos Humanos"],
    exclude_tags: []
  },
  {
    category: "Solution",
    label: "Solution",
    group: "Solution",
    include_tags: ["Solution"],
    exclude_tags: []
  },
  {
    category: "Workshop Facilitation",
    label: "Workshop Facilitation",
    group: "Workshop Facilitation",
    include_tags: ["Workshop Facilitation"],
    exclude_tags: []
  },
  {
    category: "Relocation",
    label: "Relocation",
    group: "Relocation",
    include_tags: ["Relocation"],
    exclude_tags: []
  },
  {
    category: "Music Production",
    label: "Music Production",
    group: "Music Production",
    include_tags: ["Music Production"],
    exclude_tags: []
  },
  {
    category: "Singing",
    label: "Singing",
    group: "Singing",
    include_tags: ["Singing"],
    exclude_tags: []
  },
  {
    category: "Food Service",
    label: "Food Service",
    group: "Food Service",
    include_tags: ["Food Service"],
    exclude_tags: []
  },
  {
    category: "Employment Law",
    label: "Employment Law",
    group: "Employment Law",
    include_tags: ["Employment Law"],
    exclude_tags: []
  },
  {
    category: "Google Groups",
    label: "Google Groups",
    group: "Google Groups",
    include_tags: ["Google Groups"],
    exclude_tags: []
  },
  {
    category: "Renewable Energy",
    label: "Renewable Energy",
    group: "Renewable Energy",
    include_tags: ["Renewable Energy"],
    exclude_tags: []
  },
  {
    category: "Commercial Insurance",
    label: "Commercial Insurance",
    group: "Commercial Insurance",
    include_tags: ["Commercial Insurance"],
    exclude_tags: []
  },
  {
    category: "Epc",
    label: "Epc",
    group: "Epc",
    include_tags: ["Epc"],
    exclude_tags: []
  },
  {
    category: "Revit",
    label: "Revit",
    group: "Revit",
    include_tags: ["Revit"],
    exclude_tags: []
  },
  {
    category: "Acls",
    label: "Acls",
    group: "Acls",
    include_tags: ["Acls"],
    exclude_tags: []
  },
  {
    category: "Adolescents",
    label: "Adolescents",
    group: "Adolescents",
    include_tags: ["Adolescents"],
    exclude_tags: []
  },
  {
    category: "Criminal Justice",
    label: "Criminal Justice",
    group: "Criminal Justice",
    include_tags: ["Criminal Justice"],
    exclude_tags: []
  },
  {
    category: "Technical Writing",
    label: "Technical Writing",
    group: "Technical Writing",
    include_tags: ["Technical Writing"],
    exclude_tags: []
  },
  {
    category: "Grants",
    label: "Grants",
    group: "Grants",
    include_tags: ["Grants"],
    exclude_tags: []
  },
  {
    category: "Maintenance Management",
    label: "Maintenance Management",
    group: "Maintenance Management",
    include_tags: ["Maintenance Management"],
    exclude_tags: []
  },
  {
    category: "Financial Audits",
    label: "Financial Audits",
    group: "Financial Audits",
    include_tags: ["Financial Audits"],
    exclude_tags: []
  },
  {
    category: "Books",
    label: "Books",
    group: "Books",
    include_tags: ["Books"],
    exclude_tags: []
  },
  {
    category: "Mobile Applications",
    label: "Mobile Applications",
    group: "Mobile Applications",
    include_tags: ["Mobile Applications"],
    exclude_tags: []
  },
  {
    category: "Media",
    label: "Media",
    group: "Media",
    include_tags: ["Media"],
    exclude_tags: []
  },
  {
    category: "Report Writing",
    label: "Report Writing",
    group: "Report Writing",
    include_tags: ["Report Writing"],
    exclude_tags: []
  },
  {
    category: "Arbitration",
    label: "Arbitration",
    group: "Arbitration",
    include_tags: ["Arbitration"],
    exclude_tags: []
  },
  {
    category: "Professional Services",
    label: "Professional Services",
    group: "Professional Services",
    include_tags: ["Professional Services"],
    exclude_tags: []
  },
  {
    category: "Value Engineering",
    label: "Value Engineering",
    group: "Value Engineering",
    include_tags: ["Value Engineering"],
    exclude_tags: []
  },
  {
    category: "Managed Services",
    label: "Managed Services",
    group: "Managed Services",
    include_tags: ["Managed Services"],
    exclude_tags: []
  },
  {
    category: "Sem",
    label: "Sem",
    group: "Sem",
    include_tags: ["Sem"],
    exclude_tags: []
  },
  {
    category: "Physical Security",
    label: "Physical Security",
    group: "Physical Security",
    include_tags: ["Physical Security"],
    exclude_tags: []
  },
  {
    category: "Styling",
    label: "Styling",
    group: "Styling",
    include_tags: ["Styling"],
    exclude_tags: []
  },
  {
    category: "Petrochemical",
    label: "Petrochemical",
    group: "Petrochemical",
    include_tags: ["Petrochemical"],
    exclude_tags: []
  },
  {
    category: "Buyer Representation",
    label: "Buyer Representation",
    group: "Buyer Representation",
    include_tags: ["Buyer Representation"],
    exclude_tags: []
  },
  {
    category: "Cash Register",
    label: "Cash Register",
    group: "Cash Register",
    include_tags: ["Cash Register"],
    exclude_tags: []
  },
  {
    category: "Data Migration",
    label: "Data Migration",
    group: "Data Migration",
    include_tags: ["Data Migration"],
    exclude_tags: []
  },
  {
    category: "Training Delivery",
    label: "Training Delivery",
    group: "Training Delivery",
    include_tags: ["Training Delivery"],
    exclude_tags: []
  },
  {
    category: "Technology",
    label: "Technology",
    group: "Technology",
    include_tags: ["Technology"],
    exclude_tags: []
  },
  {
    category: "Detail",
    label: "Detail",
    group: "Detail",
    include_tags: ["Detail"],
    exclude_tags: []
  },
  {
    category: "Recording",
    label: "Recording",
    group: "Recording",
    include_tags: ["Recording"],
    exclude_tags: []
  },
  {
    category: "Internal Medicine",
    label: "Internal Medicine",
    group: "Internal Medicine",
    include_tags: ["Internal Medicine"],
    exclude_tags: []
  },
  {
    category: "Asistencia Sanitaria",
    label: "Asistencia Sanitaria",
    group: "Asistencia Sanitaria",
    include_tags: ["Asistencia Sanitaria"],
    exclude_tags: []
  },
  {
    category: "Funding",
    label: "Funding",
    group: "Funding",
    include_tags: ["Funding"],
    exclude_tags: []
  },
  {
    category: "Bpo",
    label: "Bpo",
    group: "Bpo",
    include_tags: ["Bpo"],
    exclude_tags: []
  },
  {
    category: "Manufactura",
    label: "Manufactura",
    group: "Manufactura",
    include_tags: ["Manufactura"],
    exclude_tags: []
  },
  {
    category: "Home Care",
    label: "Home Care",
    group: "Home Care",
    include_tags: ["Home Care"],
    exclude_tags: []
  },
  {
    category: "Clinical Trials",
    label: "Clinical Trials",
    group: "Clinical Trials",
    include_tags: ["Clinical Trials"],
    exclude_tags: []
  },
  {
    category: "Concept Development",
    label: "Concept Development",
    group: "Concept Development",
    include_tags: ["Concept Development"],
    exclude_tags: []
  },
  {
    category: "Word",
    label: "Word",
    group: "Word",
    include_tags: ["Word"],
    exclude_tags: []
  },
  {
    category: "Location",
    label: "Location",
    group: "Location",
    include_tags: ["Location"],
    exclude_tags: []
  },
  {
    category: "Learner",
    label: "Learner",
    group: "Learner",
    include_tags: ["Learner"],
    exclude_tags: []
  },
  {
    category: "Bank Reconciliation",
    label: "Bank Reconciliation",
    group: "Bank Reconciliation",
    include_tags: ["Bank Reconciliation"],
    exclude_tags: []
  },
  {
    category: "Concerts",
    label: "Concerts",
    group: "Concerts",
    include_tags: ["Concerts"],
    exclude_tags: []
  },
  {
    category: "Variance Analysis",
    label: "Variance Analysis",
    group: "Variance Analysis",
    include_tags: ["Variance Analysis"],
    exclude_tags: []
  },
  {
    category: "Resolve",
    label: "Resolve",
    group: "Resolve",
    include_tags: ["Resolve"],
    exclude_tags: []
  },
  {
    category: "Culinary Skills",
    label: "Culinary Skills",
    group: "Culinary Skills",
    include_tags: ["Culinary Skills"],
    exclude_tags: []
  },
  {
    category: "Surveillance",
    label: "Surveillance",
    group: "Surveillance",
    include_tags: ["Surveillance"],
    exclude_tags: []
  },
  {
    category: "Distance Learning",
    label: "Distance Learning",
    group: "Distance Learning",
    include_tags: ["Distance Learning"],
    exclude_tags: []
  },
  {
    category: "Planification De Projets",
    label: "Planification De Projets",
    group: "Planification De Projets",
    include_tags: ["Planification De Projets"],
    exclude_tags: []
  },
  {
    category: "Development",
    label: "Development",
    group: "Development",
    include_tags: ["Development"],
    exclude_tags: []
  },
  {
    category: "County",
    label: "County",
    group: "County",
    include_tags: ["County"],
    exclude_tags: []
  },
  {
    category: "Marketing Research",
    label: "Marketing Research",
    group: "Marketing Research",
    include_tags: ["Marketing Research"],
    exclude_tags: []
  },
  {
    category: "Telecomunicaciones",
    label: "Telecomunicaciones",
    group: "Telecomunicaciones",
    include_tags: ["Telecomunicaciones"],
    exclude_tags: []
  },
  {
    category: "Dispute Resolution",
    label: "Dispute Resolution",
    group: "Dispute Resolution",
    include_tags: ["Dispute Resolution"],
    exclude_tags: []
  },
  {
    category: "Hindi",
    label: "Hindi",
    group: "Hindi",
    include_tags: ["Hindi"],
    exclude_tags: []
  },
  {
    category: "Typography",
    label: "Typography",
    group: "Typography",
    include_tags: ["Typography"],
    exclude_tags: []
  },
  {
    category: "Emergency Medicine",
    label: "Emergency Medicine",
    group: "Emergency Medicine",
    include_tags: ["Emergency Medicine"],
    exclude_tags: []
  },
  {
    category: "Usa",
    label: "Usa",
    group: "Usa",
    include_tags: ["Usa"],
    exclude_tags: []
  },
  {
    category: "Student Development",
    label: "Student Development",
    group: "Student Development",
    include_tags: ["Student Development"],
    exclude_tags: []
  },
  {
    category: "Mejora Continua",
    label: "Mejora Continua",
    group: "Mejora Continua",
    include_tags: ["Mejora Continua"],
    exclude_tags: []
  },
  {
    category: "International Development",
    label: "International Development",
    group: "International Development",
    include_tags: ["International Development"],
    exclude_tags: []
  },
  {
    category: "Semiconductors",
    label: "Semiconductors",
    group: "Semiconductors",
    include_tags: ["Semiconductors"],
    exclude_tags: []
  },
  {
    category: "Income Tax",
    label: "Income Tax",
    group: "Income Tax",
    include_tags: ["Income Tax"],
    exclude_tags: []
  },
  {
    category: "Piping",
    label: "Piping",
    group: "Piping",
    include_tags: ["Piping"],
    exclude_tags: []
  },
  {
    category: "Elder Care",
    label: "Elder Care",
    group: "Elder Care",
    include_tags: ["Elder Care"],
    exclude_tags: []
  },
  {
    category: "Referrals",
    label: "Referrals",
    group: "Referrals",
    include_tags: ["Referrals"],
    exclude_tags: []
  },
  {
    category: "Code",
    label: "Code",
    group: "Code",
    include_tags: ["Code"],
    exclude_tags: []
  },
  {
    category: "Pmo",
    label: "Pmo",
    group: "Pmo",
    include_tags: ["Pmo"],
    exclude_tags: []
  },
  {
    category: "Algorithms",
    label: "Algorithms",
    group: "Algorithms",
    include_tags: ["Algorithms"],
    exclude_tags: []
  },
  {
    category: "Warehouse Management",
    label: "Warehouse Management",
    group: "Warehouse Management",
    include_tags: ["Warehouse Management"],
    exclude_tags: []
  },
  {
    category: "Investors",
    label: "Investors",
    group: "Investors",
    include_tags: ["Investors"],
    exclude_tags: []
  },
  {
    category: "Supplies",
    label: "Supplies",
    group: "Supplies",
    include_tags: ["Supplies"],
    exclude_tags: []
  },
  {
    category: "Sponsorship",
    label: "Sponsorship",
    group: "Sponsorship",
    include_tags: ["Sponsorship"],
    exclude_tags: []
  },
  {
    category: "User Interface Design",
    label: "User Interface Design",
    group: "User Interface Design",
    include_tags: ["User Interface Design"],
    exclude_tags: []
  },
  {
    category: "Banquets",
    label: "Banquets",
    group: "Banquets",
    include_tags: ["Banquets"],
    exclude_tags: []
  },
  {
    category: "Resorts",
    label: "Resorts",
    group: "Resorts",
    include_tags: ["Resorts"],
    exclude_tags: []
  },
  {
    category: "Condos",
    label: "Condos",
    group: "Condos",
    include_tags: ["Condos"],
    exclude_tags: []
  },
  {
    category: "International Trade",
    label: "International Trade",
    group: "International Trade",
    include_tags: ["International Trade"],
    exclude_tags: []
  },
  {
    category: "Acute Care",
    label: "Acute Care",
    group: "Acute Care",
    include_tags: ["Acute Care"],
    exclude_tags: []
  },
  {
    category: "Forms",
    label: "Forms",
    group: "Forms",
    include_tags: ["Forms"],
    exclude_tags: []
  },
  {
    category: "Trabalho Em Equipe",
    label: "Trabalho Em Equipe",
    group: "Trabalho Em Equipe",
    include_tags: ["Trabalho Em Equipe"],
    exclude_tags: []
  },
  {
    category: "German",
    label: "German",
    group: "German",
    include_tags: ["German"],
    exclude_tags: []
  },
  {
    category: "Kundendienst",
    label: "Kundendienst",
    group: "Kundendienst",
    include_tags: ["Kundendienst"],
    exclude_tags: []
  },
  {
    category: "User Acceptance Testing",
    label: "User Acceptance Testing",
    group: "User Acceptance Testing",
    include_tags: ["User Acceptance Testing"],
    exclude_tags: []
  },
  {
    category: "Patient Education",
    label: "Patient Education",
    group: "Patient Education",
    include_tags: ["Patient Education"],
    exclude_tags: []
  },
  {
    category: "Test Planning",
    label: "Test Planning",
    group: "Test Planning",
    include_tags: ["Test Planning"],
    exclude_tags: []
  },
  {
    category: "Police",
    label: "Police",
    group: "Police",
    include_tags: ["Police"],
    exclude_tags: []
  },
  {
    category: "Cash Management",
    label: "Cash Management",
    group: "Cash Management",
    include_tags: ["Cash Management"],
    exclude_tags: []
  },
  {
    category: "Screening",
    label: "Screening",
    group: "Screening",
    include_tags: ["Screening"],
    exclude_tags: []
  },
  {
    category: "Leases",
    label: "Leases",
    group: "Leases",
    include_tags: ["Leases"],
    exclude_tags: []
  },
  {
    category: "Cell Culture",
    label: "Cell Culture",
    group: "Cell Culture",
    include_tags: ["Cell Culture"],
    exclude_tags: []
  },
  {
    category: "Pharmaceutical Sales",
    label: "Pharmaceutical Sales",
    group: "Pharmaceutical Sales",
    include_tags: ["Pharmaceutical Sales"],
    exclude_tags: []
  },
  {
    category: "Modeling",
    label: "Modeling",
    group: "Modeling",
    include_tags: ["Modeling"],
    exclude_tags: []
  },
  {
    category: "Medicaid",
    label: "Medicaid",
    group: "Medicaid",
    include_tags: ["Medicaid"],
    exclude_tags: []
  },
  {
    category: "Brochures",
    label: "Brochures",
    group: "Brochures",
    include_tags: ["Brochures"],
    exclude_tags: []
  },
  {
    category: "Post Production",
    label: "Post Production",
    group: "Post Production",
    include_tags: ["Post Production"],
    exclude_tags: []
  },
  {
    category: "Scripting",
    label: "Scripting",
    group: "Scripting",
    include_tags: ["Scripting"],
    exclude_tags: []
  },
  {
    category: "P&l Management",
    label: "P&l Management",
    group: "P&l Management",
    include_tags: ["P&l Management"],
    exclude_tags: []
  },
  {
    category: "Appeals",
    label: "Appeals",
    group: "Appeals",
    include_tags: ["Appeals"],
    exclude_tags: []
  },
  {
    category: "Content Management",
    label: "Content Management",
    group: "Content Management",
    include_tags: ["Content Management"],
    exclude_tags: []
  },
  {
    category: "Business Management",
    label: "Business Management",
    group: "Business Management",
    include_tags: ["Business Management"],
    exclude_tags: []
  },
  {
    category: "Firewalls",
    label: "Firewalls",
    group: "Firewalls",
    include_tags: ["Firewalls"],
    exclude_tags: []
  },
  {
    category: "Workshops",
    label: "Workshops",
    group: "Workshops",
    include_tags: ["Workshops"],
    exclude_tags: []
  },
  {
    category: "Customer Support",
    label: "Customer Support",
    group: "Customer Support",
    include_tags: ["Customer Support"],
    exclude_tags: []
  },
  {
    category: "Risk",
    label: "Risk",
    group: "Risk",
    include_tags: ["Risk"],
    exclude_tags: []
  },
  {
    category: "External Audit",
    label: "External Audit",
    group: "External Audit",
    include_tags: ["External Audit"],
    exclude_tags: []
  },
  {
    category: "It Operations",
    label: "It Operations",
    group: "It Operations",
    include_tags: ["It Operations"],
    exclude_tags: []
  },
  {
    category: "Wealth Management",
    label: "Wealth Management",
    group: "Wealth Management",
    include_tags: ["Wealth Management"],
    exclude_tags: []
  },
  {
    category: "Criminal Law",
    label: "Criminal Law",
    group: "Criminal Law",
    include_tags: ["Criminal Law"],
    exclude_tags: []
  },
  {
    category: "Newspapers",
    label: "Newspapers",
    group: "Newspapers",
    include_tags: ["Newspapers"],
    exclude_tags: []
  },
  {
    category: "Advertising Agency",
    label: "Advertising Agency",
    group: "Advertising Agency",
    include_tags: ["Advertising Agency"],
    exclude_tags: []
  },
  {
    category: "Pharmacy",
    label: "Pharmacy",
    group: "Pharmacy",
    include_tags: ["Pharmacy"],
    exclude_tags: []
  },
  {
    category: "Healthcare Consulting",
    label: "Healthcare Consulting",
    group: "Healthcare Consulting",
    include_tags: ["Healthcare Consulting"],
    exclude_tags: []
  },
  {
    category: "Benefits Administration",
    label: "Benefits Administration",
    group: "Benefits Administration",
    include_tags: ["Benefits Administration"],
    exclude_tags: []
  },
  {
    category: "Volunteering",
    label: "Volunteering",
    group: "Volunteering",
    include_tags: ["Volunteering"],
    exclude_tags: []
  },
  {
    category: "Soa",
    label: "Soa",
    group: "Soa",
    include_tags: ["Soa"],
    exclude_tags: []
  },
  {
    category: "Jsp",
    label: "Jsp",
    group: "Jsp",
    include_tags: ["Jsp"],
    exclude_tags: []
  },
  {
    category: "Liability",
    label: "Liability",
    group: "Liability",
    include_tags: ["Liability"],
    exclude_tags: []
  },
  {
    category: "Switches",
    label: "Switches",
    group: "Switches",
    include_tags: ["Switches"],
    exclude_tags: []
  },
  {
    category: "Qualification",
    label: "Qualification",
    group: "Qualification",
    include_tags: ["Qualification"],
    exclude_tags: []
  },
  {
    category: "Project Portfolio Management",
    label: "Project Portfolio Management",
    group: "Project Portfolio Management",
    include_tags: ["Project Portfolio Management"],
    exclude_tags: []
  },
  {
    category: "Upstream",
    label: "Upstream",
    group: "Upstream",
    include_tags: ["Upstream"],
    exclude_tags: []
  },
  {
    category: "Business Transformation",
    label: "Business Transformation",
    group: "Business Transformation",
    include_tags: ["Business Transformation"],
    exclude_tags: []
  },
  {
    category: "Direct Mail",
    label: "Direct Mail",
    group: "Direct Mail",
    include_tags: ["Direct Mail"],
    exclude_tags: []
  },
  {
    category: "Plc",
    label: "Plc",
    group: "Plc",
    include_tags: ["Plc"],
    exclude_tags: []
  },
  {
    category: "Group Therapy",
    label: "Group Therapy",
    group: "Group Therapy",
    include_tags: ["Group Therapy"],
    exclude_tags: []
  },
  {
    category: "Core Java",
    label: "Core Java",
    group: "Core Java",
    include_tags: ["Core Java"],
    exclude_tags: []
  },
  {
    category: "Handling Money",
    label: "Handling Money",
    group: "Handling Money",
    include_tags: ["Handling Money"],
    exclude_tags: []
  },
  {
    category: "Driving",
    label: "Driving",
    group: "Driving",
    include_tags: ["Driving"],
    exclude_tags: []
  },
  {
    category: "Trucking",
    label: "Trucking",
    group: "Trucking",
    include_tags: ["Trucking"],
    exclude_tags: []
  },
  {
    category: "Latex",
    label: "Latex",
    group: "Latex",
    include_tags: ["Latex"],
    exclude_tags: []
  },
  {
    category: "Interfaces",
    label: "Interfaces",
    group: "Interfaces",
    include_tags: ["Interfaces"],
    exclude_tags: []
  },
  {
    category: "Interim Management",
    label: "Interim Management",
    group: "Interim Management",
    include_tags: ["Interim Management"],
    exclude_tags: []
  },
  {
    category: "Banca",
    label: "Banca",
    group: "Banca",
    include_tags: ["Banca"],
    exclude_tags: []
  },
  {
    category: "Html 5",
    label: "Html 5",
    group: "Html 5",
    include_tags: ["Html 5"],
    exclude_tags: []
  },
  {
    category: "Gaap",
    label: "Gaap",
    group: "Gaap",
    include_tags: ["Gaap"],
    exclude_tags: []
  },
  {
    category: "Supply Management",
    label: "Supply Management",
    group: "Supply Management",
    include_tags: ["Supply Management"],
    exclude_tags: []
  },
  {
    category: "Web Analytics",
    label: "Web Analytics",
    group: "Web Analytics",
    include_tags: ["Web Analytics"],
    exclude_tags: []
  },
  {
    category: "Lifesciences",
    label: "Lifesciences",
    group: "Lifesciences",
    include_tags: ["Lifesciences"],
    exclude_tags: []
  },
  {
    category: "Drilling",
    label: "Drilling",
    group: "Drilling",
    include_tags: ["Drilling"],
    exclude_tags: []
  },
  {
    category: "Lotus Notes",
    label: "Lotus Notes",
    group: "Lotus Notes",
    include_tags: ["Lotus Notes"],
    exclude_tags: []
  },
  {
    category: "Friendly",
    label: "Friendly",
    group: "Friendly",
    include_tags: ["Friendly"],
    exclude_tags: []
  },
  {
    category: "Textiles",
    label: "Textiles",
    group: "Textiles",
    include_tags: ["Textiles"],
    exclude_tags: []
  },
  {
    category: "Database Administration",
    label: "Database Administration",
    group: "Database Administration",
    include_tags: ["Database Administration"],
    exclude_tags: []
  },
  {
    category: "Law Enforcement",
    label: "Law Enforcement",
    group: "Law Enforcement",
    include_tags: ["Law Enforcement"],
    exclude_tags: []
  },
  {
    category: "Executive Search",
    label: "Executive Search",
    group: "Executive Search",
    include_tags: ["Executive Search"],
    exclude_tags: []
  },
  {
    category: "Indian",
    label: "Indian",
    group: "Indian",
    include_tags: ["Indian"],
    exclude_tags: []
  },
  {
    category: "Feasibility Studies",
    label: "Feasibility Studies",
    group: "Feasibility Studies",
    include_tags: ["Feasibility Studies"],
    exclude_tags: []
  },
  {
    category: "Peoplesoft",
    label: "Peoplesoft",
    group: "Peoplesoft",
    include_tags: ["Peoplesoft"],
    exclude_tags: []
  },
  {
    category: "Guidelines",
    label: "Guidelines",
    group: "Guidelines",
    include_tags: ["Guidelines"],
    exclude_tags: []
  },
  {
    category: "Regulatory Affairs",
    label: "Regulatory Affairs",
    group: "Regulatory Affairs",
    include_tags: ["Regulatory Affairs"],
    exclude_tags: []
  },
  {
    category: "Mining",
    label: "Mining",
    group: "Mining",
    include_tags: ["Mining"],
    exclude_tags: []
  },
  {
    category: "Financial Planning",
    label: "Financial Planning",
    group: "Financial Planning",
    include_tags: ["Financial Planning"],
    exclude_tags: []
  },
  {
    category: "Cross Functional Team",
    label: "Cross Functional Team",
    group: "Cross Functional Team",
    include_tags: ["Cross Functional Team"],
    exclude_tags: []
  },
  {
    category: "After Effects",
    label: "After Effects",
    group: "After Effects",
    include_tags: ["After Effects"],
    exclude_tags: []
  },
  {
    category: "Marketing Social",
    label: "Marketing Social",
    group: "Marketing Social",
    include_tags: ["Marketing Social"],
    exclude_tags: []
  },
  {
    category: "Planning",
    label: "Planning",
    group: "Planning",
    include_tags: ["Planning"],
    exclude_tags: []
  },
  {
    category: "Security Management",
    label: "Security Management",
    group: "Security Management",
    include_tags: ["Security Management"],
    exclude_tags: []
  },
  {
    category: "Airports",
    label: "Airports",
    group: "Airports",
    include_tags: ["Airports"],
    exclude_tags: []
  },
  {
    category: "Google Adwords",
    label: "Google Adwords",
    group: "Google Adwords",
    include_tags: ["Google Adwords"],
    exclude_tags: []
  },
  {
    category: "Business Requirements",
    label: "Business Requirements",
    group: "Business Requirements",
    include_tags: ["Business Requirements"],
    exclude_tags: []
  },
  {
    category: "Metodologia Kaizen",
    label: "Metodologia Kaizen",
    group: "Metodologia Kaizen",
    include_tags: ["Metodologia Kaizen"],
    exclude_tags: []
  },
  {
    category: "Concepts",
    label: "Concepts",
    group: "Concepts",
    include_tags: ["Concepts"],
    exclude_tags: []
  },
  {
    category: "Dns",
    label: "Dns",
    group: "Dns",
    include_tags: ["Dns"],
    exclude_tags: []
  },
  {
    category: "Perl",
    label: "Perl",
    group: "Perl",
    include_tags: ["Perl"],
    exclude_tags: []
  },
  {
    category: "Career Counseling",
    label: "Career Counseling",
    group: "Career Counseling",
    include_tags: ["Career Counseling"],
    exclude_tags: []
  },
  {
    category: "Applicant Tracking Systems",
    label: "Applicant Tracking Systems",
    group: "Applicant Tracking Systems",
    include_tags: ["Applicant Tracking Systems"],
    exclude_tags: []
  },
  {
    category: "Public Sector",
    label: "Public Sector",
    group: "Public Sector",
    include_tags: ["Public Sector"],
    exclude_tags: []
  },
  {
    category: "Gsm",
    label: "Gsm",
    group: "Gsm",
    include_tags: ["Gsm"],
    exclude_tags: []
  },
  {
    category: "Dance",
    label: "Dance",
    group: "Dance",
    include_tags: ["Dance"],
    exclude_tags: []
  },
  {
    category: "Corporate Events",
    label: "Corporate Events",
    group: "Corporate Events",
    include_tags: ["Corporate Events"],
    exclude_tags: []
  },
  {
    category: "Patient",
    label: "Patient",
    group: "Patient",
    include_tags: ["Patient"],
    exclude_tags: []
  },
  {
    category: "Athletics",
    label: "Athletics",
    group: "Athletics",
    include_tags: ["Athletics"],
    exclude_tags: []
  },
  {
    category: "Complaints",
    label: "Complaints",
    group: "Complaints",
    include_tags: ["Complaints"],
    exclude_tags: []
  },
  {
    category: "Honor",
    label: "Honor",
    group: "Honor",
    include_tags: ["Honor"],
    exclude_tags: []
  },
  {
    category: "Pcr",
    label: "Pcr",
    group: "Pcr",
    include_tags: ["Pcr"],
    exclude_tags: []
  },
  {
    category: "Listings",
    label: "Listings",
    group: "Listings",
    include_tags: ["Listings"],
    exclude_tags: []
  },
  {
    category: "Fine Dining",
    label: "Fine Dining",
    group: "Fine Dining",
    include_tags: ["Fine Dining"],
    exclude_tags: []
  },
  {
    category: "Fashion Design",
    label: "Fashion Design",
    group: "Fashion Design",
    include_tags: ["Fashion Design"],
    exclude_tags: []
  },
  {
    category: "Scholarships",
    label: "Scholarships",
    group: "Scholarships",
    include_tags: ["Scholarships"],
    exclude_tags: []
  },
  {
    category: "Information Security",
    label: "Information Security",
    group: "Information Security",
    include_tags: ["Information Security"],
    exclude_tags: []
  },
  {
    category: "Stratejik Planlama",
    label: "Stratejik Planlama",
    group: "Stratejik Planlama",
    include_tags: ["Stratejik Planlama"],
    exclude_tags: []
  },
  {
    category: "Mutual Funds",
    label: "Mutual Funds",
    group: "Mutual Funds",
    include_tags: ["Mutual Funds"],
    exclude_tags: []
  },
  {
    category: "Seis Sigma",
    label: "Seis Sigma",
    group: "Seis Sigma",
    include_tags: ["Seis Sigma"],
    exclude_tags: []
  },
  {
    category: "Software Design",
    label: "Software Design",
    group: "Software Design",
    include_tags: ["Software Design"],
    exclude_tags: []
  },
  {
    category: "Os X",
    label: "Os X",
    group: "Os X",
    include_tags: ["Os X"],
    exclude_tags: []
  },
  {
    category: "Jira",
    label: "Jira",
    group: "Jira",
    include_tags: ["Jira"],
    exclude_tags: []
  },
  {
    category: "Foreign Languages",
    label: "Foreign Languages",
    group: "Foreign Languages",
    include_tags: ["Foreign Languages"],
    exclude_tags: []
  },
  {
    category: "Structural Engineering",
    label: "Structural Engineering",
    group: "Structural Engineering",
    include_tags: ["Structural Engineering"],
    exclude_tags: []
  },
  {
    category: "Financial Markets",
    label: "Financial Markets",
    group: "Financial Markets",
    include_tags: ["Financial Markets"],
    exclude_tags: []
  },
  {
    category: "Welding",
    label: "Welding",
    group: "Welding",
    include_tags: ["Welding"],
    exclude_tags: []
  },
  {
    category: "Enseignement",
    label: "Enseignement",
    group: "Enseignement",
    include_tags: ["Enseignement"],
    exclude_tags: []
  },
  {
    category: "Texas",
    label: "Texas",
    group: "Texas",
    include_tags: ["Texas"],
    exclude_tags: []
  },
  {
    category: "Bloomberg",
    label: "Bloomberg",
    group: "Bloomberg",
    include_tags: ["Bloomberg"],
    exclude_tags: []
  },
  {
    category: "Oop",
    label: "Oop",
    group: "Oop",
    include_tags: ["Oop"],
    exclude_tags: []
  },
  {
    category: "Cpa",
    label: "Cpa",
    group: "Cpa",
    include_tags: ["Cpa"],
    exclude_tags: []
  },
  {
    category: "Linkedin",
    label: "Linkedin",
    group: "Linkedin",
    include_tags: ["Linkedin"],
    exclude_tags: []
  },
  {
    category: "Hvac",
    label: "Hvac",
    group: "Hvac",
    include_tags: ["Hvac"],
    exclude_tags: []
  },
  {
    category: "Composition",
    label: "Composition",
    group: "Composition",
    include_tags: ["Composition"],
    exclude_tags: []
  },
  {
    category: "Workflow",
    label: "Workflow",
    group: "Workflow",
    include_tags: ["Workflow"],
    exclude_tags: []
  },
  {
    category: "Occupational Health",
    label: "Occupational Health",
    group: "Occupational Health",
    include_tags: ["Occupational Health"],
    exclude_tags: []
  },
  {
    category: "Asset",
    label: "Asset",
    group: "Asset",
    include_tags: ["Asset"],
    exclude_tags: []
  },
  {
    category: "Consideration",
    label: "Consideration",
    group: "Consideration",
    include_tags: ["Consideration"],
    exclude_tags: []
  },
  {
    category: "Trend Analysis",
    label: "Trend Analysis",
    group: "Trend Analysis",
    include_tags: ["Trend Analysis"],
    exclude_tags: []
  },
  {
    category: "Physics",
    label: "Physics",
    group: "Physics",
    include_tags: ["Physics"],
    exclude_tags: []
  },
  {
    category: "Gis",
    label: "Gis",
    group: "Gis",
    include_tags: ["Gis"],
    exclude_tags: []
  },
  {
    category: "Cost Accounting",
    label: "Cost Accounting",
    group: "Cost Accounting",
    include_tags: ["Cost Accounting"],
    exclude_tags: []
  },
  {
    category: "Customer Insight",
    label: "Customer Insight",
    group: "Customer Insight",
    include_tags: ["Customer Insight"],
    exclude_tags: []
  },
  {
    category: "New Hire Orientations",
    label: "New Hire Orientations",
    group: "New Hire Orientations",
    include_tags: ["New Hire Orientations"],
    exclude_tags: []
  },
  {
    category: "Comunicaciones De Marketing",
    label: "Comunicaciones De Marketing",
    group: "Comunicaciones De Marketing",
    include_tags: ["Comunicaciones De Marketing"],
    exclude_tags: []
  },
  {
    category: "Securities",
    label: "Securities",
    group: "Securities",
    include_tags: ["Securities"],
    exclude_tags: []
  },
  {
    category: "Producing",
    label: "Producing",
    group: "Producing",
    include_tags: ["Producing"],
    exclude_tags: []
  },
  {
    category: "Corporate Tax",
    label: "Corporate Tax",
    group: "Corporate Tax",
    include_tags: ["Corporate Tax"],
    exclude_tags: []
  },
  {
    category: "Pre Opening",
    label: "Pre Opening",
    group: "Pre Opening",
    include_tags: ["Pre Opening"],
    exclude_tags: []
  },
  {
    category: "Physician Relations",
    label: "Physician Relations",
    group: "Physician Relations",
    include_tags: ["Physician Relations"],
    exclude_tags: []
  },
  {
    category: "Supply",
    label: "Supply",
    group: "Supply",
    include_tags: ["Supply"],
    exclude_tags: []
  },
  {
    category: "Visual Arts",
    label: "Visual Arts",
    group: "Visual Arts",
    include_tags: ["Visual Arts"],
    exclude_tags: []
  },
  {
    category: "Flash",
    label: "Flash",
    group: "Flash",
    include_tags: ["Flash"],
    exclude_tags: []
  },
  {
    category: "International Logistics",
    label: "International Logistics",
    group: "International Logistics",
    include_tags: ["International Logistics"],
    exclude_tags: []
  },
  {
    category: "Microsoft",
    label: "Microsoft",
    group: "Microsoft",
    include_tags: ["Microsoft"],
    exclude_tags: []
  },
  {
    category: "Music Industry",
    label: "Music Industry",
    group: "Music Industry",
    include_tags: ["Music Industry"],
    exclude_tags: []
  },
  {
    category: "Executive Coaching",
    label: "Executive Coaching",
    group: "Executive Coaching",
    include_tags: ["Executive Coaching"],
    exclude_tags: []
  },
  {
    category: "Network Design",
    label: "Network Design",
    group: "Network Design",
    include_tags: ["Network Design"],
    exclude_tags: []
  },
  {
    category: "Mental Health Counseling",
    label: "Mental Health Counseling",
    group: "Mental Health Counseling",
    include_tags: ["Mental Health Counseling"],
    exclude_tags: []
  },
  {
    category: "Stakeholder Engagement",
    label: "Stakeholder Engagement",
    group: "Stakeholder Engagement",
    include_tags: ["Stakeholder Engagement"],
    exclude_tags: []
  },
  {
    category: "Sap R/3",
    label: "Sap R/3",
    group: "Sap R/3",
    include_tags: ["Sap R/3"],
    exclude_tags: []
  },
  {
    category: "Checks",
    label: "Checks",
    group: "Checks",
    include_tags: ["Checks"],
    exclude_tags: []
  },
  {
    category: "News Writing",
    label: "News Writing",
    group: "News Writing",
    include_tags: ["News Writing"],
    exclude_tags: []
  },
  {
    category: "Marketing Communication",
    label: "Marketing Communication",
    group: "Marketing Communication",
    include_tags: ["Marketing Communication"],
    exclude_tags: []
  },
];