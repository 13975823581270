import React, {Component, Fragment} from 'react';
import Parser from 'html-react-parser';
import { Link } from "react-router-dom";

class Details extends Component {

  state = {
    showLogo: true
  }

  getLocation(location) {
    let locationStr = "";
    if (location.street) {
      locationStr = location.street;
    }

    if (location.street2) {
      locationStr += ", " + location.street2;
    }

    const array = [location.city, (location.state||"") + " " + (location.postalCode||""), location.country];
    const cityStateCountry = array.filter((item) => item && item !== "").join(", ");
    locationStr = locationStr ? locationStr + "<br />" : locationStr;
    locationStr += cityStateCountry; 
    return locationStr;
  }

  onLogoError = (e) => {
    e.target.onerror = null; 
    this.setState({showLogo: false});
  }

  render() {
    const company = this.props.leadDetail.company;

    return(
      <div className="details">
        <div className="details-data">
          {(this.state.showLogo && company.website) ?
            <img className="details-data__logo-img" src={`https://logo.clearbit.com/${company.website}`} onError={(e) => this.onLogoError(e)}/>
            :
            <div className="details-data__logo">Logo</div> 
          }
          <h5 className="details-data__title">{company.name}</h5>
          
          {company.location && Parser(this.getLocation(company.location)) &&
            <p className="details-data__address">
              <img src="/assest/accountBased/social-icons/pin.svg" alt=""/>
              {Parser(this.getLocation(company.location))}
            </p>
          }

          {company.website &&
            <a className="details-data__link" href={`http://${company.website}`} target="_blank">
              <img src="/assest/accountBased/social-icons/Comb.svg" alt=""/>
              {company.website}
            </a>
          }
          
          {company.phone &&
            <p className="details-data__tel">
              <img src="/assest/accountBased/social-icons/phone.svg" alt=""/>
              {this.props.leadDetail.addedToList ? company.phone : 
                <span className="details-company__field-link" onClick={($event)=> this.props.openAddLeadToListPopup($event, this.props.leadDetail.id)}>ADD TO LIST TO REVEAL</span> 
              }
            </p>
          }

          <div className="details-data__icons">
            {(this.props.leadDetail.addedToList && company.social_profiles?.Linkedin) ?
              <Fragment>
                {company.social_profiles && company.social_profiles.Linkedin ? <a target="_blank" href={`http://${company.social_profiles.linkedin}`} ><img src="/assest/accountBased/social-icons/ln-icon.svg" alt=""/></a> : ''}
                {/* {company.social_profiles && company.social_profiles.facebook ? <a target="_blank" href={`http://${company.social_profiles.facebook}`} ><img src="/assest/accountBased/social-icons/fb-icon.svg" alt=""/></a> : ''}
                {company.social_profiles && company.social_profiles.twitter ? <a target="_blank" href={`http://${company.social_profiles.twitter}`} ><img src="/assest/accountBased/social-icons/tw-icon.svg" alt=""/></a> : ''} */}
              </Fragment>
              :
              <Fragment>
                {company.social_profiles?.Linkedin && 
                <Fragment>
                  <img onClick={($event)=> this.props.openAddLeadToListPopup($event, this.props.leadDetail.id)}  className="disabled" src="/assest/accountBased/social-icons/ln-icon.svg" alt=""/>
                  {/* <img onClick={($event)=> this.props.openAddLeadToListPopup($event, this.props.leadDetail.id)}  className="disabled" src="/assest/accountBased/social-icons/fb-icon.svg" alt=""/>
                  <img onClick={($event)=> this.props.openAddLeadToListPopup($event, this.props.leadDetail.id)}  className="disabled" src="/assest/accountBased/social-icons/tw-icon.svg" alt=""/> */}
                </Fragment>
                }
              </Fragment>
            }
          </div>

        </div>  
        <div className="details-company">
          <h5 className="details-company__title company-title">Company Details</h5>
          
          <p className="details-company__field">
            <span className="details-company__field-label">Revenue: </span>
            <span className="details-company__field-value">{company.revenue ? company.revenue : 'Unknown'}</span>
          </p>

          <p className="details-company__field">
            <span className="details-company__field-label">Industry: </span>
            <span className="details-company__field-value">{company.industry && company.industry.name ? company.industry.name : 'Unkown'}</span>
          </p>

          <p className="details-company__field">
            <span className="details-company__field-label">Employee Size: </span>
            <span className="details-company__field-value">{company.employees ? company.employees : ''}</span>
          </p>

          {/* <p className="details-company__field">
            <span className="details-company__field-label">Adwords Ad Budget: </span>
            <span className="details-company__field-value">{company.adwords_budget && company.adwords_budget.range !== 'None' ? company.adwords_budget.range : 'Unknown'}</span>
            { this.props.leadDetail.addedToList ? 
              <span className="details-company__field-value">{company.adwords_budget.range}</span> 
              :
              <span className="details-company__field-link" onClick={($event)=> this.props.openAddLeadToListPopup($event, this.props.leadDetail.id)}>ADD TO LIST TO REVEAL</span> 
            }
          </p> */}

        </div>
      </div>
    )
  }
}

export default Details;