import React, { Component } from 'react';

class OtherInfo extends Component {

  render() {
    return( 
      <div className="details-cont">
        <div className="details-cont__row">
          
          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>Account ID</p>
              <input placeholder="Text here" type="text" value={this.props.user.account_id} disabled />
            </label>
          </div>

          <div className="details-cont__col">
            <label className="details-cont__label">
              <p>User ID</p>
              <input placeholder="Text here" type="text" value={this.props.user.id} disabled />
            </label>
          </div>
          
        </div>
      </div>
    ) 
  }
}

export default OtherInfo;