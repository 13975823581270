import { SET_ERROR, SET_SUCCESS_MESSAGE } from "../constants/constant";


export function setErrorMessage(err) {
    return {
        type: SET_ERROR,
        payload: err
    }
}
export function setSuccessMessage(succ) {
    return {
        type: SET_SUCCESS_MESSAGE,
        payload: succ
    }
}
