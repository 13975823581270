import {
  LEAD_FILTER_FUZZBOT_STATUS,
  LEAD_FILTER_FUZZBOT_ACTIVE,
  LEAD_FILTER_FUZZBOT_PAUSE,
  LEAD_FILTER_FUZZBOT_NONE,
  LEAD_FILTER_FILTER_NAME,
  LEAD_FILTER_LIST_AT_MAX_CAPACITY,
  LEAD_FILTER_LIST_WITH_ZERO_LEADS,
  LEAD_FILTER_OWNER,
  LEAD_FILTER_OWNERS,
  LEAD_LIST_NAMES,
  LEAD_LIST_PAGINATION,
  LEADS_RECORDS_PER_PAGE,
  LEADS_CLEAR_PAGE_NUMBER,
  LEADS_SORT_BY,
  SET_ALL_CHECK_TO_FALSE,
  LEAD_NAME_FILTER_ACTION
} from '../constants/constant';

export const LEAD_FILTERS_INITIAL_STATE = {
  filters: {
    fuzeBot: false,
    fuzebotActive: false,
    fuzebotPaused: false,
    fuzebotNone: false,
    listsAtMax: false,
    listsWithZeroRemaining: false,
    nameFilter: false,
    ownerState: false,
    owners: "",
    listIDs: "",
    listName: '',
  },
  sorting: {
    sort: "",
    sortOrder: '',
  },
  pagination: {
    recordsPerPage: 10,
    pageNum: 1,
  }
};

export default function (state = LEAD_FILTERS_INITIAL_STATE, action) {
  switch (action.type) {
    case LEAD_FILTER_FUZZBOT_STATUS:
      if (action.payload) {
        return {
          ...state,
          filters: {
            ...state.filters,
            fuzeBot: action.payload,
          }
        };
      } else {
        return {
          ...state,
          filters: {
            ...state.filters,
            fuzeBot: action.payload,
            fuzebotActive: action.payload,
            fuzebotPaused: action.payload,
            fuzebotNone: action.payload
          }
        };
      }
    case LEAD_FILTER_FUZZBOT_ACTIVE:
      return {
        ...state,
        filters: {
          ...state.filters,
          fuzebotActive: action.payload,
        }
      };
    case LEAD_FILTER_FUZZBOT_PAUSE:
      return {
        ...state,
        filters: {
          ...state.filters,
          fuzebotPaused: action.payload,
        }
      };
    case LEAD_FILTER_FUZZBOT_NONE:
      return {
        ...state,
        filters: {
          ...state.filters,
          fuzebotNone: action.payload,
        }
      };
    case LEAD_FILTER_FILTER_NAME:
      return {
        ...state,
        filters: {
          ...state.filters,
          nameFilter: action.payload,
        }
      };
    case LEAD_FILTER_LIST_AT_MAX_CAPACITY:
      return {
        ...state,
        filters: {
          ...state.filters,
          listsAtMax: action.payload,
        }
      };
    case LEAD_FILTER_LIST_WITH_ZERO_LEADS:
      return {
        ...state,
        filters: {
          ...state.filters,
          listsWithZeroRemaining: action.payload,
        }
      };
    case LEAD_FILTER_OWNER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ownerState: action.payload,
          owners: !action.payload? '' : state.owners,
        }
      };
    case LEADS_RECORDS_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          recordsPerPage: action.payload,
          pageNum: 1,
        }
      }
    case LEADS_CLEAR_PAGE_NUMBER:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageNum: 1
        }
      }
    case LEAD_FILTER_OWNERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          owners: action.payload
        }
      };
    case LEAD_LIST_NAMES:
      return {
        ...state,
        filters: {
          ...state.filters,
          listIDs: action.payload
        }
      };
    case LEAD_LIST_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageNum: action.payload
        }
      }
    case LEAD_NAME_FILTER_ACTION:
      return {
        ...state,
        filters: {
          ...state.filters,
          listName: action.payload
        }
      }
    case LEADS_SORT_BY:
      return {
        ...state,
        sorting: {
          ...state.sorting,
          sort: action.payload.status,
          sortOrder: action.payload.sortOrder
        }
      }
    case SET_ALL_CHECK_TO_FALSE:
      return {
        ...state,
        filters: {
          ...state.filters,
          fuzeBot: action.payload,
          fuzebotActive: action.payload,
          fuzebotPaused: action.payload,
          fuzebotNone: action.payload,
          nameFilter: action.payload,
          listsAtMax: action.payload,
          listsWithZeroRemaining: action.payload,
          ownerState: action.payload,
          owners: "",
          listIDs: "",
          listName: '',
        }
      }
    default:
      return state;
  }
}
