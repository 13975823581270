import React, { Component } from "react";
import AddCollegeGroup from "../AddCollegeGroup";
import EditCollegeGroups from "../EditCollegeGroups";
import CollegeField from "../CollegeField";

class CollegeDropdown extends Component {
  state = {
    i: 0,
    value: "",
    addGroupPanel: false,
    editGroupsPanel: false,
    fieldPanel: false,
    showMessage: false,
    selectedColleges: [],
    collegeOptions: [],
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
    this.inputRef = React.createRef();
    // const includeTag, excludeTag, selectedGroups = [];
    
    // if (this.props.includeKeywords && this.props.includeKeywords.length) {
    //   includeTag = this.props.includeKeywords;
    // }
  }

  componentWillMount() {
    this.updateSelectedGroups(this.props);
  }
  
  componentDidUpdate(prevProps, prevState) {
    this.updateSelectedGroups(prevProps);  
  }

  updateSelectedGroups = (props) => {
    const {selectedGroups} = props;
    if (Boolean(selectedGroups && selectedGroups.length !== this.state.selectedColleges.length)) {
    this.setState({
      selectedColleges: selectedGroups,
      fieldPanel: !!selectedGroups.length
    });
    // this.sort(this.state.roleGroups);
  }
  }


  componentWillUpdate() {
    const { clearFields, updateClearFields } = this.props;
    if (clearFields) {
      updateClearFields();
      this.setState({
        i: 0,
        value: "",
        addGroupPanel: false,
        editGroupsPanel: this.state.editGroupsPanel,
        fieldPanel: false,
        selectedColleges: [],
        collegeOptions: [],
      });
      return true;
    }
    // this.updateSelectedGroups();
  }

  handleClickOutside = e => {
    if (this.inputRef.current && !this.inputRef.current.contains(e.target)) {
      this.setState({
        addGroupPanel: false,
        editGroupsPanel: false,
        fieldPanel: true
      });
    }
  };

  getValue = e => {
    const value = e.target.value;
    this.setState({ value: value });
};

  openPanel = index => {
    // document.addEventListener("click", this.handleClickOutside);
    if (index === "add") {
      document.removeEventListener("click", this.handleClickOutside);
      this.setState({
        addGroupPanel: true,
        editGroupsPanel: false,
        fieldPanel: false
      });
      // document.addEventListener("click", this.handleClickOutside);
    } else if (index === "edit") {
      document.removeEventListener("click", this.handleClickOutside);
      this.setState({
        addGroupPanel: false,
        editGroupsPanel: !this.state.editGroupsPanel
      });
    } else if (index === "field") {
      this.setState({ 
        addGroupPanel: false,fieldPanel: true });
    } else {
      document.removeEventListener("click", this.handleClickOutside);
      this.setState({ fieldPanel: false, editGroupsPanel: false });
    }
    document.addEventListener("click", this.handleClickOutside);
  };
  searchTag = input => {
    const { finishRoleGroups } = this.state;    
  };

  addExcludeTag = (val, role) => {
    const { excludeTag, includeTag } = this.state;
    const { setExcludeTags, setIncludeTags, name } = this.props;
    // if (role === "exclude") {
    if (this.state.excludeOption) {
      excludeTag.push(val);
      setExcludeTags(excludeTag, name);
    } else {
      includeTag.push(val);
      setIncludeTags(includeTag, name);
    }
    this.setState({
      excludeTag: excludeTag,
      value: "",
      filteredRoleGroups: this.state.finishRoleGroups,
      includeTag: includeTag
    });
    this.openPanel("field");
  };

  dellExcludeTag = (item, e, role) => {
    const { name, setExcludeTags, setIncludeTags } = this.props;
    e.stopPropagation();
    document.removeEventListener("click", this.handleClickOutside);
    const { excludeTag, includeTag } = this.state;
    if (role === "exclude") {
      const exclArr = excludeTag.filter(el => {
        return item !== el;
      });
      this.setState({ excludeTag: exclArr });
      setExcludeTags(exclArr, name);
    } else {
      const inclArr = includeTag.filter(el => {
        return item !== el;
      });
      this.setState({ includeTag: inclArr });
      setIncludeTags(inclArr, name);
    }
    document.addEventListener("click", this.handleClickOutside);
  };

  //добавление группы в выбранные
  addGroupSelect = (cat, isTechName, techName, role, includeOnlyTag) => {
    const { roleGroups, selectedGroups } = this.state;
    const { name } = this.props;
    const foundAlready = this.state.selectedColleges.find(loc => loc === cat.term);
    if (!foundAlready) {
      const updatedColleges = [...this.state.selectedColleges];
      updatedColleges.push(cat.term);
      this.setState({selectedColleges: updatedColleges, addGroupPanel: false, value: ''});
      this.props.setGroups(name, updatedColleges);
    }
    this.openPanel("field");
  }

  //удаление группы из выбраных
  delGroupSelect = (e, cat, isExcludeTag, isTechName, techName) => {
    document.removeEventListener("click", this.handleClickOutside);
    e.stopPropagation();
    const filteredLocations = this.state.selectedColleges.filter(loc => loc !== cat);
    this.setState({selectedColleges: filteredLocations});
    this.props.setGroups(this.props.name, filteredLocations);
    document.addEventListener("click", this.handleClickOutside);
  };

  closeField = bool => {
    this.setState({ notField: bool });
  };

  toggleExcludeOption = async () => {
    this.setState({excludeOption: !this.state.excludeOption});
  }

  setCollegeOptions = (colleges) => {
    this.setState({collegeOptions: colleges});
  }
  
  render() {
    const {
      editGroupsPanel,
      roleGroups,
      addGroupPanel,
      fieldPanel,
      finishRoleGroups,
      filteredRoleGroups,
      finishRoleGroupsSelected,
      value,
      excludeTag,
      showMessage,
      includeTag,
      selectedGroups
    } = this.state;
    const { inputType, placeholder, name, haveTags, label, customInput, showExclude, basicHeading1 } = this.props;
    const count =
      (this.state.selectedColleges ? this.state.selectedColleges.length: 0);

    return (
      <div ref={this.inputRef}>
        <input
          value={value}
          type={inputType}
          name={name}
          autoComplete="nope"
          placeholder={placeholder}
          onChange={this.getValue}
          onFocus={() => {
            this.openPanel("add");
          }}
        />
        {addGroupPanel ? (
          <AddCollegeGroup
            addGroupSelect={this.addGroupSelect}
            roleGroups={roleGroups}
            finishRoleGroups={filteredRoleGroups}
            addExcludeTag={this.addExcludeTag}
            addTagInSelect={this.addExcludeTag}
            value={value}
            customInput={customInput}
            showMessage={showMessage}
            name={name}
            haveTags={haveTags}
            label={label}
            showExclude={showExclude}
            basicHeading1={basicHeading1}
            excludeOption={this.state.excludeOption}
            toggleExcludeOption={this.toggleExcludeOption}
            collegeOptions={this.state.collegeOptions}
            setCollegeOptions={this.setCollegeOptions}
          />
        ) : (
            ""
          )}

        {fieldPanel &&  count > 0 ? (
          <CollegeField
            openPanel={this.openPanel}
            delGroupSelect={this.delGroupSelect}
            closeField={this.closeField}
            count={count}
            colleges={this.state.selectedColleges}
          />
        ) : (
            ""
          )}

          {/* {editGroupsPanel && count > 0 ? (
          <EditCollegeGroups
            finishRoleGroupsSelected={finishRoleGroupsSelected}
            openEditGroupsTags={this.openEditGroupsTags}
            delGroupSelect={this.delGroupSelect}
            delTagInSelect={this.delTagInSelect}
            excludeTag={excludeTag}
            dellExcludeTag={this.dellExcludeTag}
            includeTag={includeTag}
            dellIncludeTag={this.dellIncludeTag}
            haveTags={haveTags}
            name={name}
            basicHeading1={basicHeading1}
            labelField="term"
            colleges={this.state.selectedColleges}
          />
        ) : (
            ""
          )} */}
      </div>
    );
  }
}

export default CollegeDropdown;
