import React, { Fragment, Component } from "react";
import { LocationType } from "../../../../../constants/constant";

class LocationField extends Component {
  getLocationTypeTitle = (locationItem) => {
    if (locationItem.type === LocationType.City) {
      return "City";
    }
    if (locationItem.type === LocationType.State) {
      return "State";
    }
    if (locationItem.type === LocationType.Continent) {
      return "Continent";
    }
    if (locationItem.type === LocationType.Country) {
      return "Country";
    }
    if (locationItem.type === LocationType.Group) {
      return "Region";
    }
    if (locationItem.type === LocationType.Metro) {
      return "Metro";
    } else {
      return "Keyword";
        }
  };

  getFieldText = (item) => {
    const { labelField } = this.props;
    let str =
      this.getLocationTypeTitle(item) +
      ": " +
      item[labelField].replace("|", ", ");

    if (str[str.length - 2] == ",") return str.substring(0, str.length - 2);
    return str;
  };
  render() {
    const {
      openPanel,
      count,
      locations,
      delGroupSelect,
      labelField,
    } = this.props;
    //field__custom_exclude exclude_tag
    let includeTag = locations.filter((item) => !item.excludeGroup);
    let excludeTag = locations.filter((item) => item.excludeGroup);
    return (
      <div className="people-market-field">
        <div className="people-market-field__box">
          {includeTag.map((item, index) => (
            <Fragment key={index}>
              <p
                className="people-market-field__group"
                onClick={() => openPanel("edit")}
              >
                {this.getFieldText(item)}
                <img
                  src="/assest/accountBased/del-tag.svg"
                  alt=""
                  onClick={(e) => delGroupSelect(e, item)}
                />
              </p>
            </Fragment>
          ))}

          {excludeTag.map((item, index) => (
            <Fragment key={index}>
              <p
                className="field__custom_exclude exclude_tag"
                onClick={() => openPanel("edit")}
              >
                {this.getFieldText(item)}
                <img
                src="/assest/apiKeys/Close_red.svg"
                alt=""
                  onClick={(e) => delGroupSelect(e, item)}
                />
              </p>
            </Fragment>
          ))}
        </div>
      </div>
    );
  }
}

export default LocationField;
