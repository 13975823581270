import { ROLE } from "../constants/constant";

export function role(roleGroupsData, name) {
  return {
    type: "ROLE",
    payload: roleGroupsData,
    name: name
  };
}
export function companyLocation(locationData) {
  return {
    type: "COMPANY_LOCATION",
    payload: locationData
  };
}
export function personLocation(locationData) {
  return {
    type: "PERSON_LOCATION",
    payload: locationData
  };
}
export function college(collegeData) {
  return {
    type: "COLLEGE",
    payload: collegeData
  };
}
export function major(majorData) {
  return {
    type: "MAJOR",
    payload: majorData
  };
}
export function estimatedSalary(estimatedSalary) {
  return {
    type: "ESTIMATED_SALARY",
    payload: estimatedSalary
  };
}
export function yearsOfExperience(yearsOfExperience) {
  return {
    type: "YEARS_OF_EXPERIENCE",
    payload: yearsOfExperience
  };
}
export function timeInLatestJobAction(timeInLatestJob) {
  return {
    type: "TIME_IN_LATEST_JOB",
    payload: timeInLatestJob
  };
}
export function industries(industriesData) {
  //console.log(industriesData);
  return {
    type: "INDUSTRIES",
    payload: industriesData
	  };
}
export function employees(employeesData) {
  return {
    type: "EMPLOYEES",
    payload: employeesData
  };
}
export function adwordsBudget(adwordsData) {
  return {
    type: "ADWORDS_BUDGET",
    payload: adwordsData
  };
}
export function technologies(techData) {
  return {
    type: "TECHNOLOGY",
    payload: techData
  };
}
export function news(newsData) {
  return {
    type: "NEWS",
    payload: newsData
  };
}
export function skills(skillsData) {
  return {
    type: "SKILLS",
    payload: skillsData
  };
}

export function industries2(industry2sData) {
  return {
    type: "INDUSTRIES2",
    payload: industry2sData
  };
}
export function departments(departmentsData) {
  return {
    type: "DEPARTMENTS",
    payload: departmentsData
  };
}
export function includeKeywords(incKeywordsData) {
  return {
    type: "INCLUDE_KEYWORDS",
    payload: incKeywordsData
  };
}
export function excludeKeywords(excKeywordsData) {
  return {
    type: "EXCLUDE_KEYWORDS",
    payload: excKeywordsData
  };
}
export function hiring(hiringData, name) {
  return {
    type: "HIRING",
    payload: hiringData,
    name: name
  };
}
export function includePastRole(include) {
  return {
    type: "INCLUDE_PAST_ROLE",
    payload: include,
  };
}
export function marketBasedAction(marketBased) {
  return {
    type: "MARKET_BASED",
    payload: marketBased,
  };
}

export function resetState() {
  return {
    type: "RESET_STATE"
  };
}

export function clearMarketBasedChanged() {
  return {
    type: "CLEAR_MARKETBASED_CHANGED"
  };
}

