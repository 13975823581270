import React, { Component, Fragment } from 'react'
var moment = require('moment')
class IgnoreListContent extends Component {
  render() {
    const { table, error, IgnoreLists, changeCheck, onDelete } = this.props
    return (
      <Fragment>
        {error &&
          <div className="error-div-ignoreList">
            <p>
              We were unable to retrieve your ignore list. Please contact help@leadfuze.co for further assistance or
              <span className="text-try-again" onClick={IgnoreLists}>try again</span>.
            </p>
          </div>
        }
        {table &&
          table.map((item, index) =>
            <div className="ignore-list coupon-header" key={index}>
              <div className="ignore-list__row">
                <div className="ignore-header__check">
                  <div className={`check ${item.check ? 'active' : ''}`} onClick={() => changeCheck(index, item.id)}></div>
                </div>
                <div className="ignore-list__items">
                  {item.type !== 2 &&
                    <p className="ignore-header__col category">{item.type === 0 ? "Company" : "Domain"}</p>
                  }
                  {item.type === 2 &&
                    <p className="ignore-header__col category">Email</p>
                  }
                  <p className="ignore-header__col title">{item.keyword}</p>
                  <p className="ignore-header__col upload">{item.source === 1 ? "Bulk" : "Manual"}</p>
                  <p className="ignore-header__col added">{moment(item.upload_date).format("MM-DD-YYYY")}</p>
                  <div className="ignore-header__close">
                    <div
                      className="ignore-list__close"
                      onClick={() => onDelete(item.id)}
                    >
                      <img src="/assest/billing/del.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Fragment>
    )
  }
}

export default IgnoreListContent
