import React from 'react'
import CouponsHeader from './CouponsHeader'
import CouponsContent from './CouponsContent'
import '../style.scss'

const Coupons = props =>{
  const { openAddCoupon, 
          searchValue, 
          searchFilter, 
          changeAllCheck, 
          allCheck, 
          changeCheck, 
          items } = props
  return(
    <section className="coupon">
      <div className="coupon__title">
        <h3>Coupons</h3>
        <div className="coupon__links">
          <p className="add" onClick={openAddCoupon}>
            <img src="/assest/accountBased/plus-orange.svg" alt=""/>
            Add New Coupon
          </p>
          <p><img src="/assest/billing/del.svg" alt=""/>Delete selected</p>
        </div>
      </div>
      <div className="coupon-search">
        <div className="coupon-search__inp">
          <img src="/assest/accountBased/search.svg" alt=""/>
          <input type="text" 
                 placeholder="Search coupon by code"
                 value={searchValue}
                 onInput={searchFilter}/>
        </div>
      </div>
      <div className="coupon-table">
        <CouponsHeader allCheck={allCheck} 
                       changeAllCheck={changeAllCheck}/>
        <CouponsContent items={items}
                        changeCheck={changeCheck}/>
      </div>
    </section>
  )
}

export default Coupons