import moment from "moment";
import React, { Component, Fragment } from 'react';
import GeneralInfo from './GeneralInfo';
import { LOCAL_STORAGE_KEYS } from "../../../../constants/constant";

class UsersContent extends Component {

  formatDate(date) {
    if (date) {
      return moment(date).format("MM-DD-YYYY");
    }
    return  "N/A";
  }

  getUserStatus(accounts) {
    if (accounts.length > 0 && accounts[0].status && accounts[0].status.length > 0) {
      if (accounts[0].status === "paid") {
        return "Paid";
      } else if (accounts[0].status === "active_trial") {
        return "Active Trial";
      }
    }

    return "Expired Trial";
  }

  getPlanName(accounts) {
    if (accounts.length > 0) {
      return accounts[0].plan_name
    }

    return "N/A";
  }

  getMonthlyLeads(accounts) {
    if (accounts.length > 0) {
      return accounts[0].monthly_lead_credits
    }

    return 0;
  }

  getIsUnlimited(accounts) {
    if (accounts.length > 0) {
      return accounts[0].is_unlimited ? "Unlimited" : ""
    }

    return "N/A";
  }

  differenceInTime(dateStr) {
    if (!dateStr) {
      return "";
    }

    const date = moment(dateStr);
    const diffInDays = moment().diff(date, "days");
    if (diffInDays === 0) {
      const diffInHours = moment().diff(date, "hours");
      if (diffInHours === 0) {
        const diffInMinutes = moment().diff(date, "minutes");
        return `${diffInMinutes} minutes ago`;
      }
      return `${diffInHours} hours ago`;
    }
    return `${diffInDays} days ago`;
  }

  render() {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));

    return (
      this.props.items.map((item, index) =>
        <Fragment key={index}>
          <div className={`users-tab-list__back ${item.active ? 'active' : ''}`} onClick={() => this.props.toggleUserDetail(item.id, false)}></div>

          <div className={`users-tab-list coupon-header ${item.active ? 'active' : ''}`} onClick={() => this.props.toggleUserDetail(item.id, true)}>
            <div className="users-tab-list__row">

              <div className="users-tab-list__items">
                <div className="users-tab-header__col">
                  <p className="bold">{item.first_name} {item.last_name}</p>
                  <span>{item.email}</span>
                </div>

                <div className="users-tab-header__col">
                  <p>{this.getUserStatus(item.accounts)}</p>
                  <span>{this.getPlanName(item.accounts)}</span>
                </div>

                <div className="users-tab-header__col">
                  <p>{item.available_lead_credits} leads</p>
                  <span>Recurring: {this.getMonthlyLeads(item.accounts)} leads</span>
                  <span>{this.getIsUnlimited(item.accounts)}</span>
                </div>

                <div className="users-tab-header__col users_created_date">
                  <p>{this.formatDate(item.registration_date)}</p>
                  <span>{item.accounts.length == 0 || item.accounts[0].plan_name.length === 0 ? 'Trial' : item.accounts[0].plan_name}</span>
                </div>

                <div className="users-tab-header__col users_last_login">
                  <p>{this.formatDate(item.last_login_date)}</p>
                  <span>{this.differenceInTime(item.last_login_date)}</span>
                </div>
              </div>

              <div className="users-tab-dropdown">
                <ul className={`users-tab-list__nav ${item.menuDropDownActive ? 'active' : ''}`} onClick={(event) => {event.stopPropagation(); this.props.toggleMenuDropDown(item.id, true)}}>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>

                <div className={`users-tab-dropdown__overlay ${item.menuDropDownActive ? 'active' : ''}`}  onClick={(event) =>  {event.stopPropagation(); this.props.toggleMenuDropDown(item.id, false)}}></div>

                {item.menuDropDownActive &&
                  <div className="dropdown-start">
                    {user.id !== item.id &&
                      <div className="dropdown-in-group" onClick={(event) => { event.stopPropagation(); this.props.loginAsUser(item.id)}}>Login as</div>
                    }

                    <div className="dropdown-in-group" onClick={(event) => {event.stopPropagation(); this.props.enableUserEditMode(item.id, true)}}>Edit</div>

                    {user.id !== item.id &&
                      <div className="dropdown-in-group danger" onClick={(event) => {event.stopPropagation(); this.props.showConfirmationPopup(item, "user")}}>Delete User</div>
                    }

                    {user.account_id !== item.account_id &&
                      <div className="dropdown-in-group danger" onClick={(event) => {event.stopPropagation(); this.props.showConfirmationPopup(item, "account")}}>Delete Account</div>
                    }
                  </div>
                }
              </div>

            </div>

            { item.active &&
              <GeneralInfo user={item}
                enableUserEditMode={this.props.enableUserEditMode}
                updateUserData={this.props.updateUserData} />
            }

          </div>
        </Fragment>
      )
    )
  }
}

export default UsersContent;
