import React from 'react'
import ReactTooltip from 'react-tooltip'
import {INTEGRATION_ID} from '../../../../constants/constant'

export default function IntegrationsToggle({turningOffIntegrationSwitch, openFreshSalesPopup, openDuxSoupPopup, getIntegrations, index, image, name, active, activeID, integrationsData, openIntegrationPopup, openIntegrationPopup2, id, apiLocation, integrationLabel}) {

    const changeIntegrationZapier = (i) => {
        openIntegrationPopup("zap", "", "", i)
    }

    const changeIntegrationAppypie = (i) => {
        openIntegrationPopup("appypie", "", "", i)
    }


    const changeIntegration = async (name, id, active, index, apiLocation, integrationLabel) => {
        if (active) {
            turningOffIntegrationSwitch(index, activeID)
            return
        }

        switch (id) {
            case INTEGRATION_ID.DUX_SOUP: {
                openDuxSoupPopup(name, "", "", index, image)
                return
            }
            case INTEGRATION_ID.FRESHSALES: {
                openFreshSalesPopup(name, "", "", index, image)
                return
            }
            case INTEGRATION_ID.SALESFORCE:
            case INTEGRATION_ID.PIPEDRIVE:
            case INTEGRATION_ID.MAILSHAKE:
            case INTEGRATION_ID.GOOGLE_SHEETS: {
                openIntegrationPopup(name, "", "", index, integrationLabel, image)
                return
            }
            case INTEGRATION_ID.JAZZ_HR:
            case INTEGRATION_ID.LEMLIST:
            case INTEGRATION_ID.REPLY:
            case INTEGRATION_ID.HIGHLEVEL:
            case INTEGRATION_ID.CLOSE_IO:
            case INTEGRATION_ID.SALESFLARE:
            case INTEGRATION_ID.WOODPECKER:
            case INTEGRATION_ID.HUBSPOT:
            case INTEGRATION_ID.BIDSKETCH: {
                openIntegrationPopup2(name, "", "", index, apiLocation, integrationLabel, image)
                return
            }
        }
    }

    const settingsPopup = () => {
        if (active) {
            switch (id) {
                case INTEGRATION_ID.DUX_SOUP: {
                    openDuxSoupPopup(name, true, activeID, index, image)
                    return
                }
                case INTEGRATION_ID.FRESHSALES: {
                    openFreshSalesPopup(name, true, activeID, index, image)
                    return
                }
                case INTEGRATION_ID.SALESFORCE:
                case INTEGRATION_ID.PIPEDRIVE:
                case INTEGRATION_ID.MAILSHAKE:
                case INTEGRATION_ID.GOOGLE_SHEETS: {
                    openIntegrationPopup(name, true, activeID, index, integrationLabel, image)
                    return
                }
                case INTEGRATION_ID.JAZZ_HR:
                case INTEGRATION_ID.LEMLIST:
                case INTEGRATION_ID.SALESFLARE:
                case INTEGRATION_ID.CLOSE_IO:
                case INTEGRATION_ID.REPLY:
                case INTEGRATION_ID.HIGHLEVEL:
                case INTEGRATION_ID.HUBSPOT:
                case INTEGRATION_ID.BIDSKETCH: {
                    openIntegrationPopup2(name, true, activeID, index, apiLocation, integrationLabel, image)
                    return
                }
            }
        }
    }

    return (
        <div className="setting-icon">
            <ReactTooltip/>
            {
                active ? (
                    id == INTEGRATION_ID.ZAPIER ?
                        <div
                            className="switch active"
                            data-tip={`This integration can only be turned off from Zapier directly`}><span></span>
                        </div> : id == INTEGRATION_ID.APPYPIE ? <div
                            className="switch active"
                            data-tip={`This integration can only be turned off from Appypie directly`}><span></span>
                        </div> :
                        (
                            <>
                                < img
                                    src="/assest/accountBased/acc-7-hover.svg"
                                    onClick={() => settingsPopup()}
                                    alt=""
                                />
                                <div className="switch active" onClick={() => {
                                    changeIntegration(name, id, active, index, apiLocation, integrationLabel)
                                }}><span></span></div>
                            </>
                        )

                ) : (
                    id == INTEGRATION_ID.ZAPIER ?
                        <div
                            className="switch"
                            onClick={() => changeIntegrationZapier(index)}
                        ><span></span></div> :
                        id == INTEGRATION_ID.APPYPIE ?
                            <div
                                className="switch"
                                onClick={() => changeIntegrationAppypie(index)}
                            ><span></span></div> : <div className="switch" onClick={() => {
                                changeIntegration(name, id, active, index, apiLocation, integrationLabel)
                            }}><span></span></div>

                )
            }

        </div>
    )
}
