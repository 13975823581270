import React from 'react'

const CouponsContent = props =>{
  const { items, changeCheck } = props
  return(
    items.map((item, index)=>
      <div className="coupon-list coupon-header" key={index}>
        <div className="coupon-list__row">
          <div className="coupon-header__check">
            <div className={`check ${item.check ? 'active' : ''}`} 
                 onClick={()=>changeCheck(index)}></div>
          </div>
          <div className="coupon-list__items">
            <p className="coupon-header__col code">{item.code}</p>
            <p className="coupon-header__col description">{item.descr}</p>
            <p className="coupon-header__col col">{item.time}</p>
            <p className="coupon-header__col col">{item.monthly}</p>
            <p className="coupon-header__col col-big">{item.usage}</p>
            <p className="coupon-header__col col-big">{item.redeemers}</p>
            <p className="coupon-header__col date">{item.expire}</p>
          </div>
          <div className="coupon-header__close">
            <div className="coupon-list__close">
              <img src="/assest/billing/del.svg" alt=""/>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default CouponsContent