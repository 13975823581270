import "../style.scss";
import React, { Fragment, Component } from "react";
import { Fab } from "@material-ui/core";
import ApiKeysContent from "../ApiKeysContent";
import AddNewKey from "./AddNewKeyPopup";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { sortyBy } from "../../../actions/apikeysfilters";
import { LOCAL_STORAGE_KEYS } from "../../../constants/constant";
import { bindActionCreators } from "redux";
import { Button } from "@material-ui/core";
import Loader from "../../Loader";
import { getApiKeysList } from "../../../actions/api_keys";

const NAME_COLUMN = {
  name: `Name`,
  clname: "name",
  active: false,
  icon: true,
  order: "",
};

const KEY_COLUMN = {
  name: `Key`,
  clname: "key",
  active: false,
  icon: false,
  order: "",
};

const STATUS_COLUMN = {
  name: "Status",
  clname: "status",
  active: false,
  icon: true,
  order: "",
};

const DATE_CREATED_COLUMN = {
  name: "Date Created",
  clname: "date apiKey_created_date",
  active: false,
  icon: true,
  order: "",
};

const DATE_REVOKED_COLUMN = {
  name: "Date Revoked",
  clname: "date apiKey_revoked_date",
  active: false,
  icon: true,
  order: "",
};

const list = [
  NAME_COLUMN,
  KEY_COLUMN,
  STATUS_COLUMN,
  DATE_CREATED_COLUMN,
  DATE_REVOKED_COLUMN,
  {
    name: "",
    clname: "apiKey_revoke",
    active: false,
    icon: false,
    order: "",
  },
];

class ApiKeysTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupAddNewKey: false,
      popupAddPremiumAPIPopup: false,
      consent: false,
    };
  }

  componentWillUnmount() {
    list.map((lists) => {
      lists.order = "";
    });
  }
  sortList = async (name) => {
    const { sortyBy, ApiKeys, apiKeysFilter, getApiKeysList } = this.props;
    let order = "";
    if (apiKeysFilter.order === "asc") {
      order = "desc";
    } else {
      order = "asc";
    }
    list.map((lists) => {
      if (name === lists.name) {
        lists.order = order;
      } else {
        lists.order = "";
      }
    });
    switch (name) {
      case NAME_COLUMN.name:
        await sortyBy({ status: "name", order: order });
        break;
      case STATUS_COLUMN.name:
        await sortyBy({ status: "status", order: order });
        break;
      case DATE_CREATED_COLUMN.name:
        await sortyBy({ status: "creation_date", order: order });
        break;
      case DATE_REVOKED_COLUMN.name:
        await sortyBy({ status: "revoke_date", order: order });
        break;
      default:
        await sortyBy("creation_date");
    }
    await getApiKeysList("");
    ApiKeys();
    return true;
  };
  openAddNewKeyPopup = () => {
    this.setState({ popupAddNewKey: !this.state.popupAddNewKey });
  };

  openAddPremiumAPIPopup = () => {
    this.setState({
      popupAddPremiumAPIPopup: !this.state.popupAddPremiumAPIPopup,
    });
  };

  gotoPremiumPlan = (type) => {
    let userAccount = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT)
    );
    if (type === "premium_monthly_1122_1500") {
      const STRIPE_PLAN_URL =
        process.env.REACT_APP_STRIPE_PREMIUM_1500_PLAN_URL +
        "?prefilled_email=" +
        userAccount.email;
      window.open(STRIPE_PLAN_URL, "_blank");
    }
  };

  toggleConsent =()=> {
    this.setState({consent: !this.state.consent});
  }

  render() {
    const { popupAddNewKey, popupAddPremiumAPIPopup } = this.state;
    const { apiKeysLists, ApiKeys, loader } = this.props;
    return (
      <Fragment>
        <div className="markbased__table markbased__table_key">
          <div className="coupon">
            <div className="coupon__title">
              <h3>API KEYS</h3>
              <div className="coupon__links">
                <p className="add" onClick={this.openAddNewKeyPopup}>
                  <Button>
                    <img src="/assest/accountBased/plus-orange.svg" alt="" />
                    Generate new key
                  </Button>
                </p>

                <p className="add" onClick={this.openAddPremiumAPIPopup}>
                  <Button>
                    <img src="/assest/accountBased/plus-orange.svg" alt="" />
                    Enable Premium API Credits
                  </Button>
                </p>
              </div>
            </div>
            <div className="coupon-table">
              <div className="coupon-header">
                <div className="coupon-header__row">
                  {list.map((item, index) => (
                    <div
                      className={`coupon-header__col ${item.clname} ${
                        item.order ? "sorted" : ""
                      } `}
                      key={index}
                    >
                      <p
                        onClick={
                          item.name === "Key"
                            ? undefined
                            : () => this.sortList(item.name)
                        }
                      >
                        {item.name}
                      </p>
                      {item.icon && item.order && (
                        <div onClick={() => this.sortList(item.name)}>
                          {item.order === "asc" ? (
                            <img
                              src="/assest/accountBased/sort_asc.svg"
                              alt=""
                            />
                          ) : (
                            <img
                              src="/assest/accountBased/sort_desc.svg"
                              alt=""
                            />
                          )}
                        </div>
                      )}
                      {item.icon && !item.order && (
                        <div onClick={() => this.sortList(item.name)}>
                          {<img src="/assest/accountBased/sort.svg" alt="" />}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {loader.loading && <Loader />}
            {apiKeysLists.apiKeysLists && (
              <ApiKeysContent
                items={apiKeysLists.apiKeysLists}
                ApiKeys={ApiKeys}
              />
            )}
            <AddNewKey
              popupAddNewKey={popupAddNewKey}
              openAddNewKeyPopup={this.openAddNewKeyPopup}
              ApiKeys={ApiKeys}
            />
            {popupAddPremiumAPIPopup && (
              <Fragment>
                <div
                  className={`add-list ${
                    popupAddPremiumAPIPopup ? "open" : ""
                  }`}
                >
                  <div className="add-list__content" style={{maxWidth:`35rem`}}>
                    <div className="pricing-row">
                      {/* <div className={`pricing-col starter-plan ${ this.state.consent === '' || this.state.consent === 'monthly_1019_500' ? 'selected' : ''}`} onClick={() => {}}> */}
                      <div
                        className={`pricing-col`}
                        style={{width:`30rem`, border:`none`, boxShadow:`none`}}
                        onClick={() => {}}
                      >
                       
                          <img
                            className="add-list__close"
                            src="/assest/accountBased/close.svg"
                            alt=""
                            onClick={this.openAddPremiumAPIPopup}
                          />
                          <h3 className="pricing-title">Premium API Plan</h3>

                          <div
                            className="pricing-for bg-limeblue"
                            style={{ padding: "10px" }}
                          >
                            For individuals looking to start Premium API Features
                          </div>
                          <div
                            className="pricing-price generate"
                            style={{ padding: `1rem` }}
                          >
                            <p className="price_info">$99/mo</p>
                            <div className="monthly">
                              1500 Leads Credits/
                              <span className="pricing-sub">
                                {"mo"}
                                <sup>*</sup>
                                <a
                                  href="#"
                                  data-for="starterToolTip"
                                  data-tip
                                  data-place="right"
                                >
                                  <span
                                    className="fa fa-question-circle-o tooltips"
                                    aria-hidden="true"
                                  ></span>
                                </a>
                              </span>
                              <ReactTooltip id="starterToolTip">
                                <p className="starter-tooltip">
                                  When you add a lead to a list, this uses up
                                  one lead credit. You are then given contact
                                  info, social data, and more on that lead. You
                                  can always buy more lead credits at any time.
                                </p>
                              </ReactTooltip>
                            </div>
                            <p
                              className="sub_price_info"
                              style={{ padding: `0.5rem` }}
                            >{``}</p>
                          </div>

                          <div className="pricing-items">
                            <ul>
                              <li>
                                <i className="fa fa-briefcase"></i>Features 1 
                              </li>
                              <li>
                                <i className="fa fa-briefcase"></i>Features 2
                             </li>
                            </ul>
                            <div
                              className="pricing-for bg-limegreen"
                              style={{ padding: `1rem` }}
                            >
                              <label className="title">Agree To Terms:</label>
                              <div
                                onClick={this.toggleConsent}
                                className="terms_agree__check"
                                style={{
                                  marginTop: `15px`,
                                  paddingLeft: `0.8rem`,
                                }}
                              >
                                <div
                                  className={`check ${
                                    this.state.consent ? "active" : ""
                                  }`}
                                  style={{
                                    padding: `6px`,
                                    border: `2px solid rgb(236, 151, 29)`,
                                    borderRadius: `12px`,
                                  }}
                                ></div>
                                <p
                                  style={{
                                    marginBottom: `20px`,
                                    marginTop: `-20px`,
                                  }}
                                >
                                  By purchasing, you agree to the{" "}
                                  <u>
                                    <a
                                      href="https://leadFuze.com/terms-use"
                                      target="_blank"
                                    >
                                      terms of service
                                    </a>
                                  </u>
                                  .
                                </p>
                              </div>
                              <Fab
                               disabled={!this.state.consent}
                                variant="extended"
                                size="medium"
                                color="primary"
                                aria-label="add"
                                onClick={() =>
                                  this.gotoPremiumPlan(
                                    "premium_monthly_1122_1500"
                                  )
                                }
                              >
                                Subscribe
                              </Fab>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sortyBy,
      getApiKeysList,
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    apiKeysFilter: state.apiKeysFilter,
    loader: state.loader,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeysTable);
