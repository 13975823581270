import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../helpers/renderfield';
import Loader from '../../Loader';
import '../style.scss';
import { Fab } from '@material-ui/core';

const ForgotPassContent = (props) => {
  const { validationError, visible, handleSubmit, responseError, isPasswordReset, isValidToken, wrongEmail } = props;
  return (
    <div className="pass-cont">
      <div className="pass-cont__logo">
        <img src="/assest/accountBased/logo.svg" alt="" />
      </div>
      <div className="height-notification">
        {wrongEmail &&
          <div className="error-div">
            <p>User with this email does not exist.</p>
          </div>
        }
        {!isValidToken && !isPasswordReset &&
          <div className="error-div">
            <p>Your reset token has expired. Please request password reset again.</p>
          </div>
        }
        {responseError &&
          <div className="error-div">
            <p>Sorry, we were unable to send you password reset instructions. Please try again later or contact help@leadfuze.co for further assistance.</p>
          </div>
        }
      </div>
      {!isPasswordReset &&
        <div className="pass-cont__box">
          <h2 className={`pass-cont__title ${responseError || !isValidToken ? 'mt-0' : ''}`}>Forgot Password?</h2>
          <form method="post" onSubmit={handleSubmit}>
            <div className="sign-in__cont input-field lists_table_loader" >
              {visible && <Loader />}

              <label className="sign-in__label">
                <p className="without-icon-field">EMAIL ADDRESS</p>
                <div className="without-icon-field">
                  <Field name="email" component={renderField} type="email" label="myemail@address.com" validationError={validationError.email} />
                </div>
                {validationError &&
                  <span className="error-red">{validationError.email}</span>
                }
              </label>
              <p className="pass-cont__descr">Enter the email address for your LeadFuze account. We will send you a password reset link!</p>
              <div className="pass-cont__buttons">
                <Fab
                  type="submit"
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="add"
                >Request Reset Link</Fab>
                <Link to="/signin"><span className="pass-cont__link">Back to sign in</span></Link>
              </div>
            </div>

          </form>
        </div>
      }
      {isPasswordReset && !wrongEmail &&
        < div className="pass-cont__box">
          <h2 className="pass-cont__title">Password Reset Link Sent</h2>
          <p className="pass-cont__descr email-box">Check your email inbox, there should be a password reset link.</p>
          <div className="pass-cont__buttons">
            <Link to="/signin" className="pass-cont__btn">Back to sign in</Link>
          </div>
        </div>
      }
      <div></div>
      {
        !isPasswordReset &&
        <p className="pass-cont__text">Don’t have an account? <a className="pass-cont__sign" href="https://leadfuze.com/start">Sign Up!</a> It takes less than a minute.</p>
      }
    </div >
  )
}

export default reduxForm({
  form: 'forget-pass'
})(ForgotPassContent)
