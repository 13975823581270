import React, {Component} from 'react';
import './style.scss';

class ConfirmCancellationPopup extends Component {


    render() {
        return (
            <div className="popup-pause">
                <div className="popup-pause__container">
                    <img className="popup-pause__close" src="/assest/accountBased/close.svg" alt=""
                         onClick={() => this.props.showConfirmCancellationPopup(false)}/>
                    <p className="popup-pause__title">Cancel my account</p>
                    <p className="confirmation-popup__message">Are you sure you want to cancel your account?</p>

                    <div className="confirmation-popup__buttons">
                        <div className="confirmation-popup__btn orange"
                             onClick={() => this.props.showDoubleLeadCreditsPopup(true)}>Yes - Cancel my account
                        </div>
                        <div className="confirmation-popup__btn orange-light"
                             onClick={() => this.props.showConfirmCancellationPopup(false)}>No
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ConfirmCancellationPopup;
