import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

class JobStatus extends Component {
  
  render() {
    const {
      setRequireJobStatus,
      setRequireEmployment,
      setRequireUnemployment,
      searchResultFilters
    } = this.props;

    return (
      <div className="right-filters__jobStatus">
        <div className="right-filters__switch" onClick={setRequireJobStatus}>
            {/* <div className={`switch ${searchResultFilters.require_job_status ? 'active' : ''}`}><span></span></div> */}
          <p className="switch__name">Job Status</p>
        </div>
        

        {searchResultFilters.require_job_status &&
          <div className="jobSection">
            <label onClick={setRequireEmployment}
                    className={`radio-btn ${searchResultFilters.require_employment ? 'active' : ''}`}>
                <div className={`radio-btn__check`}><span></span></div>
                <p>Currently Employed</p>
            </label>
            <label onClick={setRequireUnemployment}
                    className={`radio-btn ${searchResultFilters.require_unemployment ? 'active' : ''}`}>
                <div className={`radio-btn__check`}><span></span></div>
                <p>Currently Unemployed &nbsp;</p>
                <img data-tip data-for="unemployed_status" data-place="bottom" src="/assest/icon-help.svg" alt="" width="12" />
                <ReactTooltip id="unemployed_status">
                  <p className="unemployed-tooltip">
                    Turning this on will ignore company criteria and will show everyone matching your people criteria filters.
                  </p>
                </ReactTooltip>
            </label>
            {/* <div className="right-filters__jobChecks actCheck">
              <div className="right-filters__check" onClick={setRequireEmployment}>
                <div className={`check ${searchResultFilters.require_employment ? 'active' : ''}`}></div>
                <p className={`${searchResultFilters.require_employment ? 'right-filters__checked' : ''}`}>Currently Employed</p>
              </div>

              <div className="right-filters__check" onClick={setRequireUnemployment}>
                <div className={`check ${searchResultFilters.require_unemployment ? 'active' : ''}`}></div>
                <p className={`${searchResultFilters.require_unemployment ? 'right-filters__checked' : ''}`}>Currently Unemployed</p>
              </div>
            </div> */}
          </div>
        }
      </div>
    )
  }
}

export default JobStatus;