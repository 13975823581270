import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import LeadSearch from './LeadSearch'
import LeadList from './LeadList'
import Admin from './Admin'
import JwtDecode from 'jwt-decode';
import items from './items'
import './style.scss'
import Settings from './Settings';
import moment from "moment";
import { withRouter } from 'react-router-dom';
import { extendTrial } from '../../services/billing'
import { toast } from 'react-toastify'
import { LOCAL_STORAGE_KEYS } from '../../constants/constant'
import ReactTooltip from 'react-tooltip';
import {EmitterService} from "../../services/emitterService";

class Sidebar extends Component {
  state = {
    active: items
  }

  componentDidMount = () => {
    const { active } = this.state
    const urlParams = window.location.pathname
    
    let pathArray = window.location.pathname.split('/');
    let secondLevelLocation = pathArray[1];
    if (active) {
      active.forEach(items => {
        if (items.slug === urlParams) {
          items.current = !items.current
          items.subMenu = !items.subMenu
        } else if (secondLevelLocation === "lead-search" && items.slug === "/lead-search/market-based") {
          items.subMenu = !items.subMenu
          items.current = !items.current
        } else if ((secondLevelLocation === "profile" || secondLevelLocation === "integrations") && items.slug === "/api-keys") {
          items.subMenu = !items.subMenu
          items.current = !items.current
        } else if (secondLevelLocation === "ignore-list" && items.slug === "/lead-search/market-based") {
          items.subMenu = !items.subMenu
          items.current = !items.current
        } else if (secondLevelLocation === "lead-lists" && items.slug === "/people-lists") {
          items.subMenu = !items.subMenu
          items.current = !items.current
        } else if (secondLevelLocation === "lead-list" && items.slug === "/people-lists") {
          items.subMenu = !items.subMenu
          items.current = !items.current
        }
      })
      this.setState({ active: this.state.active })
    }
  }

  changeClass = (i) => {
    const { active } = this.state
    if (active[i].current === false) {
      for (let key in active) {
        active[key].current = false
        active[key].subMenu = false
      }
      active[i].current = !active[i].current
      active[i].subMenu = !active[i].subMenu

    } else {
      active[i].subMenu = !active[i].subMenu
    }
    this.setState({ active: this.state.active })
  }

  backToAdmin = () => {
    const primaryLogin = JSON.parse(localStorage.getItem("primaryLogin"));

    localStorage.setItem('user', JSON.stringify(primaryLogin.user))
    localStorage.setItem('userAccount', JSON.stringify(primaryLogin.userAccount));
    localStorage.setItem('token', primaryLogin.token);
    localStorage.removeItem("primaryLogin");
    window.location.href = window.location.origin + "/admin/users";
  }

  extendTrialPeriod = async () => {
    const toastId =  toast('Extending your trial Period', {
      hideProgressBar: true, autoClose: 10000
    });
    try {
      const extendTrialRes = await extendTrial();
      EmitterService.emit("loadLeadsRemaining");
      toast.update(toastId, {
        render: "Trial Extended successfully",
        className: "toast-success-div",
        type: toast.TYPE.SUCCESS,
        hideProgressBar: true,
        autoClose: 2000
      });
    } catch(e) {
      toast.update(toastId, {
        className: "toast-error-div",
        render: `Failed to extend the trial`,
        type: toast.TYPE.ERROR,
        autoClose: 2000,
    });
    }

  }

  showTrialRenewalBanner = (account) => {
    // ye if we need to show or not, this function takes the decision
    if (!account.registration_date) {
        return false
    }

    const hoursLeft = moment(account.trial_expiration_date).diff(moment(), "hours");

    if (hoursLeft > 0) {
        return false
    }

    const days = moment(account.trial_expiration_date).diff(moment(account.registration_date), "days");
    // var days = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000  60  60 * 24));

    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));

    if ((account.status === "trial_expired" && (user.is_lead_fuze_admin || account.extended_trial === false))) {
        return true
    }

    return false
}

  render() {
    const userAccount = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT));
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
    const primaryLogin = JSON.parse(localStorage.getItem('primaryLogin'));
    let trialDiv = null;
    let disableButtonsDueToExpired = false;
    if (this.props.userProfileInfo.accounts && this.props.userProfileInfo.accounts.length) {
      if (!this.props.userProfileInfo.accounts[0].status || this.props.userProfileInfo.accounts[0].status === 'active_trial'
      || this.props.userProfileInfo.accounts[0].status === 'trial_expired' || this.props.userProfileInfo.accounts[0].status === 'cancelled') {
        disableButtonsDueToExpired = this.props.userProfileInfo.accounts[0].status === 'trial_expired';
        //const daysLeft = moment(this.props.userProfileInfo.accounts[0].trial_expiration_date).diff(moment(), "days");
        const hoursLeft = moment(this.props.userProfileInfo.accounts[0].trial_expiration_date).diff(moment(), "hours");
        
        let daysLeft = Math.ceil(hoursLeft / 24);
        // if(daysLeft > 7)
        //   daysLeft = 7;
        if (hoursLeft >= 24) {
          if(daysLeft > 8) {
            trialDiv = <div className="sidebarText">
            <Link className="sidebarText__link" to="/billing-and-users">Upgrade now</Link> to experience the full LeadFuze platform.
          </div>
          }
          else {
            trialDiv = <div className="sidebarText">
            <div className="sidebarText__descr">You have <div className="sidebarText__btn">{daysLeft} Days Left</div> in your trial.</div>
            <Link className="sidebarText__link" to="/billing-and-users">Upgrade now</Link>
          </div>
          }
        } else if (hoursLeft < 24 && hoursLeft > 0) {
          trialDiv = <div className="sidebarText">
          <div className="sidebarText__descr">You have <div className="sidebarText__btn">{hoursLeft} Hours Left</div> in your trial.</div>
          <Link className="sidebarText__link" to="/billing-and-users">Upgrade now</Link>
        </div>
        } else if (hoursLeft <= 0) {
          if (this.showTrialRenewalBanner(this.props.userProfileInfo.accounts[0])) {
            trialDiv = <div className="sidebarText">
          <div className="sidebarText__descr">Your trial has expired!</div>
          <p className="sidebarText__link" onClick={this.extendTrialPeriod}>Click here to extend your trial for 7 days</p>
          <div className="sidebarText__descr">or upgrade to a paid plan below to unlock everything today!</div>
          <Link className="sidebarText__link" to="/billing-and-users">Upgrade now</Link>
            {/* alert = <Alert
                message={<span>Your trial has expired! <a href="" onClick={this.handleExtendTrial.bind(this)}>Click here to extend your trial for 7 days</a>, or upgrade to a paid plan below to unlock everything today!</span>}/>  */}
          </div>
        } else {
          disableButtonsDueToExpired = true;
          trialDiv = <div className="sidebarText">
                <div className="sidebarText__descr">Your trial has expired! Need some more time to evaluate? <a href="mailto:help@leadfuze.co" style={{color: '#ec971d'}}>Contact us</a> to extend or upgrade to a paid plan to unlock everything today!</div>
                <Link className="sidebarText__link" to="/billing-and-users">Upgrade now</Link>
              </div>
          }
        }
      }
    }
    let isSuperAdmin = false;
    let isAdmin = user ? user.is_admin : false;
    const parsed = JwtDecode(localStorage.token);
    if (parsed) {
      isSuperAdmin = parsed.is_lead_fuze_admin;
    }

    let logout = false;

    if (!primaryLogin && !user.is_admin && userAccount.status === 'trial_expired') {
      localStorage.clear();
      logout = true;
    }

    return (
      <div className={`sidebar`}>

        {logout && this.props.history.push("/signin?path=/lead-search/market-based")}
        {(disableButtonsDueToExpired) && this.props.history.location.pathname !== '/billing-and-users' && this.props.history.push("/billing-and-users")}
        <div className="sidebar__container">
          <div className="sidebar__logo">
            <img src="/assest/accountBased/logo.svg" alt="" />
          </div>

          {primaryLogin &&
            <div className="nav-primary-login">
              <div className="link" onClick={() => this.backToAdmin()}>Back To Admin</div>
            </div>
          }

          <div className={`sidebarTop scroll_view ${disableButtonsDueToExpired ? "disable-btn" : ''}`}>
            <ul className="sidebarTop__list">
              {
                items.map((item, index) =>
                  <Fragment key={index}>

                    {item.name === 'Admin' && !isSuperAdmin ? '' :
                      <Fragment>
                        {
                        !isAdmin && item.name == 'Billing & Users' ? 
                          <li data-tip data-for="only_accessible_to_admins" data-place="top" className={`sidebarTop__item disabled ${item.flag ? 'arr' : ''}`} onClick={() => this.changeClass(index)}>
                            <img  src={`/assest/accountBased/${item.icon}`} alt="" />
                            <ReactTooltip id="only_accessible_to_admins">
                              <p className="non-accessible-admin-tooltip">
                                This page is only accessible to account admins
                              </p>
                            </ReactTooltip>
                            {item.name}
                          </li> :
                        <Link to={item.slug}>
                          <li className={`sidebarTop__item ${item.flag ? 'arr' : ''} ${item.current ? 'active' : ''}`} onClick={() => this.changeClass(index)}>
                            <img src={`/assest/accountBased/${item.current ? item.active : item.icon}`} alt="" />
                            {item.name}
                          </li>
                        </Link>
                        }

                        {
                          items[0].subMenu && index === 0 ? <LeadSearch current={items[0].current} /> :
                            // items[1].subMenu && index === 1 ? <LeadList current={items[1].current} /> :
                              items[3].subMenu && index === 3 ? <Settings current={items[2].current} /> :
                                items[4].subMenu && index === 4 ? <Admin current={items[3].current} /> : ''
                        }
                      </Fragment>
                    }
                  </Fragment>
                )
              }
              <li className="sidebarTop__item" onClick={() => window.open("http://kb.leadfuze.com")} >
                <img src="/assest/accountBased/quest.svg" alt=""
                />
                Knowledge Base
              </li>
            </ul>
          </div>
          {trialDiv}
        </div>
      </div>
    )
  }
}

export default withRouter((Sidebar))
