import React, { Component, Fragment } from 'react';

class EmailCheck extends Component {
  
  render() {
    const {
      setRequireEmail,
      setRequireDoubleVerifiedEmails,
      setRequireCompanyEmails,
      setRequirePersonalEmails,
      searchResultFilters
    } = this.props;

    return (
      <Fragment>
        <div className="right-filters__top">
          <div className="right-filters__email" onClick={setRequireEmail}>
            <div className={`switch ${searchResultFilters.require_email ? 'active' : ''}`}><span></span></div>
            <p className="switch__name">Require Email?</p>
          </div>
          {searchResultFilters.require_email &&
           <div className="right-filters__checks actCheck">
              {/* <div className="right-filters__check" onClick={setRequireDoubleVerifiedEmails}>
                <div className={`check ${searchResultFilters.require_double_verified_emails ? 'active' : ''}`}></div>
                <p className={`${searchResultFilters.require_double_verified_emails ? 'right-filters__checked' : ''}`}>Double Verified Emails Only</p>
              </div> */}

              <div className="right-filters__check" onClick={setRequireCompanyEmails}>
                <div className={`check ${searchResultFilters.require_company_emails ? 'active' : ''}`}></div>
                <p className={`${searchResultFilters.require_company_emails ? 'right-filters__checked' : ''}`}>Require Company Emails</p>
              </div>

              <div className="right-filters__check" onClick={setRequirePersonalEmails}>
                <div className={`check ${searchResultFilters.require_personal_emails ? 'active' : ''}`}></div>
                <p className={`${searchResultFilters.require_personal_emails ? 'right-filters__checked' : ''}`}>Require Personal Emails</p>
              </div>
            </div>
          }
        </div>
      </Fragment>
    )
  }
}

export default EmailCheck