export function isValidEmail(email) {
  if (!email) {
    return false
  }
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email)
}

export function isValidDomain(domain) {
  if (!domain) {
    return false;
  }
  return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}([a-zA-Z0-9]?)\.[a-zA-Z]{2,10}$/.test(domain);
}

export function isNotEmpty(value) {
  if (!value) {
    return false
  }
  return value.trim() !== ''
}

export const validate = values => {
  const errors = {};
  if (!values.pass) {
    errors.pass = 'Password is Required';
  }
  if (!values.confirm_pass ) {
    errors.confirm_pass = 'Confirm Password is Required' ;
  } else if (values.confirm_pass !== values.pass) {
    errors.confirm_pass = 'Password mismatched' ;
  }
    // Check for email validity
  if (!values.email) {
    errors.email = 'Email is Required';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  } else {
    // Extract domain from email
    const domain = values.email.split('@')[1];
    if (!isValidDomain(domain)) {
      errors.email = 'Invalid domain in email address';
    }
  }
  return errors;
}
